// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type ShelfTags = 'refrigerator' | 'freezer' | 'freezer2_2' | 'domestic' | 'freezer24' | 'safe';
export const shelfTagsValues = [
	'refrigerator',
	'freezer',
	'freezer2_2',
	'domestic',
	'freezer24',
	'safe',
] as ShelfTags[];
export const shelfTagsNames = makeServerTranslations(shelfTagsValues, 'enums.SHELF_TAGS');

export type ProductStatuses = 'active' | 'disabled' | 'removed' | 'archive';
export const productStatusesValues = ['active', 'disabled', 'removed', 'archive'] as ProductStatuses[];
export const productStatusesNames = makeServerTranslations(productStatusesValues, 'enums.PRODUCT_STATUSES');

export type AccountingTypes = 'unit' | 'weight' | 'true_weight';
export const accountingTypesValues = ['unit', 'weight', 'true_weight'] as AccountingTypes[];
export const accountingTypesNames = makeServerTranslations(accountingTypesValues, 'enums.ACCOUNTING_TYPES');

export type ProductScheduleTypes =
	| 'coffee'
	| 'kitchens'
	| 'alcohol'
	| 'pharmacy'
	| 'safe'
	| 'weakAlcohol'
	| 'hardAlcohol';
export const productScheduleTypesValues = [
	'coffee',
	'kitchens',
	'alcohol',
	'pharmacy',
	'safe',
	'weakAlcohol',
	'hardAlcohol',
] as ProductScheduleTypes[];
export const productScheduleTypesNames = makeServerTranslations(
	productScheduleTypesValues,
	'enums.PRODUCT_SCHEDULE_TYPES'
);
