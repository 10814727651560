// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type EquipmentTypes =
	| 'refrigerator'
	| 'freezer'
	| 'refrigerator2_2'
	| 'refrigerator4_6'
	| 'refrigerator_18'
	| 'coffee_machine'
	| 'blender'
	| 'mixer'
	| 'bake'
	| 'toaster'
	| 'deep_fryer'
	| 'citrus_juicer'
	| 'ice_cream_freezer'
	| 'combi_oven'
	| 'contact_grill'
	| 'combi_stove'
	| 'microwave';
export const equipmentTypesValues = [
	'refrigerator',
	'freezer',
	'refrigerator2_2',
	'refrigerator4_6',
	'refrigerator_18',
	'coffee_machine',
	'blender',
	'mixer',
	'bake',
	'toaster',
	'deep_fryer',
	'citrus_juicer',
	'ice_cream_freezer',
	'combi_oven',
	'contact_grill',
	'combi_stove',
	'microwave',
] as EquipmentTypes[];
export const equipmentTypesNames = makeServerTranslations(equipmentTypesValues, 'enums.EQUIPMENT_TYPES');

export type EquipmentStatuses = 'active' | 'disabled' | 'removed';
export const equipmentStatusesValues = ['active', 'disabled', 'removed'] as EquipmentStatuses[];
export const equipmentStatusesNames = makeServerTranslations(equipmentStatusesValues, 'enums.EQUIPMENT_STATUSES');

export type EquipmentModule = 'storage' | 'kitchen';
export const equipmentModuleValues = ['storage', 'kitchen'] as EquipmentModule[];
export const equipmentModuleNames = makeServerTranslations(equipmentModuleValues, 'enums.EQUIPMENT_MODULE');
