export const theme = {
	scheme: 'Classic Light',
	author: 'Jason Heeris (http://heeris.id.au)',
	base00: '#F5F5F5',
	base01: '#E0E0E0',
	base02: '#D0D0D0',
	base03: '#B0B0B0',
	base04: '#505050',
	base05: '#303030',
	base06: '#202020',
	base07: '#151515',
	base08: '#AC4142',
	base09: '#D28445',
	base0A: '#F4BF75',
	base0B: '#90A959',
	base0C: '#75B5AA',
	base0D: '#6A9FB5',
	base0E: '#AA759F',
	base0F: '#8F5536',
};

export const serviceTypes = {
	pim: 'Голубь',
	wms: 'WMS',
	grocery: 'Grocery',
};

export const entityTypes = {
	store: 'Склад',
	product: 'Продукт',
	order: 'Ордер',
};

export const suggestLabels = {
	search_wms: 'Искать в WMS',
	search_product_in_stores: 'Искать продукт по всем складам',
	search_product: 'Искать продукт',
	check_product_in_store: 'Проверить продукт на складе',
	search_order: 'Искать ордер',
	special: 'Искать приключения',
	wms: 'Искать в WMS',
	product: 'Продукт',
	store: 'Склад',
};

export const compliments = [
	'Вы не только технически грамотны, но и очень талантливы в создании прекрасного пользовательского интерфейса ❤️',
	'Ваше умение быстро адаптироваться к изменяющимся условиям и требованиям проекта делает вас ценным членом команды разработки ❤️',
	'Ваше острое чувство юмора и легкость общения помогают снять напряжение вокруг сложных задач и мотивируют других членов команды ❤️',
	'Ваша способность быстро решать проблемы и находить эффективные решения делает вас неотъемлемой частью любого проекта ❤️',
	'Ваше умение работать в команде и поддерживать коллег приводит к более эффективной и приятной рабочей атмосфере ❤️',
	'Ваше стремление к самосовершенствованию и постоянное изучение новых технологий помогает не только вам, но и всей команде двигаться вперед ❤️',
	'Ваше храброе отношение к риску и желание пробовать новое вдохновляет коллег на эксперименты и поиск креативных решений ❤️',
	'Ваша инициативность в разработке проектов позволяет предлагать новые идеи, которые могут улучшить работу всей команды ❤️',
	'Ваша способность анализировать данные и делать выводы помогает сокращать время работы над проектами и повышать эффективность продукта ❤️',
	'Ваша страсть к разработке и желание создавать нечто новое и уникальное делает вас не только разработчиком, но и творцом ❤️',
	'Ваша способность находить креативные решения задач делает вас не только разработчиком, но и художником ❤️',
	'Ваше умение понимать потребности пользователей помогает создавать продукты, которые идеально подходят для своей целевой аудитории ❤️',
	'Ваша энергия и позитивный настрой помогают поддерживать командный дух, повышая мотивацию всех членов команды ❤️',
	'Ваша способность работать с большим количеством информации одновременно помогает быстро принимать решения и управлять проектами ❤️',
	'Ваше умение проводить время с коллегами за пределами работы помогает закреплять дружеские отношения и повышать эффективность команды ❤️',
	'Ваша гибкость и умение находить компромиссы делает вас ценным членом любой команды разработки ❤️',
	'Ваша точность и внимательность к деталям помогают создавать продукты с высоким уровнем качества и без ошибок ❤️',
	'Ваше знание не только технической стороны вопроса, но и контекста и целей проекта делает вас более эффективным разработчиком ❤️',
	'Ваше умение работать под давлением и находить решения в сложных ситуациях помогает сохранять спокойствие и справляться с задачами ❤️',
	'Ваше умение быстро обучаться новым технологиям и инструментам делает вас готовым к работе с последними тенденциями в отрасли ❤️',
];
