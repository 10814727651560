import { useDirection } from '~i18n/hooks';
import { ReactComponent as ChevronLeftSolid } from '~images/icons/chevron-left-solid.svg';
import { ReactComponent as ChevronRightSolid } from '~images/icons/chevron-right-solid.svg';
import Icon from '~shared/utils/icons';

import { useStyles } from './styles';

interface Props {
	onPrevClick: () => void;
	onNextClick: () => void;
}

const Slider = ({ onPrevClick, onNextClick }: Props) => {
	const { classes } = useStyles();

	const PrevIcon = useDirection(ChevronLeftSolid, ChevronRightSolid);
	const NextIcon = useDirection(ChevronRightSolid, ChevronLeftSolid);

	return (
		<div className={classes.chevronsWrapper}>
			<Icon data-test="prevIcon" onClick={onPrevClick} className={classes.chevronCircle} component={PrevIcon} />
			<Icon data-test="nextIcon" onClick={onNextClick} className={classes.chevronCircle} component={NextIcon} />
		</div>
	);
};

export default Slider;
