import dayjs from 'dayjs';

import { ReactComponent as Auto } from '~images/icons/auto-courier.svg';
import { ReactComponent as Courier } from '~images/icons/bike-courier.svg';
import { ReactComponent as Rover } from '~images/icons/rover-courier.svg';
import { ReactComponent as Taxi } from '~images/icons/taxi-courier.svg';
import type { ProcessedCourierCardData } from '~shared/constants/tableauCouriers';
import type { Orders } from '~types/orders';
import type { Shifts } from '~types/shifts';

export const isCourierReturningTooLong = (lastOrderTime?: string | null, interval = 10) => {
	return lastOrderTime ? dayjs(lastOrderTime).isBefore(dayjs().subtract(interval, 'minute')) : false;
};

const diffInSeconds = (created: string) => dayjs().diff(dayjs(created), 'second');

export const sortedCouriersData = (data: ProcessedCourierCardData[]) => {
	const sortedCourierStats = [...data].sort(
		(a, b) => diffInSeconds(b.lastOrderTime ?? b.created) - diffInSeconds(a.lastOrderTime ?? a.created)
	);

	const mainCouriersAmount = sortedCourierStats.length <= 7 ? sortedCourierStats.length : 6;

	return {
		mainCourierList: sortedCourierStats.slice(0, mainCouriersAmount),
		restCourierList: sortedCourierStats.slice(mainCouriersAmount),
		totalAmount: sortedCourierStats.length,
	};
};

export const getCourierIcon = (
	deliveryType?: Shifts.DeliveryType | Orders.Order['dispatch_transport_type'] | 'courier_car',
	isRover?: boolean | null
) => {
	if (isRover) return Rover;

	switch (deliveryType) {
		case 'yandex_taxi':
		case 'yandex_taxi_remote':
		case 'yandex_taxi_night':
			return Taxi;
		case 'rover':
			return Rover;
		case 'car':
		case 'courier_car':
			return Auto;
		default:
			return Courier;
	}
};
