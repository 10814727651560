import { useEffect } from 'react';

import { getData, saveData } from '../utils/persistData';

export default function (name: string, selector: string) {
	useEffect(() => {
		const position = getData(name, true);
		if (position) {
			const element = document.querySelector(selector);
			if (element) element.scrollTop = position;
		}
		return () => {
			const element = document.querySelector(selector);
			if (element) {
				const scroll = element.scrollTop;
				saveData(name, scroll, true);
			}
		};
	}, []);
	return;
}
