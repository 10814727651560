import type { Location } from '@remix-run/router';
import ym from 'react-yandex-metrika';

import { getUserConstants } from '~zustand/userData';

const DEBUG_METRIKA = false;

function ymWithCheck(methodName: string, ...args: unknown[]) {
	const {
		domain_configs: { yandex_metrika_id: yandexMetrikaId },
	} = getUserConstants();
	if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_MODE === 'production' && yandexMetrikaId) {
		ym(methodName, ...args);
	} else if (process.env.NODE_ENV === 'development' && DEBUG_METRIKA) {
		console.log('METRIKA', methodName, JSON.stringify(args, null, 2));
	}
}

function sendEvent(type: string, eventParams?: Record<string, unknown>) {
	ymWithCheck('reachGoal', 'event', { _type: type, ...eventParams });
}

export function sendChangeRoute(location: Location) {
	ymWithCheck('hit', location.pathname + location.search);
}

export function updateUserParams(userId: string | undefined, params: Record<string, unknown>) {
	ymWithCheck('userParams', {
		// Я.Метрика требует передавать id пользователя именно с таким именем
		UserID: userId,
		...params,
	});
}

export function sendLinkClick(payload: { url: string; vars?: Record<string, unknown> }) {
	sendEvent('click_link', { url: payload.url, ...payload.vars });
}
