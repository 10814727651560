import type { Orders } from '~types/orders';

export type KanbanColumnsData = {
	waiting_collecting: Orders.Order[];
	collecting: Orders.Order[];
	ready_to_deliver: Orders.Order[];
	delivering: Orders.Order[];
};

export type OrdersMetricsTypes = {
	orders: KanbanColumnsData;
	processing: number;
	completed: number;
	waiting_collecting: number;
	collecting: number;
	ready_to_deliver: number;
	delivering: number;
};

export const calculateOrdersMetrics = (data: KanbanColumnsData, completed: number): OrdersMetricsTypes => {
	return {
		orders: data,
		completed: completed ?? 0,
		processing: data.collecting.length + data.delivering.length ?? 0,
		collecting: data.collecting.length ?? 0,
		waiting_collecting: data.waiting_collecting.length ?? 0,
		ready_to_deliver: data.ready_to_deliver.length ?? 0,
		delivering: data.delivering.length ?? 0,
	};
};
