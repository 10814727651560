import api from '~/api';

export async function massiveSchetTasks(
	action: 'APPROVE' | 'DISABLE' | 'RUN' | 'FORCE_RUN',
	tasks: string[],
	onAfterLoad: () => void
) {
	try {
		switch (action) {
			case 'APPROVE':
				await api.schets.approve({ schet_task_id: tasks });
				break;
			case 'DISABLE':
				await api.schets.pause({ schet_task_id: tasks });
				break;
			case 'RUN':
				await api.schets.start({ schet_task_id: tasks });
				break;
			case 'FORCE_RUN':
				await api.schets.force_run({ schet_task_id: tasks });
				break;
		}
		onAfterLoad();
	} catch {}
}
