import type { DependencyList, EffectCallback } from 'react';
import { useEffect } from 'react';

type Destructor = () => void;
type SetDestructor = (destructor: Destructor) => void;
type AsyncEffectCallback = (cb?: SetDestructor) => Promise<ReturnType<EffectCallback>>;

export function useAsyncEffect(asyncEffect: AsyncEffectCallback, deps?: DependencyList) {
	return useEffect(() => {
		let destructor: Destructor | null = null;

		void asyncEffect((d) => {
			destructor = d;
		});

		return () => {
			destructor?.();
		};
	}, deps);
}
