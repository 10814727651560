import { Button } from 'antd';

import { useStyles } from '../styles';

interface Props {
	isDisabled?: boolean;
	taxiType?: string;
	onButtonClick: (props: { taxiOnly: boolean; taxiType?: string }) => void;
	buttonText: string;
	isSelected?: boolean;
}

const UnassignButton = ({ isDisabled = false, taxiType, onButtonClick, buttonText, isSelected = false }: Props) => {
	const { cx, classes } = useStyles();

	const onClick = () => {
		onButtonClick(taxiType ? { taxiOnly: true, taxiType } : { taxiOnly: false });
	};

	return (
		<Button
			disabled={isDisabled}
			data-test={`unassign courier${taxiType ? ` ${taxiType}` : ''}`}
			className={cx(classes.modalButtons, {
				[classes.modalButtonsTaxi]: !!taxiType,
				[classes.modalButtonsCourier]: !taxiType,
				[classes.itemSelected]: !!isSelected,
			})}
			onClick={onClick}
		>
			{buttonText}
		</Button>
	);
};

export default UnassignButton;
