import api from '~/api';
import useLoadData from '~shared/hooks/useLoadData';

import type { ViewData } from '../index';

type Args = {
	levelState: ViewData;
	clusterId?: string;
};

const useLoadCritEvents = ({ levelState, clusterId }: Args) => {
	const { loading, data } = useLoadData(
		() =>
			api.storeProblems.list({
				cluster_id: clusterId ?? '',
				[`${levelState.type}_id`]: levelState.id,
			}),
		[levelState, clusterId],
		!clusterId || levelState.type === 'company'
	);

	return { critLoading: loading, critData: data?.results };
};

export default useLoadCritEvents;
