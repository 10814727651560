import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Arrow } from '~images/icons/arrow-for-date.svg';
import type { CheckProjects } from '~types/checkProjects';

import { useStyles } from '../styles';

type Props = {
	begin: CheckProjects.CheckProject['schedule']['begin'];
	end?: CheckProjects.CheckProject['schedule']['end'];
};

const PeriodBlock = ({ begin, end }: Props) => {
	const formatTemplate = 'D MMMM YYYY';
	const { classes } = useStyles();
	const [t] = useTranslation();

	const beginString = dayjs(begin).format(formatTemplate);
	const endString = dayjs(end).format(formatTemplate);

	return (
		<div className={classes.periodBlock}>
			<span>{beginString}</span>
			{beginString !== endString && (
				<>
					<Arrow />
					{end ? <span>{endString}</span> : <span className={classes.noEndDate}>{t('без окончания')}</span>}
				</>
			)}
		</div>
	);
};

export default PeriodBlock;
