import { Button } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ModalComponent from '~shared/components/ModalComponent';
import Title from '~shared/components/Title';

import { useStyles } from '../OrdersPage/styles';
import UserCard from './UsersDataPage';

export type UserToShow = { user_id: string; fullname?: string; link?: string };

interface Props {
	userToShow?: UserToShow;
	setUserToShow: (value: UserToShow | undefined) => void;
}

const UserModal = ({ userToShow, setUserToShow }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();

	const link = userToShow?.link || `/users/${userToShow?.user_id}`;

	return (
		<ModalComponent
			modalId={userToShow?.user_id}
			className={cx(`data-test-show-user-modal-${userToShow ? 'visible' : 'hidden'}`, classes.orderModal)}
			title={
				userToShow && (
					<Title
						title={t('Просмотр сотрудника')}
						additionalButtons={[
							{
								text: t('Открыть страницу сотрудника'),
								type: 'link',
								to: link,
								dataTest: 'data test open user page from modal',
							},
						]}
						copyLink={`${window.location.origin}${link}`}
						inModal
					/>
				)
			}
			open={!!userToShow}
			onCancel={() => setUserToShow(undefined)}
			footer={
				<Button type="default" onClick={() => setUserToShow(undefined)}>
					{t('Закрыть')}
				</Button>
			}
		>
			{userToShow && <UserCard idFromProps={userToShow.user_id} inModal />}
		</ModalComponent>
	);
};

export default memo(UserModal);
