import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

const OrdersPageNew = lazy(() => import('../components/OrdersPageNew'));

const ordersNewRoutes = makeRoutes([
	{
		path: '/orders_new',
		permit: 'dispatcher',
		exp: 'exp_old_disp_off',
		alternativeElement: <Navigate replace to="/orders" />,
		element: <PrependPath />,
	},
	{
		path: '/stores/:store_id/orders_new',
		permit: 'dispatcher',
		element: <OrdersPageNew />,
		exp: 'exp_new_dispatch',
	},
]);
export default ordersNewRoutes;
