import { type DependencyList, useEffect } from 'react';

import type { Orders } from '~types/orders';
import { getPartnerPickupListOrders, usePartnerPickupOrdersData } from '~zustand/ordersData';

type UseLoadPartnerPickupList = (partnerPickupListIsOn: boolean, deps: DependencyList) => { orders: Orders.Order[] };

const useLoadPartnerPickupList: UseLoadPartnerPickupList = (partnerPickupListIsOn, deps: DependencyList) => {
	const partnerPickupOrdersData = usePartnerPickupOrdersData();

	useEffect(() => {
		if (partnerPickupListIsOn) {
			getPartnerPickupListOrders();
		}
	}, deps);

	return { orders: partnerPickupOrdersData };
};

export default useLoadPartnerPickupList;
