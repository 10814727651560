import { Tooltip } from 'antd';
import { ReactComponent as QuestionOutlined } from 'images/icons/question-outline.svg';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import { orderIndicatorTypes } from '~constants/order';
import { orderCheckValidModeNames, orderTypeNames } from '~server-types/doc/api/models/order';
import { getAcceptanceApproveTime } from '~shared/utils/datesUtils';
import { FlagOutlined } from '~shared/utils/icons';
import type { Orders } from '~types/orders';
import { useCheckExp, useCheckPermit } from '~zustand/userData';

import { checkAcceptanceApprovalTime } from './OrdersDataPage/utils';
import { OrderTypeIcon } from './OrderTypeIcon';
import { ScanIcon } from './ScanIcon';
import { useStyles } from './styles';
import { showIndicator } from './Utils/showIndicator';

type Props = {
	order: Orders.Order;
	ordersByIds: Record<string, Orders.Order | undefined>;
};

export function OrderType({ order, ordersByIds }: Props) {
	const { cx, classes: styles } = useStyles();
	const [t] = useTranslation();
	const name = orderTypeNames[order.type] ?? order.type;
	const isExpAgutin = useCheckExp('exp_agutin');
	const isPermitDispatcherApproveAcceptance = useCheckPermit('dispatcher_approve_acceptance');

	const orderCheckNumber: number | undefined = useMemo(() => {
		if (order.type !== 'inventory_check_product_on_shelf' || !order.vars?.third_party_assistance) return;
		const parentsOrders = order.parent.map((orderId: string) => ordersByIds[orderId]);
		if (parentsOrders.some((order) => !!order?.type)) {
			const parents = parentsOrders.filter(
				(order: Orders.Order | undefined) => !['inventory_check_more', 'inventory'].includes(order?.type)
			);
			return parents.length + 1;
		}
	}, [ordersByIds]);

	const cache = useCache({
		stores: order.store_id,
	});

	if (order.type === 'shipment' || ['acceptance', 'acceptance_market'].includes(order.type)) {
		const timezone = cache.stores[order.store_id ?? '']?.tz;

		const notTooLate = checkAcceptanceApprovalTime(order.vars?.all_children_done, timezone);

		const getApproveIcon = () => {
			const controlTime = getAcceptanceApproveTime(order.vars?.all_children_done, timezone);
			const tooltip = t('Необходимо подтверждение приемки до {{time}}', {
				time: controlTime?.format('YYYY.MM.DD, HH:mm:ss') ?? '—',
			});
			return <OrderTypeIcon status={order.attr?.documents_status} type="approve" tooltipTitle={tooltip} />;
		};

		return (
			<div className={styles.orderTypeWrapper}>
				<span className={styles.orderType}>{name}</span>
				<ScanIcon order={order} />
				{isExpAgutin && !order.vars?.closed && notTooLate && isPermitDispatcherApproveAcceptance && getApproveIcon()}
			</div>
		);
	}
	if (order.type === 'inventory' && order.vars?.inventory_type === 'final') {
		return (
			<div className={styles.orderTypeWrapper} data-test="order final inventory icon">
				<span className={styles.orderType}>{name}</span>
				<Tooltip title={t('Финальная инвентаризация')}>
					<FlagOutlined className={styles.icon} width={32} height={32} />
				</Tooltip>
			</div>
		);
	}

	if (showIndicator(order)) {
		return (
			<>
				<div>{name}</div>
				<div>
					{order.vars?.indicator && (
						<>
							<span className={cx(styles.orderTypeSubtitle, styles[order.vars.indicator])}>
								{orderIndicatorTypes[order.vars.indicator]}
							</span>
							{orderCheckNumber && <span className={styles.orderCheckNumber}>#{orderCheckNumber}</span>}
						</>
					)}
				</div>
			</>
		);
	}

	if (['writeoff_prepare_day', 'check_valid_short', 'check_valid_regular'].includes(order.type) && order.vars?.mode) {
		return (
			<div className={styles.orderMode}>
				<span>{name}</span>
				<Tooltip title={orderCheckValidModeNames[order.vars?.mode]}>
					<QuestionOutlined width={16} height={16} />
				</Tooltip>
			</div>
		);
	}

	return <>{name}</>;
}
