import { Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { pricePattern } from '~constants/patterns';
import { Field } from '~shared/components/Forms';

import { useStyles } from '../styles';
import { useStyles as useTextStyles } from '../TextField/styles';
import type { FieldProps } from '../types';
import { getColSpan } from '../utils';

interface Props extends FieldProps {
	className: string;
	dataTest: string;
}

const RenderInput = ({ input, className, dataTest }: Props) => {
	return (
		<Input className={className} value={input.value} onChange={input.onChange} type="number" data-test={dataTest} />
	);
};

const PriceForAmountField = () => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const { classes: textClasses } = useTextStyles({});

	return (
		<Row align="middle" gutter={[0, { xs: 0, sm: 10 }]}>
			<Col
				className={classes.labelContainer}
				{...getColSpan({
					xl: { span: 12 },
					lg: { span: 12 },
					sm: { span: 24 },
				})}
			>
				<label className={classes.label}>{`${t('Стоимость')}:`}</label>
				<div className={classes.dotSeparator} />
			</Col>
			<Col
				{...getColSpan({
					xl: { span: 12 },
					lg: { span: 12 },
					sm: { span: 24 },
				})}
			>
				<Row gutter={10}>
					<Col span={10}>
						<Field
							name="price"
							type="number"
							className={textClasses.input}
							dataTest="data form Стоимость"
							component={RenderInput}
							normalize={(event: any, previousValue: any) => {
								const value = event?.target?.value ?? event;

								if (value === '') return value;
								if (new RegExp(pricePattern).test(value)) {
									return value;
								}
								return previousValue;
							}}
						/>
					</Col>
					<Col className={classes.priceUnit} span={14}>
						за
						<Field
							name="price_unit"
							className={classes.priceUnitInput}
							type="number"
							dataTest="data form За количество"
							component={RenderInput}
							normalize={(event: any, previousValue: any) => {
								const value = event.target?.value ?? event;
								return isNaN(Number(value)) ? previousValue || '' : value;
							}}
							parse={(event: any) => {
								const value = event.target?.value ?? event;
								return isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
							}}
						/>
						ед.
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default PriceForAmountField;
