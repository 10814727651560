import { Button } from 'antd';
import type { JSX } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as RedoAltRegular } from '~images/icons/redo-alt-regular.svg';
import CopyString from '~shared/components/hoc/CopyString';
import { checkIsUnassignCourierAllowed } from '~shared/utils/checkIsUnassignCourierAllowed';
import courierName from '~shared/utils/courierName';
import Icon from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';
import type { Couriers } from '~types/couriers';
import { useCheckPermit } from '~zustand/userData';

import { useStyles } from './styles';

interface Props {
	courier?: Couriers.CourierBrief;
	id?: string;
	name?: string | null;
	openUnassignCourierModal?: () => void;
	showDispatch?: boolean;
	noCopy?: boolean;
}

const Courier = ({ courier, id, name, openUnassignCourierModal, showDispatch, noCopy = false }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();

	const isPermitCouriersLoad = useCheckPermit('couriers_load');

	const showUnAssignCourierModal = (e: any) => {
		e.stopPropagation();
		if (openUnassignCourierModal) {
			openUnassignCourierModal();
		}
	};

	const nameString = courier ? courierName(courier) : name ?? '—';
	const idString = courier ? courier.courier_id : id ?? '';

	const courierBlock = () => {
		let courierString: JSX.Element;
		if (courier ?? name ?? id) {
			courierString =
				isPermitCouriersLoad && idString ? (
					<Link
						to={`/couriers/${idString}`}
						className={classes.courierBlock}
						data-test="courier name string"
						target="_blank"
					>
						{nameString}
					</Link>
				) : (
					<span className={classes.courierBlock} data-test="courier name string">
						{nameString}
					</span>
				);
		} else {
			courierString = (
				<span className={cx(classes.notAppointText, classes.courierBlock)} data-test="courier name string">
					{t('Не назначен')}
				</span>
			);
		}

		return (
			<>
				{courierString}
				{showDispatch && checkIsUnassignCourierAllowed() && openUnassignCourierModal && (
					<Button
						onClick={(e) => showUnAssignCourierModal(e)}
						className={commonClasses.btnIcon}
						data-test="unassign courier button row"
					>
						<Icon component={RedoAltRegular} />
					</Button>
				)}
			</>
		);
	};
	return (
		<div className={classes.block}>
			{!noCopy || nameString !== '—' ? (
				<CopyString string={nameString ?? ''} tooltip={t('Скопировать имя')} success={t('Имя курьера скопировано')}>
					{courierBlock()}
				</CopyString>
			) : (
				courierBlock()
			)}
		</div>
	);
};

export default memo(Courier);
