import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ClustersEquipment = lazy(() => import('../components/Equipment/ClustersEquipment'));
const StoresEquipment = lazy(() => import('../components/Equipment/StoresEquipment'));

const clustersEquipment = makeRoutes([
	{
		path: '/clusters_equipment',
		element: <ClustersEquipment />,
		exp: 'exp_lavka4',
	},
	{
		path: '/stores/:store_id/clusters_equipment',
		element: <StoresEquipment />,
		exp: 'exp_lavka4',
	},
]);

export default clustersEquipment;
