import { notification } from '@lavka/ui-kit';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import type { Stores } from '~types/stores';
import { useCheckPermit, useUser } from '~zustand/userData';

import type { SelectFieldProps } from '../SelectField';
import MultipleEntitySelect from './index';

const MultipleStoresSelectField = ({ ...props }: SelectFieldProps) => {
	const [t] = useTranslation();
	const user = useUser();
	const isPermitOutOfCompany = useCheckPermit('out_of_company');
	const cache = useCache({});

	const searchStores = async (searchText: string, cancelToken: CancelTokenSource | undefined) => {
		try {
			cancelToken?.cancel();

			const searchData: Stores.SeekRequest = {
				cursor: '',
			};
			if (searchText) {
				searchData.title = searchText;
			}
			if (props.additionalProps?.clusterId) {
				searchData.cluster_id = props.additionalProps.clusterId;
			}
			if (!isPermitOutOfCompany) {
				searchData.company_id = user.company_id;
			}

			cancelToken = axios.CancelToken.source();
			const { data } = await api.stores.list(searchData, { cancelToken: cancelToken?.token }); // у складов title принимает как id, так и текстовое значение

			cache.addData({
				entityName: 'stores',
				data: data.results,
			});

			return data.results.map((store) => ({
				value: store.store_id,
				text: `${store.title} (${store.cluster})`,
			}));
		} catch {
			notification.error({
				message: t('Не удалось получить список складов'),
			});
		}
	};

	return (
		<MultipleEntitySelect
			entity="store"
			keyId="stores"
			linkCategory="stores"
			searchFunction={searchStores}
			valueString={t('Выбран {{count}} склад', {
				count: props.input.value?.length ?? 0,
			})}
			{...props}
		/>
	);
};

export default MultipleStoresSelectField;
