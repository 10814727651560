import type { AxiosAdditionalConfig } from 'api';
import type { AxiosResponse } from 'axios';

import mergeResponses from './mergeResponses';

export default async function getAllRecursive<TRes extends { cursor: string | null }>(
	api: (cursor: string | null, config?: AxiosAdditionalConfig) => Promise<AxiosResponse<TRes>>,
	config?: AxiosAdditionalConfig
): Promise<AxiosResponse<TRes>> {
	const responses: AxiosResponse<TRes>[] = [];
	let cursor: string | null = '';

	while (cursor !== null) {
		if (config?.signal?.aborted) {
			throw { data: {}, status: 'CANCELED' };
		}
		const response: AxiosResponse<TRes> = await api(cursor, config);
		responses.push(response);
		cursor = response.data.cursor;
	}

	if (responses.length) {
		const result = responses.shift()!;
		responses.forEach((res) => mergeResponses<TRes>(result, res));

		return result;
	}

	throw new Error();
}
