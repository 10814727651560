import { arrayUniq } from '~shared/utils/arrayUniq';
import type { CheckProjects } from '~types/checkProjects';

import type { LocationRowType } from './useGetLocationTreeFromCompany';

export const getLocationFromValue = (
	locationTree: LocationRowType[],
	selectedLocations?: string[]
): CheckProjects.CheckProject['stores'] => {
	const location: Record<'store_id' | 'cluster_id' | 'company_id', string[]> = {
		store_id: [],
		cluster_id: [],
		company_id: [],
	};

	if (!selectedLocations) return location;

	const selectedSet = new Set(selectedLocations);

	// Проверяем, есть ли среди выбранных строк компания - тогда остальное можно не проверять
	if (selectedSet.has(locationTree[0]?.key)) {
		location.company_id.push(locationTree[0].key);
		return location;
	}

	const clusters = locationTree[0]?.children ?? [];
	for (const cluster of clusters) {
		if (selectedSet.has(cluster.key)) {
			cluster.children?.forEach((store) => selectedSet.delete(store.key));
			location.cluster_id.push(cluster.key);
		} else {
			cluster.children?.forEach((store) => {
				if (selectedSet.has(store.key)) {
					location.store_id.push(store.key);
				}
			});
		}
	}

	return location;
};

export const getChildrenKeys = (children?: LocationRowType[]) => {
	const keys: string[] = [];
	if (!children) {
		return [];
	}
	children.forEach((el) => {
		keys.push(el.key);
		if (el.children?.length) {
			keys.push(...getChildrenKeys(el.children));
		}
	});
	return keys;
};

export const getValueFromLocation = (
	treeNodesIdMap: Record<string, LocationRowType>,
	location?: CheckProjects.CheckProject['stores']
): string[] => {
	const ids: string[] = [];
	if (!location) return [];
	Object.keys(location).forEach((entityType) => {
		const entitiesList = location[entityType];
		entitiesList?.forEach((entityId: any) => {
			const entity = treeNodesIdMap[entityId];
			if (entity) {
				ids.push(entityId, ...getChildrenKeys(entity.children));
			}
		});
	});

	return arrayUniq(ids);
};

export const filterLocationTree = (
	locationTree: LocationRowType[],
	filterPredicate: (entity: LocationRowType) => boolean,
	editMode: boolean
): LocationRowType[] => {
	if (editMode) {
		return locationTree;
	}

	const result: LocationRowType[] = [];

	for (const company of locationTree) {
		if (filterPredicate(company)) {
			result.push(company);
			continue;
		}

		const filteredClusters: LocationRowType[] = [];

		for (const cluster of company.children ?? []) {
			if (filterPredicate(cluster)) {
				filteredClusters.push(cluster);
				continue;
			}

			const filteredStores = cluster.children?.filter((store) => filterPredicate(store)) ?? [];

			if (filteredStores.length) {
				filteredClusters.push({
					...cluster,
					children: filteredStores,
				});
			}
		}

		if (filteredClusters.length) {
			result.push({
				...company,
				children: filteredClusters,
			});
		}
	}

	return result;
};

export const getVisibleLocationTree = (locationTree: LocationRowType[], inputValue: string[], editMode: boolean) => {
	if (inputValue.length === 0) {
		return locationTree;
	}
	const ids = new Set<string>(inputValue);
	const filterPredicate = (entity: LocationRowType) => ids.has(entity.key);

	return filterLocationTree(locationTree, filterPredicate, editMode);
};

export const filterTreeBySearchParameter = (
	locationTree: LocationRowType[],
	parameter: Exclude<keyof LocationRowType, 'children'>,
	value: string
) => {
	const filterPredicate = (entity: LocationRowType) => entity[parameter].toLowerCase().includes(value.toLowerCase());
	return filterLocationTree(locationTree, filterPredicate, false);
};

export const filterTreeByEntityType = (locationTree: LocationRowType[], value: string) => {
	if (value === 'company') {
		return [];
	}

	const result: LocationRowType[] = locationTree.map(({ children, ...company }) => {
		if (value === 'store') {
			return { ...company, children: children?.filter((cluster) => !!cluster.children?.length) };
		}
		return company;
	});
	return result;
};

export const filterTreeByPaused = (
	locationTree: LocationRowType[],
	mode: 'paused' | 'not_paused' | 'all',
	getIsStorePaused: (value: string) => boolean
) => {
	const filterPredicate = (entity: LocationRowType) => {
		if (['company', 'cluster'].includes(entity.entity_type)) {
			return false;
		}
		const isStorePaused = getIsStorePaused(entity.key);
		if (mode === 'paused') {
			return isStorePaused;
		} else if (mode === 'not_paused') {
			return !isStorePaused;
		} else {
			return true;
		}
	};
	return filterLocationTree(locationTree, filterPredicate, false);
};

export const getAllVisibleStores = (visibleTree: LocationRowType[]) => {
	return visibleTree.flatMap((company: LocationRowType) => {
		return company.children?.flatMap((cluster) => cluster.children?.map((store) => store.key) ?? []) ?? [];
	});
};

export const getAllVisibleClustersAndCompanies = (visibleTree: LocationRowType[]) =>
	visibleTree.flatMap((company) => [company.key, ...(company.children?.map((cluster) => cluster.key) ?? [])]);
