import dayjs from 'dayjs';
import { firstBy } from 'thenby';

import type { CouriersByStatusData, ProcessedCourierCardData } from '~shared/constants/tableauCouriers';
import courierName from '~shared/utils/courierName';
import { isNotNullish } from '~shared/utils/isNotNullish';
import type { KanbanColumnsData } from '~shared/utils/tableauOrdersMetrics';
import type { Couriers } from '~types/couriers';
import type { Orders } from '~types/orders';

import type { CourierCardData, OrderDataInCourier } from '../Blocks/DeliverySpace/Delivery';

const isWaitingCollecting = (order: Orders.Order) => order.status === 'request';

const isCollecting = (order: Orders.Order) => order.status === 'processing';

const isReadyToDeliver = (order: Orders.Order) =>
	order.status === 'complete' &&
	[
		'COURIER_ASSIGNED',
		'READY_FOR_DELIVERY',
		'UNCONFIRMED',
		'AWAITING_CARD_PAYMENT',
		'CALL_CENTER_CONFIRMED',
		'PLACE_CONFIRMED',
		'WAITING_ASSIGNMENTS',
	].includes(order.eda_status);

const isDelivering = (order: Orders.Order) =>
	order.status === 'complete' && ['ORDER_TAKEN', 'ARRIVED_TO_CUSTOMER', 'CUSTOMER_NO_SHOW'].includes(order.eda_status);

export const isOrderDelivered = (order: Orders.Order) =>
	order.status === 'complete' && ['DELIVERED', 'CANCELLED', 'UNKNOWN'].includes(order.eda_status);

export const getDataByColumn = (orders: Orders.Order[]): KanbanColumnsData => {
	const data: {
		waiting_collecting: Orders.Order[];
		collecting: Orders.Order[];
		ready_to_deliver: Orders.Order[];
		delivering: Orders.Order[];
	} = {
		waiting_collecting: [],
		collecting: [],
		ready_to_deliver: [],
		delivering: [],
	};

	for (const order of orders) {
		if (isWaitingCollecting(order)) {
			data.waiting_collecting.push(order);
		} else if (isCollecting(order)) {
			data.collecting.push(order);
		} else if (isReadyToDeliver(order)) {
			data.ready_to_deliver.push(order);
		} else if (isDelivering(order)) {
			data.delivering.push(order);
		}
	}

	return data;
};

export const getCourierStatusesFromOrder = (order: Orders.Order) => {
	if (isReadyToDeliver(order) || order.status === 'processing') {
		return 'collecting';
	}

	if (isDelivering(order) && order.eda_status === 'ORDER_TAKEN') {
		return 'delivering';
	}

	if (isDelivering(order) && order.eda_status === 'ARRIVED_TO_CUSTOMER') {
		return 'deliver';
	}
};

export const getProcessedCourierData = (
	orders: Orders.Order[],
	couriers: Couriers.CourierBrief[],
	couriersInfo: Record<string, Couriers.CourierBrief | undefined>
) => {
	const result: CouriersByStatusData = {
		columns: {
			free: [],
			returning: [],
			pause: [],
			deliver: [],
			delivering: [],
			collecting: [],
			noStatus: [],
		},
		activeCouriers: 0,
		allCouriers: 0,
	};

	// Собираем курьеров и их статусы из заказов
	const orderCouriersMap = orders.reduce(
		(map, order) => {
			let courierData;
			if (order?.courier_id) {
				courierData = couriersInfo[order.courier_id];
			}
			if (!order?.courier_id || !courierData) return map;

			if (!map[order.courier_id]) {
				map[order.courier_id] = {
					name: courierName(courierData, { showEatsId: false }),
					orders: [],
					courier_id: courierData.courier_id,
					delivery_type: courierData.delivery_type,
					is_rover: courierData.is_rover,
				};
			}
			map[order.courier_id].orders!.push({
				orderStatus: order.status,
				statusTime: order.status_time,
				status: getCourierStatusesFromOrder(order),
				docNumber: order.doc_number,
				deliveryPromise: order.delivery_promise,
				created: order.approved,
				edaStatus: order.eda_status,
			});

			return map;
		},
		{} as Record<string, CourierCardData>
	);

	// couriers - все курьеры на лавке в данный момент
	const couriersList: CourierCardData[] = couriers
		.map((courier) => {
			if (orderCouriersMap[courier.courier_id])
				return {
					...orderCouriersMap[courier.courier_id],
					delivery_type: courier.delivery_type,
					is_rover: courier.is_rover,
				};

			if (courier.grocery_shift_status === 'pause') {
				return {
					name: courierName(courier, { showEatsId: false }),
					courier_id: courier.courier_id,
					status: 'pause',
					lastOrderTime: courier.grocery_shift_status_time,
					checkin_time: courier.checkin_time,
					orders: [],
					delivery_type: courier.delivery_type,
					is_rover: courier.is_rover,
				};
			}

			if (
				courier.grocery_shift_status &&
				['unpause', 'open'].includes(courier.grocery_shift_status) &&
				dayjs(courier.last_order_time).isAfter(dayjs(courier.checkin_time).add(60, 's'))
			) {
				return {
					name: courierName(courier, { showEatsId: false }),
					courier_id: courier.courier_id,
					lastOrderTime: courier.last_order_time,
					status: 'returning',
					checkin_time: courier.checkin_time,
					orders: [],
					delivery_type: courier.delivery_type,
					is_rover: courier.is_rover,
				};
			}

			if (courier.grocery_shift_status !== 'close') {
				const currentTime = dayjs.max(dayjs(courier.grocery_shift_status_time), dayjs(courier?.checkin_time)).format();
				return {
					name: courierName(courier, { showEatsId: false }),
					courier_id: courier.courier_id,
					status: 'free',
					lastOrderTime: currentTime,
					checkin_time: currentTime,
					orders: [],
					delivery_type: courier.delivery_type,
					is_rover: courier.is_rover,
				};
			}
		})
		.filter(isNotNullish);

	const filteredCouriersList = couriersList.filter((courier) => courier.checkin_time !== null);

	const edaStatusesPriority = {
		ARRIVED_TO_CUSTOMER: 5,
		ORDER_TAKEN: 4,
		COURIER_ASSIGNED: 3,
		WAITING_ASSIGNMENTS: 2,
		UNCONFIRMED: 1,
	} as const;

	filteredCouriersList.forEach((courier) => {
		if (!courier) return;

		if (!courier.orders && courier.status) {
			result.columns[courier.status].push(courier);
			return;
		}

		courier.orders?.sort(
			firstBy(
				(a: OrderDataInCourier, b: OrderDataInCourier) =>
					edaStatusesPriority[a.edaStatus ?? ''] - edaStatusesPriority[b.edaStatus ?? '']
			).thenBy((a: OrderDataInCourier, b: OrderDataInCourier) =>
				dayjs(a.statusTime?.[a.edaStatus ?? '']).isBefore(dayjs(b.statusTime?.[b.edaStatus ?? ''])) ? 1 : -1
			)
		);

		if (!courier.orders?.length) {
			result.columns[courier.status ?? 'noStatus'].push(courier);
			return;
		}
		const deliveryPromise = courier.orders[0].deliveryPromise;

		const courierData: ProcessedCourierCardData = {
			name: courier.name,
			courier_id: courier.courier_id,
			status: courier.orders[0].status,
			docNumbers: courier.orders.map((order) => order.docNumber),
			deliveryPromise,
			created: courier.orders[0].created,
			delivery_type: courier.delivery_type,
			is_rover: courier.is_rover ? courier.is_rover : undefined,
		};

		if (courier.orders[0].status === 'collecting') {
			const checkinTime = dayjs(courier.checkin_time);
			const closestOrderAssignedTime = dayjs(courier.orders[0].statusTime?.COURIER_ASSIGNED);
			courierData.deliveryPromise = dayjs.min([checkinTime, closestOrderAssignedTime]).format();
		}
		if (['deliver', 'delivering'].includes(courier.orders[0].status)) {
			const statusTimes = Object.values(courier.orders[0].statusTime ?? {});
			courierData.deliveryPromise = statusTimes.length
				? dayjs.max(statusTimes.map((el) => dayjs(el))).format()
				: deliveryPromise;
		}

		result.columns[courier.orders[0].status ?? 'noStatus'].push(courierData);
	});

	result.activeCouriers =
		result.columns.collecting.length + result.columns.deliver.length + result.columns.delivering.length;
	result.allCouriers =
		result.activeCouriers + result.columns.free.length + result.columns.pause.length + result.columns.returning.length;

	return result;
};
