import { useStyles } from './styles';

export function StartScreen() {
	const { classes } = useStyles();

	return (
		<div className={classes.container}>
			<h1 className={classes.label} style={{}}>
				🍅 GPT
			</h1>
		</div>
	);
}
