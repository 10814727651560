import { Spin } from 'antd';
import type { apiLoadProperties } from 'multiSelect/initMultiSelect';

import { type EntityMap, type EntityName } from '~cache/apiLoadProperties';
import EntityLink from '~shared/components/Fields/MultipleEntitySelect/UnifiedMultiSelect/EntityLink';
import { useStyles } from '~shared/components/Fields/MultipleEntitySelect/UnifiedMultiSelect/styles';

export default function EntitiesListToDisplay<T extends EntityName>({
	ids,
	selectEntity,
	listDataTest,
	loading,
	dictionary,
	entityProperties,
}: {
	ids: string[];
	selectEntity: T;
	listDataTest?: string;
	loading?: boolean;
	dictionary: Record<string, EntityMap[T] | undefined>;
	entityProperties: (typeof apiLoadProperties)[T];
}) {
	const { classes } = useStyles();

	if (!ids.length) return;
	if (loading) return <Spin size="small" />;
	return (
		<ul className={classes.entitiesList} data-test={listDataTest ?? `entities list ${selectEntity} view`}>
			{ids.map((id) => {
				const entityInstance = dictionary[id];

				// @ts-ignore
				const title = entityInstance ? entityProperties.title(entityInstance) : '';

				return (
					<li key={id} className={classes.editModeListEntities} data-test={`${selectEntity} list item ${title}`}>
						<EntityLink entity={selectEntity} id={id} title={title ?? id} />
					</li>
				);
			})}
		</ul>
	);
}
