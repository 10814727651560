import { makeStyles } from '~styles/theme';

import { mainBackground } from '../../styles';

export const useStyles = makeStyles()((theme) => ({
	layout: {
		margin: 0,
		marginInlineStart: -16,
		blockSize: '100vh',
		display: 'flex',
		inlineSize: '100vw',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: mainBackground,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minInlineSize: 375,
		textAlign: 'center',
	},
	headerText: {
		fontSize: '5vh',
		color: theme.text.white,
		inlineSize: '45%',
	},
	authKeyBlock: {
		paddingBlock: '1vw',
		paddingInline: '3vw',
		border: '1px solid ' + theme.border.secondary,
		borderRadius: 15,
		fontSize: '10vh',
		fontWeight: 'bold',
		color: theme.text.white,
	},
	logoutButton: {
		cursor: 'pointer',
	},
}));
