import { notification } from '@lavka/ui-kit';

import type { Suptools } from '~types/suptools';

import { compliments } from '../constants';

export const generateRequest = (handler: string, args: string[]): Suptools.SearchHandler => {
	const request = {
		handler,
		kwargs: {},
	} as Suptools.SearchHandler;

	for (let i = 0; i < args.length; i += 2) {
		request.kwargs[args[i]] = args[i + 1];
	}

	return request;
};

export const validateSearch = (handler: string, kwargs: string[], handlers: Suptools.SearchHandler[] | null) => {
	if (handler === 'special') {
		return true;
	}

	if (handlers === null) {
		return false;
	}

	const handlerTypes = [...handlers.map((el) => el.handler), 'wms'];

	if (!handlerTypes.includes(handler)) {
		notification.error({ message: 'Выберите корректный тип поиска' });
		return false;
	}

	const argKeys = kwargs.filter((kw, i) => i % 2 === 0);

	if (handler === 'wms') {
		if (!argKeys.length) {
			notification.error({ message: 'Нужно больше аргументов' });
			return false;
		} else if (argKeys.length !== 1) {
			notification.error({ message: 'Что-то вы не то написали' });
			return false;
		}
		return true;
	}

	const handlerTemplate = handlers.find((el) => el.handler === handler);

	if (!handlerTemplate) {
		notification.error({ message: 'Выберите корректный тип поиска' });
		return false;
	}
	const templateArgs = Object.keys(handlerTemplate.kwargs);

	let isValid = true;
	for (const arg of templateArgs) {
		if (!argKeys.includes(arg)) {
			notification.error({ message: `Нужен аргумент ${arg}` });
			isValid = false;
		}
	}
	return isValid;
};

export const getRandomCompliment = () => {
	const rand = Math.floor(Math.random() * (compliments.length - 1));
	return compliments[rand];
};
