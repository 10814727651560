import type { CancelTokenSource } from 'axios';
import type { MutableRefObject } from 'react';
import { createContext } from 'react';

import type { Assortments } from '~types/assortments';
import type { Products } from '~types/products';

import type { RegradingProducts } from './types';

export type RegradingContextType = {
	products: RegradingProducts<Products.Product | null | undefined>;
	inAssortments: RegradingProducts<Assortments.Product | null | undefined>;
	price: RegradingProducts<{
		store: string | null;
		markdown: string | null;
	} | null>;
	cancelTokenSource?: MutableRefObject<CancelTokenSource | undefined>;
};

export const RegradingContext = createContext<RegradingContextType>({
	products: { old: null, regrade: null },
	inAssortments: { old: null, regrade: null },
	price: { old: { store: null, markdown: null }, regrade: { store: null, markdown: null } },
	cancelTokenSource: undefined,
});
