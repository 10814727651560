import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { Language } from '~i18n/types';
import type { User } from '~types/user';

export default {
	authPrepare: (data: { provider: string; invite?: string; redirect_uri?: string }, config?: AxiosAdditionalConfig) =>
		axios.post<{ url: string }>('/api/profile/auth-prepare', data, config),
	authDone: (data: { state: string; code: string }) => axios.post('/api/profile/auth-done', data),
	logout: () => axios.post('/api/profile/logout', {}),
	options: () => axios.get<User.UserData>('api/profile/options'),
	options_save: (data: { lang: Language }) => axios.post('api/profile/options_save', data),
	make_invite: (data: { email: string; ttl: number; role?: string }) => {
		return axios.post('/api/profile/make-invite', data);
	},
};
