import { Checkbox, Radio, Table } from 'antd';
import type { TableRowSelection } from 'antd/es/table/interface';
import type { ColumnType } from 'antd/lib/table';
import type { JSX } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useDebouncedCallback from '~shared/hooks/useDebounce';
import { arrayUniq } from '~shared/utils/arrayUniq';
import columnSearch from '~shared/utils/columnSearch/';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { useUser } from '~zustand/userData';

import type { FieldProps } from '../types';
import { useStyles } from './styles';
import type { LocationRowType, TreeNodesIdMap } from './useGetLocationTreeFromCompany';
import useGetLocationTreeFromCompany from './useGetLocationTreeFromCompany';
import useStoresInfo from './useStoresInfo';
import {
	filterTreeByEntityType,
	filterTreeByPaused,
	filterTreeBySearchParameter,
	getAllVisibleClustersAndCompanies,
	getAllVisibleStores,
	getLocationFromValue,
	getValueFromLocation,
	getVisibleLocationTree,
} from './utils';

const typeDictionary = makeDynamicTranslations({
	company: tDynamic('Компания'),
	store: tDynamic('Склад'),
	cluster: tDynamic('Кластер'),
});

const pauseModeOptions = makeDynamicTranslations({
	all: tDynamic('Все'),
	paused: tDynamic('На паузе'),
	not_paused: tDynamic('Без паузы'),
});

export interface LocationTreeFieldProps extends FieldProps {
	additionalButton?: JSX.Element;
	setLocations?: (map: TreeNodesIdMap) => void;
}

const LocationTreeField = ({ input, editMode, additionalButton, meta, setLocations }: LocationTreeFieldProps) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const [searchValues, setSearchValues] = useState<Record<'title' | 'external_id' | 'entity_type', string | undefined>>(
		{
			title: '',
			external_id: '',
			entity_type: undefined,
		}
	);
	const [showPausedMode, setShowPauseMode] = useState<keyof typeof pauseModeOptions>('all');
	const [showOnlyPicked, toggleShowOnlyPicked] = useState<boolean>(!editMode);
	const [visibleTree, setVisibleTree] = useState<LocationRowType[]>([]);
	const user = useUser();

	const { locationTree, loading: locationLoading, treeNodesIdMap } = useGetLocationTreeFromCompany(user.company_id);

	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(getValueFromLocation(treeNodesIdMap, input.value));
	const [expandedKeys, setExpandedKeys] = useState<string[]>(getAllVisibleClustersAndCompanies(visibleTree));

	useEffect(() => {
		setSelectedRowKeys(getValueFromLocation(treeNodesIdMap, input.value));
	}, [treeNodesIdMap, input.value]);

	useEffect(() => {
		setLocations?.(treeNodesIdMap);
	}, [treeNodesIdMap]);

	const {
		pausedCountClusterMap,
		pausedCountCompanyMap,
		getIsStorePaused,
		storesLoading,
		isEntityPartiallyPicked,
		isEntityFullyPicked,
	} = useStoresInfo({
		inputValue: editMode ? [] : selectedRowKeys,
		visibleTree,
		companyId: user.company_id,
		pauseCondition: (store) => store.status !== 'active' || !store.options.exp_big_brother,
	});

	useEffect(() => {
		let resultTree = locationTree;
		let expandedKeys = getAllVisibleClustersAndCompanies(resultTree);
		if (showOnlyPicked) {
			resultTree = getVisibleLocationTree(resultTree, selectedRowKeys, editMode);
		}
		if (['paused', 'not_paused'].includes(showPausedMode)) {
			resultTree = filterTreeByPaused(resultTree, showPausedMode, getIsStorePaused);
		}
		if (searchValues.title) {
			resultTree = filterTreeBySearchParameter(resultTree, 'title', searchValues.title);
		}
		if (searchValues.external_id) {
			resultTree = filterTreeBySearchParameter(resultTree, 'external_id', searchValues.external_id);
		}
		if (searchValues.entity_type) {
			expandedKeys = getAllVisibleClustersAndCompanies(filterTreeByEntityType(resultTree, searchValues.entity_type));
		}
		setVisibleTree(resultTree);
		setExpandedKeys(expandedKeys);
	}, [selectedRowKeys, editMode, locationTree, searchValues, showPausedMode, showOnlyPicked]);

	const useGetColumnSearchStatusProps = columnSearch(searchValues, setSearchValues, 'text');
	const typeSearchProps = columnSearch(
		searchValues,
		setSearchValues,
		'radio',
		Object.entries(typeDictionary).map(([value, label]) => ({
			label,
			value,
		}))
	);

	const titleSearchProps = useGetColumnSearchStatusProps('title');
	const externalIdSearchProps = useGetColumnSearchStatusProps('external_id');

	const debouncedOnChange = useDebouncedCallback(
		(value?: string[]) => {
			input.onChange(getLocationFromValue(locationTree, value));
		},
		400,
		[input.onChange, locationTree]
	);

	const pickedIdsSet = useMemo(() => new Set(selectedRowKeys), [selectedRowKeys]);

	const columns: ColumnType<LocationRowType>[] = [
		{
			title: t('Название'),
			dataIndex: 'title',
			key: 'title',
			render: (text, row) => {
				let paused, label;
				if (showPausedMode !== 'not_paused') {
					switch (row.entity_type) {
						case 'store':
							paused = getIsStorePaused(row.key);
							label = <span className={classes.pauseLabel}>{t('на паузе')}</span>;
							break;
						case 'cluster':
							paused = pausedCountClusterMap[row.key];
							label = <span className={classes.pauseLabel}>{t('{{paused}} на паузе', { paused })}</span>;
							break;
						case 'company':
							paused = pausedCountCompanyMap[row.key];
							label = <span className={classes.pauseLabel}>{t('{{paused}} на паузе', { paused })}</span>;
							break;
					}
				}

				const entityToLinkTypeMap = {
					store: 'stores',
					cluster: 'clusters',
					company: 'companies',
				};

				const isPicked = pickedIdsSet.has(row.key) || isEntityFullyPicked(row.entity_type, row.key);
				const isPartiallyPicked =
					!isPicked &&
					['cluster', 'company'].includes(row.entity_type) &&
					isEntityPartiallyPicked(row.entity_type, row.key);

				return (
					<div className={classes.locationRow}>
						<Link
							to={`/${entityToLinkTypeMap[row.entity_type]}/${row.key}`}
							className={cx(classes.locationTitle, { [classes.pickedLocation]: isPicked || isPartiallyPicked })}
						>
							{!editMode && (
								<Checkbox
									data-test={`view checkbox ${row.key}${isPicked ? ' checked' : ''}${
										isPartiallyPicked ? ' indeterminate' : ''
									}`}
									disabled
									checked={isPicked || isPartiallyPicked}
									indeterminate={isPartiallyPicked}
								/>
							)}
							<span>{text}</span>
						</Link>
						{!!paused && <span>{label}</span>}
					</div>
				);
			},
			...titleSearchProps,
		},
		{
			title: t('Внешний ID'),
			dataIndex: 'external_id',
			key: 'external_id',
			width: 300,
			render: (text) => text ?? '—',
			...externalIdSearchProps,
		},
		{
			title: t('Тип'),
			dataIndex: 'entity_type',
			key: 'entity_type',
			width: 100,
			render: (_, record) => typeDictionary[record.entity_type] ?? '—',
			...typeSearchProps('entity_type'),
		},
	];

	const rowSelection: TableRowSelection<LocationRowType> = {
		onChange: (newSelectedRowKeys) => {
			if (searchValues.title || searchValues.external_id) {
				newSelectedRowKeys = newSelectedRowKeys.filter((key) => treeNodesIdMap[String(key)].entity_type === 'store');
				const visibleIds = new Set([
					...getAllVisibleStores(visibleTree),
					...getAllVisibleClustersAndCompanies(visibleTree),
				]);
				const prevSelectedKeys = selectedRowKeys.filter((key) => !visibleIds.has(key));
				newSelectedRowKeys = arrayUniq([...newSelectedRowKeys, ...prevSelectedKeys]);
			}
			setSelectedRowKeys(newSelectedRowKeys as string[]);
			debouncedOnChange(newSelectedRowKeys as string[]);
		},
		getCheckboxProps: () => ({ disabled: !editMode }),
		checkStrictly: false,
		selectedRowKeys,
	};

	return (
		<>
			{meta.error && <p className={classes.inputTextError}>{meta.error}</p>}
			<div className={classes.pauseFilter}>
				<div>
					<Radio.Group
						onChange={(e) => setShowPauseMode(e.target.value)}
						data-test="show paused mode select"
						value={showPausedMode}
						optionType="button"
						options={Object.entries(pauseModeOptions).map(([value, label]) => ({ value, label }))}
					/>
					{additionalButton}
				</div>
				{!editMode && (
					<Checkbox
						defaultChecked={showOnlyPicked}
						value={showOnlyPicked}
						onChange={(e) => toggleShowOnlyPicked(e.target.checked)}
						data-test="show only picked checkbox"
					>
						{t('Показать только выбранные')}
					</Checkbox>
				)}
			</div>
			<Table
				expandable={{
					defaultExpandAllRows: true,
					expandedRowKeys: expandedKeys,
					onExpand: (expanded, record) => {
						setExpandedKeys(
							expanded ? [...expandedKeys, record.key] : expandedKeys.filter((key) => key !== record.key)
						);
					},
				}}
				tableLayout="auto"
				columns={columns}
				loading={locationLoading || storesLoading}
				rowKey="key"
				data-test="location field table"
				dataSource={visibleTree}
				rowSelection={editMode ? rowSelection : undefined}
			/>
		</>
	);
};

export default LocationTreeField;
