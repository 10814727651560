import { ProTable } from '~shared/ui-kit/ProTable';
import type { Orders } from '~types/orders';

import { dataTest } from './pageConfig';

interface Props {
	orders: Orders.Order[];
	tableConfig: any;
}

const OrdersTable = ({ orders, tableConfig }: Props) => {
	return <ProTable dataSource={orders} {...tableConfig} data-test={dataTest.table.orders} />;
};

export default OrdersTable;
