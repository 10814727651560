import { memo } from 'react';

import { useCache } from '~cache/useCache';
import { useUser } from '~zustand/userData';

import Order from '../../../components/OrdersPage/OrdersDataPage/OrdersViewDataPage';
import { useStyles } from '../../../components/OrdersPage/styles';
import ModalComponent from '../ModalComponent';
import { OrderModalTitle } from './OrderModalTitle';

interface Props {
	orderIdToShow: string | undefined;
	setOrderIdToShow: (orderId: string | undefined) => void;
	highlightProductId?: string;
}

const OrderModal = ({ orderIdToShow, setOrderIdToShow, highlightProductId }: Props) => {
	const { cx, classes } = useStyles();
	const cache = useCache({
		orders: orderIdToShow,
	});
	const user = useUser();

	const storeId = user.store_id;
	const orderToShow = orderIdToShow ? cache.orders[orderIdToShow] : undefined;

	return (
		<ModalComponent
			modalId={orderIdToShow}
			className={cx(`data-test-show-order-modal-${orderIdToShow ? 'visible' : 'hidden'}`, classes.orderModal)}
			title={<OrderModalTitle order={orderToShow} store_id={storeId} />}
			open={!!orderIdToShow}
			onCancel={() => setOrderIdToShow(undefined)}
			destroyOnClose={true}
			footer={
				<div className={classes.modalFooter}>
					<div
						data-test="modal footer"
						id={`order-modal-footer-${orderIdToShow}`}
						className={classes.modalFooterControls}
					/>
				</div>
			}
		>
			{orderIdToShow && <Order orderIdFromProps={orderIdToShow} highlightProductId={highlightProductId} inModal />}
		</ModalComponent>
	);
};

export default memo(OrderModal);
