import { useEffect, useMemo } from 'react';

import { useCache } from '~cache/useCache';
import { sortOrdersData } from '~shared/utils/sortOrdersData';
import type { Orders } from '~types/orders';
import { getCourierCheckins } from '~zustand/couriersData';
import { getOrders } from '~zustand/ordersData';
import { useCheckPermit, useUser } from '~zustand/userData';

import addTagsToOrder from '../../components/MonitorTableau/utils/addTagsToOrder';
import { getProcessedCourierData } from '../../components/MonitorTableau/utils/processCourierData';
import { filterOrders } from '../../components/OrdersPage';
import useCouriersMonitor from './useCouriersMonitor';
import useGetUserStoreCluster from './useGetCluster';

interface OrdersData {
	orders: Orders.Order[];
}

const useProcessedCourierAndOrderData = (ordersData: OrdersData) => {
	const user = useUser();
	const { store, cluster } = useGetUserStoreCluster();
	const isTv = user.role === 'tv_device';
	const isPermiOrdersIncomplete = useCheckPermit('orders_incomplete');
	const loadIncomplete = isTv ? true : isPermiOrdersIncomplete;

	useEffect(() => {
		if (!store?.store_id) return;
		const timeout = 30 * 60 * 1000; // раз в полчаса принудительно перезапрашиваем курьеров и заказы

		const interval = setInterval(() => {
			getOrders({ loadIncomplete });
			getCourierCheckins({ storeId: store?.store_id });
		}, timeout);

		return () => {
			clearInterval(interval);
		};
	}, [store?.store_id]);

	const { couriersData } = useCouriersMonitor(user.store_id ?? '');

	const storeCouriers = couriersData.couriers.filter((courier) => courier.checkin_store_id === user.store_id);

	const cache = useCache({
		stores: user.store_id,
		couriersBrief: ordersData.orders.map((order) => order.courier_id),
		userExecutors: ordersData.orders.flatMap((order) => order.users),
		shelves: {
			ids: ordersData.orders.flatMap((order) => order.shelves),
			_fields: ['shelf_id', 'type', 'tags'],
		},
		clusters: cluster?.cluster_id,
	});

	// Фильтруем и добавляем к заказам дополнительную информацию, чтобы дочерним компонентам не запрашивать лишнего
	const orders = useMemo(() => {
		const sortedOrders = sortOrdersData(filterOrders(ordersData.orders), false);
		// Выкидываем ордеры на выпек
		const ordersNoKitchenProd = sortedOrders.filter((order) => order.type !== 'kitchen_prod');
		return ordersNoKitchenProd.map((order) => addTagsToOrder(order, cluster, cache.shelves));
	}, [ordersData.orders, cluster, cache.shelves]);

	const couriers = getProcessedCourierData(orders, storeCouriers, cache.couriersBrief);

	return {
		couriers,
		orders,
	};
};

export default useProcessedCourierAndOrderData;
