import { PageHeaderButton } from '@lavka/ui-kit';
import { Button, Tooltip } from 'antd';
import type { TooltipProps } from 'antd/es/tooltip';
import type { JSX, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useStyles as useCommonStyles } from '~styles/common';

interface CommonTypes {
	text?: string;
	condition?: boolean;
	dataTest?: string;
	disabled?: boolean;
	hideText?: boolean;
	icon?: ReactNode;
	className?: string;
	description?: string;
	additionalProps?: {
		danger?: boolean;
	};
	tooltip?: TooltipProps;
	inHeader?: boolean;
}

interface ButtonType extends CommonTypes {
	type: 'button';
	action?: () => void;
}

export interface LinkType extends CommonTypes {
	type: 'link';
	to: string;
	target?: '_blank';
}

export interface ComponentType {
	type: 'component';
	condition?: boolean;
	component: JSX.Element;
}

export type PrimaryButton = ButtonType | LinkType | ComponentType;

interface Props {
	button: PrimaryButton;
	inHeader?: boolean;
	className?: string;
}

const PrimaryButtonComponent = ({ button, inHeader, className }: Props) => {
	const { cx, classes: commonClasses } = useCommonStyles();

	if (button.condition === false) {
		return null;
	}

	if (button.type === 'component') {
		return button.component;
	}

	let element = inHeader ? (
		<PageHeaderButton
			view="header-accent"
			variant={button.icon && (button.hideText || !button.text) ? 'icon' : 'default'}
			data-test={button.dataTest}
			onClick={button.type === 'button' ? () => button.action?.() : undefined}
			disabled={button.disabled}
			iconLeft={button.icon}
			className={cx(button.className, className)}
		>
			{!button.hideText && button.text}
		</PageHeaderButton>
	) : (
		<Button
			icon={button.icon}
			data-test={button.dataTest}
			disabled={button.disabled}
			className={cx(
				button.className === undefined ? 'ant-btn ant-btn-primary ant-btn-background-ghost' : button.className,
				{
					[commonClasses.btnIconBordered]: !!button.icon,
				},
				className
			)}
			onClick={button.type === 'button' ? () => button.action?.() : undefined}
			{...button?.additionalProps}
		>
			{!button.hideText && !button.icon && button.text}
		</Button>
	);

	if (button.type === 'link') {
		element = (
			<Link to={button.to} target={button.target}>
				{element}
			</Link>
		);
	}

	if (button.tooltip) {
		element = <Tooltip {...button.tooltip}>{element}</Tooltip>;
	}

	return element;
};

export default PrimaryButtonComponent;
