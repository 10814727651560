import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	userAvatarWrapper: {
		gridArea: 'avatar',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		inlineSize: '100%',
		blockSize: 'auto',
		borderRadius: '50%',
		color: theme.text.white,
		fontSize: '0.9em',
	},
	userName: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		inlineSize: '2em',
		blockSize: '2em',
		borderRadius: '50%',
		backgroundColor: 'rgba(255, 255, 255, 0.1)',
		color: theme.text.white,
	},
	iconAvatarContainer: {
		inlineSize: '100%',
		blockSize: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
	},
}));
