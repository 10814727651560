import { blue } from '@ant-design/colors';

import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

const TRIANGLE_HEIGHT = 12;

export const useStyles = makeStyles()(() => ({
	placemarkBalloonContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	topRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	ordersMapContainer: {
		marginBlockEnd: 30,
	},
	markContainer: {
		position: 'relative',
		inlineSize: 'fit-content',
		blockSize: 0,
		backgroundColor: 'purple',
		cursor: 'auto',
	},
	mark: {
		position: 'absolute',
		insetBlockStart: 0,
		insetInlineStart: '-50%',
		zIndex: -210,
		transform: 'translateX(-50%)',
		blockSize: 12,
		filter: 'drop-shadow(0 4px 2px rgba(178,178,178,.4))',
		'&>.triangle': {
			position: 'absolute',
			insetInlineStart: '50%',
			transform: 'translate(-50%, -100%)',
			inlineSize: 25,
			blockSize: TRIANGLE_HEIGHT,
			clipPath: 'polygon(0% 0%,50% 100%,100% 0%)',
			cursor: 'pointer',
		},
		'&>.rectangle': {
			transform: `translateY(calc(-100% - ${TRIANGLE_HEIGHT - 1}px))`,
			paddingBlock: '2px',
			paddingInline: '10px',
			inlineSize: 'fit-content',
			border: '3.5px solid',
			borderRadius: 30,
			whiteSpace: 'nowrap',
			fontSize: 13,
			cursor: 'pointer',
			userSelect: 'none',
		},
	},
	clusterInfoWindowContainer: {
		maxBlockSize: 286,
		display: 'flex',
	},
	clusterInfoWindowLeft: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
		'&>a': {
			paddingBlock: '4px',
			paddingInline: '10px',
			display: 'block',
			borderRadius: '8px 0 0 8px',
			color: blue[6],
			'&.active': {
				backgroundColor: gray[4],
				color: gray[9],
				cursor: 'default',
			},
		},
	},
	clusterInfoWindowRight: {
		paddingInlineStart: 10,
		minInlineSize: 230,
		borderInlineStart: `solid 1px ${gray[4]}`,
	},
	thinScroll: {
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			inlineSize: 12,
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: 20,
			border: '3px solid white',
			backgroundColor: gray[4],
		},
	},
}));
