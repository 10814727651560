import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useExportFile } from '~shared/hooks/useExportFile';

export default function (storeId: string) {
	const [t] = useTranslation();

	async function loadFile(): Promise<void> {
		try {
			await api.schets.export_csv({ storeId: storeId });
		} catch {}
	}

	const { sendStartSignal, loading } = useExportFile({
		subscribeKey: ['export_schets', 'store', storeId],
		eventType: 'export_schet_tasks_end',
		loadFile,
		fileName: `schets_from_store_${storeId}.csv`,
		notifications: {
			wait: t('Выгрузка заданий склада запущена. Пожалуйста, дождитесь готовности данных'),
			success: t('Файл с заданиями склада успешно загружен'),
			error: t('Не удалось загрузить файл с заданиями скалада'),
		},
	});

	return { sendSchetsExportSignal: sendStartSignal, loading };
}
