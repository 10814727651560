import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { Stores } from '~types/stores';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<Stores.SampleListRequest, Stores.Sample>('/api/admin/samples/list'),
	load: makeLoadFn<Stores.Sample, 'sample_id'>('/api/admin/samples/load', 'sample_id'),
	save: (data: Stores.Sample) => axios.post<{ result: Stores.Sample }>('/api/admin/samples/save', data),

	listStores: (data: { sample_id: string }) =>
		axios.post<{ result: Stores.Store[] }>('/api/admin/samples/list_stores', data),

	changeStores: (
		data: { sample_id: string; add_ids: string[]; remove_ids: string[] },
		config?: AxiosAdditionalConfig
	) => axios.post<EmptyObject>('/api/admin/samples/change-stores', data, config),
};
