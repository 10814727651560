import { Button } from '@lavka/ui-kit';
import { ErrorPage } from '@lavka/ui-kit/lib/esm/components/ErrorPage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const [t] = useTranslation();
	const navigate = useNavigate();
	return (
		<ErrorPage
			errorCode="404"
			title={t('Страница не найдена')}
			subtitle={t('Такой страницы не существует')}
			actions={
				<Button
					fullWidth={true}
					onClick={() => {
						navigate('/');
					}}
					data-testid="go home button"
				>
					{t('Вернуться в главное меню')}
				</Button>
			}
		/>
	);
};

export default NotFound;
