export const getBrowser = () => {
	const userAgent = navigator.userAgent;
	let tem;
	let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if (/trident/i.test(match[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
		return { name: 'IE', version: tem[1] || '' };
	}
	if (match[1] === 'Chrome') {
		tem = userAgent.match(/\bEdg\/(\d+)/);
		if (tem != null) {
			return { name: 'Edge(Chromium)', version: tem[1] };
		}
		tem = userAgent.match(/\bOPR\/(\d+)/);
		if (tem != null) {
			return { name: 'Opera', version: tem[1] };
		}
	}
	match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
	if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
		match.splice(1, 1, tem[1]);
	}
	return {
		name: match[0],
		version: match[1],
	};
};

export const isBrowserOld = () => {
	const oldBrowserVersions: Record<string, number> = {
		'Edge(Chromium)': 92,
		Opera: 78,
		Chrome: 92,
		Safari: 15,
		IE: 100,
		Firefox: 90,
	};
	const browserData = getBrowser();
	return Object.keys(oldBrowserVersions).some(
		(browser) => browserData.name === browser && Number(browserData.version) <= oldBrowserVersions[browser]
	);
};
