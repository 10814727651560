import dayjs from 'dayjs';

import { equipmentDisabledReasonsNames } from '~server-types/doc/api/admin/equipments';
import { stopListReasonsNames } from '~server-types/doc/api/models/order';
import { storeDisabledReasonNames } from '~server-types/doc/api/models/store';
import cutLongText from '~shared/utils/cutLongText';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

import type { Trouble } from '../../healthTypes';

export type ViewTrouble = {
	storeId: string;
	orderId?: string;
	viewTroubleType: string;
	time: string;
	date: string;
	action: 'order' | 'store';
	created: string;
	beginOfProblem?: string;
};

const getTroubleView = (trouble: Trouble, i: number): ViewTrouble => {
	const { order_id, document_reason, document_equipments } = trouble.details?.[0] ?? {};

	// Проблема приходит в UTC +00:00, делаем поправку на таймзону автоматически
	const troubleView = {
		storeId: trouble.store_id,
		time: dayjs(trouble.begin_of_problem).format('HH:mm'),
		date: dayjs(trouble.begin_of_problem).format('DD MMMM YYYY'),
		beginOfProblem: trouble.begin_of_problem,
		action: 'order' as const,
		orderId: order_id,
		created: trouble.created,
		index: i,
	};

	if (trouble.order_type === 'stop_list') {
		return makeDynamicTranslations({
			...troubleView,
			viewTroubleType: tDynamic('Стоп-лист{{dot}}{{reason}}', {
				dot: document_reason ? '. ' : '',
				reason: cutLongText(stopListReasonsNames[document_reason], 40),
			}),
		});
	}

	if (['hide_and_show', 'turnoff_starbucks'].includes(trouble.order_type)) {
		return makeDynamicTranslations({
			...troubleView,
			viewTroubleType:
				document_equipments || document_reason
					? tDynamic('{{equipment}}{{dot}}{{reason}}', {
							equipment: document_equipments?.[0],
							dot: document_reason ? '. ' : '',
							reason: cutLongText(equipmentDisabledReasonsNames[document_reason], 40),
						})
					: '',
		});
	}

	if (trouble.reason === 'store_disabled') {
		const disabledReason = trouble.details[0].store_disabled_reason;
		return makeDynamicTranslations({
			...troubleView,
			viewTroubleType: tDynamic('Склад не работает{{dot}}{{reason}}', {
				dot: disabledReason ? '. ' : '',
				reason: disabledReason ? cutLongText(storeDisabledReasonNames[disabledReason], 40) : '',
			}),
			action: 'store',
		});
	}

	return {
		...troubleView,
		viewTroubleType: trouble.reason,
	};
};

export default getTroubleView;
