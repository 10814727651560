import { notification } from '@lavka/ui-kit';
import { Header } from '@lavka/ui-kit/lib/esm/business-components/PageHeader/components/Header';
import { Tooltip } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { useCache } from '~cache/useCache';
import { tabletMaxWidth } from '~constants/breakPoints';
import { storeEstatusNames } from '~server-types/doc/api/models/store';
import { HeaderCenter } from '~shared/components/Layout/HeaderCenter';
import type { HeaderInformerProps } from '~shared/components/Layout/HeaderInformer';
import { HeaderInformer } from '~shared/components/Layout/HeaderInformer';
import { HeaderLeft } from '~shared/components/Layout/HeaderLeft';
import { HeaderRight } from '~shared/components/Layout/HeaderRight';
import { useUser } from '~zustand/userData';

import { useStyles } from './styles';

export const LayoutHeader = () => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const user = useUser();
	const isTablet = useMediaQuery({
		query: `(max-width: ${tabletMaxWidth}px)`,
	});

	const cache = useCache({
		stores: user.store_id,
	});

	const estatus = cache.stores[user.store_id ?? '']?.estatus;

	const prevInventoryModeStatus = useRef(estatus);

	useEffect(() => {
		if (estatus === 'inventory_locked') {
			notification.warning({
				message: storeEstatusNames['inventory_locked'],
				description: t('В этот период часть операций будет недоступна. Это может занять до 15 минут.'),
			});
		}
		if (estatus === 'inventory' && prevInventoryModeStatus.current === 'inventory_locked') {
			notification.success({
				message: t('Полная инвентаризация завершена'),
				description: t('Все операции вновь доступны.'),
			});
		}
		prevInventoryModeStatus.current = estatus;
	}, [estatus]);

	let informerProps: HeaderInformerProps | null = null;
	if (estatus === 'inventory') {
		informerProps = {
			type: 'warning',
			content: <span data-test="inventory mode text">{t('Склад в режиме инвентаризации')} </span>,
		};
	}
	if (estatus === 'inventory_locked') {
		informerProps = {
			type: 'warning',
			content: (
				<Tooltip title={t('В этот период часть операций будет недоступна. Это может занять до 15 минут.')}>
					<span data-test="inventory locked mode text">{storeEstatusNames['inventory_locked']}</span>
				</Tooltip>
			),
		};
	}

	return (
		<>
			<Header contentLeft={!isTablet ? <HeaderLeft /> : null} contentRight={<HeaderRight />} className={classes.header}>
				<HeaderCenter />
			</Header>
			{informerProps && <HeaderInformer {...informerProps} />}
		</>
	);
};
