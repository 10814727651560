import { notification } from '@lavka/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import ModalComponent from '~shared/components/ModalComponent';
import PrimaryButtonComponent from '~shared/components/PrimaryButtonComponent';
import { useStyles } from '~styles/theme';

type Props = {
	collects: string[];
	setCollectsToClose: (value: string[]) => void;
};

const CompleteCollectsBtn = ({ collects, setCollectsToClose }: Props) => {
	const [t] = useTranslation();
	const [showModal, toggleModal] = useState<boolean>(false);
	const [sending, toggleSending] = useState<boolean>(false);
	const { cx } = useStyles();

	const completeCollects = async () => {
		toggleSending(true);
		try {
			await api.orders.finishCollect({ order_ids: collects });
			toggleModal(false);
			notification.success({
				message: t('Отправлен сигнал на закрытие заказ-нарядов'),
			});
		} catch {
			notification.error({
				message: t('Не удалось отправить сигнал на закрытие заказ-нарядов'),
			});
		} finally {
			toggleSending(false);
			setCollectsToClose([]);
		}
	};

	return (
		<>
			<PrimaryButtonComponent
				button={{
					type: 'button',
					action: () => toggleModal(true),
					text: t('Закрыть заказ-наряды'),
					className: cx('ant-btn', 'ant-btn-primary'),
					disabled: sending,
					dataTest: 'finish collects btn',
				}}
			/>
			<ModalComponent
				title={t('Закрытие заказ-нарядов')}
				open={showModal}
				onOk={completeCollects}
				onCancel={() => toggleModal(false)}
				okText={t('Закрыть заказ-наряды')}
				cancelText={t('Отмена')}
				okButtonProps={{
					className: 'data-test-finish-collects-submit',
				}}
				className={`data-test-finish-collects-modal-${showModal ? 'visible' : 'hidden'}`}
			>
				{t('Будут закрыты все выбранные заказ-наряды')}
			</ModalComponent>
		</>
	);
};

export default CompleteCollectsBtn;
