import { notification } from '@lavka/ui-kit';

import api from '~/api';

export async function getUsersLastSeenData(
	storeId: string,
	timestamp: {
		start: string;
		end: string;
	},
	setData: (data: { active: Record<string, string>; inactive: Record<string, string> }) => void
) {
	try {
		const { data } = await api.reportData.health_metrics.users_last_seen({
			store_id: storeId,
			start_timestamp: timestamp.start,
			end_timestamp: timestamp.end,
		});
		setData(data);
	} catch (e) {
		notification.error({
			message: e.data.message,
		});
	}
}
