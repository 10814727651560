import { Button, Checkbox, Dropdown, Menu, Tooltip } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FilterRegular } from '~images/icons/filter-regular.svg';
import Icon from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from '../styles';

interface Props {
	valuesToShow: string[];
	onChange: (values: string[]) => void;
	allValues: string[];
	dictionary: Record<string, string>;
	dataTest?: string;
	filtered?: boolean;
}

const Filter = ({ valuesToShow, onChange, allValues, dictionary, dataTest, filtered = false }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();

	const [indeterminate, setIndeterminate] = useState(true);
	const [checkAll, setCheckAll] = useState(false);

	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		onChange(e.target?.checked ? allValues : []);
		setIndeterminate(false);
		setCheckAll(e.target?.checked);
	};

	const onCheckboxChange = (values: string[]) => {
		onChange(values);
		setIndeterminate(!!values.length && values.length < allValues.length);
		setCheckAll(values.length === allValues.length);
	};

	const menu = (
		<Menu className={cx(classes.filterBlock, classes.filterBlockScrolled)} data-test="filter main menu">
			{allValues.length > 1 && (
				<Checkbox
					className={classes.checkboxItem}
					indeterminate={indeterminate}
					onChange={onCheckAllChange}
					checked={checkAll}
					onClick={(e) => e.stopPropagation()}
					data-test="check_all"
				>
					{t('Выбрать все')}
				</Checkbox>
			)}
			<Checkbox.Group value={valuesToShow} onChange={onCheckboxChange} className={classes.checkboxGroup}>
				{allValues.map((value) => (
					<Checkbox
						key={value}
						value={value}
						onClick={(e) => e.stopPropagation()}
						data-test={value}
						checked={valuesToShow.includes(value)}
					>
						{dictionary[value]}
					</Checkbox>
				))}
			</Checkbox.Group>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<Tooltip title={t('Фильтры')}>
				<Button
					className={cx(commonClasses.btnIconBordered, classes.filterBtn, {
						active: filtered,
					})}
					data-test={dataTest ?? 'check project orders filters button'}
				>
					<Icon component={FilterRegular} />
				</Button>
			</Tooltip>
		</Dropdown>
	);
};

export default Filter;
