import type { TabProps } from '@lavka/ui-kit';
import { TabBar } from '@lavka/ui-kit';
import { Spin } from 'antd';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import usePrevious from '~shared/hooks/usePrevious';

import { useStyles } from './styles';

export type TabsContentProps = PropsWithChildren<
	TabProps & { condition?: boolean; forceRender?: boolean; contentClassName?: string }
>[];

export type TabBarProps = {
	onSelectTab: (value: string) => void;
	activeTab: string | null;
	className?: string;
	tabsContent: TabsContentProps;
	destroyInactiveTabPane?: boolean;
};

const Tabs = ({ onSelectTab, activeTab, className, tabsContent, destroyInactiveTabPane = false }: TabBarProps) => {
	const { classes } = useStyles();
	const [mountedTabs, setMountedTabs] = useState<Record<string, boolean>>({});
	const [loading, setLoading] = useState<Record<string, boolean>>({});
	const prevActiveTab = usePrevious(activeTab);

	useEffect(() => {
		if (destroyInactiveTabPane && prevActiveTab) {
			setMountedTabs((prev) => ({
				...prev,
				[prevActiveTab]: false,
			}));
		}

		if (activeTab && !mountedTabs[activeTab]) {
			setLoading((prev) => ({
				...prev,
				[activeTab]: true,
			}));
		}
	}, [activeTab]);

	useEffect(() => {
		if (activeTab && loading[activeTab]) {
			setMountedTabs((prev) => ({
				...prev,
				[activeTab]: true,
			}));

			setLoading((prev) => ({
				...prev,
				[activeTab]: false,
			}));
		}
	}, [activeTab, loading]);

	const filteredTabs = tabsContent.filter((tab) => tab.condition || !tab.hasOwnProperty('condition'));

	return (
		<div className={classes.container}>
			<TabBar onSelectTab={onSelectTab} activeTabId={activeTab} tabs={filteredTabs} className={className} />

			{filteredTabs.map((tab) => {
				const isActive = activeTab === tab.id;

				if (isActive && (!mountedTabs[tab.id] || loading[tab.id])) {
					return <Spin key={tab.id} />;
				}

				const shouldRender = isActive || tab.forceRender || (!destroyInactiveTabPane && mountedTabs[tab.id]);

				if (shouldRender) {
					return (
						<div
							key={tab.id}
							style={{ display: activeTab === tab.id ? 'block' : 'none' }}
							className={tab.contentClassName}
						>
							{tab.children}
						</div>
					);
				}

				return null;
			})}
		</div>
	);
};

export default Tabs;
