import { orderTypeNames } from 'server-types/doc/api/models/order';

import { NextIconLink } from '~shared/components/NextIconLink';
import Timer from '~shared/components/Timer';
import type { Orders } from '~types/orders';

import { useStyles } from './styles';

const StorekeeperOrders = ({
	orders,
	storeId,
	isHealthPage,
}: {
	orders?: Orders.Order[];
	storeId?: string | null;
	isHealthPage: boolean;
}) => {
	const { classes } = useStyles({ mode: isHealthPage });

	return (
		<div className={classes.storekeeperOrdersWrapper}>
			{orders?.map((order) => (
				<div className={classes.storekeeperOrder} key={order.order_id}>
					<div className={classes.orderInfoWrapper}>
						<span> {order.attr.doc_number} </span>
						<div className={classes.orderInfo}>
							<span>{orderTypeNames[order.type]}</span>
						</div>
					</div>
					<div className={classes.timerWrapper}>
						<Timer time={order.status_time?.processing} countUp={true} />
						{isHealthPage ? <NextIconLink link={`/stores/${storeId}/orders/${order.order_id}`} /> : null}
					</div>
				</div>
			))}
		</div>
	);
};

export default StorekeeperOrders;
