import dayjs from 'dayjs';

import { useCache } from '~cache/useCache';
import type { Orders } from '~types/orders';
import type { User } from '~types/user';

export type ExecutersType = {
	active?: Record<string, string>;
	inactive?: Record<string, string>;
};

export type UserExecutorLastSeenType = Partial<User.Executor> & {
	lastSeen: string;
};

export type StorekeepersDataType = {
	occupied: User.Executor[];
	avaliable: UserExecutorLastSeenType[];
	inactive: UserExecutorLastSeenType[];
	usersOrders: Record<string, Orders.Order[]>;
};

export const useStorekeepersData = (executers: ExecutersType, orders: Orders.Order[]): StorekeepersDataType => {
	const { userExecutors } = useCache({
		userExecutors: [...Object.keys(executers.active ?? {}), ...Object.keys(executers.inactive ?? {})],
	});

	const processingOrders = orders.filter((order) => order.status === 'processing');
	const inactive = Object.keys(executers.inactive ?? {})
		.filter((userId) => userExecutors[userId])
		.map((userId) => ({
			...userExecutors[userId],
			lastSeen: dayjs(executers.inactive![userId]).format('HH:mm'),
		}));

	const usersWithOrders = processingOrders.reduce(
		(result, order) => {
			order.users.forEach((userId) => {
				result[userId] = result[userId] || [];
				result[userId].push(order);
			});

			return result;
		},
		{} as Record<string, Orders.Order[]>
	);

	const avaliable: UserExecutorLastSeenType[] = [];
	const occupied: User.Executor[] = [];
	const usersOrders: Record<string, Orders.Order[]> = {};

	for (const [userId, lastSeenTime] of Object.entries(executers.active || {})) {
		const currentUser = userExecutors[userId];
		if (!currentUser) continue;

		const currentOrders = usersWithOrders[userId];
		if (currentOrders) {
			occupied.push(currentUser);
			usersOrders[userId] = currentOrders;
		} else {
			avaliable.push({
				...currentUser,
				lastSeen: dayjs(lastSeenTime).format('HH:mm'),
			});
		}
	}

	return {
		occupied,
		avaliable,
		inactive,
		usersOrders,
	};
};
