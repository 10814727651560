import { lazy } from 'react';

import { useCashConfiguration } from './utils';

const WalletFinanceStorePage = lazy(() => import('./WalletFinanceStorePage'));
const WalletFinanceCourierPage = lazy(() => import('./WalletFinanceCourierPage'));

export const WalletStorePage = () => {
	const cashConfig = useCashConfiguration();

	if (!cashConfig.isConfigsReady) {
		return null;
	}

	return <WalletFinanceStorePage cashConfig={cashConfig} />;
};

export const WalletCourierPage = () => {
	const cashConfig = useCashConfiguration();

	if (!cashConfig.isConfigsReady) {
		return null;
	}

	return <WalletFinanceCourierPage cashConfig={cashConfig} />;
};
