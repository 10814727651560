import { getUserAudio } from '~zustand/userSettings';

type SoundFile = 'alert.m4a' | 'new_message_notice.mp3' | 'new_order_notice.mp3';

export const Audioplayer = {
	isAudioOn() {
		return getUserAudio();
	},
	play(soundFile: SoundFile) {
		if (this.isAudioOn()) {
			const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/${soundFile}`);
			audio?.play().catch((e) => console.info(e.message));
		}
	},
};
