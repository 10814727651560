import { DevTool } from '@hookform/devtools';
import { notification } from '@lavka/ui-kit';
import type { CSSProperties, KeyboardEventHandler, ReactNode } from 'react';
import { useEffect } from 'react';
import type { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type Props<TFieldValues extends FieldValues = FieldValues> = {
	hookForm: UseFormReturn<TFieldValues>;
	onSubmit?: SubmitHandler<TFieldValues>;
	onKeyDown?: KeyboardEventHandler<HTMLFormElement>;
	className?: string;
	'data-test'?: string;
	children?: ReactNode;
	style?: CSSProperties;
};

export function Form<TFieldValues extends FieldValues = FieldValues>(props: Props<TFieldValues>) {
	const { hookForm, onSubmit, children, className } = props;
	const [t] = useTranslation();

	const {
		formState: { isSubmitted, isSubmitSuccessful },
		handleSubmit,
		control,
	} = hookForm;

	useEffect(() => {
		if (isSubmitted && !isSubmitSuccessful) {
			notification.error({
				message: t('Неправильно заполнена форма'),
			});
		}
	}, [isSubmitted, isSubmitSuccessful]);

	return (
		<FormProvider {...hookForm}>
			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
			<form
				onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}
				className={className}
				data-test={props['data-test']}
			>
				{children}
			</form>
		</FormProvider>
	);
}
