// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type GeoJsonBoxType = 'MultiPoint';
export const geoJsonBoxTypeValues = ['MultiPoint'] as GeoJsonBoxType[];
export const geoJsonBoxTypeNames = makeServerTranslations(geoJsonBoxTypeValues);

export type ZoneStatuses = 'template' | 'active' | 'disabled' | 'removed';
export const zoneStatusesValues = ['template', 'active', 'disabled', 'removed'] as ZoneStatuses[];
export const zoneStatusesNames = makeServerTranslations(zoneStatusesValues, 'enums.ZONE_STATUSES');

export type ZoneDeliveryTypes =
	| 'foot'
	| 'foot_external'
	| 'foot_night'
	| 'car'
	| 'car_night'
	| 'yandex_taxi'
	| 'yandex_taxi_remote'
	| 'yandex_taxi_night'
	| 'rover';
export const zoneDeliveryTypesValues = [
	'foot',
	'foot_external',
	'foot_night',
	'car',
	'car_night',
	'yandex_taxi',
	'yandex_taxi_remote',
	'yandex_taxi_night',
	'rover',
] as ZoneDeliveryTypes[];
export const zoneDeliveryTypesNames = makeServerTranslations(zoneDeliveryTypesValues, 'enums.ZONE_DELIVERY_TYPES');

export type ZoneZoneType = 'Feature';
export const zoneZoneTypeValues = ['Feature'] as ZoneZoneType[];
export const zoneZoneTypeNames = makeServerTranslations(zoneZoneTypeValues);

export type ZoneZoneGeometryType = 'Polygon' | 'MultiPolygon';
export const zoneZoneGeometryTypeValues = ['Polygon', 'MultiPolygon'] as ZoneZoneGeometryType[];
export const zoneZoneGeometryTypeNames = makeServerTranslations(zoneZoneGeometryTypeValues);
