import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import { ReactComponent as ExternalLinkSquareAltRegular } from '~images/icons/external-link-square-alt-regular.svg';
import { ReactComponent as UserSolid } from '~images/icons/user-solid.svg';
import { Link } from '~shared/ui-kit/Link';
import calculateDistance from '~shared/utils/calculateDistance';
import courierName from '~shared/utils/courierName';
import Icon from '~shared/utils/icons';
import { isNotNullish } from '~shared/utils/isNotNullish';
import type { Couriers } from '~types/couriers';
import type { Orders } from '~types/orders';

import { useStyles } from '../styles';
import { Context } from './context';

interface Props {
	data: Couriers.CourierForDispatch;
	batching?: boolean;
}

const speedConst = 165;

const Courier = ({ data, batching }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();

	const { requestData, setRequestField, order } = useContext(Context);

	const orderIds = data.orders?.map((order) => order.order_id) || [];

	const cache = useCache({
		stores: order.store_id,
		orders: orderIds,
	});

	const { courier } = data;

	const distanceData = useMemo(():
		| {
				first: {
					time: number;
					distance: number;
					weight: number | '—';
				};
				adding: {
					time: number;
					distance: number;
					weight: number | '—';
				};
		  }
		| undefined => {
		const orderFromCache = cache.stores[order.store_id];

		if (!batching || !orderFromCache) return;

		try {
			const storeCoords: [number, number] = [
				orderFromCache.location.geometry.coordinates[1],
				orderFromCache.location.geometry.coordinates[0],
			];

			// берем текущие заказы курьера и сортируем их по удаленности от склада от меньшего к большему
			const ordersSorter = (orderIds: Orders.Order['order_id'][]) =>
				orderIds
					.map((orderId) => (orderId === order?.order_id ? order : cache.orders[orderId]))
					.filter(isNotNullish)
					.sort(
						(a, b) =>
							calculateDistance(storeCoords, [a.client_address.lat, a.client_address.lon]) -
							calculateDistance(storeCoords, [b.client_address.lat, b.client_address.lon])
					);

			// считаем параметры ордеров в зависимости от их порядкового номера
			const countOrdersParams = (orders: any[]) =>
				orders.reduce(
					(acc, order, index, array) => {
						let time;
						let distance;
						const weight = order.vars?.total_order_weight ?? 0;

						if (index === 0) {
							distance = calculateDistance(storeCoords, [order.client_address.lat, order.client_address.lon]);
							time = 5 + (distance * 1000) / speedConst;
						} else if (index === array.length - 1) {
							distance = calculateDistance(
								[array[index - 1].client_address.lat, array[index - 1].client_address.lon],
								[order.client_address.lat, order.client_address.lon]
							);
							time = (distance * 1000) / speedConst;
						} else {
							distance = calculateDistance(
								[array[index - 1].client_address.lat, array[index - 1].client_address.lon],
								[order.client_address.lat, order.client_address.lon]
							);
							time = 5 + (distance * 1000) / speedConst;
						}
						return {
							time: acc.time + time,
							distance: acc.distance + distance,
							weight: acc.weight + weight,
						};
					},
					{ weight: 0, distance: 0, time: 0 }
				);

			const courierOrdersParams = countOrdersParams(ordersSorter(orderIds));

			const allOrdersParams = countOrdersParams(ordersSorter([...orderIds, order.order_id]));

			return {
				first: {
					time: Math.round(courierOrdersParams.time),
					distance: parseFloat(courierOrdersParams.distance.toFixed(2)),
					weight: parseFloat((courierOrdersParams.weight / 1000).toFixed(2)),
				},
				adding: {
					time: Math.round(allOrdersParams.time - courierOrdersParams.time),
					distance: parseFloat((allOrdersParams.distance - courierOrdersParams.distance).toFixed(2)),
					weight: parseFloat(((allOrdersParams.weight - courierOrdersParams.weight) / 1000).toFixed(2)),
				},
			};
		} catch (e) {
			console.error(e);
			return;
		}
	}, [batching]);

	return (
		<li
			key={courier.courier_id}
			data-test={`courier item ${courier.courier_id}`}
			className={cx(classes.courierItemContainer, {
				[classes.itemSelected]: requestData.courier_id === courier.courier_id,
			})}
			onClick={() =>
				setRequestField({
					courier_id: requestData.courier_id === courier.courier_id ? undefined : courier.courier_id,
					batch_to_order_id: batching ? orderIds[0] : undefined,
				})
			}
		>
			<div className={classes.courierItem}>
				<div>
					<Icon component={UserSolid} />
				</div>
				<div className={cx({ [classes.courierItemSimple]: !batching })}>
					<p className={classes.courierName}>{courierName(courier, { showEatsId: false })}</p>
					{distanceData && (
						<>
							<p className={classes.courierDataItem}>
								{t('Вес заказов:')} {distanceData.first.weight} {t('кг')} {'(+'}
								<span>
									{distanceData.adding.weight} {t('кг')}
									{')'}
								</span>
							</p>
							<p className={classes.courierDataItem}>
								{t('Расстояние:')} {distanceData.first.distance} {t('км')} {'(+'}
								<span>
									{distanceData.adding.distance} {t('км')}
									{')'}
								</span>
							</p>
							<p className={classes.courierDataItem}>
								{t('Время доставки:')} {distanceData.first.time} {t('мин.')} {'(+'}
								<span>
									{distanceData.adding.time} {t('мин.')}
									{')'}
								</span>
							</p>
						</>
					)}
				</div>
			</div>
			<div className={classes.courierItemLink}>
				<Link to={`${order.store_id ? `/stores/${order.store_id}` : ''}/couriers/${courier.courier_id}`}>
					<Icon component={ExternalLinkSquareAltRegular} />
				</Link>
			</div>
		</li>
	);
};

export default Courier;
