import { notification } from '@lavka/ui-kit';

import i18n from '~/i18n';

export default (str: string, successText?: string, errorText?: string) => {
	try {
		const el = document.createElement('textarea');
		el.value = str;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		if (successText) {
			notification.success({
				message: successText,
			});
		}
		if (process.env.REACT_APP_MODE === 'ci') {
			window.lastCopiedText = str;
		}
	} catch {
		if (successText || errorText) {
			notification.error({
				message: errorText || i18n.t('Не удалось скопировать'),
			});
		}
	}
};
