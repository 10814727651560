import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import isMetricInRed from '~shared/utils/getIsMetricInRed';
import getProperTime from '~shared/utils/transformSecondsToTimeString';

import { useStyles } from './styles';

type Props = {
	metric: string;
	value?: number | null;
	threshold?: number;
};

const MetricWithGoalCell = ({ metric, value, threshold }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();

	const isMetricExceeded = useMemo(() => isMetricInRed(metric, value, threshold), [metric, value, threshold]);

	if (value && threshold) {
		return (
			<div className={classes.metricWithGoalCell}>
				<span>{getProperTime(Math.round(value))}</span>
				<span className={cx(classes.goalText, { [classes.goalOfExceededMetric]: isMetricExceeded })}>
					{t('Цель {{time}}', { time: getProperTime(threshold) })}
				</span>
			</div>
		);
	}

	return <span data-test={metric}>{value ? getProperTime(Math.round(value)) : '—'}</span>;
};

export default MetricWithGoalCell;
