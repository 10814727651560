import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	paper: {
		marginBlockStart: 96,
		display: 'flex',
		flexDirection: 'column',
		maxInlineSize: 600,
	},
}));
