import axios from 'axios';

import { getUserDomainConfigs } from '~zustand/userData';

export default {
	geocode: (text: string) => {
		const domainConfigs = getUserDomainConfigs();

		return axios.get('https://geocode-maps.yandex.ru/1.x', {
			transformRequest: (data, headers) => {
				delete headers?.common['Authorization'];
				delete headers?.common['X-WMS-UI-Version'];
				delete headers?.common['X-Request-Id'];
				delete headers?.common['X-Request-User-Role'];
				return data;
			},
			params: {
				apikey: domainConfigs.yandex_maps_key ?? '',
				geocode: text,
				format: 'json',
			},
		});
	},
};
