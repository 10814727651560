import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { Orders } from '~types/orders';
import type { Stores } from '~types/stores';

import { defaultPeriodState } from './constants';
import type { PeriodState } from './healthTypes';
import type { ViewData } from './index';

type HealthContextType = {
	periodState: PeriodState;
	loading: boolean;
	userStoreId?: string;
	userStore?: Stores.Store;
	setOrderIdToShow: Dispatch<SetStateAction<Orders.Order['order_id'] | undefined>>;
	levelState: ViewData;
};

export const HealthContext = createContext<HealthContextType>({
	periodState: defaultPeriodState,
	loading: false,
	userStoreId: '',
	setOrderIdToShow: () => {},
	levelState: { type: 'cluster' },
	userStore: undefined,
});
