import type { Field, SectionConfig } from './types';

export const getField = (
	fields: SectionConfig | SectionConfig[],
	sectionLabel: string,
	fieldKey: string,
	parentFieldKey?: string
): Field | undefined => {
	if (!fields) {
		return;
	}

	const section = (Array.isArray(fields) ? fields : [fields]).find((item) => item.label === sectionLabel);

	if (section && parentFieldKey) {
		const parent = section.fields.find((item) => item.key === parentFieldKey);
		if (parent && parent.type === 'object' && parent.inputType === 'fieldGroup') {
			const field = parent.fields.find((item) => item.key === fieldKey);
			if (field) return field as Field;
		}
	}

	if (section) {
		const field = section.fields.find((item) => item.key === fieldKey);
		if (field) return field as Field;
	}
};

export const getDefaultValues = <T extends Record<string, any> = Record<string, any>>(
	fields: SectionConfig[] = []
): T => {
	return fields.reduce((result, section) => {
		section.fields.forEach((field) => {
			if (field.defaultValue === undefined) {
				return;
			}

			const keyParts = field.key.split('.');

			let fieldBase: T = result;
			for (const part of keyParts.slice(0, -1)) {
				if (!fieldBase[part]) {
					// @ts-ignore
					fieldBase[part] = {};
				}
				fieldBase = fieldBase[part] as T;
			}
			// @ts-ignore
			fieldBase[keyParts[keyParts.length - 1]] = field.defaultValue;
		});

		return result;
	}, {} as T);
};
