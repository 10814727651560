/**
 * Копия хука https://a.yandex-team.ru/arcadia/taxi/lavka/ui-kit/src/hooks/useSkipOnMountEffect/useSkipOnMountEffect.ts
 *
 * TODO: После обновления в проекте ui-kit, нужно будет удалить копию и использовать оригинальный хук из ui-kit
 */

import type { DependencyList, EffectCallback } from 'react';
import { useEffect, useRef } from 'react';

/**
 * Аналогичен useEffect, но не выполняется при монтировании (первичном рендере)
 */
export function useSkipOnMountEffect(effect: EffectCallback, deps?: DependencyList) {
	const isMountedRef = useRef(false);

	useEffect(() => {
		if (isMountedRef.current) {
			effect();
		} else {
			isMountedRef.current = true;
		}
	}, deps);
}
