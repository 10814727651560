import 'react-phone-number-input/style.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../TextField';
import type { FieldProps } from '../types';

const JsonField = ({ ...field }: FieldProps) => {
	const [value, setValue] = useState<string>(JSON.stringify(field.input.value));
	const [isFieldInvalid, setIsFieldInvalid] = useState<boolean>(true);
	const [t] = useTranslation();

	useEffect(() => {
		try {
			field.input.onChange(JSON.parse(value));
			setIsFieldInvalid(false);
		} catch {
			field.input.onChange(null);
			setIsFieldInvalid(true);
		}
	}, [value]);

	return (
		<TextField
			{...field}
			type="string"
			inputType="textarea"
			input={{
				...field.input,
				onChange: (value) => setValue(value),
				value: value,
			}}
			colspan={{
				xl: { span: 12 },
				lg: { span: 12 },
				sm: { span: 12 },
				xs: { span: 12 },
			}}
			isFieldInvalid={isFieldInvalid}
			isFieldInvalidMessage={t('Некорректный формат JSON')}
		/>
	);
};
export default JsonField;
