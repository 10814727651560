import type { JSX } from 'react';

import { useUserTheme } from '~zustand/userSettings';

import { useStyles } from './styles';

interface Props {
	title?: string;
	icon?: string;
	iconDark?: string;
	text?: string;
	button?: JSX.Element;
	className?: string;
}

const EmptyState = ({ title, text, icon, iconDark, button, className }: Props) => {
	const { cx, classes } = useStyles();
	const theme = useUserTheme();

	return (
		<div className={cx(classes.container, className)}>
			{icon && <img src={theme === 'light' ? icon : iconDark || icon} alt="" className={classes.image} />}
			{title && <p className={cx(classes.title, 'title')}>{title}</p>}
			{text && <p className={cx(classes.text, 'text')}>{text}</p>}
			{button}
		</div>
	);
};

export default EmptyState;
