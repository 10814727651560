import { makeRoutes } from '~shared/utils/makeRoutes';

import CheckProjects from '../components/CheckProjects';
import CheckProjectsDataPage from '../components/CheckProjects/CheckProjectsDataPage';

const checkProjectsRoutes = makeRoutes([
	{
		path: '/check_projects',
		element: <CheckProjects />,
	},
	{
		path: '/check_projects/add',
		permit: 'check_projects_save',
		element: <CheckProjectsDataPage editMode addMode />,
	},
	{
		path: '/check_projects/edit/:check_project_id',
		permit: 'check_projects_save',
		element: <CheckProjectsDataPage editMode />,
	},
	{
		path: '/check_projects/:check_project_id',
		permit: 'check_projects_load',
		element: <CheckProjectsDataPage />,
	},
]);

export default checkProjectsRoutes;
