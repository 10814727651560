import { Link } from '~shared/ui-kit/Link';

import type { BreadcrumbElement } from './types';

interface Props {
	item: BreadcrumbElement;
	disabled: boolean;
}

export const BreadcrumbContent = ({ item, disabled }: Props) => {
	const dataTest = `health breadcrumb ${item.title}`;

	if (!disabled && item.link && item.title) {
		return (
			<Link to={item.link} data-test={dataTest}>
				{item.title}
			</Link>
		);
	}

	return <span data-test={dataTest}>{item.title ?? '—'}</span>;
};
