import axios from 'axios';

import type { OrderFiles } from '~types/orderFiles';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	load: makeLoadFn<OrderFiles.OrderFiles, 'order_files_id'>('/api/admin/order_files/load', 'order_files_id'),
	load_by_order_id: makeLoadFn<OrderFiles.OrderFiles, 'order_id'>(
		'/api/admin/order_files/load_by_order_id',
		'order_id'
	),
	save: (
		data: Pick<OrderFiles.OrderFiles, 'order_files_id'> & Partial<Pick<OrderFiles.OrderFiles, 'files' | 'status'>>
	) => axios.post<{ result: OrderFiles.OrderFiles }>('/api/admin/order_files/save', data),
};
