import { Button } from 'antd';
import type { TableRowSelection } from 'antd/lib/table/interface';
import type { Key } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import api from '~/api';
import type { PrimaryButton } from '~shared/components/PrimaryButtonComponent';
import useLoadData from '~shared/hooks/useLoadData';
import useRerender from '~shared/hooks/useRerender';
import useSubscribe from '~shared/hooks/useSubscribe';
import type { Event, EventData } from '~shared/utils/ev';
import { useStyles as useCommonStyles } from '~styles/common';
import type { Schets } from '~types/schets';
import { useCheckPermit } from '~zustand/userData';

import { ImportSchetTasksButton } from './ImportSchetTasksButton';
import { SchetsList } from './index';
import useExportSchets from './useExportSchets';
import { massiveSchetTasks } from './utils/massiveSchetAction';

export const StoreSchets = () => {
	const params = useParams<{ store_id: string }>();
	const [t] = useTranslation();
	const { cx, classes: commonClasses } = useCommonStyles();

	const [cursor, setCursor] = useState<string>('');
	const { rerenderKey, updateRerenderKey } = useRerender();
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	const storeId = params.store_id!;
	const { sendSchetsExportSignal, loading } = useExportSchets(params.store_id!);
	const isPermitStocksExportCsv = useCheckPermit('stocks_export_csv');
	const isPermitSchetTasksExportCsv = useCheckPermit('schet_tasks_export_csv');
	const isPermitSchetTasksImportData = useCheckPermit('schet_tasks_import_data');
	const isPermitSchetsSeek = useCheckPermit('schets_seek');

	const schetsData = useLoadData(
		() =>
			api.schets.list({
				store_id: storeId,
				scope: 'store' as const,
				cursor,
			}),
		[cursor, rerenderKey],
		!isPermitSchetsSeek
	);

	const subscribeCallback = (data: Event) => {
		if (Array.isArray(data.data) && data.data.some((ev: EventData) => ev.type === 'import_schet_tasks')) {
			updateRerenderKey();
		}
	};

	useSubscribe(
		{
			key: ['store', params.store_id],
			cb: subscribeCallback,
			unSub: true,
			single: false,
		},
		[],
		!isPermitSchetsSeek
	);

	const selectedSchetsData = useMemo(
		() => schetsData.data?.results.filter((task) => selectedRowKeys.some((id) => task.schet_task_id === id)),
		[schetsData.data, selectedRowKeys]
	);

	const hideApprove = useMemo(() => {
		return selectedSchetsData?.some((schet) => {
			return (!schet.schedule_draft && schet.approved_by) || schet.status === 'deleted';
		});
	}, [selectedSchetsData, selectedRowKeys]);

	const hidePause = useMemo(() => {
		return selectedSchetsData?.some((schet) => {
			return schet.status === 'paused' || schet.status === 'deleted';
		});
	}, [schetsData, selectedRowKeys]);

	const hideRun = useMemo(() => {
		return selectedSchetsData?.some((schet) => {
			return schet.status !== 'paused' || schet.schedule_draft || !schet.approved_by;
		});
	}, [schetsData, selectedRowKeys]);

	const hideForceRun = useMemo(() => {
		return selectedSchetsData?.some((schet) => {
			return schet.status !== 'pending';
		});
	}, [schetsData, selectedRowKeys]);

	const rowSelection: TableRowSelection<Schets.Schet> = {
		selectedRowKeys,
		onChange: (selectedRowKeys: Key[]) => {
			setSelectedRowKeys(selectedRowKeys as string[]);
		},
	};

	const actions = [
		{
			disabled: hideApprove,
			onClick: () => massiveSchetTasks('APPROVE', selectedRowKeys, updateRerenderKey),
			text: t('Подтвердить'),
			dataTest: 'approve schets button',
		},
		{
			disabled: hidePause,
			onClick: () => massiveSchetTasks('DISABLE', selectedRowKeys, updateRerenderKey),
			text: t('Отключить'),
			dataTest: 'disable schets button',
		},
		{
			disabled: hideRun,
			onClick: () => massiveSchetTasks('RUN', selectedRowKeys, updateRerenderKey),
			text: t('Запустить'),
			dataTest: 'run schets button',
		},
		{
			disabled: hideForceRun,
			onClick: () => massiveSchetTasks('FORCE_RUN', selectedRowKeys, updateRerenderKey),
			text: t('Запустить не дожидаясь расписания'),
			dataTest: 'run schets button',
		},
	];

	const dotsOptions = [
		{
			section: '',
			buttons: actions.map((action) => {
				return {
					component: (
						<Button
							onClick={action.onClick}
							data-test={action.dataTest}
							disabled={action.disabled}
							className={cx(commonClasses.dropdownBtn, {
								[commonClasses.dropdownBtnDisabled]: action.disabled,
							})}
						>
							{action.text}
						</Button>
					),
				};
			}),
			condition: isPermitStocksExportCsv && selectedRowKeys.length > 0,
		},
	];

	const additionalPrimaryButtons: PrimaryButton[] = [
		{
			type: 'button',
			action: sendSchetsExportSignal,
			dataTest: 'export schets csv button',
			text: t('Экспорт CSV'),
			condition: isPermitSchetTasksExportCsv,
			className: 'exportSchetTasksButton',
			disabled: loading,
		},
		{
			type: 'component',
			condition: isPermitSchetTasksImportData,
			component: (
				<ImportSchetTasksButton
					onImport={async (csv) => {
						await api.schets.import_csv({ storeId: params.store_id!, csv });
					}}
				/>
			),
		},
	];

	return (
		<SchetsList
			cursor={schetsData.data?.cursor ?? ''}
			schetsData={schetsData.data?.results ?? []}
			loading={schetsData.loading}
			setCursor={setCursor}
			additionalPrimaryButtons={additionalPrimaryButtons}
			dotsOptions={dotsOptions}
			rowSelection={rowSelection}
			baseUrl={`/stores/${storeId}/schets`}
		/>
	);
};
