import { Col, InputNumber, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import type { FieldProps } from '~shared/components/Fields/types';
import { getColSpan } from '~shared/components/Fields/utils';

import { useStyles } from '../styles';

interface Props extends FieldProps {
	colspan?: Record<string, { span: number }>;
}

const DayIntervalField = ({ input, editMode, colspan, label }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [first, last] = t('Раз в {{ count }} дней', {
		count: input.value ?? 0,
	}).split(String(input.value ?? 0));

	return (
		<Row>
			<Col {...getColSpan(colspan)} className={classes.labelContainer}>
				{label}
				<div className={classes.dotSeparator}></div>
			</Col>
			<Col {...getColSpan(colspan)}>
				{editMode ? (
					<>
						<span>{first}</span>
						<InputNumber
							min={1}
							max={365}
							onChange={(value) => {
								input.onChange(Number(value));
							}}
							value={input.value}
							data-test="data form Раз в"
						/>
						<span>{last}</span>
					</>
				) : (
					<div className={classes.valueOnViewMode} data-test={`data form ${label}`}>
						{t('Раз в {{count}} дней', { count: input.value })}
					</div>
				)}
			</Col>
		</Row>
	);
};

export default DayIntervalField;
