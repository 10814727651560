import { useContext, useMemo } from 'react';

import { ReactComponent as RepeatIcon } from '~images/icons/repeat.svg';
import Icon from '~shared/utils/icons';

import { eventScheduleType, eventTypes } from '../../../../components/StoresPage/StoresDataPage/Events/constants';
import { compareTime } from '../../../../components/StoresPage/StoresDataPage/Events/utils';
import { EventsCalendarContext } from '../context';
import { useStyles } from './styles';

const ScheduleByDay = () => {
	const { classes } = useStyles();
	const { searchData, storeEventsDataByWeek, openSchedule } = useContext(EventsCalendarContext);

	const events = useMemo(() => {
		const storeEvents = [...storeEventsDataByWeek[searchData.local_date]];

		return storeEvents.sort((a, b) => compareTime(a.time, b.time));
	}, [searchData.local_date, openSchedule, storeEventsDataByWeek]);

	return (
		<div className={classes.scheduleContainer} data-test="schedule-by-day">
			{events.map((event) => (
				<div className={classes.scheduleRow} key={event.type}>
					<span className={classes.scheduleTime}>{event.time}</span>
					<div className={classes.scheduleEvent}>
						<span className={classes.scheduleEventTitle}>{event.title ?? eventScheduleType[event.type]}</span>
						<span className={classes.scheduleEventType}>{eventTypes[event.type]}</span>
					</div>
					{event.isRepeated && <Icon data-test="store event repeat icon" component={RepeatIcon} width={14} />}
				</div>
			))}
		</div>
	);
};

export default ScheduleByDay;
