import { Button, Card, Typography } from 'antd';
import type { JSX } from 'react';
import { memo, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';

import { isSuperRole } from '~constants/userRoles';
import useGenerateQrCode from '~shared/hooks/useGenerateQrCode';
import copyToClipboard from '~shared/utils/copyToClipboard';
import { ReloadOutlined } from '~shared/utils/icons';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { useUser } from '~zustand/userData';

import type { FieldProps } from '../Fields/types';
import { useStyles } from './styles';

const { Title, Text } = Typography;

interface Props extends Partial<FieldProps> {
	barcodeId: string;
	cardTitle: string;
	cardDataTest?: string;
	barcode?: string;
	additionalData?: Record<'store' | 'rack' | 'type' | 'tags', string | undefined>;
	noActions?: boolean;
	titleLevel?: 2 | 1 | 4 | 3;
	scale?: number;
	onlyPrint?: boolean;
}

const additionalDataMap = makeDynamicTranslations({
	store: tDynamic('Склад'),
	rack: tDynamic('Стеллаж'),
	type: tDynamic('Тип'),
});

const CodeCard = ({
	barcodeId = '',
	cardTitle,
	cardDataTest,
	barcode,
	scale,
	editMode,
	input,
	additionalData,
	titleLevel = 4,
	onlyPrint,
}: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();

	const user = useUser();
	const printCardRef = useRef<any>();

	cardTitle = cardTitle ?? t('Имя отсутствует');

	const generatedBarcode = useGenerateQrCode(
		`barcode-${barcodeId}`,
		{
			text: barcode ?? '',
			scale: scale ?? 4,
		},
		[barcode]
	);

	const generatedBarcodeForPrint = useGenerateQrCode(
		`barcode-for-print-${barcodeId}`,
		{
			text: barcode ?? '',
			scale: 4,
		},
		[barcode]
	);

	const renderAdditionalData = useMemo(() => {
		const result: JSX.Element[] = [];
		if (additionalData) {
			const dataEntries = Object.entries(additionalData);
			const lines = dataEntries.length >= 3;
			dataEntries.forEach(([key, data]) => {
				if (key === 'type' && !data) {
					data = t('Тёплая полка');
				}
				if (data) {
					result.push(
						<div key={key} className={classes.cardData}>
							{key !== 'tags' && (
								<Text className={classes.dataStringSecondary} type="secondary">
									{`${additionalDataMap[key as keyof typeof additionalDataMap]}${lines ? ': ' : ''}`}
								</Text>
							)}
							{!lines && <br />}
							<Text className={lines ? classes.dataStringSecondary : classes.dataString}>{data}</Text>
						</div>
					);
				}
			});
		}
		return result;
	}, [additionalData]);

	const renderCard = (print?: boolean) => {
		const barcodeData = {
			error: print ? generatedBarcodeForPrint.error : generatedBarcode.error,
			canvasId: print ? `barcode-for-print-${barcodeId}` : `barcode-${barcodeId}`,
		};
		return (
			<Card
				className={cx(classes.card, {
					[classes.cardView]: !print,
				})}
				data-test={cardDataTest}
			>
				<div className={classes.cardBodyInner}>
					<div
						className={classes.codeDataContainer}
						data-test={`barcode data ${barcodeData.error ? 'error' : 'image'}`}
					>
						{barcodeData.error ? (
							<p>{barcodeData.error}</p>
						) : (
							<div className={classes.codeContainer}>
								{input && input.value && (
									<div className={`${classes.updateCover} update-cover`}>
										<ReloadOutlined className={classes.updateIcon} />
										<div>После сохранения QR-код обновится</div>
									</div>
								)}
								<canvas id={barcodeData.canvasId} className={classes.codeImage} />
							</div>
						)}
					</div>
					<div className={classes.cardDataBlock}>
						<Title
							level={cardTitle.length > 7 && titleLevel === 2 ? 3 : titleLevel}
							className={classes.dataString}
							style={{ lineHeight: 1 }}
						>
							{cardTitle}
						</Title>
						<div className={classes.cardBottomData}>{renderAdditionalData}</div>
					</div>
				</div>
				{!print && input && (
					<div className={classes.controls}>
						{editMode ? (
							<Button
								className={classes.cardButton}
								size="small"
								data-test={`${input.value ? 'not update' : 'update'} barcode`}
								onClick={() => input.onChange(!input.value)}
							>
								{input.value ? t('Не обновлять QR код') : t('Обновить QR-код')}
							</Button>
						) : (
							<ReactToPrint
								bodyClass={classes.toPrint}
								trigger={() => (
									<Button className={classes.cardButton} size="small" data-test="print barcode button">
										{t('Распечатать')}
									</Button>
								)}
								content={() => printCardRef.current}
							/>
						)}
						{barcode && !editMode && isSuperRole(user.role) && (
							<Button
								className={classes.cardButton}
								size="small"
								data-test="copy qr code button"
								onClick={() => copyToClipboard(barcode, 'QR код скопирован')}
							>
								{/* Кнопка для разработки, не переводим */}
								Скопировать QR код
							</Button>
						)}
					</div>
				)}
			</Card>
		);
	};

	return (
		<>
			{renderCard(onlyPrint)}
			{!onlyPrint && (
				<div className={classes.printDiv}>
					<div ref={printCardRef}>{renderCard(true)}</div>
				</div>
			)}
		</>
	);
};

export default memo(CodeCard);
