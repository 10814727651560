import { useTranslation } from 'react-i18next';

import type { CheckProjects } from '~types/checkProjects';

import { useStyles } from '../styles';

type Props = {
	stores: CheckProjects.CheckProject['stores'];
	paused: number | null;
};

const LocationBlock = ({ stores, paused }: Props) => {
	const [t] = useTranslation();
	const resultLocation = [];
	const { classes } = useStyles();

	if (stores.cluster_id?.length) {
		resultLocation.push(t('{{count}} кластер', { count: stores.cluster_id.length }));
	}

	if (stores.store_id?.length) {
		resultLocation.push(t('{{count}} лавка', { count: stores.store_id.length }));
	}

	return (
		<>
			<span>{resultLocation.length ? resultLocation.join(', ') : '—'}</span>
			{paused ? (
				<span className={classes.pauseLabel}>
					{t('{{count}} на паузе', {
						count: paused,
					})}
				</span>
			) : null}
		</>
	);
};

export default LocationBlock;
