export function arrayDiff<T>(first: T[], second: T[]): T[] {
	const secondSet = new Set(second);
	return first.filter((x) => !secondSet.has(x));
}

export function arrayIntersection<T>(first: T[], second: T[]): T[] {
	const secondSet = new Set(second);
	return first.filter((x) => secondSet.has(x));
}

export function areArraysIntersect<T>(first: T[], second: T[]): boolean {
	return !!arrayIntersection(first, second).length;
}
