import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const CourierServicePage = lazy(() => import('../components/CourierServicesPage'));
const CourierServiceDataPage = lazy(() => import('../components/CourierServicesPage/CourierServicesDataPage'));

const courierServicesRoutes = makeRoutes([
	{
		path: '/courier_services',
		element: <CourierServicePage />,
	},
	{
		path: '/courier_services/add',
		permit: 'courier_services_create',
		element: <CourierServiceDataPage editMode addMode />,
	},
	{
		path: '/courier_services/edit/:courier_service_id',
		permit: 'courier_services_save',
		element: <CourierServiceDataPage editMode />,
	},
	{
		path: '/courier_services/:courier_service_id',
		permit: 'courier_services_load',
		element: <CourierServiceDataPage />,
	},
]);

export default courierServicesRoutes;
