import { useCache } from '~cache/useCache';
import type { Stores } from '~types/stores';
import { useUser, useUserConstants } from '~zustand/userData';

const useGetUserStoreCluster = (): {
	store?: Stores.Store;
	cluster?: Stores.Cluster;
} => {
	const user = useUser();
	const constants = useUserConstants();
	const userStoreId = user?.store_id;
	const clusterId = constants.store?.cluster_id;

	const cache = useCache({ stores: userStoreId, clusters: clusterId });

	const userStore = cache.stores[userStoreId ?? ''];
	const userStoreCluster = cache.clusters[clusterId ?? ''];

	return { store: userStore, cluster: userStoreCluster };
};

export default useGetUserStoreCluster;
