import type { AxiosResponse } from 'axios';
import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { Products } from '~types/products';

type Barcodes = {
	items: Products.Item[];
	products: Products.Product[];
};

export default {
	barcode: (data: { barcode: string; cursor?: string }, config?: AxiosAdditionalConfig) =>
		axios
			.post<Barcodes>('/api/admin/search/barcode', data, config)
			.then((response: AxiosResponse<Barcodes | { result: Barcodes }>) => {
				return {
					...response,
					data: {
						result: {
							items: 'items' in response.data ? response.data.items : response.data.result.items,
							products: 'products' in response.data ? response.data.products : response.data.result.products,
						},
					},
				};
			}),
};
