import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ error?: string }>()((theme, { error }) => ({
	input: {
		inlineSize: '100%',
		borderColor: error ? 'red' : gray[4],
	},
	inputError: {
		borderColor: 'red!important',
	},
	emailInput: {
		display: 'flex',
		flexDirection: 'column',
		inlineSize: '100%',
	},
	inputTextError: {
		display: 'flex',
		color: theme.text.textField.errorMessage,
		fontSize: 12,
		marginInlineStart: 2,
	},
	phoneInput: {
		inlineSize: '100%',
		'& .PhoneInputCountry': {
			marginInlineEnd: 8,
		},
	},
}));
