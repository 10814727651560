import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const BusinessCard = lazy(() => import('~shared/components/BusinessCard'));

const businessCardRoutes = makeRoutes([
	{
		path: '/business-card',
		element: <BusinessCard />,
	},
]);

export default businessCardRoutes;
