import { t } from 'i18next';
import isEqual from 'lodash/isEqual';

import { statusesColorDictionary, statusesDictionary } from '~constants/dataPageFields/commonConstants';
import { measures, units } from '~constants/measures';
import { productsScopeDictionary } from '~constants/other';
import { warehouseGroupList } from '~constants/warehouseGroupList';
import { accountingTypesNames } from '~server-types/doc/api/models/product';
import { shelfTagsNames } from '~server-types/doc/api/models/shelf';
import { arrayUniq } from '~shared/utils/arrayUniq';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import renderStatus from '~shared/utils/renderStatus';
import type { Assortments } from '~types/assortments';
import type { ProductGroups } from '~types/productGroups';
import type { Products } from '~types/products';

import type { RegradingProducts } from './types';

export const getUniqueProductsGroups = (products: RegradingProducts<Products.Product | null | undefined>) => {
	const groups: Products.Product['groups'] = [];
	if (products.old) {
		groups.push(...products.old.groups);
	}

	if (products.regrade) {
		groups.push(...products.regrade.groups);
	}

	return arrayUniq(groups);
};

const toFixed = (number: number | string, value = 2) => {
	if (typeof number === 'string') number = parseFloat(number);
	return number ? number.toFixed(value) : '—';
};

export const getImportedValues = (data: Products.Imported) => {
	if (!data) {
		return;
	}

	return {
		...data,
		calorie: data?.calorie
			? t('{{amount}} {{measure_unit}}', { amount: toFixed(data.calorie), measure_unit: t('Ккал') })
			: '',
		fat: data?.fat ? t('{{amount}} {{measure_unit}}', { amount: toFixed(data.fat), measure_unit: t('г.') }) : '',
		carbohydrate: data?.carbohydrate
			? t('{{amount}} {{measure_unit}}', { amount: toFixed(data.carbohydrate), measure_unit: t('г.') })
			: '',
		protein: data?.protein
			? t('{{amount}} {{measure_unit}}', { amount: toFixed(data.protein), measure_unit: t('г.') })
			: '',
		sizes: t('{{height}}/{{width}}/{{depth}} {{measure_unit}}', {
			height: data?.height ?? '—',
			width: data?.width ?? '—',
			depth: data?.depth ?? '—',
			measure_unit: t('мм'),
		}),
		private_label: data?.private_label ? t('Да') : t('Нет'),
		is_meta: data?.is_meta ? t('Да') : t('Нет'),
		warehouse_group_list: warehouseGroupList[data.warehouse_group_list],
	};
};

export const getProductFieldValue = (
	product: Products.Product,
	key: keyof Products.Product,
	productKey: string,
	parentIds: Record<string, Products.Product | undefined>,
	inAssortments: RegradingProducts<Assortments.Product | null | undefined>
) => {
	// @ts-ignore
	const fieldValue = product[key] && product[key].length ? product[key] : undefined;

	// @ts-ignore
	if (key === 'weight') {
		return `${product.amount} ${product.amount_unit ? product.amount_unit : ''}`;
	}

	if (key === 'valid') {
		return product[key] ? t('{{count}} дней', { count: product[key] }) : undefined;
	}

	// @ts-ignore
	if (key === 'in_assortment') {
		return inAssortments[productKey] ? t('Присутствует в статусе') : t('Отсутствует');
	}

	if (key === 'products_scope') {
		return product[key] && product[key].length
			? product[key].map((scope: any) => productsScopeDictionary[scope] || scope).join(', ')
			: '—';
	}

	if (['write_off_before', 'valid'].includes(key)) {
		// @ts-ignore
		return typeof product[key] === 'number' ? t('{{count}} дней', { count: product[key] }) : '';
	}

	if (key === 'quants') {
		return typeof product[key] === 'number' ? `${product.quants} ${product.quant_unit}` : '';
	}

	if (key === 'quant_unit') {
		const value = product && product[key];
		return value ? units[value] : undefined;
	}

	if (key === 'measure_unit') {
		const value = product && product[key];
		return value ? measures[value] : undefined;
	}

	if (key === 'type_accounting') {
		const value = product && product[key];
		return value ? accountingTypesNames[value] : undefined;
	}

	if (key === 'status') {
		return renderStatus(product[key], statusesDictionary, statusesColorDictionary);
	}

	if (key === 'tags') {
		return product[key][0] ? shelfTagsNames[product[key][0]] : t('Тёплая полка');
	}

	if (['created', 'updated'].includes(key)) {
		// @ts-ignore
		return formatTimeAndYear(product[key]);
	}

	if (key === 'parent_id') {
		return product.parent_id
			? `(${parentIds[product.parent_id]?.external_id}) ${parentIds[product.parent_id]?.title}`
			: undefined;
	}

	return fieldValue;
};

export const priceTypes = makeDynamicTranslations({
	store: tDynamic('Цена (базовая)'),
	markdown: tDynamic('Цена (уценка)'),
});

export const checkIfProductsNotEquals = (
	products: RegradingProducts<Products.Product | null | undefined>,
	fieldName: string
) => products.old && products.regrade && !isEqual(products.old[fieldName], products.regrade[fieldName]);

export const productTitles = makeDynamicTranslations({
	old: tDynamic('Старый товар'),
	regrade: tDynamic('Новый товар'),
});

// Фильтруем продукты, оставляя только мастер группы
export const getProductsWithMasterGroups = (
	products: RegradingProducts<Products.Product | null | undefined>,
	productGroupsById: Record<string, ProductGroups.ProductGroup | undefined>
) => {
	const filteredProducts: typeof products = {
		old: undefined,
		regrade: undefined,
	};

	for (const [key, product] of Object.entries(products)) {
		const groups: string[] = [];
		product?.groups.forEach((id: string) => {
			if (Object.keys(productGroupsById).includes(id) && productGroupsById[id]?.vars.imported.type === 'master') {
				groups.push(id);
			}
		});

		filteredProducts[key] = {
			...product,
			groups,
		};
	}

	return filteredProducts;
};
