import { useEffect, useState } from 'react';

import { useStyles } from '../Card/styles';
import { getRandomCompliment } from '../Card/utils';

type Props = {
	type: string;
};

function Frame({ src, scrolling = 'no' }: { src: string; scrolling?: string }) {
	// eslint-disable-next-line @eslint-react/dom/no-missing-iframe-sandbox
	return <iframe width="100%" height="600" frameBorder="none" scrolling={scrolling} src={src}></iframe>;
}

export function SpecialCard({ type }: Props) {
	const { classes } = useStyles();
	const [randomCompliment, setRandomCompliment] = useState<string | null>(null);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout> | null = null;
		if (type === 'giftcard') {
			const element = document.querySelector('#root > section > section')! as HTMLDivElement;
			element.style.transform = 'scale(-1)';

			timer = setTimeout(() => {
				element.style.transform = 'scale(1)';
				setRandomCompliment(getRandomCompliment());
			}, 1000);
		}
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, []);

	if (type === 'tamagochi') {
		return <Frame src="https://www.retrogames.cc/embed/25130-tamagotchi-usa-europe.html" />;
	}
	if (type === 'chatgpt') {
		return <Frame src="https://www.aitianhu.com/#/chat/1002" />;
	}
	if (type === 'giftcard') {
		return randomCompliment ? <div className={classes.giftCard}>{randomCompliment}</div> : null;
	}
	if (type === 'wms') {
		return <Frame src={document.location.href} />;
	}
	if (type === 'eda') {
		return <Frame src="https://eda.yandex.ru/" scrolling="yes" />;
	}

	return null;
}
