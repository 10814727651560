import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import type { PeriodState } from '../../healthTypes';
import { useStyles } from './styles';

type Props = {
	comparisonType: PeriodState['comparisonType'];
	onChange: (value: any) => void;
	dataTest?: string;
};

const ComparisonTypeSelect = ({ comparisonType, onChange, dataTest }: Props) => {
	const { classes } = useStyles();
	const [t] = useTranslation();
	return (
		<div>
			<Select
				className={classes.typeSelect}
				defaultValue={comparisonType}
				onChange={onChange}
				data-test={dataTest ?? 'health comparison type select'}
				options={[
					{ value: 'day', label: t('День ко дню'), 'data-test': 'health comparison type select day' },
					{ value: 'week', label: t('Неделя к неделе'), 'data-test': 'health comparison type select week' },
				]}
			/>
		</div>
	);
};

export default ComparisonTypeSelect;
