import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

type Measures = Record<string, string>;
export const measures: Measures = makeDynamicTranslations({
	unit: tDynamic('Шт'),
	g: tDynamic('г'),
	kg: tDynamic('Кг'),
	liter: tDynamic('Литр'),
});

export const units: Measures = makeDynamicTranslations({
	milligram: tDynamic('Мг'),
	gram: tDynamic('г'),
	kilogram: tDynamic('Кг'),
	milliliter: tDynamic('Мл'),
	centiliter: tDynamic('Сл'),
	liter: tDynamic('Л'),
	unit: tDynamic('Шт'),
});
