import { Radio } from 'antd';
import type { CancelTokenSource } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProductSearchField } from '../ProductSelectField';
import { productSearchFields } from '../ProductSelectField';
import { searchProducts } from '../ProductSelectField/utils';
import type { SelectFieldProps } from '../SelectField';
import { useStyles } from '../styles';
import MultipleEntitySelect from './index';

const MultipleProductSelectField = ({ ...props }: SelectFieldProps) => {
	const [t] = useTranslation();
	const [searchField, setSearchField] = useState<ProductSearchField>('external_id');
	const { classes } = useStyles();
	const searchFunc = async (data: string, cancelTokenSource: CancelTokenSource | undefined) => {
		return await searchProducts(data, searchField, cancelTokenSource);
	};

	const paramsComponent = (
		<Radio.Group
			onChange={(e) => setSearchField(e.target.value)}
			defaultValue={searchField}
			className={classes.radioGroup}
		>
			{(Object.keys(productSearchFields) as ProductSearchField[]).map((key: ProductSearchField) => (
				<Radio.Button key={key} value={key} className={`data-test-radio-${productSearchFields[key]}`}>
					{productSearchFields[key]}
				</Radio.Button>
			))}
		</Radio.Group>
	);

	return (
		<MultipleEntitySelect
			entity="product"
			keyId="products"
			linkCategory="products"
			searchFunction={searchFunc}
			valueString={t('Выбран {{count}} продукт', {
				count: props.input.value?.length ?? 0,
			})}
			paramsComponent={paramsComponent}
			skip={searchField === 'barcode'}
			{...props}
		/>
	);
};

export default MultipleProductSelectField;
