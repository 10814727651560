import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	subtitleContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	errorDetail: {
		fontSize: theme.uiKit.semantic.typography.caption1,
		marginBlockStart: theme.uiKit.semantic.gaps.gap240,
	},
}));
