type Option = {
	label: string;
	value: string;
};

export type FieldConfig = {
	type: 'text' | 'select';
	placeholder: string;
	defaultValue?: string;
	options?: Option[];
};

export const modelOptions: Option[] = [
	{ label: 'Продукт', value: 'product' },
	{ label: 'Посылка', value: 'item' },
	{ label: 'Полка', value: 'shelf' },
	{ label: 'Ассортимент', value: 'assortment' },
	{ label: 'Прайс-лист', value: 'price_list' },
	{ label: 'Черновой прайс-лист', value: 'price_list_draft' },
	// { label: "Группа", value: "group" },
	{ label: 'Склад', value: 'store' },
	{ label: 'Курьер', value: 'courier' },
	{ label: 'Ордер', value: 'order' },
	{ label: 'Пользователь', value: 'user_executors' },
	{ label: 'Компания', value: 'company' },
	{ label: 'Тег курьерской смены', value: 'courier_shift_tag' },
	{ label: 'Кластер', value: 'cluster' },
	{ label: 'Проект контроля', value: 'check_project' },
	{ label: 'Курьерская служба', value: 'courier_service' },
	{ label: 'Кошелек', value: 'wallet' },
	{ label: 'Курьерская смена', value: 'courier_shift' },
	{ label: 'Семпл', value: 'sample' },
];

export const searchFieldsConfig: Record<string, FieldConfig> = {
	product: {
		type: 'text',
		placeholder: 'Продукт',
	},
	store: {
		type: 'text',
		placeholder: 'Склад',
	},
	model: {
		type: 'select',
		placeholder: 'Сущность',
		options: modelOptions,
		defaultValue: 'store',
	},
	key: {
		type: 'text',
		placeholder: 'Идентификатор',
	},
	order: {
		type: 'text',
		placeholder: 'Ордер',
	},
	surprise: {
		type: 'text',
		placeholder: 'Приключения',
	},
};
