import i18n from '~/i18n';
import getProperTime from '~shared/utils/transformSecondsToTimeString';

export const getGraphFormatter = (metric: string) =>
	metric === 'orders_count'
		? (value: number) => i18n.t('{{value}} шт.', { value: value })
		: (value: number) => getProperTime(value, true, true);

export const defaultConfig = {
	yAxisOrientation: 'right',
	lineStroke: '#2F54EB',
	removeDots: true,
	isAnimationActive: false,
};
