import { Col, InputNumber, Row } from 'antd';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import type { CalendarParams } from '~constants/dataPageFields/types';
import { withTimezone } from '~shared/utils/datesUtils';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';

import DatePicker from '../../DayJsComponents/DatePicker';
import { useStyles } from '../styles';
import type { FieldProps } from '../types';
import { EmptyValue, getColSpan } from '../utils';
import { intervalViewText } from './utils';

interface Props {
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	format: any;
	editMode?: boolean;
	addMode?: boolean;
	params?: CalendarParams;
	pickerParams?: Record<string, any>;
	disabled?: boolean;
	additionalContent?: JSX.Element;
}
const defaultParams = Object.freeze({ onlyDate: false, withSeconds: true, alwaysShowYear: true });
const defaultPickerParams = Object.freeze({ showTime: true });

const SchetsScheduleField = ({
	id,
	colspan,
	label,
	editMode,
	input,
	params = defaultParams,
	pickerParams = defaultPickerParams,
	disabled,
	addMode,
	additionalContent,
}: Props & FieldProps) => {
	const { cx, classes } = useStyles();
	const [t] = useTranslation();
	const changeField = (field: 'start_time' | 'interval', value: any) => {
		input.onChange({ ...input.value, [field]: value });
	};

	return (
		<>
			<Row align="top" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={editMode ? { minHeight: 42 } : {}}>
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label className={classes.label} htmlFor={id}>
						{t('Дата и время начала')}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
				<Col {...getColSpan(colspan)}>
					{editMode ? (
						<div className={cx(classes.inputContainer, classes.inputContainerSchedule, 'wms-input-container')}>
							<DatePicker
								placeholder="DD.MM.YYYY HH:mm"
								value={
									!addMode && input.value?.start_time
										? withTimezone(input.value?.start_time, params.timezone)
										: undefined
								}
								format={pickerParams?.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
								popupClassName="data-test-select-schet-time"
								data-test={`data form ${label}`}
								onChange={(date) => {
									return pickerParams?.showTime
										? changeField('start_time', withTimezone(date, params.timezone, true)?.format() || null)
										: changeField('start_time', withTimezone(date?.second(0), params.timezone, true)?.format() || null);
								}}
								disabled={disabled}
								{...pickerParams}
							/>
						</div>
					) : (
						<p className={classes.valueOnViewMode} data-test={`data form ${label}`}>
							{input.value?.start_time ? (
								formatTimeAndYear(input.value?.start_time, {
									timezone: params.timezone,
									onlyDate: params.onlyDate,
									withSeconds: params.withSeconds,
									alwaysShowYear: params.alwaysShowYear,
								})
							) : (
								<EmptyValue />
							)}
						</p>
					)}
				</Col>
			</Row>
			<Row align="bottom" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={editMode ? { minHeight: 42 } : {}}>
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label className={classes.label} htmlFor={id}>
						{t('Повторять каждые')}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
				<Col style={{ alignSelf: 'baseline' }} {...getColSpan(colspan)}>
					{editMode ? (
						<div
							data-test="data form repeat field"
							className={cx(
								classes.inputContainer,
								classes.inputContainerSchedule,
								classes.inputContainerScheduleInterval,
								'wms-input-container'
							)}
						>
							<div>
								<label>{t('Месяцы')}</label>
								<InputNumber
									data-test="data form repeat months"
									placeholder={t('Месяцы')}
									value={input.value?.interval?.months ?? 0}
									min={0}
									onChange={(months) =>
										changeField('interval', {
											...input.value?.interval,
											months: months ?? 0,
										})
									}
								/>
							</div>
							<div>
								<label>{t('Дни')}</label>
								<InputNumber
									data-test="data form repeat days"
									placeholder={t('Дни')}
									value={input.value?.interval?.days ?? 0}
									min={0}
									onChange={(days) =>
										changeField('interval', {
											...input.value?.interval,
											days: days ?? 0,
										})
									}
								/>
							</div>
							<div>
								<label>{t('Часы')}</label>
								<InputNumber
									data-test="data form repeat hours"
									value={input.value?.interval?.hours ?? 0}
									min={0}
									placeholder={t('Часы')}
									onChange={(hours) =>
										changeField('interval', {
											...input.value?.interval,
											hours: hours ?? 0,
										})
									}
								/>
							</div>
							<div>
								<label>{t('Минуты')}</label>
								<InputNumber
									data-test="data form repeat minutes"
									value={input.value?.interval?.minutes ?? 0}
									min={0}
									placeholder={t('Минуты')}
									onChange={(minutes) =>
										changeField('interval', {
											...input.value?.interval,
											minutes: minutes ?? 0,
										})
									}
								/>
							</div>
							<div>
								<label>{t('Секунды')}</label>
								<InputNumber
									data-test="data form repeat seconds"
									value={input.value?.interval?.seconds ?? 0}
									min={0}
									placeholder={t('Секунды')}
									onChange={(seconds) =>
										changeField('interval', {
											...input.value?.interval,
											seconds: seconds ?? 0,
										})
									}
								/>
							</div>
						</div>
					) : (
						<div className={cx(classes.valueOnViewMode)} data-test="schet schedule value">
							{input.value?.interval ? intervalViewText(input.value?.interval) : <EmptyValue />}
						</div>
					)}
				</Col>
			</Row>
			{additionalContent}
		</>
	);
};

export default SchetsScheduleField;
