import type { Field, SectionConfig } from '~constants/dataPageFields/types';

export const mapFields = (sections: SectionConfig[], cb: (field: Field) => Field | undefined): SectionConfig[] => {
	let mappedSections: SectionConfig[] = sections.map((section) => {
		let fields = section.fields.map((field) => cb(field));
		fields = fields.filter((field) => field);
		return { ...section, fields } as SectionConfig;
	});
	mappedSections = mappedSections.filter((section) => {
		return section.fields.length > 0;
	});
	return mappedSections;
};
