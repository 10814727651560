import { Link } from 'react-router-dom';

import { apiLoadProperties } from '../../../../../multiSelect/initMultiSelect';

const EntityLink = ({ id, entity, title }: { id: string; entity: string; title?: string | null }) => {
	//TODO: при замене полей проверить что selectEntity совпадает со ссылкой. Если нет, ввести еще одну переменную

	return apiLoadProperties[entity].shouldHaveLink ? (
		<Link data-test={`list entity ${id}`} to={`/${entity}/${id}`}>
			{title ?? id}
		</Link>
	) : (
		<div data-test={`list entity ${id}`}>{title ? title : id}</div>
	);
};

export default EntityLink;
