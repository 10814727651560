import { useMemo } from 'react';

import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import { arrayUniq } from '~shared/utils/arrayUniq';
import type { Stores } from '~types/stores';
import type { User } from '~types/user';
import { getStoresData, useStoresData } from '~zustand/storesData';

import type { LocationRowType } from './useGetLocationTreeFromCompany';
import { getAllVisibleStores } from './utils';

type CollectionsByEntity = {
	currentStoresByCluster: Record<string, string[]>;
	currentStoresByCompany: Record<string, string[]>;
	allStoresByCluster: Record<string, string[]>;
	allStoresByCompany: Record<string, string[]>;
	valueStoresByCluster: Record<string, string[]>;
	valueStoresByCompany: Record<string, string[]>;
};

const addStoreToMap = (
	collection: Record<string, string[]>,
	store: Pick<Stores.Store, 'store_id' | 'company_id' | 'cluster_id'>,
	idProp: 'company_id' | 'cluster_id'
) => {
	collection[store[idProp]] ??= [];
	collection[store[idProp]].push(store.store_id);
};

type Arguments = {
	inputValue?: string[];
	visibleTree?: LocationRowType[];
	companyId?: User.User['company_id'] | undefined;
	pauseCondition?: (value: Pick<Stores.Store, 'options' | 'status'>) => boolean;
};

export default function ({ inputValue, visibleTree, companyId, pauseCondition }: Arguments) {
	const { stores, loading: storesLoading } = useStoresData(companyId);

	useAsyncEffect(async () => {
		if (companyId) {
			await getStoresData(companyId);
		}
	}, [companyId]);

	const storesMap = useMemo(() => {
		if (storesLoading) return {} as Record<string, (typeof stores)[0]>;
		return stores.reduce(
			(map, store) => {
				map[store.store_id] = store;
				return map;
			},
			{} as Record<string, (typeof stores)[0]>
		);
	}, [stores, storesLoading]);

	const initCollectionsState: CollectionsByEntity = {
		currentStoresByCluster: {},
		currentStoresByCompany: {},
		allStoresByCluster: {},
		allStoresByCompany: {},
		valueStoresByCluster: {},
		valueStoresByCompany: {},
	};

	const {
		allStoresByCluster,
		allStoresByCompany,
		valueStoresByCluster,
		valueStoresByCompany,
		currentStoresByCluster,
		currentStoresByCompany,
	}: CollectionsByEntity = useMemo(() => {
		if (storesLoading) {
			return initCollectionsState;
		}

		const inputValueIdsSet = new Set(inputValue ?? []);

		// Забираем все id склада из дерева, которое видно прямо сейчас
		const currentTreeIds = visibleTree ? getAllVisibleStores(visibleTree) : [];
		const currentTreeIdsSet = new Set(currentTreeIds);

		return stores.reduce((map, store) => {
			addStoreToMap(map.allStoresByCluster, store, 'cluster_id');
			addStoreToMap(map.allStoresByCompany, store, 'company_id');

			if (inputValue && inputValueIdsSet.has(store.store_id)) {
				addStoreToMap(map.valueStoresByCluster, store, 'cluster_id');
				addStoreToMap(map.valueStoresByCompany, store, 'company_id');
			}

			if (currentTreeIdsSet.has(store.store_id)) {
				addStoreToMap(map.currentStoresByCluster, store, 'cluster_id');
				addStoreToMap(map.currentStoresByCompany, store, 'company_id');
			}

			return map;
		}, initCollectionsState);
	}, [stores, storesMap, storesLoading, inputValue, visibleTree]);

	const getIsStorePaused = (storeId: string): boolean => {
		const store = storesMap[storeId];
		if (store && pauseCondition) {
			return pauseCondition(store);
		}
		return false;
	};

	const getTotalPausedCount = ({
		storeIds,
		clusterIds,
	}: {
		storeIds: string[];
		clusterIds: string[];
	}): number | null => {
		if (storesLoading) return null;

		const allStoreIds = [...storeIds, ...clusterIds.flatMap((id) => allStoresByCluster[id] ?? [])];
		const uniqueStoreIds = arrayUniq(allStoreIds);

		return uniqueStoreIds.reduce((count, storeId) => {
			if (getIsStorePaused(storeId)) count += 1;
			return count;
		}, 0);
	};

	const getPausedByCollection = (collection: Record<string, string[]>) => {
		return Object.keys(collection).reduce((map: Record<string, number>, item: string) => {
			map[item] = collection[item].reduce((count: number, store: string) => {
				if (getIsStorePaused(store)) {
					count += 1;
				}
				return count;
			}, 0);
			return map;
		}, {});
	};

	const pausedCountClusterMap = useMemo(() => {
		return getPausedByCollection(currentStoresByCluster);
	}, [currentStoresByCluster]);

	const pausedCountCompanyMap = useMemo(() => {
		return getPausedByCollection(currentStoresByCompany);
	}, [currentStoresByCompany]);

	const collectionMap = {
		cluster: {
			all: allStoresByCluster,
			picked: valueStoresByCluster,
		},
		company: {
			all: allStoresByCompany,
			picked: valueStoresByCompany,
		},
	};

	const isEntityPartiallyPicked = (type: 'store' | 'cluster' | 'company', id: string) => {
		if (type === 'store') return false;
		const all = collectionMap[type].all[id];
		const picked = collectionMap[type].picked[id];

		return all && picked ? all.length > picked.length : false;
	};

	const isEntityFullyPicked = (type: 'store' | 'cluster' | 'company', id: string) => {
		if (type === 'store') {
			return false;
		}

		const all = collectionMap[type].all[id];
		const picked = collectionMap[type].picked[id];
		return all && picked ? all.length === picked.length : false;
	};

	return {
		stores,
		storesLoading,
		allStoresByCluster,
		isEntityPartiallyPicked,
		isEntityFullyPicked,
		getIsStorePaused,
		getTotalPausedCount,
		pausedCountClusterMap,
		pausedCountCompanyMap,
	};
}
