import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()({
	container: {
		inlineSize: 750,
		marginBlock: '0',
		marginInline: 'auto',
		marginBlockEnd: 'calc(100vh - 735px)',
	},
	lines: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
		gap: 14,
	},
	label: {
		fontSize: '36px',
		lineHeight: '40px',
		textAlign: 'center',
		fontWeight: 600,
		marginBlockStart: 120,
		marginBlockEnd: 64,
	},
	line: {
		display: 'flex',
		flexDirection: 'column',
		gap: 14,
		alignItems: 'center',
	},
	blocks: {
		display: 'flex',
		flexDirection: 'column',
		gap: 14,
		listStyle: 'none',
		padding: 0,
	},
	block: {
		background: 'rgb(249 250 251)',
		color: 'rgb(31 41 55)',
		padding: 12,
		borderRadius: 6,
		textAlign: 'center',
	},
	blocksTitle: {
		fontSize: '18px',
		lineHeight: '28px',
		margin: 0,
	},
});
