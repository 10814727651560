import axios from 'axios';

import type { Suptools } from '~types/suptools';

export default {
	search: (request: { handler: string; kwargs: Record<string, any> }) =>
		axios.post('/api/admin/suptools/search', request),

	searchHandlers: () => axios.post<{ result: Suptools.SearchHandler[] }>('/api/admin/suptools/search_handlers', {}),
};
