import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	storeStatTable: {
		'.ant-table-thead .ant-table-cell': {
			fontWeight: 500,
			backgroundColor: `${theme.bgColor.secondary} !important`,
		},
	},
	surge: {
		color: '#C81EFA',
		fontSize: 12,
		lineHeight: '14px',
		fontWeight: 700,
	},
	surgeContainer: {
		display: 'flex',
		paddingInlineStart: 10,
	},
	storeTitleCell: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	tableauIcon: {
		cursor: 'pointer',
		'&:hover': {
			color: theme.text.btnLink,
		},
	},
	metricWithGoalCell: {
		display: 'flex',
		flexDirection: 'column',
	},
	goalText: {
		fontSize: '0.9em',
		color: theme.text.light,
		lineHeight: 0.9,
	},
	goalOfExceededMetric: {
		color: theme.text.error,
	},
}));
