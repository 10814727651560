import { makeStyles } from '~styles/theme';

import {
	accentYellow,
	blocksBackground,
	darkYellow,
	detailText,
	headerColor,
	lightRed,
	mainText,
	tagBackground,
	tagText,
} from '../../styles';

export const useStyles = makeStyles()((theme) => ({
	orderCard: {
		display: 'grid',
		gridAutoFlow: 'row',
		gridTemplateAreas: `'main tags time'
										 'main tags time'
										 'bottom bottom bottom'`,
		gridAutoColumns: '20fr 5fr max-content',
		backgroundColor: blocksBackground,
		color: theme.text.white,
		fontSize: 16,
		borderRadius: 12,
		paddingBlock: 8,
		paddingInline: 8,
		marginBlock: 6,
		marginInline: 0,
		'& > *:first-child': {
			padding: 0,
		},
	},
	mainCardBlock: {
		fontSize: 16,
		lineHeight: '1.188em',
		gridArea: 'main',
		'& > *:not(:last-child)': {
			marginBlockEnd: 4,
		},
	},
	tagsCardBlock: {
		gridArea: 'tags',
		marginBlockStart: 3,
		'& > *': {
			display: 'grid',
			justifyContent: 'flex-start',
			direction: 'rtl',
			gridTemplateColumns: 'repeat(2, 1em)',
			rowGap: 4,
			columnGap: 4,
			fontSize: 16,
		},
	},
	timeCardBlock: {
		gridArea: 'time',
		minInlineSize: '3.143em',
		fontFeatureSettings: 'initial',
		fontVariantNumeric: 'tabular-nums',
		marginInlineStart: '0.286em',
		marginBlockEnd: '0.286em',
		color: theme.text.white,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		'& > *': {
			fontSize: '1em',
			color: theme.text.white,
			lineHeight: '1.188em',
		},
	},
	mainNumber: {
		color: theme.text.white,
		opacity: 0.6,
	},
	accentedNumber: {
		color: theme.text.white,
	},
	executerRow: {
		display: 'grid',
		gridTemplateAreas: "'avatar text'",
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'center',
		gap: '0.25em',
		fontSize: 16,
	},
	icon: {
		inlineSize: '1em',
		blockSize: '1em',
		fill: theme.text.white,
		opacity: 0.8,
	},
	unAssignedIcon: {
		opacity: 0.5,
	},
	taxiIcon: {
		fill: headerColor,
		opacity: 1,
	},
	executerText: {
		gridArea: 'text',
		fontSize: '0.813em',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	executer: {
		gridArea: 'text',
		color: theme.text.white,
		opacity: 0.5,
	},
	courier: {
		gridArea: 'text',
		color: theme.text.white,
	},
	notAssigned: {
		gridArea: 'text',
		color: mainText,
	},
	detailStatus: {
		inlineSize: 'fit-content',
		paddingBlock: '0.188em',
		paddingInline: '0.5em',
		marginBlockStart: '0.188em',
		marginBlockEnd: '0.25em !important',
		backgroundColor: tagBackground,
		fontSize: '0.813em',
		color: tagText,
		borderRadius: 8,
	},
	yellowDetail: {
		color: accentYellow,
		backgroundColor: darkYellow,
	},
	redDetail: {
		color: detailText,
		backgroundColor: lightRed,
	},
	statusTime: {
		fontSize: '0.813em',
		opacity: 0.6,
		marginBlockStart: '0.357em',
	},
	yellowTime: {
		color: accentYellow,
	},
	partnerOrderId: {
		color: mainText,
	},
	iconStyle: {
		fontSize: '1em',
	},
	heavyIcon: {
		color: '#5AC31A',
	},
	pauseText: {
		color: theme.text.white,
		opacity: 0.6,
	},
	cardBottom: {
		gridArea: 'bottom',
		display: 'grid',
		gridTemplateColumns: '1fr 6em',
		alignContent: 'start',
	},
	executersContainer: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: 4,
	},
	extraWaypoints: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'flex-end',
		gap: 4,
	},
	extraWaypointsTag: {
		fontSize: '0.786em',
		lineHeight: '0.929em',
		fontWeight: 700,
		paddingInline: 4,
		paddingBlock: 1,
		marginInlineEnd: 0,
		blockSize: 'fit-content',
		inlineSize: 'fit-content',
		marginBlock: 5,
		'&.ant-tag.ant-tag-has-color': {
			color: theme.text.tableau.labelColor,
		},
	},
	extraWaypointsTagRed: {
		'&.ant-tag.ant-tag-has-color': {
			color: theme.text.white,
		},
	},
}));
