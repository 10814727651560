import { useEffect } from 'react';

import { setEditMode } from '~zustand/metaInfo';

export default function (editMode: boolean | undefined) {
	useEffect(() => {
		setEditMode(!!editMode);
		return function () {
			setEditMode(false);
		};
	}, [editMode]);
}
