import { Button, Col, Row, Select } from 'antd';
import dayjs from 'dayjs';
import type { IntRange } from 'rc-picker/lib/interface';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TimePicker from '~shared/components/DayJsComponents/TimePicker';
import { DeleteOutlined } from '~shared/utils/icons';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from '../styles';
import type { Day } from './index';
import { useStyles as useTimeStyles } from './styles';

export const timetableObj = makeDynamicTranslations({
	monday: tDynamic('Понедельник'),
	tuesday: tDynamic('Вторник'),
	wednesday: tDynamic('Среда'),
	thursday: tDynamic('Четверг'),
	friday: tDynamic('Пятница'),
	saturday: tDynamic('Суббота'),
	sunday: tDynamic('Воскресенье'),
	everyday: tDynamic('Каждый день'),
	workday: tDynamic('Рабочие дни'),
	weekend: tDynamic('Выходные дни'),
	holiday: tDynamic('Праздники'),
	day_interval: tDynamic('enums.TIMETABLE_ITEM_TYPES.day_interval'),
});

interface Props {
	id: string;
	newItem: boolean;
	time: Day;
	disabled?: boolean;
	editMode?: boolean;
	index: number;
	onChange: (value: unknown) => void;
	value: Day[];
	special?: boolean;
	onDelete: (index: number) => void;
	minuteStep?: IntRange<1, 59>;
	typeValue?: string;
}

const timepickerProps = {
	showSecond: false,
	format: 'HH:mm',
	allowClear: false,
};

const TimeRow = ({
	time,
	editMode,
	onChange,
	value,
	disabled,
	index,
	onDelete,
	newItem,
	minuteStep,
	typeValue,
}: Props) => {
	const [t] = useTranslation();
	const [type, setType] = useState<string>(time.type);
	const [begin, setBegin] = useState<string | undefined>(time?.begin);
	const [end, setEnd] = useState<string | undefined>(time?.end);
	const { classes: timeClasses } = useTimeStyles();
	const { classes: commonClasses } = useCommonStyles();
	const { classes } = useStyles();

	const days: JSX.Element[] = Object.keys(timetableObj).map((key) => (
		<Select.Option key={key} value={key}>
			{timetableObj[key]}
		</Select.Option>
	));

	useEffect(() => {
		if (type && begin && end && Array.isArray(value)) {
			if (newItem) {
				if (!value.find((el) => `${el.type}_${el.begin}_${el.end}` === `${type}_${begin}_${end}`)) {
					onChange([...value, { type, begin, end }]);
				}
			} else {
				onChange(
					value.map((item, i) => {
						if (i === index) {
							return { type, begin, end };
						}
						return item;
					})
				);
			}
		}
	}, [type, begin, end]);

	return (
		<Row gutter={[10, { xs: 0, sm: 20 }]} data-test={`time row ${index}`}>
			{editMode ? (
				<>
					<Col
						xs={{ span: 11 }}
						md={{ span: 11 }}
						lg={{ span: 11 }}
						xl={{ span: 11 }}
						className={classes.labelContainer}
					>
						<Select
							className={classes.select}
							defaultValue={timetableObj[type] || typeValue}
							onChange={(value) => setType(value)}
							disabled={disabled || !!typeValue}
							data-test={`data form time row day select ${index}`}
							popupClassName={`data-test-select-dropdown-time-row-day-select-${index}`}
						>
							{days}
						</Select>
					</Col>
					<Col xs={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
						<TimePicker
							disabled={!editMode || disabled}
							{...timepickerProps}
							data-test={`data form time row start picker ${index}`}
							dropdownClassName={`data-test-timepicker-time-row-start-picker-${index}`}
							minuteStep={minuteStep}
							placeholder={t('С')}
							className={timeClasses.timePicker}
							isTimeRange={false}
							onChange={(time) => {
								if (Array.isArray(time)) throw Error('Incorrect value type');
								setBegin(time?.format('HH:mm:ss'));
							}}
							value={begin ? dayjs(begin, 'HH:mm:ss') : undefined}
							showNow={false}
						/>
					</Col>
					<Col xs={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
						<TimePicker
							disabled={!editMode || disabled}
							{...timepickerProps}
							data-test={`data form time row end picker ${index}`}
							dropdownClassName={`data-test-timepicker-time-row-end-picker-${index}`}
							minuteStep={minuteStep}
							placeholder={t('До')}
							className={timeClasses.timePicker}
							isTimeRange={false}
							onChange={(time) => {
								if (Array.isArray(time)) throw Error('Incorrect value type');
								setEnd(time?.format('HH:mm:ss'));
							}}
							value={end ? dayjs(end, 'HH:mm:ss') : undefined}
							showNow={false}
						/>
					</Col>
					<Col xs={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }} xl={{ span: 1 }}>
						{editMode && !disabled && (
							<Button
								ghost
								shape="circle"
								data-test="remove button"
								icon={<DeleteOutlined />}
								style={{ border: 'none' }}
								className={commonClasses.btnIcon}
								onClick={() => onDelete(index)}
							/>
						)}
					</Col>
				</>
			) : (
				<Col xs={{ span: 24 }}>
					<li>
						{t('{{day}} с {{begin}} до {{end}}', {
							day: timetableObj[type],
							begin,
							end,
						})}
					</li>
				</Col>
			)}
		</Row>
	);
};

export default TimeRow;
