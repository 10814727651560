import { makeRoutes } from '~shared/utils/makeRoutes';

import Health from '../components/Health';

const health = makeRoutes([
	{
		path: '/health_store',
		permit: 'health_store',
		element: <Health myStore />,
	},
	{
		path: '/health',
		permit: 'health_store',
		element: <Health />,
	},
]);

export default health;
