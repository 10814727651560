import { Col, Row, Switch, Tooltip } from 'antd';
import { useEffect } from 'react';

import { ReactComponent as QuestionCircleSolid } from '~images/icons/question-circle-solid.svg';
import Icon from '~shared/utils/icons';

import { useStyles } from '../styles';
import type { FieldProps } from '../types';
import { getColSpan } from '../utils';

interface TextFieldProps extends FieldProps {
	className?: string;
	onPressEnter: () => void;
	inputType?: 'text' | 'number';
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	defaultChecked?: boolean;
	hasLock?: boolean;
	addMode?: boolean;
	leftSide?: boolean;
	tooltip?: string;
	dataTest?: string;
}

const SwitchField = ({
	className,
	id,
	label,
	input,
	disabled,
	editMode,
	colspan,
	defaultChecked,
	leftSide,
	tooltip,
	dataTest,
}: TextFieldProps) => {
	const { cx, classes } = useStyles();

	useEffect(() => {
		if (defaultChecked !== undefined) {
			input.onChange(defaultChecked);
		}
	}, [defaultChecked]);

	return (
		<Row align="bottom" className={className} gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]}>
			{leftSide ? (
				<div className={classes.switchLine}>
					<Switch
						data-test={dataTest ?? `data form ${label}${disabled ? ' disabled' : ''}`}
						disabled={disabled || !editMode}
						defaultChecked={defaultChecked}
						onChange={input.onChange}
						checked={input.value}
					/>
					<Col className={classes.leftLabelContainer}>
						<label className={classes.label} htmlFor={id}>
							{label}
						</label>
					</Col>
				</div>
			) : (
				<>
					<Col {...getColSpan(colspan)} className={classes.labelContainer}>
						<label className={classes.label} htmlFor={id}>
							{label}
						</label>
						<div className={classes.dotSeparator} />
					</Col>
					<Col {...getColSpan(colspan)} className={classes.labelContainer}>
						<div className={classes.tooltipWrapper}>
							<Switch
								data-test={dataTest ?? `data form ${label}${disabled ? ' disabled' : ''}`}
								disabled={disabled || !editMode}
								defaultChecked={defaultChecked}
								onChange={input.onChange}
								checked={input.value}
							/>
							{tooltip && (
								<Tooltip title={tooltip}>
									<Icon
										component={QuestionCircleSolid}
										className={cx(classes.tooltipIcon, classes.tooltipIconSwitch)}
									/>
								</Tooltip>
							)}
						</div>
					</Col>
				</>
			)}
		</Row>
	);
};

export default SwitchField;
