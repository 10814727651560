import { notification } from '@lavka/ui-kit';
import type { CancelTokenSource } from 'axios';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import EmptyTableState from '~shared/components/EmptyState/EmptyTableState';
import type { FieldProps } from '~shared/components/Fields/types';
import type { Assortments } from '~types/assortments';
import type { Products } from '~types/products';
import type { Stores } from '~types/stores';
import { useUser } from '~zustand/userData';

import { CompareProductsData } from './CompareProductsData';
import { RegradingContext } from './context';
import SelectProductsWithSearch from './SelectProductsWithSearch';
import { useStyles } from './styles';
import type { RegradingProducts, RegradingProductsType } from './types';

interface Props {
	addMode?: boolean;
	editMode: boolean;
	colspan?: Record<string, { span: number }>;
}

export const Regrading = memo(({ addMode, editMode, colspan, input, ...props }: Props & FieldProps) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [products, setProducts] = useState<RegradingProducts<Products.Product | null | undefined>>({
		old: null,
		regrade: null,
	});
	const cancelTokenSource = useRef<CancelTokenSource>();
	const [inAssortments, setInAssortments] = useState<RegradingProducts<Assortments.Product | null>>({
		old: null,
		regrade: null,
	});
	const [price, setPrice] = useState<
		RegradingProducts<{
			store: string | null;
			markdown: string | null;
		} | null>
	>({ old: { store: null, markdown: null }, regrade: { store: null, markdown: null } });
	const user = useUser();

	const cache = useCache({
		stores: user.store_id,
		products: [input.value?.[0]?.product_id, input.value?.[0]?.regrading_target],
	});

	const checkInAssortment = useCallback(
		async (assortmentId: string, productId: string, fieldName: RegradingProductsType) => {
			try {
				const { data } = await api.assortmentProduct.load({
					assortment_id: assortmentId,
					product_id: productId,
				});

				if (data.result) {
					setInAssortments({
						...inAssortments,
						[fieldName]: data.result,
					});
				}
			} catch {
				notification.error({
					message: t('Не удалось получить продукт в ассортименте'),
				});
			}
		},
		[products]
	);

	const getPrice = useCallback(
		async (priceListId: string, productId: string, fieldName: RegradingProductsType) => {
			try {
				const { data } = await api.priceListProducts.load({
					price_list_id: priceListId,
					product_id: productId,
				});
				if (data.result) {
					setPrice({ ...price, [fieldName]: data.result.price });
				}
			} catch {
				notification.error({
					message: t('Не удалось получить продукт в прайс-листе'),
				});
			}
		},
		[products]
	);

	useEffect(() => {
		if (input.value?.length) {
			setProducts({
				old: cache.products[input.value?.[0]?.product_id],
				regrade: cache.products[input.value?.[0]?.regrading_target],
			});
		}
	}, [input.value, cache.products[input.value?.[0]?.product_id], cache.products[input.value?.[0]?.regrading_target]]);

	const findProduct = async (
		value: string | null,
		option: Record<string, string> | null,
		fieldName: RegradingProductsType
	) => {
		if (!value) {
			setProducts({ ...products, [fieldName]: value });
			return;
		}

		try {
			const { data } = await api.products.list(
				{ title: value, cursor: '' },
				{ cancelToken: cancelTokenSource.current?.token }
			);

			const product = data.results.find((el) => el.product_id === option?.key);

			if (!product) {
				return;
			}

			setProducts({ ...products, [fieldName]: product });

			const store: Stores.Store | undefined = cache.stores[user.store_id ?? ''];
			if (product?.product_id && store?.price_list_id) {
				await getPrice(store.price_list_id, product?.product_id, fieldName);
			}
			if (product?.product_id && store?.assortment_id) {
				await checkInAssortment(store.assortment_id, product?.product_id, fieldName);
			}
		} catch {
			notification.error({
				message: t('Не удалось выполнить поиск'),
			});
		}
	};

	const contextValue = useMemo(
		() => ({ products, inAssortments, price, cancelTokenSource }),
		[products, inAssortments, price, cancelTokenSource]
	);

	return (
		<div className={classes.regradingContainer}>
			<RegradingContext.Provider value={contextValue}>
				<SelectProductsWithSearch
					{...props}
					onSelect={findProduct}
					addMode={addMode}
					editMode={editMode}
					colspan={colspan}
					input={input}
				/>
				{!products.old || !products.regrade ? (
					<EmptyTableState title={t('Добавьте оба товара, чтобы увидеть сравнение')} />
				) : (
					<CompareProductsData />
				)}
			</RegradingContext.Provider>
		</div>
	);
});
