import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ProductsTable = lazy(() => import('../components/ProductsPage'));
const ProductsDataPage = lazy(() => import('../components/ProductsPage/ProductsDataPage/ProductDataPage'));
const ItemDataPage = lazy(() => import('../components/ProductsPage/ProductsDataPage/ItemDataPage'));

const productsRoutes = makeRoutes([
	{
		path: '/products',
		permit: 'products_seek',
		element: <ProductsTable listType="flat" title="" />,
	},
	{
		path: '/products/add',
		permit: 'products_create',
		element: <ProductsDataPage editMode addMode />,
	},
	{
		path: '/products/edit/:product_id',
		permit: 'products_save',
		element: <ProductsDataPage editMode />,
	},
	{
		path: '/products/:product_id',
		permit: 'products_load',
		element: <ProductsDataPage />,
	},
	{
		path: '/products/ext/:external_id',
		permit: 'products_load',
		element: <ProductsDataPage />,
	},
	{
		path: '/items/:item_id',
		permit: 'products_load',
		element: <ItemDataPage />,
	},
]);

export default productsRoutes;
