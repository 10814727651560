import { Button, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { appVersion } from '~/appConfig';
import { useDirection } from '~i18n/hooks';
import { ReactComponent as IndentRegular } from '~images/icons/indent-regular.svg';
import { ReactComponent as OutdentRegular } from '~images/icons/outdent-regular.svg';
import useClearCache from '~shared/hooks/useClearCache';
import { isBrowserOld } from '~shared/utils/getBrowser';
import Icon, { ExclamationCircleOutlined } from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';
import { useFullscreenMode } from '~zustand/metaInfo';
import { useUserConstants } from '~zustand/userData';

import ErrorsAlert from '../../../../components/StoresPage/StoresDataPage/ErrorsAlert';
import { NdaButton } from './NdaButton';
import { SessionId } from './SessionId';
import { useStyles } from './styles';

type Props = {
	collapsed: boolean;
	toggleCollapsed: () => void;
};

export function SiderFooter({ collapsed, toggleCollapsed }: Props) {
	const [t, i18n] = useTranslation();
	const location = useLocation();
	const constants = useUserConstants();
	const fullScreenMode = useFullscreenMode();

	const { cx, classes } = useStyles({ collapsed, fullScreenMode });
	const { classes: commonClasses } = useCommonStyles();

	const { isLatest, refreshApp } = useClearCache(location.pathname);

	const direction = i18n.dir();

	const MenuIcon = useDirection(OutdentRegular, IndentRegular);
	const MenuCollapsedIcon = useDirection(IndentRegular, OutdentRegular);
	const configuredFrontendUIVersion = constants.frontend_version;
	const UIVersionIsFixed = configuredFrontendUIVersion === appVersion;
	const companyName =
		constants.domain_configs.branding === 'yandex' ? t('ООО «ЯНДЕКС»') : t('"YANGO FOOD TECHNOLOGIES" LLC');

	const UIVersionToDisplay = UIVersionIsFixed
		? configuredFrontendUIVersion + 'f'
		: configuredFrontendUIVersion
			? configuredFrontendUIVersion
			: appVersion;

	return (
		<div className={classes.siderFooter}>
			{!window.ResizeObserver || !Array.prototype.at ? (
				<div className={classes.browserAlert}>
					<ErrorsAlert
						errors={[t('Пожалуйста, обновите браузер. Приложение может работать некорректно')]}
						type="error"
						closable={true}
						showIcon={false}
					/>
				</div>
			) : isBrowserOld() ? (
				<div className={classes.browserAlert}>
					<ErrorsAlert
						errors={[t('Вы используете устаревшую версию браузера. Пожалуйста, обновитесь до последней версии')]}
						type="warning"
						closable={true}
						showIcon={false}
					/>
				</div>
			) : null}
			{!isLatest && !collapsed && (
				<Button
					danger
					style={{ marginBlockEnd: 16 }}
					ghost
					onClick={() => refreshApp()}
					icon={<ExclamationCircleOutlined />}
				>
					{t('Обновить версию')}
				</Button>
			)}
			{!collapsed && (
				<>
					<div className={classes.version}>
						<b>UI:</b> {UIVersionToDisplay}
					</div>
					<div className={classes.version}>
						<b>Backend:</b> {constants.backend_version}
					</div>
					<div className={classes.sessionId}>
						<SessionId />
					</div>
				</>
			)}
			{constants.domain_configs.account === 'yandex' && <NdaButton collapsed={collapsed} />}
			<div className={classes.menuCollapser}>
				{!collapsed && (
					<span
						data-test="menu copyright company name"
						className={classes.copyright}
					>{`© 1997–${dayjs().get('year')} ${companyName}`}</span>
				)}
				<Tooltip
					title={collapsed ? t('Развернуть боковое меню') : t('Свернуть боковое меню')}
					trigger="hover"
					placement={direction === 'ltr' ? 'right' : 'left'}
					zIndex={10_000}
				>
					<Icon
						className={cx(commonClasses.btnIcon, classes.collapseIcon)}
						component={collapsed ? MenuCollapsedIcon : MenuIcon}
						onClick={toggleCollapsed}
					/>
				</Tooltip>
			</div>
		</div>
	);
}
