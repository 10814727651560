import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export const getLatestDate = (dates: Dayjs[]) => dayjs.max(dates);

// Кнопки, связанные с приемкой товаров должны перестать показываться в 4 утра
// на следующие сутки по часовому поясу Лавки после даты обновления
// последнего обновившегося дочернего ордера
export const getStowageDisableTime = (childOrdersDates: any, timezone?: string) => {
	const latest = getLatestDate(childOrdersDates);
	return latest ? latest.tz(timezone).endOf('day').add(4, 'hour').add(1, 'second') : undefined;
};

export const getAcceptanceApproveTime = (childOrdersDates: string | undefined, timezone: string | undefined) => {
	return childOrdersDates && timezone
		? dayjs(childOrdersDates)
				.tz(timezone ?? 'Europe/Moscow')
				.add(1, 'day')
				.startOf('day')
				.add(16, 'hour')
		: undefined;
};

// Функция для получения текущего времени согласно часовому поясу лавки
export const getCurrentTzTime = (timezone?: string) => dayjs().tz(timezone);

export const utcWithTimezone = (utcTimeString: string, timezone: string | undefined): string =>
	dayjs(utcTimeString).tz(timezone).format();

// хак, инпуты antd не понимают сдвиг +00:00
export const withTimezone = (
	date: Dayjs | string | undefined | null,
	timezone: string | undefined,
	updateOnlyTimezone = false
): Dayjs | undefined => {
	if (!date) return undefined;
	if (!timezone) {
		return dayjs(date);
	}
	const offset = dayjs(date).tz(timezone).utcOffset();

	let formattedDate = date;
	if (!offset && typeof date !== 'string') {
		formattedDate = date.utcOffset(0, true).format().replace('Z', '+00:00');
	}

	return offset ? dayjs(formattedDate).tz(timezone, updateOnlyTimezone) : dayjs.tz(formattedDate, timezone);
};
