import analyticStatsInfo from '~constants/analyticStatsInfo';

function getIsMetricInRed(metric: string, value?: number | string | null, threshold?: number) {
	return Boolean(
		threshold &&
			value &&
			!isNaN(Number(value)) &&
			analyticStatsInfo[metric].sign !== undefined &&
			(analyticStatsInfo[metric].sign ? threshold > Number(value) : threshold < Number(value))
	);
}

export default getIsMetricInRed;
