/* eslint-disable max-lines */
import dayjs from 'dayjs';
import { Fragment, lazy, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { getField } from '~constants/dataPageFields/fieldHelper';
import { orderFields } from '~constants/dataPageFields/orderPageFields';
import type { SelectField } from '~constants/dataPageFields/types';
import { edaStatusStringColorDictionary, paymentStatusColorDictionary, paymentStatuses } from '~constants/order';
import { ReactComponent as Uzum } from '~images/icons/uzum.svg';
import {
	extraWayPointsNames,
	invoicePaymentMethodTypeNames,
	orderDeliveryStatusNames,
} from '~server-types/doc/api/models/order';
import useLoadData from '~shared/hooks/useLoadData';
import courierName from '~shared/utils/courierName';
import isKitchenOrderType from '~shared/utils/isKitchenOrderType';
import isOrderPaused from '~shared/utils/isOrderPaused';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import renderStatus from '~shared/utils/renderStatus';
import userName from '~shared/utils/userName';
import type { Orders } from '~types/orders';
import { useCheckExp, useCheckPermit, useCheckSubPermit, usePermitValue, useUserPermits } from '~zustand/userData';
import { useUserTheme } from '~zustand/userSettings';

import { SWITCH_OFF_KITCHEN_EQUIPMENT_REASONS } from '../../../Equipment/StoresEquipment/const';
import type { UserToShow } from '../../../UsersPage/UserModal';
import UserModal from '../../../UsersPage/UserModal';
import ExternalLink from '../../ExternalLink';
import { statusStringColorDictionary, statusStringColorDictionaryDark } from '../../pageConfig';
import { formatPlannedTime } from '../../Utils/plannedTime';
import ChangeOrderUsers from '../ChangeOrderUsers';
import SetPackingList from '../PackingList';
import { useStyles } from './styles';

const DataBlocks = lazy(() => import('~shared/components/DataBlocks'));

interface Props {
	order: Orders.Order & Orders.Attr;
	orderId: string;
	inModal?: boolean;
}

const OrderDataBlocks = ({ order, orderId, inModal }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [userToShow, setUserToShow] = useState<UserToShow>();

	const isExpSnatch = useCheckExp('exp_snatch');
	const isExpTnt = useCheckExp('exp_tnt');
	const isExpAcceptanceWaitWaybill = useCheckExp('exp_acceptance_wait_waybill');
	const isExpPartnerPickupList = useCheckExp('exp_partner_pickup_list');
	const isPermitDispatcherSetUsersAll = useCheckPermit('dispatcher_set_users_all');
	const isPermitDispatcherSetUsers = useCheckPermit('dispatcher_set_users');
	const isPermitOrdersExternalLinks = useCheckPermit('orders_external_links');
	const isPermitCouriersExternalLinks = useCheckPermit('couriers_external_links');

	const permits = useUserPermits();
	const cache = useCache({
		couriersBrief: order.courier_id,
		checkProjects: order.vars?.check_project_id,
		userExecutors: [...(order?.users ?? []), order?.user_id, order?.user_done],
		orders: order.parent,
	});

	const { data: equipmentData } = useLoadData(
		() => api.equipment.items.load({ equipment_id: order.vars!.equipment_id! }),
		[order.vars?.equipment_id],
		!order.vars?.equipment_id
	);

	const { data: equipmentTypeData } = useLoadData(
		() =>
			api.equipment.types.load({
				equipment_type_id: equipmentData!.result.equipment_type_id,
			}),
		[equipmentData?.result.equipment_type_id],
		!equipmentData?.result.equipment_type_id
	);

	const modesCondition = ['writeoff_prepare_day', 'check_valid_short', 'check_valid_regular'].includes(order.type);

	// модифицируем поле на просмотр Режима работы в зависимости от условий
	const varsModeField = getField(orderFields, t('Основное'), 'vars.mode');
	if (varsModeField && typeof varsModeField.hidden === 'object') {
		varsModeField.hidden.view = !modesCondition;
	}

	// модифицируем поле на просмотр Режима работы в зависимости от условий
	const varsTotalWeightField = getField(orderFields, t('Основное'), 'vars.total_order_weight');
	if (varsTotalWeightField && typeof varsTotalWeightField.hidden === 'object') {
		varsTotalWeightField.hidden.view = !isKitchenOrderType(order.type);
	}

	const isApplicableAcceptance = !isExpSnatch || order.type === 'acceptance';

	const invoiceSumField = getField(orderFields, t('Основное'), 'invoice_sum');
	if (invoiceSumField) {
		invoiceSumField.hidden = isApplicableAcceptance;
	}

	const invoiceTypeField = getField(orderFields, t('Основное'), 'invoice_payment_method_type');
	if (invoiceTypeField) {
		invoiceTypeField.hidden = isApplicableAcceptance;
	}

	const waybillField = getField(orderFields, t('Основное'), 'attr.waybill');
	if (waybillField) {
		waybillField.hidden = order.type !== 'acceptance' || !(isExpTnt || isExpAcceptanceWaitWaybill);
	}

	const isPickupOrder = order.attr?.is_pickup && isExpPartnerPickupList;

	const partnerNameField = getField(orderFields, t('Основное'), 'attr.partner_name');
	if (partnerNameField) {
		partnerNameField.hidden = !isPickupOrder;
	}

	const courierPinField = getField(orderFields, t('Основное'), 'attr.courier_pin');
	if (courierPinField) {
		courierPinField.hidden = !isPickupOrder;
	}

	const orderItems = useMemo(() => {
		if (!order || !order.type) {
			return;
		}
		if (order.type !== 'check') {
			return order.required;
		}

		const products = order.products.map((productId) => ({
			product_id: productId,
		}));
		const shelves = order.shelves.map((shelfId) => ({
			shelf_id: shelfId,
		}));
		return [...products, ...shelves];
	}, [order]);

	const createReasonField = getField(orderFields, t('Основное'), 'creation_reason');
	if (createReasonField) {
		createReasonField.hidden = order.type !== 'stop_list' || !orderItems?.some((item: any) => !!item?.shelf_id);
	}

	const reasonField = getField(orderFields, t('Основное'), 'vars.reason') as SelectField;
	if (reasonField) {
		reasonField.hidden = !['hide_and_show', 'turnoff_starbucks'].includes(order.type);
		reasonField.options =
			order.type === 'turnoff_starbucks' ? SWITCH_OFF_KITCHEN_EQUIPMENT_REASONS : reasonField.options;
	}

	const extraWayPointsField = getField(orderFields, t('Основное'), 'attr.extra_waypoints') as SelectField;
	if (extraWayPointsField) {
		extraWayPointsField.hidden = !order.attr?.extra_waypoints;
	}

	const theme = useUserTheme();
	const isLight = theme === 'light';
	const blockColor = isLight ? '#fff2e8' : '#2A1712';

	const paymentInfo = useMemo(() => {
		const invoice = order.invoices?.[0];
		if (!invoice) return { status: '—', type: '—' };
		if (invoice.invoice_type === 'payment') {
			return { status: 'paid', type: invoice.invoice_payment_method_type ?? '—' };
		} else {
			return { status: 'not_paid', type: invoice.invoice_payment_method_type ?? '—' };
		}
	}, [order.invoices]);

	const dispatcherSetIsArray = Array.isArray(usePermitValue('dispatcher_set_users', permits));
	const isOrderTypeSubPermitOfDispatcherSetUsers = useCheckSubPermit('dispatcher_set_users', order.type);
	const allowSetUsers =
		!order ||
		(dispatcherSetIsArray
			? isOrderTypeSubPermitOfDispatcherSetUsers
			: isPermitDispatcherSetUsers || isPermitDispatcherSetUsersAll);

	const needInsertWBNumber = isExpAcceptanceWaitWaybill && !order?.attr?.waybill;

	const courier = order.courier_id ? cache.couriersBrief[order.courier_id] : undefined;

	return (
		<>
			{userToShow && <UserModal userToShow={userToShow} setUserToShow={setUserToShow} />}
			<DataBlocks
				fields={orderFields
					.flatMap((section) => section.fields)
					.filter((fields) => {
						//отображение поля поставщик только для ордеров с типом приемка
						if (fields.key === 'contractor') return order.type === 'acceptance';
						// Отображение поля тип инвентаризации только для ордеров с таким типом
						if (fields.key === 'inventory_type') return order.type === 'inventory';
						// Планируемое время показывает только для заказов и сборки с полок
						if (fields.key === 'planned_time') return ['order', 'order_retail'].includes(order.type);
						// К оплате клиентом показывает только для заказов и сборки с полок
						if (fields.key === 'invoice_sum') return ['order', 'order_retail'].includes(order.type);
						// Тип оплаты показывает только для заказов и сборки с полок
						if (fields.key === 'invoice_payment_method_type') return ['order', 'order_retail'].includes(order.type);
						// Сумма заказа показывает только для заказов и сборки с полок
						if (fields.key === 'total_price') return ['order', 'order_retail'].includes(order.type);
						// Признак паузы показываем только в карточке заказа и сборки с полок и только если он на паузе
						if (fields.key === 'paused_until')
							return isOrderPaused(order.paused_until) && ['order', 'order_retail'].includes(order.type);
						// LAVKADEV-12521 Блоки, перекочевавшие из раздела "Дополнительно"
						if (fields.key === 'vars.check_project_id') return order.type === 'check';
						if (fields.key === 'attr.reason') return order.type === 'shipment_rollback';
						if (['courier_id', 'client_address.fullname', 'eda_status'].includes(fields.key))
							return ['order', 'order_retail', 'kitchen_prod', 'refund'].includes(order.type);
						if (fields.key === 'vars.equipment_id') return !!order.vars?.equipment_id;
						return fields.key !== 'type';
					})}
				keysOrder={[
					'paused_until',
					...(inModal ? [] : ['status', 'target']),
					'request_number',
					'doc_number',
					'doc_date',
					'contractor',
					'parent',
					'approved',
					'users',
					'total_price',
					'inventory_type',
					'vars.total_order_weight',
					'invoice_sum',
					'invoice_payment_method_type',
					'attr.waybill',
					'attr.partner_name',
					'attr.courier_pin',
					'creation_reason',
					'planned_time',
					'vars.reason',
					'eda_status',
					'user_id',
					'user_done',
					'courier_id',
					'attr.reason',
					'vars.check_project_id',
					'client_address.fullname',
					'vars.equipment_id',
				]}
				data={order}
				// TODO Написать getValueFunc для пользователей, как только добавят возможность получения списка по id на беке
				replaceData={[
					{
						key: 'paused_until',
						getValueFunc: () => t('На паузе до {{ time }}', { time: dayjs(order.paused_until).format('HH:mm:ss') }),
						dataColor: () =>
							isLight ? statusStringColorDictionary.canceled : statusStringColorDictionaryDark.canceled,
						blockColor: () => blockColor,
					},
					{
						key: 'doc_number',
						copyAction: true,
						copySuccessText: t('Номер ордера скопирован'),
						additionalContent:
							isKitchenOrderType(order?.type) && isPermitOrdersExternalLinks ? (
								<ExternalLink type="order" externalId={order?.external_id} />
							) : undefined,
					},
					{
						key: 'doc_date',
						getValueFunc: (date) =>
							date
								? (formatTimeAndYear(date, {
										onlyDate: true,
									}) as string)
								: '—',
					},
					{
						key: 'contractor',
						tooltip: order.contractor && order.contractor?.length > 20 ? order.contractor : undefined,
						getValueFunc: (data) => (data && data.length > 20 ? `${data.slice(0, 20)}...` : data),
					},
					{
						key: 'status',
						dataColor: (data: string) =>
							isLight ? statusStringColorDictionary[data] : statusStringColorDictionaryDark[data],
						tooltip: order.estatus,
						hidden: inModal,
					},
					{
						key: 'target',
						dataColor: (data: string) =>
							isLight ? statusStringColorDictionary[data] : statusStringColorDictionaryDark[data],
						tooltip: order.vars?.stage,
						hidden: inModal,
					},
					{
						key: 'parent',
						getValueFunc: (parent) => parent && parent[0] && cache.orders?.[parent[0]]?.doc_number,
						to: `/stores/${order.store_id}/orders/${order.parent?.[0]}`,
					},
					{
						key: 'approved',
						getValueFunc: (approved) => {
							// TODO LAVKADEV-12307: убрать проверку, как бэк перестанет присылать 01.01.1970
							const isApprovedAfterCreated = dayjs(approved).diff(dayjs(order.created)) > 0;
							return isApprovedAfterCreated
								? formatTimeAndYear(approved, {
										onlyDate: false,
										withSeconds: true,
									})
								: '—';
						},
					},
					{
						key: 'inventory_type',
						getValueFunc: () => (order.vars?.inventory_type ? order.vars?.inventory_type : null),
					},
					{
						key: 'vars.total_order_weight',
						getValueFunc: (weight) =>
							weight
								? t('{{weight}} кг', {
										weight: parseFloat((weight / 1000).toFixed(2)),
									})
								: '—',
					},
					{
						key: 'users',
						to: order?.users?.map((userId) => `/users/${userId}`),
						type: 'multiple' as const,
						visibleOnEmpty: true,
						getValueFunc: () =>
							order?.users?.length
								? order?.users?.map((userId) => userName(cache.userExecutors[userId]) ?? userId)
								: '—',

						additionalContent: allowSetUsers ? (
							<ChangeOrderUsers orderId={orderId} users={order?.users} onlyChange={order.type === 'stowage_market'} />
						) : undefined,
					},
					{
						key: 'invoice_sum',
						getValueFunc: () => order?.invoices?.[0].invoice_sum ?? order?.total_price ?? '',
						blockColor: () => blockColor,
					},
					{
						key: 'invoice_payment_method_type',
						getValueFunc: () => invoicePaymentMethodTypeNames[paymentInfo.type],
						additionalContent: order?.invoices?.length
							? renderStatus(paymentInfo.status, paymentStatuses, paymentStatusColorDictionary)
							: undefined,
						blockColor: () => blockColor,
					},
					{
						key: 'attr.waybill',
						getValueFunc: () => order.attr.waybill ?? '—',
						additionalContent: <SetPackingList order={order} />,
						blockColor: () => (needInsertWBNumber ? '#fff1f0' : ''),
						dataColor: () => (needInsertWBNumber ? '#f5222d' : ''),
						titleColor: () => (needInsertWBNumber ? '#f5222d' : ''),
					},
					{
						key: 'attr.partner_name',
						getValueFunc: () => {
							// Это костыль. Уберем когда https://st.yandex-team.ru/LAVKAPROJECTS-7111
							if (order.attr.partner_name === 'korzinkago_uzum') {
								return (
									<div className={classes.partner}>
										<Uzum />
										<span>{order.attr.partner_name}</span>
									</div>
								);
							}
							return order.attr.partner_name ?? '—';
						},
					},
					{
						key: 'attr.courier_pin',
						getValueFunc: () => order.attr.courier_pin ?? '—',
					},
					{
						key: 'creation_reason',
						getValueFunc: () => order.attr?.creation_reason?.comment ?? '—',
					},
					{
						key: 'planned_time',
						getValueFunc: () => formatPlannedTime(order),
					},
					{
						key: 'courier_id',
						getValueFunc: () =>
							courier ? courierName(courier, { showEatsId: false }) : order.courier?.name ?? undefined,
						to: `/couriers/${order.courier_id}`,
						clickAction: undefined,
						type: useCheckPermit('couriers_load') && order.courier_id ? 'link' : 'normal',
						additionalContent:
							order.courier?.taxi_driver_uuid && isPermitCouriersExternalLinks ? (
								<ExternalLink type="courier" externalId={order.courier.taxi_driver_uuid} />
							) : undefined,
					},
					{
						key: 'user_id',
						getValueFunc: (userId) => (userId ? userName(cache.userExecutors[userId]) : userId),
						clickAction: useCheckPermit('users_load')
							? (fullname) =>
									setUserToShow({
										user_id: order.user_id,
										fullname: fullname,
									})
							: undefined,
						type: 'action',
					},
					{
						key: 'user_done',
						getValueFunc: (userId) => (order.user_done ? userName(cache.userExecutors[userId]) : userId),
						clickAction: useCheckPermit('users_load')
							? (fullname) =>
									setUserToShow({
										user_id: order.user_done,
										fullname: fullname,
									})
							: undefined,
						type: 'action',
					},
					{
						key: 'eda_status',
						getValueFunc: (data) => orderDeliveryStatusNames[data],
						dataColor: (data) => edaStatusStringColorDictionary[data],
					},
					{
						key: 'vars.equipment_id',
						type: 'link',
						getValueFunc: (value) => equipmentTypeData?.result?.title ?? value,
						to: `/stores/${order.store_id}/shelves/equipments/${order.vars?.equipment_id}`,
						visibleOnEmpty: true,
					},
					{
						key: 'vars.check_project_id',
						type: 'link',
						getValueFunc: (text: string) => cache.checkProjects[text]?.title ?? '',
						to: `/check_projects/${order.vars?.check_project_id}`,
					},
					{
						key: 'attr.extra_waypoints',
						additionalContent:
							order.attr?.extra_waypoints && order.attr?.extra_waypoints.length > 1 ? (
								<div>
									{order.attr?.extra_waypoints?.map((el, index) => (
										<Fragment key={el}>
											{index > 0 ? ' · ' : null}
											<span key={el}>{extraWayPointsNames[el]}</span>
										</Fragment>
									))}
								</div>
							) : undefined,
					},
				]}
			/>
		</>
	);
};

export default OrderDataBlocks;
