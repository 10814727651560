import { tabletMaxWidth } from '~constants/breakPoints';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	header: {
		inlineSize: '100%',
	},
	buttonsBarRight: {
		display: 'flex',
		alignItems: 'center',
		marginInlineStart: 'auto',
	},
	additionalButtonsBar: {
		display: 'flex',
		alignItems: 'center',
		marginInlineStart: 'auto',
		'&>*': {
			marginInlineEnd: 8,
		},
		'& ~ *': {
			marginInlineStart: '0 !important',
		},
		'& + *': {
			marginInlineEnd: 8,
		},
	},
	progress: {
		inlineSize: '100%',
		blockSize: 4,
		display: 'flex',
		alignItems: 'center',
		'&>div': {
			inlineSize: '100%',
		},
	},
	spin: {
		marginInlineEnd: 24,
		marginInlineStart: 8,
	},
	title: {
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: 'normal',
		fontSize: '16px',
		margin: 0,
		color: theme.text.primary,
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		[`@media (max-width:${tabletMaxWidth}px)`]: {
			fontSize: '20px',
		},
	},
	button: {
		color: theme.text.primary,
		backgroundColor: theme.bgColor.main,
		lineHeight: 'normal',
	},
	editButton: {
		color: theme.text.primary,
		lineHeight: 'normal',
		marginInlineStart: 'auto',
		backgroundColor: 'inherit',
	},
	orderHeader: {
		display: 'flex',
		alignItems: 'center',
		'& button:last-of-type': {
			marginInlineStart: 'auto',
		},
	},
	status: {
		display: 'inline-block',
		marginInlineStart: 8,
	},
	dotsOptions: {
		marginInlineStart: 8,
		borderColor: theme.border.secondary,
	},
	buttonsWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	copyBtnBlock: {
		display: 'inline-flex',
		'&>button': {
			borderColor: theme.border.secondary,
		},
	},
	supportChatButton: {
		display: 'inline-flex',
		marginInlineEnd: 4,
	},
}));
