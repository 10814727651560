export const rusToLatin = function (str: string, separator = '-') {
	const dict = {
		а: 'a',
		б: 'b',
		в: 'v',
		г: 'g',
		д: 'd',
		е: 'e',
		ё: 'e',
		ж: 'j',
		з: 'z',
		и: 'i',
		й: 'i',
		к: 'k',
		л: 'l',
		м: 'm',
		н: 'n',
		о: 'o',
		п: 'p',
		р: 'r',
		с: 's',
		т: 't',
		у: 'u',
		ф: 'f',
		х: 'h',
		ц: 'c',
		ч: 'ch',
		ш: 'sh',
		щ: 'shch',
		ъ: '',
		ы: 'y',
		ь: '',
		э: 'e',
		ю: 'u',
		я: 'ya',
		' ': separator,
	} as Record<string, string>;
	const newStr: string[] = [];

	for (const char of str) {
		newStr.push(
			dict[char] ||
				(dict[char.toLowerCase()] === undefined && char) ||
				dict[char.toLowerCase()].replace(/^(.)/, function (match: string) {
					return match.toUpperCase();
				})
		);
	}

	return newStr.join('');
};
