import { PageHeaderButton } from '@lavka/ui-kit';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { DownOutlined } from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from '../styles';

const SaveButton = ({ sendWithStatus, isFormComplete }: any) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();

	const menu = (
		<Menu className={cx(commonClasses.dropdownContainer, classes.saveContainer)} data-test="price list draft save menu">
			<Menu.Item
				key="draft"
				onClick={() => sendWithStatus('draft')}
				className={classes.saveBlock}
				data-test="save check_project as draft"
			>
				<p className={classes.saveBlockTitle}>{t('Черновик')}</p>
			</Menu.Item>
			<Menu.Item
				key="waiting_approve"
				onClick={() => sendWithStatus('waiting_approve')}
				disabled={!isFormComplete}
				className={classes.saveBlock}
				data-test={`save check_project as waiting_approve${!isFormComplete ? ' disabled' : ''}`}
			>
				<p className={classes.saveBlockTitle}>{t('На подтверждении')}</p>
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<PageHeaderButton view="header-accent" iconRight={<DownOutlined />} data-test="check_project save dropdown">
				{t('Сохранить как')}
			</PageHeaderButton>
		</Dropdown>
	);
};

export default SaveButton;
