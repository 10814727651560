import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type State = {
	isOnline: boolean;
	turnOnline: () => void;
	turnOffline: () => void;
	editMode: boolean;
	fullScreenMode: boolean;
	setFullscreenMode: (mode: boolean) => void;
	setEditMode: (mode: boolean) => void;
};

const initialState = {
	isOnline: false,
	editMode: false,
	fullScreenMode: false,
};

export const metaInfoStore = create<State>()(
	devtools(
		(set) => ({
			...initialState,
			turnOnline: () => set((state) => ({ ...state, isOnline: true })),
			turnOffline: () => set((state) => ({ ...state, isOnline: false })),

			setFullscreenMode: (mode: boolean) => set((state) => ({ ...state, fullScreenMode: mode })),
			setEditMode: (mode: boolean) => set((state) => ({ ...state, editMode: mode })),
		}),
		{ name: 'metaInfoData' }
	)
);

// Это в компонентах
export const useIsOnline = () => metaInfoStore((state) => state.isOnline);
export const useFullscreenMode = () => metaInfoStore((state) => state.fullScreenMode);
export const useEditMode = () => metaInfoStore((state) => state.editMode);

// в функции
export const getIsOnline = () => metaInfoStore.getState().isOnline;
export const turnAppOnline = () => metaInfoStore.getState().turnOnline();
export const turnAppOffline = () => metaInfoStore.getState().turnOffline();

export const getEditMode = () => metaInfoStore.getState().editMode;
export const setFullscreenMode = (mode: boolean) => metaInfoStore.getState().setFullscreenMode(mode);
export const setEditMode = (mode: boolean) => metaInfoStore.getState().setEditMode(mode);
