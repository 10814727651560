import { Button } from 'antd';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import { order as orderStatusDictionary } from '~constants/order';
import { ReactComponent as ExternalLinkAltRegular } from '~images/icons/external-link-alt-regular.svg';
import { ReactComponent as PlusCircle } from '~images/icons/plus-circle-regular.svg';
import { orderDeliveryStatusNames } from '~server-types/doc/api/models/order';
import { checkIsUnassignCourierAllowed } from '~shared/utils/checkIsUnassignCourierAllowed';
import courierName from '~shared/utils/courierName';
import { escapeHtml } from '~shared/utils/escapeHtml';
import type { Couriers } from '~types/couriers';
import type { Orders } from '~types/orders';

import type { OrderWithExtra } from '../Yandex/YandexOrdersMap';
import { useStyles } from './styles';

type PlacemarkBalloonHeaderProps = {
	orderId: string;
	docNumber: string;
};
const PlacemarkBalloonHeader = ({ orderId, docNumber }: PlacemarkBalloonHeaderProps) => {
	const { cx, classes } = useStyles();

	return (
		<Button className={cx(`order-button-${orderId}`, classes.balloonHeader)}>
			{docNumber}
			<ExternalLinkAltRegular className={cx('icon', classes.balloonHeaderIcon)} width={11} height={11} />
		</Button>
	);
};

export const placemarkBalloonHeader = ({ orderId, docNumber }: PlacemarkBalloonHeaderProps) => {
	return ReactDOMServer.renderToString(<PlacemarkBalloonHeader orderId={orderId} docNumber={docNumber} />);
};

type PlacemarkBalloonBodyProps = {
	order: OrderWithExtra;
	courier: Couriers.CourierBrief | undefined;
};

export const PlacemarkBalloonBody = ({ order, courier }: PlacemarkBalloonBodyProps) => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	return (
		<div>
			<div>
				<div>
					<span>{t('Статус:')}</span>
					<span className={classes.rowData}>
						{order.status ? escapeHtml(orderStatusDictionary[order.status]) : t('Нет статуса')}
					</span>
				</div>
				<div>
					<span>{t('Статус доставки:')}</span>
					<span className={classes.rowData}>
						{order.eda_status ? escapeHtml(orderDeliveryStatusNames[order.eda_status]) : t('Нет статуса')}
					</span>
				</div>
				<div>
					<span>{t('Курьер:')}</span>
					<span className={classes.rowData}>
						{order.courier_id ? (
							escapeHtml(courierName(courier, { showEatsId: false }))
						) : order.dispatch_delivery_type === 'yandex_taxi' ? (
							t('Доставка на такси')
						) : order.courier?.name ? (
							order.courier.name
						) : checkIsUnassignCourierAllowed() ? (
							<button type="button" id={`assign-button-${order.order_id}`} className={classes.assignButton}>
								<PlusCircle width={12} height={12} />
								<span className={classes.assignButtonTitle}>{t('Назначить')}</span>
							</button>
						) : (
							t('Нет курьера')
						)}
					</span>
				</div>
			</div>
			{order.isBatched && order.batchedWith ? (
				<>
					<div>{t('Другие заказы курьера')}</div>
					{order.batchedWith.map((num) => (
						<div key={num}>{num}</div>
					))}
				</>
			) : (
				''
			)}
		</div>
	);
};

export const placemarkBalloonBody = ({ order, courier }: PlacemarkBalloonBodyProps) => {
	return ReactDOMServer.renderToString(<PlacemarkBalloonBody order={order} courier={courier} />);
};

export const getListeners = ({
	type,
	orderId,
	setUnassignCourierModalOrderId,
	setOrderModalOrderId,
}: {
	type: 'yandex' | 'google';
	orderId: Orders.Order['order_id'];
	setUnassignCourierModalOrderId?: (orderId?: string) => void;
	setOrderModalOrderId?: (orderId?: string) => void;
}) => {
	const callbackOnOpen = () => {
		const assignCourierButton = document.getElementById(`assign-button-${orderId}`);
		// eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
		assignCourierButton?.addEventListener('click', () => setUnassignCourierModalOrderId?.(orderId));

		const orderButtons = document.getElementsByClassName(`order-button-${orderId}`);
		let orderButton: HTMLButtonElement;
		if (type === 'google') {
			orderButton = orderButtons[orderButtons.length - 1] as HTMLButtonElement;
		} else {
			orderButton = orderButtons[1] as HTMLButtonElement;
		}
		if (orderButton) {
			orderButton.style.fontSize = '15.6px';
			orderButton.style.fontWeight = 'bold';
			// eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
			orderButton.addEventListener('click', () => setOrderModalOrderId?.(orderId));
			const buttonIcon = orderButton.getElementsByClassName('icon')[0] as HTMLButtonElement;
			buttonIcon.style.display = 'inline';
		}
	};

	const callbackOnClose = () => {
		const assignCourierButton = document.getElementById(`assign-button-${orderId}`);
		assignCourierButton?.removeEventListener('click', () => setUnassignCourierModalOrderId?.(orderId));

		const orderButtons = document.getElementsByClassName(`order-button-${orderId}`);
		let orderButton: HTMLButtonElement;
		if (type === 'google') {
			orderButton = orderButtons[orderButtons.length - 1] as HTMLButtonElement;
		} else {
			orderButton = orderButtons[1] as HTMLButtonElement;
		}
		if (orderButton) {
			orderButton?.removeEventListener('click', () => setOrderModalOrderId?.(orderId));
		}
	};

	return [callbackOnOpen, callbackOnClose];
};
