import i18n from '~/i18n';

export const intervalViewText = (interval: any) => {
	return `${i18n.t('Повторять каждые')}
	${interval.months ? i18n.t('{{count}} месяц', { count: interval.months }) : ''}
  ${interval.days ? i18n.t('{{count}} день', { count: interval.days }) : ''} ${
		interval.hours ? i18n.t('{{count}} час', { count: interval.hours }) : ''
	} ${interval.minutes ? i18n.t('{{count}} минута', { count: interval.minutes }) : ''} ${
		interval.seconds ? i18n.t('{{count}} секунда', { count: interval.seconds }) : ''
	}`;
};
