import { createContext } from 'react';

import type { Orders } from '~types/orders';

export type UnassignRequestData = {
	order_id: Orders.Order['order_id'];
	batch_to_order_id?: string;
	courier_id?: string;
	disable_batching?: boolean;
	taxi_only?: boolean;
	taxi_type?: 'taxi' | 'bike' | 'any';
};

export type ContextTypeCourier = {
	order: Orders.Order;
	requestData: Omit<UnassignRequestData, 'order_id'>;
	setRequestData: (data: Omit<UnassignRequestData, 'order_id'>) => void;
	setRequestField: (data: Record<string, string | boolean | undefined>) => void;
};

export const Context = createContext<ContextTypeCourier>({
	order: {} as Orders.Order,
	requestData: {},
	setRequestData: () => {},
	setRequestField: () => {},
});
