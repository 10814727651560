/*global google*/
import i18n from '~/i18n';
import { clustersMapData, clustersTranslations } from '~constants/other';
import type { ClusterMapLayer } from '~server-types/doc/api/models/cluster';
import {
	placemarkOptionsGenerator,
	polygonOptionsGenerator,
	zoneStatusColorDictionary,
} from '~shared/utils/mapFunctions';
import type { Stores } from '~types/stores';
import type { Zones } from '~types/zones';

import type { ClusterGeometryFields } from './constants';
import { DEFAULT_MAP_ZOOM } from './constants';
import type { PartialStore, PartialZone, StoreMap } from './useAddStoresToMap';

declare let ymaps: any;

export const boundsChangeSubscription = (map: any, setBounds: any) => {
	const bounds = map.getBounds();

	setBounds(bounds);

	map.events.add('boundschange', function (e: any) {
		const bounds = e.originalEvent.newBounds;
		if (bounds) {
			setBounds(bounds);
		}
	});
};

export const polygonCreator = (
	coords: Stores.Coordinates[][],
	eZoneOptions: {
		delivery_type: Zones.Zone['delivery_type'];
		status: Zones.Zone['status'];
		color: string;
	},
	store: PartialStore,
	mapType: ClusterMapLayer
) => {
	switch (mapType) {
		case 'google':
			return new google.maps.Polygon({
				paths: coords[0].map((path) => ({ lat: path[1], lng: path[0] })),
				...polygonOptionsGenerator(store.status, eZoneOptions),
				fillOpacity: 1,
			});
		case 'yandex':
			return new ymaps.Polygon(
				coords,
				{
					balloonContent: `<div>
														<p style='margin-block-end: 0'>${store.title}</p>
														<a href='/stores/${store.store_id}'>${i18n.t('перейти')}</a>
												</div>`,
				},
				{
					strokeWidth: 5,
					...polygonOptionsGenerator(store.status, eZoneOptions),
				}
			);
	}
};

export const placemarkCreator = (store: PartialStore, mapType: ClusterMapLayer) => {
	const storeLink = `<div>
																		<p style='margin-block-end: 0'>${store.title}</p>
																		<a href='/stores/${store.store_id}' target='_blank'>${i18n.t('перейти')}</a>
																	</div>`;
	const coords = store.location.geometry.coordinates;
	switch (mapType) {
		case 'yandex':
			return new ymaps.Placemark(
				coords,
				{
					hintContent: store.title,
					balloonContent: storeLink,
				},
				{
					...placemarkOptionsGenerator(store?.status, store?.type),
				}
			);
		case 'google':
			return new google.maps.Marker({
				position: { lat: coords[1], lng: coords[0] },
			});
	}
};

export const addObjectsToMap = (
	map: any,
	mapType: ClusterMapLayer,
	objects: {
		remove: any[];
		show: any[];
	}
) => {
	switch (mapType) {
		case 'yandex':
			ymaps.geoQuery(objects.remove).removeFromMap(map);
			ymaps.geoQuery(objects.show).addToMap(map);
			break;
		case 'google':
			objects.remove.forEach((object) => object.setMap(null));
			objects.show.forEach((object) => object.setMap(map));
	}
};

export const generatePolygons = (
	store: StoreMap,
	zonesToShow: string[] | undefined,
	mapType: ClusterMapLayer
): any[] => {
	const eZones = store.zones.map((eZone) => eZone);
	const polygons: any = [];
	const pushPolygon = (
		eZone: PartialZone,
		eZoneOptions: {
			delivery_type: Zones.Zone['delivery_type'];
			status: Zones.Zone['status'];
			color: string;
		}
	) => {
		if (eZone.zone.geometry.type === 'MultiPolygon') {
			eZone.zone.geometry.coordinates.forEach((coords) =>
				polygons.push(polygonCreator(coords, eZoneOptions, store, mapType))
			);
		} else {
			polygons.push(polygonCreator(eZone.zone.geometry.coordinates, eZoneOptions, store, mapType));
		}
	};
	eZones.forEach((eZone) => {
		const eZoneOptions = {
			delivery_type: eZone.delivery_type,
			status: eZone.status,
			color: zoneStatusColorDictionary[store.status],
		};
		if (zonesToShow) {
			if (zonesToShow.includes(eZone.delivery_type)) {
				pushPolygon(eZone, eZoneOptions);
			}
		} else {
			pushPolygon(eZone, eZoneOptions);
		}
	});
	return polygons;
};

export const storesFilter = (options: any, store: any) => {
	let statusFilter = true;
	let optionFilter = true;
	if (options?.statusesToShow && !options.statusesToShow.includes(store.status)) {
		statusFilter = false;
	}
	if (options?.optionsToShow) {
		Object.keys(store.options).forEach((option) => {
			if (options.optionsToShow.includes(option) && store.options[option] === false) {
				optionFilter = false;
			}
		});
	}
	return statusFilter && optionFilter;
};

const calcAverageCoordinates = (coordinates: Stores.Coordinates[]): [number, number] => {
	let xSum = 0;
	let ySum = 0;

	coordinates.forEach(([x, y]) => {
		xSum += Number(x);
		ySum += Number(y);
	});

	const xAverage = xSum / coordinates.length;
	const yAverage = ySum / coordinates.length;

	return [Number(xAverage.toFixed(3)), Number(yAverage.toFixed(3))];
};

export type ClusterGeometry = {
	title: string;
	id: string;
	center?: [number, number];
	zoom?: number;
};

export const getClusterGeometry = (cluster: ClusterGeometryFields): ClusterGeometry | null => {
	const result = {
		title: clustersTranslations[cluster.title] ?? cluster.title,
		id: cluster.cluster_id,
		zoom: clustersMapData[cluster.title]?.zoom ?? DEFAULT_MAP_ZOOM,
	};

	if (clustersMapData[cluster.title]?.center) {
		return {
			...result,
			center: clustersMapData[cluster.title].center,
		};
	}

	const geometry = cluster.zone?.geometry;

	if (!geometry) {
		return null;
	}

	let coordinates: Stores.Coordinates[] = [];

	if (geometry.type === 'MultiPolygon') {
		coordinates = geometry.coordinates[0][0];
	} else if (geometry.type === 'Polygon') {
		coordinates = geometry.coordinates[0];
	}

	if (coordinates.length === 0) {
		return null;
	}

	return {
		...result,
		center: calcAverageCoordinates(coordinates),
	};
};

export type ZoneBrief = Pick<Zones.Zone, 'store_id' | 'zone'>;

export const getZonelessClusterGeometry = (cluster: ClusterGeometryFields, zones: ZoneBrief[]) => {
	const allCoordinates: Stores.Coordinates[] = [];

	zones.forEach((zone) => {
		const geometry = zone.zone.geometry;

		if (geometry.type === 'MultiPolygon') {
			allCoordinates.push(...geometry.coordinates[0][0]);
		} else if (geometry.type === 'Polygon') {
			allCoordinates.push(...geometry.coordinates[0]);
		}
	});

	return getClusterGeometry({
		...cluster,
		zone: {
			type: 'Feature',
			geometry: {
				type: 'Polygon',
				coordinates: [allCoordinates],
			},
		},
	});
};
