import { useStyles } from '../styles';
import tomato from './tomato-spinner.gif';

const TomatoSpinner = () => {
	const { classes } = useStyles();
	return (
		<div className={classes.spinner}>
			<img src={tomato} height={130} style={{ borderRadius: '40px' }} />
		</div>
	);
};

export default TomatoSpinner;
