import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { CourierServices } from '~types/courierServices';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<CourierServices.SeekRequest, CourierServices.CourierService>(
		'/api/supply/admin/courier_services/list'
	),
	load: makeLoadFn<CourierServices.CourierService, 'courier_service_id'>(
		'/api/supply/admin/courier_services/load',
		'courier_service_id'
	),
	save: (data: CourierServices.CourierService) =>
		axios.post<{ result: CourierServices.CourierService }>('/api/supply/admin/courier_services/save', data),
};
