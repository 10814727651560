import { Tooltip } from 'antd';

import type { ProcessedCourierCardData } from '~shared/constants/tableauCouriers';
import { Link } from '~shared/ui-kit/Link';
import type { Orders } from '~types/orders';

import { useStyles as useOrderCardStyles } from '../../../components/MonitorTableau/Blocks/OrderCard/styles';
import getInitials from '../../../components/MonitorTableau/utils/getInitials';
import { getCourierIcon } from '../DeliveryCard/utils';
import { useStyles } from './styles';
import { avatarBackgroundColor } from './utils';

interface UserAvatarProps {
	userId: string;
	name?: string;
	srcLink?: string;
	// Показываем ли инициалы вместо иконки
	showInitials?: boolean;
	isRover?: boolean;
	deliveryType?: ProcessedCourierCardData['delivery_type'] | Orders.Order['dispatch_transport_type'];
}

export const UserAvatar = ({
	userId,
	name,
	srcLink,
	showInitials = false,
	isRover = false,
	deliveryType,
}: UserAvatarProps) => {
	const { classes } = useStyles();
	const { cx, classes: orderCardClasses } = useOrderCardStyles();

	const IconElement = getCourierIcon(deliveryType, isRover);
	const currentBackgroundColor = avatarBackgroundColor(userId, isRover, deliveryType?.includes('taxi'));

	if (showInitials) {
		return srcLink ? (
			<Link
				to={srcLink}
				style={{ backgroundColor: currentBackgroundColor }}
				className={classes.userName}
				target="_blank"
			>
				<Tooltip title={name}>{getInitials(name)}</Tooltip>
			</Link>
		) : (
			<div className={classes.userAvatarWrapper}>
				<span style={{ backgroundColor: currentBackgroundColor }} className={classes.userName}>
					{getInitials(name)}
				</span>
			</div>
		);
	}

	return (
		<div className={classes.iconAvatarContainer} style={{ backgroundColor: currentBackgroundColor }}>
			<IconElement
				className={cx(orderCardClasses.icon, {
					[orderCardClasses.taxiIcon]: deliveryType?.includes('taxi'),
				})}
			/>
		</div>
	);
};
