import { notification } from '@lavka/ui-kit';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { checkProjectsFields, productTabLabels } from '~constants/dataPageFields/checkProjectsFields';
import { getDefaultValues } from '~constants/dataPageFields/fieldHelper';
import { checkProjectStatusesNames } from '~server-types/doc/api/models/check_project';
import DataForm from '~shared/components/DataForm';
import DataFormControls from '~shared/components/DataForm/DataFormControls';
import { convertScheduleIntoValue, getRepeatType } from '~shared/components/Fields/RepeatScheduleField/utils';
import Title from '~shared/components/Title';
import useEditMode from '~shared/hooks/useEditMode';
import useLoadData from '~shared/hooks/useLoadData';
import useQueryParams from '~shared/hooks/useQueryParams';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import useUuid from '~shared/hooks/useUuid';
import Tabs from '~shared/ui-kit/Tabs';
import { isObjectEmpty } from '~shared/utils/isObjectEmpty';
import renderStatus from '~shared/utils/renderStatus';
import { useStyles as useCommonStyles } from '~styles/common';
import type { CheckProjects } from '~types/checkProjects';
import { useCheckPermit, useUser } from '~zustand/userData';

import ErrorsAlert from '../../StoresPage/StoresDataPage/ErrorsAlert';
import CheckProjectActionModal from '../CheckProjectActionModal';
import { checkProjectStatusColors } from '../pageConfig';
import { useStyles } from '../styles';
import { getCheckProjectActions } from '../TableActions';
import { getNextCheckTimeFromSchedule } from '../TableBlocks/NextPlannedBlock';
import { getSchedule, isApprovable, isFormComplete } from '../utils/helpers';
import SaveButton from './SaveButton';
import { LocationSection } from './Sections/LocationSection';
import { MainSection } from './Sections/MainSection';
import { OrdersSection } from './Sections/OrdersSection';
import { ProductSection } from './Sections/ProductSection';
import type { CheckProjectFormValues } from './types';

interface Props {
	addMode?: boolean;
	editMode?: boolean;
	idFromProps?: string;
	inModal?: boolean;
}

const CheckProjectsDataPage = ({ addMode, editMode, idFromProps, inModal }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();
	const params = useParams<{ check_project_id: string }>();
	const navigate = useNavigate();
	const [loading, toggleLoading] = useState(false);
	const [modalOpen, toggleModalOpen] = useState<string | null>(null);
	const [projectModifiedId, setProjectModifiedId] = useState<string | null>(null);
	const checkProjectId = idFromProps || params.check_project_id!;
	const user = useUser();
	const isPermitCheckProjectsLoad = useCheckPermit('check_projects_load');
	const queryParams = useQueryParams('tab', { default: 'main' });

	useEditMode(editMode || addMode);

	const checkProjectData = useLoadData(
		() => api.checkProjects.load({ check_project_id: checkProjectId }, { strict: true }),
		[checkProjectId, addMode, editMode],
		addMode
	);

	const checkProject = checkProjectData.data?.result;

	const { companies } = useCache({
		companies: {
			ids: [user.company_id, checkProject?.company_id],
			_fields: ['title'],
		},
		products: checkProject?.products?.regrading_map?.flatMap((e) => Object.values(e)),
		userExecutors: {
			ids: [
				checkProject?.vars?.created_by,
				checkProject?.vars?.last_edited_by,
				checkProject?.vars?.declined_by,
				checkProject?.vars?.approved_by,
			],
			_fields: ['last_name', 'first_name'],
		},
	});

	const userCompanyTitle = user.company_id ? companies[user.company_id]?.title : undefined;

	const initialValues = useMemo(() => {
		if (!checkProject) {
			return;
		}

		const nextTime = checkProject?.schedule ? getNextCheckTimeFromSchedule(checkProject.schedule) : null;
		const hasFinish =
			checkProject?.schedule?.end &&
			checkProject?.schedule?.begin &&
			checkProject?.schedule?.end !== checkProject.schedule?.begin;

		const repeatType = getRepeatType(checkProject.schedule.timetable);
		const schedule = convertScheduleIntoValue(checkProject.schedule.timetable);

		return {
			...checkProject,
			repeat_type: repeatType,
			repeat_interval: repeatType === 'day_interval' ? schedule : 1,
			repeat_weekdays: repeatType === 'weekday' ? schedule : undefined,
			timetable_time: repeatType !== 'no' ? checkProject?.schedule.timetable?.[0]?.begin : undefined,
			once_date_time: repeatType === 'no' ? checkProject?.schedule.begin : undefined,
			begin_date: checkProject?.schedule.begin,
			end_date: checkProject?.schedule.end,
			has_finish: hasFinish ? 'yes' : 'no',
			next_time: nextTime?.toISOString(),
		} as CheckProjectFormValues;
	}, [addMode, editMode, checkProject]);

	const form = useForm<CheckProjectFormValues>({
		defaultValues: getDefaultValues(checkProjectsFields),
		values: initialValues,
		mode: 'onChange',
		resolver: (values) => {
			const errors: FieldErrors<typeof values> = {};

			if (!values.title) {
				errors.title = {
					type: 'required',
					message: t('Обязательное поле'),
				};
			}
			if (values.begin_date && values.end_date && dayjs(values.begin_date).isAfter(dayjs(values.end_date))) {
				errors.begin_date = {
					type: 'custom',
					message: t('Дата окончания не может быть раньше даты начала'),
				};
			}
			if (!values.once_date_time && values.check_project_type !== 'threshold' && values.repeat_type === 'no') {
				errors.once_date_time = {
					type: 'required',
					message: t('Обязательное поле'),
				};
			}
			if (!values.timetable_time && values.repeat_type !== 'no') {
				errors.timetable_time = {
					type: 'required',
					message: t('Обязательное поле'),
				};
			}
			if (!values.begin_date && values.repeat_type !== 'no') {
				errors.begin_date = {
					type: 'required',
					message: t('Обязательное поле'),
				};
			}
			if (!values.end_date && values.repeat_type !== 'no' && values.has_finish === 'yes') {
				errors.end_date = {
					type: 'required',
					message: t('Обязательное поле'),
				};
			}
			if (values.check_project_type === 'threshold' && !values.threshold) {
				errors.threshold = {
					type: 'required',
					message: t('Обязательное поле'),
				};
			}

			return {
				values: isObjectEmpty(errors) ? values : {},
				errors,
			};
		},
	});

	const [formComplete, setFormComplete] = useState(false);

	useEffect(() => {
		const sub = form.watch(() => {
			setFormComplete(isFormComplete(form.getValues()));
		});

		return () => sub.unsubscribe();
	});

	const checkProjectType = form.watch('check_project_type');

	const title = addMode
		? t('Создание проекта контроля')
		: editMode && !addMode
			? t('Редактирование проекта контроля')
			: !editMode && !addMode
				? checkProject?.title
				: '';

	useSetTitle(title);

	const externalId = useUuid([editMode]);

	const sendForm = async (values: CheckProjectFormValues) => {
		const newValues = {
			products: values.products,
			title: values.title,
			check_project_id: addMode ? undefined : values.check_project_id,
			external_id: addMode ? externalId : values.external_id,
			status: 'draft' as const,
			schedule: getSchedule(values),
			threshold: values.threshold,
			check_project_type: values.check_project_type,
			stores: values.stores,
		};

		switch (values.check_project_type) {
			case 'schedule':
				delete newValues.threshold;
				newValues.products.regrading_map = [];
				break;
			case 'threshold':
				newValues.products.product_group_id = [];
				newValues.products.regrading_map = [];
				break;
			case 'regrading':
				delete newValues.threshold;
				newValues.products.product_id = [];
				newValues.products.product_group_id = [];
				break;
		}

		const allowedStatuses = [409];

		toggleLoading(true);
		try {
			const { data } = await api.checkProjects.save(newValues as CheckProjects.CheckProject, {
				disableDefaultNotification: allowedStatuses,
				disableExternalLog: allowedStatuses,
			});

			if (values.status === 'waiting_approve') {
				const { data: newData } = await api.checkProjects.save({
					...data.result,
					status: 'waiting_approve',
				});

				checkProjectData.update(newData);
				navigate(`/check_projects/${newData.result.check_project_id}`);
			} else {
				checkProjectData.update(data);
				navigate(`/check_projects/${data.result.check_project_id}`);
			}

			notification.success({
				message: t('Проект локальной инвентаризации сохранен'),
			});
		} catch (e) {
			notification.error({
				message: allowedStatuses.includes(e.status)
					? t('Проект ЛИ с таким названием уже существует')
					: t('Не удалось сохранить проект локальной инвентаризации'),
			});
		} finally {
			toggleLoading(false);
		}
	};

	const sendWithStatus = (status: string) => {
		form.setValue('status', status);
		void form.handleSubmit(sendForm)();
	};

	const actionOptions = {
		isFormComplete: formComplete,
		isApprovable: checkProject ? isApprovable(user.user_id, checkProject.vars) : true,
	};

	return (
		<>
			<CheckProjectActionModal
				modalOpen={modalOpen}
				toggleModalOpen={toggleModalOpen}
				loading={loading}
				toggleLoading={toggleLoading}
				projectModifiedId={projectModifiedId}
				setProjectModifiedId={setProjectModifiedId}
				checkProject={checkProject}
				updateCheckProject={checkProjectData.update}
			/>
			<DataFormControls
				editMode={editMode}
				addMode={addMode}
				inProgress={loading || checkProjectData.loading}
				disabled={loading || checkProjectData.loading}
				submit={form.handleSubmit(sendForm)}
				saveBtnComponent={() => <SaveButton sendWithStatus={sendWithStatus} isFormComplete={formComplete} />}
				testSelectors={{
					back: 'check_project data page back button',
					exitEdit: 'check_project data page cancelEdit button',
					enterEdit: 'check_project data page edit button',
					save: 'check_project data page save button',
				}}
				title={
					<Title
						title={title ?? ''}
						status={
							checkProject?.status && !(addMode || editMode)
								? renderStatus(checkProject.status, checkProjectStatusesNames, checkProjectStatusColors)
								: undefined
						}
					/>
				}
				quitEditModeLink={
					isPermitCheckProjectsLoad ? (addMode ? '/check_projects' : `/check_projects/${checkProjectId}`) : undefined
				}
				showHeader={!inModal}
				dotsOptions={
					!(addMode || editMode) && checkProject?.status
						? getCheckProjectActions(
								checkProject.status,
								(modalType) => {
									toggleModalOpen(modalType);
									setProjectModifiedId(checkProject.check_project_id);
								},
								commonClasses,
								actionOptions
							)
						: undefined
				}
				additionalButtons={
					!(addMode || editMode)
						? [
								{
									type: 'button',
									text: t('Назад'),
									action: () => navigate('/check_projects'),
									dataTest: 'check_project back button',
								},
							]
						: undefined
				}
			>
				<>
					{/* Переделать, когда в массив errors будут передаваться другие ошибки, помимо too_many_products */}
					{checkProject?.vars?.errors?.includes('too_many_products') && (
						<ErrorsAlert
							errors={[t('Выбранная группа продуктов содержит больше {{count}} товаров', { count: 3000 })]}
							type="warning"
						/>
					)}
					<DataForm
						className={classes.checkProjectForm}
						onSubmit={sendForm}
						editMode={editMode}
						addMode={addMode}
						loading={loading || checkProjectData.loading}
						dataTest={`check_project data page form ${editMode ? 'edit' : 'view'}`}
						formFields={checkProjectsFields}
						hookForm={form}
						renderSections={(sectionProps) => (
							<Tabs
								activeTab={queryParams.active}
								onSelectTab={queryParams.setActive}
								tabsContent={[
									{
										id: 'main',
										label: t('Основное'),
										children: <MainSection {...sectionProps} userCompanyTitle={userCompanyTitle} />,
									},
									{
										id: 'location',
										label: t('Локация'),
										children: <LocationSection {...sectionProps} />,
									},
									{
										id: 'products',
										label: productTabLabels[checkProjectType],
										children: <ProductSection {...sectionProps} />,
									},
									{
										id: 'orders',
										label: t('Документы'),
										children: <OrdersSection checkProjectId={checkProjectId} checkProjectType={checkProjectType} />,
										condition: !editMode,
									},
								]}
							/>
						)}
					/>
				</>
			</DataFormControls>
		</>
	);
};

export default CheckProjectsDataPage;
