import { Breadcrumb, Skeleton } from 'antd';
import { memo, useContext } from 'react';

import { HealthContext } from '../../context';
import { BreadcrumbContent } from './BreadcrumbContent';
import { useStyles } from './styles';
import type { BreadcrumbElement } from './types';

interface Props {
	items: BreadcrumbElement[];
	disabled: boolean;
}

const Breadcrumbs = ({ items, disabled }: Props) => {
	const { classes } = useStyles();
	const { loading } = useContext(HealthContext);

	return (
		<div className={classes.crumbsContainer}>
			{loading ? (
				<Skeleton data-test="health breadcrumbs loading" paragraph={{ rows: 1 }} title={false} active />
			) : (
				<Breadcrumb>
					{items.map((item, i) => {
						const isLastItem = i === items.length - 1;

						return (
							<Breadcrumb.Item key={item.id}>
								<BreadcrumbContent item={item} disabled={disabled || isLastItem} />
							</Breadcrumb.Item>
						);
					})}
				</Breadcrumb>
			)}
		</div>
	);
};

export default memo(Breadcrumbs);
