import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ConfigsPage = lazy(() => import('../components/ConfigsPage'));

const configsRoutes = makeRoutes([
	{
		path: '/configs',
		element: <ConfigsPage />,
	},
]);

export default configsRoutes;
