import type { StorekeeperStatus } from '~shared/constants/storekeepers';
import type { StorekeepersDataType, UserExecutorLastSeenType } from '~shared/hooks/useStorekeepersData';
import type { User } from '~types/user';

import StorekeeperRow from './StorekeeperRow';
import { useStyles } from './styles';

export type StorekeepersCardType = {
	data: StorekeepersDataType;
	status: StorekeeperStatus;
	storeId?: string | null;
	isHealthPage?: boolean;
};

export function isUserExecutorLastSeen(
	storekeeper: User.Executor | UserExecutorLastSeenType
): storekeeper is UserExecutorLastSeenType {
	return (storekeeper as UserExecutorLastSeenType).lastSeen !== undefined;
}

export const StorekeepersCard = ({ data, status, storeId, isHealthPage = false }: StorekeepersCardType) => {
	const { classes } = useStyles({ mode: isHealthPage });

	return (
		<div className={classes.storekeepersCardWrapper}>
			{data[status].length
				? data[status].map((storekeeper: User.Executor | UserExecutorLastSeenType) => {
						return (
							<StorekeeperRow
								key={storekeeper?.user_id}
								user={storekeeper}
								orders={storekeeper.user_id ? data.usersOrders[storekeeper.user_id] : undefined}
								isToggled={status === 'occupied'}
								storeId={storeId}
								isHealthPage={isHealthPage}
							/>
						);
					})
				: null}
		</div>
	);
};
