import axios from 'axios';

import type { PrintTasks } from '~types/printTasks';

export default {
	tasks: (data: { store_id: string; done: boolean }) =>
		axios.post<{ tasks: PrintTasks.Task[] }>('/api/print/admin/tasks', data),
	repeat: (data: { task_id: string }) => axios.post('/api/print/admin/repeat', data),
	sticker: (data: { task_id: string }) => axios.post<Blob>('/api/print/admin/sticker', data, { responseType: 'blob' }),
	cancel: (data: { task_id: string }) => axios.post('/api/print/admin/cancel', data),
};
