import axios from 'axios';

export type WeatherRequest = {
	lat: number;
	lon: number;
	lang?: string;
};

export type Weather = {
	condition?: string;
	icon?: string;
	temperature?: number;
	text?: string | null;
};

export default {
	get: (data: WeatherRequest) => axios.post<{ result: Weather }>('/api/admin/weather/get', data),
};
