import { useRef } from 'react';
import type { DeepPartial } from 'react-hook-form';
import { useController } from 'react-hook-form';

import type { BaseFieldProps, GenericFieldHTMLAttributes, WrappedFieldProps } from './types';

export function Field<
	P extends GenericFieldHTMLAttributes | BaseFieldProps = GenericFieldHTMLAttributes | BaseFieldProps,
>(props: DeepPartial<WrappedFieldProps> & P) {
	const { field, fieldState, formState } = useController({ name: props.name! });
	const prevValue = useRef(field.value);

	if (!('component' in props)) {
		throw new Error('Missing Component props');
	}

	const { component: Component, ...restProps } = props;

	const onChange = (newValue: unknown) => {
		let value = newValue;

		if (props.parse) {
			value = props.parse(value, props.name);
		}
		if (props.normalize) {
			value = props.normalize(value, prevValue.current);
		}

		field.onChange(value);
		props.onAfterChange?.(value);
		prevValue.current = value;
	};

	const value = props.format?.(field.value, field.name) ?? field.value;

	const input = restProps.input ?? {
		...field,
		onChange,
		value,
	};

	return (
		// @ts-expect-error
		<Component
			{...restProps}
			input={input}
			meta={{
				error: fieldState.error?.message,
				submitFailed: !formState.isSubmitSuccessful,
				touched: fieldState.isTouched,
			}}
		/>
	);
}
