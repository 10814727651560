import { Tooltip } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CaretSquareUpRegular } from '~images/icons/caret-square-up-regular.svg';
import { ReactComponent as MarketLogo } from '~images/icons/market-logo.svg';
import CopyString from '~shared/components/hoc/CopyString';
import { Link } from '~shared/ui-kit/Link';
import Icon from '~shared/utils/icons';
import type { Orders } from '~types/orders';

import { useStyles } from './styles';

interface Props {
	order: Orders.Order;
	showModal?: (orderId: string) => void;
	minWidth?: number;
	isLink?: boolean;
}

const OrderNumber = ({ order, showModal, minWidth, isLink }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();

	const parentOrder: string | undefined = order.parent[0];
	const isFromMarket = order.attr.logistic_tags?.includes('market_batching');

	const parentOrderClick = (e: any) => {
		e.stopPropagation();
		if (showModal && parentOrder) {
			showModal(parentOrder);
		}
	};

	const orderNumberElement = (
		<span className={classes.orderNumber} data-test={`order number ${order.doc_number}`}>
			{order.doc_number}
		</span>
	);

	return (
		<div className={classes.numberContainer} style={{ minInlineSize: minWidth }}>
			<CopyString
				string={order.doc_number}
				tooltip={t('Скопировать номер')}
				success={t('Номер документа скопирован')}
				dataTest="order number"
			>
				{isLink ? (
					<Link to={`/stores/${order.store_id}/orders/${order.order_id}`}>{orderNumberElement}</Link>
				) : (
					orderNumberElement
				)}
				{isFromMarket && (
					<Icon
						className={cx(classes.rowIconHideOnHover, classes.rowIcon)}
						component={MarketLogo}
						data-test="market order"
						width={16}
						height={16}
					/>
				)}
				{parentOrder && showModal && (
					<Tooltip title={t('Показать родительский ордер')}>
						<Icon
							className={classes.parentOrderIcon}
							component={CaretSquareUpRegular}
							onClick={parentOrderClick}
							data-test="show parent order"
						/>
					</Tooltip>
				)}
			</CopyString>
		</div>
	);
};

export default memo(OrderNumber);
