import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ iconColor?: string }>()((theme, { iconColor }) => ({
	subTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 15,
		fontSize: '0.9rem',
		color: theme.text.primary,
		paddingBlock: '8px 6px',
		paddingInlineEnd: 12,
		userSelect: 'none',
	},
	columnTitleTextWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
	},
	columnTitleText: {
		gap: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		inlineSize: '100%',
	},
	ratio: {
		background: theme.bgColor.secondary,
		color: theme.text.default,
		paddingBlock: 0,
		paddingInline: 5,
		borderRadius: 5,
		fontSize: '1rem',
		span: {
			color: theme.text.secondary,
		},
	},
	iconWrapper: {
		inlineSize: 24,
		blockSize: 24,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		background: iconColor,
		'&.anticon svg': {
			fill: theme.text.white,
			fontSize: 16,
		},
	},
	columnChankWrapper: {
		background: theme.bgColor.main,
		boxShadow: theme.softShadow,
		borderRadius: 8,
	},
	columnTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '1.1rem',
		fontWeight: 500,
		color: theme.text.primary,
		paddingBlock: '8px 6px',
		paddingInline: 12,
		userSelect: 'none',
		'&.anticon': {
			fontSize: 24,
		},
		borderBlockEnd: `2px solid ${theme.border.primary}`,
		'&:last-child, &.columnTitleNoBorder': {
			border: 'none',
		},
	},
	columnTitleNoBorder: {
		border: 'none',
	},
	chevron: {
		fontSize: 10,
	},
	additionalContentWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: theme.text.primary,
		paddingBlock: '8px 6px',
		paddingInline: '12px 12px',
		userSelect: 'none',
		marginBlockEnd: 6,
	},
	columnPosition: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		inlineSize: '100%',
	},
}));
