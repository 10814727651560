import { red } from '@ant-design/colors';

import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	counter: {
		position: 'relative',
		display: 'inline-block',
	},
	counterMinus: {
		color: red[5],
		'&:before': {
			content: "'–'",
			display: 'block',
			position: 'absolute',
			insetInlineStart: -11,
		},
	},
}));
