import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { Shifts } from '~types/shifts';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<Shifts.ListTagType, Shifts.Tag>('/api/supply/admin/courier_shift_tags/list'),
	active: (data: Shifts.CourierShiftTagActiveRequest, config?: AxiosAdditionalConfig) =>
		axios.post<{ courier_shift_tags: Shifts.Tag[] }>('/api/supply/admin/courier_shift_tags/active', data, config),
	load_title: (data: { title: string | string[] }) =>
		axios
			.post<{
				result: Shifts.Tag[];
				courier_shift_tag: Shifts.Tag[];
			}>('/api/supply/admin/courier_shift_tags/load_title', data)
			.then((resp) => ({ ...resp, data: { result: resp.data.result ?? resp.data.courier_shift_tag } })),
	save: (data: Record<string, any>) =>
		axios.post<{ result: Shifts.Tag }>('/api/supply/admin/courier_shift_tags/save', data),
	load: makeLoadFn<Shifts.Tag, 'courier_shift_tag_id'>(
		'/api/supply/admin/courier_shift_tags/load',
		'courier_shift_tag_id'
	),
};
