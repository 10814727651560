import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useStorekeepersData } from '~shared/hooks/useStorekeepersData';
import { getUsersLastSeenData } from '~shared/utils/getUsersLastSeenData';
import type { Orders } from '~types/orders';

import useRerender from './useRerender';

export const useStoreExecuters = (orders: Orders.Order[], storeId?: string | null, timezone?: string | null) => {
	const { rerenderKey, updateRerenderKey } = useRerender();

	const [userLastSeenData, setUserLastSeenData] = useState<
		| {
				active: Record<string, string>;
				inactive: Record<string, string>;
		  }
		| EmptyObject
	>();

	useEffect(() => {
		const now = dayjs().tz(timezone ?? undefined);
		storeId &&
			getUsersLastSeenData(
				storeId,
				{
					start: now.startOf('day').format(),
					end: now.format(),
				},
				setUserLastSeenData
			);
		const intervalAmount = 240_000; // 4 минуты
		const timerId = setTimeout(() => updateRerenderKey(), intervalAmount);
		return () => clearTimeout(timerId);
	}, [rerenderKey]);

	const { avaliable, occupied, inactive, usersOrders } = useStorekeepersData(
		{
			active: userLastSeenData?.active,
			inactive: userLastSeenData?.inactive,
		},
		orders
	);

	return {
		avaliable,
		occupied,
		inactive,
		usersOrders,
		topWidget: {
			avaliable: avaliable.length,
			occupied: occupied.length,
			inactive: inactive.length,
		},
	};
};
