import { createContext } from 'react';

import type { Suptools } from '~types/suptools';

import type { StashResponse } from './Block';

export type SupportSearchContextType = {
	addBlock: (key: string, text: string, runRequest: boolean) => void;
	removeBlock: (key: string) => void;
	responses: Record<string, StashResponse>;
	handlers: Suptools.SearchHandler[] | null;
};

export const SupportSearchContext = createContext<SupportSearchContextType>({
	responses: {},
	addBlock() {},
	removeBlock() {},
	handlers: null,
});
