import { notification } from '@lavka/ui-kit';
import { Button, Spin } from 'antd';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import ElementField from '~shared/components/Fields/ElementField';
import type { FieldProps } from '~shared/components/Fields/types';
import { Field } from '~shared/components/Forms';

import { CourierIdentification } from '../../CouriersPage/CourierIdentification';
import { CourierPhone } from '../../CouriersPage/CourierPhone';
import { UserPD } from '../UserPD';

export type Props = FieldProps & {
	userType?: 'user' | 'courier';
	userId: string;
	pdType: 'email' | 'phone' | 'identification';
	field: unknown;
	canEdit: boolean;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	initialComponent: JSX.Element;
	isFakePersonalData?: boolean;
};

export function UserPdField(props: Props) {
	const { userId, pdType, editMode, addMode, input, canEdit, userType, initialComponent, isFakePersonalData } = props;
	const [t] = useTranslation();
	const [editVisible, setEditVisible] = useState<boolean>(false);
	const [loading, toggleLoading] = useState<boolean>(false);

	const handleEditClick = async () => {
		if (isFakePersonalData) {
			setEditVisible(true);
			return;
		}

		if (!editMode) {
			return;
		}

		toggleLoading(true);

		try {
			if (userType === 'courier' && pdType === 'phone') {
				const { data } = await api.couriers.load_phone(
					{ courier_id: userId },
					{
						disableDefaultNotification: [400],
					}
				);
				input.onChange(data.phones?.[0] ?? '');
				setEditVisible(true);
			}

			if (userType === 'courier' && pdType === 'identification') {
				const { data } = await api.couriers.load_identifications(
					{ courier_id: userId },
					{
						disableDefaultNotification: [400],
					}
				);
				input.onChange(data.identifications?.[0] ?? '');
				setEditVisible(true);
			}

			if ((userType === 'user' || !userType) && ['phone', 'email'].includes(pdType)) {
				const { data } = await api.users.loadPd(
					{
						user_id: userId,
						data_type: pdType as 'phone' | 'email',
					},
					{ disableDefaultNotification: [429] }
				);
				input.onChange(data.result ?? '');
				setEditVisible(true);
			}
		} catch (e) {
			if (
				editMode &&
				userType === 'courier' &&
				['identification', 'phone'].includes(pdType) &&
				e?.data?.code === 'ER_NOT_EXISTS'
			) {
				input.onChange('');
				setEditVisible(true);
			} else {
				notification.error({
					message: t('Произошла ошибка во время загрузки персональных данных'),
				});
			}
		} finally {
			toggleLoading(false);
		}
	};

	let fieldData: JSX.Element | string = '';

	if (!editMode && (userType === 'user' || !userType) && ['phone', 'email'].includes(pdType)) {
		fieldData = <UserPD userId={userId} type={pdType as 'phone' | 'email'} />;
	} else if (!editMode && userType === 'courier' && pdType === 'phone') {
		fieldData = <CourierPhone courierId={userId} disabled={!canEdit} />;
	} else if (!editMode && userType === 'courier' && pdType === 'identification') {
		fieldData = <CourierIdentification courierId={userId} disabled={!canEdit} />;
	} else if (!addMode && !editVisible) {
		fieldData = loading ? (
			<Spin size="small" />
		) : (
			<Button size="small" onClick={handleEditClick}>
				{editMode ? t('Редактировать') : t('Показать')}
			</Button>
		);
	}
	if (fieldData) {
		return <ElementField {...props} fieldData={fieldData} />;
	}
	return <Field {...props} component={initialComponent} />;
}
