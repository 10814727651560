import { Radio } from 'antd';
import type { CancelTokenSource } from 'axios';
import type { JSX } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

import type { SelectFieldProps } from '../SelectField';
import SelectWithSearch from '../SelectField/SelectWithSearch';
import { useStyles } from '../styles';
import { COLSPAN } from './constants';
import { searchProducts } from './utils';

export type ProductSearchField = 'external_id' | 'title' | 'barcode';

export const productSearchFields = makeDynamicTranslations({
	external_id: tDynamic('Идентификатор'),
	barcode: tDynamic('Штрих-код'),
	title: tDynamic('Название'),
} as const);

interface OwnProps {
	hideLabel?: boolean;
	afterLabel?: any;
	customLayout?: (label: string, input: JSX.Element, params?: JSX.Element) => JSX.Element;
	dataTest?: string;
	colspan?: {
		xl: { span: number };
		lg: { span: number };
		sm: { span: number };
		xs: { span: number };
	};
}

type Props = OwnProps & SelectFieldProps;

const ProductSelectField = ({ hideLabel, customLayout, colspan = COLSPAN, afterLabel, dataTest, ...props }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [searchField, setSearchField] = useState<ProductSearchField>('external_id');
	const cache = useCache({
		products: searchField !== 'barcode' ? props.input.value : undefined,
	});

	const cancelTokenSource = useRef<CancelTokenSource>();

	const paramsComponent = (
		<Radio.Group
			onChange={(e) => setSearchField(e.target.value)}
			defaultValue={searchField}
			className={classes.radioGroup}
		>
			{(Object.keys(productSearchFields) as ProductSearchField[]).map((key: ProductSearchField) => (
				<Radio.Button key={key} value={key} className={`data-test-radio-${productSearchFields[key]}`}>
					{productSearchFields[key]}
				</Radio.Button>
			))}
		</Radio.Group>
	);

	return (
		<SelectWithSearch
			afterLabel={afterLabel}
			customLayout={customLayout}
			paramsComponent={paramsComponent}
			inputClassName={classes.productField}
			getUserValueFunc={(value) => cache.products[value]?.title}
			cache={cache.products[props.input.value]?.title}
			hideLabel={hideLabel}
			colspan={colspan}
			searchFunc={(searchData) => searchProducts(searchData, searchField, cancelTokenSource.current)}
			dataTest={dataTest}
			{...props}
			placeholder={searchField === 'barcode' ? t('Поиск продукта или посылки') : t('Поиск продукта')}
		/>
	);
};

export default ProductSelectField;
