import { Button, Divider, Tooltip } from 'antd';

import { ReactComponent as ResetFilters } from '~images/icons/reset-filters.svg';
import Icon from '~shared/utils/icons';

import { useStyles } from './styles';

interface Props {
	resetFunction: () => void;
	hasDivider?: boolean;
	className?: string;
	tooltip?: string;
}

const ResetFiltersButton = ({ resetFunction, hasDivider, className, tooltip }: Props) => {
	const { cx, classes } = useStyles();
	const button = () => (
		<Button onClick={resetFunction} className={classes.button}>
			<Icon component={ResetFilters} />
		</Button>
	);
	return (
		<div className={cx(classes.container, className)} data-test="reset map filters button">
			{hasDivider && <Divider type="vertical" className={classes.divider} />}
			{tooltip ? <Tooltip title={tooltip}>{button()}</Tooltip> : button()}
		</div>
	);
};

export default ResetFiltersButton;
