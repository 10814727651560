/* eslint-disable max-lines */

import { blue, geekblue, green, grey, purple, red, volcano } from '@ant-design/colors';
import { Spin } from 'antd';
import dayjs from 'dayjs';

import i18n from '~/i18n';
import { edaStatusColorDictionary, order as orderStatuses } from '~constants/order';
import { orderDeliveryStatusNames, orderTypeNames } from '~server-types/doc/api/models/order';
import IdReplacer from '~shared/components/IdReplacer';
import type { PrimaryButton } from '~shared/components/PrimaryButtonComponent';
import Timer from '~shared/components/Timer';
import Title from '~shared/components/Title';
import { Link } from '~shared/ui-kit/Link';
import type { ProColumns, ProTableProps } from '~shared/ui-kit/ProTable';
import courierName from '~shared/utils/courierName';
import isKitchenOrderType from '~shared/utils/isKitchenOrderType';
import isOrderPaused from '~shared/utils/isOrderPaused';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import renderStatus from '~shared/utils/renderStatus';
import userName from '~shared/utils/userName';
import type { Couriers } from '~types/couriers';
import type { Orders } from '~types/orders';
import type { User } from '~types/user';
import { checkExp, checkPermit } from '~zustand/userData';

import { router } from '../../router';
import type { UserToShow } from '../UsersPage/UserModal';
import Courier from './Courier';
import { CourierPhoneButton } from './CourierPhoneButton';
import { DispatchDeliveryType } from './DispatchDeliveryType';
import OrderNumber from './OrderNumber';
import { addressWithDistance } from './OrdersDataPage/utils';
import PaymentCell from './OrdersTab/PaymentCell';
import { OrderType } from './OrderType';
import PartnerTakenBtn from './PartnerTakenBtn';
import { formatPlannedTime } from './Utils/plannedTime';
import { renderLongTitle } from './Utils/renderLongTitle';

export const suggestStatusColorDictionary = {
	request: 'green',
	done: 'gold',
	error: 'red',
};

export const orderProblemsTypes = makeDynamicTranslations({
	low: tDynamic('недостаточно товара'),
	no_waybill: tDynamic('не указан номер ТТН'),
	shelf_not_found: tDynamic('полка не найдена'),
	product_not_found: tDynamic('продукт не найден'),
	item_not_found: tDynamic('посылка не найдена'),
	product_duplicate: tDynamic('продукт уже есть в списке'),
	product_without_components: tDynamic('продукт без компонентов'),
	empty_products: tDynamic('пустая секция products'),
	empty_shelves: tDynamic('пустая секция shelves'),
	product_reserved: tDynamic('товар зарезервирован'),
	stock_changed: tDynamic('остатки изменились в процессе обработки'),
	child_order_incomplete: tDynamic('подчиненный заказ завершен некорректно'),
	shelf_conflict_weight_product: tDynamic('на одной полке не могут находится разные диапазоны одного весового товара'),
	have_stocks: tDynamic('есть остатки'),
	no_experiment: tDynamic('необходим эксперимент'),
});

export const getConfig = (inModal: boolean = false) => {
	return makeDynamicTranslations({
		tableTitle: tDynamic('Диспетчерская'),
		formTitle: tDynamic('Общая информация'),
		rowKey: 'order_id',
		formTitles: {
			edit: tDynamic('Редактирование ордера'),
			add: tDynamic('Создание ордера'),
			view: (order: Orders.Order, storeId?: string, additionalButtons?: PrimaryButton[]) => (
				<Title
					title={`${order.type ? orderTypeNames[order.type] || order.type : i18n.t('Ордер')} ${
						order.doc_number ? order.doc_number : ''
					} ${order.study ? '(учебный)' : ''}`}
					additionalButtons={additionalButtons}
					copyLink={`${window.location.origin}/stores/${storeId}/orders/${order.order_id}`}
					copyLinkTwo={order.order_id}
					externalId={order.type === 'order' ? order.external_id : undefined}
					successTextTwo={i18n.t('ID документа скопирован')}
					showChatSupport={!!storeId && checkPermit('chat')}
					inModal={inModal}
				/>
			),
		},
		btns: {
			add: tDynamic('Создать ордер'),
		},
	});
};

export const dataTest = {
	table: {
		requested: 'dispatcher table requested',
		processed: 'dispatcher table processed',
		wrong: 'dispatcher table wrong',
		complete: 'dispatcher table complete',
		orders: 'dispatcher table orders',
		add: 'orders toolbar add button',
		pickup: 'dispatcher table partner pickup orders',
	},
	form: {
		back: 'orders data page back button',
		exitEdit: 'orders data page cancelEdit button',
		enterEdit: 'orders data page edit button',
		save: 'orders data page save button',
	},
};
export const routes = {
	newTable: (storeId: string) => `/stores/${storeId}/orders_new`,
	table: (storeId: string) => `/stores/${storeId}/orders`,
	add: (storeId: string) => `/stores/${storeId}/orders/add`,
	view: (storeId: string, id: string) => `/stores/${storeId}/orders/${id}`,
};

export const statusColorDictionary = {
	reserving: '',
	approving: 'geekblue',
	request: 'blue',
	processing: 'purple',
	complete: 'green',
	canceled: 'volcano',
	failed: 'red',
	assign_OK: 'green',
	assign_FAIL: 'red',
	unassign_OK: 'green',
	unassign_FAIL: 'red',
};

export const statusStringColorDictionary = {
	reserving: grey[8],
	approving: geekblue[6],
	request: blue[6],
	processing: purple[6],
	complete: green[6],
	canceled: volcano[6],
	failed: red[6],
	assign_OK: green[6],
	assign_FAIL: red[6],
	unassign_OK: green[6],
	unassign_FAIL: red[6],
};

export const statusStringColorDictionaryDark = {
	reserving: grey[2],
	approving: '#2b4acb',
	request: '#177ddc',
	processing: '#854ECA',
	complete: '#49aa19',
	canceled: '#d84a1b',
	failed: '#d32029',
	assign_OK: '#49aa19',
	assign_FAIL: '#d32029',
	unassign_OK: '#49aa19',
	unassign_FAIL: '#d32029',
};

export const orderTableColumns = (
	showModal?: {
		order?: (orderId: string) => void;
		user?: (user: UserToShow) => void;
	},
	hideColumns?: string[],
	additionalData?: {
		orders: Record<string, Orders.Order | undefined>;
		userExecutors: Record<string, User.Executor | undefined>;
		isOrderNumberLink?: boolean;
	}
) =>
	makeDynamicTranslations<ProColumns<Orders.Order>[]>([
		{
			title: tDynamic('Дата'),
			dataIndex: 'doc_date',
			key: 'doc_date',
			width: 90,
			hideInTable: hideColumns?.includes('doc_date'),
			sorter: (a, b) => dayjs(a.doc_date).diff(dayjs(b.doc_date)),
			render: (doc_date) => {
				return formatTimeAndYear(doc_date, { onlyDate: true });
			},
		},
		{
			title: tDynamic('Создан'),
			dataIndex: 'created',
			key: 'created',
			width: 130,
			hideInTable: hideColumns?.includes('created'),
			sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
			render: (created) => {
				return formatTimeAndYear(created);
			},
		},
		{
			title: tDynamic('Вид операции'),
			key: 'type',
			hideInTable: hideColumns?.includes('type'),
			sorter: (a, b) => {
				if (orderTypeNames[a.type] && b.type) return orderTypeNames[a.type].localeCompare(orderTypeNames[b.type]);
				if (orderTypeNames[a.type]) return 1;
				if (orderTypeNames[b.type]) return -1;
				return 0;
			},
			render: (order) => {
				return <OrderType order={order} ordersByIds={additionalData?.orders ?? {}} />;
			},
			shouldCellUpdate: () => true,
		},
		{
			title: tDynamic('Номер'),
			key: 'doc_number',
			hideInTable: hideColumns?.includes('doc_number'),
			render: (row) => (
				<OrderNumber order={row} showModal={showModal?.order} isLink={additionalData?.isOrderNumberLink} />
			),
			shouldCellUpdate: () => true,
		},
		{
			title: tDynamic('Позиций'),
			key: 'required_amount',
			hideInTable: hideColumns?.includes('required_amount'),
			render: (row: Orders.Order) => row.required.length,
			sorter: (a, b) => a.required.length - b.required.length,
		},
		{
			title: tDynamic('Сумма заказа'),
			key: 'invoice_sum',
			hideInTable: !checkExp('exp_snatch') || hideColumns?.includes('invoice_sum'),
			render: (row: Orders.Order) => <PaymentCell order={row} />,
		},
		{
			title: tDynamic('Контрагент/Описание'),
			dataIndex: 'contractor',
			key: 'contractor',
			hideInTable: hideColumns?.includes('contractor'),
			render: (text: string | undefined, data: Orders.Order) => {
				if ((checkExp('exp_snatch') || checkExp('exp_partner_pickup_list')) && data.attr?.partner_order_id) {
					return data.attr.partner_order_id;
				}
				return renderLongTitle(text, data.attr?.humanized_title);
			},
			shouldCellUpdate: () => true,
		},
		{
			title: tDynamic('Партнер'),
			key: 'partner_name',
			hideInTable: hideColumns?.includes('partner_name'),
			render: (data: Orders.Order) => data.attr.partner_name,
		},
		{
			title: tDynamic('OTP-код'),
			key: 'otp_code',
			hideInTable: hideColumns?.includes('otp_code'),
			render: (data: Orders.Order) => data.attr.courier_pin,
		},
		{
			title: tDynamic('Причина'),
			key: 'attr.documents_comment',
			hideInTable: hideColumns?.includes('documents_comment'),
			render: (row: Orders.Order) => renderLongTitle(row.attr.documents_comment),
		},
		{
			title: tDynamic('Исполнители'),
			key: 'users',
			hideInTable: hideColumns?.includes('users'),
			render: (row: Orders.Order) => {
				const usersLength = row.users?.length;
				if (usersLength) {
					if (checkPermit('users_load')) {
						const executor = additionalData?.userExecutors[row.users[0]];

						return (
							<>
								<Link
									to={`/users/${executor?.user_id ?? ''}`}
									data-test={`order user show modal ${executor?.user_id}`}
									onLeftClick={(e) => {
										e.stopPropagation();

										if (executor && showModal?.user) {
											showModal?.user({
												user_id: executor.user_id,
												fullname: userName(executor),
											});
										}
									}}
								>
									<IdReplacer id={row.users[0]} type="userExecutors" keyReplaceOn={['last_name', 'first_name']} />
								</Link>
								{usersLength > 1 ? ` +${usersLength - 1}` : null}
							</>
						);
					} else {
						return (
							<>
								<IdReplacer id={row.users[0]} type="userExecutors" keyReplaceOn={['last_name', 'first_name']} />
								{usersLength > 1 ? ` +${usersLength - 1}` : null}
							</>
						);
					}
				} else {
					return '—';
				}
			},
			shouldCellUpdate: () => true,
		},
		{
			title: tDynamic('Статус'),
			dataIndex: 'status',
			key: 'status',
			hideInTable: hideColumns?.includes('status'),
			width: '120px',
			sorter: (a, b) => {
				if (orderTypeNames[a.status] && b.status)
					return orderTypeNames[a.status].localeCompare(orderTypeNames[b.status]);
				if (orderTypeNames[a.status]) return 1;
				if (orderTypeNames[b.status]) return -1;
				return 0;
			},
			render: (text: string) => renderStatus(text as string, orderStatuses, statusColorDictionary),
		},
		{
			key: 'mark_as_taken',
			hideInTable: hideColumns?.includes('mark_as_taken'),
			width: '120px',
			render: (order: Orders.Order) => (
				<PartnerTakenBtn order_id={order.order_id} orderNumber={order.doc_number} otpCode={order.attr.courier_pin} />
			),
		},
	]);

export const edaOrderMask = /(\d){6}-(\d){6}/g;

export const showDispatch = (order: Orders.Order): boolean => {
	if (order.doc_number.match(edaOrderMask)) {
		return false;
	}
	if (['PICKUP', 'UNKNOWN', 'DELIVERED', 'CANCELLED', 'ARRIVED_TO_CUSTOMER'].includes(order.eda_status)) {
		return false;
	}
	if (order.eda_status === 'ORDER_TAKEN') {
		return checkExp('exp_order_reassign_after_taken');
	}

	return isKitchenOrderType(order.type) && ['request', 'processing', 'complete'].includes(order.status);
};

export const orderTableColumnsOrder = (additionalData: {
	order: (orderId: string) => void;
	user: (user: UserToShow) => void;
	unassignCourier: (order_id: Orders.Order['order_id']) => void;
	storeCoords?: number[];
	couriers?: Record<string, Couriers.CourierBrief | undefined>;
	isLoading?: boolean;
	isOrderNumberLink?: boolean;
}): ProColumns<Orders.Order>[] =>
	makeDynamicTranslations([
		{
			title: tDynamic('Дата создания'),
			dataIndex: 'created',
			key: 'created',
			sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
			render: (text, row: Orders.Order) => {
				return <div style={{ minInlineSize: 135 }}> {formatTimeAndYear(row.created, { onlyDate: false })}</div>;
			},
		},
		{
			title: tDynamic('Номер'),
			dataIndex: 'doc_number',
			key: 'doc_number',
			render: (_, row: Orders.Order) => (
				<OrderNumber
					order={row}
					showModal={additionalData?.order}
					minWidth={170}
					isLink={additionalData.isOrderNumberLink}
				/>
			),
			shouldCellUpdate: () => true,
		},
		{
			title: tDynamic('Сумма заказа'),
			key: 'invoice_sum',
			hideInTable: !checkExp('exp_snatch'),
			render: (_, row: Orders.Order) => <PaymentCell order={row} />,
		},
		{
			title: tDynamic('Статус'),
			dataIndex: 'status',
			key: 'status',
			render: (text: string) => (
				<div style={{ minInlineSize: 90 }}> {renderStatus(text, orderStatuses, statusColorDictionary)} </div>
			),
		},
		{
			title: tDynamic('Статус доставки'),
			dataIndex: 'eda_status',
			key: 'eda_status',
			render: (text: string) => (
				<div style={{ minInlineSize: 125 }}>
					{renderStatus(text, orderDeliveryStatusNames, edaStatusColorDictionary)}
				</div>
			),
		},
		{
			title: tDynamic('Осталось времени'),
			key: 'delivery_promise',
			render: (_, data: Orders.Order) => {
				if (isOrderPaused(data.paused_until)) {
					return <span style={{ color: '#FA8C16' }}>{i18n.t('На паузе')}</span>;
				}

				if (!data.attr.time_slot && data.delivery_promise) {
					return <Timer time={data.delivery_promise} word />;
				}

				if (data.attr.time_slot?.end && !['reserving', 'approving'].includes(data.status)) {
					return <Timer time={data.attr.time_slot.end} word />;
				}

				return '—';
			},
		},
		{
			title: tDynamic('Доставить до'),
			key: 'planned_time',
			render: (_, data: Orders.Order) => formatPlannedTime(data),
		},
		{
			title: tDynamic('Курьер'),
			dataIndex: 'courier_id',
			key: 'courier_id',
			render: (text: string | null, data: Orders.Order) => {
				const fullname = courierName(additionalData.couriers?.[text!]);
				return additionalData.isLoading ? (
					<Spin size="small" />
				) : (
					<Courier
						showDispatch={showDispatch(data)}
						openUnassignCourierModal={() => additionalData.unassignCourier(data.order_id)}
						courier={additionalData.couriers?.[text!]}
						name={fullname && fullname !== '—' ? fullname : data.courier?.name}
						id={text!}
					/>
				);
			},
			shouldCellUpdate: () => true,
		},
		{
			title: tDynamic('Телефон'),
			dataIndex: 'courier',
			key: 'phones',
			render: (text: Orders.Order['courier'], row: Orders.Order) => (
				<CourierPhoneButton showButton={!!text} orderId={row.order_id} />
			),
			hideInTable: !checkExp('exp_allow_external_phones'),
		},
		{
			title: tDynamic('Адрес'),
			dataIndex: 'client_address',
			key: 'client_address',
			render: (text) => addressWithDistance(text, additionalData.storeCoords),
			shouldCellUpdate: () => true,
		},
		{
			title: tDynamic('Очередь'),
			dataIndex: 'dispatch_delivery_type',
			render: (_, order: Orders.Order) => <DispatchDeliveryType order={order} />,
		},
	]);

export const getTableConfig = (
	storeId: string | undefined,
	rowClick: (record: Orders.Order) => void,
	searchColumnOptions?: { key: string; options: any }[],
	showModal?: {
		order?: (orderId: string) => void;
		user?: (user: UserToShow) => void;
	},
	hideColumns?: string[],
	additionalData?: {
		disableRowClick?: boolean;
		orders: Record<string, Orders.Order | undefined>;
		userExecutors: Record<string, User.Executor | undefined>;
		user?: Partial<User.User>;
		isOrderNumberLink?: boolean;
	}
): ProTableProps<Orders.Order> => ({
	rowKey: getConfig().rowKey,
	size: 'small' as const,
	columns: searchColumnOptions
		? orderTableColumns(showModal, hideColumns, additionalData).map((column) => {
				const searchOptions = searchColumnOptions.find((option) => option.key === column.key)?.options;
				if (searchOptions) {
					return {
						...column,
						...searchOptions,
					};
				}
				return column;
			})
		: orderTableColumns(showModal, hideColumns, additionalData),
	pagination: false as const,
	tableLayout: 'fixed' as const,
	scroll: { x: true as const },
	rowClassName: (record: any) => {
		return `${record.problems.length ? 'order-problem-row' : ''} data-test-${
			record.vars?.dataTest || 'order-row'
		} order-row`;
	},
	onRow: additionalData?.disableRowClick
		? undefined
		: (record: any) => {
				return {
					onClick: () => {
						if (rowClick) {
							rowClick(record);
						} else {
							void router.navigate(`/stores/${storeId}/orders/${record.order_id}`);
						}
					},
					onMouseDown: (event) => {
						if (event.button === 1 || event.metaKey || event.ctrlKey) {
							window.open(`/stores/${storeId}/orders/${record.order_id}`, '_blank');
						}
					},
				};
			},
});
