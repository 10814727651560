import type { MouseEventHandler } from 'react';

import { ReactComponent as IndentRegular } from '~images/icons/indent-regular.svg';
import { ReactComponent as MobileMenu } from '~images/icons/mobile-menu.svg';
import { ReactComponent as OutdentRegular } from '~images/icons/outdent-regular.svg';
import Icon from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from '../styles';

export const MobileMenuButton = ({
	open,
	toggleOpen,
	isTabletOrMobile = false,
	isMobileTableau = false,
}: {
	open: boolean;
	toggleOpen?: MouseEventHandler<HTMLSpanElement>;
	isTabletOrMobile?: boolean;
	isMobileTableau?: boolean;
}) => {
	const { classes } = useStyles({});
	const { cx, classes: commonClasses } = useCommonStyles();
	const showMenu = isMobileTableau ? isMobileTableau : isTabletOrMobile;

	return showMenu ? (
		<div
			className={cx(classes.burgerButton, { mobileMenu: isMobileTableau, mobileMenuOpened: isMobileTableau && open })}
		>
			<Icon
				className={cx(commonClasses.btnIcon, classes.collapseIcon, {
					mobileMenuIcon: isMobileTableau,
				})}
				component={isMobileTableau ? MobileMenu : open ? OutdentRegular : IndentRegular}
				onClick={toggleOpen}
			/>
		</div>
	) : null;
};
