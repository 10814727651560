import type { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SelectUserWithSearch from '~shared/components/Fields/SelectField/SelectUserWithSearch';
import SwitchField from '~shared/components/Fields/SwitchField';
import { Field, Form } from '~shared/components/Forms';
import type { User } from '~types/user';

type Props = {
	hookForm: UseFormReturn<User.AdvancedUser>;
	onSubmit: SubmitHandler<User.AdvancedUser>;
	onlyChange?: boolean;
};

export default ({ hookForm, onSubmit, onlyChange }: Props) => {
	const [t] = useTranslation();

	return (
		<Form onSubmit={onSubmit} hookForm={hookForm} data-test="change order_user form">
			<Field
				label={t('Введите сотрудника, которого хотите назначить')}
				key="user_id"
				name="user_id"
				editMode={true}
				allowClear
				colspan={{
					xl: { span: 12 },
					lg: { span: 12 },
					sm: { span: 12 },
					xs: { span: 24 },
				}}
				entityName="userExecutors"
				additionalOptions={{ suggestEntities: true, onlyActive: true }}
				component={SelectUserWithSearch}
			/>
			{!onlyChange && (
				<Field
					label={t('Добавить исполнителя к документу')}
					key="add_user"
					name="add_user"
					editMode={true}
					defaultChecked={true}
					colspan={{
						xl: { span: 12 },
						lg: { span: 12 },
						sm: { span: 12 },
						xs: { span: 24 },
					}}
					component={SwitchField}
				/>
			)}
		</Form>
	);
};
