import dayjs from 'dayjs';

import getTroubleView from '../Blocks/CriticalBlock/getTroubleView';
import type { Trouble } from '../healthTypes';

interface Args {
	data: Trouble[] | undefined;
	searchData: {
		event?: string[] | undefined;
	};
}

export const getCritViewData = ({ data, searchData }: Args) => {
	const filteredData = data?.filter(
		(e) => !searchData.event?.length || searchData.event?.includes(e.order_type) || searchData.event?.includes(e.reason)
	);
	return filteredData
		?.map(getTroubleView)
		.sort((a, b) => (dayjs(a.beginOfProblem).isBefore(dayjs(b.beginOfProblem)) ? 1 : -1));
};
