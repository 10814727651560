import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	wrapper: {
		inlineSize: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: '4px',
		marginBlockEnd: '20px',
		position: 'relative',

		'@media (min-width: 768px)': {
			gridTemplateColumns: 'repeat(2, 1fr)',
		},

		'@media (min-width: 1280px)': {
			gridTemplateColumns: 'repeat(auto, minmax(360px, 1fr))',
		},

		'@media (min-width: 1440px)': {
			gridTemplateColumns: 'repeat(4, minmax(150px, 1fr))',
		},
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
	},
	greyText: {
		color: theme.text.light,
	},
	columnTag: {
		background: 'grey',
	},
	sumBlock: {
		display: 'flex',
		gap: 10,
		span: {
			fontSize: '0.9rem',
		},
	},
	primaryText: {
		color: theme.text.default,
	},
}));
