import { dispatchDeliveryType } from '~constants/order';
import { ReactComponent as Taxi } from '~images/icons/taxi-fallback.svg';
import type { Orders } from '~types/orders';

import { useStyles } from './styles';

interface Props {
	order: Orders.Order;
}

export const DispatchDeliveryType = ({ order }: Props) => {
	const { classes } = useStyles();

	const deliveryType = order.dispatch_delivery_type ? dispatchDeliveryType[order.dispatch_delivery_type] : undefined;

	if (!deliveryType && order.attr.time_slot) {
		return '—';
	}

	return (
		<div className={classes.dispatchDeliveryType}>
			{deliveryType && <span data-test="dispatch_delivery_type">{deliveryType}</span>}
			{!order.attr.time_slot && (
				<span className={classes.dispatchDeliveryTypeTaxiIcon}>
					<Taxi />
				</span>
			)}
		</div>
	);
};
