import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import type { ComponentProps } from 'react';

import { useStyles } from './styles';

interface Props extends ComponentProps<typeof Button> {
	tooltip?: string;
}

const DeleteButton = (props: Props) => {
	const { classes } = useStyles();
	const { tooltip, ...buttonProps } = props;

	const button = (
		<Button
			ghost
			className={classes.removeItemButton}
			data-test="remove button"
			icon={<DeleteOutlined />}
			{...buttonProps}
		/>
	);
	return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

export default DeleteButton;
