import dayjs from 'dayjs';

import { arrayUniq } from '~shared/utils/arrayUniq';
import type { Orders } from '~types/orders';

import { statusOrder } from './pageConfig';

export const checkProjectOrdersUniqueDates = (checkProjectOrders: Orders.Order[]): string[] => {
	return arrayUniq(checkProjectOrders.map((order) => order.doc_date));
};

export const checkProjectOrdersNewestDate = (uniqueDates: string[]) =>
	uniqueDates.reduce((prev, curr) => (prev < curr ? curr : prev), '');

export const checkProjectOrdersConfig = (data: Orders.Order[]): Record<string, string> => {
	const uniqueDates = checkProjectOrdersUniqueDates(data);
	const config: Record<string, string> = {};

	uniqueDates.forEach((date) => {
		config[date] = date;
	});

	return config;
};

export const sortByStatusAndProcessingStatusTime = (data: Orders.Order[]) =>
	data.sort(
		(a: Orders.Order, b: Orders.Order) =>
			statusOrder[a.status] - statusOrder[b.status] ||
			dayjs(a.status_time?.processing).diff(dayjs(b.status_time?.processing))
	);

export const checkProjectOrdersFilteredByDate = (checkProjectOrders: Orders.Order[], filteredDates: string[]) => {
	const filteredDatesSet = new Set(filteredDates);
	return checkProjectOrders.filter((order) => filteredDatesSet.has(order.doc_date));
};
