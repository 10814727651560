import { useTranslation } from 'react-i18next';

import { UserAvatar } from '~shared/components/UserAvatar';
import type { UserExecutorLastSeenType } from '~shared/hooks/useStorekeepersData';
import userName from '~shared/utils/userName';
import type { User } from '~types/user';

import { isUserExecutorLastSeen } from '..';
import { useStyles } from './styles';

export type StorekeeperUserNameProps = {
	user: User.Executor | UserExecutorLastSeenType;
	isHealthPage: boolean;
};

const StorekeeperUserName = ({ user, isHealthPage }: StorekeeperUserNameProps) => {
	const [t] = useTranslation();
	const { classes } = useStyles({ mode: isHealthPage });
	const userFullname = userName(user) || '—';
	return (
		<>
			<div className={classes.storekeeperNameWrapper}>
				{user.user_id && <UserAvatar name={userFullname} userId={user.user_id} showInitials={true} />}
				<span className={classes.storekeeperName}>{userFullname}</span>
			</div>
			{isUserExecutorLastSeen(user) ? (
				<div className={classes.storekeeperLastSeenWrapper}>
					<span>{user.lastSeen}</span>
					<span className={classes.storekeeperLastSeen}>{t('Последняя активность')}</span>
				</div>
			) : null}
		</>
	);
};

export default StorekeeperUserName;
