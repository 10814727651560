import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	fileRow: {
		display: 'flex',
		marginBlockStart: 16,
		alignItems: 'center',
		'&>span': {
			display: 'block',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			maxInlineSize: 600,
			marginInlineEnd: 8,
		},
	},
	importBoxTitle: {
		color: theme.text.primary,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '16px',
		lineHeight: '24px',
		marginBlockStart: '22px !important',
	},
	importBoxText: {
		color: theme.text.primary,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '22px',
	},
	encodingRow: {
		paddingBlockStart: 16,
	},
	template: {
		fontSize: 16,
		marginBlockEnd: 8,
	},
}));
