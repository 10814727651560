import dayjs from 'dayjs';

import type { Orders } from '~types/orders';

export function formatPlannedTime(data: Orders.Order) {
	if (data.attr.time_slot) {
		return [dayjs(data.attr.time_slot.start).format('HH:mm'), dayjs(data.attr.time_slot.end).format('HH:mm')].join('—');
	}
	if (data.delivery_promise) {
		return dayjs(data.delivery_promise).format('HH:mm:ss');
	}

	return '—';
}
