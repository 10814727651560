import { useEffect, useRef, useState } from 'react';

import type { ClusterMapLayer } from '~server-types/doc/api/models/cluster';

export default function <In, Out>(
	values: Record<string, In>,
	mapType: ClusterMapLayer | null,
	func: (value: In) => Promise<Out>
): Record<string, Out> {
	const [valueMap, setValueMap] = useState<Record<string, Out>>({});
	const results = useRef<Record<string, Out>>({});
	const inProgress = useRef(new Set<string>());
	const prevMapType = useRef<ClusterMapLayer | null>(mapType);

	const refreshValueMap = async () => {
		const newIds: string[] = Object.keys(values).filter(
			(entityId) => !(entityId in results.current) && !inProgress.current.has(entityId)
		);
		if (!newIds.length) return;

		await Promise.all(
			newIds.map(async (id) => {
				let value: Out;
				inProgress.current.add(id);

				try {
					value = await func(values[id]);
				} catch (e) {
					inProgress.current.delete(id);
					throw e;
				}

				if (inProgress.current.has(id)) {
					results.current[id] = value;
					inProgress.current.delete(id);
				}
			})
		);

		setValueMap({ ...results.current });
	};

	useEffect(() => {
		const removedKeys = [...Object.keys(results.current), ...inProgress.current].filter((key) => !(key in values));
		removedKeys.forEach((key) => {
			inProgress.current.delete(key);
			delete results.current[key];
		});
		if (removedKeys.length) {
			setValueMap({ ...results.current });
		}

		void refreshValueMap();
	}, [values]);

	useEffect(() => {
		if (prevMapType.current !== mapType) {
			prevMapType.current = mapType;
			results.current = {};
			void refreshValueMap();
		}
	}, [mapType]);

	return valueMap;
}
