import { lazy } from 'react';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

const DxgyCommonDataPage = lazy(() => import('../components/TariffPlans/Dxgy/DxgyCommonDataPage'));
const DxgyPersonalDataPage = lazy(() => import('../components/TariffPlans/Dxgy/DxgyPersonalDataPage'));

const dxgyRoutes = makeRoutes([
	// Общие цели
	{
		path: '/dxgy_common/draft/add',
		element: <PrependPath type="cluster" />,
	},
	{
		path: '/clusters/:cluster_id/dxgy_common/draft/add',
		permit: 'dxgy_create',
		element: <DxgyCommonDataPage addMode editMode />,
	},
	{
		path: '/clusters/:cluster_id/dxgy_common/draft/edit/:draft_id',
		permit: 'dxgy_save',
		element: <DxgyCommonDataPage editMode />,
	},
	{
		path: '/clusters/:cluster_id/dxgy_common/draft/:draft_id',
		permit: 'dxgy_proxy',
		element: <DxgyCommonDataPage />,
	},
	{
		path: '/clusters/:cluster_id/dxgy_common/:goal_id',
		permit: 'dxgy_proxy',
		element: <DxgyCommonDataPage />,
	},
	// Персональные цели
	{
		path: '/dxgy_personal/draft/add',
		element: <PrependPath type="cluster" />,
	},
	{
		path: '/clusters/:cluster_id/dxgy_personal/draft/add',
		permit: 'dxgy_create',
		element: <DxgyPersonalDataPage addMode editMode />,
	},
	{
		path: '/clusters/:cluster_id/dxgy_personal/draft/edit/:draft_id',
		permit: 'dxgy_save',
		element: <DxgyPersonalDataPage editMode />,
	},
	{
		path: '/clusters/:cluster_id/dxgy_personal/draft/:draft_id',
		permit: 'dxgy_proxy',
		element: <DxgyPersonalDataPage />,
	},
	{
		path: '/clusters/:cluster_id/dxgy_personal/:goal_id',
		permit: 'dxgy_proxy',
		element: <DxgyPersonalDataPage />,
	},
]);

export default dxgyRoutes;
