import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	card: {
		inlineSize: 350,
		blockSize: 163,
		borderRadius: 0,
		'& .ant-card-body': {
			padding: 12,
		},
	},
	cardView: {
		backgroundColor: theme.bgColor.main,
		borderRadius: 12,
		border: 'none',
		blockSize: 'auto',
		'& .ant-card-body': {
			padding: '20px',
			paddingInlineEnd: '16px',
		},
	},
	codeImage: {
		inlineSize: '100% !important',
		blockSize: '100% !important',
	},
	avatar: {
		marginBlockEnd: 24,
	},
	dataString: {
		fontSize: 12,
		fontWeight: 500,
		wordBreak: 'break-word',
		color: theme.text.primary + '!important',
		marginBlockEnd: '0 !important',
	},
	dataStringSecondary: {
		fontSize: 12,
		fontWeight: 400,
		wordBreak: 'break-word',
		color: theme.text.primary + '!important',
		marginBlockEnd: '0 !important',
	},
	codeContainer: {
		position: 'relative',
		'& canvas': {
			backgroundColor: gray[0],
			border: '2px solid ' + gray[0],
		},
	},
	updateIcon: {
		marginBlockEnd: 16,
		fontSize: 30,
	},
	updateCover: {
		position: 'absolute',
		insetBlockStart: 0,
		insetBlockEnd: 0,
		insetInlineStart: 0,
		insetInlineEnd: 0,
		backgroundColor: 'rgba(230, 247, 255, .95)',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		justifyContent: 'center',
		fontSize: 12,
		padding: 8,
	},
	toPrint: {
		marginBlock: '5mm',
		marginInline: '5mm',
		'& .update-cover': {
			display: 'none',
		},
	},
	cardData: {
		marginBlockEnd: 0,
	},
	controls: {
		paddingBlockStart: 16,
		borderBlockStart: '2px solid #f5f5f5',
		marginBlockStart: 16,
	},
	codeDataContainer: {
		marginInlineEnd: 8,
		inlineSize: 115,
		float: 'left',
	},
	cardDataBlock: {
		display: 'flex',
		flexDirection: 'column',
		blockSize: '100%',
	},
	cardBottomData: {
		marginBlockStart: 8,
	},
	cardBodyInner: {
		blockSize: 120,
	},
	cardButton: {
		inlineSize: '100%',
		borderColor: theme.border.secondary,
		marginBlockEnd: 5,
	},
	printDiv: {
		overflow: 'hidden',
		blockSize: 0,
		inlineSize: 0,
	},
}));
