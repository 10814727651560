import { Col, Row } from 'antd';
import type { JSX } from 'react';

import { getColSpan } from '~shared/components/Fields/utils';

import { COLSPAN } from './constants';

export const TwoColumnCheckboxLayout = (el: JSX.Element) => {
	return (
		<Row gutter={[0, { xs: 0, sm: 20 }]} style={{ marginBlockEnd: 8 }}>
			<Col {...getColSpan(COLSPAN)}></Col>
			<Col {...getColSpan(COLSPAN)}>{el}</Col>
		</Row>
	);
};

export const NormalCheckboxLayout = (el: JSX.Element) => {
	return (
		<Row gutter={[0, { xs: 0, sm: 20 }]} style={{ insetBlockStart: 0 }}>
			{el}
		</Row>
	);
};
