import dayjs from 'dayjs';

import getProperTime from '~shared/utils/transformSecondsToTimeString';

import type { HealthLevelMetrics, HealthMetric, PeriodState, TimeIntervalDataObject } from '../../healthTypes';
import type { TickValue } from '../ChartsComparisonModal';
import type { EnrichedGraphsPoint } from './index';

// Фиксирую формат - dayjs format по умолчанию подставляет то Z, то +00:00
const DEFAULT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

type DateRange = {
	minDate: string | null;
	maxDate: string | null;
};

type LabelType = 'five_min' | 'hour' | 'day';

const getChartLabelType = (minDate: string | null, maxDate: string | null): LabelType | undefined => {
	if (!minDate || !maxDate) return;
	const diff = dayjs(maxDate).diff(minDate, 'day');
	if (diff < 1) {
		return 'five_min';
	} else if (diff < 14) {
		return 'hour';
	} else {
		return 'day';
	}
};

const getLabelFormat = (labelType: LabelType | undefined, timestamp: string) => {
	if (dayjs(timestamp).format('HH:mm') === '00:00' && labelType === 'hour') {
		return 'DD.MM';
	}
	return labelType === 'day' ? 'DD.MM' : 'HH:mm';
};

export const getMetricGraphData = (
	metric: keyof HealthLevelMetrics,
	data: EnrichedGraphsPoint[],
	retroData: EnrichedGraphsPoint[] = [],
	comparisonType: PeriodState['comparisonType'],
	thresholds?: Record<string, number>,
	isAllRetroUsed?: boolean
): TimeIntervalDataObject[] => {
	const { minDate, maxDate }: DateRange = data.reduce(
		(acc: DateRange, point) => {
			const isBeforeMin = dayjs.tz(point.timestamp).isBefore(dayjs.tz(acc.minDate));
			const isAfterMax = dayjs.tz(point.timestamp).isAfter(dayjs.tz(acc.maxDate));
			if (acc.minDate === null || isBeforeMin) {
				acc.minDate = point.timestamp;
			}
			if (acc.maxDate === null || isAfterMax) {
				acc.maxDate = point.timestamp;
			}
			return acc;
		},
		{ minDate: null, maxDate: null }
	);

	const labelType = getChartLabelType(minDate, maxDate);

	const timestampMap: Record<string, { now?: EnrichedGraphsPoint; past?: EnrichedGraphsPoint }> = {};

	retroData.forEach((point) => {
		if (!point.timestamp) return;
		const labelFormat = getLabelFormat(labelType, point.timestamp);
		const label = dayjs.tz(point.timestamp).format(labelFormat);
		timestampMap[point.timestamp] = { past: { ...point, label } };
	});

	data?.forEach((point) => {
		if (!point.timestamp) return;
		const correspondingPastTimestamp = dayjs.utc(point.timestamp).subtract(1, comparisonType).format(DEFAULT_FORMAT);

		const labelFormat = getLabelFormat(labelType, point.timestamp);
		const label = dayjs.tz(point.timestamp).format(labelFormat);

		if (timestampMap[correspondingPastTimestamp]?.past) {
			timestampMap[correspondingPastTimestamp].now = { ...point, label };
		}
	});

	return Object.keys(timestampMap)
		.map((timestamp) => {
			const { now, past } = timestampMap[timestamp];

			const retroDate = past?.label ?? dayjs.tz(past?.timestamp).format('HH:mm');
			const nowDate = now?.label ?? dayjs.tz(now?.timestamp).format('HH:mm');

			// значение может быть числом, null и undefined
			// null означает, что данных не было передано, что эквивалентно нулю
			// undefined означает, что мы в будущем или данных ещё нет, поэтому передаём undefined,
			// который в компоненте будет отображаться как прочерк
			const value = now?.[metric] === undefined ? undefined : Math.floor(Number(now?.[metric]));
			const retroValue = past?.[metric] === undefined ? undefined : Math.floor(Number(past?.[metric]));

			return {
				key: metric,
				date: isAllRetroUsed ? retroDate : nowDate,
				fullDate: now?.timestamp ? dayjs.tz(now?.timestamp) : dayjs.tz(past?.timestamp),
				retroFullDate: dayjs.tz(past?.timestamp),
				value,
				retroValue,
				hours: dayjs.tz(past?.timestamp).hour(),
				minutes: dayjs.tz(past?.timestamp).minute(),
				comparisonType: now?.comparisonType,
				threshold_max: thresholds?.[metric],
			};
		})
		.filter((point) => isAllRetroUsed || point.value !== undefined)
		.sort((a, b) => (a.retroFullDate.isAfter(b.retroFullDate) ? 1 : -1));
};

export const getAveragedData = (data: TimeIntervalDataObject[], isDataByHour?: boolean, tickValue?: TickValue) => {
	if (!tickValue || tickValue === 5 || isDataByHour) return data;

	let sum = 0;
	let retroSum = 0;
	// интервал усреднения: точки каждые 5 минут, смотрим, сколько раз 5 минут в нашем интервале
	const intervalLength = tickValue / 5;

	const averagedData = [] as typeof data;
	data.forEach((dataPoint, index) => {
		sum += dataPoint.value ?? 0;
		retroSum += dataPoint.retroValue ?? 0;

		// в конце каждого интервала или в конце массива добавляем усреднённую сумму в массив
		if (!((index + 1) % intervalLength) || index === data.length - 1) {
			let denominator;

			// если мы в массив укладывается не ровное количество интервалов
			// то усредняем не по длине интервала, а по длине остатка
			if (!((index + 1) % intervalLength)) {
				denominator = intervalLength;
			} else {
				denominator = data.length % intervalLength;
			}

			sum /= denominator;
			retroSum /= denominator;
			averagedData.push({
				...data[index],
				value: sum,
				retroValue: retroSum,
			});
			sum = 0;
			retroSum = 0;
		}
	});
	return averagedData;
};

const getMetricValue = (value: number, key: string) =>
	key === 'orders_count' ? Math.floor(Number(value)) : getProperTime(value, true, true);

export const getTooltipAverageData = (
	metrics: string[],
	data: Partial<Record<HealthMetric, TimeIntervalDataObject[]>>,
	dataPoint?: TimeIntervalDataObject,
	tickValue?: TickValue,
	isDataByHour?: boolean
) => {
	const tooltipAverageData = {} as Partial<Record<HealthMetric, TimeIntervalDataObject>>;
	metrics.forEach((metric) => {
		const allAveragedData = {} as typeof data;
		allAveragedData[metric] = getAveragedData(data[metric], isDataByHour, tickValue);

		const currentIndex = allAveragedData[metric].findIndex(
			(item: TimeIntervalDataObject) => item.date === dataPoint?.date
		);
		const currentMetricPoint = allAveragedData[metric][currentIndex];

		const { key, value, retroValue } = currentMetricPoint ?? {};

		tooltipAverageData[metric] = {
			...currentMetricPoint,
			value: value && getMetricValue(value, key),
			retroValue: retroValue && getMetricValue(retroValue, key),
		};
	});

	return tooltipAverageData;
};
