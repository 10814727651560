import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { FileInfo } from '~types/common';

export default {
	export_csv: (data: { product_id: string }, config?: AxiosAdditionalConfig) =>
		axios.post<{ code: string; message?: string; file_info?: FileInfo }>(
			'/api/admin/stocks_log/export_csv',
			data,
			config
		),
};
