import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
	filters: Record<string, Record<string, boolean | string | number>>;
	setFilterValues: (filterKey: string, filterValues: Record<string, boolean | string | number>) => void;
};

export const useFiltersStore = create<State>()(
	devtools(
		immer((set) => ({
			filters: {},
			setFilterValues: (filterKey: string, filterValues: Record<string, boolean | string | number>) => {
				set(
					(state) => {
						state.filters[filterKey] = filterValues;
					},
					false,
					'setFilterValues'
				);
			},
		})),
		{ name: 'filtersData' }
	)
);

// Это в компонентах
export const useTableFilters = () => useFiltersStore((state) => state.filters);

// Это в функциях
export const setTableFilters = ({
	filterKey,
	filterValues,
}: {
	filterKey: string;
	filterValues: Record<string, boolean | string | number>;
}) => useFiltersStore.getState().setFilterValues(filterKey, filterValues);
