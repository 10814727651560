import { useState } from 'react';

import type { OrderType } from '~server-types/doc/api/models/order';
import type { Event } from '~shared/utils/ev';
import isKitchenOrderType from '~shared/utils/isKitchenOrderType';
import type { Events } from '~types/events';
import type { Orders } from '~types/orders';
import { addCouriers } from '~zustand/couriersData';
import { getOrders, getOrdersData, updateOrders, useOrders } from '~zustand/ordersData';

import { Audioplayer } from '../utils/audio';
import useSubscribe from './useSubscribe';

const edaStatusesToReloadOrder = ['ORDER_TAKEN', 'ARRIVED_TO_CUSTOMER', 'DELIVERED'];
const isReloadOrderForDeliveryTimer = (event: Events.OrderEvent, orderInStore: Orders.Order) =>
	event?.eda_status &&
	orderInStore.eda_status !== event.eda_status &&
	edaStatusesToReloadOrder.includes(event.eda_status);

type Props = {
	storeId: string;
	loadIncomplete?: boolean;
	updateCouriers?: boolean;
	timeZone?: string;
	onSuccess?: () => void;
};
export default function ({ storeId, loadIncomplete, updateCouriers, timeZone, onSuccess }: Props): {
	ordersData: {
		orders: Orders.Order[];
		loaded: boolean;
	};
	orderEvents: Events.OrderEvent[];
} {
	const { orders, loaded } = useOrders();
	const [orderEvents, setOrderEvents] = useState<Events.OrderEvent[]>([]);

	const processedEventData = (data: Event) => {
		const orderIdToLoad: string[] = [];
		const orderDataToUpdate: any[] = [];
		const couriersToUpdate: string[] = [];
		const orderEvents = data.data.filter(({ type }) =>
			isKitchenOrderType(type as OrderType | undefined)
		) as Events.OrderEvent[];
		setOrderEvents(orderEvents);
		// в области видимости остается ordersData из первого рендера диспетчерской
		orderEvents.forEach((orderEvent) => {
			const processesOrdersData = getOrdersData();
			const orderInStore = processesOrdersData[orderEvent.order_id];
			if (!orderInStore) {
				orderIdToLoad.push(orderEvent.order_id);
				if (orderEvent.status === 'request' && orderEvent.estatus === 'waiting') {
					Audioplayer.play('alert.m4a');
				}
			} else {
				if (
					updateCouriers &&
					orderEvent.status === 'complete' &&
					orderEvent.estatus === 'done' &&
					orderInStore.courier_id
				) {
					couriersToUpdate.push(orderInStore.courier_id);
				}
				if (orderInStore.version < orderEvent.version || isReloadOrderForDeliveryTimer(orderEvent, orderInStore)) {
					orderIdToLoad.push(orderEvent.order_id);
				} else {
					orderDataToUpdate.push(orderEvent);
				}
			}
		});
		if (orderDataToUpdate.length) {
			updateOrders(orderDataToUpdate as Orders.Order[]);
		}
		if (orderIdToLoad.length) {
			getOrders({
				ids: orderIdToLoad,
				supplementUpdateTimezone: timeZone,
			});
		}
		if (couriersToUpdate.length) {
			addCouriers(couriersToUpdate);
		}
		onSuccess?.();
	};

	const eventCB = (data: Event, code: string) => {
		switch (code) {
			case 'OK':
				processedEventData(data);
				break;
			case 'INIT':
			case 'MAYBE_DATA_LOST':
				getOrders({ loadIncomplete });
				break;
			default:
				break;
		}
	};

	useSubscribe({
		key: ['order', 'store', storeId],
		cb: eventCB,
		unSub: false,
		single: true,
		name: 'dispatcher',
		needInit: true,
	});

	return { ordersData: { orders: Object.values(orders), loaded }, orderEvents };
}
