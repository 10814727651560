import type { Event } from '~shared/utils/ev';
import type { Events } from '~types/events';
import { addCouriers, getCourierCheckins, getCouriers, updateCouriers, useCouriers } from '~zustand/couriersData';

import useSubscribe from './useSubscribe';

export default function (storeId: string) {
	const couriersData = useCouriers();

	const processCouriers = (data: Events.CourierEventData) => {
		const couriersState = getCouriers();

		const couriersList: Events.CourierEvent[] = data.data;
		const couriersToUpdate: Events.CourierEvent[] = [];
		const couriersToGet: string[] = [];
		couriersList.forEach((courier) => {
			if (couriersState.couriers[courier.courier_id]) {
				couriersToUpdate.push(courier);
			} else {
				couriersToGet.push(courier.courier_id);
			}
		});

		updateCouriers(couriersToUpdate);
		addCouriers(couriersToGet);
	};

	const eventCB = (data: Event, code: string) => {
		switch (code) {
			case 'OK': {
				processCouriers(data as Events.CourierEventData);
				break;
			}
			case 'INIT':
			case 'MAYBE_DATA_LOST':
				getCourierCheckins({ storeId });
				break;
			default:
				break;
		}
	};

	useSubscribe({
		key: ['courier', 'store', storeId],
		cb: eventCB,
		unSub: false,
		single: true,
		name: 'courier_dispatcher',
		needInit: true,
	});

	return { couriersData };
}
