import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	list: {
		paddingBlockStart: 28,
		paddingInlineStart: 0,
		listStyle: 'none',
		display: 'flex',
		flexDirection: 'column',
	},
	listHorizontal: {
		paddingBlockStart: 28,
		paddingInlineStart: 0,
		listStyle: 'none',
		'&>img': {
			marginInlineEnd: 12,
		},
		'&>img:last-of-type': {
			marginInlineEnd: 0,
		},
	},
	image: {
		inlineSize: 220,
		blockSize: 220,
		marginBlockEnd: 28,
		objectFit: 'cover',
	},
	gallery: {
		inlineSize: '100%',
		paddingBlockEnd: 23,
		position: 'relative',
		borderBlockEnd: `2px solid ${theme.border.disabled}`,
		'& .image-gallery-thumbnail': {
			inlineSize: 64,
			borderRadius: 2,
			border: '2px solid transparent',
			'& + .image-gallery-thumbnail': {
				marginInlineStart: '14px !important',
			},
		},
		'& .image-gallery-thumbnails': {
			padding: '0',
			paddingBlockStart: '23px',
		},
		'& .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover': {
			border: '2px solid #1890ff!important',
		},
		'& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg': {
			blockSize: 60,
			inlineSize: 30,
		},
		'& .image-gallery-icon:hover': {
			color: '#1890ff!important',
		},
	},
	navIcon: {
		position: 'absolute',
		zIndex: 1,
		insetBlockEnd: -75,
		blockSize: 40,
		display: 'flex',
		alignItems: 'center',
		'& svg': {
			inlineSize: 12,
			blockSize: 20,
			fill: '#BFBFBF',
		},
		'&:hover': {
			'& svg': {
				transition: 'fill .2s',
				fill: '#1890FF',
			},
		},
	},
	navIconLeft: {
		insetInlineStart: -30,
	},
	navIconRight: {
		insetInlineEnd: -30,
	},
}));
