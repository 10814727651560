import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	header: {
		display: 'flex',
		marginBlockEnd: 12,
	},
	mapRow: {
		blockSize: 'calc(100% - 76px)',
		minBlockSize: 'calc(100vh - 264px)',
	},
	buttonsContainer: {
		display: 'flex',
		marginBlock: '12px 0',
		marginInline: '12px',
		justifyContent: 'center',
		'& button:first-of-type': {
			borderStartStartRadius: '4px !important',
			borderEndStartRadius: '4px !important',
		},
		'& button:last-child': {
			borderStartEndRadius: '4px !important',
			borderEndEndRadius: '4px !important',
		},
	},
	locationSwitcherContainer: {
		display: 'inline-flex',
		alignItems: 'center',
	},
	locationSwitcher: {
		marginInlineEnd: 8,
	},
	checkboxGroup: {
		'&.ant-checkbox-group': {
			display: 'flex',
			flexDirection: 'column',
			'&>label': {
				marginInlineStart: 0,
			},
		},
	},
	dropdownContainer: {
		paddingBlock: '0',
		paddingInline: '0',
		border: 'none',
		'& .ant-checkbox-wrapper': {
			marginBlockEnd: 16,
			'&:last-child': {
				marginBlockEnd: 4,
			},
		},
	},
	filterTitle: {
		fontSize: 16,
		fontWeight: 500,
		color: `${theme.text.primary} !important`,
		margin: 0,
		paddingBlock: '0',
		paddingInline: '16px',
	},
	collapser: {
		'& .ant-collapse-content-box': {
			paddingBlockStart: 0,
			paddingBlockEnd: 0,
		},
		'& .ant-collapse-header': {
			paddingBlock: '8px !important',
			paddingInline: '32px !important',
			paddingInlineStart: '16px !important',
			fontSize: 14,
			fontWeight: 500,
			color: `${theme.text.secondary} !important`,
			background: 'transparent',
		},
		'& .ant-collapse-item': {
			borderBlockEnd: 'none',
			backgroundColor: theme.bgColor.main,
		},
		'& .ant-collapse-arrow': {
			insetInlineStart: 'auto!important',
			insetInlineEnd: 12,
			paddingBlockStart: '8px !important',
		},
	},
	filterBlock: {
		paddingBlockStart: 16,
		minInlineSize: 252,
		maxBlockSize: '70vh',
		overflow: 'auto',
	},
	mapControls: {
		marginBlockEnd: 16,
		display: 'flex',
		alignItems: 'center',
		'&>button': {
			marginInlineEnd: 8,
			backgroundColor: theme.bgColor.main,
			color: theme.text.primary,
		},
	},
	legend: {
		display: 'flex',
		flexWrap: 'wrap',
		listStyle: 'none',
		paddingBlock: '16px',
		paddingInline: '0',
		'&>li': {
			marginInlineEnd: 12,
			display: 'flex',
			alignItems: 'center',
			color: theme.text.secondary,
			'&:last-child': {
				marginInlineEnd: 0,
			},
		},
	},
	colorBox: {
		inlineSize: 12,
		blockSize: 12,
		borderRadius: 4,
		marginInlineEnd: 8,
	},
	spin: {
		marginInlineStart: 16,
		position: 'relative',
		insetBlockStart: 3,
	},
}));
