import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { Shifts } from '~types/shifts';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	save: (data: Shifts.ScheduleSave, config?: AxiosAdditionalConfig) =>
		axios.post<{ job_id?: string | null; result: Shifts.ShiftScheduleObject }>(
			'/api/supply/admin/courier_shift_schedules/save',
			data,
			config
		),
	list: makeListFn<Shifts.ListShiftSchedule, Shifts.ShiftScheduleObject>(
		'/api/supply/admin/courier_shift_schedules/list'
	),
	load: makeLoadFn<Shifts.ShiftScheduleObject, 'courier_shift_schedule_id'>(
		'/api/supply/admin/courier_shift_schedules/load',
		'courier_shift_schedule_id'
	),
	setStatus: (
		data: { courier_shift_schedule_id: string; status: Shifts.ScheduleSaveBase['status'] },
		config?: AxiosAdditionalConfig
	) =>
		axios.post<{ job_id?: string | null; courier_shift_schedule?: Shifts.ShiftScheduleObject }>(
			'/api/supply/admin/courier_shift_schedules/set_status',
			data,
			config
		),
};
