import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
	},
	userInfo: {
		paddingInline: '12px',
		display: 'inline-block',
	},
	userRole: {
		fontSize: '13px',
		lineHeight: '14px',
		color: theme.uiKit.semantic.textMinor,
	},
	userStore: {
		fontSize: '16px',
		lineHeight: '17px',
		color: theme.uiKit.semantic.textOnError,
	},
}));
