import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ noPadding: boolean }>()((theme, { noPadding }) => ({
	container: {
		blockSize: '100vh',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.bgColor.main,
	},
	content: {
		display: 'flex',
		overflow: 'hidden',
		flex: 1,
	},
	main: {
		paddingBlock: noPadding ? 0 : '16px',
		paddingInline: noPadding ? 0 : '24px',
		flex: 1,
		overflowY: 'auto',
	},
	spin: {
		blockSize: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		flexWrap: 'wrap',
	},
}));
