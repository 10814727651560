import { Button, notification } from '@lavka/ui-kit';
import type { ErrorPageProps } from '@lavka/ui-kit/lib/esm/components/ErrorPage';
import { ErrorPage } from '@lavka/ui-kit/lib/esm/components/ErrorPage';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import api from '~/api';
import { appSessionId, appVersion } from '~/appConfig';
import { useCache } from '~cache/useCache';
import { changeJoinedStore } from '~zustand/actions/userData';
import { getUserData, useUserConstants } from '~zustand/userData';

import IdReplacer from '../IdReplacer';
import { useStyles } from './styles';

type NoAccessType = {
	details: {
		store_id?: string;
		company_id?: string;
	};
	className?: string;
};

const NoAccess = ({ error }: { error: NoAccessType }) => {
	const { classes } = useStyles();
	const [t] = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const constants = useUserConstants();

	const cache = useCache({
		companies: error.details?.company_id,
		stores: error.details?.store_id,
	});

	const company = error.details?.company_id ? cache.companies[error.details?.company_id] : null;

	const linkToEntity = async () => {
		if (!error.details?.store_id && !error.details?.company_id) {
			return navigate('/');
		}

		try {
			if (error.details?.store_id) {
				await changeJoinedStore(error.details.store_id);
			}
			if (error.details?.company_id) {
				await api.companies.joinCompany({ company_id: error.details?.company_id });

				notification.success({
					message: t('Вы прикреплены к компании «{{companyTitle}}»', {
						companyTitle: company?.title,
					}),
				});
			}
			await getUserData();
			await navigate(location, { replace: true });
		} catch {
			notification.error({
				message: error.details?.company_id
					? t('Не удалось прикрепиться к компании')
					: t('Не удалось прикрепиться к складу'),
			});
		}
	};

	const buttonText = useMemo(() => {
		if (error.details?.store_id) {
			return t('Прикрепиться к складу');
		}
		if (error.details?.company_id) {
			return t('Прикрепиться к компании');
		}
		return t('Вернуться в главное меню');
	}, [error]);

	const additionals = [
		appSessionId && { name: 'Error id', value: appSessionId },
		appVersion && { name: 'UI', value: appVersion },
		constants.backend_version && { name: 'Backend', value: constants.backend_version },
	].filter(Boolean) as ErrorPageProps['additionals'];

	return (
		<ErrorPage
			errorCode="403"
			title={t('Страница недоступна')}
			subtitle={
				<div className={classes.subtitleContainer}>
					{t('У вас нет прав доступа к данной странице')}
					{error.details?.company_id || error.details?.store_id ? (
						<IdReplacer
							id={error.details?.company_id ? error.details?.company_id : error.details?.store_id}
							type={error.details?.company_id ? 'companies' : 'stores'}
							keyReplaceOn="title"
							link={
								error.details?.company_id
									? `/companies/${error.details?.company_id}`
									: `/stores/${error.details?.store_id}`
							}
							className={classes.errorDetail}
						/>
					) : null}
				</div>
			}
			actions={
				<Button fullWidth={true} onClick={linkToEntity} data-testid="link button">
					{buttonText}
				</Button>
			}
			additionals={additionals}
			data-testid="error page"
			className={error.className}
		/>
	);
};

export default NoAccess;
