import { lazy } from 'react';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

const TariffPlansPage = lazy(() => import('../components/TariffPlans'));
const TariffPlanDataPage = lazy(() => import('../components/TariffPlans/TariffPlanBase/TariffPlanDataPage'));

const tariffsRoutes = makeRoutes([
	{
		path: '/tariff_plans',
		permit: 'tariff_plans_seek',
		element: <TariffPlansPage />,
	},
	{
		path: '/tariff_plans/add',
		element: <PrependPath type="cluster" />,
	},
	{
		path: 'clusters/:cluster_id/tariff_plans/add',
		permit: 'tariff_plans_create',
		element: <TariffPlanDataPage editMode addMode />,
	},
	{
		path: '/tariff_plans/edit/:tariff_plan_id',
		permit: 'tariff_plans_save',
		element: <TariffPlanDataPage editMode />,
	},
	{
		path: '/tariff_plans/:tariff_plan_id',
		permit: 'tariff_plans_load',
		element: <TariffPlanDataPage />,
	},
]);

export default tariffsRoutes;
