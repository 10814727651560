import { mobileMaxWidth } from '~constants/breakPoints';
import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	smallText: {
		fontSize: '0.8em',
		color: theme.text.icon,
		marginBlockEnd: 0,
	},
	bigText: {
		fontWeight: 'bold',
		marginBlock: '3px 5px',
		marginInline: '0',
	},
	tooltipContainer: {
		backgroundColor: 'black',
		opacity: '85%',
		borderRadius: 5,
		padding: 10,
	},
	smallTooltipContainer: {
		inlineSize: 100,
	},
	bigTooltipContainer: {
		inlineSize: 200,
	},
	hugeTooltipContainer: {
		inlineSize: 300,
		paddingBlock: '10px',
		paddingInline: '15px',
	},

	smallDataBlock: {
		display: 'flex',
		flexDirection: 'column',
		marginBlockEnd: 5,
		padding: 0,
	},
	topDataBlock: {
		display: 'flex',
		flexDirection: 'row',
		gap: 20,
		justifyContent: 'start',
	},
	verticalDataBlock: {
		display: 'flex',
		flexDirection: 'column',
	},
	bottomDataBlock: {
		display: 'flex',
		flexDirection: 'row',
		margin: 0,
	},
	hugeBottom: {
		justifyContent: 'start',
		rowGap: 10,
	},
	detailBlock: {
		inlineSize: '50%',
	},
	hugeDetailValue: {
		gap: 10,
	},
	smallLabel: {
		color: '#BFBFBF',
		fontSize: '0.8em',
	},
	smallData: {
		color: gray[2],
	},
	normText: {
		color: theme.text.statusBlue,
	},
	aboveNormText: {
		color: theme.text.statusRed,
	},
	dotSeparator: {
		display: 'none',
		[`@media (min-width:${mobileMaxWidth}px)`]: {
			position: 'relative',
			insetBlockEnd: '5px',
			display: 'block',
			borderBlockEnd: `1px dotted ${theme.border.primary}`,
			flexGrow: 1,
			marginBlock: '0',
			marginInline: '4px',
		},
	},
	tooltipSubtitle: {
		color: gray[2],
		fontSize: '0.8em',
	},
	statBlock: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		inlineSize: 200,
		minBlockSize: 80,
		borderRadius: 12,
		paddingBlock: 12,
		paddingInline: 16,
		marginBlockEnd: 15,
		backgroundColor: theme.bgColor.main,
		color: theme.text.primary,
		boxShadow: `1px 1px 8px 2px ${theme.bgColor.disabled}`,
	},
	statBlockTitle: {
		fontSize: '1em',
		lineHeight: '1.5em',
	},
	blockDataVertical: {
		inlineSize: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	blockDataHorizontal: {
		inlineSize: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	statBlockCount: {
		inlineSize: '100%',
		display: 'flex',
		alignItems: 'end',
		justifyContent: 'flex-start',
		fontWeight: 500,
		fontSize: '1.2em',
		lineHeight: '1.4em',
	},
	statBlockCountArabic: {
		justifyContent: 'flex-end',
	},
	statBlockDiagram: {
		display: 'flex',
		alignItems: 'end',
		justifyContent: 'flex-end',
	},
	statBlockBad: {
		backgroundColor: theme.bgColor.table.problem,
		color: theme.border.statusVolcano,
	},
	badValue: {
		color: 'red',
	},
}));
