import cutLongText from '~shared/utils/cutLongText';
import type { Couriers } from '~types/couriers';

export default (
	courier:
		| (Partial<Pick<Couriers.Courier, 'display_name' | 'last_name' | 'first_name' | 'vars'>> & {
				courier_id?: string | null;
		  })
		| Couriers.CourierBrief
		| string
		| undefined,
	options: {
		showEatsId?: boolean;
		returnId?: boolean;
		showDisplayName?: boolean;
		cutLongText?: number;
	} = {
		showEatsId: true,
	}
) => {
	if (!courier) {
		return '—';
	}

	if (typeof courier === 'string') {
		return courier;
	}

	let courierName = '';

	if (courier?.first_name && courier?.last_name && !options?.showDisplayName) {
		courierName = `${courier.last_name} ${courier.first_name}`;
	} else if (courier.display_name) {
		courierName = courier.display_name;
	}

	if (courierName) {
		if (options?.showEatsId) {
			let eatsId: string | null | undefined = undefined;

			if ('vars' in courier) {
				eatsId = courier.vars?.external_ids?.eats;
			} else if ('eats_id' in courier) {
				eatsId = courier.eats_id;
			}

			if (eatsId) {
				courierName = `${courierName} (${eatsId})`;
			}
		}

		if (options?.cutLongText) {
			courierName = cutLongText(courierName, options.cutLongText);
		}

		return courierName;
	}

	if (options?.returnId && courier?.courier_id) {
		return courier.courier_id;
	}

	return '—';
};
