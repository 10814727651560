import { notification, PageHeaderButton } from '@lavka/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImportTextFileModal } from '~shared/components/ImportTextFileModal';
import { UploadOutlined } from '~shared/utils/icons';
import { splitLines } from '~shared/utils/splitLines';

type Props = {
	onImport: (schetTasksCSV: string) => void;
};

export const ImportSchetTasksButton = ({ onImport }: Props) => {
	const [t] = useTranslation();
	const [importFileModalJsonVisible, setImportFileModalJsonVisible] = useState<boolean>(false);

	const validateCSV = (text: string) => {
		const hasInterval = splitLines(text)
			.filter(Boolean)[0]
			.split('\t')
			.some((column) => column === 'interval');
		if (!hasInterval) {
			throw Error(t('Некорректный CSV: поле {{ field }} отсутствует', { field: 'interval' }));
		}
		return null;
	};

	const onImportCSV = (text: string) => {
		try {
			validateCSV(text);
			onImport(text);
		} catch (error) {
			notification.error({
				message: error,
			});
			return;
		}
	};

	return (
		<>
			<ImportTextFileModal
				visible={importFileModalJsonVisible}
				setVisible={setImportFileModalJsonVisible}
				onImport={onImportCSV}
				validate={validateCSV}
				title={t('Импорт заданий')}
				extension=".csv"
				fileName="schets"
				onError={() => {
					notification.error({
						message: t('Не удалось импортировать задания'),
					});
				}}
			/>
			<PageHeaderButton
				view="header-accent"
				data-test="dropdown import schets button"
				onClick={() => setImportFileModalJsonVisible(true)}
				iconLeft={<UploadOutlined />}
			>
				{t('Импорт')}
			</PageHeaderButton>
		</>
	);
};
