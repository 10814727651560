import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useCurrentPageTitle } from '~zustand/currentPage';

const HelmetWrapper = () => {
	const [t, i18n] = useTranslation();
	const lang = i18n.language;
	const currentPageTitle = useCurrentPageTitle();

	return (
		<Helmet defer={false}>
			<html lang={lang} dir={i18n.dir()} />
			<title>{`${t('Яндекс Склад')} ${currentPageTitle ? ' — ' + currentPageTitle : ''}`}</title>
		</Helmet>
	);
};

export default HelmetWrapper;
