import { useContext } from 'react';

import analyticStatsInfo from '~constants/analyticStatsInfo';
import { ReactComponent as LightningSharp } from '~images/icons/lightning-sharp.svg';
import { ReactComponent as MonitorIcon } from '~images/icons/tv-regular.svg';
import { Link } from '~shared/ui-kit/Link';
import type { ProColumns } from '~shared/ui-kit/ProTable';
import { ProTable } from '~shared/ui-kit/ProTable';
import capitalize from '~shared/utils/capitalize';
import Icon from '~shared/utils/icons';

import { clusterTableColumnKeys, tableColumnKeys } from '../../constants';
import { HealthContext } from '../../context';
import type { ThresholdDataEntry } from '../../hooks/useGetThresholds';
import type { StoresTableData } from '../../hooks/useHealthData';
import redirectToTableau from '../../utils/redirectToTableau';
import MetricWithGoalCell from './MetricWithGoalCell';
import { useStyles } from './styles';

type Props = {
	data: StoresTableData[];
	isClusters: boolean;
	thresholds?: Record<string, ThresholdDataEntry>;
};

const StoreStatTable = ({ data, isClusters, thresholds }: Props) => {
	const { classes } = useStyles();
	const { loading, userStore } = useContext(HealthContext);
	const keys = isClusters ? clusterTableColumnKeys : tableColumnKeys;
	const columns: ProColumns<StoresTableData>[] = keys.map((col) => {
		const colData = analyticStatsInfo[col];
		const resultCol: ProColumns<StoresTableData> = {
			title: capitalize(colData.label),
			render: (text: StoresTableData) => <span data-test={colData.key}>{text[colData.key] ?? '—'}</span>,
			sorter: (a, b) => (a[col] ?? 0) - (b[col] ?? 0),
		};
		if (colData.type === 'surge') {
			resultCol.align = 'center';
			resultCol.render = (text) =>
				isClusters || !text ? (
					<span data-test={colData.key}>{text[colData.key] ?? '—'}</span>
				) : (
					<div data-test={colData.key} className={classes.surgeContainer}>
						<Icon component={LightningSharp} size={13} />
						<span className={classes.surge}> × {text[colData.key]}</span>
					</div>
				);
		}
		if (colData.type === 'time') {
			resultCol.render = (text, data: StoresTableData) => (
				<MetricWithGoalCell
					metric={colData.key}
					value={text[colData.key]}
					threshold={
						isClusters
							? thresholds?.[data.cluster_id ?? '']?.[colData.key]
							: thresholds?.[data.store_id ?? '']?.[colData.key]
					}
				/>
			);
		}
		if (colData.type === 'store_link') {
			resultCol.render = (text, data: StoresTableData) => (
				<div className={classes.storeTitleCell} data-test={colData.key}>
					<Link to={`?type=store&id=${data.store_id}`}>{text[colData.key]}</Link>
					<MonitorIcon
						width={16}
						onClick={() =>
							redirectToTableau(
								{ id: userStore?.store_id ?? '', title: userStore?.title ?? '' },
								{ id: data.store_id ?? '', title: data.title ?? '' }
							)
						}
						className={classes.tableauIcon}
					/>
				</div>
			);
			resultCol.sorter = (a, b) => (a.title ?? '').localeCompare(b.title ?? '');
		}
		if (colData.type === 'cluster_link') {
			resultCol.render = (text, data: StoresTableData) => (
				<span data-test={colData.key}>
					{text[colData.key] === '—' ? (
						text[colData.key]
					) : (
						<Link to={`?type=cluster&id=${data.cluster_id}`}>{text[colData.key]}</Link>
					)}
				</span>
			);
			resultCol.sorter = (a, b) => (a.cluster_title ?? '').localeCompare(b.cluster_title ?? '');
		}
		return resultCol;
	});

	return (
		<div className={classes.storeStatTable} data-test="health list block">
			<ProTable
				loading={loading}
				tableLayout="auto"
				columns={columns}
				rowKey={isClusters ? 'cluster_id' : 'store_id'}
				data-test={isClusters ? 'health cluster stat table' : 'health store stat table'}
				dataSource={data}
			/>
		</div>
	);
};

export default StoreStatTable;
