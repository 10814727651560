import { blue, cyan, geekblue, green, grey, lime, magenta, orange, purple, red, volcano } from '@ant-design/colors';

import { orderTypeValues } from '~server-types/doc/api/models/order';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { Orders } from '~types/orders';

export const order = makeDynamicTranslations({
	reserving: tDynamic('Резервирование'),
	approving: tDynamic('Подтверждение'),
	request: tDynamic('Запрос'),
	processing: tDynamic('Обработка'),
	complete: tDynamic('Готов'),
	canceled: tDynamic('Отменен'),
	failed: tDynamic('Невозможно выполнить'),
	assign_OK: tDynamic('Назначение курьера'),
	assign_Fail: tDynamic('Назначение курьера'),
	unassign_OK: tDynamic('Снятие курьера и поиск нового'),
	unassign_FAIL: tDynamic('Снятие курьера и поиск нового'),
});

export const orderStatusesList = Object.keys(order).reduce(
	(result, status) => {
		result[status] = status;
		return result;
	},
	{} as Record<string, string>
);

export const orderIndicatorValues: Orders.OrderIndicatorType[] = ['begin', 'export', 'import', 'done'];

export const orderIndicatorTypes = makeDynamicTranslations<Record<Orders.OrderIndicatorType, string>>({
	begin: tDynamic('Подсчёт остатков'),
	export: tDynamic('Выгрузка отчёта'),
	import: tDynamic('Загрузка внешнего отчёта'),
	done: tDynamic('Завершение проверки'),
});

export const orderIndicatorStepTypes = makeDynamicTranslations<Record<Orders.OrderIndicatorType, string>>({
	begin: tDynamic('Подсчёт остатков'),
	export: tDynamic('Передача наших результатов в РЦ'),
	import: tDynamic('Получение результатов от РЦ'),
	done: tDynamic('Завершение проверки'),
});

export const orderIndicatorStepTooltip = makeDynamicTranslations<Record<Orders.OrderIndicatorType, string>>({
	begin: tDynamic('Закончите подсчет всех позиций'),
	export: tDynamic('Отправьте результаты нашего подсчёта в РЦ, нажав кнопку «Экспорт наших результатов»'),
	import: tDynamic('Получите результаты подсчета от РЦ, нажав кнопку «Импорт результатов РЦ»'),
	done: tDynamic(
		'Если есть товары с расхождениями, то создайте контрольную проверку. Если расхождений нет, то завершите.'
	),
});

export const edaStatusColorDictionary = {
	UNCONFIRMED: '',
	WAITING_ASSIGNMENTS: '',
	CALL_CENTER_CONFIRMED: 'geekblue',
	PLACE_CONFIRMED: 'blue',
	READY_FOR_DELIVERY: 'purple',
	COURIER_ASSIGNED: 'magenta',
	DELIVERED: 'green',
	CANCELLED: 'volcano',
	ARRIVED_TO_CUSTOMER: 'lime',
	CUSTOMER_NO_SHOW: 'red',
	AWAITING_CARD_PAYMENT: 'orange',
	ORDER_TAKEN: 'cyan',
	PICKUP: 'cyan',
};

export const edaStatusStringColorDictionary = {
	UNCONFIRMED: grey[8],
	CALL_CENTER_CONFIRMED: geekblue[6],
	PLACE_CONFIRMED: blue[6],
	READY_FOR_DELIVERY: purple[6],
	COURIER_ASSIGNED: magenta[6],
	DELIVERED: green[6],
	CANCELLED: volcano[6],
	ARRIVED_TO_CUSTOMER: lime[6],
	CUSTOMER_NO_SHOW: red[6],
	AWAITING_CARD_PAYMENT: orange[6],
	ORDER_TAKEN: cyan[6],
};

export const dispatchDeliveryType = makeDynamicTranslations({
	courier: tDynamic('Лавка'),
	yandex_taxi: tDynamic('Такси'),
});

// дополнительно еще сверяем список доступных ордеров с пермитом dispatcher_create_order src/components/OrdersPage/OrdersDataPage/PrintAcceptanceGRV.tsx:550
export const orderTypesList = orderTypeValues.filter(
	(type) =>
		![
			'kitchen_provision',
			'inventory_check_more',
			'inventory_check_product_on_shelf',
			'move',
			'refund',
			'part_refund',
			'stowage',
			'stowage_market',
			'order_retail',
		].includes(type) // ордера, которые нельзя создать из интерфейса
);

export const paymentStatuses = makeDynamicTranslations({
	paid: tDynamic('Оплачен'),
	not_paid: tDynamic('Не оплачен'),
});

export const paymentStatusColorDictionary = {
	paid: 'green',
	not_paid: 'orange',
};

export const checkProjectOrdersTableHeaders = {
	status: 'status',
	created: 'created',
	begin_date: 'begin_date',
	end_date: 'end_date',
	cluster: 'cluster',
	address: 'address',
	type: 'type',
	doc_number: 'doc_number',
	products: 'products',
	executers: 'executers',
};

export const lotReasonsDictionary: Record<string, string> = makeDynamicTranslations({
	DAMAGED_WITHOUT_ACCESS_TO_ATTACHMENT: tDynamic('Лот поврежден'),
	DAMAGED_WITH_ACCESS_TO_ATTACHMENT: tDynamic('Лот имеет доступ ко вложению'),
	SEAL_IS_MISSING: tDynamic('Отсутствует пломба'),
});
