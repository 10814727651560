import type { AxiosInstance } from 'axios';

import { checkExp } from '~zustand/userData';

const requests: Record<string, number> = {};

export default function (axios: AxiosInstance) {
	if (!checkExp('exp_error_duplicate_requests')) {
		return;
	}
	axios.interceptors.response.use((response) => {
		const requestName = (response.config.method ?? 'get') + response.config.url + JSON.stringify(response.data);
		const errorObject = {
			type: 'DUPLICATED_REQUEST',
			requestUrl: response?.config?.url,
			method: response.config.method,
		};
		if (requests[requestName]) {
			window.Ya?.Rum.logError({
				message: 'Duplicated request',
				page: window.location.href,
				additional: errorObject,
			});
		}
		requests[requestName] = requests?.[requestName] ?? 0 + 1;

		setTimeout(() => {
			if (!requests[requestName]) {
				return;
			}

			if (requests[requestName] > 1) {
				requests[requestName] -= 1;
			} else {
				delete requests[requestName];
			}
		}, 5000);

		return response;
	});
}
