import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Bonuses } from '~types/bonuses';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	save: (data: Bonuses.BonusCreate | Bonuses.BonusUpdate) =>
		axios.post<{ result: Bonuses.Bonus }>('/api/supply/admin/salaries/bonus/save', data),

	load: makeLoadFn<Bonuses.Bonus, 'bonus_id'>('/api/supply/admin/salaries/bonus/load', 'bonus_id'),

	decline: (data: Required<Pick<Bonuses.Bonus, 'bonus_id' | 'revision'>>) =>
		axios.post<{ result: Bonuses.Bonus }>('/api/supply/admin/salaries/bonus/decline', data),

	approve: (data: Required<Pick<Bonuses.Bonus, 'bonus_id' | 'revision'>>) =>
		axios.post<{ result: Bonuses.Bonus }>('/api/supply/admin/salaries/bonus/approve', data),

	set_finish: (data: { bonus_id: Bonuses.Bonus['bonus_id']; new_active_to: Bonuses.Bonus['active_to'] }) =>
		axios.post<{ result: Bonuses.Bonus }>('/api/supply/admin/salaries/bonus/set_finish', data),

	list: makeListFn<Bonuses.ListRequest, Bonuses.Bonus>('/api/supply/admin/salaries/bonus/list'),
};
