import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	select: {
		inlineSize: '100%',
	},
	selectError: {
		'& .ant-select-selector': {
			borderColor: 'red!important',
		},
	},
	option: {
		textWrap: 'wrap',
	},
	selectedOption: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));
