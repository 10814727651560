import { checkPermit } from '~zustand/userData';

type CheckOnlyView = {
	editMode: boolean;
	loadPermit: string;
	listPermit: string;
};

export const checkOnlyView = ({ editMode, listPermit, loadPermit }: CheckOnlyView) => {
	const displayViewModeWhileInChangeMode = editMode && checkPermit(loadPermit) && !checkPermit(listPermit);
	return displayViewModeWhileInChangeMode || !editMode;
};
