import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { PriceLists } from '~types/priceLists';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<
		{
			title?: string;
			price_list_target?: string | null;
			drafts_for_applying?: boolean | null;
		},
		PriceLists.PriceList
	>('/api/admin/draft/price_lists/list'),
	save: (data: Record<string, any>) =>
		axios.post<{ result: PriceLists.PriceList }>('/api/admin/draft/price_lists/save', data),
	load: makeLoadFn<PriceLists.PriceList, 'price_list_id'>('/api/admin/draft/price_lists/load', 'price_list_id'),
};
