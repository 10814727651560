import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<void, 'addNewBlockButton'>()((theme, _, classes) => ({
	block: {
		fontSize: 18,
		borderInlineStart: `2px solid ${theme.border.secondary}`,
		marginBlockEnd: 32,
		paddingInlineStart: 12,
		position: 'relative',
		[`&:hover .${classes.addNewBlockButton}`]: {
			opacity: 1,
		},
	},
	addNewBlockButton: {
		position: 'absolute',
		opacity: 0,
		color: theme.border.secondary,
		insetInlineStart: 0,
		insetBlockEnd: 0,
		transform: 'translate(-60%, 110%)',
		cursor: 'pointer',
		'&:hover': {
			opacity: 1,
		},
	},
	request: {
		marginBlockEnd: 24,
		display: 'flex',
		gap: 8,
		alignItems: 'center',
	},
	requestInputPrefix: {
		color: theme.border.secondary,
	},
	requestInput: {
		flex: 1,
		border: 'none',
		outline: 'none',
		borderBlockEnd: '1px solid black',
		/* stylelint-disable-next-line */
		outlineBlockEnd: '1px solid black',
	},
	metaInfo: {
		fontSize: 14,
	},
	copyButton: {
		cursor: 'pointer',
	},
	deleteButton: {
		cursor: 'pointer',
	},
	response: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
	},
}));
