import csvConverter from 'csvtojson';

export function convertCSVtoJson(csv: string, delimiter = 'auto'): PromiseLike<any> {
	return csvConverter({ delimiter })
		.fromString(csv)
		.then((csvRow) => {
			return csvRow;
		});
}

export function convertToCSV(objArray: string) {
	const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
	let str = '';

	for (const obj of array) {
		let line = '';
		for (const index in obj) {
			if (line !== '') line += ';';
			const data = obj[index];
			line += typeof data === 'string' ? data.replace(/(\r\n|\n|\r)/gm, '') : data;
		}

		str += line + '\r\n';
	}

	return str;
}

export function downloadFile(file: string, exportedFilename: string, type: string) {
	const blob = new Blob(['\uFEFF' + file], { type }); // добавляем "\uFEFF" чтобы была кодировка utf-8

	const link = document.createElement('a');
	if (link.download !== undefined) {
		const url = URL.createObjectURL(blob);
		link.setAttribute('href', url);
		link.setAttribute('download', exportedFilename);
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}

export function downloadBlob(blob: Blob, fileName: string) {
	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = fileName;
	document.body.append(link);
	link.click();
	link.remove();
	setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

export function exportJSONFile(json: Record<string, any>, fileName: string) {
	downloadFile(JSON.stringify(json, null, 2), fileName + '.json', 'text/json;charset=utf-8');
}

export function exportCSVFile(headers: any, items: any, fileTitle: string | undefined) {
	const itemsToExport = headers ? [headers, ...items] : items;

	const jsonObject = JSON.stringify(itemsToExport);

	const csv = convertToCSV(jsonObject);

	const exportedFilename = fileTitle ? fileTitle + '.csv' : 'export.csv';

	downloadFile(csv, exportedFilename, 'text/csv;charset=utf-8;');
}
