import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	chevronsWrapper: {
		display: 'flex',
		gap: 5,
	},
	chevronCircle: {
		borderRadius: '50%',
		padding: 5,
		background: theme.bgColor.secondary,
		fontSize: 10,
		cursor: 'pointer',
		':hover': {
			transition: '0.3s',
			background: theme.bgColor.activeSelect,
		},
	},
}));
