import type { ManipulateType } from 'dayjs';
import dayjs from 'dayjs';

import type { PeriodState, PeriodTime } from '../healthTypes';
import type { ViewData } from '../index';

interface PeriodArguments {
	sliceType: PeriodState['sliceType'];
	tz?: string;
}

export type Timestamps = {
	now: PeriodTime;
	past: PeriodTime;
	company_id?: string;
	cluster_id?: string;
	head_supervisor_id?: string;
	supervisor_id?: string;
	store_id?: string;
};

export const getDatesFromSlice = ({ sliceType, tz }: PeriodArguments): { start: string; end: string } => {
	const now = dayjs().tz(tz);
	const start = sliceType === 'hour' ? now.subtract(1, 'hour') : now.startOf('day');

	return {
		start: start.format(),
		end: now.format(),
	};
};

export const getComparisonHealthRequests = (
	start: string,
	end: string,
	comparisonType: ManipulateType,
	levelState: ViewData
): Timestamps => {
	const startDate = dayjs(start);
	const endDate = dayjs(end);
	const pastStart = startDate.subtract(1, comparisonType).format();
	const pastEnd = endDate.subtract(1, comparisonType).format();

	return {
		now: {
			start_timestamp: start,
			end_timestamp: end,
			[`${levelState.type}_id`]: levelState.id,
		},
		past: {
			start_timestamp: pastStart,
			end_timestamp: pastEnd,
			[`${levelState.type}_id`]: levelState.id,
		},
	};
};
