import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	scheduleContainer: {
		display: 'flex',
		flexDirection: 'column',
		paddingBlockStart: 0,
		paddingBlockEnd: 18,
		paddingInline: 16,
	},
	scheduleRow: {
		display: 'grid',
		gridTemplateColumns: '40px minmax(0, 430px) 12px',
		borderBlockEnd: `1px solid ${theme.border.disabled}`,
		paddingBlock: 18,
		paddingInline: 6,
	},
	scheduleTime: {
		fontSize: 12,
	},
	scheduleEvent: {
		display: 'flex',
		flexDirection: 'column',
	},
	scheduleEventTitle: {
		fontWeight: 500,
		lineHeight: '16px',
	},
	scheduleEventType: {
		display: 'inline-block',
		color: theme.text.icon,
		lineHeight: '16px',
		marginBlockStart: 2,
	},
}));
