import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { checkProjectStatusesNames } from '~server-types/doc/api/models/check_project';
import ModalComponent from '~shared/components/ModalComponent';
import Title from '~shared/components/Title';
import renderStatus from '~shared/utils/renderStatus';
import type { CheckProjects } from '~types/checkProjects';

import CheckProjectsDataPage from './CheckProjectsDataPage';
import { checkProjectStatusColors } from './pageConfig';
import { useStyles } from './styles';
import TableActions from './TableActions';
import { isApprovable, isProjectComplete } from './utils/helpers';

type Props = {
	projectToShow: CheckProjects.CheckProject | undefined;
	setProjectToShowId: (value: string | undefined) => void;
	toggleModalOpen: (value: string | null) => void;
	setProjectModifiedId: (value: string | null) => void;
	userId?: string;
};

const CheckProjectModal = ({
	projectToShow,
	setProjectToShowId,
	toggleModalOpen,
	setProjectModifiedId,
	userId,
}: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const link = projectToShow ? `/check_projects/${projectToShow?.check_project_id}` : '';

	return (
		<ModalComponent
			modalId={projectToShow?.check_project_id}
			className={classes.modal}
			title={
				projectToShow && (
					<Title
						title={projectToShow.title ?? t('Просмотр проекта ЛИ')}
						status={renderStatus(projectToShow.status, checkProjectStatusesNames, checkProjectStatusColors)}
						additionalButtons={[
							{
								text: t('Открыть страницу проекта'),
								type: 'link',
								to: link,
								dataTest: 'data test open check_project from modal',
							},
						]}
						copyLink={`${window.location.origin}${link}`}
						inModal
					/>
				)
			}
			open={!!projectToShow}
			onCancel={() => setProjectToShowId(undefined)}
			footer={
				<div className={classes.modalFooter}>
					<div>
						{projectToShow?.status && projectToShow?.check_project_id && (
							<TableActions
								status={projectToShow?.status}
								openModal={(type) => toggleModalOpen(type)}
								setProjectModifiedId={setProjectModifiedId}
								id={projectToShow?.check_project_id}
								isFooter={true}
								options={{
									isFormComplete: isProjectComplete(projectToShow),
									isApprovable: isApprovable(userId, projectToShow.vars),
								}}
							/>
						)}
					</div>
					<div>
						<Button
							type="default"
							onClick={() => setProjectToShowId(undefined)}
							data-test="close check_project modal button"
						>
							{t('Закрыть')}
						</Button>
					</div>
				</div>
			}
		>
			{projectToShow && <CheckProjectsDataPage idFromProps={projectToShow.check_project_id} inModal />}
		</ModalComponent>
	);
};

export default CheckProjectModal;
