import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import api from '~/api';
import useLoadData from '~shared/hooks/useLoadData';

import NotFound from '../../../shared/components/ErrorPage/NotFound';

export function RedirectToOrderByExternalId() {
	const params = useParams<{ store_id: string; external_id: string }>();
	const navigate = useNavigate();

	const { data, loaded } = useLoadData(
		() => api.orders.list({ external_id: params.external_id, _fields: ['order_id'] }),
		[params.external_id],
		!params.external_id
	);

	if (!loaded) {
		return <Spin />;
	}

	if (data?.results[0]?.order_id) {
		navigate(`/stores/${params.store_id}/orders/${data?.results[0].order_id}`);
		return null;
	}

	return <NotFound />;
}
