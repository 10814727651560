import { Spin } from 'antd';
import { useEffect } from 'react';

import useProductGroupsTree from '../../../../components/ProductGroupsPage/useProductGroupsTree';
import { useStyles } from '../styles';
import { EmptyValue, getViewText } from '../utils';

interface Props {
	value: any[];
	label?: string;
	activeStatuses?: string[];
	path?: string;
	showAllParents?: boolean;
	loadFromId?: string;
	showFrontCategory?: boolean;
}

const TreeView = ({
	value,
	label,
	activeStatuses,
	path,
	showAllParents = false,
	loadFromId,
	showFrontCategory = true,
}: Props) => {
	const { classes } = useStyles();

	const { loading, productGroupsById, getProductGroupPath, addProductGroupsById, groupsDictionary } =
		useProductGroupsTree();

	useEffect(() => {
		if (loadFromId && !productGroupsById[loadFromId]) {
			addProductGroupsById(loadFromId);
		}
	}, [loadFromId, productGroupsById[loadFromId!]]);

	const checkIsUnactive = (id: string) => {
		const status = productGroupsById[id]?.status;

		if (!activeStatuses || !status) {
			return true;
		}

		return !activeStatuses.includes(status);
	};

	if (loading) {
		return <Spin size="small" />;
	}

	if (!value.length) {
		return (
			<p className={classes.valueOnViewMode} data-test={`data form ${label}`}>
				<EmptyValue />
			</p>
		);
	}

	return (
		<>
			{value.map((id) => {
				// Мы не должны выводить фронт-категории для рекласса https://st.yandex-team.ru/LAVKADEV-12433
				if (!showFrontCategory && productGroupsById[id]?.vars.imported.type === 'front') {
					return null;
				}

				const unactive = checkIsUnactive(id);
				const allParents = getProductGroupPath(id);

				return (
					<div className={classes.valueOnViewMode} data-test={`data form ${label}`} key={id}>
						{showAllParents
							? allParents?.map((parent) => {
									const parentUnactive = checkIsUnactive(parent);

									return (
										<span key={parent}>
											{getViewText({
												value: parent,
												dictionary: groupsDictionary,
												path,
												params: {
													className: parentUnactive ? classes.unactive : undefined,
													dataTest: parentUnactive ? 'unactive' : undefined,
												},
											})}
											{' / '}
										</span>
									);
								})
							: null}
						{getViewText({
							value: id,
							dictionary: groupsDictionary,
							path,
							params: {
								className: unactive ? classes.unactive : undefined,
								dataTest: unactive ? 'unactive' : undefined,
							},
						})}
					</div>
				);
			})}
		</>
	);
};

export default TreeView;
