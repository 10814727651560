import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	critEventLink: {
		marginInlineStart: 5,
	},
	criticalBlock: {
		minInlineSize: 300,
		paddingBlock: '10px',
		paddingInline: '20px',
		marginInlineStart: 10,
		boxShadow: theme.softShadow,
		borderRadius: 8,
		blockSize: 300,
		'@media (max-width: 900px)': {
			blockSize: 'auto',
			maxBlockSize: 300,
		},
	},
	criticalHeaderRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBlockStart: 7,
	},
	criticalTableFilterRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBlockEnd: 5,
	},
	criticalIndicator: {
		paddingBlock: '2px',
		paddingInline: '5px',
		backgroundColor: theme.bgColor.badge,
		color: theme.text.white,
		borderRadius: '40%',
		marginInlineStart: 10,
	},
	critTableBlock: {
		'.ant-table': {
			blockSize: 245,
		},
		'.ant-table-tbody': {
			paddingBlock: '0 !important',
			paddingInline: '0 !important',
		},
		'.ant-table-tbody > tr > td': {
			paddingBlock: '0 !important',
			paddingInline: '0 !important',
		},
		'.ant-table-placeholder': {
			'.ant-table-cell': {
				margin: 0,
				padding: 0,
			},
			div: {
				blockSize: 180,
				paddingBlockStart: 10,
				paddingBlockEnd: 0,
				img: {
					marginBlockEnd: 0,
				},
			},
		},
		'.ant-table-wrapper': {
			'& .ant-table-tbody >tr >td': {
				borderBlockEnd: 0,
			},
			'& .ant-table-filter-column': {
				justifyContent: 'flex-start',
				alignItems: 'center',

				'& .ant-table-column-title': {
					flex: 0,
				},
			},
			'& .ant-table-thead th.ant-table-column-sort': {
				backgroundColor: 'transparent!important',
			},
			'& .ant-table-thead th.ant-table-column-has-sorters:hover': {
				backgroundColor: 'transparent!important',
			},
			'& td.ant-table-column-sort': {
				backgroundColor: 'transparent!important',
			},
		},
		'.ant-table-thead > tr > th.ant-table-cell': {
			backgroundColor: 'transparent',
			paddingInline: '0!important',

			'&::before': {
				display: 'none',
			},

			'.ant-table-column-title': {
				fontSize: 12,
				fontWeight: 400,
				color: theme.text.secondary,
			},
		},
		'.ant-table tbody>tr.ant-table-row:hover > td': {
			backgroundColor: '#fafafa!important',
		},
	},
	critEvent: {
		paddingBlock: '5px',
		paddingInline: 0,
	},
	critEventStore: {
		fontSize: '0.9em',
	},
	critEventType: {
		color: theme.text.statusRed,
		fontSize: '0.9em',
	},
	criticalEventDate: {
		display: 'flex',
		flexDirection: 'column',
		marginInlineEnd: 5,
	},
	critEventTime: {
		color: theme.text.secondary,
	},
}));
