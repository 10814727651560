import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	statusBar: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 12,
	},
	statusGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
	},
	tagsGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		fontSize: 13,
		svg: {
			blockSize: 16,
		},
		'&>*': {
			marginInlineEnd: 3,
		},
	},
}));
