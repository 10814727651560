import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { AssortmentProductStatuses } from '~server-types/doc/api/models/assortment_product';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { Assortments } from '~types/assortments';

export default {
	list: makeListFn<{ assortment_id?: string; product_id?: string | string[] }, Assortments.Product>(
		'/api/admin/assortment_products/list'
	),
	save: (data: Record<string, any>) => axios.post<{ result: any }>('/api/admin/assortment_products/save', data),
	load: (data: { assortment_id?: string; product_id?: string }, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: any }>('/api/admin/assortment_products/load', data, config),
	import_data: (data: { assortment_id: string; csv: string; status: AssortmentProductStatuses }) =>
		axios.post('/api/admin/assortment_products/import_data', data),
};
