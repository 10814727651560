import { Empty } from 'antd';
import type { Dispatch, SetStateAction } from 'react';

import { columnToStatusMap } from '~constants/columnToStatusMap';
import Timer from '~shared/components/Timer';
import { usePagination } from '~shared/hooks/usePagination';
import splitDocNumber from '~shared/utils/splitDocNumber';
import type { KanbanColumnsData } from '~shared/utils/tableauOrdersMetrics';
import type { Orders } from '~types/orders';

import { columnLabelMap } from '../../../../../MonitorTableau/constants';
import ColumnContent from '../../ColumnContent/ColumnContent';
import { useStyles as healthTableauStyles } from '../../styles';
import { useStyles } from './styles';

interface Props {
	columnsData: KanbanColumnsData;
	setOrderIdToShow: Dispatch<SetStateAction<string | undefined>>;
}

export const OrdersCards = ({ columnsData, setOrderIdToShow }: Props) => {
	const { classes } = useStyles();
	const { classes: healthTableauClasses } = healthTableauStyles();

	const {
		paginationData: column,
		incrementPaginationValue,
		decrementPaginationValue,
	} = usePagination<string>(Object.keys(columnsData));

	return (
		<ColumnContent
			dataTest="order list"
			pagination={{
				onPrevClick: decrementPaginationValue,
				onNextClick: incrementPaginationValue,
			}}
			text={
				<div className={healthTableauClasses.sumBlock}>
					<span>{columnLabelMap[column]}</span>
					<span data-test="processing" className={healthTableauClasses.greyText}>
						{columnsData[column].length}
					</span>
				</div>
			}
		>
			<div className={classes.orderItems}>
				{columnsData[column].length ? (
					(columnsData[column] as Orders.Order[]).map((order) => {
						const docNumberParts = splitDocNumber(order.doc_number);
						const latestStatusTime = columnToStatusMap[column] && order.status_time[columnToStatusMap[column]];

						return (
							<div
								data-test={`order ${order.order_id}`}
								onClick={() => setOrderIdToShow(order.order_id)}
								key={order.order_id}
								className={classes.orderItemInfo}
							>
								<div>
									<span>
										{docNumberParts[0]}
										<span className={healthTableauClasses.primaryText}>{docNumberParts[1]}</span>
									</span>
									{order.approved && <Timer time={order.approved} countUp={true} />}
								</div>
								<div>
									{columnLabelMap[column]}
									{latestStatusTime && <Timer time={latestStatusTime} countUp={true} />}
								</div>
							</div>
						);
					})
				) : (
					<Empty />
				)}
			</div>
		</ColumnContent>
	);
};
