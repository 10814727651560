import type { ProTableProps } from '~shared/ui-kit/ProTable';
import { ProTable } from '~shared/ui-kit/ProTable';
import type { Orders } from '~types/orders';

import { dataTest } from '../pageConfig';

type Props = {
	orders: Orders.Order[];
	tableConfig: ProTableProps<Orders.Order>;
	setSelection: (value: string[]) => void;
};

const CollectsTable = ({ orders, tableConfig, setSelection }: Props) => {
	const rowSelection = {
		type: 'checkbox' as const,
		onChange: (_: any, selectedRows: Orders.Order[]) => {
			setSelection(selectedRows.map((row) => row.order_id));
		},
	};

	return (
		<ProTable dataSource={orders} {...tableConfig} rowSelection={rowSelection} data-test={dataTest.table.orders} />
	);
};

export default CollectsTable;
