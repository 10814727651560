import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import api from '~/api';
import type { Schets } from '~types/schets';

type State = {
	schetsData: { schets: Schets.Schet[] | undefined };
	getSchetsData: (options: Schets.ListSchetsType) => Promise<void>;
};

const initialState = {
	schets: undefined,
};

export const useSchetsStore = create<State>()(
	devtools(
		(set) => ({
			schetsData: initialState,
			getSchetsData: async (options) => {
				const { data } = await api.schets.list(options);
				set({ schetsData: { schets: data.results } }, false, 'getSchetsData');
			},
		}),
		{ name: 'schetsData' }
	)
);

// Это в компонентах
export const useSchetsData = () => useSchetsStore((state) => state.schetsData.schets);

// Это в функциях
export const getSchetsData = async (options: Schets.ListSchetsType) =>
	await useSchetsStore.getState().getSchetsData(options);
