import type { BackendModule, ReadCallback } from 'i18next';

import type { Language } from './types';

export const DynamicImportBackend: BackendModule = {
	type: 'backend',
	init: () => {},
	create: () => {},
	read(language: Language, _, callback: ReadCallback) {
		import(`./translations/${language.replace('-', '_')}.json`)
			.then((resources) => {
				callback(null, resources);
			})
			.catch((error) => {
				callback(error, false);
			});
	},
};
