import { simpleHash } from '~shared/utils/simpleHash';

import { avatarColors } from './constants';

export const avatarBackgroundColor = (
	id: string | null,
	isRover?: boolean | null,
	isTaxi?: boolean,
	isExecuter?: boolean
) => {
	if (isRover) {
		return '#fc3f1d';
	}
	if (isTaxi) {
		return '#fce000';
	}
	if (isExecuter || !id) {
		return 'rgba(255, 255, 255, 0.1)';
	}
	return avatarColors[simpleHash(id) % avatarColors.length];
};
