import { storeStatusNames, storeStatusValues } from '~server-types/doc/api/models/store';
import { zoneStatusColorDictionary } from '~shared/utils/mapFunctions';
import { useUserConstants } from '~zustand/userData';

import { useStyles } from './styles';

const MapLegend = () => {
	const { classes } = useStyles();

	const constants = useUserConstants();
	const statuses = constants.store?.statuses ?? [];
	const userStoreStatuses = statuses && storeStatusValues.filter((status) => statuses.includes(status));

	return (
		<ul className={classes.legend}>
			{userStoreStatuses.map((status, index) => (
				<li key={status}>
					<div
						className={classes.colorBox}
						style={{
							backgroundColor: zoneStatusColorDictionary[status],
						}}
					/>
					{`${storeStatusNames[status]}${index === userStoreStatuses.length - 1 ? '.' : ';'}`}
				</li>
			))}
		</ul>
	);
};

export default MapLegend;
