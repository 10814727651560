import { useEffect, useState } from 'react';

export function usePagination<T>(data: T[]) {
	const [paginationValue, setPaginationValue] = useState(0);

	useEffect(() => {
		if (!data[paginationValue]) {
			setPaginationValue(data.length - 1);
		}
	}, [data]);

	const incrementPaginationValue = () => {
		if (data[paginationValue + 1]) {
			setPaginationValue((prev) => prev + 1);
		}
	};

	const decrementPaginationValue = () => {
		if (paginationValue > 0 && data[paginationValue - 1]) {
			setPaginationValue((prev) => prev - 1);
		}
	};

	return {
		paginationData: data[paginationValue],
		incrementPaginationValue,
		decrementPaginationValue,
	};
}
