import { v4 as uuid } from 'uuid';
import { create } from 'zustand';

import type { HealthMetric } from '../healthTypes';

type MetricWithId = {
	metric: HealthMetric;
	id: string;
};

interface MetricsState {
	metrics: MetricWithId[];
	setMetric: (metric: HealthMetric, id: string) => void;
	getMetric: (id: string) => MetricWithId;
	addMetric: (metric: HealthMetric) => void;
	removeMetric: (idToRemove: string) => void;
}

export const useMetricsStore = create<MetricsState>()((set, get) => ({
	metrics: [
		{ metric: 'full_cte', id: uuid() },
		{ metric: 'orders_count', id: uuid() },
	],
	setMetric: (metric, id) =>
		set((state) => {
			const newMetrics = state.metrics.map((val) => (val.id === id ? { ...val, metric } : val));
			return {
				metrics: newMetrics,
			};
		}),
	getMetric: (id) => get().metrics.find((val) => val.id === id) || get().metrics[0],
	addMetric: (metric) => {
		set((state) => ({
			metrics: [...state.metrics, { metric, id: uuid() }],
		}));
	},
	removeMetric: (idToRemove) => {
		set((state) => ({
			metrics: state.metrics.filter((val) => val.id !== idToRemove),
		}));
	},
}));
