import { Modal } from 'antd';
import type { ModalProps } from 'antd/es/modal';
import type { CSSProperties } from 'react';
import { memo, useEffect } from 'react';

import { ReactComponent as ArrowLeftRegular } from '~images/icons/arrow-left-regular.svg';
import { ReactComponent as TimesRegular } from '~images/icons/times-regular.svg';
import Icon from '~shared/utils/icons';
import { addModal, removeModal, useOpenedModals } from '~zustand/currentPage';
import { useUserTheme } from '~zustand/userSettings';

import { useStyles } from './styles';

interface Props {
	modalId?: string;
	children: any;
	wrapClassName?: string;
}

const maskStyle: CSSProperties = {
	backgroundColor: 'RGBA(0, 0, 0, 0.9)',
};

const ModalComponent = ({ modalId, wrapClassName, title, ...props }: ModalProps & Props) => {
	const theme = useUserTheme();
	const isLight = theme === 'light';
	const opendModals = useOpenedModals();

	const isNested = !!modalId && opendModals.indexOf(modalId) > 0;
	const { cx, classes } = useStyles({ isNested });

	useEffect(() => {
		if (modalId) {
			const isInArray = opendModals.includes(modalId);

			if (props.open && !isInArray) {
				addModal(modalId);
			} else if (!props.open && isInArray) {
				removeModal(modalId);
			}
		}
		return () => {
			modalId && removeModal(modalId);
		};
	}, [props.open]);

	return (
		<Modal
			{...props}
			title={title ?? <></>}
			wrapClassName={cx(classes.modal, wrapClassName)}
			styles={isLight ? undefined : { mask: maskStyle }}
			closeIcon={
				<div className="ant-btn-default" data-test={`modal ${isNested ? 'back' : 'close'} button`}>
					<Icon component={isNested ? ArrowLeftRegular : TimesRegular} />
				</div>
			}
		>
			{props.children}
		</Modal>
	);
};

export default memo(ModalComponent);
