import { Button } from 'antd';
import dayjs from 'dayjs';
import type { Dispatch, SetStateAction } from 'react';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IdReplacer from '~shared/components/IdReplacer';
import useSetTableScroll from '~shared/hooks/useSetTableScroll';
import type { ProColumns } from '~shared/ui-kit/ProTable';
import { ProTable } from '~shared/ui-kit/ProTable';
import columnSearch from '~shared/utils/columnSearch/';

import { criticalEvents } from '../../constants';
import { HealthContext } from '../../context';
import type { Trouble } from '../../healthTypes';
import { useStyles as useHealthStyles } from '../../styles';
import { getCritViewData } from '../../utils/getCritViewData';
import LittleText from '../LittleText';
import CritOrderLink from './CritOrderLink';
import type { ViewTrouble } from './getTroubleView';
import { useStyles } from './styles';

type Props = {
	data?: Trouble[];
	loading: boolean;
	setModalOpen: Dispatch<SetStateAction<boolean>>;
};

const CriticalBlock = ({ data, loading, setModalOpen }: Props) => {
	const { classes } = useStyles();
	const { classes: healthClasses } = useHealthStyles();
	const [t] = useTranslation();

	const { setOrderIdToShow, userStoreId } = useContext(HealthContext);

	const tableScroll = useSetTableScroll({ selector: '#critical-events-table .ant-table-body', minHeight: 200 });

	const [searchData, setSearchData] = useState<{ event?: string[] }>({
		event: undefined,
	});

	const searchProps = columnSearch(
		searchData,
		setSearchData,
		'checkbox',
		Object.entries(criticalEvents).map(([key, value]) => ({
			label: value,
			value: key,
		}))
	)('event');

	const viewData = useMemo(() => getCritViewData({ data, searchData }), [data, searchData]);

	const columns: ProColumns<ViewTrouble>[] = [
		{
			title: t('Событие'),
			key: 'event',
			render: (_, data) => (
				<div className={classes.critEvent}>
					<div className={classes.critEventStore}>
						<IdReplacer
							id={data.storeId}
							type="stores"
							keyReplaceOn="title"
							cutSymbols={40}
							dataTest={`address ${data.storeId}`}
						/>
					</div>
					<div>
						<span className={classes.critEventType} data-test={`event type ${data.orderId}`}>
							{data.viewTroubleType}
						</span>
					</div>
				</div>
			),
			...searchProps,
		},
		{
			title: t('Время'),
			key: 'time',
			dataIndex: 'time',
			width: 100,
			align: 'right' as const,
			render: (time, trouble) => (
				<div className={classes.criticalEventDate}>
					<span className={classes.critEventTime} data-test={`time ${trouble.orderId}`}>
						{time}
					</span>
					<LittleText dataTest={`date ${trouble.orderId}`}>{trouble.date}</LittleText>
				</div>
			),
			sorter: (a, b) => (dayjs(`${a.date} ${a.time}`).isAfter(dayjs(`${b.date} ${b.time}`)) ? 1 : -1),
		},
		{
			key: 'action',
			dataIndex: 'action',
			align: 'left' as const,
			width: 45,
			render: (action, trouble) => (
				<CritOrderLink
					action={action}
					trouble={trouble}
					setOrderIdToShow={setOrderIdToShow}
					userStoreId={userStoreId}
				/>
			),
		},
	];

	return (
		<div data-test="health critical block" className={classes.criticalBlock}>
			<div className={classes.criticalHeaderRow}>
				<div>
					<span className={healthClasses.sectorHeader}>{t('Критичные события')}</span>
					{!!viewData?.length && <span className={classes.criticalIndicator}>{viewData.length}</span>}
				</div>
				<div>
					<Button type="link" onClick={() => setModalOpen(true)}>
						{t('Все события')}
					</Button>
				</div>
			</div>
			<div className={classes.critTableBlock} id="critical-events-table">
				<ProTable
					data-test="health critical table"
					tableLayout="auto"
					columns={columns}
					dataSource={viewData}
					scroll={tableScroll.scrollObj}
					loading={loading}
					rowKey="index"
				/>
			</div>
		</div>
	);
};

export default CriticalBlock;
