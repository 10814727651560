// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type EquipmentDisabledReasons =
	| 'no_electricity'
	| 'engineering_works'
	| 'breaking'
	| 'temperature_increase'
	| 'door_jammed'
	| 'no_water';
export const equipmentDisabledReasonsValues = [
	'no_electricity',
	'engineering_works',
	'breaking',
	'temperature_increase',
	'door_jammed',
	'no_water',
] as EquipmentDisabledReasons[];
export const equipmentDisabledReasonsNames = makeServerTranslations(
	equipmentDisabledReasonsValues,
	'enums.EQUIPMENT_DISABLED_REASONS'
);
