import type { z } from 'zod';
import { defaultErrorMap, ZodIssueCode } from 'zod';

import i18n from '~/i18n';

export const zodErrorMap: z.ZodErrorMap = (error, _ctx) => {
	let message = '';

	switch (error.code) {
		case ZodIssueCode.invalid_type:
			message = i18n.t('Обязательное поле');
			break;

		case ZodIssueCode.too_small:
			message =
				error.type === 'string' && error.inclusive
					? i18n.t('Должно быть не менее {{ count }} символов', { count: Number(error.minimum) })
					: i18n.t('Значение не может быть меньше чем {{ number }}', { number: error.minimum });

			break;

		case ZodIssueCode.too_big:
			message = i18n.t('Значение должно быть не больше чем {{ number }}', { number: error.maximum });
			break;

		default:
			return defaultErrorMap(error, _ctx);
	}
	return { message };
};
