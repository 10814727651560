import { notification } from '@lavka/ui-kit';

import { changeJoinedStore } from '~zustand/actions/userData';

export type StoreShort = { id: string; title: string } | undefined;

const openInNewTab = () => {
	window.open('/tableau', '_blank');
};

const redirectToTableau = async (storeFrom: StoreShort, storeTo: StoreShort) => {
	if (!storeFrom || !storeTo) return;
	if (storeFrom.id === storeTo.id) {
		openInNewTab();
		return;
	}
	try {
		await changeJoinedStore(storeTo.id, storeFrom.title, storeTo.title);
		openInNewTab();
	} catch (e) {
		notification.error({
			message: e.data.message,
		});
	}
};

export default redirectToTableau;
