import { makeDynamicTranslations, tDynamic } from './makeDynamicTranslations';

export function makeServerTranslations<T extends string>(values: T[], prefix?: string): Record<T, string> {
	if (!prefix) {
		return Object.fromEntries(values.map((e) => [e, e as string])) as Record<T, string>;
	} else {
		return makeDynamicTranslations(
			Object.fromEntries(values.map((e) => [e, tDynamic(prefix + '.' + e)])) as Record<T, string>
		);
	}
}
