import { Col, Row } from 'antd';

import imageSizeReplacer from '~shared/utils/imageSizeReplacer';

import { useStyles } from '../styles';
import KeydataRow from './KeydataRow';

export const test = {
	image_image_url:
		'https://avatars.mds.yandex.net/get-grocery-goods/2783132/fe7c7f53-d438-4029-9f1c-07766b8cf1e6/48x48',
	url_link_to_page: 'https://wms.lavka.tst.yandex.net/products/5999cd6f9f3f4f93aa692ec97e0b0e32000200020000',
	text_title: 'Помидорка',
	bool_is_active: false,
	dt_created: '2023-06-08 19:19:22.918947+03:00',
};

const KeydataTable = ({ keydata, isError }: any) => {
	const { cx, classes } = useStyles();
	const updData = { ...keydata };

	const images = Object.keys(keydata).reduce((list: string[], cur: string) => {
		if (cur.match(/^img_/gi)) {
			list.push(keydata[cur]);
			delete updData[cur];
		}
		return list;
	}, []);

	return Object.keys(keydata).length ? (
		<Row
			justify="space-between"
			className={cx(classes.padding, classes.keydataContainer, { [classes.errorKeydata]: isError })}
		>
			<Col span={18}>
				{Object.keys(updData).map((keyString) => (
					<KeydataRow key={keyString} keyString={keyString} value={keydata[keyString]} />
				))}
			</Col>
			{images.length ? (
				<Col span={6}>
					<img src={imageSizeReplacer(images[0], 's')} />
				</Col>
			) : null}
		</Row>
	) : null;
};

export default KeydataTable;
