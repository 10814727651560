import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ShiftExpansions = lazy(() => import('../components/Shifts/ShiftExpansions'));
const ShiftExpansionsDataPage = lazy(() => import('../components/Shifts/ShiftExpansions/ShiftExpansionsDataPage'));

const shiftExpansionsRoutes = makeRoutes([
	{
		path: '/shifts?tab=expansions',
		permit: 'courier_shift_schedules_seek',
		element: <ShiftExpansions />,
	},
	{
		path: '/shifts/expansions/add',
		permit: 'courier_shift_schedules_create',
		element: <ShiftExpansionsDataPage editMode addMode />,
	},
	{
		path: '/shifts/expansions/edit/:id',
		permit: 'courier_shift_schedules_save',
		element: <ShiftExpansionsDataPage editMode />,
	},
	{
		path: '/shifts/expansions/:id',
		permit: 'courier_shift_schedules_load',
		element: <ShiftExpansionsDataPage />,
	},
]);

export default shiftExpansionsRoutes;
