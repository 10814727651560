import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalComponent from '~shared/components/ModalComponent';

import { setMarkPartnerOrderAsTaken } from './Utils/setMarkPartnerOrderAsTaken';

const PartnerTakenBtn = ({
	order_id,
	orderNumber,
	otpCode,
}: {
	order_id: string;
	orderNumber: string;
	otpCode: string;
}) => {
	const [t] = useTranslation();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [markAsTaken, setMarkAsTaken] = useState<boolean>(false);

	useEffect(() => {
		if (markAsTaken) {
			setMarkPartnerOrderAsTaken({ order_id });
		}
	}, [markAsTaken]);

	return (
		<>
			<ModalComponent
				title={t('Партнёрский заказ {{orderNumber}}', { orderNumber })}
				open={showModal}
				onOk={(e) => {
					e.stopPropagation();
					setMarkAsTaken(true);
				}}
				onCancel={(e) => {
					e.stopPropagation();
					setShowModal(false);
				}}
				okText={t('Выдать')}
				cancelText={t('Закрыть')}
			>
				{t('Не забудьте сообщить OTP-код курьеру: {{otpCode}}', { otpCode: otpCode ?? '—' })}
			</ModalComponent>
			<Button
				onClick={(e) => {
					e.stopPropagation();
					setShowModal(!showModal);
				}}
				data-test="partner taken button"
			>
				{t('Выдача заказа')}
			</Button>
		</>
	);
};

export default PartnerTakenBtn;
