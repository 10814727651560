import debounce from 'lodash/debounce';
import { type DependencyList, useEffect, useMemo, useState } from 'react';

export function useDebounce<T>(value: T, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, [value]);

	return debouncedValue;
}

// Теперь пользуемся этим дебаунсером
export default function useDebouncedCallback<T extends (...args: never[]) => unknown>(
	callback: T,
	wait: number,
	deps: DependencyList
): T {
	const fn = useMemo(() => debounce<T>(callback, wait), deps);

	useEffect(() => {
		return () => fn.cancel();
	}, deps);

	return fn as unknown as T;
}
