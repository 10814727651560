import { useTranslation } from 'react-i18next';

import ModalComponent from '~shared/components/ModalComponent';

import { remove } from './EquipmentDataPage/utils/requests';

type Props = {
	equipmentToRemove: { title?: string; id?: string };
	setEquipmentToRemove: (value: { title?: string; id?: string }) => void;
	setIsRemoving: (value: boolean) => void;
	isRemoving: boolean;
	storeId: string | undefined;
	onAfterRemove: () => void;
};

const DeleteModal = ({ equipmentToRemove, setIsRemoving, isRemoving, setEquipmentToRemove, onAfterRemove }: Props) => {
	const [t] = useTranslation();

	return (
		<ModalComponent
			title={t('Удаление оборудования')}
			open={!!equipmentToRemove.id}
			onOk={async () => {
				await remove({
					equipmentId: equipmentToRemove.id!,
					onAfterRemove: onAfterRemove,
					setIsRemoving: setIsRemoving,
				});
				setEquipmentToRemove({ id: undefined, title: undefined });
			}}
			onCancel={() => setEquipmentToRemove({ id: undefined, title: undefined })}
			okText={t('Да')}
			cancelText={t('Закрыть')}
			okButtonProps={{
				className: 'data-test-delete-equipment-submit',
				danger: true,
				disabled: isRemoving,
			}}
		>
			{t('Вы уверены, что хотите удалить оборудование «{{title}}»?', { title: equipmentToRemove.title })}
		</ModalComponent>
	);
};

export default DeleteModal;
