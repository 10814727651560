import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import { ShelfCard } from '~shared/components/CodeCard/ShelfCard';
import IdReplacer from '~shared/components/IdReplacer';
import type { ProColumns } from '~shared/ui-kit/ProTable';
import { ProTable } from '~shared/ui-kit/ProTable';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import type { Orders } from '~types/orders';

import { useStyles } from './styles';

interface Props {
	order: Orders.Order;
}

type TableRow = {
	index: number;
	product_id: string;
	count?: number;
	result_count?: number;
};

export function CollectDocument({ order }: Props) {
	const { classes } = useStyles();
	const [t] = useTranslation();

	const columns: ProColumns<TableRow>[] = [
		{
			title: '№',
			dataIndex: 'index',
			key: 'index',
			width: 40,
		},
		{
			title: t('Артикул'),
			key: 'external_id',
			width: 40,
			render: (_, record) => (
				<IdReplacer id={record.product_id} type="products" keyReplaceOn="external_id" cutSymbols={40} />
			),
		},
		{
			title: t('Наименование'),
			key: 'title',
			render: (_, record) => (
				<IdReplacer id={record.product_id} type="products" keyReplaceOn="long_title" cutSymbols={40} />
			),
		},
		{
			title: t('План'),
			dataIndex: 'count',
			key: 'count',
		},
		{
			title: t('Факт.'),
			dataIndex: 'result_count',
			key: 'result_count',
		},
	];

	const rows: TableRow[] = order.required.map((e, i) => ({
		index: i + 1,
		product_id: e.product_id,
		count: e.count,
		result_count: e.result_count,
	}));

	const cache = useCache({
		shelves: order.vars?.shelf,
	});

	const shelf = cache.shelves[order.vars?.shelf ?? ''];

	return (
		<div className={classes.document}>
			<div className={classes.header}>
				<div>{shelf && <ShelfCard shelf={shelf} />}</div>
				<div>
					<h3>{order.attr.contractor}</h3>
					<p>
						{t('Заказ-наряд: {{doc_number}}', {
							doc_number: order.doc_number,
						})}
					</p>
					<p>
						{t('Дата создания: {{orderCreate}}', {
							orderCreate: formatTimeAndYear(order.created, {
								onlyDate: false,
								withSeconds: true,
								alwaysShowYear: true,
							}),
						})}
					</p>
				</div>
			</div>
			<ProTable rowKey="number" dataSource={rows} columns={columns} tableLayout="auto" />
			<div className={classes.footer}>
				<p>{t('Подпись: ___________________')}</p>
			</div>
		</div>
	);
}
