import type { User } from '~types/user';

import cutLongText from './cutLongText';

export default (
	user:
		| Partial<
				Pick<User.User, 'user_id' | 'fullname' | 'last_name' | 'first_name' | 'middle_name' | 'display_name' | 'nick'>
		  >
		| string
		| undefined,
	options?: {
		cutSymbols?: number; // Обрезает имя до заданного количества символов
		showDisplayName?: boolean; // Опция вернет display_name пользователя
		showId?: boolean; // Возвращает user_id в скобках после имени пользователя
		showNickIfNoOtherNameSet?: boolean; // Возвращает nick, если никакое имя не задано
		returnIdAsDefaultValue?: boolean; // Возвращает user_id, как дефолтное значение, вместо пустой строки
	}
) => {
	if (!user) return '';

	if (typeof user === 'string') {
		return cutLongText(user, options?.cutSymbols);
	}

	let userName = '';

	if (options?.showDisplayName) {
		userName = user.display_name ?? '';
	} else if (user.fullname) {
		userName = user.fullname;
	} else if (user.last_name && user.first_name) {
		userName = [user.last_name, user.first_name, user.middle_name].filter(Boolean).join(' ');
	} else if (user.display_name) {
		userName = user.display_name;
	} else if (options?.showNickIfNoOtherNameSet) {
		userName = user.nick ?? '';
	}

	if (userName) {
		if (options?.showId && user.user_id) {
			userName = `${userName} (${user.user_id})`;
		}
		return cutLongText(userName, options?.cutSymbols);
	}

	return !!options?.returnIdAsDefaultValue && user.user_id ? cutLongText(user.user_id, options?.cutSymbols) : '';
};
