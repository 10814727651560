/* eslint-disable max-lines */
import type { FunctionComponent, SVGProps } from 'react';

import { ReactComponent as AnalyticsIcon } from '~images/icons/analytics-solid.svg';
import { ReactComponent as AppleAltRegular } from '~images/icons/apple-alt-regular.svg';
import { ReactComponent as AppleCrateRegular } from '~images/icons/apple-crate-regular.svg';
import { ReactComponent as ArchiveRegular } from '~images/icons/archive-regular.svg';
import { ReactComponent as BikingRegular } from '~images/icons/biking-regular.svg';
import { ReactComponent as BuildingRegular } from '~images/icons/building-regular.svg';
import { ReactComponent as BusinessTimeRegular } from '~images/icons/business-time-regular.svg';
import { ReactComponent as EquipmentRegular } from '~images/icons/equipment-icon.svg';
import { ReactComponent as Sampling } from '~images/icons/gift.svg';
import { ReactComponent as HatChefRegular } from '~images/icons/hat-chef-regular.svg';
import { ReactComponent as HeartRegular } from '~images/icons/heart-regular.svg';
import { ReactComponent as HistoryRegular } from '~images/icons/history-regular.svg';
import { ReactComponent as HomeRegular } from '~images/icons/home-regular.svg';
import { ReactComponent as HousePersonLeaveRegular } from '~images/icons/house-person-leave-regular.svg';
import { ReactComponent as InventoryRegular } from '~images/icons/inventory-regular.svg';
import { ReactComponent as LiProjectsIcon } from '~images/icons/li_projects.svg';
import { ReactComponent as MapRegular } from '~images/icons/map-regular.svg';
import { ReactComponent as MapSigns } from '~images/icons/map-signs-regular.svg';
import { ReactComponent as MarkingRegular } from '~images/icons/marking-regular.svg';
import { ReactComponent as MoneyBillRegular } from '~images/icons/money-bill-regular.svg';
import { ReactComponent as MoneyCheckEditRegular } from '~images/icons/money-check-edit-regular.svg';
import { ReactComponent as OvenRegular } from '~images/icons/oven-regular.svg';
import { ReactComponent as PalletAltRegular } from '~images/icons/pallet-alt-regular.svg';
import { ReactComponent as PalletRegular } from '~images/icons/pallet-regular.svg';
import { ReactComponent as PrintRegular } from '~images/icons/print-regular.svg';
import { ReactComponent as CompensationRegular } from '~images/icons/salaries-regular.svg';
import { ReactComponent as ShoppingBasketRegular } from '~images/icons/shopping-basket-regular.svg';
import { ReactComponent as SwitchIconRegular } from '~images/icons/switch-icon.svg';
import { ReactComponent as TagsRegular } from '~images/icons/tags-regular.svg';
import { ReactComponent as TasksRegular } from '~images/icons/tasks-regular.svg';
import { ReactComponent as TruckLoadingRegular } from '~images/icons/truck-loading-regular.svg';
import { ReactComponent as MonitorIcon } from '~images/icons/tv-regular.svg';
import { ReactComponent as UserFriendsRegular } from '~images/icons/user-friends-regular.svg';
import { ReactComponent as UsersRegular } from '~images/icons/users-regular.svg';
import { ReactComponent as WalletIcon } from '~images/icons/wallet.svg';
import { ReactComponent as WarehouseAltRegular } from '~images/icons/warehouse-alt-regular.svg';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { ExpName } from '~types/userConfig';
import { checkExp } from '~zustand/userData';

interface LinkMenuItem {
	text: string;
	path: string;
	name: string;
	permitName: string | string[];
	icon: FunctionComponent<SVGProps<SVGSVGElement>>;
	exp?: ExpName;
	condition?: boolean;
	tagText?: string;
}

export type MenuItems = LinkMenuItem[];

export const menuItemsAdmin: MenuItems = makeDynamicTranslations<MenuItems>([
	{
		text: tDynamic('Компании'),
		path: '/companies',
		name: 'companies',
		permitName: 'companies_seek',
		icon: BuildingRegular,
	},
	{
		text: tDynamic('Словарь оборудования'),
		path: '/equipment_dictionary',
		name: 'equipment_dictionary',
		permitName: 'equipment_types_load',
		icon: EquipmentRegular,
		exp: 'exp_lavka4',
	},
	{
		text: tDynamic('Все сотрудники'),
		path: '/users',
		name: 'users',
		permitName: 'users_seek',
		icon: UsersRegular,
	},
	{
		text: tDynamic('Журнал действий'),
		path: '/users_log',
		name: 'users_log',
		permitName: 'cur_user',
		icon: HistoryRegular,
	},
	{
		text: tDynamic('Здоровье'),
		path: '/health',
		name: 'health',
		permitName: 'health_store',
		icon: HeartRegular,
	},
	{
		text: tDynamic('Корректировки зарплат'),
		path: '/compensations_adjustments',
		name: 'compensations_adjustments',
		permitName: 'adjustments_seek',
		icon: CompensationRegular,
		exp: 'exp_activate_wms_adjustments',
	},
	{
		text: tDynamic('Тарифы'),
		path: '/tariff_plans',
		name: 'tariff_plans',
		permitName: 'tariff_plans_seek',
		icon: MoneyBillRegular,
	},
	{
		text: tDynamic('Конфигурации'),
		path: '/configs',
		name: 'configs',
		permitName: 'configs_page',
		icon: SwitchIconRegular,
	},
	{
		text: tDynamic('Поиск по маркировке'),
		path: '/markings',
		name: 'markings',
		permitName: 'true_marks_load',
		icon: MarkingRegular,
		condition: process.env.REACT_APP_MODE !== 'production',
	},
]);

export const menuItemsCompany = (companyId: string): MenuItems =>
	makeDynamicTranslations([
		{
			text: tDynamic('Склады'),
			path: '/stores',
			name: 'stores',
			permitName: 'stores_seek',
			icon: WarehouseAltRegular,
		},
		{
			text: tDynamic('Проекты контроля'),
			path: '/check_projects',
			name: 'check_projects',
			permitName: 'check_projects_load',
			icon: LiProjectsIcon,
		},
		{
			text: tDynamic('Оборудование кластера'),
			path: '/clusters_equipment',
			name: 'clusters_equipment',
			permitName: 'equipments_cluster_list',
			icon: EquipmentRegular,
			exp: 'exp_lavka4',
		},
		{
			text: tDynamic('Кластеры'),
			path: '/clusters',
			name: 'clusters',
			permitName: 'clusters_seek',
			icon: MapSigns,
		},
		{
			text: tDynamic('Юридические лица'),
			path: '/legal_entities',
			name: 'legal_entities',
			permitName: 'legal_entities_list',
			icon: BuildingRegular,
		},
		{
			text: tDynamic('Все ассортименты'),
			path: '/assortments',
			name: 'assortments',
			permitName: 'assortments_seek',
			icon: PalletAltRegular,
		},
		{
			text: tDynamic('Прайс-листы'),
			path: '/price_lists',
			name: 'price_lists',
			permitName: 'price_lists_seek',
			icon: MoneyBillRegular,
		},
		{
			text: tDynamic('Черновые прайс-листы'),
			path: '/price_lists_drafts',
			name: 'price_lists_drafts',
			permitName: 'draft_price_lists_seek',
			icon: MoneyCheckEditRegular,
		},
		{
			text: tDynamic('Семплинг'),
			path: '/samples',
			name: 'samples',
			permitName: 'samples_seek',
			icon: Sampling,
		},
		{
			text: tDynamic('Группы продуктов'),
			path: '/product_groups',
			name: 'products groups',
			permitName: 'product_groups_seek',
			icon: AppleCrateRegular,
		},
		{
			text: tDynamic('Продукты'),
			path: '/products',
			name: 'products',
			permitName: 'products_seek',
			icon: AppleAltRegular,
		},
		{
			text: tDynamic('Карта'),
			path: '/map',
			name: 'map',
			permitName: ['stores_load', 'zones_seek'],
			icon: MapRegular,
		},
		{
			text: tDynamic('Задания'),
			type: 'link',
			path: `/companies/${companyId}/schets`,
			name: 'schets',
			permitName: 'schets_seek',
			icon: TasksRegular,
		},
		{
			text: tDynamic('Все смены'),
			path: '/shifts',
			name: 'shifts',
			permitName: 'courier_shifts_seek',
			icon: BusinessTimeRegular,
		},
		{
			text: tDynamic('Курьеры'),
			path: '/couriers',
			name: 'couriers',
			permitName: 'couriers_seek',
			icon: BikingRegular,
		},
		{
			text: tDynamic('Курьерские службы'),
			path: '/courier_services',
			name: 'courier_services',
			permitName: 'courier_services_seek',
			icon: HousePersonLeaveRegular,
		},
		{
			text: tDynamic('Теги смен'),
			path: '/shift_tags',
			name: 'shift_tags',
			permitName: 'courier_shift_tags_seek',
			icon: TagsRegular,
		},
	]);

export const menuItemsStore = (storeId: string): MenuItems =>
	makeDynamicTranslations([
		{
			text: tDynamic('Общая информация'),
			path: `/stores/${storeId}`,
			name: 'stores',
			permitName: 'stores_load',
			icon: HomeRegular,
		},
		{
			text: tDynamic('Мой ассортимент'),
			path: `/stores/${storeId}/assortment`,
			name: 'assortments',
			permitName: 'assortments_seek',
			icon: PalletRegular,
		},
		{
			text: tDynamic('Кухня'),
			path: `/stores/${storeId}/kitchen`,
			name: 'kitchen',
			permitName: 'kitchen_menu_view',
			icon: OvenRegular,
			exp: 'exp_chef_interface',
			condition: !checkExp('exp_povar_interface'),
		},
		{
			text: tDynamic('Кухня'),
			path: '/kitchen_new',
			name: 'kitchen',
			permitName: 'kitchen_menu_view',
			icon: OvenRegular,
			exp: 'exp_povar_interface',
		},
		{
			text: tDynamic('Меню кухни'),
			path: `/stores/${storeId}/kitchen_menu`,
			name: 'kitchen_menu',
			permitName: 'kitchen_menu_view',
			icon: HatChefRegular,
		},
		{
			text: tDynamic('Мои сотрудники'),
			path: `/stores/${storeId}/users`,
			name: 'users',
			permitName: 'users_seek',
			icon: UserFriendsRegular,
		},
		{
			text: tDynamic('Области хранения'),
			path: `/stores/${storeId}/shelves`,
			name: 'shelves',
			permitName: 'shelves_load',
			icon: InventoryRegular,
		},
		{
			text: tDynamic('Диспетчерская'),
			path: `/stores/${storeId}/orders`,
			name: 'orders',
			permitName: 'dispatcher',
			icon: TruckLoadingRegular,
			condition: !checkExp('exp_old_disp_off'),
		},
		{
			text: tDynamic('Диспетчерская 2.0'),
			path: `/stores/${storeId}/orders_new`,
			name: 'orders_new',
			permitName: 'dispatcher',
			exp: 'exp_new_dispatch',
			icon: TruckLoadingRegular,
		},
		{
			text: tDynamic('Операции'),
			path: '/all_orders',
			name: 'all_orders',
			permitName: 'dispatcher',
			icon: ShoppingBasketRegular,
		},
		{
			text: tDynamic('Оборудование склада'),
			path: '/stores_equipment',
			name: 'stores_equipment',
			//TODO: ждем нормальные пермиты от бэка
			permitName: 'equipments_load',
			icon: EquipmentRegular,
			exp: 'exp_lavka4',
		},
		{
			text: tDynamic('Печать'),
			path: `/stores/${storeId}/print_tasks`,
			name: 'print_tasks',
			permitName: 'store_print_tasks',
			icon: PrintRegular,
		},
		{
			text: tDynamic('Касса'),
			path: '/wallet',
			name: 'wallet',
			permitName: 'finance_wallet_access',
			exp: 'exp_lebowski',
			icon: WalletIcon,
		},
		{
			text: tDynamic('Остатки'),
			path: `/stores/${storeId}/stocks`,
			name: 'stocks',
			permitName: 'stocks_seek',
			icon: ArchiveRegular,
		},
		{
			text: tDynamic('Аналитика'),
			path: '/analytics',
			name: 'analytics',
			permitName: 'courier_analytics',
			icon: AnalyticsIcon,
		},
		{
			text: tDynamic('Здоровье'),
			path: '/health_store',
			name: 'health',
			permitName: 'health_store',
			icon: HeartRegular,
		},
		{
			text: tDynamic('Табло'),
			path: '/tableau',
			name: 'tableau',
			permitName: 'monitor_orders',
			icon: MonitorIcon,
		},
		{
			text: tDynamic('Задания'),
			path: `/stores/${storeId}/schets`,
			name: 'schets',
			permitName: 'schets_seek',
			icon: TasksRegular,
		},
		{
			text: tDynamic('Слоты'),
			path: `/stores/${storeId}/shifts-director`,
			name: 'shifts-director',
			permitName: 'courier_shifts_seek',
			icon: BusinessTimeRegular,
			exp: 'exp_alfred_pennyworth',
		},
		{
			text: tDynamic('Таблица слотов'),
			path: `/stores/${storeId}/shifts`,
			name: 'shifts',
			permitName: 'courier_shifts_seek',
			icon: BusinessTimeRegular,
			exp: 'exp_master_of_puppets',
		},
		{
			text: tDynamic('Курьеры'),
			path: `/stores/${storeId}/couriers`,
			name: 'couriers',
			permitName: 'couriers_seek',
			icon: BikingRegular,
			exp: 'exp_master_of_puppets_2',
		},
	]);
