import { Col, Row } from 'antd';

import Timer from '~shared/components/Timer';
import type { deliveryStatusesArray, ProcessedCourierCardData } from '~shared/constants/tableauCouriers';
import { deliveryStatuses } from '~shared/constants/tableauCouriers';
import { Link } from '~shared/ui-kit/Link';
import type { Orders } from '~types/orders';

import { UserAvatar } from '../UserAvatar';
import type { DeliveryCardTheme } from './constants';
import DeliveryCardFooter from './DeliveryCardFooter';
import { useStyles } from './styles';
import { isCourierReturningTooLong, sortedCouriersData } from './utils';

export type DeliveryCardTypes = {
	status: (typeof deliveryStatusesArray)[number];
	couriersStats: ProcessedCourierCardData[];
	theme?: DeliveryCardTheme;
	tick?: boolean;
	metricsHeight?: number;
	orders?: Orders.Order[] | null;
};

const DeliveryCard = ({ status, couriersStats, theme = 'monitor', tick, metricsHeight, orders }: DeliveryCardTypes) => {
	const { cx, classes } = useStyles({ themeFlag: theme, metricsHeight });

	const { mainCourierList, restCourierList, totalAmount } = sortedCouriersData(couriersStats);

	return (
		<div
			className={cx(classes.deliveryCard, {
				[classes.mobileTableauDeliveryCard]: theme === 'mobileTableau',
			})}
		>
			<Row
				className={cx(classes.deliveryHeader, {
					[classes.mobileTableauDeliveryHeader]: theme === 'mobileTableau',
				})}
			>
				<Col span={24}>
					<span
						className={cx(classes.deliveryCardTitle, {
							[classes.mobileTableauDeliveryCardTitle]: theme === 'mobileTableau',
						})}
					>
						{deliveryStatuses[status]}
					</span>
					<span
						data-test={status}
						className={cx(classes.couriersAmount, {
							[classes.mobileTableauCouriersAmount]: theme === 'mobileTableau',
							[classes.healthCouriersAmount]: theme === 'health',
						})}
					>
						{totalAmount}
					</span>
				</Col>
			</Row>
			{mainCourierList.map((courier) => {
				const isLateCourier =
					courier.status !== 'collecting' &&
					isCourierReturningTooLong(courier.lastOrderTime, ['free', 'pause'].includes(courier.status || '') ? 15 : 10);

				const courierOrder = orders?.find((order) => order.courier_id === courier.courier_id);

				return (
					<div
						key={courier.name}
						className={cx(classes.deliveryCardRow, {
							[classes.redDelivery]: isLateCourier,
							[classes.healthDeliveryCardRow]: theme === 'health',
							[classes.mobileTableauDeliveryCardRow]: theme === 'mobileTableau',
							[classes.mobileTableauRedDelivery]: theme === 'mobileTableau' && isLateCourier,
						})}
					>
						<div className={cx(classes.iconWrapper, { [classes.mobileTableauIconWrapper]: theme === 'mobileTableau' })}>
							<UserAvatar
								name={courier.name}
								userId={courier.courier_id ?? ''}
								isRover={courier.is_rover}
								deliveryType={courier.delivery_type ?? courierOrder?.dispatch_transport_type}
							/>
						</div>
						<div className={classes.centerDeliveryBlock}>
							<Link
								to={`/couriers/${courier.courier_id}`}
								className={cx(classes.deliveryName, {
									[classes.mobileTableauDeliveryName]: theme === 'mobileTableau',
								})}
								target="_blank"
							>
								{courier.name}
							</Link>
							{courier.docNumbers &&
								courier.docNumbers.map((docNumber) => (
									<span
										key={docNumber}
										className={cx(classes.deliveryDocNumber, {
											[classes.mobileTableauDeliveryDocNumber]: theme === 'mobileTableau',
										})}
									>
										{docNumber}
									</span>
								))}
						</div>
						{courier.deliveryPromise && (
							<div className={classes.deliveryTime}>
								<span className={classes.time}>
									<Timer time={courier.deliveryPromise} countUp={true} sync={tick !== undefined} tick={tick} />
								</span>
							</div>
						)}
						{courier.lastOrderTime && (
							<div className={classes.deliveryTime}>
								<span
									className={cx(classes.time, {
										[classes.redTime]: isLateCourier,
										[classes.mobileTableauRedTime]: theme === 'mobileTableau' && isLateCourier,
									})}
								>
									<Timer time={courier.lastOrderTime} countUp={true} sync={tick !== undefined} tick={tick} />
								</span>
							</div>
						)}
					</div>
				);
			})}
			{restCourierList.length ? <DeliveryCardFooter couriers={restCourierList} /> : null}
		</div>
	);
};

export default DeliveryCard;
