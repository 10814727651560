import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { TabloMetricSlices } from '~server-types/analytics/doc/api/models/tablo_metric';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { Analytics } from '~types/analytics';

import type { AnalyticsGraphRequest, AnalyticsTableRequest } from '../components/AnalyticsPage';
import type {
	HealthLevel,
	HealthMetricsListRequest,
	HealthMetricsListResponse,
	HealthRequest,
	UsersLastSeenRequest,
	UsersLastSeenResponse,
} from '../components/Health/healthTypes';

export default {
	store_metrics: {
		graph: (data: AnalyticsGraphRequest) =>
			axios.post<Analytics.ExecuterGraphResponse>('/api/report/report_data/store_metrics/graph', data),
		list: makeListFn<AnalyticsTableRequest, Analytics.ExecuterStat>('/api/report/report_data/store_metrics/list'),
		graph_writeoffs: (data: AnalyticsGraphRequest) =>
			axios.post<{ graphs: unknown; aggregations: unknown }>('api/report_data/store_metrics/graph_writeoffs', data),
	},
	courier_metrics: {
		graph: (data: AnalyticsGraphRequest) =>
			axios.post<Analytics.CourierGraphResponse>('/api/report/report_data/courier_metrics/graph', data),
		list: makeListFn<AnalyticsTableRequest, Analytics.CourierStat>('/api/report/report_data/courier_metrics/list'),
		exportOrders: (
			data: { store_id: string; period: { begin: string; end: string } },
			config?: AxiosAdditionalConfig
		) => axios.post<EmptyObject>('/api/report/report_data/courier_metrics/export_orders', data, config),
	},
	realtime_metrics: {
		store_metrics: (data: { store_id: string; date?: string }) =>
			axios.post('/api/report/report_data/realtime_metrics/store_metrics', data),
		courier_metrics: (data: { store_id: string; date?: string | null }) =>
			axios.post<Analytics.RealtimeCourierMetricsResponse>(
				'/api/report/report_data/realtime_metrics/courier_metrics',
				data
			),
		shift_metrics: (data: { store_id: string; date?: string | null }) =>
			axios.post<Analytics.RealtimeShiftMetricsResponse>(
				'/api/report/report_data/realtime_metrics/shift_metrics',
				data
			),
	},
	tablo_metrics: {
		list: makeListFn<HealthRequest, any>('/api/report/report_data/tablo_metrics/list'),
		log: (data: { date: string; slice: TabloMetricSlices; entity: HealthLevel; entity_id: string; cursor?: string }) =>
			axios.post('/api/report/report_data/tablo_metrics/log', data),
	},
	health_metrics: {
		list: (data: HealthMetricsListRequest) =>
			axios.post<HealthMetricsListResponse>('/api/report/report_data/health_metrics/list', data),
		analytics: (data: HealthMetricsListRequest) =>
			axios.post<HealthMetricsListResponse>('/api/report_data/health_metrics/analytics', data),
		users_last_seen: (data: UsersLastSeenRequest) =>
			axios.post<UsersLastSeenResponse>('/api/report_data/health_metrics/users_last_seen', data),
	},
};
