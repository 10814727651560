import axios from 'axios';

import type { FileInfo } from '~types/common';

export default {
	get_object_s3: (data: FileInfo) =>
		axios.get<Blob>('/api/files/get_object_s3', {
			params: data,
			responseType: 'blob',
		}),
};
