/*global google*/
import type { Stores } from '~types/stores';

export const polygonCoordsToGeoJson = (polygons: number[][][]) => {
	return {
		type: 'Feature',
		geometry: {
			type: 'MultiPolygon',
			coordinates: polygons,
		},
	};
};

export const polygonsToGooglePolygons = (polygons: Stores.Coordinates[][][]): google.maps.LatLngLiteral[][] => {
	const googlePolygons: google.maps.LatLngLiteral[][] = [];
	polygons.flat().forEach((polygon, index) => {
		polygon.forEach((coords) => {
			googlePolygons[index] ??= [];
			googlePolygons[index].push({ lat: Number(coords[1]), lng: Number(coords[0]) });
		});
		// удаляем последнюю точку у полигона, равную первой (такой формат у google)
		const polygonLength = googlePolygons[index].length;
		if (
			googlePolygons[index][0].lat === googlePolygons[index][polygonLength - 1].lat &&
			googlePolygons[index][0].lng === googlePolygons[index][polygonLength - 1].lng
		) {
			googlePolygons[index].pop();
		}
	});
	return googlePolygons;
};

export const googlePolygonsToPolygons = (googlePolygons: google.maps.Polygon[]): number[][][] => {
	const polygons: number[][][] = [];
	googlePolygons.forEach((polygon) => {
		const polygonLength = polygon.getPath().getLength();
		const coords: any = [];
		for (let i = 0; i < polygonLength; i++) {
			const coordsJSON = polygon.getPath().getAt(i).toJSON();
			coords.push([coordsJSON.lng, coordsJSON.lat]);
		}
		if (coords[0]) {
			coords.push(coords[0]); // добавляем конечную точку, равную первой, если полигон не пустой
		}
		if (coords.length) {
			polygons.push([coords]);
		}
	});
	return polygons;
};

export const markerToPlacemark = (marker: google.maps.Marker): Stores.Location | undefined => {
	const position = marker.getPosition()?.toJSON();
	if (position) {
		return {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: [position.lng, position.lat],
			},
		};
	}
	return undefined;
};

export function getPolygonsBounds(shapes: google.maps.Polygon[]) {
	const bounds = new google.maps.LatLngBounds();
	for (const shape of shapes) {
		const paths = shape.getPaths();
		paths.forEach(function (path) {
			const ar = path.getArray();
			for (let i = 0, l = ar.length; i < l; i++) {
				bounds.extend(ar[i]);
			}
		});
	}
	return bounds;
}

// у api google-карт нет событие входа в fullscreen поэтому определяем это такой функцией
export const fullScreenEventListener = (map: google.maps.Map, callback: (fullscreen: boolean) => void) => {
	if (
		//@ts-expect-error
		map.getDiv().firstChild.clientHeight === window.innerHeight &&
		//@ts-expect-error
		map.getDiv().firstChild.clientWidth === window.innerWidth
	) {
		callback(true);
	} else {
		callback(false);
	}
};

export const deletePolygonNode = (mev: google.maps.PolyMouseEvent, polygon: google.maps.Polygon): void => {
	if (mev.vertex != null) {
		polygon.getPath().removeAt(mev.vertex);
	}
};

export const setTheme = (map: any, isLight: any) => {
	if (!map) {
		return;
	}

	if (isLight) {
		map.setOptions({ styles: [] });
	} else {
		map.setOptions({
			styles: [
				{
					elementType: 'geometry',
					stylers: [
						{
							color: '#1d2c4d',
						},
					],
				},
				{
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#8ec3b9',
						},
					],
				},
				{
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#1a3646',
						},
					],
				},
				{
					featureType: 'administrative.country',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#4b6878',
						},
					],
				},
				{
					featureType: 'administrative.land_parcel',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#64779e',
						},
					],
				},
				{
					featureType: 'administrative.province',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#4b6878',
						},
					],
				},
				{
					featureType: 'landscape.man_made',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#334e87',
						},
					],
				},
				{
					featureType: 'landscape.natural',
					elementType: 'geometry',
					stylers: [
						{
							color: '#023e58',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'geometry',
					stylers: [
						{
							color: '#283d6a',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#6f9ba5',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#1d2c4d',
						},
					],
				},
				{
					featureType: 'poi.park',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#023e58',
						},
					],
				},
				{
					featureType: 'poi.park',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#3C7680',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'geometry',
					stylers: [
						{
							color: '#304a7d',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#98a5be',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#1d2c4d',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry',
					stylers: [
						{
							color: '#2c6675',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#255763',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#b0d5ce',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#023e58',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#98a5be',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#1d2c4d',
						},
					],
				},
				{
					featureType: 'transit.line',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#283d6a',
						},
					],
				},
				{
					featureType: 'transit.station',
					elementType: 'geometry',
					stylers: [
						{
							color: '#3a4762',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry',
					stylers: [
						{
							color: '#0e1626',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#4e6d70',
						},
					],
				},
			],
		});
	}
};
