import { ErrorPage } from '@lavka/ui-kit/lib/esm/components/ErrorPage';
import { useTranslation } from 'react-i18next';

import { appSessionId } from '~/appConfig';

const FrontCrush = () => {
	const [t] = useTranslation();
	return (
		<ErrorPage
			errorCode="599"
			title={t('Сервер не отвечает')}
			subtitle={t('В данный момент что-то не так с сервером')}
			data-testid="error page"
			additionals={[{ name: 'Error id', value: appSessionId }]}
		/>
	);
};

export default FrontCrush;
