import '@yandex-int/rum-counter/dist/bundle/implementation';
import '@yandex-int/rum-counter/dist/bundle/onload';
import '@yandex-int/rum-counter/dist/bundle/scroll';
import '@yandex-int/rum-counter/dist/bundle/spa-metric';

export enum BlockstatDict {
	Init = '3036',
	BeforeInit = '3070',
}

export function sendTimeMark(id: BlockstatDict) {
	window.Ya.Rum.sendTimeMark(id);
}

export function sendHeroElement() {
	window.Ya.Rum.sendHeroElement();
}

export function makeSubPage(pageName: string) {
	window.Ya.Rum.spa.makeSpaSubPage(pageName, undefined, false, true);
	window.Ya.Rum.setVars({
		'-page': pageName,
	});
}

export const updateAdditional = (additional: Ya.Rum.Additional) => {
	try {
		window.Ya.Rum.updateAdditional(additional);

		const prevAdditional = window.Ya.Rum._vars['-additional'];

		window.Ya.Rum.setVars({
			'-additional': JSON.stringify({
				...(prevAdditional ? JSON.parse(prevAdditional) : {}),
				...additional,
			}),
		});
	} catch {}
};
