import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { useCache } from '~cache/useCache';
import { mobileMaxWidth } from '~constants/breakPoints';
import { userRolesNames } from '~server-types/doc/api/models/user';
import EventsCalendar from '~shared/components/EventsCalendar';
import NotificationBtn from '~shared/components/Notifications/NotificationBtn';
import { Link } from '~shared/ui-kit/Link';
import cutLongText from '~shared/utils/cutLongText';
import { useCheckPermit, useUser } from '~zustand/userData';

import Settings from '../Settings';
import { useStyles } from './styles';

export const HeaderRight = () => {
	const [t] = useTranslation();
	const user = useUser();
	const isPermitChat = useCheckPermit('chat');
	const isPermitActiveProjects = useCheckPermit('active_projects');

	const { classes } = useStyles();

	const { stores } = useCache({
		stores: user.store_id,
	});

	const isMobile = useMediaQuery({
		query: `(max-width: ${mobileMaxWidth}px)`,
	});

	return (
		<div className={classes.container}>
			{!isMobile && (
				<div className={classes.userInfo}>
					<div className={classes.userRole} data-test="layout user role">
						{user.role ? cutLongText(userRolesNames[user.role], 30) : ''}
					</div>
					<div data-test="layout user store">
						{user.store_id && stores[user.store_id]?.title ? (
							<Link to={`/stores/${user.store_id}`} className={classes.userStore}>
								{cutLongText(stores[user.store_id]?.title ?? '', 20)}
								{' >'}
							</Link>
						) : (
							<div className={classes.userStore}>{t('Не привязан к складу')}</div>
						)}
					</div>
				</div>
			)}

			{user.store_id && isPermitChat && <NotificationBtn inHeader />}
			{isPermitActiveProjects && <EventsCalendar />}
			<Settings />
		</div>
	);
};
