import { Button } from 'antd';
import type { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import ActionsComponent from '~shared/components/ActionsComponent';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import { useStyles as useCommonStyles } from '~styles/common';
import { checkPermit } from '~zustand/userData';

type ActionOptions = {
	isFormComplete: boolean;
	isApprovable: boolean;
};

interface Props {
	status: string;
	openModal: (type: string) => void;
	setProjectModifiedId: (value: string) => void;
	id: string;
	isFooter?: boolean;
	options: ActionOptions;
}

export const getCheckProjectActions = (
	status: string,
	handleClick: (action: string) => void,
	commonClasses: any,
	options: ActionOptions
) =>
	makeDynamicTranslations([
		{
			type: 'button' as const,
			onClick: () => handleClick('send_to_approve'),
			dataTest: 'check_projects send_to_approve action',
			text: tDynamic('Отправить на подтверждение'),
			component: (
				<Button
					className={commonClasses.dropdownBtn}
					onClick={() => handleClick('send_to_approve')}
					data-test="check_projects send_to_approve action"
					disabled={!options.isFormComplete}
				>
					<Trans i18nKey="Отправить на подтверждение" />
				</Button>
			),
			condition: status === 'draft' && checkPermit('check_projects_save'),
			disabled: !options.isFormComplete,
		},
		{
			type: 'button' as const,
			onClick: () => handleClick('approve'),
			dataTest: `check_projects approve action${!options.isApprovable ? ' disabled' : ''}`,
			text: tDynamic('Подтвердить'),
			component: (
				<Button
					className={commonClasses.dropdownBtn}
					onClick={() => handleClick('approve')}
					data-test={`check_projects approve action${!options.isApprovable ? ' disabled' : ''}`}
					disabled={!options.isApprovable}
				>
					<Trans i18nKey="Подтвердить" />
				</Button>
			),
			condition: status === 'waiting_approve' && checkPermit('check_projects_save'),
			disabled: !options.isApprovable,
		},
		{
			type: 'button' as const,
			onClick: () => handleClick('edit'),
			dataTest: 'check_projects edit action',
			text: tDynamic('Изменить'),
			component: (
				<Button
					className={commonClasses.dropdownBtn}
					data-test="check_projects edit action"
					onClick={() => handleClick('edit')}
				>
					<Trans i18nKey="Изменить" />
				</Button>
			),
			condition: ['draft'].includes(status) && checkPermit('check_projects_save'),
		},
		{
			type: 'button' as const,
			onClick: () => handleClick('make_copy'),
			dataTest: 'check_projects make_copy action',
			text: tDynamic('Создать копию'),
			component: (
				<Button
					className={commonClasses.dropdownBtn}
					onClick={() => handleClick('make_copy')}
					data-test="check_projects make_copy action"
				>
					<Trans i18nKey="Создать копию" />
				</Button>
			),
			condition: checkPermit('check_projects_save'),
		},
		{
			type: 'button' as const,
			onClick: () => handleClick('disable'),
			dataTest: 'check_projects disable action',
			text: tDynamic('Отключить'),
			component: (
				<Button
					className={commonClasses.dropdownBtn}
					onClick={() => handleClick('disable')}
					data-test="check_projects disable action"
				>
					<Trans i18nKey="Отключить" />
				</Button>
			),
			condition: status === 'active' && checkPermit('check_projects_save'),
		},
		{
			type: 'button' as const,
			onClick: () => handleClick('remove'),
			dataTest: 'check_projects remove action',
			text: tDynamic('Удалить'),
			component: (
				<Button
					className={commonClasses.dropdownBtn}
					onClick={() => handleClick('remove')}
					data-test="check_projects remove action"
				>
					<Trans i18nKey="Удалить" />
				</Button>
			),
			condition: status === 'draft' && checkPermit('check_projects_save'),
		},
		{
			type: 'button' as const,
			onClick: () => handleClick('disapprove'),
			dataTest: 'check_projects disapprove action',
			text: tDynamic('Отклонить'),
			component: (
				<Button
					className={commonClasses.dropdownBtn}
					onClick={() => handleClick('disapprove')}
					data-test="check_projects disapprove action"
				>
					<Trans i18nKey="Отклонить" />
				</Button>
			),
			condition: status === 'waiting_approve' && checkPermit('check_projects_save'),
		},
	]);

const TableActions: FunctionComponent<Props> = ({
	status,
	openModal,
	setProjectModifiedId,
	id,
	isFooter = false,
	options,
}: Props) => {
	const { classes: commonClasses } = useCommonStyles();

	const modalOpenOnClick = (modalType: string) => {
		openModal(modalType);
		setProjectModifiedId(id);
	};

	const actions = getCheckProjectActions(status, modalOpenOnClick, commonClasses, options);

	if (isFooter) {
		return actions
			.map((action) =>
				action.condition ? (
					<Button key={action.dataTest} onClick={action.onClick} data-test={action.dataTest} disabled={action.disabled}>
						{action.text}
					</Button>
				) : null
			)
			.filter((item) => item);
	}

	return <ActionsComponent actions={actions} hasCustomComponent={true} />;
};

export default TableActions;
