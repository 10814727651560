import { red } from '@ant-design/colors';

import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	notAppointText: {
		color: red[5],
		fontWeight: 500,
	},
	block: {
		display: 'inline-flex',
		alignItems: 'center',
		'& button': {
			marginInlineStart: 8,
		},
		'& .wms-copy-btn': {
			marginInlineStart: '0 !important',
		},
	},
	courierBlock: {
		inlineSize: 150,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		margin: 0,
		marginInlineStart: '0 !important',
		textAlign: 'start',
		alignItems: 'center',
		'&>span': {
			display: 'inline-block',
			inlineSize: 150,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
}));
