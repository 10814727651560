import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	actionsBlock: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'column',
		minInlineSize: 200,
		zIndex: 9999,
	},
	actionsBlockShort: {
		display: 'flex',
		justifyContent: 'center',
		'&>*': {
			marginInlineEnd: 8,
			marginBlockEnd: '0 !important',
		},
		'&>*:last-child': {
			marginInlineEnd: 0,
		},
		'& p': {
			marginInlineEnd: 16,
			marginBlockEnd: '0 !important',
		},
		'& p:last-child': {
			marginInlineEnd: 0,
		},
	},
	actionIcon: {
		color: theme.text.secondary,
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: 'none',
		border: 'none',
		inlineSize: 32,
		blockSize: 32,
		borderRadius: 4,
		backgroundColor: 'transparent!important',
		transition: '.3s',
		'&:hover': {
			color: `${theme.text.secondary}!important`,
			backgroundColor: `${theme.text.menuItem.hover}!important`,
		},
	},
	iconsContainer: {
		display: 'flex',
	},
	actionButton: {
		padding: 0,
		inlineSize: 32,
		blockSize: 32,
		borderRadius: 4,
		marginInlineEnd: 8,
		background: 'transparent!important',
		border: 'none',
		color: theme.text.logo,
		flexShrink: 0,
		boxShadow: 'none',
		'&:hover': {
			color: `${theme.text.secondary}!important`,
			transitionDuration: '0.4s',
			backgroundColor: `${theme.border.primary}!important`,
		},
		'&:last-child': {
			marginInlineEnd: 0,
		},
	},
	actionButtonDisabled: {
		color: `${theme.text.disabled}!important`,
		border: 'none',
		background: 'transparent!important',
		'&:hover': {
			color: `${theme.text.disabled}!important`,
			background: 'transparent!important',
		},
	},
	actionButtonRed: {
		color: `${theme.text.statusRed} !important`,
		'&:hover': {
			color: `${theme.text.statusRed} !important`,
		},
	},
	actionButtonGreen: {
		color: `${theme.text.statusGreen} !important`,
		'&:hover': {
			color: `${theme.text.statusGreen} !important`,
		},
	},
}));
