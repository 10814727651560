import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type ThemeType = 'dark' | 'light';
type State = {
	theme: ThemeType;
	audio: boolean;
	setTheme: (theme: ThemeType) => void;
	setAudio: (newAudio: boolean) => void;
};

const initialState = {
	theme: 'light' as const,
	audio: false,
};

export const userSettingsStore = create<State>()(
	devtools(
		persist(
			immer((set) => ({
				theme: initialState.theme,
				audio: initialState.audio,
				setTheme: (theme: ThemeType) => set(() => ({ theme }), false, 'setTheme'),
				setAudio: (audio: boolean) => set(() => ({ audio }), false, 'setAudio'),
			})),
			{ name: 'userSettingsStore' }
		)
	)
);

// Это в компонентах
export const useUserTheme = () => userSettingsStore((state) => state.theme);
export const useUserAudio = () => userSettingsStore((state) => state.audio);

// Это в функциях
export const getUserAudio = () => userSettingsStore.getState().audio;
export const setUserTheme = (theme: ThemeType) => userSettingsStore.getState().setTheme(theme);
export const setUserAudio = (audio: boolean) => userSettingsStore.getState().setAudio(audio);
