export const avatarColors = [
	'#7A1829', // dark red
	'#EB2F96', // pink
	'#324CF0', // blue
	'#135200', // dark green
	'#531DAB', // violet
	'#876800', // gold
	'#D48806', // yellow
	'#006D75', // teal
	'#239808', // green
	'#874D00', // brown
	'#644F2B', // oak
	'#780650', // violet red
	'#C81EFA', // magenta
];
