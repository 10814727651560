import { useEffect, useRef, useState } from 'react';
import type { Placemark, Polygon } from 'yandex-maps';

import type { ClusterMapLayer } from '~server-types/doc/api/models/cluster';
import type { Stores } from '~types/stores';
import type { Zones } from '~types/zones';

import type { PartialEntity } from '../../apis/types';
import { useLoadMapData } from './useLoadMapData';
import { addObjectsToMap, generatePolygons, placemarkCreator, storesFilter } from './utils';

export interface ComponentStoreObj {
	storeId: string;
	status?: string;
	polygon: Polygon[] | null;
	placemark: Placemark;
}

export type PartialZone = PartialEntity<Zones.Zone, ['store_id' | 'status' | 'delivery_type' | 'zone']>;
export type PartialStore = PartialEntity<Stores.Store, ['store_id', 'status', 'location', 'options', 'title', 'type']>;
export type StoreMap = PartialStore & { zones: PartialZone[] };

interface Props {
	map?: google.maps.Map | ymaps.Map;
	mapType: ClusterMapLayer;
	options?: {
		statusesToShow?: string[];
		excludeStoreId?: string;
		zonesToShow?: string[];
		optionsToShow?: string[];
		otherOptionsToShow?: string[];
	};
	skip?: boolean;
	zoneStatuses?: Zones.Zone['status'][];
	deliveryType?: Zones.Zone['delivery_type'][];
}

export default function ({ map, mapType, options, skip = false, zoneStatuses, deliveryType }: Props): {
	renderedStores: ComponentStoreObj[];
	objectsOnMap: any[];
	mapBounds?: Stores.Bounds;
	setBounds: (bounds: Stores.Bounds) => void;
	clearRenderedStores: () => void;
	isLoading: boolean;
} {
	const isFirstRun = useRef<boolean>(true);
	const [mapBounds, setBounds] = useState<Stores.Bounds | undefined>();
	const [renderedStores, setRenderedStores] = useState<ComponentStoreObj[]>([]);
	const [objectsOnMap, setObjectsOnMap] = useState<any[]>([]);
	const { stores, isLoading } = useLoadMapData({ mapBounds, zoneStatuses, deliveryType, skip, map });

	// функция рендеренга складов на карте
	const addStoresOnMap = (stores: StoreMap[], updateAll = false) => {
		if (skip || !stores.length) {
			return;
		}

		const storesToShow: ComponentStoreObj[] = []; // склады, которые добавим
		const savedStores: Record<string, ComponentStoreObj> = {}; // уже отрендереные склады
		const objectsToShow: (Polygon | Placemark)[] = []; // объекты, которые добавим
		const objectsToRemove: (Polygon | Placemark)[] = []; // удаляем объекты, которые отображать не надо

		renderedStores.forEach((renderedStore) => {
			if (updateAll) {
				if (renderedStore.placemark) {
					objectsToRemove.push(renderedStore.placemark);
				}
				if (renderedStore.polygon) {
					objectsToRemove.push(...renderedStore.polygon);
				}
			} else {
				if (!stores.find((store) => renderedStore.storeId === store.store_id)) {
					if (renderedStore.placemark) {
						objectsToRemove.push(renderedStore.placemark);
					}
					if (renderedStore.polygon) {
						objectsToRemove.push(...renderedStore.polygon);
					}
				} else {
					savedStores[renderedStore.storeId] = renderedStore;
				}
			}
		});

		stores.forEach((store) => {
			if (store.store_id !== options?.excludeStoreId && storesFilter(options, store) && !savedStores[store.store_id]) {
				const polygon = store.zones // создание полигона для каждого склада
					? generatePolygons(store, options?.zonesToShow, mapType)
					: null;

				const placemark = store.location ? placemarkCreator(store, mapType) : null;

				storesToShow.push({
					storeId: store.store_id,
					status: store.status,
					polygon,
					placemark,
				});
				if (options?.otherOptionsToShow?.includes('showLocation') && placemark) {
					objectsToShow.push(placemark);
				}
				if (polygon) {
					objectsToShow.push(...polygon);
				}
			}
		});

		setObjectsOnMap(objectsToShow);

		addObjectsToMap(map, mapType, {
			remove: objectsToRemove,
			show: objectsToShow,
		});

		setRenderedStores([...Object.values(savedStores), ...storesToShow]);
	};

	useEffect(() => {
		addStoresOnMap(stores);
	}, [stores]);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
		} else {
			addStoresOnMap(stores, true);
		}
	}, [
		options?.statusesToShow?.length,
		options?.zonesToShow?.length,
		options?.optionsToShow?.length,
		options?.excludeStoreId?.length,
		options?.otherOptionsToShow?.length,
	]);

	return {
		renderedStores,
		objectsOnMap,
		mapBounds,
		setBounds,
		clearRenderedStores: () => setRenderedStores([]),
		isLoading,
	};
}
