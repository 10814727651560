import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	printDiv: {
		overflow: 'hidden',
		blockSize: 0,
		inlineSize: 0,
	},
	header: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridColumnGap: '5mm',
		marginBlockEnd: '5mm',
	},
	footer: {
		marginBlockStart: '5mm',
	},
	storeTitle: {
		marginBlockEnd: '5mm',
	},
	printContainer: {
		'& .ant-card': {
			blockSize: 'auto',
		},
		'& .ant-table': {
			border: '1px solid #f0f0f0',
			borderBlockEnd: 'none',
		},
		'& .ant-table-cell': {
			paddingBlock: '3px',
			paddingInline: '16px',
			blockSize: 25,
			fontSize: 10,
		},
	},
	document: {
		paddingBlock: '10mm 15mm',
		paddingInline: '10mm 15mm',
		pageBreakAfter: 'always',
	},
}));
