import { ExportOutlined } from '@ant-design/icons';
import { Button, IconArrowRight, IconCopyOutline, IconLink } from '@lavka/ui-kit';
import { Spin, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { order as orderDictonary } from '~constants/order';
import { orderTypeNames } from '~server-types/doc/api/models/order';
import { Link } from '~shared/ui-kit/Link';
import copyToClipboard from '~shared/utils/copyToClipboard';
import type { Orders } from '~types/orders';
import { checkPermit } from '~zustand/userData';
import { useUserTheme } from '~zustand/userSettings';

import {
	statusStringColorDictionary,
	statusStringColorDictionaryDark,
} from '../../../components/OrdersPage/pageConfig';
import NotificationOrderBtn from '../Notifications/NotificationOrderBtn';
import { useStyles } from './styles';

interface Props {
	order?: Orders.Order;
	store_id?: string;
}

export const OrderModalTitle = ({ order, store_id }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const theme = useUserTheme();
	const isLight = theme === 'light';
	if (!order || !store_id) {
		return <Spin />;
	}
	return (
		<div className={classes.header}>
			<div className={classes.statusBar}>
				<div className={classes.statusGroup}>
					{`${order.type ? orderTypeNames[order.type] || order.type : t('Ордер')} ${
						order.doc_number ? order.doc_number : ''
					} ${order.study ? t('(учебный)') : ''}`}
					<Link to={`/stores/${store_id}/orders/${order.order_id}`}>
						<ExportOutlined />
					</Link>
				</div>
				<div className={classes.tagsGroup}>
					<Tag
						color={isLight ? statusStringColorDictionary[order.status] : statusStringColorDictionaryDark[order.status]}
						data-test="data form Статус ордера"
					>
						{t(orderDictonary[order.status])}
					</Tag>
					<IconArrowRight />
					<Tag data-test="data form Целевой статус">{t(orderDictonary[order.target])}</Tag>
				</div>
			</div>
			<div className={classes.statusBar}>
				{store_id && checkPermit('chat') && (
					<NotificationOrderBtn orderId={order.order_id} externalId={order.external_id} />
				)}
				<Button
					view="ghost"
					size="xxs"
					iconLeft={<IconLink />}
					onClick={(e) => {
						e.stopPropagation();
						copyToClipboard(
							`${window.location.origin}/stores/${store_id}/orders/${order.order_id}`,
							t('Ссылка скопирована')
						);
					}}
				>
					{t('Скопировать ссылку')}
				</Button>

				<Button
					view="ghost"
					size="xxs"
					iconLeft={<IconCopyOutline />}
					onClick={(e) => {
						e.stopPropagation();
						copyToClipboard(order.order_id);
					}}
				>
					{t('Скопировать ID')}
				</Button>
			</div>
		</div>
	);
};
