import { isNumeric } from '~shared/utils/isNumeric';

export const checkIsWmsId = (value: string) => {
	return /^[0-9a-f]{44}$/i.test(value);
};

export const checkIsCourierTaxiUuid = (value: string) => {
	return /^[0-9a-f]{32}$/i.test(value);
};

export const checkIsEdaId = (text: string) => {
	return isNumeric(text);
};

export const checkIsId = (value: string) => {
	return checkIsWmsId(value) || checkIsCourierTaxiUuid(value) || checkIsEdaId(value);
};
