import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	calendarHeader: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	calendarTitle: {
		fontSize: 16,
		fontWeight: 500,
		color: theme.text.primary,
		marginBlock: 0,
	},
	calendarByWeekBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		inlineSize: 156,
		blockSize: 32,
		border: `1px solid ${theme.border.disabled}`,
		borderRadius: 6,
		fontSize: 14,
		fontWeight: 400,
		marginInlineEnd: 40,

		'&.ant-btn:not(.ant-btn-link, .ant-btn-primary)': {
			borderColor: theme.border.disabled,
		},
	},
	calendarTopPanel: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingInline: 16,
		paddingBlock: 6,
	},
}));
