import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { PriceLists } from '~types/priceLists';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<{ title?: string }, PriceLists.PriceList>('/api/admin/price_lists/list'),
	save: (data: Record<string, any>) =>
		axios.post<{ result: PriceLists.PriceList }>('/api/admin/price_lists/save', data),
	load: makeLoadFn<PriceLists.PriceList, 'price_list_id'>('/api/admin/price_lists/load', 'price_list_id'),
	make: (data: { price_list_id: string; drafts: string[]; mode: 'update' | 'replace' }) =>
		axios.post('/api/admin/price_lists/make', data),
};
