import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as DeliveryLogo } from '~images/delivery-logo.svg';
import { ReactComponent as ProductionLogo } from '~images/logo-title-production.svg';
import { ReactComponent as ProductionLogoEn } from '~images/logo-title-production-en.svg';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import { login } from '~shared/utils/loginFunctions';
import { useUser, useUserDomainConfigs } from '~zustand/userData';

import { useStyles } from './styles';

const Main = () => {
	const [t, i18n] = useTranslation();
	const { cx, classes } = useStyles();
	const navigate = useNavigate();
	const user = useUser();
	const domainConfigs = useUserDomainConfigs();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');

	// Поддержка /?token
	useAsyncEffect(async () => {
		if (token) {
			const path = await login({ token });
			navigate(path, { replace: true });
		}
	}, [token]);

	if (user.role === 'tv_device') {
		return <Navigate replace to="/tv-client/tableau" />;
	}
	if (user.role === 'guest') {
		return <Navigate replace to="/login" />;
	}

	let Brand: JSX.Element | null = null;

	if (domainConfigs.branding === 'yandex') {
		if (i18n.language === 'ru-RU') {
			Brand = <ProductionLogo className={classes.logo} />;
		} else {
			Brand = <ProductionLogoEn className={cx(classes.logo, classes.enLogo)} />;
		}
	} else {
		Brand = <p className={classes.wmsLogo}>{t('WMS')}</p>;
	}

	return (
		<div className={classes.container}>
			{Brand}
			<DeliveryLogo className={classes.deliveryLogo} />
		</div>
	);
};

export default Main;
