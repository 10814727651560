import { useCache } from '~cache/useCache';
import { useUser, useUserConstants } from '~zustand/userData';

const useGetLinkedLocations = () => {
	const user = useUser();
	const constants = useUserConstants();
	const { store_id, company_id } = user ?? {};

	const cache = useCache({ stores: store_id, companies: company_id, clusters: constants.store?.cluster_id });
	const userStore = store_id ? cache.stores[store_id] : undefined;

	let timezone: undefined | null | string;

	if (!store_id) {
		// Устанавливаем null если пользователь не привязан к складу
		timezone = null;
	}

	const userCluster = userStore?.cluster_id ? cache.clusters[userStore.cluster_id] : undefined;

	if (userCluster?.tz) {
		timezone = userCluster.tz;
	}

	return {
		user: user,
		userStore,
		userCompany: company_id ? cache.companies[company_id] : undefined,
		timezone,
	};
};

export default useGetLinkedLocations;
