import { Spin } from 'antd';
import { useMemo } from 'react';

import { useCache } from '~cache/useCache';
import type { Couriers } from '~types/couriers';

import { edaOrderMask } from '../pageConfig';
import { useStyles } from '../styles';
import Courier from './Courier';

interface Props {
	title: string;
	couriers: Couriers.CourierForDispatch[];
	batching?: boolean;
}

const Group = ({ title, couriers, batching }: Props) => {
	const { classes } = useStyles();
	const cache = useCache({
		orders: couriers.flatMap((e) => e.orders.map((e) => e.order_id)),
	});

	const filteredCouriers = useMemo(() => {
		if (!batching) {
			return couriers;
		}
		return (
			couriers?.filter(
				(courier) =>
					!courier.orders.find((order) => cache.orders[order?.order_id || '']?.doc_number.match(edaOrderMask))
			) ?? []
		);
	}, [batching, couriers]);

	return (
		<div>
			<h3 className={classes.couriersGroupTitle}>{title}</h3>
			<ul className={classes.couriersGroup}>
				{cache.loading ? (
					<Spin size="small" />
				) : (!batching || couriers) && filteredCouriers.length ? (
					filteredCouriers.map((data) => <Courier key={data.courier.courier_id} data={data} batching={batching} />)
				) : (
					'—'
				)}
			</ul>
		</div>
	);
};
export default Group;
