import { notification } from '@lavka/ui-kit';
import { useCallback } from 'react';

import api from '~/api';
import { PersonalDataButton } from '~shared/components/PersonalDataButton';

interface Props {
	userId: string;
	type: 'email' | 'phone';
	buttonText?: string;
}

export const UserPD = ({ userId, type, buttonText }: Props) => {
	const loadPd = useCallback((): Promise<string> => {
		try {
			const res = api.users
				.loadPd({ user_id: userId, data_type: type }, { disableDefaultNotification: [429] })
				.then((e) => e.data.result ?? '');
			return res;
		} catch (e) {
			notification.error(e.message);
			throw Error(e);
		}
	}, [userId, type]);

	return <PersonalDataButton loadData={loadPd} className={`data-test-pd-${type}-button`} buttonText={buttonText} />;
};
