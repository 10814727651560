import type { JSX } from 'react';
import { useEffect } from 'react';

import { changeCurrentPageTitle } from '~zustand/currentPage';

type Titles = {
	edit: string | JSX.Element;
	add: string | JSX.Element;
	view: string | JSX.Element;
};

type Statuses = {
	addMode?: boolean;
	editMode?: boolean;
};

export const useSetTitle = (title?: string | JSX.Element, titles?: Titles, statuses?: Statuses) => {
	let titleToShow = title;

	if (!titleToShow) {
		const statuts = statuses?.addMode ? 'add' : statuses?.editMode ? 'edit' : 'view';
		titleToShow = (titles && titles[statuts]) || '';
	}

	useEffect(() => {
		changeCurrentPageTitle(`${titleToShow}`);
		return () => {
			changeCurrentPageTitle('');
		};
	}, [title]);
};
