import type { JSX } from 'react';

import emptyStateImage from '~images/empty-state-table.svg';
import emptyStateImageBlack from '~images/empty-state-table-black.svg';

import EmptyState from '../index';

interface Props {
	title?: string;
	text?: string;
	button?: JSX.Element;
	className?: string;
	icon?: string | undefined;
}

const EmptyTableState = ({ title, text, button, className, icon }: Props) => {
	return (
		<EmptyState
			icon={icon ?? emptyStateImage}
			iconDark={emptyStateImageBlack}
			title={title}
			text={text}
			button={button}
			className={className}
		/>
	);
};

export default EmptyTableState;
