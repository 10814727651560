import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	removeItemButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: 'none',
		boxShadow: 'none',
		svg: {
			color: gray[6],
		},
	},
}));
