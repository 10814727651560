import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ collapsed: boolean }>()((theme, { collapsed }) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBlockStart: collapsed ? '4px' : '0px',
		marginBlockEnd: '4px',
		marginInlineEnd: collapsed ? 'unset' : 'auto',
		fontSize: '12px',
		lineHeight: '20px',
		fontWeight: 500,
		color: gray[6],
		cursor: 'pointer',
		'&:hover': {
			color: theme.text.primary,
		},
	},
	text: {
		marginInlineStart: '4px',
		marginInlineEnd: 'auto',
	},
	popoverHeader: {
		fontSize: '14px',
		lineHeight: '16px',
		color: theme.bgColor.main,
	},
	popoverBody: {
		fontSize: '12px',
		lineHeight: '14px',
		color: gray[5],
	},
	popoverLink: {
		fontSize: '12px',
		lineHeight: '14px',
	},
}));
