import type { ProColumns } from '~shared/ui-kit/ProTable';

export default <P extends object = any>(
	columns: ProColumns<P>[],
	...patches: Record<ProColumns<P>['key'] & string, Partial<ProColumns<P>>>[]
): ProColumns<P>[] => {
	return columns.map((column) => {
		const key = column.key as string;
		let patchedColumn = { ...column };

		patches.forEach((patch) => {
			if (patch[key as ProColumns<P>['key'] & string]) {
				patchedColumn = {
					...patchedColumn,
					...patch[key as ProColumns<P>['key'] & string],
				};
			}
		});

		return patchedColumn;
	});
};
