import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
	title: string;
	openedModals: string[];
	changePageTitle: (title: string) => void;
	addModal: (modalId: string) => void;
	removeModal: (modalId: string) => void;
};

const initialState = {
	title: '',
	openedModals: [],
};

export const currentPageStore = create<State>()(
	devtools(
		immer((set) => ({
			...initialState,
			changePageTitle: (title: string) => {
				set((state) => {
					state.title = title;
				});
			},
			addModal: (modalId: string) => {
				set((state) => {
					state.openedModals.push(modalId);
				});
			},
			removeModal: (modalId: string) => {
				set((state) => {
					state.openedModals = state.openedModals.filter((id) => id !== modalId);
				});
			},
		})),
		{ name: 'pageInfoData' }
	)
);

// Это в компонентах
export const useCurrentPageTitle = () => currentPageStore((state) => state.title);
export const useOpenedModals = () => currentPageStore((state) => state.openedModals);

// в функции
export const changeCurrentPageTitle = (title: string) => currentPageStore.getState().changePageTitle(title);
export const addModal = (modalId: string) => currentPageStore.getState().addModal(modalId);
export const removeModal = (modalId: string) => currentPageStore.getState().removeModal(modalId);
