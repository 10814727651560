import { useCallback, useEffect, useRef, useState } from 'react';

import { useYandexMapsScript } from '../hooks/useYandexMapsScript';

const useIsMounted = () => {
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	return isMounted;
};

export const YmapsInitializer = ({ children }: { children: any }) => {
	const [isReady, setIsReady] = useState<boolean>(false);
	const isMounted = useIsMounted();

	const onScriptLoad = useCallback(() => {
		const attachReady = () => {
			if (!isMounted.current) {
				return;
			}

			if (!window.ymaps) {
				setTimeout(attachReady, 500);
			} else {
				void window.ymaps.ready(() => {
					if (isMounted.current) {
						setIsReady(true);
					}
				});
			}
		};

		attachReady();
	}, []);

	const onScriptUnload = useCallback(() => {
		setIsReady(false);
	}, []);

	useYandexMapsScript(onScriptLoad, onScriptUnload);

	return isReady ? children : <></>;
};
