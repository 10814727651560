import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { Stores } from '~types/stores';

export const initialStatusToShowState = ['active'];

export const initialZonesToShowState = ['foot'];

export const initialOptionsToShowState: any[] = [];

export const otherOptions = ['showLocation'];

export const initialOtherOptionsState = ['showLocation'];

export const otherOptionsDictionary = makeDynamicTranslations({
	showLocation: tDynamic('Показывать точки'),
});

export const RETRY_AMOUNT = 6;
export const DEFAULT_MAP_ZOOM = 11;

export type ClusterGeometryFields = Pick<Stores.Cluster, 'cluster_id' | 'title' | 'zone'>;

export const CLUSTER_FIELDS_TO_LOAD: (keyof ClusterGeometryFields)[] = ['cluster_id', 'title', 'zone'];
