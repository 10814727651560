import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ClustersPage = lazy(() => import('../components/Clusters'));
const ClustersDataPage = lazy(() => import('../components/Clusters/ClustersDataPage'));

const clusters = makeRoutes([
	{
		path: '/clusters',
		element: <ClustersPage />,
	},
	{
		path: '/clusters/add',
		permit: 'clusters_create',
		element: <ClustersDataPage editMode addMode />,
	},
	{
		path: '/clusters/edit/:cluster_id',
		permit: 'clusters_save',
		element: <ClustersDataPage editMode />,
	},
	{
		path: '/clusters/:cluster_id',
		permit: 'clusters_load',
		element: <ClustersDataPage />,
	},
]);

export default clusters;
