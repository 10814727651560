import { Col, Row, Tooltip } from 'antd';
import type { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import type { CalendarParams } from '~constants/dataPageFields/types';
import { ReactComponent as QuestionCircleSolid } from '~images/icons/question-circle-solid.svg';
import { withTimezone } from '~shared/utils/datesUtils';
import Icon from '~shared/utils/icons';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';

import DatePicker from '../../DayJsComponents/DatePicker';
import { DatePickerSundayWeek } from '../../DayJsComponents/DatePickerSundayWeek';
import { useStyles } from '../styles';
import { useStyles as useTextStyles } from '../TextField/styles';
import type { FieldProps } from '../types';
import { EmptyValue, getColSpan } from '../utils';

interface Props extends FieldProps {
	addMode?: boolean;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	format?: string;
	params?: CalendarParams;
	pickerParams?: Record<string, any>;
	fieldRequired?: boolean;
	disabledDate?: (currentDate: Dayjs) => boolean;
	isMinutesReadOnly?: boolean;
	onlyView?: boolean;
	hideLabel?: boolean;
	width?: string;
	placeholder?: string;
	className?: string;
	hasViewMode?: boolean;
	startWeekFromSunday?: boolean;
}

const defaultParams = Object.freeze({ onlyDate: true, withSeconds: false, alwaysShowYear: true });
const DateField = ({
	id,
	label,
	input,
	disabled,
	meta: { error },
	colspan,
	editMode,
	format = 'YYYY-MM-DD',
	params = defaultParams,
	fieldRequired,
	disabledDate,
	pickerParams,
	isMinutesReadOnly = false,
	onlyView,
	hideLabel = false,
	width,
	placeholder,
	className,
	hasViewMode = true,
	startWeekFromSunday = false,
}: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { classes: textClasses } = useTextStyles({ error });
	const Date = startWeekFromSunday ? DatePickerSundayWeek : DatePicker;

	const datePickerFormat = (date: any) =>
		date
			?.minute(0)
			.second(0)
			.format(pickerParams?.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY');

	return (
		<Row
			align="top"
			gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]}
			style={editMode ? { minHeight: 42, width } : { width }}
			className={className}
		>
			{!hideLabel && (
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label
						className={cx(classes.label, {
							[classes.labelRequired]: editMode && fieldRequired,
						})}
						htmlFor={id}
					>
						{label}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
			)}
			<Col {...getColSpan(colspan)} className={classes.readOnlyFieldContainer}>
				{(!onlyView && editMode) || !hasViewMode ? (
					<>
						<div className={cx(classes.inputContainer, 'wms-input-container', { error })}>
							<Date
								placeholder={placeholder ?? t('Выбрать дату')}
								value={input.value ? withTimezone(input.value, params.timezone) : undefined}
								format={
									isMinutesReadOnly ? datePickerFormat : pickerParams?.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'
								}
								data-test={`data form ${label}`}
								popupClassName={`data-test-date-dropdown-${label.replace(/\s/g, '-')}`}
								onChange={(date) => {
									return pickerParams?.showTime
										? input.onChange(withTimezone(date, params.timezone, true) || null)
										: input.onChange(withTimezone(date?.second(0), params.timezone, true)?.format(format) || null);
								}}
								disabledDate={disabledDate}
								disabled={disabled}
								className={cx(textClasses.input, {
									[textClasses.inputError]: !!error,
								})}
								{...pickerParams}
							/>
							{params.timezone && (
								<Tooltip title={params.timezoneMessage || t('Указывается во временной зоне объекта')}>
									<Icon component={QuestionCircleSolid} className={classes.tooltipIcon} />
								</Tooltip>
							)}
						</div>
						{error && (
							<span className={cx({ [classes.inputTextError]: error })} data-test={`data form ${label} error`}>
								{error}
							</span>
						)}
					</>
				) : (
					<p className={classes.valueOnViewMode} data-test={`data form ${label}`}>
						{input.value ? (
							formatTimeAndYear(input.value, {
								timezone: params.timezone,
								onlyDate: params.onlyDate,
								withSeconds: params.withSeconds,
								alwaysShowYear: params.alwaysShowYear,
							})
						) : (
							<EmptyValue />
						)}
					</p>
				)}
			</Col>
		</Row>
	);
};

export default DateField;
