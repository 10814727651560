import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	ordersMetricsWrapper: {
		inlineSize: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	metricsItem: {
		inlineSize: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		borderBlockEnd: `2px solid ${theme.border.primary}`,
		':last-child': {
			border: 'none',
		},
	},
	metric: {
		inlineSize: '50%',
		color: theme.text.light,
		lineHeight: 1,
		paddingBlock: 4,
		paddingInline: 6,
		display: 'flex',
		flexDirection: 'column',
	},
	metricName: {
		fontSize: 12,
		lineHeight: '14px',
		margin: 0,
	},
	metricValue: {
		fontSize: 14,
		fontWeight: 500,
		lineHeight: '14px',
		color: theme.text.primary,
	},
	orderItems: {
		inlineSize: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	orderItemInfo: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		inlineSize: '100%',
		paddingBlock: '4px 6px',
		paddingInline: 0,
		borderBlockEnd: `2px solid ${theme.border.primary}`,
		'&:last-child': {
			borderBlockEnd: 'none',
		},
		'&:hover': {
			cursor: 'pointer',
			boxShadow: `0px 1px 0px 0px ${theme.bgColor.statusLightBlue}`,
		},
		div: {
			color: theme.text.light,
			lineHeight: 1,
			inlineSize: '100%',
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
}));
