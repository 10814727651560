import { Col, Input, Row } from 'antd';
import deepdash from 'deepdash';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { JSONTree } from 'react-json-tree';

import { theme } from '../../constants';
import { useStyles } from '../styles';

type Props = {
	data: Record<string, any>;
};

const JsonBlock = ({ data }: Props) => {
	const { cx, classes } = useStyles();
	const [filteredData, setFilteredData] = useState<string>('');
	const [searchValue, setSearchValue] = useState<string>('');
	const search = (key: string | number | undefined) => (key ? key.toString().includes(searchValue) : false);

	useEffect(() => {
		const filtered = deepdash(_).filterDeep(
			data,
			(value, key) =>
				(['string', 'number', 'boolean'].includes(typeof value) && String(value).includes(searchValue)) ||
				String(key).includes(searchValue)
		);
		setFilteredData(filtered);
	}, [data, searchValue]);

	return (
		<>
			<Row className={cx(classes.padding, classes.jsonContainer)}>
				<Col>
					<Input
						style={{ borderRadius: '5px' }}
						placeholder="Поиск по JSON"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
				</Col>
			</Row>
			<Row className={cx(classes.padding, classes.jsonContainer)}>
				<Col className={classes.jsonTree}>
					<JSONTree
						data={filteredData}
						theme={theme}
						labelRenderer={([key]) =>
							searchValue && search(key) ? <span className={classes.foundKey}>{key}</span> : key
						}
						hideRoot={true}
						shouldExpandNodeInitially={() => true}
					/>
				</Col>
			</Row>
		</>
	);
};

export default JsonBlock;
