import { Spin } from 'antd';
import type { OptionProps } from 'antd/lib/select';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '~/i18n';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import { useDebounce } from '~shared/hooks/useDebounce';
import useUserSearch from '~shared/hooks/useUserSearch';
import { checkIsWmsId } from '~shared/utils/ids';
import type { Couriers } from '~types/couriers';
import type { User } from '~types/user';

import type { entityMap } from '../../SimpleFields/PersonalDataSelect/utils';
import { getNames, getSurnames } from '../../SimpleFields/PersonalDataSelect/utils';
import type { SelectFieldProps } from './index';
import SelectField from './index';

interface Props extends SelectFieldProps {
	getUserValueFunc?: (id: string) => string;
	loading?: boolean;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	allowClear?: boolean;
	customLayout?: (label: string, input: JSX.Element, params?: JSX.Element) => JSX.Element;
	hideLabel?: boolean;
	dataTest?: string;
	prepareOptionProps?: (
		option: string,
		initialProps: Omit<OptionProps, 'children' | 'value'>,
		dictionary: Record<string, string> | []
	) => Omit<OptionProps, 'children'>;
	placeholder?: string;
	resetValue?: boolean;
	cache?: Record<string, Couriers.CourierBrief>;
	entityName?: keyof typeof entityMap;
	additionalOptions?: {
		suggestEntities?: boolean;
		onlyActive?: boolean;
	};
	searchSurnames?: ({ searchText }: { searchText: string }) => Promise<OptionProps[] | undefined>;
	searchNames?: ({
		searchText,
		entityName,
		companyId,
		storeId,
		status,
	}: {
		searchText: string;
		entityName: keyof typeof entityMap;
		companyId: string;
		storeId?: string;
		status?: 'active';
		filter?: (item: Couriers.Courier | User.User) => boolean;
	}) => Promise<OptionProps[] | undefined>;
	filter?: (item: Couriers.Courier | User.User) => boolean;
}

const SelectUserWithSearch = ({
	input,
	entityName = 'couriersBrief',
	additionalOptions,
	getUserValueFunc,
	loading,
	colspan,
	allowClear = true,
	linkTo,
	customLayout,
	hideLabel,
	dataTest,
	placeholder = entityName === 'couriersBrief'
		? i18n.t('Введите фамилию или ID курьера')
		: i18n.t('Введите фамилию или ID сотрудника'),
	resetValue = false,
	cache,
	searchSurnames = getSurnames,
	searchNames = getNames,
	filter,
	...props
}: Props) => {
	const [t] = useTranslation();
	const [value, setValue] = useState<string | undefined>();
	const updateValue = (value: string | null) => {
		input.onChange(value);
	};

	const searchValue = useDebounce(value, 500);
	const search = useUserSearch({
		searchValue,
		value: input.value,
		setValue,
		updateValue,
		entityName,
		additionalOptions,
		getUserValueFunc,
		searchSurnames,
		searchNames,
		filter,
	});

	useAsyncEffect(async () => {
		if (input.value) {
			await search.setInitialValue(input.value);
		}
	}, [cache, input.value, resetValue]);

	const getNotFoundContentElement = () => {
		if (search.isLoading || loading) {
			return <Spin size="small" />;
		}

		if (value) {
			return t('Нет данных');
		}

		return null;
	};

	return (
		<SelectField
			notFoundContent={getNotFoundContentElement()}
			showSearch={true}
			hideLabel={hideLabel}
			customLayout={customLayout}
			onSearch={setValue}
			searchValue={value}
			colspan={colspan}
			allowClear={allowClear}
			input={{
				...input,
				onChange: (newValue) => {
					if (checkIsWmsId(newValue)) {
						input.onChange(newValue);
					}
				},
			}}
			linkTo={linkTo}
			dictionary={search.dictionary}
			onSelect={search.onSelect}
			dataTest={dataTest}
			placeholder={placeholder}
			onClear={search.onClear}
			filterOption={search.filterOption}
			{...props}
			optionsGroup={search.optionGroup}
			open={search.open}
			onFocus={search.onFocus}
			onBlur={search.onBlur}
		/>
	);
};

export default SelectUserWithSearch;
