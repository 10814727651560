import { blue } from '@ant-design/colors';

import { tabletMaxWidth } from '~constants/breakPoints';
import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ collapsed?: boolean; menuShadow?: boolean; fullScreenMode?: boolean }>()(
	(theme, { collapsed, menuShadow, fullScreenMode }) => ({
		menuItem: {
			'.ant-menu-item &': {
				color: theme.text.primary,
			},
		},
		burgerButton: {
			inlineSize: 24,
			blockSize: 24,
			insetBlockStart: 16,
			paddingBlock: '10px',
			paddingInline: '5px',
			borderStartEndRadius: 10,
			borderEndEndRadius: 10,
			boxSizing: 'content-box',
			fontSize: 20,
			position: 'absolute',
			insetInlineEnd: -44,
			backgroundColor: theme.bgColor.secondary,
			border: `2px solid ${theme.border.primary}`,
			borderInlineStart: 'none',

			'&.mobileMenu': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: theme.transparentColor,
				borderColor: theme.transparentColor,

				'& svg': {
					fontSize: 24,
				},

				'&.mobileMenuOpened': {
					backgroundColor: theme.bgColor.secondary,
					border: `2px solid ${theme.border.primary}`,
					insetInlineEnd: -38,
				},
			},
		},
		menuButton: {
			display: 'flex',
			blockSize: 40,
			alignItems: 'center',
			borderRadius: 8,
			color: theme.text.primary,
			marginBlock: collapsed ? '4px' : '0 !important',
			marginInline: collapsed ? '4px' : '0 !important',
			inlineSize: '100%',
			justifyContent: collapsed ? 'center' : 'flex-start',
			'& .anticon': {
				marginInlineEnd: 12,
			},
			'&.ant-menu-item-selected': {
				borderInlineEndColor: blue[5],
				backgroundColor: theme.bgColor.menu.itemSelected + '!important',
				color: theme.text.selected,
				'&>a': {
					color: `${theme.text.selected}!important`,
				},
			},
			'&.ant-menu-item-active>a': {
				color: theme.text.menuItem.hover + '!important',
			},
			'& p': {
				margin: 0,
			},
			'& .anticon + span': {
				display: collapsed ? 'none !important' : 'inline !important',
			},
			'& .anticon + .ant-tag': {
				display: 'inline-flex !important',
			},
			'&:after': {
				display: 'none',
			},
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, .04)',
				transitionDuration: '.3s',
				'&>a': {
					color: theme.text.menuItem.hover + '!important',
				},
			},
		},
		siderHeader: {
			display: 'flex',
			alignItems: 'flex-start',
			boxSizing: 'border-box',
			paddingInlineStart: 26,
			position: 'relative',
			paddingBlockStart: 12,
			paddingBlockEnd: 5,
			blockSize: 70,
			boxShadow: menuShadow ? '0px 2px 8px rgba(0, 0, 0, 0.04)' : 'none',
			backgroundColor: process.env.REACT_APP_MODE === 'testing' ? theme.bgColorTesting.main : 'transparent',
			'& p': {
				margin: 0,
				fontWeight: 300,
				fontSize: 26,
				whiteSpace: 'nowrap',
			},
			/* stylelint-disable-next-line */
			[`@media (max-width:${tabletMaxWidth}px)`]: {},
		},
		root: {
			backgroundColor: theme.bgColor.main,
			blockSize: '100vh',
		},
		sider: {
			display: fullScreenMode ? 'none' : 'block',
			backgroundColor: theme.bgColor.main,
			borderInlineEnd: `2px solid ${theme.border.primary}`,
			position: 'relative',
			'& .ant-layout-sider-children': {
				display: 'flex',
				flexDirection: 'column',
				'&>*': {
					display: 'flex',
					flexDirection: 'column',
				},
			},
			/* stylelint-disable-next-line */
			[`@media (max-width:${tabletMaxWidth}px)`]: {
				transitionDuration: '.4s',
				position: 'absolute',
				blockSize: 'calc(100vh - 80px)',
				zIndex: 9999,
			},

			'&.mobileTableauSider': {
				position: 'absolute',
				minBlockSize: '100%',
				zIndex: 1,
			},
		},
		mainMenu: {
			border: 'none',
			paddingInline: '12px',
			paddingBlock: '8px',
			inlineSize: '100%',
			backgroundColor: process.env.REACT_APP_MODE === 'testing' ? theme.bgColorTesting.main : 'transparent',
		},
		siderBody: {
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
		},
		menuContainer: {
			flex: 1,
			flexGrow: 1,
			blockSize: '100%',
			overflowY: 'auto',
			backgroundColor: process.env.REACT_APP_MODE === 'testing' ? theme.bgColorTesting.main : 'transparent',
			'& .ant-menu-item': {
				paddingBlock: collapsed ? 'unset' : '0 !important',
				paddingInline: collapsed ? '' : '12px!important',
			},
		},
		siderFooter: {
			padding: '12px',
			boxShadow: menuShadow ? '0px -2px 8px rgba(0, 0, 0, 0.04)' : 'none',
			position: 'relative',
			backgroundColor: process.env.REACT_APP_MODE === 'testing' ? theme.bgColorTesting.main : 'transparent',
		},
		copyright: {
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '11px',
			lineHeight: '16px',
			color: gray[5],
		},
		updateButton: {
			marginBlockEnd: 16,
		},
		version: {
			fontSize: '12px',
			color: gray[6],
			marginBlockEnd: '2px',
		},
		sessionId: {
			fontSize: '10px',
			color: gray[6],
			marginBlockEnd: '2px',
			cursor: 'pointer',
		},
		menuSeparator: {
			blockSize: 40,
			display: 'flex',
			color: theme.text.primary,
			justifyContent: collapsed ? 'center' : 'space-between',
			alignItems: 'center',
			paddingInlineStart: collapsed ? 0 : 10,
			cursor: 'pointer',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			transition:
				'color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1)',
			'&:hover': {
				color: '#1890ff',
			},
			'.ant-menu-title-content': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			},
		},
		menuCollapser: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: collapsed ? 'center' : 'space-between',
			color: theme.text.disabled,
			fontSize: '12px',
		},
		collapseIcon: {
			fontSize: 16,
			color: `${gray[5]}!important`,

			'&.mobileMenuIcon': {
				fontSize: 24,
			},
		},
		browserAlert: {
			marginBlockEnd: 16,
		},
	})
);
