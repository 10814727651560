import { Button } from 'antd';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteButton from '~shared/components/DeleteButton';

import type { HealthMetric } from '../../healthTypes';
import MetricSelector from './MetricSelector';
import { useStyles } from './styles';

type Props = {
	metric: HealthMetric;
	setMetric: (metric: HealthMetric) => void;
	setModalOpen?: Dispatch<SetStateAction<boolean>>;
	removeMetric?: () => void;
	isLastMetric: boolean;
};

const ChartHeader = ({ metric, setMetric, setModalOpen, removeMetric, isLastMetric }: Props) => {
	const { classes } = useStyles();
	const [t] = useTranslation();

	// если есть функция для открывания модалки, значит это не модалка
	const isModal = !setModalOpen;

	return (
		<div className={classes.chartHeader}>
			<div className={classes.chartHeaderLeft}>
				<MetricSelector metric={metric} setMetric={setMetric} />
				{/* удалять график можно только в модалке */}
				{isModal && (
					<DeleteButton disabled={isLastMetric} onClick={removeMetric} className={classes.chartRemoveButton} />
				)}
			</div>
			{!isModal && (
				<div className={classes.chartHeaderRight}>
					{/*<Checkbox /> */}
					{/*<span className={classes.checkboxLabel}>{t('Критические события')}</span>*/}
					<Button
						type="link"
						className={classes.allDataLink}
						onClick={() => setModalOpen(true)}
						data-test="health chart modal open button"
					>
						{t('Все данные')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default ChartHeader;
