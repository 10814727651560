import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { timetableTypesDays } from '~constants/timetableTypes';
import type { TimetableItemTypes } from '~server-types/doc/api/models/timetable';
import type { CheckProjects } from '~types/checkProjects';

type Props = {
	schedule: CheckProjects.CheckProject['schedule'];
};

type TimetableType = TimetableItemTypes | 'once';

const getCheckedTime = (time: string, daysPlus: number, begin: Dayjs = dayjs()) => {
	const [hours, minutes] = time.split(':').map((str) => Number(str));
	return begin
		.add(daysPlus, 'd')
		.hour(hours ?? 0)
		.minute(minutes ?? 0)
		.second(0);
};
const isCheckedInRange = (checked: Dayjs, begin: Dayjs, end?: Dayjs) =>
	checked.isAfter(dayjs()) && checked.isAfter(begin) && (!end || checked.isBefore(end));

type GetNextPlannedTimeArgs = {
	types: TimetableType[];
	time?: string;
	begin?: string;
	end?: string | null;
	repeat?: number;
};

// Вычисление даты следующей запланированной инвентаризации согласно расписанию
export const getNextPlannedTime = ({ types, time, begin, end, repeat }: GetNextPlannedTimeArgs): Dayjs | null => {
	if (!time || !types.length || !begin) return null;
	const now = dayjs();

	// час проведения инвы
	const hours = Number(dayjs(time, 'HH:mm').hour());

	// дата начала и конце расписания с часом запуска
	const beginDateTime = dayjs(begin).hour(hours);
	const endDateTime = end ? dayjs(end).hour(hours) : undefined;

	// если хотя бы один timetable в массиве - once, инва происходит один раз
	const isOnce = types.find((type) => type === 'once');

	// если хотя бы один timetable в массиве - day_interval,
	// расписание строится по принципу "раз в Х дней"
	const isDayInterval = types.find((type) => type === 'day_interval') && repeat;

	if (isOnce) {
		// инва один раз - проверим, дата проведения инвы до или после текущей даты
		return now.isAfter(beginDateTime) ? null : beginDateTime;
	}

	if (isDayInterval) {
		// если дата начала расписания позже текущей - она и есть следующая дата инвы
		if (now.isBefore(beginDateTime)) {
			return beginDateTime;
		}

		let keepGoing = true;
		let checkedTime = beginDateTime;

		// идем по дням от начала расписания до конца с шагом repeat и проверяем
		while (keepGoing) {
			if (isCheckedInRange(checkedTime, beginDateTime, endDateTime)) {
				return checkedTime;
			}

			// если мы добрались до конца расписания - вырубаем цикл
			if (end && checkedTime.isAfter(dayjs(end))) {
				keepGoing = false;
			}

			// не попали ни в одну ветку - делаем следующий шаг в цикле
			if (repeat) {
				checkedTime = getCheckedTime(time, repeat, checkedTime);
			} else {
				return null;
			}
		}

		return null;
	}

	// если тип не once и не day_interval, значит имеем дело с днями недели

	// сперва надо составить из наборов типов список цифр дней - например:
	// выходные и понедельник - это [0, 1, 6]
	const daysList: number[] = [...new Set(types.map((type) => timetableTypesDays[type]).flat())];
	// так как дней недели всего семь, нам надо просто пробежать по ним + 1 - на случай, если
	// день недели как сегодня, но время инвы уже прошло
	for (let i = 0; i < 8; i++) {
		// к текущему дню прибавим i дней - для вычисления текущего проверяемого дня
		// нужно только время и число прибавленных к сегодня дней
		const checkedTime = getCheckedTime(time, i);

		// число - день недели проверяемого дня
		const weekday = checkedTime.day();
		if (
			// в списке есть текущий проверяемый день
			daysList.includes(weekday) &&
			isCheckedInRange(checkedTime, beginDateTime, endDateTime)
		) {
			return checkedTime;
		}
	}
	return null;
};

export const getNextCheckTimeFromSchedule = (schedule: CheckProjects.CheckProject['schedule']): Dayjs | null => {
	const { timetable, begin, end } = schedule;
	let time;
	if (timetable.length) {
		time = getNextPlannedTime({
			types: timetable.map((t) => t.type),
			time: timetable[0].begin,
			begin,
			end,
			repeat: timetable[0].repeat,
		});
	} else {
		time = getNextPlannedTime({
			types: ['once'],
			time: dayjs(begin).format('HH:mm'),
			begin,
			end,
		});
	}

	return time;
};

const NextPlannedBlock = ({ schedule }: Props) => {
	const format = 'DD.MM.YYYY';
	const next = getNextCheckTimeFromSchedule(schedule);

	return <div>{next ? next.format(format) : '—'}</div>;
};

export default NextPlannedBlock;
