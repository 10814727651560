import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	userInfoBlock: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline',
		gap: 10,
		marginBlockStart: 10,
	},
	levelHeader: {
		fontSize: '1.4em',
		fontWeight: 'bold',
		marginInlineEnd: 10,
	},
}));
