import api from '~/api';

import type { HealthMetricsListRequest } from '../healthTypes';

export const loadHealthData = async (args: { now?: HealthMetricsListRequest; past?: HealthMetricsListRequest }) => {
	const data = await Promise.all([
		args.now && api.reportData.health_metrics.list(args.now),
		args.past && api.reportData.health_metrics.list(args.past),
	]);
	const now = data[0]?.data;
	const past = data[1]?.data;
	return { now, past };
};

export const loadHealthAnalytics = async (args: { now: HealthMetricsListRequest; past?: HealthMetricsListRequest }) => {
	const data = await Promise.all([
		api.reportData.health_metrics.analytics(args.now),
		args.past && api.reportData.health_metrics.analytics(args?.past),
	]);
	const now = data[0].data;
	const past = data[1]?.data;
	return { now, past };
};
