import type { ConfigProviderProps } from 'antd';
import dayjs from 'dayjs';
import type { ComponentType } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';

export function useDirection(ltrComponent: ComponentType, rtlComponent: ComponentType) {
	const [, i18n] = useTranslation();

	return useMemo(() => {
		const direction = i18n.dir();

		return direction === 'ltr' ? ltrComponent : rtlComponent;
	}, [i18n.language]);
}

export function useCountries() {
	const [, i18n] = useTranslation();
	const [isoList, setIsoList] = useState<Record<string, string>>({});
	const lang = i18n.language;

	useAsyncEffect(async () => {
		if (!lang) return;

		const countries = await import(`./countries/${lang.replace('-', '_')}.json`);
		setIsoList(countries.default);
	}, [lang]);

	return isoList;
}

export function useLibTranslations() {
	const [, i18n] = useTranslation();
	const [antdLocale, setAntdLocale] = useState<ConfigProviderProps['locale'] | undefined>();
	const lang = i18n.language;

	useAsyncEffect(async () => {
		if (!lang) return;

		const { translations } = await import(`./libs/${lang.replace('-', '_')}.ts`);

		dayjs.locale(translations.dayjs);
		setAntdLocale(translations.antd);
	}, [lang]);

	return { antdLocale };
}
