export function writeCredentialsInLocalStorage(value: string) {
	try {
		localStorage.setItem('lavkaCredentials', value);
	} catch {
		localStorage.clear();
		localStorage.setItem('lavkaCredentials', value);
	}
}

export function removeCredentialsFromLocalStorage() {
	localStorage.removeItem('lavkaCredentials');
}

export function getCredentialsFromLocalStorage() {
	return localStorage.getItem('lavkaCredentials');
}

export function getToken() {
	const credentials = getCredentialsFromLocalStorage();
	if (credentials) {
		return JSON.parse(credentials)?.token;
	}
	return '';
}
