import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	titleCell: {
		inlineSize: 200,
		textAlign: 'start',
	},
	periodBlock: {
		display: 'flex',
		gap: 10,
		alignItems: 'center',
	},
	pauseLabel: {
		display: 'inline',
		background: '#FFEC3D',
		color: '#874D00',
		paddingBlock: '3px',
		paddingInline: '10px',
		borderRadius: 12,
		marginInlineStart: 15,
	},
	noEndDate: {
		opacity: 0.5,
	},
	saveContainer: {
		maxInlineSize: 240,
		paddingBlock: '8px',
		paddingInline: '12px',
		'& .ant-dropdown-menu-item': {
			whiteSpace: 'normal',
		},
	},
	saveBlockTitle: {
		margin: '0',
		marginBlockEnd: '4px',
	},
	saveBlock: {
		cursor: 'pointer',
	},
	saveBlockText: {
		color: theme.text.secondary,
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '16px',
	},
	modal: {
		insetBlockStart: '5%',
		inlineSize: '90% !important',
		'& .ant-modal-body': {
			blockSize: '80vh',
		},
	},
	modalFooter: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	filterBlock: {
		'&.ant-dropdown-menu': {
			padding: 10,
			minInlineSize: 252,
		},
	},
	filterBlockScrolled: {
		overflowY: 'auto',
		maxBlockSize: 300,
	},
	checkboxItem: {
		'&.ant-checkbox-wrapper': {
			marginBlockEnd: 10,
		},
	},
	checkboxGroup: {
		'&.ant-checkbox-group': {
			display: 'flex',
			flexDirection: 'column',
			gap: 10,
			'&>label': {
				marginInlineStart: 0,
			},
		},
	},
	tabTitleWrapper: {
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			marginInlineStart: 10,
		},
	},
	checkProjectForm: {
		position: 'relative',
		'& tbody .ant-table-row.ant-table-row-level-0 td.ant-table-cell': {
			cursor: 'pointer',
		},
	},
	filterBtn: {
		marginInlineStart: 'auto',
	},
	pickedLocation: {
		display: 'flex',
		alignItems: 'end',
	},
	locationRow: {
		display: 'flex',
		alignItems: 'center',
		marginBlockStart: 5,
	},
	locationTitle: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
	},
	checkIcon: {
		lineHeight: '16px',
	},
	intervalBlock: {
		inlineSize: 200,
		whiteSpace: 'break-spaces',
	},
	pauseFilter: {
		marginBlockEnd: 20,
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
	locationSpinner: {
		marginBlockStart: 20,
		display: 'flex',
		justifyContent: 'center',
	},
	productTypeAccounting: {
		color: theme.text.secondary,
		fontSize: 12,
		marginInlineStart: 10,
		marginBlockStart: -2,
	},
	statusField: {
		minInlineSize: 200,
	},
}));
