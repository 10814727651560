import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	calendarContainer: {
		'.ant-modal-content': {
			position: 'fixed',
			insetBlock: 0,
			insetInlineEnd: 0,
			paddingBlock: 12,
			paddingInline: 0,
		},
		'.ant-modal-header': {
			blockSize: 110,
			paddingBlock: 0,
			paddingInline: 0,
			alignItems: 'flex-start',
		},
		'.ant-modal-close': {
			insetBlockStart: 6,
			insetInlineEnd: 16,
		},
		'.ant-modal-close-x': {
			inlineSize: 32,
			blockSize: 32,
			lineHeight: '32px',
			color: theme.text.secondary,
			'& .ant-btn:not(.ant-btn-link, .ant-btn-primary)': {
				borderColor: theme.border.disabled,
			},
		},
	},
}));
