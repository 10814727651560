import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	timeCell: {
		fontWeight: 'bold',
	},
	eventCell: {
		display: 'flex',
		flexDirection: 'column',
		gap: 0,
	},
	eventCellReason: {
		color: theme.text.light,
		fontSize: '0.9em',
		marginBlockStart: -5,
	},
	filterHeader: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
	},
	graphsModalContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		minBlockSize: 700,
	},
	titleContainer: {
		marginBlockStart: 15,
	},
	userInfoBlock: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline',
	},
	titleText: {
		color: theme.text.light,
		fontSize: '0.9em',
	},
	chartContainer: {
		flexGrow: 1,
		overflow: 'hidden',
		blockSize: 150,
		marginInlineStart: '20px',
		marginBlockEnd: '20px',
	},
}));
