import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ isNested?: boolean }>()((theme, { isNested }) => ({
	modal: {
		'& .ant-modal-header': {
			paddingBlock: '12px',
			paddingInline: isNested ? '60px 15px' : '15px 60px',
			blockSize: 56,
			display: 'flex',
			alignItems: 'center',
		},
		'& .ant-modal-close-x': {
			inlineSize: 30,
			blockSize: 30,
		},
		'& .ant-modal-close': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			inlineSize: 32,
			blockSize: 32,
			insetInlineEnd: isNested ? 'auto' : 12,
			insetInlineStart: isNested ? 15 : 'auto',
			border: `1px solid ${theme.border.secondary}`,
		},
	},
	closeButton: {
		inlineSize: 32,
		blockSize: 32,
		paddingBlock: '1px 0',
		paddingInline: '0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderColor: theme.border.secondary,
	},
}));
