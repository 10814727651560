import { Col, Row, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import type { TreeFieldParams } from '~constants/dataPageFields/types';

import { useSearchableTreeSelect } from '../../../../components/ProductGroupsPage/useSearchableTreeSelect';
import { useStyles } from '../styles';
import { useStyles as useTagStyles } from '../TagField/styles';
import type { FieldProps } from '../types';
import { getColSpan } from '../utils';
import { COLSPAN } from './constants';
import TreeView from './TreeView';

interface TreeSelectFieldProps extends FieldProps {
	data: any;
	params?: TreeFieldParams;
	activeStatuses?: string[];
	path?: string;
	showAllParents?: boolean;
	hideLabel?: boolean;
	colspan?: {
		xl: { span: number };
		lg: { span: number };
		sm: { span: number };
		xs: { span: number };
	};
}

const TreeSelectField = ({
	id,
	label,
	input: { value, onChange },
	meta: { error },
	disabled,
	editMode,
	hideLabel,
	params,
	activeStatuses,
	path,
	showAllParents = false,
	colspan = COLSPAN,
}: TreeSelectFieldProps) => {
	const { classes } = useStyles();
	const { classes: tagClasses } = useTagStyles({ error });
	const [t] = useTranslation();

	const treeSelectParams = useSearchableTreeSelect(
		value,
		{
			fields: {
				id: 'group_id',
				pId: 'parent_group_id',
				label: 'name',
			},
			errorNotificationText: t('Не удалось получить группу продуктов'),
		},
		!editMode
	);

	return (
		<Row align="middle" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]}>
			{!hideLabel && (
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label className={classes.label} htmlFor={id}>
						{label}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
			)}
			<Col {...getColSpan(colspan)} data-test={`data form ${label}`}>
				{editMode ? (
					<TreeSelect
						data-test="data form tree select"
						value={value}
						onChange={onChange}
						virtual={false}
						disabled={disabled}
						showSearch
						{...params}
						{...treeSelectParams}
						className={tagClasses.tag}
					/>
				) : (
					<TreeView
						path={path}
						showAllParents={showAllParents}
						activeStatuses={activeStatuses}
						label={label}
						value={value}
						loadFromId={value}
					/>
				)}
			</Col>
		</Row>
	);
};

export default TreeSelectField;
