import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	eventsDayPickerContainer: {
		display: 'grid',
		gridTemplateColumns: '32px 1fr 32px',
		gridTemplateRows: '1fr',
		alignItems: 'center',
		paddingInline: 16,
		borderBlockStart: `2px solid ${theme.border.primary}`,
		borderBlockEnd: `1px solid ${theme.border.primary}`,
		boxShadow: theme.calendarDayPickerShadow,
	},
	eventsWeek: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBlock: 9,
		paddingInline: 5,
	},
	eventsWeekDay: {
		display: 'flex',
		alignItems: 'center',
		color: theme.text.icon,
		backgroundColor: 'transparent',
		cursor: 'pointer',
		border: 'none',
		textTransform: 'capitalize',

		paddingBlock: 12,
		paddingInline: 9,

		'&:hover, &.active': {
			borderRadius: 12,
			backgroundColor: theme.bgColor.disabled,
			transition: 'background-color .5s linear',
		},

		'&.active > span': {
			backgroundColor: theme.text.btnLink,
			color: theme.bgColor.button,
			borderRadius: '50%',
			transition: 'background-color .7s linear',
		},
	},

	eventsWeekDate: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		marginInlineStart: 5,
		fontWeight: 500,
		color: theme.text.secondary,
		inlineSize: 20,
		blockSize: 20,
	},

	eventsDayControlButton: {
		border: 'none',
		background: 'transparent',
		fontSize: 8,
		boxShadow: 'none',

		'&.ant-btn:not(.ant-btn-link, .ant-btn-primary)[disabled]': {
			borderColor: 'transparent',
			backgroundColor: 'transparent!important',
		},
	},
}));
