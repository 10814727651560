import type { CheckProjectStatuses } from '~server-types/doc/api/models/check_project';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const checkProjectStatusColors: Record<CheckProjectStatuses, string> = {
	active: 'green',
	disabled: 'geekblue',
	removed: '',
	draft: '',
	waiting_approve: 'gold',
};

export const statusOrder = {
	active: 1,
	disabled: 4,
	removed: 5,
	draft: 3,
	waiting_approve: 2,
};

export const actionsConfig = makeDynamicTranslations({
	send_to_approve: {
		headerText: tDynamic('Отправка проекта на подтверждение'),
		mainText: tDynamic(
			'Вы уверены, что хотите отправить проект на подтверждение? Статус проекта будет изменен на «Ожидает подтверждения»'
		),
		buttonText: tDynamic('Отправить на подтверждение'),
		buttonColor: 'blue',
	},
	approve: {
		headerText: tDynamic('Подтверждение проекта'),
		mainText: tDynamic('Вы уверены, что хотите подтвердить проект? Статус проекта изменится на «Активный».'),
		buttonText: tDynamic('Подтвердить'),
		alertText: tDynamic('После подтверждения внести изменения в проект нельзя'),
		buttonColor: 'blue',
	},
	edit: {
		headerText: tDynamic('Изменение проекта'),
		mainText: tDynamic('Вы уверены, что хотите изменить проект?'),
		buttonText: tDynamic('Изменить'),
		buttonColor: 'red',
	},
	make_copy: {
		headerText: tDynamic('Создание копии проекта'),
		mainText: tDynamic('Вы уверены, что хотите создать копию проекта?'),
		buttonText: tDynamic('Создать копию'),
		alertText: tDynamic(''),
		buttonColor: 'blue',
	},
	disable: {
		headerText: tDynamic('Отключение проекта'),
		mainText: tDynamic('Вы уверены, что хотите отключить проект? Статус проекта будет изменен на «Отключенный»'),
		buttonText: tDynamic('Отключить'),
		buttonColor: 'red',
	},
	remove: {
		headerText: tDynamic('Удаление проекта'),
		mainText: tDynamic('Вы уверены, что хотите удалить проект? Статус проекта будет изменен на «Удаленный»'),
		buttonText: tDynamic('Удалить'),
		buttonColor: 'red',
	},
	disapprove: {
		headerText: tDynamic('Отклонение проекта'),
		mainText: tDynamic('Вы уверены, что хотите отклонить проект? Статус проекта изменится на «Черновик»'),
		buttonText: tDynamic('Отклонить'),
		buttonColor: 'red',
	},
} as Record<
	string,
	{
		headerText: string;
		mainText: string;
		buttonText: string;
		alertText?: string;
		buttonColor: string;
	}
>);
