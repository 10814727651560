const createGoogleInfoWindows = () => {
	const infoWindows = new Set<google.maps.InfoWindow>();

	const add = (infoWindow: google.maps.InfoWindow) => {
		infoWindows.add(infoWindow);
	};

	const closeAll = () => {
		infoWindows.forEach((infoWindow) => {
			if (infoWindow?.close) {
				infoWindow.close();
			}
		});
	};

	return {
		add,
		closeAll,
	};
};

export default createGoogleInfoWindows;
