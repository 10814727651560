import { useEffect, useState } from 'react';

import useSubscribe from '~shared/hooks/useSubscribe';
import { useUser } from '~zustand/userData';

export const useMetricsSubscribe = (callback: () => void) => {
	const [delay, setDelay] = useState<number>();
	let timeout: NodeJS.Timeout;

	const user = useUser();

	useSubscribe({
		key: ['refresh_health', 'store', user.store_id],
		cb: (evt, code) => {
			const evtDelay = evt.data[0].delay;
			switch (code) {
				case 'OK':
				case 'INIT':
				case 'MAYBE_DATA_LOST':
					setDelay(evtDelay * 1000);
					break;
				default:
					break;
			}
		},
		unSub: true,
		single: false,
		needInit: true,
	});

	useEffect(() => {
		if (delay) {
			timeout = setTimeout(() => {
				callback();
				setDelay(undefined);
			}, delay);
		}
		return () => clearTimeout(timeout);
	}, [delay]);
};
