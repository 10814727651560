import type { PickerProps, RangePickerProps } from 'antd/es/date-picker/generatePicker/interface';
import type { Dayjs } from 'dayjs';
import { forwardRef } from 'react';

import DatePicker from './DatePicker';

type TimePickerProps = Omit<PickerProps<Dayjs>, 'picker'>;
type TimePickerRangeProps = Omit<RangePickerProps<Dayjs>, 'picker'>;

const TimePicker = forwardRef<
	any,
	(TimePickerProps & { isTimeRange: false }) | (TimePickerRangeProps & { isTimeRange: true })
>((props, ref) => {
	return props.isTimeRange ? (
		<DatePicker.RangePicker {...props} picker="time" mode={undefined} ref={ref} />
	) : (
		<DatePicker {...props} picker="time" mode={undefined} ref={ref} />
	);
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
