import { notification } from '@lavka/ui-kit';
import { Button, Spin } from 'antd';
import type { MouseEvent } from 'react';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

export interface IPersonalDataButtonProps {
	loadData: () => Promise<string>;
	className?: string;
	disabled?: boolean;
	buttonText?: string;
}

export const PersonalDataButton = memo((props: IPersonalDataButtonProps) => {
	const [t] = useTranslation();

	const { loadData, className, disabled, buttonText } = props;

	const { cx, classes } = useStyles();
	const [data, setData] = useState<string | null>(null);
	const [loading, toggleLoading] = useState<boolean>(false);

	const handleClick = useCallback(
		async (e: MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			toggleLoading(true);

			try {
				const data = await loadData();
				setData(data);
			} catch {
				notification.error({
					message: t('Произошла ошибка во время загрузки персональных данных'),
				});
			} finally {
				toggleLoading(false);
			}
		},
		[loadData]
	);

	if (loading) {
		return <Spin size="small" />;
	}

	if (data !== null) {
		return <div>{data.length > 0 ? data : t('Данные отсутствуют')}</div>;
	}
	return (
		<Button size="small" disabled={disabled} onClick={handleClick} className={cx(classes.showDataBtn, className)}>
			{buttonText ?? t('Показать')}
		</Button>
	);
});
