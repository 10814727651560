import { Link } from 'react-router-dom';

import { useDirection } from '~i18n/hooks';
import { ReactComponent as ChevronLeftSolid } from '~images/icons/chevron-left-solid.svg';
import { ReactComponent as ChevronRightSolid } from '~images/icons/chevron-right-solid.svg';
import Icon from '~shared/utils/icons';

import { useStyles } from './styles';

export const NextIconLink = ({ link, target = '_blank' }: { link: string; target?: string }) => {
	const { classes } = useStyles();
	const NextIcon = useDirection(ChevronRightSolid, ChevronLeftSolid);

	return (
		<Link className={classes.nextIconLink} target={target} to={link}>
			<Icon component={NextIcon} />
		</Link>
	);
};
