import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import { useEffect, useMemo } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { useWatch } from 'react-hook-form';

import type { BaseField, SectionConfig } from '~constants/dataPageFields/types';
import { arrayUniq } from '~shared/utils/arrayUniq';

function checkConditions(sections: SectionConfig[], formValues: Record<string, unknown>) {
	const changedFields: BaseField[] = [];
	for (const section of sections) {
		for (const field of section.fields) {
			if (!field.conditions?.visible) {
				continue;
			}

			const rules = field.conditions.visible;
			const currentHidden = field.hidden;
			for (const rule of rules) {
				const passed = Object.keys(rule).every((fieldKey) => {
					const value = fieldKey.split('.').reduce((acc, e) => {
						return acc?.[e] as Record<string, unknown>;
					}, formValues);
					const condition = rule[fieldKey];

					return typeof condition === 'function' ? condition(value) : isEqual(condition, value);
				});

				if (passed) {
					field.hidden = false;
					break;
				}
				field.hidden = true;
			}

			if (field.hidden !== currentHidden) {
				changedFields.push(field);
			}
		}
	}
	return changedFields;
}

function useTrackedFields(sections: SectionConfig[]) {
	return useMemo(() => {
		const fields: string[] = [];

		for (const section of sections) {
			for (const field of section.fields) {
				const ruleKeys = field.conditions?.visible?.flatMap((rules) => Object.keys(rules));
				if (ruleKeys) {
					fields.push(...ruleKeys);
				}
			}
		}

		return arrayUniq(fields);
	}, []);
}

export function useHandleFormChange<T extends FieldValues>(
	sections: SectionConfig[],
	context: UseFormReturn<T>,
	parentName?: string
) {
	const trackedFields = useTrackedFields(sections);
	let watch = useWatch({ control: context.control });

	if (parentName) {
		watch = get(watch, parentName);
	}

	const trackedFormValues = {};

	trackedFields.forEach((field) => {
		const value = get(watch, field);
		set(trackedFormValues, field, value);
	});

	useEffect(() => {
		const changedFields = checkConditions(sections, trackedFormValues);

		for (const field of changedFields) {
			if (field.hidden) {
				// @ts-expect-error
				context.setValue(field.key, field.defaultValue);
			} else {
				// это для того, чтобы затриггерить ререндер формы. Иначе, ничего работать не будет
				// @ts-expect-error
				context.setValue(field.key, context.getValues()[field.key]);
			}
		}
	}, [JSON.stringify(trackedFormValues)]);
}

export function UseHandleFormChange<T extends FieldValues>(props: {
	sections: SectionConfig[];
	context: UseFormReturn<T>;
	parentName?: string;
}) {
	useHandleFormChange(props.sections, props.context, props.parentName);

	return null;
}
