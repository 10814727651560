import dayjs from 'dayjs';
import { type DependencyList } from 'react';

import { useCache } from '~cache/useCache';
import type { Orders } from '~types/orders';
import { getSupplementOrders, useSupplementOrdersData } from '~zustand/ordersData';
import { useUserConstants } from '~zustand/userData';

import { SUBORDERS } from '../../components/OrdersPage';
import { useAsyncEffect } from './useAsyncEffect';

type UseGetDocumentsToProcess = (agutinIsOn: boolean, deps?: DependencyList) => { orders: Orders.Order[] } | undefined;

type SearchData = {
	type?: ('shipment' | 'acceptance' | 'acceptance_market')[];
	cursor: string | null;
	doc_date: {
		from: string;
		to: string;
	};
	date_status_updated_from: string;
	date_status_updated_to: string;
};

const useGetDocumentsToProcess: UseGetDocumentsToProcess = (agutinIsOn: boolean, deps?: DependencyList) => {
	const constants = useUserConstants();
	const supplement = useSupplementOrdersData();

	const requestDaysNumber = constants.configs?.options.processing_required_deep_in_days.option ?? 7;

	const searchData: SearchData = {
		cursor: '',
		doc_date: {
			from: dayjs().subtract(requestDaysNumber, 'day').format('YYYY-MM-DD'),
			to: dayjs().format('YYYY-MM-DD'),
		},
		date_status_updated_from: dayjs().subtract(requestDaysNumber, 'day').format('YYYY-MM-DD'),
		date_status_updated_to: dayjs().format('YYYY-MM-DD'),
		type: ['acceptance', 'acceptance_market', 'shipment'],
	};

	useAsyncEffect(async () => {
		if (searchData.cursor !== null) {
			return getSupplementOrders(searchData);
		}
	}, deps);

	useCache({
		userExecutors: supplement.flatMap((e: Orders.Order) => e.users),
	});

	return { orders: supplement.filter((order: Orders.Order) => !SUBORDERS.includes(order.type)) };
};

export default useGetDocumentsToProcess;
