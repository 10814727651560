import { notification } from '@lavka/ui-kit';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import api from '~/api';
import type { PathsListPostEntity } from '~server-types/analytics/doc/api/admin/store_healths';
import OrderModal from '~shared/components/OrdersHistory/OrderModal';
import TableHeader from '~shared/components/TableHeader';
import { useMetricsSubscribe } from '~shared/hooks/useMetricsSubscribe';
import useRerender from '~shared/hooks/useRerender';
import { getIdsFromEntityList } from '~shared/utils/getIdsList';
import type { Orders } from '~types/orders';
import { useCheckPermit } from '~zustand/userData';

import Breadcrumbs from './Blocks/Breadcrumbs';
import ChartBlock from './Blocks/ChartBlock';
import BasicTooltip from './Blocks/ChartBlock/BasicTooltip';
import ChartComparisonModal from './Blocks/ChartsComparisonModal';
import CriticalBlock from './Blocks/CriticalBlock';
import CriticalEventsModal from './Blocks/CriticalEventsModal';
import HealthTableau from './Blocks/HealthTableau';
import LittleText from './Blocks/LittleText';
import PeriodControls from './Blocks/PeriodControls';
import Stats from './Blocks/Stats';
import StoreStatTable from './Blocks/StoreStatTable';
import SubUsers from './Blocks/SubUsers';
import UserInfo from './Blocks/UserInfo';
import { defaultPeriodState } from './constants';
import { HealthContext } from './context';
import type { PeriodState } from './healthTypes';
import useGetLinkedLocations from './hooks/useGetLinkedLocations';
import useGetThresholds from './hooks/useGetThresholds';
import useHealthData from './hooks/useHealthData';
import useLoadBreadcrumbs from './hooks/useLoadBreadcrumbs';
import useLoadCritEvents from './hooks/useLoadCritEvents';
import { useMetricsStore } from './hooks/useMetricsStore';
import { useStyles } from './styles';

export type ViewData = {
	type: PathsListPostEntity;
	id?: string;
};

interface Props {
	myStore?: boolean;
}

const Health = ({ myStore = false }: Props) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { user, userStore, timezone } = useGetLinkedLocations();

	const isPermitHealthCompany = useCheckPermit('health_company');
	const isPermitHealthCluster = useCheckPermit('health_cluster');
	const isPermitHealthHeadSupervisor = useCheckPermit('health_head_supervisor');
	const isPermitHealthSupervisor = useCheckPermit('health_supervisor');
	const isPermitHealthStore = useCheckPermit('health_store');

	const [searchParams, setSearchParams] = useSearchParams();
	const [orderIdToShow, setOrderIdToShow] = useState<Orders.Order['order_id'] | undefined>();
	const [graphsModalOpen, setGraphsModalOpen] = useState(false);
	const [critsModalOpen, setCritsModalOpen] = useState(false);
	const [periodState, setPeriodState] = useState<PeriodState>(defaultPeriodState);

	const { rerenderKey: subscriptionKey, updateRerenderKey: updateSubscriptionKey } = useRerender();

	useMetricsSubscribe(updateSubscriptionKey);

	const myStoreLevelState: ViewData = {
		type: 'store',
		id: user.store_id,
	};

	const [levelState, setLevelState] = useState<ViewData>(
		myStore
			? myStoreLevelState
			: {
					type: (searchParams.get('type') as PathsListPostEntity | null) ?? 'company',
					id: searchParams.get('id') ?? user.company_id,
				}
	);

	const { graphs, storesTableData, clustersTableData, stats, subUsers, mainLoading, currentEntity, updated } =
		useHealthData({
			levelState,
			periodState,
			timezone,
			subscriptionKey,
		});
	const metricId = useMetricsStore((state) => state.metrics[0].id);
	const breadcrumbs = useLoadBreadcrumbs(currentEntity, user.company_id);
	const currentClusterId = breadcrumbs[1]?.id;

	const { critLoading, critData } = useLoadCritEvents({ levelState, clusterId: currentClusterId });

	const thresholds = useGetThresholds({
		type: levelState.type,
		id: levelState.id,
		storeIds: getIdsFromEntityList(storesTableData, 'store_id'),
		clusterIds: getIdsFromEntityList(clustersTableData, 'cluster_id'),
	});

	const getAllowedUsers = async (role: 'head_supervisor' | 'supervisor') => {
		try {
			const { data } = await api.users.list_allowed({
				role,
				store_id: user.store_id as string,
			});

			data.result.length &&
				setSearchParams({
					type: role,
					id: data.result[0].user_id,
				});
		} catch {
			user.store_id && setSearchParams(myStoreLevelState);
			notification.error({ message: t('Не удалось получить список супервайзеров') });
		}
	};

	useEffect(() => {
		if (myStore) {
			return;
		}

		const type = searchParams.get('type') as PathsListPostEntity | null;
		const id = searchParams.get('id');

		if (type && id) {
			setSearchParams({ type, id });
		}

		if (type || id) {
			return;
		}

		if (isPermitHealthCompany && user.company_id) {
			setSearchParams({ type: 'company', id: user.company_id });
		} else if (isPermitHealthCluster && userStore?.cluster_id) {
			setSearchParams({ type: 'cluster', id: userStore.cluster_id });
		} else if ((isPermitHealthHeadSupervisor || isPermitHealthSupervisor) && user.user_id) {
			const role = isPermitHealthHeadSupervisor ? 'head_supervisor' : 'supervisor';

			// Если роль пользователя Супервайзер или Старший супервайзер
			if (user.role && user.user_id && ['head_supervisor', 'supervisor'].includes(user.role)) {
				setSearchParams({
					type: role,
					id: user.user_id,
				});
			} else if (user.store_id) {
				// Запрашиваем старшего супервайзера или супервайзера для склада текущего пользователя
				void getAllowedUsers(role);
			}
		} else if (isPermitHealthStore && user.store_id) {
			setSearchParams(myStoreLevelState);
		}
	}, [
		userStore?.cluster_id,
		user?.user_id,
		user?.store_id,
		isPermitHealthCompany,
		isPermitHealthCluster,
		isPermitHealthHeadSupervisor,
		isPermitHealthSupervisor,
		myStore,
	]);

	useEffect(() => {
		if (myStore) {
			setLevelState(myStoreLevelState);
			return;
		}

		const type = searchParams.get('type') as PathsListPostEntity | null;
		const id = searchParams.get('id');

		if (type && id && (type !== levelState.type || id !== levelState.id)) {
			setLevelState({ type, id });
		}
	}, [searchParams.get('type'), searchParams.get('id'), myStore]);

	const title = t('Здоровье');

	const contextValue = useMemo(
		() => ({ periodState, loading: mainLoading, userStore, userStoreId: user.store_id, setOrderIdToShow, levelState }),
		[periodState, mainLoading, userStore, setOrderIdToShow, levelState]
	);

	const healthTabloVisible = currentEntity.store_id || (currentEntity.type === 'store' && currentEntity.id);
	return (
		<HealthContext.Provider value={contextValue}>
			<OrderModal setOrderIdToShow={setOrderIdToShow} orderIdToShow={orderIdToShow ?? ''} />
			<ChartComparisonModal
				visible={graphsModalOpen}
				setModalOpen={setGraphsModalOpen}
				thresholds={thresholds[levelState.type][levelState.id ?? '']}
			/>
			<CriticalEventsModal
				data={critData}
				loadingTable={critLoading}
				visible={critsModalOpen}
				setModalOpen={setCritsModalOpen}
			/>
			<TableHeader
				title={title}
				rightContent={
					updated ? (
						<LittleText>
							{t('Обновлено: {{time}}', {
								time: dayjs(updated)
									.tz(timezone ?? undefined)
									.format('HH:mm, DD.MM.YYYY'),
							})}
						</LittleText>
					) : undefined
				}
			/>
			<div className={classes.healthContainer} data-test="health container">
				<div className={classes.healthHeader}>
					<div>
						<Breadcrumbs items={breadcrumbs} disabled={myStore} />
						<UserInfo id={levelState.id} type={levelState.type} />
					</div>
				</div>
				{subUsers && levelState.type !== 'store' && (
					<SubUsers subUsers={subUsers} onClick={(id, type) => setSearchParams({ type, id })} />
				)}
				<PeriodControls periodState={periodState} setPeriodState={setPeriodState} />
				<Stats statsData={stats} thresholds={thresholds[levelState.type][levelState.id ?? '']} />
				<div className={cx(classes.specialInfoRow, { [classes.specialInfoCompany]: levelState.type === 'company' })}>
					<ChartBlock
						chartLoading={mainLoading}
						data={graphs.now}
						retroData={graphs.past}
						setModalOpen={setGraphsModalOpen}
						id={metricId}
						thresholds={levelState.type === 'store' ? thresholds[levelState.type][levelState.id ?? ''] : undefined}
						isAllRetroUsed={periodState.sliceType !== 'hour'}
						customTooltip={<BasicTooltip />}
					/>
					{levelState.type !== 'company' && (
						<CriticalBlock data={critData} loading={critLoading} setModalOpen={setCritsModalOpen} />
					)}
				</div>
				{levelState && levelState.type !== 'store' && (
					<StoreStatTable
						data={levelState.type === 'company' ? clustersTableData : storesTableData}
						isClusters={levelState.type === 'company'}
						thresholds={levelState.type !== 'company' ? thresholds.store : undefined}
					/>
				)}
			</div>
			{healthTabloVisible && (
				<HealthTableau
					averagesData={stats.now}
					storeId={currentEntity.store_id ?? currentEntity.id}
					timezone={timezone}
				/>
			)}
		</HealthContext.Provider>
	);
};

export default Health;
