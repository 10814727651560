import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	container: {
		maxInlineSize: 264,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingBlock: '72px',
		paddingInline: '20px',
		margin: 'auto',
		boxSizing: 'content-box',
		whiteSpace: 'normal',
	},
	image: {
		inlineSize: 184,
		marginBlockEnd: 32,
	},
	title: {
		marginBlockEnd: 16,
		textAlign: 'center',
		color: theme.text.primary,
		fontSize: '16px',
		fontWeight: 500,
	},
	text: {
		fontWeight: 'normal',
		textAlign: 'center',
		color: gray[8],
		fontSize: '14px',
		lineHeight: '22px',
		whiteSpace: 'pre-wrap',
	},
}));
