import { makeStyles } from '~styles/theme';

import { blocksBackground, darkRed, darkYellow, mainText } from '../../../components/MonitorTableau/styles';
import type { DeliveryCardTheme } from './constants';
import { accentRed } from './constants';

export const useStyles = makeStyles<{ themeFlag: DeliveryCardTheme; metricsHeight?: number }>()(
	(theme, { themeFlag, metricsHeight }) => ({
		redTime: {
			color: themeFlag === 'health' ? accentRed : theme.text.white,
		},
		mobileTableauRedTime: {
			color: theme.text.error,
		},
		time: {
			fontSize: '0.786em',
			overflow: 'hidden',
		},
		deliveryName: {
			color: themeFlag === 'health' ? theme.text.primary : theme.text.white,
			fontSize: '0.813em',
			display: 'inline-block',
		},
		mobileTableauDeliveryName: {
			fontSize: 16,
			fontWeight: 400,
			color: theme.text.widgetAmount,
		},
		deliveryCard: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: themeFlag === 'health' ? theme.bgColor.main : blocksBackground,
			borderRadius: 12,
			color: themeFlag === 'health' ? theme.text.primary : theme.text.white,
			boxShadow: themeFlag === 'health' ? theme.softShadow : 'none',
		},
		mobileTableauDeliveryCard: {
			fontSize: 16,
			fontWeight: 500,
			backgroundColor: theme.bgColor.main,
			color: theme.text.widgetAmount,
			boxShadow: theme.widgetShadow,
		},
		deliveryCardRow: {
			paddingBlock: 5,
			paddingInline: 0,
			display: 'grid',
			gridTemplateColumns: 'auto 4fr 1fr',
			gridTemplateAreas: "'avatar center time'",
			columnGap: '0.25em',
			color: themeFlag === 'health' ? theme.text.primary : theme.text.white,
			fontSize: 16,
			'&:last-child': {
				borderRadius: '0 0 18px 18px',
			},
		},
		mobileTableauDeliveryCardRow: {
			gridTemplateColumns: '32px 1fr auto',
			color: theme.text.widgetAmount,
			fontSize: 16,
			fontWeight: 400,
			paddingBlock: 12,
			paddingInline: 12,
			columnGap: 12,
			borderBlockEnd: `1px solid ${theme.border.widgetContent}`,
		},
		healthDeliveryCardRow: {
			borderBlockStart: `2px solid ${theme.border.primary}`,
			fontSize: 16,
		},
		deliveryHeader: {
			paddingBlock: 10,
			paddingInline: 0,
			inlineSize: '100%',
		},
		mobileTableauDeliveryHeader: {
			borderBlockEnd: `1px solid ${theme.border.widgetContent}`,
		},
		deliveryCardTitle: {
			color: themeFlag === 'health' ? theme.text.primary : theme.text.white,
			inlineSize: '100%',
			paddingBlock: 10,
			paddingInline: '16px 0',
		},
		mobileTableauDeliveryCardTitle: {
			color: theme.text.widgetAmount,
			paddingInline: '16px 0',
		},
		couriersAmount: {
			marginInlineStart: 10,
			color: mainText,
		},
		mobileTableauCouriersAmount: {
			color: theme.text.metricsValue,
		},
		healthCouriersAmount: {
			color: theme.text.icon,
		},
		centerDeliveryBlock: {
			gridArea: 'center',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			'& > *': {
				lineHeight: '1.3em',
			},
		},
		deliveryDocNumber: {
			fontSize: '0.688em',
			color: themeFlag === 'health' ? mainText : 'rgba(255, 255, 255, .5)',
		},
		mobileTableauDeliveryDocNumber: {
			color: theme.text.metricsValue,
			fontSize: 13,
		},
		redDelivery: {
			backgroundColor: themeFlag === 'health' ? 'inherit' : darkRed,
		},
		mobileTableauRedDelivery: {
			backgroundColor: 'inherit',
		},
		yellowDelivery: {
			backgroundColor: themeFlag === 'health' ? 'inherit' : darkYellow,
		},
		deliveryTime: {
			gridArea: 'time',
			fontFeatureSettings: 'initial',
			fontVariantNumeric: 'tabular-nums',
		},
		deliveryContent: {
			display: 'flex',
			flexDirection: 'column',
			gap: 6,
			marginBlockStart: 6,
			blockSize: `calc(100vh - 106px - ${metricsHeight}px)`,
			borderRadius: 12,
		},
		deliveryCardFooter: {
			display: 'flex',
			flexDirection: 'row',
			position: 'relative',
			margin: 10,
			marginInlineStart: 13,
			justifyContent: 'start',
		},
		footerInitials: {
			borderRadius: '50%',
			color: theme.text.white,
			fontSize: '0.9em',
			zIndex: 100,
		},
		icon: {
			inlineSize: '1em',
			blockSize: '1em',
			color: mainText,
		},
		iconWrapper: {
			gridArea: 'avatar',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			inlineSize: '1.5em',
			blockSize: '1.5em',
			borderRadius: '50%',
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
			color: theme.text.white,
			marginInlineStart: 10,
			fontSize: 16,
		},
		mobileTableauIconWrapper: {
			inlineSize: '2em',
			blockSize: '2em',
			marginInlineStart: 0,

			'& svg': {
				inlineSize: '1.3em',
				blockSize: '1.3em',
			},
		},
	})
);
