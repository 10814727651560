import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	columnTag: {
		background: 'grey',
	},
	warehouseOperationsSum: {
		display: 'flex',
		flexDirection: 'column',
		color: theme.text.light,
		fontSize: 12,
		lineHeight: '14px',
		span: {
			color: theme.text.primary,
			fontSize: 14,
			fontWeight: 500,
			lineHeight: '16px',
		},
	},
	warehouseInfoBlockWrapper: {
		inlineSize: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
	},
	warehouseInfoBlockItem: {
		display: 'flex',
		justifyContent: 'space-between',
		fontWeight: 500,
	},
	warehouseInfoBlockCount: {
		color: theme.text.light,
	},
}));
