import type { ProcessedCourierCardData } from '~shared/constants/tableauCouriers';

import { UserAvatar } from '../UserAvatar';
import type { DeliveryCardTheme } from './constants';
import { useStyles } from './styles';

type Props = {
	couriers: ProcessedCourierCardData[];
	theme?: DeliveryCardTheme;
};

const DeliveryCardFooter = ({ couriers, theme = 'health' }: Props) => {
	const { classes } = useStyles({ themeFlag: theme });

	return (
		<div className={classes.deliveryCardFooter}>
			{couriers.map((courier, i) => {
				return (
					<div
						className={classes.footerInitials}
						style={
							i
								? {
										position: 'relative',
										insetInlineStart: `-${0.7 * i}em`,
										zIndex: 100 - i,
									}
								: undefined
						}
						key={courier.courier_id}
					>
						<UserAvatar
							name={courier.name}
							userId={courier.courier_id ?? ''}
							srcLink={`/couriers/${courier.courier_id}`}
							showInitials={true}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default DeliveryCardFooter;
