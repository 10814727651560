import { blue } from '@ant-design/colors';

import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

const FIELDS_GAP = 8;
const INPUT_MIN_INLINE_SIZE = 80;

export const useStyles = makeStyles()((theme) => ({
	fieldArray: {
		display: 'flex',
		flexDirection: 'column',
	},
	fieldArrayTitle: {
		marginBlockEnd: 12,
		fontWeight: 500,
	},
	fieldArrayContent: {
		marginBlockEnd: 12,
		display: 'flex',
		flexDirection: 'column',
	},
	fieldArrayItem: {
		display: 'flex',
		alignItems: 'center',
		columnGap: FIELDS_GAP,
		minBlockSize: '42px',

		'&.fullWidth': {
			inlineSize: '100%',
		},
	},
	fieldArraySection: {
		flexGrow: 1,
	},
	fieldArrayFields: {
		display: 'flex',
		gap: FIELDS_GAP,
	},
	fieldArrayFieldsColumn: {
		flexDirection: 'column',
	},
	fieldArrayFieldsRow: {
		flexDirection: 'row',
		whiteSpace: 'nowrap',
	},
	fieldArrayFieldsGrid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
		columnGap: 24,
		'> div': {
			alignItems: 'baseline',
			flexWrap: 'nowrap',
		},
	},
	labelContainerGrid: {
		maxInlineSize: `calc(100% - ${INPUT_MIN_INLINE_SIZE}px)`,
		alignItems: 'center',
		'div:has(label)': {
			overflow: 'hidden',
			label: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
		},
	},
	readOnlyFieldContainerGrid: {
		minInlineSize: INPUT_MIN_INLINE_SIZE,
	},
	fieldArrayItemInput: {
		alignItems: 'baseline',
		'&>.ant-col': {
			flex: 1,
			'& .ant-picker': {
				inlineSize: '100%',
			},
		},
	},
	fieldArrayAddButton: {
		'&.ant-btn:hover': {
			borderColor: blue[4],
			color: blue[4],
		},
		marginBlockEnd: 16,
		inlineSize: '100%',
	},
	fieldArrayAddButtonTooltip: {
		blockSize: '32px',
		inlineSize: '32px',
	},
	fieldArrayAddButtonTooltipIcon: {
		blockSize: '16px',
		inlineSize: '16px',
		color: theme.border.secondary,
		margin: '8px',
		'&:hover': {
			color: theme.text.btnLink,
		},
	},
	fieldArrayCheckbox: {
		span: {
			color: 'black',
		},
	},
	separator: {
		marginBlock: 12,
		blockSize: 1,
		backgroundColor: gray[2],
	},
}));
