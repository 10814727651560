import { useCache } from '~cache/useCache';
import type { PathsListPostEntity } from '~server-types/analytics/doc/api/admin/store_healths';
import { isNotNullish } from '~shared/utils/isNotNullish';
import userName from '~shared/utils/userName';

import type { BreadcrumbElement } from '../Blocks/Breadcrumbs/types';
import type { CurrentEntityType } from '../healthTypes';

const URL_BASE = '/health';
const breadcrumbListByType = {
	company: ['company_id'],
	cluster: ['company_id', 'cluster_id'],
	head_supervisor: ['company_id', 'cluster_id', 'head_supervisor_id'],
	supervisor: ['company_id', 'cluster_id', 'head_supervisor_id', 'supervisor_id'],
	store: ['company_id', 'cluster_id', 'head_supervisor_id', 'supervisor_id', 'store_id'],
} as const;

const getLink = (id: string, type: PathsListPostEntity) => {
	const params = new URLSearchParams({ id, type });
	return `${URL_BASE}?${params.toString()}`;
};

const useLoadBreadcrumbs = (currentEntity: CurrentEntityType, companyId?: string): BreadcrumbElement[] => {
	const { id, type, supervisor_id, head_supervisor_id, cluster_id } = currentEntity ?? {};

	const cache = useCache({
		companies: companyId,
		clusters: cluster_id,
		userExecutors: [supervisor_id, head_supervisor_id],
		stores: [],
	});

	if (!companyId || !id || !type) {
		return [];
	}

	return breadcrumbListByType[type]
		.map((element) => {
			switch (element) {
				case 'company_id':
					return companyId
						? {
								id: companyId,
								title: cache.companies[companyId]?.title,
								link: getLink(companyId, 'company'),
							}
						: null;
				case 'cluster_id':
					return cluster_id
						? {
								id: cluster_id,
								title: cache.clusters[cluster_id]?.title,
								link: getLink(cluster_id, 'cluster'),
							}
						: null;
				case 'store_id':
					return id ? { id, title: cache.stores[id]?.title } : null;
				case 'head_supervisor_id':
					return head_supervisor_id
						? {
								id: head_supervisor_id,
								title: userName(cache.userExecutors[head_supervisor_id]),
								link: getLink(head_supervisor_id, 'head_supervisor'),
							}
						: null;
				case 'supervisor_id':
					return supervisor_id
						? {
								id: supervisor_id,
								title: userName(cache.userExecutors[supervisor_id]),
								link: getLink(supervisor_id, 'supervisor'),
							}
						: null;
			}
		})
		.filter(isNotNullish);
};

export default useLoadBreadcrumbs;
