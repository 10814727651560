import { Button, Tooltip } from 'antd';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { ReactComponent as CopyRegular } from '~images/icons/copy-regular.svg';
import copyToClipboard from '~shared/utils/copyToClipboard';
import Icon from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from './styles';

interface Props {
	string: string;
	tooltip: string;
	success: string;
	dataTest?: string;
}

const CopyString = ({ children, string, tooltip, success, dataTest }: PropsWithChildren<Props>) => {
	const { cx, classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();
	return (
		<div className={classes.container} data-test={dataTest}>
			{children}
			<div className={cx(classes.copyBtnContainer, 'wms-copy-btn')}>
				<Tooltip title={tooltip}>
					<Button
						className={cx(commonClasses.btnIcon, classes.copyBtn)}
						onClick={(e) => {
							e.stopPropagation();
							copyToClipboard(string, success);
						}}
					>
						<Icon component={CopyRegular} />
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};

export default memo(CopyString);
