export const saveData = (name: string, data: any, isSessionStorage: boolean) => {
	if (isSessionStorage) {
		window.sessionStorage.setItem(name, JSON.stringify(data));
	} else {
		window.localStorage.setItem(name, JSON.stringify(data));
	}
};

export const getData = (name: string, isSessionStorage: boolean) => {
	const data = isSessionStorage ? window.sessionStorage.getItem(name) : window.localStorage.getItem(name);
	if (data) {
		return JSON.parse(data);
	} else return;
};
