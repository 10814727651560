import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { PriceLists } from '~types/priceLists';

export default {
	list: makeListFn<{ price_list_id?: string; product_id?: string | string[] }, PriceLists.Product>(
		'/api/admin/price_list_products/list'
	),
	save: (data: Record<string, any>) => axios.post<{ result: any }>('/api/admin/price_list_products/save', data),
	load: (data: { price_list_id?: string; product_id?: string }, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: any }>('/api/admin/price_list_products/load', data, config),
	import_data: (data: { price_list_id: string; csv: string; mark_removed: boolean }) =>
		axios.post('/api/admin/price_list_products/import_data', data),
	export_csv: (data: { price_list_id: string }) =>
		axios.post<EmptyObject>('/api/admin/price_list_products/export_csv', data),
};
