import { Button, Radio } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import type { Orders } from '~types/orders';
import { useUser } from '~zustand/userData';

import OrdersMap from '../OrdersMap';
import OrdersTable from '../OrdersTable';
import type { getTableConfig } from '../pageConfig';
import { useStyles } from './styles';

export type Props = {
	orders: Orders.Order[];
	tableConfig: ReturnType<typeof getTableConfig>;
};

export function OrdersTab({ orders, tableConfig }: Props) {
	const [t] = useTranslation();
	const { classes } = useStyles();

	const [showMap, toggleShowMap] = useState<boolean>(false);

	const user = useUser();
	const userStoreId = user.store_id;

	const cache = useCache({
		stores: userStoreId,
	});

	const store = userStoreId ? cache.stores[userStoreId] : undefined;

	const [typeFilter, setTypeFilter] = useState<'all' | 'express' | 'slot'>('all');

	const visibleOrders = useMemo(() => {
		switch (typeFilter) {
			case 'express':
				return orders.filter((e) => !e.attr.time_slot);
			case 'slot':
				return orders.filter((e) => e.attr.time_slot);
			default:
				return orders;
		}
	}, [orders, typeFilter]);

	return (
		<>
			<div className={classes.header}>
				<div className={classes.filters}>
					<Radio.Group value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)} buttonStyle="solid">
						<Radio.Button value="all">{t('Все заказы')}</Radio.Button>
						<Radio.Button value="express">{t('Экспресс')}</Radio.Button>
						<Radio.Button value="slot">{t('В слот')}</Radio.Button>
					</Radio.Group>
				</div>

				<Button onClick={() => toggleShowMap((pre) => !pre)}>
					{showMap ? t('Скрыть карту') : t('Показать карту')}
				</Button>
			</div>
			{showMap && store && <OrdersMap orders={visibleOrders} store={store} />}
			<OrdersTable orders={visibleOrders} tableConfig={tableConfig} />
		</>
	);
}
