import { notification } from '@lavka/ui-kit';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PrimaryButtonComponent from '~shared/components/PrimaryButtonComponent';
import { exportCSVFile } from '~shared/utils/export';

import type { ExportOrderProps } from '../types';

interface Props {
	headers: Record<string, string>;
	exportOrders: ExportOrderProps[];
	checkProjectId: string;
}

const ExportCSV = ({ headers, exportOrders, checkProjectId }: Props) => {
	const [t] = useTranslation();
	const [disabled, setDisabled] = useState<boolean>(false);

	const exportCSVAction = () => {
		try {
			setDisabled(true);

			exportCSVFile(headers, exportOrders, `check_project_export_${dayjs().format('DD.MM.YYYY')}_${checkProjectId}`);
			notification.success({
				message: t('Экспорт в CSV выполнен'),
			});
		} catch {
			notification.error({
				message: t('Не удалось экспортировать CSV'),
			});
		} finally {
			setDisabled(false);
		}
	};

	return (
		<PrimaryButtonComponent
			button={{
				type: 'button',
				action: exportCSVAction,
				text: t('Экспорт CSV'),
				disabled: disabled || !Object.keys(exportOrders).length,
				dataTest: 'export order button',
			}}
		/>
	);
};

export default ExportCSV;
