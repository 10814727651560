import { makeStyles } from '~styles/theme';

import { headerColor, headerHoverColor } from '../MonitorTableau/styles';

export const useStyles = makeStyles()((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		blockSize: '100%',
		minInlineSize: 375,
		marginBlockStart: -56,
	},
	avatar: {
		margin: 12,
		backgroundColor: 'transparent',
	},
	form: {
		inlineSize: '100%', // Fix IE 11 issue.
		marginBlockStart: 12,
	},
	submit: {
		marginBlock: '26px',
		marginInline: '0',
		marginBlockEnd: '24px',
	},
	root: {
		display: 'flex',
		gap: 8,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	title: {
		fontWeight: 'bold',
		fontSize: 28,
		marginBlockEnd: 16,
		lineHeight: '31px',
	},
	description: {
		color: theme.text.secondary,
		fontSize: 16,
	},
	loginButton: {
		gap: 10,
		blockSize: 56,
		inlineSize: 420,
		fontWeight: 500,
	},
	teamLoginButton: {
		color: '#fff',
		backgroundColor: headerColor,
		':hover': {
			backgroundColor: headerHoverColor,
		},
	},
	preloaderContainer: {
		display: 'flex',
		justifyContent: 'center',
		paddingBlockStart: 150,
	},
}));
