import { makeListFn } from '~shared/utils/makeApiFns';

import type { Trouble } from '../components/Health/healthTypes';
import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<
		{ cluster_id: string; store_id?: string; supervisor_id?: string; is_resolved?: string; calculated?: string },
		Trouble
	>('/api/report/admin/store_problems/list'),
	load: makeLoadFn<Trouble, 'store_problem_id'>('/api/report/admin/store_problems/load', 'store_problem_id'),
};
