import type { JSX } from 'react';
import { Navigate } from 'react-router-dom';

import { isTvClientMode } from '~shared/utils/isTvClientMode';
import type { ExpName } from '~types/userConfig';
import { useCheckExp, useCheckPermit, useUser } from '~zustand/userData';

import NotFound from '../ErrorPage/NotFound';

interface ProtectedComponentProps {
	permit: string | string[];
	exp?: ExpName | ExpName[];
	accessDeniedElement?: JSX.Element;
	ensureStoreIdExists?: boolean;
	element: JSX.Element;
	alternativeElement?: JSX.Element;
}

export const ProtectedComponent = ({
	permit,
	exp,
	accessDeniedElement,
	ensureStoreIdExists = false,
	element,
	alternativeElement,
}: ProtectedComponentProps) => {
	const user = useUser();

	let exps: ExpName[] = [];
	if (Array.isArray(exp)) {
		exps = exp;
	} else if (typeof exp === 'string') {
		exps = [exp];
	}
	const hasExp = useCheckExp(...exps);

	const permits = Array.isArray(permit) ? permit : [permit];
	const hasPermit = useCheckPermit(...permits);

	const isTv = isTvClientMode();

	if (hasPermit && hasExp && (!ensureStoreIdExists || user.store_id)) {
		return element;
	}

	if (alternativeElement && !hasExp) {
		return alternativeElement;
	}

	if (accessDeniedElement && user.role !== 'guest') {
		return accessDeniedElement;
	}
	if (user.role === 'guest') {
		localStorage.setItem('lavkaLastPathname', isTv ? '/' : window.location.pathname + window.location.search);
		return <Navigate replace to={isTv ? '/tv-client/tableau/auth' : '/login'} />;
	}

	return isTv ? <Navigate to="/tv-client/tableau" /> : <NotFound />;
};
