import type { ReactNode } from 'react';

import { ReactComponent as ErrorIcon } from './icons/error.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { useStyles } from './styles';

export type HeaderInformerProps = {
	type: 'warning' | 'error';
	content: ReactNode;
};

export function HeaderInformer({ type, content }: HeaderInformerProps) {
	const { cx, classes } = useStyles();

	return (
		<div
			className={cx(classes.container, {
				[classes.containerError]: type === 'error',
				[classes.containerWarning]: type === 'warning',
			})}
		>
			{type === 'warning' && <WarningIcon className={classes.icon} />}
			{type === 'error' && <ErrorIcon className={classes.icon} />}
			<span>{content}</span>
		</div>
	);
}
