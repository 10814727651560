export const renderLongTitle = (text?: string, title?: string) => {
	const element = title ? [text, title].filter((el) => el).join(' — ') : text;
	if (element && element?.length > 40) {
		return `${element.slice(0, 40)}...`;
	}
	if (!element?.length || element === '-') {
		return '—';
	}
	return element;
};
