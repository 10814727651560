import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { timetableTypeDictionary } from '~constants/timetableTypes';
import type { CheckProjects } from '~types/checkProjects';

import { useStyles } from '../styles';

type Props = {
	timetable: CheckProjects.CheckProject['schedule']['timetable'];
};

const IntervalBlock = ({ timetable }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	const repeatText = useMemo(() => {
		const onceInA = timetable.find((el) => el.type === 'day_interval');
		if (onceInA) {
			return t('Раз в {{count}} дней', { count: onceInA.repeat });
		}
		const days = timetable.map((el) => el.type);
		return days.map((day) => timetableTypeDictionary[day]).join(', ');
	}, [timetable]);

	return timetable.length && timetable[0]?.begin ? (
		<div className={classes.intervalBlock}>
			{repeatText}
			{timetable[0]?.begin && (
				<>
					{', '}
					{`${t('в {{count}} часов', {
						count: Number(dayjs(timetable[0].begin, 'HH:mm:ss').format('HH')),
					})}`}
				</>
			)}
		</div>
	) : (
		<div>{timetableTypeDictionary['once']}</div>
	);
};

export default IntervalBlock;
