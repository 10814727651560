import type { FunctionComponent, ReactElement, SVGProps } from 'react';
import { Link } from 'react-router-dom';

import { useDirection } from '~i18n/hooks';
import { ReactComponent as ChevronLeftSolid } from '~images/icons/chevron-left-solid.svg';
import { ReactComponent as ChevronRightSolid } from '~images/icons/chevron-right-solid.svg';
import Slider from '~shared/components/Slider';
import Icon from '~shared/utils/icons';

import type { ColumnContentIconColors } from '../constants';
import { iconColors } from '../constants';
import { useStyles as healthTableauStyles } from '../styles';
import { useStyles } from './styles';

interface Props {
	text: string | ReactElement;
	dataTest?: string;
	icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
	iconColor?: ColumnContentIconColors;
	link?: string;
	ratio?: { count: number; totalCount: number };
	children?: ReactElement;
	isSubTitle?: boolean;
	pagination?: {
		onPrevClick: () => void;
		onNextClick: () => void;
	};
	isColumn?: boolean;
	amount?: number;
}

const ColumnContent = ({
	text,
	link,
	icon,
	ratio,
	children,
	dataTest,
	pagination,
	isSubTitle = false,
	iconColor,
	isColumn = false,
	amount,
}: Props) => {
	const { classes, cx } = useStyles({ iconColor: iconColors[iconColor ?? 'default'] });
	const { classes: healthTableauClasses } = healthTableauStyles();

	const NextIcon = useDirection(ChevronRightSolid, ChevronLeftSolid);

	const content = (
		<>
			<div className={cx({ [classes.subTitle]: isSubTitle, [classes.columnTitleText]: !isSubTitle })}>
				<div className={classes.columnTitleTextWrapper}>
					{icon && <Icon className={classes.iconWrapper} component={icon} />}
					<div className={healthTableauClasses.sumBlock}>
						<span>{text}</span>
						{typeof amount !== 'undefined' ? (
							<span data-test="sum" className={healthTableauClasses.greyText}>
								{amount}
							</span>
						) : null}
					</div>
					{ratio && (
						<div className={classes.ratio}>
							<span>{ratio.count}</span>/{ratio.totalCount}
						</div>
					)}
				</div>
				{pagination && <Slider onPrevClick={pagination.onPrevClick} onNextClick={pagination.onNextClick} />}
			</div>
			{link && <Icon className={classes.chevron} component={NextIcon} />}
		</>
	);

	return (
		<div data-test={dataTest} className={classes.columnChankWrapper}>
			{link ? (
				<Link target="_blank" to={link} className={classes.columnTitle}>
					{content}
				</Link>
			) : (
				<div className={cx(classes.columnTitle, { [classes.columnTitleNoBorder]: amount === 0 })}>{content}</div>
			)}
			{children && amount !== 0 ? (
				<div className={cx(classes.additionalContentWrapper, { [classes.columnPosition]: isColumn })}>{children}</div>
			) : null}
		</div>
	);
};

export default ColumnContent;
