import dayjs from 'dayjs';
import { memo, useEffect, useState } from 'react';

import { counterString, formatDifference } from '~shared/utils/momentFormatters';

import { useStyles } from './styles';

interface Props {
	time: string | string[];
	countUp?: boolean;
	word?: boolean;
	sync?: boolean;
	tick?: boolean;
}

export interface Difference {
	minus: boolean;
	hours: number | undefined;
	minutes: number | undefined;
	seconds: number | undefined;
}

const getCounter = (time: string | string[], countUp: boolean): Difference => {
	if (Array.isArray(time)) {
		time = time[0];
	}

	return countUp ? formatDifference(dayjs(), time) : formatDifference(time, dayjs());
};

const Timer = ({ time, countUp = false, word, sync = false, tick }: Props) => {
	const [counter, setCounter] = useState<Difference>(getCounter(time, countUp));

	const { cx, classes } = useStyles();

	useEffect(() => {
		// Если внешней синхронизации таймеров нет - считаем интервал внутри
		if (!sync) {
			const timeout = setTimeout(() => setCounter(getCounter(time, countUp)), 1000);

			return () => clearTimeout(timeout);
		}
	}, [counter]);

	useEffect(() => {
		// Если есть внешняя синхронизация - на каждое изменение tick переписываем counter.
		// tick - булево, которое переключается туда-сюда
		if (sync) {
			setCounter(getCounter(time, countUp));
		}
	}, [tick]);

	return (
		<span
			className={cx(classes.counter, {
				[classes.counterMinus]: counter.minus,
			})}
		>
			{counterString(counter.hours, counter.minutes, counter.seconds, word)}
		</span>
	);
};

export default memo(Timer);
