import { Button, Collapse, Dropdown, Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FilterRegular } from '~images/icons/filter-regular.svg';
import Icon, { CaretRightOutlined } from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from '../styles';
import Filter from './Filter';

const { Panel } = Collapse;

type FilterType = {
	key: string;
	filterTitle: string;
	allValues: string[];
	valuesToShow: string[];
	onChange: (values: string[]) => void;
	dictionary: Record<string, string>;
};

interface Props {
	filters: FilterType[];
}

const FilterGroup = ({ filters }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();

	const menu = (
		<Menu className={classes.filterBlock} data-test="stores map filters menu">
			<p className={classes.filterTitle}>{t('Фильтры')}</p>
			<Collapse
				className={classes.collapser}
				bordered={false}
				defaultActiveKey={[]}
				expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
			>
				{filters.map((filter) => (
					<Panel header={filter.filterTitle} key={filter.key} data-test={`filter header title ${filter.filterTitle}`}>
						<Filter
							valuesToShow={filter.valuesToShow}
							onChange={filter.onChange}
							allValues={filter.allValues}
							dictionary={filter.dictionary}
						/>
					</Panel>
				))}
			</Collapse>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<Tooltip title={t('Фильтры')}>
				<Button
					className={commonClasses.btnIconBordered}
					style={{ marginInlineStart: 'auto' }}
					data-test="stores map filters button"
				>
					<Icon component={FilterRegular} />
				</Button>
			</Tooltip>
		</Dropdown>
	);
};

export default FilterGroup;
