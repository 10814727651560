import { notification } from '@lavka/ui-kit';
import { Tooltip } from 'antd';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportOutlined } from '~shared/utils/icons';
import { changeJoinedStore } from '~zustand/actions/userData';

import type { ViewTrouble } from './getTroubleView';
import { useStyles } from './styles';

interface Props {
	action: 'order' | 'store';
	trouble: ViewTrouble;
	setOrderIdToShow: Dispatch<SetStateAction<string | undefined>>;
	userStoreId?: string;
}

const CriticalEventLink = ({ action, trouble, setOrderIdToShow, userStoreId }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	const config = {
		order: {
			tooltip: t('Открыть ордер'),
			onClick: async () => {
				try {
					if (trouble.storeId !== userStoreId) {
						await changeJoinedStore(trouble.storeId);
					}
					setOrderIdToShow(trouble.orderId);
				} catch {
					notification.error({ message: t('Не удалось загрузить ордер') });
				}
			},
		},
		store: {
			tooltip: t('Перейти в склад'),
			onClick: () => window.open(`/stores/${trouble.storeId}`, '_blank'),
		},
	};

	return (
		<Tooltip className={classes.critEventLink} title={config[action].tooltip}>
			<ExportOutlined onClick={config[action].onClick} data-test="open trouble entity" />
		</Tooltip>
	);
};

export default CriticalEventLink;
