import { makeRoutes } from '~shared/utils/makeRoutes';

import EnterTokenPage from '../components/EnterTokenPage';
import Login from '../components/Login';
import AuthDone from '../components/Login/auth-done';

const authRoutes = makeRoutes([
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/login-done',
		element: <AuthDone />,
	},
	{
		path: '/token',
		element: <EnterTokenPage />,
	},
]);

export default authRoutes;
