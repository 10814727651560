import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { CourierShiftStatuses, StoppedReasons } from '~server-types/supply/doc/api/admin/courier_shifts';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { Shifts } from '~types/shifts';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<Shifts.ListShiftsType, Shifts.Shift>('/api/supply/admin/courier_shifts/list'),

	list_day: (data: Shifts.ListDayShiftsType, config?: AxiosAdditionalConfig) =>
		axios.post('/api/supply/admin/courier_shifts/list_day', data, config),

	list_week: (data: Shifts.ListDayShiftsType, config?: AxiosAdditionalConfig) =>
		axios.post('/api/supply/admin/courier_shifts/list_week', data, config),

	export_shifts: (data: Shifts.ListShiftsType) => axios.post('/api/supply/admin/courier_shifts/export_shifts', data),

	import_data: (data: { csv: string; placement?: string }) =>
		axios.post('/api/supply/admin/courier_shifts/import_data', data),

	import_shifts: (data: { csv: string }) => axios.post('/api/supply/admin/courier_shifts/import_shifts', data),

	load: makeLoadFn<Shifts.Shift, 'courier_shift_id'>('/api/supply/admin/courier_shifts/load', 'courier_shift_id'),

	save: (data: Shifts.CourierShiftSave, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: Shifts.Shift }>('/api/supply/admin/courier_shifts/save', data, config),

	pause_availability: (data: { courier_shift_id: string }, config?: AxiosAdditionalConfig) =>
		axios.post<Shifts.PauseAvailability>('/api/supply/admin/courier_shifts/pause_availability', data, config),

	pause: (data: Shifts.PauseType) =>
		axios.post<{ courier_shift: Shifts.Shift }>('/api/supply/admin/courier_shifts/pause', data),

	unpause: (data: { courier_shift_id: string }) =>
		axios.post<{ courier_shift: Shifts.Shift }>('/api/supply/admin/courier_shifts/unpause', data),

	stop: (data: {
		courier_shift_id: string;
		status: CourierShiftStatuses;
		force_stop?: boolean;
		reason?: StoppedReasons;
		comment?: string;
		reissue?: boolean;
	}) => axios.post<{ courier_shift: Shifts.Shift }>('/api/supply/admin/courier_shifts/stop', data),

	reset_courier: (data: { courier_shift_id: string }) =>
		axios.post<{ courier_shift: Shifts.Shift }>('/api/supply/admin/courier_shifts/reset_courier', data),
};
