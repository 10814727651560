import { Steps, Tooltip } from 'antd';

import { orderIndicatorStepTooltip, orderIndicatorStepTypes, orderIndicatorValues } from '~constants/order';
import type { Orders } from '~types/orders';

import { useStyles } from '../styles';

type Props = {
	order: Orders.Order;
};

export function OrderIndicatorSteps({ order }: Props) {
	const { classes: styles } = useStyles();

	let currentIndicatorStep = -1;
	if (order.vars?.indicator) {
		currentIndicatorStep = orderIndicatorValues.indexOf(order.vars.indicator);
	}
	// Если завершен, то показываем все галочки
	if (order.status === 'complete') {
		currentIndicatorStep = orderIndicatorValues.length;
	}

	return (
		<Steps className={styles.orderIndicatorSteps} size="small" current={currentIndicatorStep}>
			{orderIndicatorValues.map((e) => (
				<Steps.Step
					key={e}
					title={orderIndicatorStepTypes[e]}
					stepIcon={(props) => <Tooltip title={orderIndicatorStepTooltip[e]}>{props.node}</Tooltip>}
				/>
			))}
		</Steps>
	);
}
