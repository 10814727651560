import type { AxiosPromise, AxiosResponse } from 'axios';

import arrayToChunks from './arrayToChunks';
import mergeResponses from './mergeResponses';
import { isFulfilled, isRejected } from './promiseHelpers';

type Request<T> = (ids: string | string[]) => AxiosPromise<T>;

const MAX_IDS = 100;

export const requestSeparator = async <T extends Record<string, unknown>>(
	request: Request<T>,
	ids: string | string[],
	chunkSize: number = MAX_IDS
): Promise<AxiosResponse<T>> => {
	if (!Array.isArray(ids) || ids.length <= chunkSize) {
		return request(ids);
	}

	const responses: PromiseSettledResult<AxiosResponse<T>>[] = await Promise.allSettled(
		arrayToChunks(ids, chunkSize).map(async (ids) => await request(ids))
	);

	const fulfilledResults = responses.filter(isFulfilled);
	const rejectedResults = responses.filter(isRejected);

	if (fulfilledResults.length) {
		const result = fulfilledResults.shift()!.value;
		fulfilledResults.forEach((res) => mergeResponses<T>(result, res.value));

		return result;
	}

	throw new Error(rejectedResults[0]?.reason);
};
