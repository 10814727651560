import { blue } from '@ant-design/colors';
import { Col, Row, Select, Upload } from 'antd';
import type { JSX } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as InboxInRegular } from '~images/icons/inbox-in-regular.svg';
import { useStyles as useFieldStyles } from '~shared/components/Fields/styles';
import { getColumns } from '~shared/constants/templateColumns';
import { exportCSVFile } from '~shared/utils/export';
import Icon, { DeleteOutlined } from '~shared/utils/icons';

import ModalComponent from '../ModalComponent';
import { useStyles } from './styles';

interface Props {
	visible: boolean;
	toggleVisible: (visible: boolean) => void;
	uploadProps?: {
		name: string;
		accept: string;
		fileList: any[];
		multiple?: boolean;
	};
	onOk: () => void;
	file: Record<string, any> | Record<string, any>[] | undefined;
	addAction: (file: File, fileList: Record<string, any>[], encode?: string) => void;
	removeAction: (index?: number) => void;
	title?: string;
	titleText?: string;
	descrText?: string;
	okText?: string;
	cancelText?: string;
	filenameKey?: string;
	selectEncoding?: {
		encodes: string[];
		default: string;
	};
	children?: JSX.Element | JSX.Element[];
	footer?: any[];
	templateFileName?: string;
	templateColumns?: string[];
	additionalContent?: JSX.Element;
}

const UploadModal = ({
	visible,
	uploadProps,
	onOk,
	toggleVisible,
	file,
	addAction,
	removeAction,
	title,
	titleText,
	descrText,
	okText,
	cancelText,
	filenameKey = 'fileName',
	selectEncoding,
	children,
	footer,
	templateFileName,
	templateColumns,
	additionalContent,
}: Props) => {
	const { classes: fieldClasses } = useFieldStyles();
	const { classes } = useStyles();
	const [t] = useTranslation();
	const [uploadEncode, setUploadEncode] = useState<string | undefined>(selectEncoding?.default);

	const encodeSelector = useMemo(() => {
		if (selectEncoding) {
			return (
				<Row align="bottom" className={classes.encodingRow}>
					<Col span={12} className={fieldClasses.encodeLabelContainer}>
						<label className={fieldClasses.label}>{t('Загружать в кодировке')}</label>
						<div className={fieldClasses.dotSeparator} />
					</Col>
					<Col span={12}>
						<Select
							defaultValue={selectEncoding.default}
							value={uploadEncode}
							options={selectEncoding.encodes.map((encode) => ({
								label: encode,
								value: encode,
							}))}
							onSelect={setUploadEncode}
							placeholder={t('Кодировка файла')}
						/>
					</Col>
				</Row>
			);
		}
		return null;
	}, [selectEncoding, uploadEncode]);

	const deleteFile = useCallback((filename: string, index = 0) => {
		return (
			<DeleteOutlined
				data-test={`delete file ${filename}`}
				onClick={() => {
					removeAction(index || undefined);
				}}
			/>
		);
	}, []);

	return (
		<ModalComponent
			open={visible}
			width={720}
			title={title ?? t('Импорт CSV')}
			okText={okText ?? t('Импортировать')}
			cancelText={cancelText ?? t('Закрыть')}
			className={`data-test-upload-modal-${visible ? 'visible' : 'hidden'}`}
			onOk={onOk}
			onCancel={() => {
				toggleVisible(false);
				file && Array.isArray(file) ? file.forEach((file, index) => removeAction(index)) : removeAction();
			}}
			okButtonProps={{
				disabled: !file || (Array.isArray(file) && file.length === 0),
				className: 'data-test-submit-upload',
			}}
			footer={footer}
		>
			{templateColumns && (
				<Trans
					i18nKey="Скачайте <file_link>шаблон</file_link>, заполните и загрузите файл."
					components={{
						file_link: (
							<a
								data-test={`import ${templateFileName} template`}
								onClick={() => exportCSVFile(getColumns(templateColumns), [], templateFileName)}
							/>
						),
					}}
				/>
			)}
			{additionalContent}
			<Upload.Dragger
				{...uploadProps}
				beforeUpload={(file, fileList) => {
					addAction(file, fileList, uploadEncode);
					return false;
				}}
			>
				<Icon component={() => <InboxInRegular width={35} />} style={{ color: blue[4] }} />
				<p className={classes.importBoxTitle}>
					{titleText ?? t('Щелкните или перетащите файл в эту область для загрузки')}
				</p>
				<p className={classes.importBoxText}>
					{descrText ?? t('Нужен файл в формате CSV и кодировке UTF-8 с использованием разделителя ;')}
				</p>
			</Upload.Dragger>
			{encodeSelector}
			{children}
			{file &&
				(Array.isArray(file) ? (
					file.map((fileItem, index) => (
						<div key={fileItem[filenameKey]} className={classes.fileRow}>
							<span data-test={`upload-file-name-${index}`}>{fileItem[filenameKey]}</span>
							{deleteFile(fileItem[filenameKey])}
						</div>
					))
				) : (
					<div className={classes.fileRow}>
						<span data-test="upload-file-name">{file[filenameKey]}</span>
						{deleteFile(file[filenameKey])}
					</div>
				))}
		</ModalComponent>
	);
};

export default UploadModal;
