import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ mode: boolean }>()((theme, { mode }) => ({
	storekeeperOrdersWrapper: {
		display: 'flex',
		flexDirection: 'column',
		inlineSize: '100%',
		borderRadius: 8,
		backgroundColor: mode ? theme.bgColor.disabled : theme.transparentColor,
	},
	storekeeperOrder: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		inlineSize: '100%',
		paddingBlock: 6,
		paddingInline: 8,
		fontSize: mode ? 'inherit' : 16,

		'&:not(:last-child)': {
			borderBlockEnd: `1px solid ${theme.border.disabled}`,
		},
	},
	orderInfoWrapper: {
		display: 'flex',
		flexDirection: 'column',
		color: mode ? 'inherit' : theme.text.widgetAmount,

		'& span': {
			wordBreak: 'break-all',
		},
	},
	orderInfo: {
		display: 'inline-flex',
		fontSize: mode ? 12 : 13,
		color: theme.text.metricsValue,
		gap: 5,
		maxInlineSize: 250,
	},
	timerWrapper: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'nowrap',
		fontFeatureSettings: 'initial',
		fontVariantNumeric: 'tabular-nums',

		'& a': {
			marginBlockStart: 0,
		},
	},
}));
