import { Tooltip } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ExternalLinkAltRegular } from '~images/icons/external-link-alt-regular.svg';
import { Link } from '~shared/ui-kit/Link';
import Icon from '~shared/utils/icons';
import { useUserDomainConfigs } from '~zustand/userData';

interface Props {
	type: 'order' | 'courier';
	externalId: string;
}

const ExternalLink = ({ type, externalId }: Props) => {
	const [t] = useTranslation();
	const domainConfigs = useUserDomainConfigs();
	const { external_id_tariff_editor, external_id_eda } = domainConfigs.urls;
	let link: string | null = null;

	switch (type) {
		case 'courier':
			const driverUuid = externalId.split('_')[1];

			if (external_id_tariff_editor && driverUuid) {
				link = `${external_id_tariff_editor}show-driver?uuid=${driverUuid}`;
			}
			break;

		case 'order':
			if (external_id_eda && domainConfigs.account === 'b2b') {
				link = `${external_id_eda}orders/view/${externalId}`;
			} else if (external_id_tariff_editor) {
				link = `${external_id_tariff_editor}grocery-orders?order_id=${externalId}`;
			}
			break;
	}

	if (!link) {
		return null;
	}

	return (
		<Tooltip title={t('Открыть во внешнем сервисе')}>
			<Link to={link} outer data-test={`${type} external link`}>
				<Icon component={ExternalLinkAltRegular} />
			</Link>
		</Tooltip>
	);
};

export default memo(ExternalLink);
