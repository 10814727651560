import { tabletMaxWidth } from '~constants/breakPoints';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ compact?: boolean; editMode?: boolean }>()((theme, { compact, editMode }) => ({
	subTitle: {
		lineHeight: '16px',
		fontSize: '14px',
		fontWeight: 400,
		color: theme.text.disabled,
	},
	divider: {
		blockSize: 2,
		margin: '0',
		marginInlineStart: '20px',
	},
	form: {
		padding: '0',
		paddingBlockEnd: '22px',
	},
	formTitle: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 16,
		fontWeight: 500,
		gap: 8,
		marginBlockEnd: 10,
		'& button': {
			marginInlineStart: 16,
			fontWeight: 'normal',
		},
	},
	formSubtitle: {
		marginBlockEnd: 16,
		fontWeight: 500,
		fontSize: 14,
		color: theme.text.primary,
	},
	titleCol: {
		paddingBlockEnd: 0,
	},
	section: {
		paddingBlock: compact ? '0' : '10px',
		paddingInline: compact ? '0' : '22px',
	},
	defaultSection: {
		paddingBlockEnd: 20,
		marginBlockEnd: 20,
	},
	rightSide: {
		paddingBlockStart: 72,
	},
	fields: {
		'&>div': {
			[`@media (max-width:${tabletMaxWidth}px)`]: {
				marginBlockEnd: 16,
			},
		},
	},
	fieldGroup: {
		marginBlockStart: 16,
		marginBlockEnd: 16,
	},
	fieldGroupLabel: {
		fontWeight: 500,
	},
	sections: {
		backgroundColor: theme.bgColor.main,
		border: 'none',
	},
	sectionContainer: {
		lineHeight: 'normal',
		backgroundColor: theme.bgColor.header,
		boxShadow: '0px 2px 16px rgba(89, 89, 89, 0.08), 0px 2px 4px rgba(89, 89, 89, 0.04)',
		borderRadius: 8,
		paddingBlock: compact ? '0' : '10px',
		paddingInline: compact ? '0' : '12px',
		margin: 10,
	},
	sectionBtns: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	sectionBtn: {
		margin: 8,
	},
	weekdaysField: {
		minBlockSize: 42,
		display: 'flex',
		alignItems: editMode ? 'center' : 'flex-start',
	},
	weekdaysLabel: {
		inlineSize: '50%',
		display: 'flex',
	},
	exportButton: {
		marginInlineStart: 10,
	},
	labelContainer: {
		display: 'flex',
		gap: 8,
		justifyContent: 'space-between',
	},
}));
