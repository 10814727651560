import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../styles';

interface Props {
	text: string;
	dataTest: string;
}

const LongTextView = ({ text, dataTest }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [collapsed, toggleCollapsed] = useState<boolean>(true);
	return (
		<div className={classes.valueOnViewMode}>
			<p className={classes.slideDownText} data-test={dataTest}>
				{collapsed ? `${text.slice(0, 35)}...` : text}
			</p>
			<p className={classes.toggleText} onClick={() => toggleCollapsed(!collapsed)}>
				{collapsed ? t('Показать полное описание') : t('Скрыть полное описание')}
			</p>
		</div>
	);
};

export default LongTextView;
