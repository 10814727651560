import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const warehouseGroupList: Record<string, string> = makeDynamicTranslations({
	banany: tDynamic('Бананы'),
	bytovaya_khimiya_tovary_dlya_doma: tDynamic('Бытовая химия, Товары для дома'),
	chipsy: tDynamic('Чипсы'),
	frukty_ovoshhi: tDynamic('Фрукты, овощи'),
	frukty_ovoshhi_zelen_limonady_i_soki: tDynamic('Фрукты, овощи, зелень, лимонады и соки'),
	garniry_sousy_konservacziya_gastronomiya_myasnaya: tDynamic('Гарниры, соусы, консервация, гастрономия (мясная)'),
	gigiena: tDynamic('Гигиена'),
	gotovaya_eda_khlebobulochnye_izdeliya: tDynamic('Готовая еда, Хлебобулочные изделия'),
	gotovye_blyuda_polufabrikaty_dlya_gotovoj_edy_tovary_srednej_vysoty: tDynamic(
		'Готовые блюда, полуфабрикаты для готовой еды (товары средней высоты)'
	),
	gotovye_blyuda_polufabrikaty_dlya_gotovoj_edy_vysokie_tovary: tDynamic(
		'Готовые блюда, полуфабрикаты для готовой еды (высокие товары)'
	),
	konditerskie_izdeliya_polufabrikaty_dlya_gotovoj_edy: tDynamic('Кондитерские изделия, полуфабрикаты для готовой еды'),
	molochnaya_produkcziya_syrykonditerskie_izdeliya_sousy_sneki: tDynamic(
		'Молочная продукция, Сыры,Кондитерские изделия, соусы, снеки'
	),
	molochnaya_produkcziya_yajczo: tDynamic('Молочная продукция, Яйцо'),
	myasnaya_gastronomiya_polufabrikaty_rybnye_izdeliya_konservacziya: tDynamic(
		'Мясная гастрономия, Полуфабрикаты, Рыбные изделия, Консервация'
	),
	myaso_okhlazhdennoe_polufabrikaty_okhlazhdennye_rybnye_izdeliya_konservacziya: tDynamic(
		'Мясо охлажденное, полуфабрикаты охлажденные, рыбные изделия, консервация'
	),
	sukhie_zavtraki_chaj_kofe_kakao_orekhi_i_sukhofrukty: tDynamic(
		'Сухие завтраки, Чай, кофе, какао, Орехи и сухофрукты'
	),
	tovary_dlya_detej: tDynamic('Товары для детей'),
	tovary_dlya_zhivotnykh: tDynamic('Товары для животных'),
	zamorozhennye_polufabrikaty_ofyag_zamorozka_maslo_slivochnoe_morozhenoe: tDynamic(
		'Замороженные полуфабрикаты - ОФЯГ заморозка - Масло сливочное, Мороженое'
	),
	Lemonades_juices_medium_height: tDynamic('Лимонады, соки (товары средней высоты)'),
	Lemonades_juices_high: tDynamic('Лимонады, соки (высокие товары)'),
	Snacks_condiments_high: tDynamic('Снеки, приправы (высокие товары)'),
	Snacks_condiments_medium: tDynamic('Снеки, приправы (товары средней высоты)'),
	Water: tDynamic('Вода'),
	Water_5l: tDynamic('Вода 5л'),
	Experiment: tDynamic('Эксперимент'),
	PVZ: tDynamic('ПВЗ'),
	Alcohol_smoking_medicine: tDynamic('Алкоголь, товары для курения, медицина'),
	Consumables: tDynamic('Расходные материалы'),
	morozhenoe: tDynamic('Мороженое'),
	Alcohol: tDynamic('Алкоголь'),
	smoking: tDynamic('Товары для курения'),
});

// TODO: LAVKADEV-6526. Переводы убрал, чтобы они не мешали загрузке топологии.
// пока что интерфейсом пользется только РФ. Договорились с Женей Князевым вернуться к переводам когда выйдем за пределы РФ
export const warehouseGroupListTopology: Record<string, string> = makeDynamicTranslations({
	banany: 'Бананы',
	bytovaya_khimiya_tovary_dlya_doma: 'Бытовая химия, Товары для дома',
	chipsy: 'Чипсы',
	frukty_ovoshhi: 'Фрукты, овощи',
	frukty_ovoshhi_zelen_limonady_i_soki: 'Фрукты, овощи, зелень, лимонады и соки',
	garniry_sousy_konservacziya_gastronomiya_myasnaya: 'Гарниры, соусы, консервация, гастрономия (мясная)',
	gigiena: 'Гигиена',
	gotovaya_eda_khlebobulochnye_izdeliya: 'Готовая еда, Хлебобулочные изделия',
	gotovye_blyuda_polufabrikaty_dlya_gotovoj_edy_tovary_srednej_vysoty:
		'Готовые блюда, полуфабрикаты для готовой еды (товары средней высоты)',
	gotovye_blyuda_polufabrikaty_dlya_gotovoj_edy_vysokie_tovary:
		'Готовые блюда, полуфабрикаты для готовой еды (высокие товары)',
	konditerskie_izdeliya_polufabrikaty_dlya_gotovoj_edy: 'Кондитерские изделия, полуфабрикаты для готовой еды',
	molochnaya_produkcziya_syrykonditerskie_izdeliya_sousy_sneki:
		'Молочная продукция, Сыры,Кондитерские изделия, соусы, снеки',
	molochnaya_produkcziya_yajczo: 'Молочная продукция, Яйцо',
	myasnaya_gastronomiya_polufabrikaty_rybnye_izdeliya_konservacziya:
		'Мясная гастрономия, Полуфабрикаты, Рыбные изделия, Консервация',
	myaso_okhlazhdennoe_polufabrikaty_okhlazhdennye_rybnye_izdeliya_konservacziya:
		'Мясо охлажденное, полуфабрикаты охлажденные, рыбные изделия, консервация',
	sukhie_zavtraki_chaj_kofe_kakao_orekhi_i_sukhofrukty: 'Сухие завтраки, Чай, кофе, какао, Орехи и сухофрукты',
	tovary_dlya_detej: 'Товары для детей',
	tovary_dlya_zhivotnykh: 'Товары для животных',
	zamorozhennye_polufabrikaty_ofyag_zamorozka_maslo_slivochnoe_morozhenoe:
		'Замороженные полуфабрикаты - ОФЯГ заморозка - Масло сливочное, Мороженое',
	Lemonades_juices_medium_height: 'Лимонады, соки (товары средней высоты)',
	Lemonades_juices_high: 'Лимонады, соки (высокие товары)',
	Snacks_condiments_high: 'Снеки, приправы (высокие товары)',
	Snacks_condiments_medium: 'Снеки, приправы (товары средней высоты)',
	Water: 'Вода',
	Water_5l: 'Вода 5л',
	Experiment: 'Эксперимент',
	PVZ: 'ПВЗ',
	Alcohol_smoking_medicine: 'Алкоголь, товары для курения, медицина',
	Consumables: 'Расходные материалы',
	morozhenoe: 'Мороженое',
	Alcohol: 'Алкоголь',
	smoking: 'Товары для курения',
});
