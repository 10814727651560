import type { FeatureCollection } from 'geojson';

import type { Stores } from '~types/stores';
import type { Zones } from '~types/zones';

import hexToRgbA from './hexToRgba';

export const geoJsonGenerator = (polygon: any, properties: Record<string, any>): FeatureCollection => {
	const features: FeatureCollection['features'] = [];

	if (polygon) {
		// преобразование MultiPolygon c одним полигоном в Polygon
		if (polygon.geometry.type === 'MultiPolygon' && polygon.geometry.coordinates.length === 1) {
			polygon = {
				...polygon,
				geometry: {
					type: 'Polygon',
					coordinates: [polygon.geometry.coordinates[0][0]],
				},
			};
		}
		features.push(polygon);
	}
	if (properties) {
		const polygon = features.find((feature) => feature?.geometry?.type === 'Polygon');
		if (polygon) {
			polygon.properties = {
				...properties,
			};
		} else {
			features.push({
				type: 'Feature',
				properties: {
					...properties,
				},
			} as FeatureCollection['features']['0']);
		}
	}
	return {
		type: 'FeatureCollection',
		features,
	};
};

export const placemarkOptionsGenerator = (statusValue: any, typeValue: any) => {
	const options: Record<string, any> = {
		zIndex: 2,
	};
	let iconType = 'Circle';
	if (typeValue === 'dc') {
		iconType = '';
	}
	switch (statusValue) {
		case 'searching':
			options.preset = `islands#violet${iconType}Icon`;
			break;
		case 'closed':
			options.preset = `islands#black${iconType}Icon`;
			options.zIndex = 1;
			break;
		case 'suspended':
			options.preset = `islands#gray${iconType}Icon`;
			break;
		case 'approved':
			options.preset = `islands#blue${iconType}Icon`;
			break;
		case 'agreement':
			options.preset = `islands#darkGreen${iconType}Icon`;
			break;
		case 'signed':
			options.preset = `islands#blue${iconType}DotIcon`;
			break;
		case 'inspection':
			options.preset = `islands#yellow${iconType}Icon`;
			break;
		case 'repair':
			options.preset = `islands#yellow${iconType}DotIcon`;
			break;
		case 'disabled':
			options.preset = `islands#green${iconType}DotIcon`;
			options.zIndex = 1;
			break;
		case 'active':
			options.preset = `islands#red${iconType}DotIcon`;
			options.zIndex = 3;
			break;
		case 'imported':
			options.preset = `islands#pink${iconType}DotIcon`;
			break;
		case 'need_approve_zone':
			options.preset = 'islands#nightCircleIcon';
			break;
		case 'approved_zone':
			options.preset = 'islands#darkOrangeCircleDotIcon';
			break;
		case 'searching_low':
			options.preset = `islands#yellow${iconType}Icon`;
			break;
		default:
			options.preset = `islands#red${iconType}DotIcon`;
	}
	return options;
};

export const zoneStatusColorDictionary: Record<string, string> = {
	searching: '#B51DFE',
	closed: '#595959',
	suspended: '#B3B3B3',
	approved: '#4598F7',
	agreement: '#ff9933',
	signed: '#40A9FF',
	inspection: '#40A9FF',
	repair: '#13C2C2',
	disabled: '#7ca186',
	imported: '#F371D1',
	need_approve_zone: '#993366',
	approved_zone: '#ff9933',
	searching_low: '#ff9933',
	active: '#1AAC02',
};

export const zoneStatusColorDictionaryDark = {
	searching: '#3E2069',
	closed: '#303030',
	suspended: '#B3B3B3',
	approved: '#203175',
	agreement: '#7C3118',
	signed: '#164C7E',
	inspection: '#164C7E',
	repair: '#146262',
	disabled: '#6F9412',
	imported: '#75204F',
	need_approve_zone: '#3E2069',
	approved_zone: '#7C3118',
	searching_low: '#7C3118',
	active: '#306317',
};

export const polygonOptionsGenerator = (
	storeStatusValue: Stores.Store['status'],
	eZoneOptions?: {
		delivery_type: Zones.Zone['delivery_type'];
		status: Zones.Zone['status'];
		color: string;
	},
	editMode?: boolean,
	fullScreenMode?: boolean
) => {
	const options: Record<string, any> = {
		editorDrawingCursor: 'crosshair',
		strokeWidth: 2,
		zIndex: 2,
	};
	if (eZoneOptions && !(editMode && fullScreenMode)) {
		options.fillColor = hexToRgbA(eZoneOptions.color, eZoneOptions.status === 'disabled' ? 0.1 : 0.2);
		options.strokeColor = eZoneOptions.status === 'disabled' ? hexToRgbA(eZoneOptions.color, 0.4) : eZoneOptions.color;
	} else if (editMode && fullScreenMode) {
		options.fillColor = 'rgba(237, 68, 67, 0.2)';
		options.strokeColor = '#ED4443';
	} else {
		const color = zoneStatusColorDictionary[storeStatusValue] ?? '#1AAC02';

		options.fillColor = hexToRgbA(color, 0.2);
		options.strokeColor = color;
	}
	switch (storeStatusValue) {
		case 'closed':
			options.zIndex = 1;
			break;
		case 'active':
		default:
			options.zIndex = 3;
	}
	return options;
};
