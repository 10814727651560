import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type * as Markings from '~types/markings';

export default {
	list: makeListFn<Markings.ListRequest, Markings.TrueMark>('/api/admin/true_marks/list'),
	trueApiCheck: (data: Markings.TrueApiCheckRequest, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: { data: { codes: Markings.TrueMarkApi[] } } }>(
			'/api/admin/true_marks/true_api_check',
			data,
			config
		),
};
