import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	subUsersBlock: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '0 26px',
		overflow: 'scroll',
		msOverflowStyle: 'none',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		'& > div': {
			paddingBlock: 10,
		},
		'& > div:hover': {
			cursor: 'pointer',
		},
		'& > div:first-child': {
			paddingInlineStart: 0,
		},
		'& .ant-skeleton-paragraph': {
			marginBlockEnd: 5,
		},
	},
	subUser: {
		inlineSize: 'auto',
		textAlign: 'center',
	},
}));
