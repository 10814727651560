import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	regradingContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginBlockStart: 16,
		inlineSize: 870,
	},
	productTitle: {
		position: 'relative',
		display: 'block',
		inlineSize: 433,
		color: theme.text.secondary,

		'&:before': {
			content: '""',
			display: 'block',
			inlineSize: 294,
			blockSize: 0,
			border: `2px dashed ${theme.border.disabled}`,
			position: 'absolute',
			insetBlockStart: '50%',
			insetInlineStart: 145,
			transform: 'translateY(-50%)',
		},
	},
	productTabs: {
		borderBlockEnd: `1px solid ${theme.border.disabled}`,
		marginBlockEnd: 16,
		marginInline: 0,
	},
	groupProducts: {
		marginBlockStart: 16,
		marginInlineStart: 12,
		paddingBlockEnd: 16,
		borderBlockEnd: `1px solid ${theme.border.disabled}`,

		'& span': {
			display: 'block',
			color: theme.text.icon,
		},

		'& .ant-col > div:last-child': {
			justifyContent: 'flex-start',
		},
	},
	selectFieldsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		position: 'relative',
	},
	inputSelectContainer: {
		display: 'flex',
		flexFlow: 'column',
		position: 'relative',

		'& .ant-col>label': {
			color: theme.text.icon,
			display: 'inline-block',
			marginBlockEnd: 4,
		},
	},
	labelContainer: {
		flex: 0,
		minBlockSize: 22,
	},
	inputSelect: {
		inlineSize: 417,
	},
	checkbox: {
		marginBlockStart: 16,
		color: theme.text.secondary,
		marginInlineStart: 12,

		'& .ant-checkbox-input': {
			color: theme.border.secondary,
		},
	},

	sectionLabel: {
		fontSize: 16,
		fontWeight: 500,
		color: theme.text.primary,
		marginInlineStart: 12,
	},

	fieldContainer: {
		marginBlockStart: 8,
		paddingInline: 12,

		'&.problem': {
			backgroundColor: theme.bgColor.table.problem,
		},
	},

	fieldLabel: {
		display: 'block',
		fontSize: 14,
		fontWeight: 400,
		color: theme.text.icon,

		'&.transparent': {
			color: 'transparent',
		},
	},

	fieldValuesContainer: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		columnGap: 16,
		marginBlockStart: 4,
		paddingBlockEnd: 8,
		color: theme.text.primary,

		'&:not(:last-child)': {
			borderBlockEnd: `1px solid ${theme.border.disabled}`,
		},

		'& > p': {
			marginBlock: 0,
		},
	},
	noValue: {
		color: theme.text.disabled,
	},
	selectIcon: {
		position: 'absolute',
		insetInlineStart: '50%',
		transform: 'translateX(-50%)',
		insetBlockEnd: 16,
		fill: theme.text.disabled,
	},
	compareSection: {
		marginBlockStart: 16,
	},
}));
