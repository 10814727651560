import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { makeSubPage, sendHeroElement } from '~shared/utils/rum';

export function useRumSpaCounter() {
	const location = useLocation();

	useLayoutEffect(() => {
		const pageName = location.pathname
			.split('/')
			.map((e) => (e.length === 44 ? 'id' : e))
			.join('.');

		makeSubPage(pageName);
		sendHeroElement();
	}, [location.pathname]);
}
