import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	entitiesList: {
		listStyleType: 'none',
		paddingInline: 0,
	},
	viewModeListEntities: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	editModeListEntities: {
		display: 'flex',
		justifyContent: 'space-between',
		minBlockSize: 32,
	},
	// эта штука нужна для кастомного отображения счетчика элементов
	entitiesCounter: {
		zIndex: 0,
		position: 'absolute',
		insetBlockStart: 5,
		insetInlineStart: 13,
	},
	select: {
		' .ant-select-selector': {
			backgroundColor: 'rgba(0,0,0,0)!important',
		},
	},
	entitiesCounterDisabled: {
		color: theme.text.disabled,
		zIndex: 2,
	},
	selectDisabled: {
		' .ant-select-selector': {
			backgroundColor: theme.bgColor.disabled + ' !important',
			marginBlockEnd: 10,
		},
	},
	selectTextError: {
		position: 'absolute',
		display: 'flex',
		color: theme.text.textField.errorMessage,
		fontSize: 12,
		insetInlineStart: 2,
		insetBlockEnd: -10,
		whiteSpace: 'pre',
	},
	selectError: {
		border: `1px solid ${theme.text.textField.errorMessage}!important`,
		borderRadius: 4,
	},
	tooltipIcon: {
		blockSize: 16,
		inlineSize: 16,
		color: theme.border.secondary,
		marginBlockStart: 8,
		marginInlineEnd: 8,
		'&:hover': {
			color: theme.text.btnLink,
		},
	},
	tooltipContainer: {
		marginInlineStart: 8,
	},
}));
