import { notification } from '@lavka/ui-kit';
import type { OptionProps } from 'antd/lib/select';
import type { JSX } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '~shared/hooks/useDebounce';

import type { SelectFieldProps } from './index';
import SelectField from './index';

export type SelectOption = {
	value: string;
	label: string;
};

interface Props extends SelectFieldProps {
	searchFunc: (value: any) =>
		| (
				| SelectOption
				| {
						value: string;
						text: string | JSX.Element;
				  }
		  )[]
		| Promise<any>;
	getUserValueFunc?: (...args: any[]) => any;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	allowClear?: boolean;
	paramsComponent?: JSX.Element;
	inputClassName?: string;
	customLayout?: (label: string, input: JSX.Element, params?: JSX.Element) => JSX.Element;
	hideLabel?: boolean;
	onSelect?: (...args: any) => void;
	onClear?: (...args: any) => void;
	afterLabel?: any;
	cache?: any;
	dataTest?: string;
	onlyView?: boolean;
	tagView?: boolean;
	loading?: boolean;
	virtual?: boolean;
	viewIconRight?: JSX.Element;
	prepareOptionProps?: (
		option: string,
		initialProps: Omit<OptionProps, 'children' | 'value'>,
		dictionary: Record<string, string> | []
	) => Omit<OptionProps, 'children'>;
	dict?: Record<string, string>;
	placeholder?: string;
	needInit?: boolean;
}

const SelectWithSearch = ({
	input,
	afterLabel,
	searchFunc,
	getUserValueFunc,
	colspan,
	allowClear,
	linkTo,
	paramsComponent,
	inputClassName,
	customLayout,
	onSelect,
	hideLabel,
	cache,
	dataTest,
	onlyView,
	tagView,
	loading,
	dict,
	viewIconRight,
	placeholder,
	onClear,
	needInit = false,
	...props
}: Props) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState<any[]>();
	const [value, setValue] = useState<string>('');
	const isFirstRun = useRef<boolean>(true);
	const [isLoading, toggleLoading] = useState<boolean>();
	const dictionary =
		dict ??
		options?.reduce((result, item) => {
			result[item.value] = item.text;
			return result;
		}, {});

	const debouncedSearchTerm = useDebounce(value, 500);

	const searchOptions = async () => {
		try {
			const searchResults = await searchFunc(value);
			setOptions(searchResults);
		} catch {
			notification.error({
				message: t('Не удалось выполнить поиск'),
			});
		}
	};

	useEffect(() => {
		if (needInit) {
			void searchOptions();
		}
	}, [needInit, JSON.stringify(dict)]);

	useEffect(() => {
		if (isFirstRun.current && !input.value) {
			isFirstRun.current = false;
			return;
		}
		if (debouncedSearchTerm) {
			void searchOptions();
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (getUserValueFunc && !dictionary?.[input.value]) {
			toggleLoading(true);
			if (allowClear && !input.value) {
				setOptions(() => {
					return [{ value: null, text: null, group: ['cluster'] }];
				});
				toggleLoading(false);
			} else {
				void (async function () {
					const data = await getUserValueFunc(input.value);
					setOptions((oldOptions) => {
						if (oldOptions) {
							const oldOptionsFiltered = oldOptions.filter((opt) => opt.value !== input.value);
							return [...oldOptionsFiltered, { value: input.value, text: data }];
						}
						return [{ value: input.value, text: data }];
					});
					toggleLoading(false);
				})();
			}
		}
	}, [input.value, cache]);

	return (
		<SelectField
			afterLabel={afterLabel}
			inputClassName={inputClassName}
			paramsComponent={paramsComponent}
			loading={isLoading || loading}
			dictionary={dictionary}
			showSearch
			hideLabel={hideLabel}
			customLayout={customLayout}
			onFocus={searchOptions}
			onSearch={setValue}
			colspan={colspan}
			allowClear={allowClear}
			input={input}
			linkTo={linkTo}
			onSelect={onSelect ? onSelect : undefined}
			dataTest={dataTest}
			onlyView={onlyView}
			tagView={tagView}
			viewIconRight={viewIconRight}
			placeholder={placeholder}
			onClear={onClear}
			{...props}
			options={options?.map((option) => option.value) || []}
			popupClassName={`data-test-select-dropdown-${dataTest?.replace(/\s/g, '-')}`}
		/>
	);
};

export default SelectWithSearch;
