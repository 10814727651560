import { Col, Row, Tooltip } from 'antd';
import type { Dayjs } from 'dayjs';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import type { CalendarParams } from '~constants/dataPageFields/types';
import { ReactComponent as QuestionCircleSolid } from '~images/icons/question-circle-solid.svg';
import { withTimezone } from '~shared/utils/datesUtils';
import Icon from '~shared/utils/icons';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';

import DatePicker from '../../DayJsComponents/DatePicker';
import { useStyles } from '../styles';
import { useStyles as useTextStyles } from '../TextField/styles';
import type { FieldProps } from '../types';
import { EmptyValue, getColSpan } from '../utils';

interface DateRangeFieldProps extends FieldProps {
	addMode?: boolean;
	colspan?:
		| Record<
				string,
				{
					span: number;
				}
		  >
		| number;
	showTime?: boolean;
	params?: CalendarParams;
	fieldRequired?: boolean;
	disabledDate?: (currentDate: Dayjs) => boolean;
	pickerParams?: Record<string, any>;
	className?: string;
	popupClassName?: string;
	sampleView?: boolean;
	onlyView?: boolean;
	additionalContent?: JSX.Element;
	customLayout?: (label: string, input: JSX.Element) => JSX.Element;
	placeholder?: [string, string];
}

const defaultParams = Object.freeze({
	timezone: undefined,
	onlyDate: true,
	withSeconds: false,
	alwaysShowYear: true,
	format: 'YYYY-MM-DD',
});

const DateRangeField = ({
	id,
	label,
	input,
	meta: { error },
	disabled,
	colspan,
	editMode,
	showTime = false,
	params = defaultParams,
	fieldRequired,
	customLayout,
	disabledDate,
	pickerParams,
	className,
	popupClassName,
	sampleView,
	onlyView,
	additionalContent,
	addMode,
	placeholder,
}: DateRangeFieldProps) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { classes: textClasses } = useTextStyles({ error });
	const { RangePicker } = DatePicker;

	const rowProps = sampleView
		? {
				align: 'bottom',
				gutter: [20, { xs: 0, sm: editMode ? 10 : 20, md: 20, lg: 20 }],
				justify: 'start',
			}
		: {
				align: 'top',
				gutter: [0, { xs: 0, sm: editMode ? 10 : 20 }],
				style: editMode ? { minHeight: 42 } : {},
			};

	const renderRangePicker = (
		<>
			<div className={cx(classes.inputContainer, 'wms-input-container', { error })}>
				<RangePicker
					placeholder={placeholder ?? [t('Дата начала'), t('Дата окончания')]}
					defaultValue={
						!addMode && Array.isArray(input.value)
							? [withTimezone(input.value[0], params?.timezone), withTimezone(input.value[1], params?.timezone)]
							: [undefined, undefined]
					}
					format={pickerParams?.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
					data-test={`data form ${label}`}
					className={cx(textClasses.input, className, {
						[textClasses.inputError]: !!error,
					})}
					onChange={
						showTime
							? (dateRange) => {
									input.onChange([
										withTimezone(dateRange?.[0], params?.timezone, true)?.format(params.format),
										withTimezone(dateRange?.[1], params?.timezone, true)?.format(params.format),
									]);
								}
							: (dateRange) => {
									input.onChange([
										withTimezone(dateRange?.[0]?.second(0), params?.timezone, true)?.format(params.format),
										withTimezone(dateRange?.[1]?.second(0), params?.timezone, true)?.format(params.format),
									]);
								}
					}
					disabledDate={disabledDate}
					disabled={disabled}
					popupClassName={popupClassName}
					{...pickerParams}
					value={
						input.value?.[0]
							? ([
									withTimezone(input.value[0], params?.timezone),
									withTimezone(input.value[1], params?.timezone),
								] as any)
							: [undefined, undefined]
					}
				/>
				{params.timezone && (
					<Tooltip title={params.timezoneMessage || t('Указывается во временной зоне объекта')}>
						<Icon component={QuestionCircleSolid} className={classes.tooltipIcon} />
					</Tooltip>
				)}
			</div>
			{error && (
				<span className={cx({ [classes.inputTextError]: error })} data-test={`data form ${label} error`}>
					{error}
				</span>
			)}
		</>
	);

	if (customLayout) {
		return customLayout(label, renderRangePicker);
	}

	return (
		// @ts-expect-error
		<Row {...rowProps}>
			<Col {...getColSpan(colspan)} className={classes.labelContainer}>
				<label
					className={cx(classes.label, {
						[classes.labelRequired]: editMode && fieldRequired,
					})}
					htmlFor={id}
				>
					{label}
				</label>
				<div className={classes.dotSeparator} />
			</Col>
			<Col {...getColSpan(colspan)} className={classes.readOnlyFieldContainer}>
				{!onlyView && editMode ? (
					renderRangePicker
				) : (
					<p className={classes.valueOnViewMode} data-test={`data form ${label}`}>
						{input.value?.[0] || input.value?.[1] ? (
							formatTimeAndYear(input.value, {
								timezone: params?.timezone,
								onlyDate: params.onlyDate,
								withSeconds: params.withSeconds,
								alwaysShowYear: params.alwaysShowYear,
							})
						) : (
							<EmptyValue />
						)}
					</p>
				)}
			</Col>
			{additionalContent && <Col span={24}>{additionalContent}</Col>}
		</Row>
	);
};

export default DateRangeField;
