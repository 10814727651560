import { Col, Radio, Row } from 'antd';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import type { PeriodState } from '../../healthTypes';
import { useStyles as useHealthStyles } from '../../styles';
import LittleText from '../LittleText';
import ComparisonTypeSelect from './ComparisonTypeSelect';
import { useStyles } from './styles';

type Props = {
	periodState: PeriodState;
	setPeriodState: Dispatch<SetStateAction<PeriodState>>;
};

const PeriodControls = ({ periodState, setPeriodState }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const { classes: healthClasses } = useHealthStyles();

	return (
		<div className={classes.periodControlsBlock}>
			<Row justify="space-between" align="middle">
				<Col flex="auto">
					<span className={healthClasses.sectorHeader}>{t('Общие метрики')}</span>
				</Col>
				<Col flex="90px">
					<LittleText>{t('Данные')}</LittleText>
				</Col>
				<Col flex="210px">
					<Radio.Group
						onChange={(e) => setPeriodState((pre) => ({ ...pre, sliceType: e.target.value }))}
						defaultValue={periodState.sliceType}
						buttonStyle="solid"
					>
						<Radio.Button value="hour">
							<span data-test="health slice type switch hour">{t('За час')}</span>
						</Radio.Button>
						<Radio.Button value="day">
							<span data-test="health slice type switch day">{t('За день')}</span>
						</Radio.Button>
					</Radio.Group>
				</Col>
				<Col flex="90px">
					<LittleText>{t('Сравнение')}</LittleText>
				</Col>
				<Col flex="150px">
					<ComparisonTypeSelect
						comparisonType={periodState.comparisonType}
						onChange={(value) => setPeriodState((pre) => ({ ...pre, comparisonType: value }))}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default PeriodControls;
