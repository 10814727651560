import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	container: {
		blockSize: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	logo: {
		inlineSize: 170,
		blockSize: 'auto',
		'& path': {
			fill: theme.text.logo,
		},
	},
	enLogo: {
		inlineSize: 220,
	},
	wmsLogo: {
		fontFamily: '"Helvetica Neue", Arial, sans-serif',
		fontSize: '45px',
		letterSpacing: 0.3,
		marginBlockEnd: -20,
	},
	deliveryLogo: {
		inlineSize: '100%',
	},
}));
