import { Skeleton } from 'antd';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PathsListPostEntity } from '~server-types/analytics/doc/api/admin/store_healths';

import { HealthContext } from '../../context';
import type { ViewLevelDetails } from '../../healthTypes';
import LittleText from '../LittleText';
import { useStyles } from './styles';

type Props = {
	subUsers: ViewLevelDetails[];
	onClick: (id: string, type: PathsListPostEntity) => void;
};

const SubUsers = ({ subUsers, onClick }: Props) => {
	const { classes } = useStyles();
	const [t] = useTranslation();
	const { loading } = useContext(HealthContext);

	const content = useMemo(() => {
		if (loading) {
			return <Skeleton data-test="health subusers loading" paragraph={{ rows: 1 }} active title={false} />;
		}

		if (!subUsers.length || subUsers.every((user) => !user.title)) {
			return <LittleText data-test="health no subusers">{t('Нет доступных пользователей')}</LittleText>;
		}

		return subUsers.map(({ title, id, type }) => (
			<div key={id} onClick={() => onClick(id, type)} className={classes.subUser}>
				<LittleText dataTest={`health subuser ${title}`}>{title}</LittleText>
			</div>
		));
	}, [subUsers, loading]);

	return <div className={classes.subUsersBlock}>{content}</div>;
};

export default SubUsers;
