import type { PropsWithChildren, ReactNode } from 'react';

interface Props {
	components: Array<(children?: ReactNode) => ReactNode>;
	children?: ReactNode;
}

export default function Compose(props: PropsWithChildren<Props>) {
	const { components } = props;

	let result = props.children;

	for (let i = (components ?? []).length - 1; i >= 0; i--) {
		const fn = components[i];
		result = fn(result);
	}

	return <>{result}</>;
}
