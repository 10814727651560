import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import type { Files } from '~types/files';

export default {
	load: (data: { file_meta_ids: string[] }) => axios.post<{ result: Files.File[] }>('/api/admin/file/load', data),

	/**
	 * @description
	 * Так как для FormData не указываются типы, ориентироваться на спецификацию бэкенда:
	 * https://a.yandex-team.ru/arcadia/taxi/lavka/platform/wms-backend/doc/api/admin/file.yaml
	 * */
	upload: (data: FormData, config?: AxiosAdditionalConfig) =>
		axios.post<{ file_meta_id: string }>('/api/admin/file/upload', data, config),

	download: (data: { file_meta_id: string }, config?: AxiosAdditionalConfig) =>
		axios.get<Blob>('/api/admin/file/download', { ...config, params: data, responseType: 'blob' }),

	delete: (data: { file_meta_id: string }, config?: AxiosAdditionalConfig) =>
		axios.post<EmptyObject>('/api/admin/file/delete', data, config),
};
