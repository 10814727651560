import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	block: {
		paddingBlock: 0,
		paddingInline: '4px',
		'&:last-child': {
			marginBlockEnd: 0,
		},
	},
	button: {
		inlineSize: 32,
		blockSize: 32,
		padding: 0,
	},
	buttonBordered: {
		'&.ant-btn': {
			border: `1px solid ${gray[4]}`,
		},
	},
	sectionTitle: {
		color: theme.text.disabled,
		margin: 0,
		paddingInlineStart: 18,
		lineHeight: '32px',
		fontSize: 12,
	},
	menu: {
		minInlineSize: 188,
	},
}));
