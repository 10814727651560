import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const Barcode = lazy(() => import('~shared/components/Barcode'));

const barcodeRoutes = makeRoutes([
	{
		path: '/barcode',
		element: <Barcode />,
	},
]);

export default barcodeRoutes;
