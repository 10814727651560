import { makeRoutes } from '~shared/utils/makeRoutes';

import { SupportSearch } from './index';

export default makeRoutes([
	{
		path: '/pomidorka',
		element: <SupportSearch />,
		permit: 'suptools_search',
	},
]);
