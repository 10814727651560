import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const ordersStatusTime = makeDynamicTranslations({
	waiting_collecting: tDynamic('Ожидают сборки'),
	collecting: tDynamic('Собираются'),
	ready_to_deliver: tDynamic('Готовы к выдаче'),
	delivering: tDynamic('Доставляются'),
});

export const extendedOrdersStatusTime = makeDynamicTranslations({
	processing: tDynamic('Заказов в работе'),
	completed: tDynamic('Выполненных заказов'),
	...ordersStatusTime,
});
