import { notification } from '@lavka/ui-kit';
import { memo, useCallback, useState } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { getDefaultValues } from '~constants/dataPageFields/fieldHelper';
import { makeWaveFields } from '~constants/dataPageFields/makeWaveFields';
import DataForm from '~shared/components/DataForm';
import ModalComponent from '~shared/components/ModalComponent';
import PrimaryButtonComponent from '~shared/components/PrimaryButtonComponent';
import { isObjectEmpty } from '~shared/utils/isObjectEmpty';

const MakeWaveBtn = () => {
	const [t] = useTranslation();
	const [showModal, toggleModal] = useState<boolean>(false);
	const [sending, toggleSending] = useState<boolean>(false);

	const form = useForm<{ limit: number }>({
		defaultValues: getDefaultValues(makeWaveFields),
		resolver: async (values) => {
			const errors: FieldErrors<typeof values> = {};

			if (values.limit) {
				const message = !(values.limit >= 1 && values.limit <= 500) ? t('Допустимые значения от 1 до 500') : undefined;

				if (message) {
					errors.limit = {
						type: 'required',
						message,
					};
				}
			}

			return {
				values: isObjectEmpty(errors) ? values : {},
				errors,
			};
		},
		mode: 'onChange',
	});

	const { handleSubmit } = form;

	const makeWave = useCallback(
		async (values: { limit: number }) => {
			try {
				await api.orders.makeWave({
					limit: Number(values.limit),
				});
				toggleModal(false);
				notification.success({
					message: t('Отправлено задание на создание {{count}} волн комплектации', { count: values.limit }),
				});
			} catch {
				notification.error({
					message: t('Не удалось отправить задание на создание волн комплектации'),
				});
			} finally {
				toggleSending(false);
			}
		},
		[showModal]
	);

	return (
		<>
			<PrimaryButtonComponent
				button={{
					type: 'button',
					action: () => toggleModal(true),
					text: t('Создать волну'),
					disabled: sending,
					dataTest: 'make wave btn',
				}}
				inHeader
			/>
			<ModalComponent
				title={t('Создание волн')}
				open={showModal}
				onOk={handleSubmit(makeWave)}
				onCancel={() => toggleModal(false)}
				okText={t('Создать')}
				cancelText={t('Закрыть')}
				okButtonProps={{
					className: 'data-test-make-wave-submit',
				}}
				className={`data-test-make-wave-modal-${showModal ? 'visible' : 'hidden'}`}
			>
				<DataForm
					addMode
					editMode
					formFields={makeWaveFields}
					onSubmit={makeWave}
					colspan={{
						xl: { span: 12 },
						lg: { span: 12 },
						sm: { span: 12 },
						xs: { span: 24 },
					}}
					hookForm={form}
				/>
			</ModalComponent>
		</>
	);
};

export default memo(MakeWaveBtn);
