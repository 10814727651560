import { type JSX } from 'react';

import { ReactComponent as CopyRegular } from '~images/icons/copy-regular.svg';
import { useStyles } from '~shared/components/DataForm/DataFormControls/styles';
import NotificationBtn from '~shared/components/Notifications/NotificationBtn';

import CopyButton from '../CopyButton';
import CopyHeaderButton from '../CopyHeaderButton';
import type { PrimaryButton } from '../PrimaryButtonComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent';

interface Props {
	title: string | JSX.Element;
	status?: JSX.Element;
	copyLink?: string;
	copyLinkTwo?: string;
	additionalButtons?: PrimaryButton[];
	successTextOne?: string;
	successTextTwo?: string;
	copyTooltipText?: string | undefined;
	className?: string;
	showChatSupport?: boolean;
	externalId?: string;
	inModal?: boolean;
}

const Title = ({
	status,
	title,
	additionalButtons,
	copyLink,
	copyLinkTwo,
	successTextOne,
	successTextTwo,
	copyTooltipText,
	className,
	showChatSupport,
	externalId,
	inModal,
}: Props) => {
	const { classes } = useStyles();

	return (
		<div className={classes.orderHeader}>
			<div data-test="modal title" className={className}>
				{title}
			</div>
			&nbsp;
			<div className={classes.status}>{status}</div>
			{additionalButtons && (
				<div className={classes.additionalButtonsBar}>
					{additionalButtons.map((button: PrimaryButton, index) => (
						// eslint-disable-next-line @eslint-react/no-array-index-key
						<PrimaryButtonComponent key={index} button={button} />
					))}
				</div>
			)}
			<div style={{ marginInlineStart: additionalButtons ? 0 : 'auto' }} className={classes.buttonsWrapper}>
				{/* Если включена опция с чатом, показываем кнопку чата поддержки */}
				{showChatSupport && (
					<div className={classes.supportChatButton}>
						<NotificationBtn orderId={copyLinkTwo} externalId={externalId} inHeader={!inModal} />
					</div>
				)}
				{!inModal && (
					<div className={classes.copyBtnBlock}>
						{copyLink && (
							<CopyHeaderButton successText={successTextOne} copyText={copyLink} tooltipText={copyTooltipText} />
						)}
						&nbsp;
						{copyLinkTwo && (
							<CopyHeaderButton successText={successTextTwo} copyText={copyLinkTwo} customIcon={CopyRegular} />
						)}
					</div>
				)}
				{inModal && (
					<div className={classes.copyBtnBlock}>
						{copyLink && <CopyButton successText={successTextOne} copyText={copyLink} tooltipText={copyTooltipText} />}
						&nbsp;
						{copyLinkTwo && <CopyButton successText={successTextTwo} copyText={copyLinkTwo} customIcon={CopyRegular} />}
					</div>
				)}
			</div>
		</div>
	);
};

export default Title;
