import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useTimer from '~shared/hooks/useTimer';

import { useStyles } from './styles';

const TableauCrushPage = () => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [timer] = useTimer(60);
	const navigate = useNavigate();

	useEffect(() => {
		if (timer === 0) {
			navigate('/tv-client/tableau');
		}
	}, [timer]);

	return (
		<div className={classes.layout}>
			<div data-test="tableau crush page" className={classes.paper}>
				<h1 className={classes.headerText}>
					{t('Что-то пошло не так. Табло перезагрузится через {{ count }} секунд', {
						count: timer,
					})}
				</h1>
			</div>
		</div>
	);
};

export default TableauCrushPage;
