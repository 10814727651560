import { Spin } from 'antd';
import type { JSX } from 'react';
import { Suspense } from 'react';
import type { RouteObject } from 'react-router/dist/lib/context';

import { RouterErrorBoundary } from '~shared/components/ErrorBoundary/RouterErrorBoundary';
import { ProtectedComponent } from '~shared/components/ProtectedComponent';
import { RouteCheckerComponent } from '~shared/components/RouteCheckerComponent';
import type { ExpName } from '~types/userConfig';

export type RouteConfig = {
	path: string;
	permit?: string | string[];
	exp?: ExpName | ExpName[];
	accessDeniedElement?: JSX.Element;
	ensureStoreIdExists?: boolean;
	element: JSX.Element;
	disableRouteChecker?: boolean;
	alternativeElement?: JSX.Element;
};

export function makeRoutes(configs: RouteConfig[]): RouteObject[] {
	return configs.map((config) => {
		const { element, path, permit, disableRouteChecker, ...restProps } = config;

		const routeElement = (
			<Suspense fallback={<Spin />}>
				<RouteCheckerComponent disableRouteChecker={disableRouteChecker}>
					{permit ? <ProtectedComponent permit={permit} element={element} {...restProps} /> : element}
				</RouteCheckerComponent>
			</Suspense>
		);

		return {
			path,
			element: routeElement,
			errorElement: <RouterErrorBoundary />,
		};
	});
}
