import { useTranslation } from 'react-i18next';

import { logout } from '~shared/utils/loginFunctions';

import { useStyles } from './styles';

const TableauLogoutPage = () => {
	const { cx, classes } = useStyles();
	const [t] = useTranslation();

	return (
		<div className={classes.layout}>
			<div data-test="tableau logout page" className={classes.paper}>
				<h1 className={classes.headerText}>
					{t('Похоже, у вас нет прав доступа к этой странице. Попробуйте выйти и авторизоваться заново')}
				</h1>
				<div
					className={cx(classes.authKeyBlock, classes.logoutButton)}
					onClick={() => logout(false, '/tv-client/tableau/auth')}
				>
					<span>{t('Выйти')}</span>
				</div>
			</div>
		</div>
	);
};

export default TableauLogoutPage;
