import type { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export const HeaderCenterPortal = ({ children }: PropsWithChildren<unknown>) => {
	const headerContainer = document.getElementById('header-container');

	if (!headerContainer) {
		return null;
	}

	return <>{createPortal(children, headerContainer)}</>;
};
