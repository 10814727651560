import generatePicker from 'antd/es/date-picker/generatePicker';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

import { translations } from '~i18n/libs/he_IL';

import { withLocale } from './utils';

// В календаре решение о первом дне решается исходя из локали.
// Для того чтобы показывать календарь с воскресения, фиксируем в нужных
// методах локаль he, остальные методы не трогаем, они влияют на переводы
const dayjsGenerateConfigSundayWeek = {
	...dayjsGenerateConfig,
	locale: {
		...dayjsGenerateConfig.locale,
		getWeekFirstDay: () => 0,
		getWeekFirstDate: (_: any, date: any) => withLocale(date, translations.dayjs, (date) => date.weekday(0)),
		getWeek: (_: any, date: any) => date.locale(translations.dayjs).week(),
	},
};

export const DatePickerSundayWeek = generatePicker<Dayjs>(dayjsGenerateConfigSundayWeek);

export function withSundayWeek(date: Dayjs, isSundayWeek: boolean, fn: (date: Dayjs) => Dayjs) {
	// Если нужно начинать неделю с воскресения, то используем локаль he в расчетах
	return withLocale(date, isSundayWeek ? translations.dayjs : dayjs.locale(), fn);
}
