import { Button, Col, Input, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getToken } from '~shared/utils/localStorage';
import { login } from '~shared/utils/loginFunctions';

import { useStyles } from './styles';

const EnterTokenPage = () => {
	const [t] = useTranslation();
	const [token, setToken] = useState(getToken());
	const { classes } = useStyles();
	const navigate = useNavigate();

	const applyToken = async () => {
		const path = await login({ token });
		navigate(path);
	};

	return (
		<Row justify="center">
			<Col span={6} data-test="enter token page">
				<div className={classes.paper}>
					<Input.TextArea
						id="outlined-multiline-static"
						value={token}
						data-test="token page token field"
						rows={6}
						onChange={(event) => setToken(event.target.value)}
					/>
					<Button color="primary" onClick={applyToken} data-test="token page apply token button">
						{t('Применить токен')}
					</Button>
				</div>
			</Col>
		</Row>
	);
};

export default EnterTokenPage;
