import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ mode: boolean }>()((theme, { mode }) => ({
	storekeeperWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		inlineSize: '100%',

		'& .ant-collapse-borderless': {
			backgroundColor: theme.transparentColor,
		},

		'& .ant-collapse-content > .ant-collapse-content-box': {
			paddingInline: 0,
			paddingBlockEnd: 6,
		},

		'&:not(:last-child)': {
			borderBlockEnd: mode ? 'none' : `1px solid ${theme.border.metricsTale}`,
		},
	},
	storekeeperNameWrapper: {
		display: 'grid',
		gridTemplateColumns: mode ? '24px 1fr' : '32px 1fr',
		gridTemplateAreas: "'avatar name'",
		fontSize: mode ? '0.9em' : 16,
		columnGap: mode ? 4 : 12,
		alignItems: 'center',
		paddingBlock: mode ? 6 : 12,
	},
	storekeeperName: {
		gridArea: 'name',
		fontWeight: mode ? 500 : 400,
		color: theme.text.widgetAmount,
		wordBreak: 'break-all',
	},
	storekeeperAccordion: {
		display: 'flex',
		inlineSize: '100%',

		'& .ant-collapse-item': {
			inlineSize: '100%',
		},

		'&.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover': {
			color: theme.text.widgetAmount,
		},

		'&.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header': {
			paddingBlock: 0,
			paddingInline: 0,
			alignItems: 'center',

			'& .ant-collapse-expand-icon': {
				blockSize: '100%',
				marginBlockStart: -4,
			},
		},

		'&.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow': {
			insetInlineEnd: mode ? 4 : 0,
			fontSize: 8,
			inlineSize: mode ? 8 : 32,
			blockSize: mode ? 8 : 32,
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: mode ? 'inherit' : theme.bgColor.mobileTableau.expandIcon,
			borderRadius: '50%',
		},
	},
	storekeeperAccordionHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	storekeeperTaskAmount: {
		display: 'inline-flex',
		justifyContent: 'flex-end',
		minInlineSize: mode ? 68 : 82,
		fontSize: mode ? 12 : 16,
		fontWeight: mode ? 500 : 400,
		color: mode ? theme.text.icon : theme.text.metricsValue,
		marginInlineStart: mode ? 4 : 10,
	},
	storekeeperLastSeenWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		insetInlineEnd: 0,
		insetBlockStart: 6,
		marginInlineStart: mode ? 6 : 'initial',
		minInlineSize: mode ? 'initial' : 140,
	},
	storekeeperLastSeen: {
		fontSize: mode ? 12 : 10,
		color: theme.text.metricsValue,
		inlineSize: mode ? 63 : 130,

		'@media (min-width: 480px)': {
			inlineSize: 130,
		},

		'@media (min-width: 1440px)': {
			inlineSize: 63,
		},
		'@media (min-width: 1740px)': {
			inlineSize: 130,
		},
	},
}));
