import { Button, Checkbox, Dropdown, Menu, Tooltip } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FilterRegular } from '~images/icons/filter-regular.svg';
import type { CheckProjectStatuses } from '~server-types/doc/api/models/check_project';
import Icon from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from './styles';

interface Props {
	valuesToShow: string[];
	onChange: (values: CheckProjectStatuses[]) => void;
	hideButtonText?: boolean;
	allValues: CheckProjectStatuses[];
	dictionary: Record<string, string>;
	dataTest?: string;
}

const Filter = ({ valuesToShow, onChange, allValues, dictionary, dataTest }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const { classes: commonClasses } = useCommonStyles();

	const [indeterminate, setIndeterminate] = useState(true);
	const [checkAll, setCheckAll] = useState(false);

	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		onChange(e.target.checked ? allValues : []);
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};

	const onCheckboxChange = (values: any) => {
		onChange(values);
		setIndeterminate(!!values.length && values.length < allValues.length);
		setCheckAll(values.length === allValues.length);
	};

	const menu = (
		<Menu className={classes.filterBlock} data-test="filter main menu">
			{allValues.length > 1 && (
				<Checkbox
					className={classes.checkboxItem}
					indeterminate={indeterminate}
					onChange={onCheckAllChange}
					checked={checkAll}
					onClick={(e) => e.stopPropagation()}
					data-test="check_all"
				>
					{t('Выбрать все')}
				</Checkbox>
			)}
			<Checkbox.Group value={valuesToShow} onChange={onCheckboxChange} className={classes.checkboxGroup}>
				{allValues.map((value) => (
					<Checkbox key={value} value={value} onClick={(e) => e.stopPropagation()} data-test={value}>
						{dictionary[value]}
					</Checkbox>
				))}
			</Checkbox.Group>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<Tooltip title={t('Фильтры')}>
				<Button
					className={commonClasses.btnIconBordered}
					style={{ marginInlineStart: 'auto' }}
					data-test={dataTest ? dataTest : 'stores map filters button'}
				>
					<Icon component={FilterRegular} />
				</Button>
			</Tooltip>
		</Dropdown>
	);
};

export default Filter;
