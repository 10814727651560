import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<void, 'copyBtn'>()((_theme, _params, classes) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			[`& .${classes.copyBtn}`]: {
				display: 'block',
			},
		},
	},
	copyBtnContainer: {
		inlineSize: 32,
		blockSize: 32,
		display: 'inline-block',
		marginInlineStart: 8,
	},
	copyBtn: {
		display: 'none',
	},
}));
