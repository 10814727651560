import type { AxiosResponse } from 'axios';

export default function mergeResponses<T extends Record<string, any>>(a: AxiosResponse<T>, b: AxiosResponse<T>) {
	if (!a || !b) {
		return a || b;
	}

	const keys = Object.keys(a.data);

	keys.forEach((key) => {
		if (Array.isArray(a.data[key]) && Array.isArray(b.data[key])) {
			a.data[key].push(...b.data[key]);
		}
	});
}
