import { notification } from '@lavka/ui-kit';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import api from '~/api';
import { useCacheStore } from '~cache/cache';
import type { ProductGroups } from '~types/productGroups';

type ProductGroupChildren = Record<ProductGroups.ProductGroup['group_id'], string[] | null>;
type State = {
	productGroupChildren: ProductGroupChildren;
	getProductGroupChildren: (groupId?: string) => Promise<void>;
};

const initialState = {
	productGroupChildren: {} as ProductGroupChildren,
};

export const userSettingsStore = create<State>()(
	devtools(
		persist(
			immer((set, get) => ({
				productGroupChildren: initialState.productGroupChildren,
				getProductGroupChildren: async (groupId = '') => {
					try {
						// Получаем данные
						const { data: newChildren } = await api.productGroups.hierarchy_list({
							parent_group_id: groupId, // если id пустая строка, запросится список корневых групп
						});

						const currentChildren = structuredClone(get().productGroupChildren);

						// Дополняем в мапу родительский ид группы - ид детей группы
						const groups = newChildren.result.reduce((map, group: ProductGroups.ProductGroupChild) => {
							const { group_id, parent_group_id } = group.product_group;

							if (parent_group_id) {
								if (Array.isArray(currentChildren[parent_group_id])) {
									// @ts-ignore
									currentChildren[parent_group_id].push(group_id);
								} else {
									currentChildren[parent_group_id] = [group_id];
								}
							}

							if (group_id) {
								currentChildren[group_id] = group.has_child ? [] : null;
							}
							if (group.product_group) {
								map.push(group.product_group);
							}
							return map;
						}, [] as ProductGroups.ProductGroup[]);

						// Сохраняем полученные группы в кеш
						useCacheStore.getState().addData({
							entityName: 'productGroups',
							data: groups,
						});
						// Сохраняем обновленный мапинг
						set({ productGroupChildren: currentChildren }, false, 'getProductGroupChildren');
					} catch (e) {
						notification.error(e);
					}
				},
			})),
			{ name: 'userSettingsStore' }
		)
	)
);

// Это в компонентах
export const useProductGroupChildren = () => userSettingsStore((state) => state.productGroupChildren);

// Это в функциях
export const getProductGroupChildren = (groupId?: string) =>
	userSettingsStore.getState().getProductGroupChildren(groupId);
