import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	container: {
		inlineSize: '100%',
		minBlockSize: '32px',
		zIndex: 900,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '13px',
		lineHeight: '14px',
	},
	containerWarning: {
		backgroundColor: theme.uiKit.semantic.statusColors.waitingBackground,
		color: theme.uiKit.semantic.statusColors.waitingText,
	},
	containerError: {
		backgroundColor: theme.uiKit.semantic.statusColors.errorBackground,
		color: theme.uiKit.semantic.error,
	},
	icon: {
		marginInlineEnd: '4px',
	},
}));
