import { Spin } from 'antd';
import type { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { isTvClientMode } from '~shared/utils/isTvClientMode';
import { useUserDomainConfigs } from '~zustand/userData';

import { LayoutHeader } from '../Header';
import { Sider } from '../Sider';
import { useStyles } from './styles';

export const LayoutContainer: FC<PropsWithChildren<unknown>> = ({ children }) => {
	const domainConfigs = useUserDomainConfigs();

	const location = useLocation();

	const isHeaderHidden =
		isTvClientMode() ||
		[
			'/login-done',
			'/barcode',
			'/business-card',
			'/tableau',
			'/mtableau',
			'/mtableau/operations',
			'/mtableau/orders',
			'/mtableau/couriers',
			'/mtableau/storekeepers',
			'/kitchen_new',
		].includes(location.pathname);

	const isMobileTableauMainScreen = location.pathname === '/mtableau';

	const isSiderHidden = isHeaderHidden || location.pathname === '/login';

	const { classes } = useStyles({ noPadding: isHeaderHidden });

	if (!domainConfigs.branding) {
		return (
			<div data-test="root">
				<Spin className={classes.spin} />;
			</div>
		);
	}

	return (
		<div className={classes.container}>
			{!isHeaderHidden && <LayoutHeader />}
			<div className={classes.content}>
				{(isMobileTableauMainScreen || !isSiderHidden) && <Sider />}
				<main className={classes.main}>{children}</main>
			</div>
		</div>
	);
};
