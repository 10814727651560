import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

import { arrayUniq } from '~shared/utils/arrayUniq';
import { checkPermit } from '~zustand/userData';

import type { ApiLoadProperties, EntityMap, EntityName } from './apiLoadProperties';

export let apiLoadProperties: ApiLoadProperties | EmptyObject = {};

export const initCache = (obj: typeof apiLoadProperties) => {
	apiLoadProperties = obj;
	useCacheStore.getState().initEntityNames();
};

export type Id = string | null | undefined;

export type Entity<T> = {
	ids: Id | Id[];
	_fields?: Readonly<(keyof T & string)[]>;
	skip?: boolean;
};

export type UseCacheStoreState = Partial<{
	[K in EntityName]: {
		data: Record<string, EntityMap[K]>;
		fields: Record<string, Set<string> | null>;
		loadingCounter: number;
	};
}>;

export type UseCacheStore = UseCacheStoreState & {
	initEntityNames: () => void;
	addData: <T extends EntityName>(params: {
		entityName: T;
		data: Partial<EntityMap[T]>[];
		fields?: null | ReadonlyArray<keyof EntityMap[T] & string>;
	}) => void;
	refreshData: <T extends EntityName>(entityName: T, entity: Entity<T>) => Promise<void>;
};

export const useCacheStore = createWithEqualityFn<UseCacheStore>()(
	immer((set, get) => ({
		initEntityNames: () => {
			// Устанавливаем поля stores: { data: {}, fields: {}, loadingCounter: 0, loaded: false } и т.д.
			const initState = Object.keys(apiLoadProperties).reduce((acc, entityName) => {
				acc[entityName as EntityName] = {
					data: {},
					fields: {},
					loadingCounter: 0,
				};

				return acc;
			}, {} as UseCacheStoreState);

			set(initState);
		},
		addData: <T extends EntityName>({
			entityName,
			data,
			fields = null,
		}: {
			entityName: T;
			data: Partial<EntityMap[T]>[];
			fields?: null | ReadonlyArray<keyof EntityMap[T] & string>;
		}): void => {
			if (!data.length) {
				return;
			}

			set((state) => {
				const idField = apiLoadProperties[entityName].idField;

				data.forEach((entityPatch) => {
					const id = entityPatch[idField as keyof EntityMap[T]] as string;
					const entity = state[entityName]!;

					entity.data[id] = {
						...(entity.data[id] ?? {}),
						...entityPatch,
					} as (typeof entity.data)[typeof id];

					if (fields) {
						if (entity.fields[id] === undefined) {
							entity.fields[id] = new Set<string>();
						}

						if (entity.fields[id] instanceof Set) {
							entity.fields[id]!.add(idField);
							fields.forEach((field) => entity.fields[id]!.add(field));
						}
						// Если fields[id] не Set, то он null, значит сущность
						// ранее уже была загружена полностью и обновление не требуется
					} else {
						entity.fields[id] = null;
					}
				});
			});
		},
		refreshData: async <T extends EntityName>(entityName: T, entity: Entity<T>): Promise<void> => {
			const { loadFunc, loadPermit, idField, ...rest } = apiLoadProperties[entityName] ?? {};

			if (!loadFunc || !checkPermit(loadPermit) || entity.skip) {
				return;
			}

			let ids = (Array.isArray(entity.ids) ? entity.ids : [entity.ids]).filter((e): e is string => !!e);
			ids = arrayUniq(ids);

			if (!ids.length) {
				return;
			}

			const prevData = get()[entityName]!;
			const idsToLoad: string[] = [];
			let fieldsToLoad: Set<string> | undefined = undefined;

			if (entity._fields) {
				fieldsToLoad = new Set<string>();

				ids.forEach((id) => {
					if (prevData.fields[id] === null) {
						return;
					}

					let shouldLoadEntity = false;

					entity._fields!.forEach((_field) => {
						if (_field !== idField && !prevData.fields[id]?.has(_field)) {
							fieldsToLoad!.add(_field);
							shouldLoadEntity = true;
						}
					});

					if (shouldLoadEntity) {
						idsToLoad.push(id);
					}
				});
			} else {
				idsToLoad.push(...ids.filter((id) => prevData.fields[id] !== null));
			}

			if (!idsToLoad.length || (fieldsToLoad && !fieldsToLoad.size)) {
				return;
			}

			set((state) => {
				if (state[entityName]) {
					state[entityName]!.loadingCounter++;
				}
			});

			try {
				const _fields = fieldsToLoad ? [...fieldsToLoad] : undefined;

				// @ts-expect-error
				const { data } = await loadFunc({
					[idField]: idsToLoad,
					...rest,
					_fields,
				});

				get().addData({
					entityName,
					data: data.result ?? [],
					// @ts-expect-error
					fields: _fields,
				});
			} catch {
			} finally {
				set((state) => {
					if (state[entityName]) {
						state[entityName]!.loadingCounter--;
					}
				});
			}
		},
	}))
);
