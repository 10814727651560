function deg2rad(deg: number) {
	return deg * (Math.PI / 180);
}

export default (coordsOne: [number, number], coordsTwo: [number, number]) => {
	const R = 6371; // Radius of the earth in km
	const dLat = deg2rad(coordsTwo[0] - coordsOne[0]); // deg2rad below
	const dLon = deg2rad(coordsTwo[1] - coordsOne[1]);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(coordsOne[0])) * Math.cos(deg2rad(coordsTwo[0])) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c; // Distance in km
	return d;
};
