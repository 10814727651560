import { Button } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { JSX } from 'react';

import { dateFormat } from '~constants/date';
import { useDirection } from '~i18n/hooks';
import DatePickerMondayWeek from '~shared/components/DayJsComponents/DatePicker';
import { DatePickerSundayWeek } from '~shared/components/DayJsComponents/DatePickerSundayWeek';
import { LeftOutlined, RightOutlined } from '~shared/utils/icons';

import { useStyles as usePaginationStyles } from '../TablePagination/styles';
import type { DisabledStatuses, SearchDataType } from './types';

interface Props {
	startWeekFromSunday: boolean;
	disabled?: DisabledStatuses;
	searchData: SearchDataType;
	setSearchData: (searchData: SearchDataType) => void;
	timezone?: string;
	formatCb?: (value: Dayjs) => string;
	view?: 'week';
	disabledDate?: (date: dayjs.Dayjs) => boolean;
	dropDownClass?: string;
	allowClear?: boolean;
	paginationAdditionalClass?: string;
	children?: JSX.Element;
}

export const DatePicker = ({
	startWeekFromSunday = false,
	disabled,
	searchData,
	setSearchData,
	timezone,
	formatCb,
	view = 'week',
	disabledDate,
	dropDownClass,
	allowClear = false,
	paginationAdditionalClass = '',
	children,
}: Props) => {
	const { cx, classes: paginationClasses } = usePaginationStyles();

	const DatePicker = startWeekFromSunday ? DatePickerSundayWeek : DatePickerMondayWeek;
	const PrevIcon = useDirection(LeftOutlined, RightOutlined);
	const NextIcon = useDirection(RightOutlined, LeftOutlined);

	const eventHandler = (date: Dayjs | null) => {
		setSearchData &&
			date &&
			setSearchData({
				...searchData,
				local_date: date.format(dateFormat),
			});
	};

	return (
		<>
			<Button
				data-test="datepicker prev"
				className={cx(paginationClasses.button, paginationClasses.buttonPrev, paginationAdditionalClass)}
				disabled={disabled?.prev}
				onClick={() => eventHandler(dayjs(searchData.local_date).subtract(1, view))}
			>
				<PrevIcon />
			</Button>
			{children ? (
				children
			) : (
				<DatePicker
					defaultValue={dayjs().tz(timezone)}
					allowClear={allowClear}
					value={dayjs(searchData.local_date).startOf('day')}
					format={formatCb}
					picker={view}
					disabledDate={disabledDate}
					popupClassName={dropDownClass}
					onChange={(date) => eventHandler(date)}
				/>
			)}

			<Button
				data-test="datepicker next"
				className={cx(paginationClasses.button, paginationClasses.buttonNext, paginationAdditionalClass)}
				disabled={disabled?.next}
				onClick={() => eventHandler(dayjs(searchData.local_date).add(1, view))}
			>
				<NextIcon />
			</Button>
		</>
	);
};
