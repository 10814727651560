import type { Dayjs } from 'dayjs';

// Вызываем функцию в нужной локали и меняем локаль обратно
export function withLocale(
	date: Dayjs,
	locale: Parameters<typeof Dayjs.prototype.locale>[0],
	fn: (date: Dayjs) => Dayjs
) {
	return fn(date.locale(locale)).locale(date.locale());
}
