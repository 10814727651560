import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	nextIconLink: {
		display: 'inline-flex',
		inlineSize: 16,
		blockSize: 16,
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 10,
		color: theme.text.primary,
		marginBlockStart: 6,
	},
}));
