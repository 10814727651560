import type { DependencyList } from 'react';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function useUuid(deps: DependencyList = [], skip = false): string {
	const uuid = useRef<string>(uuidv4());

	useEffect(() => {
		if (!skip) {
			uuid.current = uuidv4();
		}
	}, deps);

	return uuid.current;
}
