import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { KanbanColumnsData } from '~shared/utils/tableauOrdersMetrics';

export const columnLabelMap: Record<keyof KanbanColumnsData, string> = makeDynamicTranslations({
	waiting_collecting: tDynamic('Ожидают сборки'),
	collecting: tDynamic('Собираются'),
	ready_to_deliver: tDynamic('Готовы к выдаче'),
	delivering: tDynamic('Доставляются'),
});

export const weatherUpdateTimeout = 15 * 60_000; // 15 минут
