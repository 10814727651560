import dayjs from 'dayjs';

import { areArraysIntersect } from '~shared/utils/arrayDiff';
import isKitchenOrderType from '~shared/utils/isKitchenOrderType';
import type { Orders } from '~types/orders';
import { checkExp } from '~zustand/userData';

import { CANCELED_ORDER_TIMEOUT } from '../../components/Kitchen/constants';

const checkStatusKitchenExp = (order: Orders.Order, shelvesIds: string[]): boolean => {
	const statusesArr = ['processing', 'canceled'];
	if (checkExp('exp_cook_asap') || checkExp('exp_new_order')) {
		statusesArr.push('request');
	}
	if (!statusesArr.includes(order.status)) {
		return false;
	}

	if (order.status === 'canceled' && order.status_time.canceled) {
		const timeSinceCancel = dayjs(dayjs()).diff(order.status_time.canceled);

		if (timeSinceCancel > CANCELED_ORDER_TIMEOUT) {
			return false;
		}
	} else if (order.target === 'canceled') {
		const localStorageKey = `canceled_order_id_${order.order_id}`;
		const cancelTime = localStorage.getItem(localStorageKey);
		const now = dayjs().toString();

		if (!cancelTime) {
			localStorage.setItem(localStorageKey, now);
			return true;
		}

		const timeSinceCancel = dayjs(now).diff(cancelTime);
		return timeSinceCancel <= CANCELED_ORDER_TIMEOUT;
	}

	return areArraysIntersect(shelvesIds, order.shelves);
};

const checkStatusKitchenOld = (order: Orders.Order, shelvesIds: string[]): boolean => {
	if (!['request', 'processing', 'completed'].includes(order.status)) {
		return false;
	}

	return areArraysIntersect(shelvesIds, order.shelves);
};

export const isKitchenOrder = (order: Orders.Order, shelvesIds: string[]): boolean => {
	if (!isKitchenOrderType(order.type)) {
		return false;
	}

	return checkExp('exp_chef_interface')
		? checkStatusKitchenExp(order, shelvesIds)
		: checkStatusKitchenOld(order, shelvesIds);
};
