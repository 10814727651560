import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	timePicker: {
		borderRadius: 4,
		'& .rc-time-picker-input': {
			blockSize: 32,
			paddingInlineStart: 8,
			fontSize: '14px',
			flexGrow: 1,
			paddingBlock: '12px',
			paddingInline: '6px',
			background: 'none',
			border: '1px solid #d9d9d9',
			borderRadius: 4,
			color: theme.text.primary,
			'&:disabled': {
				borderColor: 'transparent',
			},
			'&:focus': {
				borderColor: '#40a9ff',
				outline: 0,
				boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
			},
		},
	},
	title: {
		marginBlock: '20px',
		marginInline: '0',
		fontWeight: 600,
		fontSize: 16,
		color: theme.text.primary,
	},
	clearIcon: {
		cursor: 'pointer',
		position: 'absolute',
		fontSize: 14,
		insetInlineEnd: 3,
		insetBlockStart: 10,
		color: theme.text.secondary,
	},
	noValueText: {
		color: theme.text.primary,
		margin: 0,
		marginBlockEnd: 10,
	},
	addButton: {
		paddingInlineStart: 8,
		marginBlockStart: 16,
	},
	label: {
		fontSize: '14px',
		fontWeight: 500,
		display: 'block',
		marginBlockEnd: 16,
	},
	container: {
		maxInlineSize: 600,
		paddingBlockEnd: 20,
	},
	tooltipIcon: {
		position: 'static',
		marginInlineStart: 8,
	},
	alert: {
		marginBlockEnd: 16,
	},
}));
