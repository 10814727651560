import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const errorsCode: Record<string, string> = makeDynamicTranslations({
	BAD_REQUEST: tDynamic('Неправильно заполнена форма'),
	ER_CONFLICT: tDynamic('Имеются документы на исполнении'),
	ER_TESTING_ONLY: tDynamic('Только для тестирования'),
	ER_READONLY_AFTER_CREATE: tDynamic('После создания объекта поле доступно только для чтения'),
	ER_TRY_LATER: tDynamic('Попробуйте еще раз позже'),
	ER_MISDIRECTED_REQUEST: tDynamic('Запрос направлен не туда'),
	ER_EXISTS: tDynamic('Уже существует'),
	ER_NOT_EXISTS: tDynamic('Не существует'),
	ER_DUPLICATE: tDynamic('Найден дубликат'),
	ER_TOKEN_EXPIRED: tDynamic('Токен просрочен'),
	ER_TVM: tDynamic('Ошибка TVM'),
	ER_EXTERNAL_SERVICE: tDynamic('Ошибка во внешнем сервисе'),
	ER_UNKNOWN_DEVICE: tDynamic('Неизвестное устройство'),
	ER_INVITE_EMAIL: tDynamic('Неправильный email в приглашении'),
	ER_WRONG_INVITE_ROLE: tDynamic('Роль не подходит для создания приглашений'),
	ER_INVATE_EXPIRED: tDynamic('Приглашение устарело'),
	ER_WRONG_INVITE_SOURCE: tDynamic('Попытка создать приглашение без прикрепления к организации'),
	ER_APPROVE_REQUIRED: tDynamic('Необходимо одобрение'),
	ER_USER_CANNOT_APPROVE: tDynamic('Пользователь не может одобрять'),
	ER_ALREADY_APPROVED: tDynamic('Уже подтверждено'),
	ER_ALREADY_PROCESSING: tDynamic('Уже исполняется'),
	ER_ALREADY_DONE: tDynamic('Уже выполнено'),
	ER_FILE_PARSE: tDynamic('Неверный формат файла'),
	ER_EXPIRED: tDynamic('Объект устарел'),
	ER_STORE_MODE: tDynamic('Невозможно в текущем режиме работы склада'),
	ER_INVENTORY_MODE: tDynamic('Недоступно в режиме инвентаризации'),
	ER_INVENTORY_LOCKED_MODE: tDynamic('Операция недоступна'), // TODO Ref: wms-front/src/components/OrdersPage/OrdersDataPage/utils.tsx
	ER_INVENTORY_EXISTS: tDynamic('Документ инвентаризации уже есть'),
	ER_NOT_INVENTORY_MODE: tDynamic('Доступно только в режиме инвентаризации'),
	ER_STORE_IS_INACTIVE: tDynamic('Склад не работает'),
	ER_STORE_LOSE: tDynamic('Пользователь потеряет привязку к складу'),
	ER_COMPANY_ACCESS: tDynamic('Нет доступа к компании'),
	ER_HAS_PROCESSING: tDynamic('Имеются документы на исполнении'),
	ER_HAS_ORDERS: tDynamic('Имеются документы, которые надо исполнить'),
	ER_PRODUCT_DUPLICATE: tDynamic('Найден дубликат товара'),
	ER_BAD_PRODUCTS_OR_SHELVES: tDynamic('Что-то не так с продуктами или полками'),
	ER_BAD_REQUIRED: tDynamic('Поле заполнено некорректно'),
	ER_BAD_SHELVES: tDynamic('Что-то не так с полками'),
	ER_COUNT_OR_RESERVE: tDynamic('Недостаточно продуктов или они зарезервированы'),
	ER_EMPTY_MOVE_SECTION: tDynamic('Пустая секция перемещения'),
	ER_EXECUTER_REQUIRED: tDynamic('Нужен исполнитель'),
	ER_NO_COMMENT: tDynamic('Отсутствует комментарий'),
	ER_NO_STORE: tDynamic('Отсутствует склад'),
	ER_ORDER_IS_NOT_PROCESSING: tDynamic('Документ не находится в обработке'),
	ER_ORDER_DONE_IN_PROGRESS: tDynamic('Документ в процессе завершения'),
	ER_ORDER_TARGET_CHANGE: tDynamic('Нельзя менять целевой статус документа'),
	ER_ORDER_IS_NOT_FAILED: tDynamic('Документ не закрыт с ошибкой'),
	ER_ORDER_IS_NOT_REANIMATED: tDynamic('Невозможно восстановить документ'),
	ER_ORDER_NOT_FOUND: tDynamic('Документ не найден'),
	ER_PRODUCTS_IS_EMPTY: tDynamic('Список товаров пуст'),
	ER_PRODUCT_NOT_FOUND: tDynamic('Товар не найден'),
	ER_SHELF_NOT_FOUND: tDynamic('Полка не найдена'),
	ER_ITEM_NOT_FOUND: tDynamic('Экземпляр не найден'),
	ER_SHELVES_IS_EMPTY: tDynamic('Список полок пуст'),
	ER_STORE_EXISTS: tDynamic('Склад уже существует'),
	ER_NO_ITEMS_EXPIRED: tDynamic('Нет просроченных экземпляров'),
	ER_SUGGEST_CONDITION_ALL: tDynamic('Количество отличается от задания'),
	ER_SUGGEST_WRONG_PRODUCT_ID: tDynamic('Неверный ID продукта'),
	ER_SUGGEST_VALID_REQUIRED: tDynamic('Срок годности не определён'),
	ER_SUGGEST_WEIGHT_REQUIRED: tDynamic('Вес не определён'),
	ER_SUGGEST_COUNT: tDynamic('Неверное количество'),
	ER_SUGGEST_REASON_REQUIRED: tDynamic('Необходимо указать причину'),
	ER_SUGGEST_ERROR_DENIED: tDynamic('Нельзя закрыть задание с ошибкой'),
	ER_SUGGEST_REASON_SHELF_NOT_FOUND: tDynamic('Указанная в reason полка не найдена'),
	ER_SUGGEST_IS_NOT_EDITABLE: tDynamic('Задание нельзя редактировать'),
	ER_SUGGEST_IS_NOT_CANCELABLE: tDynamic('Задание нельзя отменять'),
	ER_SUGGEST_IS_NOT_DONE: tDynamic('Задание завершено'),
	ER_UNKNOWN_PRODUCT: tDynamic('Неизвестный товар'),
	ER_UNKNOWN_USER: tDynamic('Неизвестный пользователь'),
	ER_WRONG_ACK_USER: tDynamic('Неверный пользователь в согласии'),
	ER_WRONG_USER_FOR_ORDER: tDynamic('Данный пользователь не может работать с документом'),
	ER_WRONG_REASON: tDynamic('Неверная причина'),
	ER_NO_SUITABLE_GATE: tDynamic('Нет подходящих ворот'),
	ER_SLOT_ALREADY_RESERVED: tDynamic('Слот уже зарезервирован'),
	ER_SLOT_NOT_RESERVED: tDynamic('Слот не зарезервирован'),
	ER_SLOT_ALREADY_USED: tDynamic('Слот уже занят'),
	ER_DUPLICATE_REQUIRED: tDynamic('В списке товаров есть дубли'),
	ER_DUPLICATE_SIGNAL: tDynamic('Дубликат сигнала'),
	ER_ORDERS_IN_PROGRESS: tDynamic('Есть незакрытые документы'),
	ER_BAD_TIME_RANGE: tDynamic('Неправильный временной диапазон'),
	ER_STORE_COURIER_SHIFT_SETUP: tDynamic('Противоречит настройкам лавки/кластера'),
	ER_COURIER_SHIFT: tDynamic('Нельзя предлагать смену уже подтвержденную курьером'),
	ER_COURIER_SHIFT_RO_FIELD: tDynamic('В текущем статусе поле доступно только для чтения'),
	ER_COURIER_SHIFT_RO: tDynamic('Смена доступна только для чтения'),
	ER_COURIER_SHIFT_INVALID_STATUS: tDynamic('Неверный статус смены'),
	ER_WMS_ONLY_ORDER: tDynamic('Документом можно управлять только из WMS'),
	ER_SCHET_VALUE_ERROR: tDynamic('Некорректные данные в параметрах'),
	ER_SCHET_CREATOR_APPROVE: tDynamic('Автор задания не может его одобрять'),
	ER_SCHET_SCHEDULE_EMPTY: tDynamic('Не указано расписание'),
	ER_SCHET_STATUS_NOT_PAUSED: tDynamic("Cтатус должен быть 'На паузе'"),
	ER_SCHET_STATUS_NOT_PENDING: tDynamic("Cтатус должен быть 'Ожидает исполнения'"),
	ER_COURIER_NOT_FOUND: tDynamic('Курьер не найден'),
	ER_EXCEEDING_DURATION_DAY: tDynamic('Превышен дневной лимит'),
	ER_EXCEEDING_DURATION_WEEK: tDynamic('Превышен недельный лимит'),
	ER_COURIER_SHIFT_INTERSECTION: tDynamic('Пересечение смен'),
	ER_COURIER_BLOCKED: tDynamic('Курьер заблокирован'),
	ER_FILE_TOO_LARGE: tDynamic('Слишком большой файл'),
	ER_GONE: tDynamic('Смена не исполняется'),
	ER_COURIER_RETURNING: tDynamic('Курьер возвращается с заказа'),
	ER_DESCENDANTS_IS_NOT_DONE: tDynamic('Акт невозможно сгенерировать, пока не завершены дочерние ордера'),
	ER_PRODUCT_IN_KITCHEN: tDynamic('Нельзя добавлять продукты из ассортимента кухни'),
	ER_COURIER_SHIFT_UNDERAGE_LIMIT: tDynamic('Количество несовершеннолетних на Лавке достигло лимита'),
	ER_REGRADING_PRODUCT: tDynamic('Продукты отличаются'),
	ER_BAD_PRODUCT_GROUPS: tDynamic('Продукты имеют разные группы продуктов'),
	ER_BAD_PRODUCT_STATUS: tDynamic("Старый продукт должен иметь статус 'Архивный'"),
	ER_BAD_PRODUCTS_EQUALS: tDynamic('Продукты не могут быть одинаковыми'),
	ER_BAD_PRODUCT_DUPLICATE: tDynamic('Продукт уже используется в текущем ордере'),
	ER_BAD_PRODUCTS_SCOPE: tDynamic('Продукты не могут принадлежать разным компаниям'),
	ER_EMPLOYEE_NUMBER_CONFLICT: tDynamic('Пользователь с таким табельным номером уже существует'),
});
