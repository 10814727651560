import { useEffect, useRef } from 'react';

import { useCache } from '~cache/useCache';
import { YmapsInitializer } from '~shared/utils/ymapsInitializer';
import type { Zones } from '~types/zones';

import { statusStringColorDictionary } from '../../pageConfig';
import { getListeners, placemarkBalloonBody, placemarkBalloonHeader } from '../Balloon';
import type { OrderWithCoords } from '../index';
import { getPreset, getShortNumber } from '../utils';

declare let ymaps: any;

export interface OrderWithExtra extends OrderWithCoords {
	isBatched?: boolean;
	batchedWith?: string[];
}

type Props = {
	mapState: {
		center: number[];
		zoom: number;
		controls: string[];
	};
	orders: Record<string, OrderWithExtra>;
	zone: Zones.Zone | null;
	setUnassignCourierModalOrderId?: (orderId?: string) => void;
	setOrderModalOrderId?: (orderId?: string) => void;
};

const YandexOrdersMap = ({ mapState, orders, zone, setUnassignCourierModalOrderId, setOrderModalOrderId }: Props) => {
	const mapContainer = useRef<HTMLDivElement | null>(null);
	const map = useRef<any>();
	const clusterer = useRef<any>();
	const ordersOnMap = useRef({} as Record<string, ymaps.Placemark>);
	const { couriersBrief } = useCache({
		couriersBrief: [],
	});

	useEffect(() => {
		map.current = new ymaps.Map(mapContainer.current, mapState, {
			autoFitToViewport: 'always',
		});
		const storePoint = new ymaps.Placemark(mapState.center, {}, { preset: 'islands#redCircleDotIconWithCaption' });
		map.current.geoObjects.add(storePoint);
		clusterer.current = new ymaps.Clusterer({
			clusterIconLayout: 'default#pieChart',
			clusterIconPieChartRadius: 25,
			clusterIconPieChartCoreRadius: 10,
			clusterIconPieChartStrokeWidth: 3,
			clusterDisableClickZoom: false,
			clusterOpenBalloonOnClick: true,
			clusterBalloonContentLayoutWidth: 360,
			clusterBalloonLeftColumnWidth: 120,
		});

		map.current.geoObjects.add(clusterer.current);

		return () => map.current?.destroy();
	}, []);

	useEffect(() => {
		if (zone) {
			const storeZone = new ymaps.Polygon(
				zone.zone.geometry.coordinates[0],
				{},
				{ fillColor: '#389E0D40', strokeColor: '#389E0D90' }
			);
			map.current.geoObjects.add(storeZone);
		}
	}, [zone]);

	useEffect(() => {
		Object.values(orders).forEach((order) => {
			if (!order || !order.coordinates) {
				return;
			}

			const balloonHeader = placemarkBalloonHeader({
				orderId: order.order_id,
				docNumber: `${order.isBatched ? '🔗 ' : ''}${order.doc_number}`,
			});

			const balloonBody = placemarkBalloonBody({
				order,
				courier: order?.courier_id ? couriersBrief[order.courier_id] : undefined,
			});

			const [callbackOnOpen, callbackOnClose] = getListeners({
				type: 'yandex',
				orderId: order.order_id,
				setUnassignCourierModalOrderId,
				setOrderModalOrderId,
			});

			const CustomLayoutClass = ymaps.templateLayoutFactory.createClass(balloonHeader + balloonBody, {
				build: function () {
					CustomLayoutClass.superclass.build.call(this);
					const unassignCourierButton = document.getElementById(`assign-button-${order.order_id}`);
					// eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
					unassignCourierButton?.addEventListener('click', this.onAssignButtonClick);

					const orderButton = document.getElementsByClassName(`order-button-${order.order_id}`)[0] as HTMLButtonElement;
					if (orderButton) {
						orderButton.style.fontSize = '15.6px';
						orderButton.style.fontWeight = 'bold';
						// eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
						orderButton.addEventListener('click', this.onOrderButtonClick);

						const buttonIcon = orderButton.getElementsByClassName('icon')[0] as HTMLButtonElement;
						buttonIcon.style.display = 'inline';
					}
				},

				clear: function () {
					const unassignCourierButton = document.getElementById(`assign-button-${order.order_id}`);
					unassignCourierButton?.removeEventListener('click', this.onAssignButtonClick);

					const orderButton = document.getElementsByClassName(`order-button-${order.order_id}`)[0];
					orderButton?.removeEventListener('click', this.onOrderButtonClick);
					CustomLayoutClass.superclass.clear.call(this);
				},

				onAssignButtonClick: () => setUnassignCourierModalOrderId?.(order?.order_id),
				onOrderButtonClick: () => setOrderModalOrderId?.(order?.order_id),
			});

			if (order.order_id in ordersOnMap.current) {
				ordersOnMap.current[order.order_id].options.set('preset', getPreset('yandex', order.eda_status));
				ordersOnMap.current[order.order_id].properties.set('balloonContentHeader', balloonHeader);
				ordersOnMap.current[order.order_id].properties.set(
					'iconContent',
					`${order.isBatched ? '🔗 ' : ''}${getShortNumber(order.doc_number)}`
				);
			} else {
				const orderPoint = new ymaps.Placemark(
					order.coordinates,
					{
						clusterCaption: balloonHeader,
						balloonContentBody: balloonBody,
						iconContent: `${order.isBatched ? '🔗 ' : ''}${getShortNumber(order.doc_number)}`,
					},
					{
						preset: getPreset('yandex', order.eda_status),
						iconColor: statusStringColorDictionary[order.eda_status],
						balloonContentLayout: CustomLayoutClass,
						balloonPanelMaxMapArea: 0,
					}
				);
				ordersOnMap.current[order.order_id] = orderPoint;
				clusterer.current.add(orderPoint);

				clusterer.current.balloon.events.add(['open', 'click'], callbackOnOpen).add('close', callbackOnClose);
			}
		});

		Object.keys(ordersOnMap.current).forEach((orderId) => {
			if (!(orderId in orders)) {
				clusterer.current.remove(ordersOnMap.current[orderId]);
				delete ordersOnMap.current[orderId];
			}
		});
	}, [orders]);

	return <div data-test="map" ref={mapContainer} style={{ inlineSize: '100%', blockSize: '500px' }} />;
};

const YandexOrdersMapWrapped = (props: Props) => (
	<YmapsInitializer>
		<YandexOrdersMap {...props} />
	</YmapsInitializer>
);

export default YandexOrdersMapWrapped;
