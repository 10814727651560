import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCache } from '~cache/useCache';
import { getField } from '~constants/dataPageFields/fieldHelper';
import type { SchetTaskHandlerType } from '~constants/dataPageFields/schetTaskFields';
import { schetTaskBasicFields } from '~constants/dataPageFields/schetTaskFields';
import type * as FieldTypes from '~constants/dataPageFields/types';
import IdReplacer from '~shared/components/IdReplacer';
import { useCheckPermit } from '~zustand/userData';

import { SchetsDataPage } from './Base';

type Props = {
	addMode?: boolean;
	editMode?: boolean;
};

const handlerFieldOptions: SchetTaskHandlerType[] = [
	'sync_products',
	'update_vat',
	'sync_price_lists',
	'calc_salaries',
	'replenish_kitchen_assortment',
];

export const CompanySchetsDataPage = (props: Props) => {
	const [t] = useTranslation();
	const isPermitCompaniesSeek = useCheckPermit('companies_seek');
	const params = useParams<{ company_id: string }>();

	useCache({
		companies: {
			ids: params.company_id,
			_fields: ['title'],
		},
	});

	const baseFields = useMemo(() => {
		const fields = schetTaskBasicFields;

		const companyField = getField(fields, t('Основное'), 'company_id') as FieldTypes.TextField | undefined;
		if (companyField) {
			companyField.defaultValue = params.company_id;
			companyField.render = (value: string) => (
				<IdReplacer
					id={value}
					type="companies"
					keyReplaceOn="title"
					link={isPermitCompaniesSeek ? `/companies/${value}` : undefined}
				/>
			);
		}

		const scopeField = getField(fields, t('Основное'), 'scope');
		if (scopeField) {
			scopeField.defaultValue = 'company';
		}

		return fields;
	}, [params.company_id]);

	const baseUrl = `/companies/${params.company_id}/schets`;

	return (
		<SchetsDataPage {...props} baseUrl={baseUrl} baseFields={baseFields} handlerFieldOptions={handlerFieldOptions} />
	);
};
