import { mobileMaxWidth } from '~constants/breakPoints';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	statBlock: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		blockSize: 90,
		boxShadow: theme.softShadow,
		borderRadius: 8,
		paddingBlock: '6px 8px',
		paddingInline: '8px',

		[`@media (min-width:${mobileMaxWidth}px)`]: {
			minInlineSize: 100,
			maxInlineSize: 200,
		},
	},
	topStatRow: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: 4,
	},
	statTitle: {
		fontSize: theme.uiKit.semantic.typography.caption2,
		lineHeight: theme.uiKit.semantic.typography.lineHeightCaption2,
	},
	statDiff: {
		display: 'inline-flex',
		flexWrap: 'nowrap',
		justifyContent: 'flex-end',
		inlineSize: '100%',
		fontSize: theme.uiKit.semantic.typography.caption2,
		lineHeight: theme.uiKit.semantic.typography.lineHeightCaption2,
		textAlign: 'end',
	},
	statSign: {
		marginInlineEnd: 5,
	},
	redText: {
		color: theme.text.statusRed,
	},
	greenText: {
		color: theme.text.statusLime,
	},
	statValue: {
		fontWeight: 500,
		fontSize: theme.uiKit.semantic.typography.caption1,
		lineHeight: theme.uiKit.semantic.typography.lineHeightCaption1,
	},
	loader: {
		'*': { marginBlockEnd: 0 },
	},
	metricInRed: {
		backgroundColor: theme.bgColor.statusRed,
	},
	statInfo: {
		display: 'flex',
		flexDirection: 'column',
	},
	goalText: {
		fontSize: '0.9em',
		color: theme.text.light,
		lineHeight: 0.9,
	},
	goalMetricInRed: {
		color: theme.text.error,
	},
}));
