import { notification } from '@lavka/ui-kit';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import ModalComponent from '~shared/components/ModalComponent';

export type ActionModalType = 'start' | 'force_run' | 'pause' | 'delete';

interface Props {
	setModalType: (type: ActionModalType | null) => void;
	modalType: ActionModalType | null;
	schetTaskId: string;
	setSchetUpdate: () => void;
}

export function SchetActionModal({ setModalType, modalType, schetTaskId, setSchetUpdate }: Props) {
	const [t] = useTranslation();
	const [sending, toggleSending] = useState(false);

	const schetActions = useCallback(async (handler: ActionModalType) => {
		toggleSending(true);
		let successMessage: string;
		let errorMessage: string;
		switch (handler) {
			case 'start':
				successMessage = t('Задание запущено по расписанию');
				errorMessage = t('Не удалось запустить задание по расписанию');
				break;
			case 'force_run':
				successMessage = t('Задание запущено не дожидаясь расписания');
				errorMessage = t('Не удалось запустить задание не дожидаясь расписания');
				break;
			case 'pause':
				successMessage = t('Задание поставлено на паузу');
				errorMessage = t('Не удалось поставить задание на паузу');
				break;
			case 'delete':
				successMessage = t('Удаление задания');
				errorMessage = t('Не удалось удалить задание');
				break;
		}
		try {
			await api.schets[handler]({
				schet_task_id: schetTaskId,
			});

			setModalType(null);
			setSchetUpdate();
			notification.success({
				message: successMessage,
			});
		} catch {
			notification.error({
				message: errorMessage,
			});
		} finally {
			toggleSending(false);
		}
	}, []);

	const dialogModalContent = useMemo(() => {
		let title = '';
		let text = '';
		switch (modalType) {
			case 'pause':
				title = t('Постановка задания на паузу');
				text = t('Вы уверены, что хотите поставить задание на паузу?');
				break;
			case 'start':
				title = t('Запуск задания');
				text = t('Вы уверены, что хотите запустить задание по расписанию?');
				break;
			case 'force_run':
				title = t('Запуск задания');
				text = t('Вы уверены, что хотите запустить задание не дожидаясь расписания?');
				break;
			case 'delete':
				title = t('Удаление задания');
				text = t('Вы уверены, что хотите удалить задание?');
				break;
		}
		return {
			title,
			text,
		};
	}, [modalType]);

	return (
		<ModalComponent
			open={!!modalType}
			title={dialogModalContent.title}
			onCancel={() => setModalType(null)}
			okText={t('Да')}
			cancelText={t('Отмена')}
			onOk={() => modalType && schetActions(modalType)}
			okButtonProps={{
				className: `data-test-schet-${modalType}-accept-button`,
				disabled: sending,
			}}
			className={`data-test-approve-schet-${modalType}-dialog-modal-${modalType ? 'visible' : 'hidden'}`}
		>
			<p>{dialogModalContent.text}</p>
		</ModalComponent>
	);
}
