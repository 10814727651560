import { Navigate, useLocation } from 'react-router-dom';

import { useUser, useUserConstants } from '~zustand/userData';

interface Props {
	type?: 'store' | 'cluster';
}

export function PrependPath({ type = 'store' }: Props) {
	const location = useLocation();
	const user = useUser();
	const constants = useUserConstants();

	let prependingPath = '';

	switch (type) {
		case 'store':
			prependingPath = `/stores/${user.store_id}`;
			break;
		case 'cluster':
			prependingPath = `/clusters/${constants.store?.cluster_id}`;
			break;
	}

	return <Navigate replace to={`${prependingPath}${location.pathname}`} />;
}
