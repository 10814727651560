import dayjs from 'dayjs';

import type { Orders } from '~types/orders';
import { useCheckExp, useCheckPermit } from '~zustand/userData';

import { OrderTypeIcon } from './OrderTypeIcon';

type Props = {
	order: Orders.Order;
};

const PROHIBITED_STATUSES = ['request', 'canceled'];

export function ScanIcon({ order }: Props) {
	const isExpChuganin = useCheckExp('exp_chuganin');
	const isExpFalcon = useCheckExp('exp_falcon');
	const isPermitDispatcherAttachOrderFiles = useCheckPermit('dispatcher_attach_order_files');

	const dateWeekAgo = dayjs().subtract(1, 'week');

	const isDateProper =
		dayjs(order.doc_date).isAfter(dateWeekAgo) || dayjs(order.vars?.documents_status_updated).isAfter(dateWeekAgo);

	const isShipmentWithIcon =
		isExpChuganin &&
		!PROHIBITED_STATUSES.includes(order.status) &&
		order.attr?.request_type === 'return_supplier' &&
		isDateProper;

	const isAcceptanceWithIcon =
		(order.attr?.order_files_schema === 'default' ? isPermitDispatcherAttachOrderFiles : isExpFalcon) &&
		!PROHIBITED_STATUSES.includes(order.status) &&
		order.attr?.request_type === 'purchase_order' &&
		isDateProper;

	const isAcceptanceMarket = order.type === 'acceptance_market' && order.attr?.documents_status === 'scan';

	return isShipmentWithIcon || isAcceptanceWithIcon || isAcceptanceMarket ? (
		<OrderTypeIcon status={order.attr?.documents_status} type="scan" />
	) : null;
}
