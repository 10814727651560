import { Col, Row } from 'antd';
import type { JSX } from 'react';

import { useStyles } from '../styles';
import type { FieldProps } from '../types';
import { EmptyValue, getColSpan } from '../utils';

interface Props extends FieldProps {
	fieldData: JSX.Element | string;
	dataTestValue?: string;
	editMode: boolean;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
}

const defaultColspan = {
	xl: { span: 12 },
	lg: { span: 12 },
	sm: { span: 12 },
	xs: { span: 12 },
};

const ElementField = ({ fieldData, dataTestValue, editMode, colspan, ...field }: Props) => {
	const { cx, classes } = useStyles();
	const separator = '';

	return (
		<Row gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]}>
			<Col {...getColSpan(colspan || defaultColspan)} className={classes.labelContainer}>
				<label className={classes.label} htmlFor={field.id}>{`${field.label}${separator}`}</label>
				<div className={classes.dotSeparator} />
			</Col>
			<Col {...getColSpan(colspan || defaultColspan)}>
				{' '}
				<p
					className={cx(classes.valueOnViewMode, classes.readOnlyFieldContainer)}
					data-test={`data form ${field.label}`}
					data-test-value={dataTestValue}
				>
					{fieldData ? fieldData : <EmptyValue />}
				</p>
			</Col>
		</Row>
	);
};

export default ElementField;
