import { useEffect } from 'react';
import { Navigate, useRouteError } from 'react-router-dom';

import NoAccess from '~shared/components/ErrorPage/NoAccess';
import { isTvClientMode } from '~shared/utils/isTvClientMode';
import { useUser } from '~zustand/userData';

import FrontCrush from '../ErrorPage/FrontCrush';

type ErrorType = {
	status: number;
	data?: {
		code?: string;
		text?: string;
		details?: {
			store_id?: string;
			company_id?: string;
		};
	};
};

export function RouterErrorBoundary() {
	const error = useRouteError() as ErrorType;

	const user = useUser();

	const isTv = isTvClientMode() || user.role === 'tv_device';

	useEffect(() => {
		if (error.status) {
			return;
		}

		window.Ya?.Rum.logError(
			{
				message: isTv ? 'Front crush in Tableau' : 'Front crush in WMS',
				page: window.location.href,
				additional: {
					type: 'FRONT_CRUSH',
					error,
				},
			},
			error as Ya.Rum.LoggableError
		);
	}, [error]);

	if (isTv) {
		return <Navigate to="/tv-client/tableau/crush" />;
	}

	return error.status === 403 ? (
		<NoAccess
			error={{
				details: error?.data?.details ?? {},
			}}
		/>
	) : (
		<FrontCrush />
	);
}
