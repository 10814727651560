import type { ReactNode } from 'react';

import { useStyles } from '../styles';

type props = {
	children: ReactNode[] | ReactNode;
	condition?: boolean;
	className?: string;
	dataTest?: string;
};
export function ProTableToolbar({ children, className, condition = true, dataTest }: props) {
	const { classes, cx } = useStyles();

	if (condition) {
		return (
			<div className={cx(classes.toolbar, className)} data-test={dataTest}>
				{children}
			</div>
		);
	}
}
