import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

import { appVersion } from '~/appConfig';
import { DynamicImportBackend } from '~i18n/backend';
import { isoLanguages } from '~i18n/consts';
import uuid from '~shared/utils/uuid';

// eslint-disable-next-line import/no-named-as-default-member
void i18n
	.use(ChainedBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		backend: {
			backends: [LocalStorageBackend, DynamicImportBackend],
			backendOptions: [
				{
					defaultVersion: process.env.NODE_ENV === 'development' ? uuid() : appVersion,
				},
			],
		},
		fallbackLng: ['en-US', 'ru-RU'],
		supportedLngs: isoLanguages,
		compatibilityJSON: 'v4',
		keySeparator: false,
		nsSeparator: false,
		returnEmptyString: false,
		saveMissing: false,
		detection: {},
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;
