import i18n from '~/i18n';

export default function transformSecondsToTimeString(seconds: number, isShort = false, showZero = false) {
	const { t } = i18n;

	const absSeconds = Math.abs(seconds);
	let hoursValue = (absSeconds - (absSeconds % 3600)) / 3600;
	let minutesValue = (absSeconds - hoursValue * 3600 - (absSeconds % 60)) / 60;
	let secondsValue = Math.round(absSeconds - hoursValue * 3600 - minutesValue * 60);

	if (seconds < 0) {
		if (hoursValue) {
			hoursValue *= -1;
		} else if (minutesValue) {
			minutesValue *= -1;
		} else {
			secondsValue *= -1;
		}
	}

	// чтобы не делать кучу условий для случаев, если нет часов, если нет минут и т.п.,
	// мы делаем из них список, который передаём в t() с собственнописанным форматером,
	// который просто объединяет их в одну строку и обращает влево для rtl языков
	// Не используем {{value, list}}, ибо там запятые вылезают даже для type: 'unit'
	// https://www.i18next.com/translation-function/formatting

	i18n.services.formatter?.add('join', (value) => {
		return value.join(' ');
	});

	const hoursSubstring = isShort
		? t('{{hours}} ч.', { hours: hoursValue })
		: t('{{hours}} час.', { hours: hoursValue });
	const minutesSubstring = isShort
		? t('{{minutes}} м.', { minutes: minutesValue })
		: t('{{duration}} мин.', { duration: minutesValue });
	const secondsSubstring = isShort
		? t('{{seconds}} с.', { seconds: secondsValue })
		: t('{{seconds}} сек.', { seconds: secondsValue });

	const displayString = [
		hoursValue ? hoursSubstring : null,
		minutesValue ? minutesSubstring : null,
		secondsValue || showZero ? secondsSubstring : null,
	];

	return t('{{displayString, join}}', {
		displayString: displayString.filter(Boolean),
	});
}
