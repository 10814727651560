import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	tooltipIcon: {
		marginBlock: '0',
		marginInline: '8px',
		position: 'relative',
		insetBlockStart: '2px',
		cursor: 'pointer',
		'& svg': {
			fill: theme.text.disabled,
		},
	},
}));
