import { Button, Tooltip } from 'antd';
import type { JSX, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useStyles as useCommonStyles } from '~styles/common';

import DotsButton from '../DotsButton';
import { useStyles } from './styles';

export interface ActionColumnItem {
	type: 'button' | 'link';
	onClick?: (...args: any) => void;
	to?: string;
	dataTest?: string;
	text: string;
	condition?: boolean;
	disabled?: boolean;
	icon?: JSX.Element;
	target?: '_blank';
	buttonColor?: 'red' | 'green';
	key?: string;
	component?: ReactElement;
	style?: Record<string, string | number>;
}

interface Props {
	actions: ActionColumnItem[];
	afterClick?: () => void;
	className?: string;
	dataTest?: string;
	triggerDataTest?: string;
	asDotsButton?: boolean;
	borderedDotsButton?: boolean;
	onVisibleChange?: (visible: boolean) => void;
	tooltip?: string;
	inline?: boolean;
	inHeader?: boolean;
	hasCustomComponent?: boolean;
}

const ActionsComponent = ({
	actions,
	afterClick,
	className,
	dataTest,
	triggerDataTest = 'actions column menu trigger',
	asDotsButton,
	borderedDotsButton = false,
	onVisibleChange,
	tooltip,
	inline = true,
	hasCustomComponent = false,
	inHeader,
}: Props) => {
	const [t] = useTranslation();
	const { classes: commonClasses } = useCommonStyles();
	const { cx, classes } = useStyles();
	const popoverMode = actions.length > 2 || asDotsButton;

	const content = () => {
		const result: any = [];
		actions.forEach((action, index) => {
			if (action.condition !== false) {
				let item: JSX.Element = <></>;
				switch (action.type) {
					case 'button':
						item =
							hasCustomComponent && action.component ? (
								action.component
							) : (
								<Button
									style={action.style}
									key={action.text}
									onClick={() => {
										action.onClick?.();
										afterClick?.();
									}}
									data-test={action.dataTest}
									className={cx({
										[commonClasses.dropdownBtn]: popoverMode,
										[classes.actionIcon]: !popoverMode,
										[commonClasses.dropdownBtnDisabled]: action.disabled,
										[classes.actionButtonRed]: action.buttonColor === 'red',
										[classes.actionButtonGreen]: action.buttonColor === 'green',
									})}
								>
									{popoverMode && !action.component ? action.text : action.icon}
									{popoverMode && action.component}
								</Button>
							);
						break;
					case 'link':
						item = (
							<Button
								key={action.text}
								className={cx({
									[commonClasses.dropdownBtn]: popoverMode,
									[classes.actionIcon]: !popoverMode,
									[commonClasses.dropdownBtnDisabled]: action.disabled,
								})}
							>
								<Link target={action.target} to={action.to!} data-test={action.dataTest}>
									{popoverMode ? action.text : action.icon}
								</Link>
							</Button>
						);
				}
				if (popoverMode) {
					result.push({
						key: index,
						component: item,
					});
				}
				result.push(
					<Tooltip key={action.text} title={action.text}>
						{item}
					</Tooltip>
				);
			}
		});
		return result;
	};

	if (popoverMode) {
		return (
			<DotsButton
				tooltip={{ title: tooltip ?? t('Дополнительные действия'), placement: 'left' }}
				dataTest={triggerDataTest}
				buttons={content()}
				inline={inline}
				className={className}
				bordered={borderedDotsButton}
				onVisibleChange={onVisibleChange}
				inHeader={inHeader}
			/>
		);
	}

	return (
		<div className={classes.actionsBlockShort} data-test={dataTest}>
			{content()}
		</div>
	);
};

export default ActionsComponent;
