import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	chartBlock: {
		minInlineSize: 400,
		paddingBlock: '15px',
		paddingInline: '20px',
		boxShadow: theme.softShadow,
		borderRadius: 8,
		blockSize: 300,
	},
	chartHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		inlineSize: '100%',
		gap: 10,
	},
	chartHeaderLeft: {
		minInlineSize: 200,
		inlineSize: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		'.ant-select': {
			maxInlineSize: '40%',
		},
	},
	chartRemoveButton: {
		justifySelf: 'end',
	},
	chartHeaderRight: {
		display: 'flex',
		inlineSize: '100%',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'end',
	},
	checkboxLabel: {
		marginInlineStart: 5,
	},
	allDataLink: {
		textAlign: 'end',
		marginInlineStart: 15,
	},
	chartContainer: {
		flexGrow: 1,
		overflowY: 'hidden',
		marginBlock: 20,
		blockSize: 220,
	},
	tooltipContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		backgroundColor: 'black',
		borderRadius: 10,
		color: theme.text.white,
		opacity: '80%',
		inlineSize: 220,
	},
	extendedTooltipContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		backgroundColor: theme.bgColor.tooltip,
		borderRadius: 10,
		color: theme.text.white,
		opacity: '80%',
		inlineSize: 380,
	},
	tooltipHeader: {
		fontWeight: 'bold',
		borderBlockEnd: '1px solid white',
		marginBlockEnd: 10,
	},
	tooltipLabel: {
		color: theme.text.tooltipLabel,
	},
	tooltipText: {
		color: theme.text.tooltipData,
	},
}));
