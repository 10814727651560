import { notification } from '@lavka/ui-kit';
import { Spin } from 'antd';
import type { TreeSelectProps } from 'antd/lib/tree-select';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProductGroups } from '~types/productGroups';

import { arrayUniq } from '../../shared/utils/arrayUniq';
import { loadNodeChildren, loadPath, searchNodes } from './utils';

type UseSearchableTreeSelectConfig = {
	fields: {
		id: string & keyof ProductGroups.ProductGroupNode;
		pId: string & keyof ProductGroups.ProductGroupNode;
		label: string & keyof ProductGroups.ProductGroupNode;
	};
	searchDebounceTimeout?: number;
	errorNotificationText: string;
};

export function useSearchableTreeSelect(
	initialValue: string | string[] | undefined,
	{ fields, searchDebounceTimeout = 250, errorNotificationText }: UseSearchableTreeSelectConfig,
	skip = false
): Partial<TreeSelectProps<string>> {
	const [t] = useTranslation();
	const [nodes, setNodes] = useState<Record<string, ProductGroups.ProductGroupNode>>({});
	const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searching, setSearching] = useState<boolean>(false);

	const treeData = useMemo(() => Object.values(nodes), [nodes]);

	const updateNodes = useCallback(
		(nodes: ProductGroups.ProductGroupNode[], rewriteAll = true) => {
			setNodes((oldNodes) => {
				const newNodes = rewriteAll ? {} : { ...oldNodes };

				nodes.forEach((node) => {
					const key = node[fields.id] as string;
					newNodes[key] = node;
				});

				return newNodes;
			});
		},
		[fields.id]
	);

	const loadNodesWithParents = useCallback(
		async (nodeIds: string[], loadParentSiblings: boolean) => {
			const path = await loadPath(nodeIds);

			const parentIdsToLoad = arrayUniq(
				path
					.filter((node) => nodeIds.includes(node[fields.id] as string) || loadParentSiblings)
					.map((node) => (node[fields.pId] ?? '') as string)
			);

			const parentEntries = parentIdsToLoad[0] ? await loadNodeChildren(parentIdsToLoad) : [];

			return {
				parentIds: arrayUniq(path.map((node) => node[fields.pId] as string)),
				nodes: [...path, ...parentEntries],
			};
		},
		[loadPath, loadNodeChildren, fields.id, fields.pId, errorNotificationText]
	);

	const loadValue = useCallback(
		async (value?: string | string[] | null) => {
			setLoading(true);

			try {
				if (value && value.length > 0) {
					const { parentIds, nodes } = await loadNodesWithParents(Array.isArray(value) ? value : [value], true);

					updateNodes(nodes);
					setExpandedNodeIds(parentIds);
				} else {
					const nodes = await loadNodeChildren();
					updateNodes(nodes);
					setExpandedNodeIds([]);
				}
			} catch {
				notification.error({
					message: errorNotificationText,
				});
			} finally {
				setLoading(false);
			}
		},
		[loadNodesWithParents, updateNodes]
	);

	useEffect(() => {
		if (!skip) {
			void loadValue(initialValue);
		}
	}, [loadValue, initialValue, skip]);

	const onTreeExpand = useCallback((values: any[]) => {
		setExpandedNodeIds(values);
	}, []);

	const loadData = useCallback(
		async (node: any | null) => {
			const nodeId: string = node?.[fields.id] ?? '';
			setLoading(true);

			try {
				const nodes = await loadNodeChildren([nodeId]);
				updateNodes(nodes, false);
			} catch {
				notification.error({
					message: errorNotificationText,
				});
			} finally {
				setLoading(false);
			}
		},
		[fields.id, loadNodeChildren, updateNodes, errorNotificationText]
	);

	const onSearch = useCallback(
		debounce(async (value: string) => {
			setSearching(true);

			try {
				if (value) {
					const nodeIds = await searchNodes(value);
					const { parentIds, nodes } = await loadNodesWithParents(nodeIds.length === 0 ? [value] : nodeIds, false);
					updateNodes(nodes, false);
					setExpandedNodeIds(parentIds);
				} else {
					const nodes = await loadNodeChildren();

					updateNodes(nodes, false);
					setExpandedNodeIds([]);
				}
				setSearching(false);
			} catch {
				notification.error({
					message: errorNotificationText,
				});
			} finally {
				setSearching(false);
			}
		}, searchDebounceTimeout),
		[searchNodes, loadNodesWithParents, updateNodes, loadNodeChildren, errorNotificationText, searchDebounceTimeout]
	);

	const onBlur = useCallback(async () => {
		await loadValue(initialValue);
	}, [loadValue, initialValue]);

	return {
		loading,
		treeData,
		treeExpandedKeys: expandedNodeIds,
		treeDataSimpleMode: {
			id: fields.id,
			pId: fields.pId,
		},
		fieldNames: {
			label: fields.label,
			value: fields.id,
		},
		filterTreeNode: (inputValue: string, treeNode: any) => {
			return (
				treeNode[fields.id] === inputValue || treeNode[fields.label].toLowerCase().includes(inputValue.toLowerCase())
			);
		},
		treeLine: true,
		onTreeExpand,
		loadData,
		onSearch,
		notFoundContent: searching ? <Spin /> : t('Нет данных'),
		onBlur,
	};
}
