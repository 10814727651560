import { notification } from '@lavka/ui-kit';
import { Col, Radio, Row } from 'antd';
import type { OptionProps } from 'antd/lib/select';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCache } from '~cache/useCache';
import { ReactComponent as SwapRight } from '~images/icons/swap-right.svg';
import type { ProductSearchField } from '~shared/components/Fields/ProductSelectField';
import { productSearchFields } from '~shared/components/Fields/ProductSelectField';
import { searchProducts } from '~shared/components/Fields/ProductSelectField/utils';
import SelectField from '~shared/components/Fields/SelectField';
import { useStyles as useFieldStyles } from '~shared/components/Fields/styles';
import TreeView from '~shared/components/Fields/TreeSelectField/TreeView';
import type { FieldProps } from '~shared/components/Fields/types';
import { useDebounce } from '~shared/hooks/useDebounce';
import { useSkipOnMountEffect } from '~shared/hooks/useSkipOnMountEffect';

import { RegradingContext } from './context';
import { useStyles } from './styles';
import type { RegradingProductsType } from './types';
import { getUniqueProductsGroups } from './utils';

interface Props {
	onSelect: (
		value: string | null,
		option: Record<string, string> | null,
		field: RegradingProductsType
	) => Promise<void>;
	addMode?: boolean;
	editMode: boolean;
	colspan?: Record<string, { span: number }>;
}

type SelectProductsWithSearchType = Props & FieldProps;

const SelectProductsWithSearch = ({
	onSelect,
	addMode,
	editMode,
	colspan,
	input,
	...props
}: SelectProductsWithSearchType) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const { cx, classes: fieldClasses } = useFieldStyles();
	const [searchField, setSearchField] = useState<ProductSearchField>('external_id');
	const [oldProductOptions, setOldProductOptions] = useState<Record<string, string>[]>([]);
	const [regradeProductOptions, setRegradeProductOptions] = useState<Record<string, string>[]>([]);
	const [searchFieldValues, setSearchFieldValues] = useState<{ old: string; regrade: string }>({
		old: '',
		regrade: '',
	});
	const { products, cancelTokenSource } = useContext(RegradingContext);
	const debouncedOldSearchTerm = useDebounce(searchFieldValues.old, 500);
	const debouncedNewSearchTerm = useDebounce(searchFieldValues.regrade, 500);
	const oldDictionary = oldProductOptions.reduce((result, item) => {
		result[item.value] = item.text;
		return result;
	}, {});
	const newDictionary = regradeProductOptions.reduce((result, item) => {
		result[item.value] = item.text;
		return result;
	}, {});

	useCache({
		products: [products.old?.product_id, products.regrade?.product_id],
		productGroups: getUniqueProductsGroups(products),
	});

	const paramsComponent = (
		<Radio.Group
			onChange={(e) => setSearchField(e.target.value)}
			defaultValue={searchField}
			className={cx(fieldClasses.radioGroup, classes.inputSelect)}
		>
			{(Object.keys(productSearchFields) as ProductSearchField[]).map((key: ProductSearchField) => (
				<Radio.Button key={key} value={key} className={`data-test-radio-${productSearchFields[key]}`}>
					{productSearchFields[key]}
				</Radio.Button>
			))}
		</Radio.Group>
	);

	const searchOptions = async (field: RegradingProductsType = 'regrade') => {
		try {
			const searchResults = await searchProducts(
				field === 'old' ? searchFieldValues.old : searchFieldValues.regrade,
				searchField,
				cancelTokenSource?.current
			);

			if (field === 'old') {
				setOldProductOptions(searchResults ?? []);
			} else {
				setRegradeProductOptions(searchResults ?? []);
			}
		} catch {
			notification.error({
				message: t('Не удалось выполнить поиск'),
			});
		}
	};

	useSkipOnMountEffect(() => {
		void searchOptions('old');
	}, [debouncedOldSearchTerm]);
	useSkipOnMountEffect(() => {
		void searchOptions();
	}, [debouncedNewSearchTerm]);

	const onClearValue = (field: RegradingProductsType) => {
		setSearchFieldValues({ ...searchFieldValues, [field]: '' });
		void onSelect(null, null, field);
	};

	const getValue = (value: string, field: RegradingProductsType) => {
		if (!products[field]) {
			return null;
		}

		return products[field]?.title ?? value;
	};

	const prepareOptionProps = (
		option: string,
		initialProps: Omit<OptionProps, 'value' | 'children'>,
		dictionary: Record<string, string> | []
	) => ({
		...initialProps,
		value: dictionary[option] ?? option,
	});

	return (
		<>
			<Row align="top" gutter={18} justify="start" className={classes.productTabs}>
				<Col>
					<span className={classes.productTitle}> {t('Выбрать товар через')} </span>
				</Col>
				<Col className={classes.inputSelect}>{paramsComponent}</Col>
			</Row>

			<div className={classes.selectFieldsContainer}>
				<SelectField
					{...props}
					id="oldProduct"
					key="oldProduct"
					dataTest="old product regrading"
					inputClassName={classes.inputSelect}
					rowClassName={classes.inputSelectContainer}
					labelContainerClassName={classes.labelContainer}
					label={t('Старый товар')}
					allowClear
					showSearch
					onFocus={() => searchOptions('old')}
					onSelect={(value, option) => onSelect(value, option, 'old')}
					onSearch={(value) => setSearchFieldValues({ ...searchFieldValues, old: value })}
					onClear={() => onClearValue('old')}
					addMode={addMode}
					editMode={editMode}
					options={oldProductOptions.map((option) => option.value)}
					dictionary={oldDictionary}
					prepareOptionProps={prepareOptionProps}
					colspan={colspan}
					input={{
						...input,
						value: getValue(input.value?.[0]?.product_id, 'old'),
						onChange: (value) => {
							input.onChange([
								{ ...input.value?.[0], product_id: oldProductOptions.find((option) => option.text === value)?.value },
							]);
						},
					}}
				/>
				<SwapRight className={classes.selectIcon} />
				<SelectField
					{...props}
					id="newProduct"
					key="newProduct"
					dataTest="new product regrading"
					inputClassName={classes.inputSelect}
					rowClassName={classes.inputSelectContainer}
					labelContainerClassName={classes.labelContainer}
					label={t('Новый товар')}
					allowClear
					showSearch
					onFocus={() => searchOptions('regrade')}
					onSelect={(value, option) => onSelect(value, option, 'regrade')}
					onSearch={(value) => setSearchFieldValues({ ...searchFieldValues, regrade: value })}
					onClear={() => onClearValue('regrade')}
					addMode={addMode}
					editMode={editMode}
					options={regradeProductOptions.map((option) => option.value)}
					dictionary={newDictionary}
					prepareOptionProps={prepareOptionProps}
					colspan={colspan}
					input={{
						...input,
						value: getValue(input.value?.[0]?.regrading_target, 'regrade'),
						onChange: (value) => {
							input.onChange([
								{
									...input.value?.[0],
									regrading_target: regradeProductOptions.find((option) => option.text === value)?.value,
								},
							]);
						},
					}}
				/>
			</div>

			<Row className={classes.groupProducts}>
				<Col>
					<span> {t('Группа товаров')} </span>
					<TreeView path="product_groups" showAllParents={true} value={getUniqueProductsGroups(products)} />
				</Col>
			</Row>
		</>
	);
};

export default SelectProductsWithSearch;
