import { useReducer, useRef } from 'react';

import api from '~/api';
import useLoadData from '~shared/hooks/useLoadData';
import useSubscribe from '~shared/hooks/useSubscribe';
import type { Event } from '~shared/utils/ev';
import type { Events } from '~types/events';
import type { Orders } from '~types/orders';
import type { Suggests } from '~types/suggests';

export function useSuggestsMonitor(order: Orders.Order) {
	const [suggestsState, dispatchSuggests] = useReducer(suggestsReducer, initialSuggestsState);

	// Используем ref, чтобы использовать в замыкании обработки событий и не пересоздавать функцию
	const orderRef = useRef<Orders.Order>(order);
	orderRef.current = order;
	const suggestsRef = useRef(suggestsState);
	suggestsRef.current = suggestsState;

	const { req: loadSuggests } = useLoadData(
		() => api.orders.suggests({ order_id: order.order_id }),
		[order.order_id],
		!order.order_id,
		(data) => {
			dispatchSuggests({ type: 'INIT', payload: data.suggests });
		}
	);

	const subscribeHandler = (data: Event) => {
		let shouldUpdateSuggests = false;

		data.data
			.map((e) => e as Events.SuggestEvent | Events.OrderEvent | Events.SuggestRemoveEvent)
			.forEach((entity) => {
				if (entity.order_id === order.order_id) {
					if (entity.type === 'order') {
						if (
							orderRef.current?.version !== entity.version &&
							(orderRef.current?.status !== 'processing' || entity.status !== 'processing')
						) {
							shouldUpdateSuggests = true;
						}
					}
					if (entity.type === 'suggest') {
						const hasSuggest = suggestsRef.current.suggests[entity.suggest_id];

						if (hasSuggest) {
							dispatchSuggests({
								type: 'UPDATE',
								payload: entity,
							});
						} else {
							void (async () => {
								try {
									const { data: suggestData } = await api.suggests.load(
										{ suggest_id: [entity.suggest_id] },
										{ force: true }
									);

									dispatchSuggests({
										type: 'ADD',
										payload: suggestData.result[0],
									});
								} catch {}
							})();
						}
					}
					if (entity.type === 'suggest_remove') {
						dispatchSuggests({
							type: 'REMOVE',
							payload: { suggestId: entity.suggest_id },
						});
					}
				}
			});

		if (shouldUpdateSuggests) {
			loadSuggests();
		}
	};

	useSubscribe(
		{
			key: ['order', 'store', order.store_id],
			cb: (data) => subscribeHandler(data),
			unSub: true,
			single: false,
			name: 'order',
		},
		[order.store_id, order.order_id],
		!order.store_id
	);

	return suggestsState;
}

export type InitActionType = {
	type: 'INIT';
	payload: Suggests.Suggest[];
};

export type UpdateActionType = {
	type: 'UPDATE';
	payload: Events.SuggestEvent;
};

export type AddActionType = {
	type: 'ADD';
	payload: Suggests.Suggest;
};

export type RemoveActionType = {
	type: 'REMOVE';
	payload: {
		suggestId: string;
	};
};

type State = {
	suggests: Record<string, Suggests.Suggest>;
	completed: Set<string>;
};

export const initialSuggestsState: State = {
	suggests: {},
	completed: new Set(),
};

function suggestsReducer(
	state: State,
	action: InitActionType | AddActionType | UpdateActionType | RemoveActionType
): State {
	switch (action.type) {
		case 'ADD':
		case 'UPDATE':
			const changedState: typeof initialSuggestsState = {
				suggests: { ...state.suggests },
				completed: new Set([...state.completed]),
			};
			const suggestId = action.payload.suggest_id;
			const suggest = state.suggests[suggestId];

			if (suggest) {
				changedState.suggests[suggestId] = {
					...suggest,
					...action.payload,
					type: suggest.type,
				};
			} else if (action.type === 'ADD') {
				changedState.suggests[suggestId] = action.payload;
			}

			if (action.payload.status === 'done') {
				changedState.completed.add(suggestId);
			}

			return changedState;
		case 'INIT':
			const updatedState: State = { completed: new Set(), suggests: {} };
			if (!action.payload) return updatedState;

			action.payload.forEach((suggest) => {
				updatedState.suggests[suggest.suggest_id] = suggest;
				if (suggest.status === 'done') {
					updatedState.completed.add(suggest.suggest_id);
				}
			});
			return updatedState;
		case 'REMOVE':
			const suggests = { ...state.suggests };
			delete suggests[action.payload.suggestId];
			const completed = new Set([...state.completed].filter((e) => e !== action.payload.suggestId));

			return {
				suggests,
				completed,
			};
		default:
			return state;
	}
}
