import { Alert } from 'antd';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ExclamationTriangleRegular } from '~images/icons/exclamation-triangle-regular.svg';
import { intervalViewText } from '~shared/components/Fields/SchetsSchedule/utils';
import { useStyles as useFieldStyles } from '~shared/components/Fields/styles';
import Icon from '~shared/utils/icons';
import { formatTimeAndYear } from '~shared/utils/momentFormatters';
import type { Schets } from '~types/schets';

import { useStyles } from '../styles';

interface Props {
	timezone?: string;
	scheduleDraft: Schets.Schedule;
}

const NeedApproveAlert = ({ scheduleDraft, timezone }: Props) => {
	const [t] = useTranslation();

	const { classes: fieldClasses } = useFieldStyles();
	const { classes } = useStyles();

	const renderSchedule = useMemo(() => {
		return (
			<ul className={classes.approveAlertSchedule}>
				<li>
					{t('Дата и время начала')}
					{':'}{' '}
					{scheduleDraft.start_time
						? formatTimeAndYear(scheduleDraft.start_time, {
								timezone,
								alwaysShowYear: true,
								withSeconds: true,
								onlyDate: false,
							})
						: '—'}
				</li>
				<li>{intervalViewText(scheduleDraft.interval)}</li>
			</ul>
		);
	}, [scheduleDraft]);

	return (
		<Alert
			data-test="field alert schedule"
			className={fieldClasses.fieldAlert}
			message={t('Требуется подтверждение нового расписания')}
			description={renderSchedule}
			type="warning"
			showIcon
			icon={<Icon component={ExclamationTriangleRegular} />}
		/>
	);
};

export default memo(NeedApproveAlert);
