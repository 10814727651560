import { Checkbox, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import i18n from '~/i18n';
import { timetableTypeDictionary } from '~constants/timetableTypes';
import { transformRawDaysToTypes } from '~shared/components/Fields/RepeatScheduleField/utils';
import type { FieldProps } from '~shared/components/Fields/types';
import { getColSpan } from '~shared/components/Fields/utils';

import { useStyles } from '../styles';

interface Props extends FieldProps {
	colspan?: Record<string, { span: number }>;
}

const getViewText = (days: string[]): string => {
	const value = transformRawDaysToTypes(days);
	const list = value.map((day) => timetableTypeDictionary[day]);
	const formatter = new Intl.ListFormat(i18n.language.replace('_', '-'), { style: 'short', type: 'conjunction' });
	return formatter.format(list).toLowerCase();
};

const getWeekdays = () => {
	const options: {
		label: string;
		value: string;
	}[] = [];
	for (let i = 1; i < 8; i++) {
		// Перекладываем воскресенье в конец
		const day = i === 7 ? 0 : i;
		options.push({
			label: dayjs.weekdaysMin()[day],
			value: dayjs().locale('en').day(day).format('dddd').toLowerCase(),
		});
	}
	return options;
};
const WeekDaysField = ({ input, editMode, colspan, label }: Props) => {
	const { classes } = useStyles();
	const options = useMemo(() => getWeekdays(), []);

	return (
		<Row>
			<Col {...getColSpan(colspan)} className={classes.labelContainer}>
				{label}
				<div className={classes.dotSeparator}></div>
			</Col>
			<Col {...getColSpan(colspan)}>
				{editMode ? (
					<Checkbox.Group
						style={{ inlineSize: '100%' }}
						onChange={(checked: string[]) => input.onChange(checked)}
						value={input.value}
						data-test={`data form ${label}`}
					>
						<Row>
							{options.map((option) => (
								<Col key={option.value} span={6} data-test={`data form option ${option.label}`}>
									<Checkbox value={option.value}>{option.label}</Checkbox>
								</Col>
							))}
						</Row>
					</Checkbox.Group>
				) : (
					<div className={classes.valueOnViewMode} data-test={`data form ${label}`}>
						{getViewText(input.value)}
					</div>
				)}
			</Col>
		</Row>
	);
};
export default WeekDaysField;
