import dayjs from 'dayjs';

import type { Orders } from '~types/orders';

import { checkAcceptanceApprovalTime } from '../OrdersDataPage/utils';

type ApproveAcceptanceProps = {
	agutinIsOn: boolean;
	timezone?: string;
	order: Orders.Order;
	mayApprove: boolean;
};

export const checkIsAcceptanceToApprove = ({ order, timezone, agutinIsOn, mayApprove }: ApproveAcceptanceProps) => {
	return (
		order.type === 'acceptance' &&
		agutinIsOn &&
		!order.vars?.closed &&
		checkAcceptanceApprovalTime(order.vars?.all_children_done, timezone) &&
		mayApprove
	);
};

type DocumentToScanProps = {
	order: Orders.Order;
	falconIsOn: boolean;
	chuganinIsOn: boolean;
};

export const checkIsDocumentToScan = ({ order, falconIsOn, chuganinIsOn }: DocumentToScanProps) => {
	const acceptanceToDisplay =
		order.attr?.request_type === 'purchase_order' &&
		order.type === 'acceptance' &&
		(falconIsOn || order.attr.order_files_schema);
	const shipmentToDisplay = order.attr?.request_type === 'return_supplier' && order.type === 'shipment' && chuganinIsOn;
	const docDateIsCorrect =
		dayjs().subtract(7, 'days').isBefore(dayjs(order.created)) ||
		dayjs().subtract(7, 'days').isBefore(dayjs(order.vars?.documents_status_updated));

	const correctDocumentStatus =
		order.type === 'acceptance_market'
			? order.attr?.documents_status === 'scan'
			: order.attr?.documents_status === 'rescan' || !order.attr?.documents_status;

	return (
		(acceptanceToDisplay || shipmentToDisplay || order.type === 'acceptance_market') &&
		docDateIsCorrect &&
		order.status !== 'canceled' &&
		order.status !== 'request' &&
		correctDocumentStatus
	);
};
