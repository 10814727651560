import { notification } from '@lavka/ui-kit';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { readAsText } from '~shared/utils/import';

import UploadModal from '../UploadModal';

type Props = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	validate?: (text: string) => string | null;
	onImport: (text: string) => void;
	title: string;
	extension: string;
	fileName: string;
	onError?: () => void;
	additionalContent?: JSX.Element;
	templateFileName?: string;
	templateColumns?: string[];
};

export const ImportTextFileModal = ({
	visible,
	setVisible,
	validate,
	onImport,
	title,
	extension,
	fileName,
	onError,
	additionalContent,
	templateFileName,
	templateColumns,
}: Props) => {
	const [t] = useTranslation();

	const [file, setFile] = useState<{ file: File; fileName: string } | undefined>();

	const addFile = (file: File) => {
		setFile({
			fileName: file.name,
			file,
		});
	};

	const onOk = async () => {
		if (!file) {
			return;
		}

		try {
			const text = await readAsText(file.file);

			const error = validate?.(text ?? '');
			if (error) {
				notification.error({
					message: error,
				});
				return;
			}

			setVisible(false);
			setFile(undefined);
			onImport(text ?? '');
		} catch {
			onError?.();
		}
	};

	return (
		<UploadModal
			title={title}
			titleText={t('Щелкните или перетащите файл(ы) в эту область для загрузки')}
			descrText={t('Пожалуйста, загрузите файл(ы) в формате {{extension}}', { extension })}
			visible={visible}
			toggleVisible={setVisible}
			uploadProps={{
				name: fileName,
				accept: extension,
				fileList: [],
				multiple: false,
			}}
			onOk={onOk}
			file={file}
			addAction={addFile}
			removeAction={() => setFile(undefined)}
			filenameKey="fileName"
			additionalContent={additionalContent}
			templateFileName={templateFileName}
			templateColumns={templateColumns}
		/>
	);
};
