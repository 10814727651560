// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type UserRoles =
	| 'tv_device'
	| 'free_device'
	| 'guest'
	| 'authen_guest'
	| 'courier'
	| 'contractor'
	| 'kitchen_contractor'
	| 'cofee_machine_contractor'
	| 'picker'
	| 'executer'
	| 'executer_junior'
	| 'barcode_executer'
	| 'stocktaker'
	| 'head_executer'
	| 'quality_control_manager'
	| 'vice_store_admin'
	| 'store_admin'
	| 'kitchen_manager'
	| 'dc_admin'
	| 'support'
	| 'support_head'
	| 'telemarketing'
	| 'company_support'
	| 'support_ro'
	| 'company_support_ro'
	| 'support_it'
	| 'company_support_it'
	| 'support_courier'
	| 'company_support_courier'
	| 'supervisor'
	| 'head_supervisor'
	| 'expansioner_ro'
	| 'expansioner'
	| 'category_manager'
	| 'company_category_manager'
	| 'kitchen_category_manager'
	| 'territorial_kitchen_manager'
	| 'content_manager'
	| 'city_head'
	| 'employee_audit'
	| 'chief_audit'
	| 'company_chief_audit'
	| 'courier_manager'
	| 'staff_courier_manager'
	| 'finance_manager'
	| 'courier_company_admin'
	| 'courier_admin'
	| 'company_courier_admin'
	| 'logistic_admin'
	| 'company_admin'
	| 'company_admin_ro'
	| 'chief_manager'
	| 'company_chief_manager'
	| 'analyst'
	| 'company_analyst'
	| 'admin_ro'
	| 'admin';
export const userRolesValues = [
	'tv_device',
	'free_device',
	'guest',
	'authen_guest',
	'courier',
	'contractor',
	'kitchen_contractor',
	'cofee_machine_contractor',
	'picker',
	'executer',
	'executer_junior',
	'barcode_executer',
	'stocktaker',
	'head_executer',
	'quality_control_manager',
	'vice_store_admin',
	'store_admin',
	'kitchen_manager',
	'dc_admin',
	'support',
	'telemarketing',
	'company_support',
	'support_ro',
	'company_support_ro',
	'support_it',
	'company_support_it',
	'support_courier',
	'company_support_courier',
	'supervisor',
	'head_supervisor',
	'expansioner_ro',
	'expansioner',
	'category_manager',
	'company_category_manager',
	'kitchen_category_manager',
	'territorial_kitchen_manager',
	'content_manager',
	'city_head',
	'employee_audit',
	'chief_audit',
	'company_chief_audit',
	'courier_manager',
	'staff_courier_manager',
	'finance_manager',
	'courier_company_admin',
	'courier_admin',
	'company_courier_admin',
	'logistic_admin',
	'company_admin',
	'company_admin_ro',
	'chief_manager',
	'company_chief_manager',
	'analyst',
	'company_analyst',
	'admin_ro',
	'admin',
] as UserRoles[];
export const userRolesNames = makeServerTranslations(userRolesValues, 'enums.USER_ROLES');

export type UserStatuses = 'active' | 'disabled';
export const userStatusesValues = ['active', 'disabled'] as UserStatuses[];
export const userStatusesNames = makeServerTranslations(userStatusesValues, 'enums.USER_STATUSES');

export type UserProviders = 'guest' | 'yandex' | 'yandex-team' | 'auth-api' | 'test' | 'test_fake' | 'internal';
export const userProvidersValues = [
	'guest',
	'yandex',
	'yandex-team',
	'auth-api',
	'test',
	'test_fake',
	'internal',
] as UserProviders[];
export const userProvidersNames = makeServerTranslations(userProvidersValues, 'enums.USER_PROVIDERS');

export type UserSessionSource = 'tsd' | 'admin' | 'lavkach';
export const userSessionSourceValues = ['tsd', 'admin', 'lavkach'] as UserSessionSource[];
export const userSessionSourceNames = makeServerTranslations(userSessionSourceValues, 'enums.USER_SESSION_SOURCE');
