import type { ButtonProps } from 'antd';
import { Button, Tooltip } from 'antd';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LinkRegular } from '~images/icons/link-regular.svg';
import copyToClipboard from '~shared/utils/copyToClipboard';
import Icon from '~shared/utils/icons';
import { useStyles } from '~styles/theme';

interface Props {
	copyText: string;
	className?: string;
	successText?: string;
	customIcon?: any;
	tooltipText?: string;
	type?: ButtonProps['type'];
	ghost?: boolean;
	dataTest?: string;
	children?: ReactNode;
	stopPropagation?: boolean;
}

const CopyButton = ({
	copyText,
	className,
	successText,
	customIcon,
	tooltipText,
	type,
	ghost,
	dataTest,
	children,
	stopPropagation,
}: Props) => {
	const [t] = useTranslation();
	const { cx } = useStyles();

	const button = (
		<Button
			data-test={dataTest}
			type={type}
			ghost={ghost}
			className={cx(!children ? 'button-icon' : undefined, className)}
			onClick={(e) => {
				if (stopPropagation) {
					e.stopPropagation();
				}
				copyToClipboard(copyText, successText ?? t('Ссылка скопирована'));
			}}
		>
			{children ?? <Icon component={customIcon ? customIcon : LinkRegular} />}
		</Button>
	);

	return tooltipText ? <Tooltip title={tooltipText}>{button}</Tooltip> : button;
};

export default CopyButton;
