import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	periodControlsBlock: {
		marginBlock: '10px 20px',
		marginInline: '0',
	},
	typeSelect: {
		inlineSize: 160,
	},
}));
