import { create } from 'zustand';

interface MenuState {
	collapsed: boolean;
	toggleCollapsed: () => void;
	open: boolean;
	setOpen: (open: boolean) => void;
}

export const useMenuStore = create<MenuState>()((set) => ({
	collapsed: false,
	toggleCollapsed: () => set((state) => ({ collapsed: !state.collapsed })),
	open: false,
	setOpen: (open) => set(() => ({ open })),
}));
