import { Col, Row } from 'antd';

import type { EntityMap, EntityName } from '~cache/apiLoadProperties';
import { checkPermit } from '~zustand/userData';

import IdReplacer from '../../IdReplacer';
import { useStyles } from '../styles';
import type { FieldProps } from '../types';
import { getColSpan } from '../utils';

export interface IdReplacerFieldProps extends FieldProps {
	entity: EntityName;
	path?: string;
	titleField: keyof EntityMap[EntityName] | Array<keyof EntityMap[EntityName]>;
	dataTest?: string;
	additional?: {
		dataTest?: string;
		content: string;
	};
	textEmpty?: boolean;
	key: string;
	permit?: string;
	hideLabel?: boolean;
	colspan?: Record<string, { span: number }>;
}

const IdReplacerField = ({
	input,
	label,
	entity,
	path,
	titleField,
	additional,
	hideLabel,
	colspan,
	key,
	dataTest,
	permit,
	textEmpty = false,
}: IdReplacerFieldProps) => {
	const { cx, classes } = useStyles();

	return (
		<Row>
			{!hideLabel && (
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label className={classes.label} htmlFor={key}>
						{label}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
			)}
			<Col {...getColSpan(colspan)} className={cx(classes.readOnlyFieldContainer, classes.boldReadOnlyFieldContainer)}>
				<IdReplacer
					id={input.value}
					type={entity}
					keyReplaceOn={titleField}
					dataTest={dataTest ?? `data form ${label}`}
					link={path ? `/${path}/${input.value}` : undefined}
					isLinkAccessible={permit ? checkPermit(permit) : true}
					textEmpty={textEmpty}
				/>
				{additional && input.value && <span data-test={additional.dataTest}>{` (${additional.content})`}</span>}
			</Col>
		</Row>
	);
};

export default IdReplacerField;
