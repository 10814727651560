import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const EquipmentDictionary = lazy(() => import('../components/Equipment/Dictionary'));

const equipmentDictionary = makeRoutes([
	{
		path: '/equipment_dictionary',
		element: <EquipmentDictionary />,
		exp: 'exp_lavka4',
	},
]);

export default equipmentDictionary;
