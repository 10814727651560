import { blue, gold } from '@ant-design/colors';

import { tabletMaxWidth } from '~constants/breakPoints';
import { makeStyles } from '~styles/theme';

import { gray } from './colors';

export const useStyles = makeStyles()((theme) => ({
	highlightRow: {
		'& td': {
			backgroundColor: '#e6f7ff',
		},
	},
	tableContainer: {
		flex: '1 1',
	},
	table: {
		backgroundColor: theme.bgColor.main,
		position: 'relative',
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: 12,
		[`@media (max-width:${tabletMaxWidth}px)`]: {
			flexDirection: 'column',
			alignItems: 'flex-end',
		},
	},
	toolbarButton: {
		blockSize: 36,
		[`@media (max-width:${tabletMaxWidth}px)`]: {
			marginBlockEnd: 36,
		},
	},
	searchField: {
		marginInlineStart: 36,
	},
	searchFieldButton: {
		padding: 5,
	},
	myStoreSwitchBlock: {
		display: 'flex',
		alignItems: 'center',
	},
	switch: {
		marginInlineEnd: 12,
	},
	rowEdited: {
		'& td': {
			backgroundColor: gold[1],
		},
	},
	rowAdded: {
		'& td': {
			backgroundColor: blue[1],
		},
	},
	rowSelected: {
		'& td': {
			backgroundColor: theme.bgColor.secondary,
		},
	},
	container: {
		blockSize: '100%',
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'column',
		blockSize: '100%',
		position: 'relative',
	},
	flexItemContainer: {
		flex: '1 1 auto',
	},
	fullHeightTableContainer: {
		blockSize: '100%',
		'& .ant-card': {
			blockSize: '100%',
		},
		'& .ant-card-body': {
			blockSize: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		'& .ant-table-wrapper': {
			flexGrow: 1,
		},
		'& .ant-spin-nested-loading': {
			blockSize: '100%',
		},
		'& .ant-table-layout-fixed': {
			blockSize: '100%',
		},
		'& .ant-spin-container': {
			blockSize: '100%',
		},
		'& .ant-table-container': {
			blockSize: '100%',
		},
		'& .ant-table-content': {
			blockSize: '100%',
		},
	},
	link: {
		color: '#177DDC',
	},
	printDiv: {
		display: 'none',
	},
	groupButtons: {
		marginInlineStart: 'auto',
		display: 'flex',
		alignItems: 'center',
		'&>*:first-child': {
			borderRadius: '4px 0 0 4px !important',
		},
		'&>*:last-child': {
			borderRadius: '0 4px 4px 0 !important',
		},
	},
	radioButton: {
		color: theme.text.primary,
		backgroundColor: theme.bgColor.main + '!important',
		'& i': {
			color: '#8c8c8c',
		},
		'&.ant-radio-button-wrapper-checked': {
			'& .anticon': {
				color: '#1890ff',
			},
		},
		'&:hover': {
			backgroundColor: theme.bgColor.main,
			borderColor: '#1890ff',
			'& i': {
				color: '#1890ff',
			},
		},
		'&[disabled]': {
			backgroundColor: theme.bgColor.main + '!important',
			borderColor: theme.border.disabled + '!important',
			'& i': {
				color: theme.text.secondary,
			},
		},
	},
	productViewBtn: {
		padding: 0,
	},
	leftToolbarItem: {
		display: 'flex',
		'&>*': {
			marginInlineEnd: 8,
		},
		'&>*:last-child': {
			marginInlineEnd: 0,
		},
	},
	splitterContent: {
		inlineSize: '100%',
		blockSize: '100%',
		minBlockSize: 'calc(100vh - 188px)',
		position: 'relative',
	},
	splitterContentSplited: {
		inlineSize: '100%',
		blockSize: '100%',
		position: 'relative',
		paddingBlockStart: 20,
	},
	splitterLayout: {
		blockSize: 'auto',
		'& .layout-pane': {
			paddingInlineStart: 16,
		},
		'& .layout-pane-primary': {
			paddingInlineStart: 0,
			paddingInlineEnd: 16,
		},
		'& .layout-splitter': {
			inlineSize: 2,
			backgroundColor: gray[3],
		},
	},
	tableButtons: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		'&>*': {
			marginInlineEnd: 8,
		},
		'&>*:last-child': {
			marginInlineEnd: 0,
		},
	},
	warningIcon: {
		marginInlineStart: 8,
	},
	tabs: {
		inlineSize: '100%',
		'& .ant-tabs-bar': {
			marginBlockEnd: 0,
		},
	},
	flexTabs: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
		'& .ant-tabs-content': {
			blockSize: 'calc(100% - 45px)',
		},
	},
	amountBlock: {
		fontWeight: 'normal',
		display: 'inline-block',
		marginInlineStart: 8,
	},
	tabText: {
		'&>.status-tag': {
			marginInlineEnd: 0,
			marginInlineStart: 8,
		},
	},
	tabSeparated: {
		position: 'relative',
		'&:before': {
			content: "''",
			insetInlineStart: -16,
			insetBlockStart: -2,
			display: 'inline-block',
			position: 'absolute',
			blockSize: 20,
			inlineSize: 2,
			borderRadius: 4,
			backgroundColor: theme.border.disabled,
		},
	},
	positiveDiff: {
		color: 'green',
	},
	negativeDiff: {
		color: 'red',
	},
	flexWrapper: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		gap: 10,
	},
	stocksImportBlock: {
		display: 'flex',
		flexDirection: 'row-reverse',
		gap: 40,
		marginBlockEnd: 10,
	},
	productTitleBlock: {
		display: 'flex',
	},
	externalId: {
		display: 'inline-block',
		inlineSize: 120,
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	titleWrapper: {
		marginInlineEnd: 10,
	},
}));
