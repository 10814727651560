import type { DependencyList, MutableRefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

interface Params {
	minHeight?: number;
	selector?: string;
	deps?: DependencyList;
}

export default function ({ minHeight = 200, selector = '.ant-table-body', deps = [] }: Params): {
	refContainer: MutableRefObject<any>;
	scrollObj: { y: number };
} {
	const refContainer = useRef<HTMLElement>(null);
	const [height, setHeight] = useState<number>(minHeight);

	useEffect(() => {
		if (!refContainer.current) return;

		const tableBody = refContainer.current.querySelector(selector) as HTMLElement;
		if (!tableBody) return;

		const box = tableBody.getBoundingClientRect();

		const windowResizeHandler = () => {
			const heightToSet = document.documentElement.clientHeight - box.top;
			if (heightToSet > minHeight) {
				setHeight(heightToSet);
			}
		};

		windowResizeHandler();

		window.addEventListener('resize', windowResizeHandler);

		return () => {
			window.removeEventListener('resize', windowResizeHandler);
		};
	}, deps);

	return {
		refContainer,
		scrollObj: {
			y: height,
		},
	};
}
