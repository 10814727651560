import { scheduleTypeCases } from '~constants/timetableTypes';
import type { TimetableItemTypes } from '~server-types/doc/api/models/timetable';
import { arrayUniq } from '~shared/utils/arrayUniq';
import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { Timetable } from '~types/common';

export const repeatTypeDictionary = makeDynamicTranslations({
	no: tDynamic('Нет'),
	weekday: tDynamic('По дням недели'),
	day_interval: tDynamic('Раз в промежуток времени'),
});

export const getRepeatType = (timetable: Timetable[]): keyof typeof repeatTypeDictionary => {
	if (timetable.length === 0) {
		return 'no';
	}

	const repeatItem = timetable.find((item) => item.type === 'day_interval');
	if (repeatItem && repeatItem.repeat) {
		return 'day_interval';
	}

	return 'weekday';
};

export const convertScheduleIntoValue = (timetable: Timetable[]) => {
	if (timetable.length === 0) {
		return;
	}

	const repeatItem = timetable.find((item) => item.type === 'day_interval');
	if (repeatItem) {
		return repeatItem.repeat;
	}

	const specialCases = Object.keys(scheduleTypeCases);

	const types = timetable
		.filter((item) => item.type !== 'day_interval')
		.flatMap((item) => {
			if (specialCases.includes(item.type)) {
				return scheduleTypeCases[item.type];
			}
			return item.type;
		});

	return arrayUniq(types);
};

export const transformRawDaysToTypes = (days: string[]): TimetableItemTypes[] => {
	const daysSet = new Set<string>(days);
	for (const type in scheduleTypeCases) {
		if (scheduleTypeCases[type].every((day) => daysSet.has(day))) {
			scheduleTypeCases[type].forEach((day) => daysSet.delete(day));
			daysSet.add(type);
			break;
		}
	}
	return [...daysSet] as TimetableItemTypes[];
};
