import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

import type { SectionConfig } from './types';

export const makeWaveFields: SectionConfig[] = makeDynamicTranslations([
	{
		label: '',
		fields: [
			{
				key: 'limit',
				label: tDynamic('Количество волн'),
				type: 'string',
				inputType: 'number',
				canEdit: true,
				defaultValue: 1,
			},
		],
	},
]);
