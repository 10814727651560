import { Col, Row, Select } from 'antd';
import { Fragment } from 'react';

import { useStyles } from '../styles';
import type { FieldProps } from '../types';
import { EmptyValue, getColSpan, getViewText } from '../utils';
import { useStyles as useTagStyles } from './styles';

const { Option } = Select;

interface TagFieldProps extends FieldProps {
	options: string[];
	dictionary?: Record<string, string>;
	path: string;
	emptyPlaceholder?: string;
	fieldRequired?: boolean;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	tagView?: boolean;
	colorDictionary?: Record<string, string>;
	tagClassName?: string;
	onlyView?: boolean;
	width?: string;
}

const TagField = ({
	id,
	label,
	input,
	meta: { error },
	disabled,
	options,
	path,
	editMode,
	dictionary,
	colspan,
	fieldRequired,
	emptyPlaceholder,
	tagView,
	colorDictionary,
	tagClassName,
	onlyView,
	width,
}: TagFieldProps) => {
	const { cx, classes } = useStyles();
	const { classes: tagClasses } = useTagStyles({ error });
	if (input.value === '') input.value = [];

	return (
		<Row align="top" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={{ inlineSize: width }}>
			<Col {...getColSpan(colspan)} className={classes.labelContainer}>
				<label
					className={cx(classes.label, {
						[classes.labelRequired]: editMode && fieldRequired,
					})}
					htmlFor={id}
				>
					{label}
				</label>
				<div className={classes.dotSeparator} />
			</Col>
			<Col {...getColSpan(colspan)} className={classes.readOnlyFieldContainer}>
				{!onlyView && editMode ? (
					<div className={cx(classes.inputContainer, 'wms-input-container')}>
						<Select
							id={id}
							className={tagClasses.tag}
							{...input}
							onFocus={input.onFocus as () => void}
							disabled={disabled}
							data-test={`data form ${label}`}
							mode="tags"
							popupClassName={`data-test-select-dropdown-${label.replace(/\s/g, '-')}`}
						>
							{options.map((option) => (
								<Option
									key={option}
									value={option}
									data-test={`data form option select ${dictionary?.[option] || option}`}
								>
									{dictionary && dictionary[option] ? dictionary[option] : option}
								</Option>
							))}
						</Select>
					</div>
				) : input.value?.length > 0 ? (
					<div className={classes.valueOnViewMode} data-test={`data form ${label}`}>
						{input.value.map((value: any) => (
							<Fragment key={value}>
								{getViewText({
									value,
									dictionary,
									path,
									params: {
										tagView,
										colorDictionary,
										className: tagClassName,
									},
								})}
							</Fragment>
						))}
					</div>
				) : (
					<p className={classes.valueOnViewMode} data-test={`data form ${label}`}>
						{emptyPlaceholder ? <span className={classes.valueOnViewMode}>{emptyPlaceholder}</span> : <EmptyValue />}
					</p>
				)}
			</Col>
		</Row>
	);
};

export default TagField;
