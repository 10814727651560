import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type {
	AdjustmentReason,
	CompensationAdjustmentSave,
	CompensationsAdjustments,
	CompensationsAdjustmentsExportType,
	CompensationsAdjustmentsListType,
	CompensationsImports,
	CompensationsImportsListType,
} from '~types/compensationsAdjustments';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	reasons: {
		list: makeListFn<EmptyObject, AdjustmentReason>('/api/supply/admin/adjustment_reasons/list'),
	},
	adjustments: {
		list: makeListFn<CompensationsAdjustmentsListType, CompensationsAdjustments>('/api/supply/admin/adjustments/list'),
		load: makeLoadFn<CompensationsAdjustments, 'adjustment_id'>('/api/supply/admin/adjustments/load', 'adjustment_id'),
		save: (input: CompensationAdjustmentSave, config?: AxiosAdditionalConfig) => {
			return axios.post<{
				result: CompensationsAdjustments;
			}>('/api/supply/admin/adjustments/save', input, config);
		},
		delete: (input: { adjustment_id: string }, config?: AxiosAdditionalConfig) => {
			return axios.post('/api/supply/admin/adjustments/delete', input, config);
		},
		import: (input: { csv: string; filename: string; external_id: string; comment: string }) =>
			axios.post('/api/supply/admin/adjustments/import', input),
		export: (input: CompensationsAdjustmentsExportType, config?: AxiosAdditionalConfig) =>
			axios.post<{ csv: string }>('/api/supply/admin/adjustments/export', input, config),
	},
	imports: {
		list: makeListFn<CompensationsImportsListType, CompensationsImports>('/api/supply/admin/adjustments_imports/list'),
		load: makeLoadFn<CompensationsImports, 'adjustments_import_id'>(
			'/api/supply/admin/adjustments_imports/load',
			'adjustments_import_id'
		),
		delete: (input: { adjustments_import_id: string }, config?: AxiosAdditionalConfig) => {
			return axios.post<EmptyObject>('/api/supply/admin/adjustments_imports/delete', input, config);
		},
		export_errors: (input: { adjustments_import_id: string }, config?: AxiosAdditionalConfig) => {
			return axios.post<{ csv: string }>('/api/supply/admin/adjustments_imports/export_errors', input, config);
		},
	},
} as const;
