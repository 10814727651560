import dayjs from 'dayjs';

import { dateFormat } from '~constants/date';

import { INTERVAL_TYPES, INTERVAL_TYPES_WITH_TIME, timeFormat, weekDaysTypes, weekend, workDays } from './constants';
import type { PrepareCheckProjectsData, PreparedEvent, PrepareSchetsData, PrepareTimetableData } from './types';

export const getDaysOfWeek = (localDate: string): dayjs.Dayjs[] => {
	const start = dayjs(localDate, dateFormat).startOf('week');

	const weekDays = [start];
	for (let i = 0; i < 6; i++) {
		weekDays.push(weekDays[i].add(1, 'day'));
	}
	return weekDays;
};

export const limitDate = (date: dayjs.Dayjs): boolean =>
	date.isBefore(dayjs().startOf('week')) || date.isAfter(dayjs().startOf('week').add(4, 'week').subtract(1, 'day'));

export const compareTime = (a: string, b: string): number => {
	const [hourA, minA] = a.split(':');
	const [hourB, minB] = b.split(':');

	return hourA !== hourB ? Number(hourA) - Number(hourB) : Number(minA) - Number(minB);
};

export const compareDate = (a: dayjs.Dayjs, b: dayjs.Dayjs): boolean => a.startOf('day').diff(b.startOf('day')) <= 0;

export const prepareSchetsData = ({ schets, eventsData, endOfWeek, timezone }: PrepareSchetsData) => {
	schets.forEach(({ schedule, handler, kwargs }) => {
		if (!schedule) {
			return;
		}
		if (handler !== 'create_check_valid' && handler !== 'create_writeoff') {
			return;
		}

		const day = dayjs(schedule.start_time).tz(timezone); // день начала запуска таска
		const time = day.format(timeFormat); // время запуска таска
		const key = day.format(dateFormat);
		const preparedEvent: PreparedEvent = {
			time,
			type: kwargs.order_type,
			isRepeated: true,
		};

		eventsData[key]?.push(preparedEvent);

		let intervalDate = day;
		const intervalType = INTERVAL_TYPES.find((type) => type in schedule.interval);
		const intervalValue: number | undefined = intervalType && schedule.interval[intervalType];

		if (intervalType === undefined || intervalValue === undefined) {
			return;
		}

		while (endOfWeek.diff(intervalDate, intervalType) > 0) {
			intervalDate = intervalDate.add(intervalValue, intervalType);
			const preparedNewDateKey = intervalDate.format(dateFormat);

			eventsData[preparedNewDateKey]?.push(
				INTERVAL_TYPES_WITH_TIME.includes(intervalType)
					? {
							...preparedEvent,
							time: intervalDate.format(timeFormat),
						}
					: preparedEvent
			);
		}
	});
};

// Добавляем в календарь периодические события с timetable
export const prepareTimetableData = ({ data, from, to, eventsData, title }: PrepareTimetableData) => {
	data.forEach((ttb) => {
		const { type, repeat, begin } = ttb;
		const preparedEvent: PreparedEvent = {
			time: begin.slice(0, begin.length - 3), // оставляем только час и минуты
			type: 'check',
			title,
			isRepeated: true,
		};

		// Обрабатываем типы с интервалом 'everyday', 'day_interval'
		if (['everyday', 'day_interval'].includes(type)) {
			const repeatedInterval = type === 'everyday' ? 1 : repeat!;
			let intervalDate = from;

			if (typeof repeatedInterval == 'number') {
				while (intervalDate.diff(to, 'hour') < 0) {
					const preparedNewDateKey = intervalDate.format(dateFormat);

					if (eventsData[preparedNewDateKey]) {
						eventsData[preparedNewDateKey].push(preparedEvent);
					}

					intervalDate = intervalDate.add(repeatedInterval, 'day');
				}
			}
		} else {
			// обрабатываем типы с интервалом "weekend", "workday" или "дни недели": monday, tuesday ...
			let interval = [type as string];

			if (type === 'weekend') {
				interval = weekend;
			}

			if (type === 'workday') {
				interval = workDays;
			}

			interval.forEach((weekDay) => {
				const eventsDataKey = Object.keys(eventsData).find(
					(date: string) => weekDaysTypes[dayjs(date).day()] === weekDay
				);
				if (eventsDataKey && dayjs(eventsDataKey).diff(to, 'day') <= 0) {
					eventsData[eventsDataKey].push(preparedEvent);
				}
			});
		}
	});
};

export const prepareCheckProjectsData = ({
	checkProjects,
	startOfWeek,
	endOfWeek,
	eventsData,
	timezone,
}: PrepareCheckProjectsData) => {
	checkProjects.forEach((checkProject) => {
		const { schedule, title } = checkProject;
		const { begin, end, timetable } = schedule;
		const fromDay = (startOfWeek.diff(dayjs(begin), 'day') >= 0 ? startOfWeek : dayjs(begin)).tz(timezone);
		const toDay = (endOfWeek.diff(dayjs(end), 'day') >= 0 ? dayjs(end).endOf('day') : endOfWeek).tz(timezone);

		// Если ЛИ одноразовая
		if (fromDay.diff(toDay, 'day') === 0) {
			if (startOfWeek.diff(fromDay, 'day') <= 0 && endOfWeek.diff(fromDay, 'day') > 0) {
				const preparedNewDateKey = fromDay.format(dateFormat);

				if (eventsData[preparedNewDateKey]) {
					eventsData[preparedNewDateKey].push({
						time: fromDay.format(timeFormat),
						type: 'check',
						title,
						isRepeated: false,
					});
				}
			}
		} else {
			prepareTimetableData({ data: timetable, from: fromDay, to: toDay, eventsData, title });
		}
	});
};
