import { memo, useCallback } from 'react';

import api from '~/api';
import { PersonalDataButton } from '~shared/components/PersonalDataButton';

interface Props {
	courierId: string;
	disabled?: boolean;
}

export const CourierPhone = memo(({ courierId, disabled }: Props) => {
	const loadPhone = useCallback((): Promise<string> => {
		return api.couriers.load_phone({ courier_id: courierId }).then((e) => e.data.phones.join(', '));
	}, [courierId]);

	return <PersonalDataButton disabled={disabled} loadData={loadPhone} className="data-test-pd-courier-phone-button" />;
});
