import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const ShiftTagsPage = lazy(() => import('../components/ShiftTags'));
const ShiftTagsDataPage = lazy(() => import('../components/ShiftTags/ShiftTagsDataPage'));

const shiftTags = makeRoutes([
	{
		path: '/shift_tags',
		permit: 'courier_shift_tags_seek',
		element: <ShiftTagsPage />,
	},
	{
		path: '/shift_tags/add',
		permit: 'courier_shift_tags_create',
		element: <ShiftTagsDataPage editMode addMode />,
	},
	{
		path: '/shift_tags/edit/:courier_shift_tag_id',
		permit: 'courier_shift_tags_save',
		element: <ShiftTagsDataPage editMode />,
	},
	{
		path: '/shift_tags/:courier_shift_tag_id',
		permit: 'courier_shift_tags_load',
		element: <ShiftTagsDataPage />,
	},
]);

export default shiftTags;
