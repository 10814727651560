import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Couriers } from '~types/couriers';
import type { Orders } from '~types/orders';

type CourierStatsRequest = {
	courier_id: Couriers.CourierStats['courier_id'];
	period: Couriers.CourierStats['stats_period_type'];
};

type CourierPaymentsRequest = {
	courier_id: Couriers.CourierStats['courier_id'];
	period: string;
};

export default {
	// TODO: LAVKASUPPLYDEV-259: Пункт 2
	shiftsStats: makeListFn<CourierStatsRequest, Couriers.CourierStats<'courier_shifts'>>(
		'/api/supply/admin/courier_bio/shifts_stats'
	),

	ordersStats: makeListFn<CourierStatsRequest, Couriers.CourierStats<'orders'>>(
		'/api/supply/admin/courier_bio/orders_stats'
	),

	orders: makeListFn<Orders.OrdersRequest, Orders.Order>('/api/supply/admin/courier_bio/orders'),

	payments_periods: makeListFn<{ courier_id: string }, string[]>('/api/salaries/admin/courier_bio/payments_periods'),

	export_payments: (input: CourierPaymentsRequest) =>
		axios.post<Blob>('/api/salaries/admin/courier_bio/export_payments', input, {
			responseType: 'blob',
		}),
};
