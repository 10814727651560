import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';
import ImageGallery from 'react-image-gallery';

import { ReactComponent as ChevronLeftSolid } from '~images/icons/chevron-left-solid.svg';
import { ReactComponent as ChevronRightSolid } from '~images/icons/chevron-right-solid.svg';
import Icon from '~shared/utils/icons';
import imageSizeReplacer from '~shared/utils/imageSizeReplacer';

import { useStyles } from './styles';

interface Props {
	images: string[];
}

const Gallery = ({ images }: Props) => {
	const { cx, classes } = useStyles();
	const imagesForGallery = images.map((imageUrl) => ({
		original: imageSizeReplacer(imageUrl, 'm'),
		thumbnail: imageSizeReplacer(imageUrl, 's'),
	}));

	const renderLeftNav = useCallback((onClick: MouseEventHandler<HTMLElement>, disabled: boolean) => {
		return (
			<Icon
				className={cx(classes.navIcon, classes.navIconLeft)}
				component={ChevronLeftSolid}
				disabled={disabled}
				onClick={onClick}
			/>
		);
	}, []);

	const renderRightNav = useCallback((onClick: MouseEventHandler<HTMLElement>, disabled: boolean) => {
		return (
			<Icon
				className={cx(classes.navIcon, classes.navIconRight)}
				component={ChevronRightSolid}
				disabled={disabled}
				onClick={onClick}
			/>
		);
	}, []);

	return (
		<ImageGallery
			items={imagesForGallery}
			renderLeftNav={renderLeftNav}
			renderRightNav={renderRightNav}
			showFullscreenButton={false}
			showPlayButton={false}
			additionalClass={classes.gallery}
		/>
	);
};

export default Gallery;
