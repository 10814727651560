import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const statuses = ['active', 'disabled', 'removed'];

export const statusesDictionary = makeDynamicTranslations({
	active: tDynamic('Активный'),
	disabled: tDynamic('Отключенный'),
	removed: tDynamic('Удаленный'),
	using: tDynamic('Используется'),
	excluded: tDynamic('Исключен'),
	parent: tDynamic('Родитель'),
	archive: tDynamic('Архивный'),
});

export const statusesColorDictionary = {
	active: 'green',
	disabled: '',
	removed: 'red',
	deleted: 'volcano',
	using: 'lime',
	excluded: 'purple',
	parent: 'blue',
	archive: '',
};
