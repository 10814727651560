import { areArraysIntersect } from '~shared/utils/arrayDiff';
import type { Orders } from '~types/orders';
import type { Shelves } from '~types/shelves';
import type { Stores } from '~types/stores';

const addTagsToOrder = (
	order: Orders.Order,
	cluster: Stores.Cluster | undefined,
	shelves: Record<string, Pick<Shelves.Shelf, 'type' | 'tags'> | undefined>
) => {
	const isKitchen = order.shelves.some((shelf) => shelves[shelf]?.type === 'kitchen_on_demand');
	const isFrozen = order.shelves.some((shelf) => {
		const { tags: shelfTags } = shelves?.[shelf] || {};
		return Array.isArray(shelfTags) && areArraysIntersect(shelfTags, ['freezer', 'freezer24']);
	});
	const weightLimit = cluster?.dispatch_setup?.delivery_max_weight ?? 15;

	return {
		...order,
		hasParcel: order.required.some((element) => element.item_id),
		isHeavy: typeof order.vars?.total_order_weight === 'number' && order.vars.total_order_weight > weightLimit * 1_000,
		isFragile: order.conditions?.fragile,
		isKitchen,
		isFrozen,
		isUltima: order.attr?.client_tags?.includes('ultima_package'),
	};
};

export default addTagsToOrder;
