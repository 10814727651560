import { lazy } from 'react';

import { useCache } from '~cache/useCache';
import { shelfTagsNames, shelfTypesNames } from '~server-types/doc/api/models/shelf';
import type { Shelves } from '~types/shelves';

const CodeCard = lazy(() => import('./index'));

type Props = {
	shelf: Shelves.Shelf;
	cardDataTest?: string;
	onlyPrint?: boolean;
};

export function ShelfCard({ shelf, cardDataTest, onlyPrint }: Props) {
	const cache = useCache({
		stores: shelf.store_id,
	});

	return (
		<CodeCard
			key={shelf.shelf_id}
			barcodeId={shelf.shelf_id}
			barcode={shelf.barcode}
			cardTitle={shelf.title}
			titleLevel={2}
			cardDataTest={cardDataTest}
			onlyPrint={onlyPrint}
			additionalData={{
				rack: shelf.rack,
				tags: shelf.tags.map((tag) => shelfTagsNames[tag]).join(', '),
				type: shelfTypesNames[shelf.type],
				store: cache.stores[shelf.store_id]?.title,
			}}
		/>
	);
}
