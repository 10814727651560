/*global google*/
import { notification } from '@lavka/ui-kit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import usePrevious from '~shared/hooks/usePrevious';
import type { Stores } from '~types/stores';
import { useUserTheme } from '~zustand/userSettings';

import { getPlacemarkCoords } from '../../../../components/StoresPage/StoresDataPage/GeoData/utils';
import {
	deletePolygonNode,
	fullScreenEventListener,
	getPolygonsBounds,
	googlePolygonsToPolygons,
	markerToPlacemark,
	polygonsToGooglePolygons,
	setTheme,
} from './utils';

interface Props {
	isReady?: boolean;
	polygons: (
		| {
				items: Stores.Zone;
				options: google.maps.PolygonOptions;
		  }
		| undefined
	)[];
	polygonOptions?: google.maps.PolygonOptions;
	initPolygonsDeps?: any[]; // зависимости, которые перезагружают полигоны
	placemark?: {
		item: Stores.Location;
		options?: Record<string, any>;
	};
	initPlacemarkDeps?: any[];
	onChange?: (data: { polygons?: number[][][]; placemark?: Stores.Location }) => void;
	editable?: boolean;
	setMapRef?: (map: google.maps.Map | undefined) => void; // передаем карту в родительские компоненты
	allStoresControls?: {
		objectsOnMap: any[];
		setBounds: (bounds: Stores.Bounds) => void;
		clearRenderedStores: () => void;
		isLoading: boolean;
	}; // параметры для отображения всех складов на карте, функция находится в родительском компоненте
	fullScreenCallback?: (isFullScreen: boolean) => void; // срабатывает при смене режима fullscreen
}

declare const google: any;

const MapInput = ({
	isReady,
	polygons,
	placemark,
	editable,
	onChange,
	fullScreenCallback,
	polygonOptions,
	initPlacemarkDeps,
	initPolygonsDeps,
	allStoresControls,
	setMapRef,
}: Props) => {
	const [t] = useTranslation();
	const map = useRef<google.maps.Map | undefined>();
	const mapPolygons = useRef<google.maps.Polygon[]>([]);
	const mapPlacemark = useRef<google.maps.Marker | undefined>();
	const mapDrawingManager = useRef<any>();
	const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);
	const isFirstRun = useRef<boolean>(true);
	const [isFullScreen, toggleIsFullScreen] = useState<boolean>(false);
	const prevScreen = usePrevious(isFullScreen);

	const theme = useUserTheme();
	const isLight = theme === 'light';

	const changeAction = () => {
		if (onChange) {
			onChange({
				polygons: googlePolygonsToPolygons(mapPolygons.current),
				placemark: mapPlacemark.current ? markerToPlacemark(mapPlacemark.current) : undefined,
			});
		}
	};

	// перезагрузка плейсмарка, когда меняется зависимость
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		initPlacemark();
	}, initPlacemarkDeps ?? []);

	// перезагрузка полигонов, когда меняется зависимость
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		initPolygons();
	}, initPolygonsDeps ?? []);

	// переключение режима редактирования полигонов
	useEffect(() => {
		// проверяем изменился ли fullscreen c с учетом undefined
		if (prevScreen !== undefined && prevScreen !== isFullScreen) {
			if (map.current && editable && isFullScreen) {
				mapPolygons.current.forEach((polygon) => {
					google.maps.event.addListener(polygon, 'rightclick', (mev: any) => deletePolygonNode(mev, polygon));
					polygon.setOptions({
						fillColor: 'rgba(237, 68, 67, 0.2)',
						strokeColor: '#ED4443',
					});
					polygon.setEditable(true);
				});
				drawPolygon();
			} else {
				mapPolygons.current.forEach((polygon) => {
					polygon.setEditable(false);
					if (polygons[0]?.options) {
						polygon.setOptions(polygons[0]!.options);
					}
				});
				if (allStoresControls) {
					allStoresControls.clearRenderedStores();
					allStoresControls.objectsOnMap.forEach((object) => object.setMap(null));
				}
				if (mapDrawingManager.current) {
					mapDrawingManager.current.setMap(null);
					mapDrawingManager.current = undefined;
				}
				changeAction();
			}
		}
		if (fullScreenCallback) {
			fullScreenCallback(isFullScreen);
		}
	}, [isFullScreen]);

	// функция рисования полигона
	const drawPolygon = () => {
		mapDrawingManager.current = new google.maps.drawing.DrawingManager({
			drawingMode: google.maps.drawing.OverlayType.POLYGON,
			drawingControl: true,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes: [google.maps.drawing.OverlayType.POLYGON],
			},
			polygonOptions: {
				editable: true,
			},
		});
		google.maps.event.addListener(mapDrawingManager.current, 'overlaycomplete', function (event: any) {
			const newPolygon = createPolygon(event.overlay.getPath(), {
				...(polygonOptions || {}),
				fillColor: 'rgba(237, 68, 67, 0.2)',
				strokeColor: '#ED4443',
			});
			mapDrawingManager.current.setDrawingMode(null);
			event.overlay.setMap(null);
			newPolygon.setEditable(true);
			google.maps.event.addListener(newPolygon, 'rightclick', (mev: any) => deletePolygonNode(mev, newPolygon));
		});

		mapDrawingManager.current.setMap(map.current);
	};

	// функция создания полигона
	const createPolygon = (paths: any, options: any): google.maps.Polygon => {
		const gmPolygon = new google.maps.Polygon({
			paths,
			...options,
			fillOpacity: 1,
		});
		mapPolygons.current.push(gmPolygon);
		gmPolygon.setMap(map.current);
		return gmPolygon;
	};

	const initPlacemark = useCallback(() => {
		if (!map.current) return;

		// удаляем существующий плейсмарк
		if (mapPlacemark.current) {
			mapPlacemark.current.setMap(null);
			mapPlacemark.current = undefined;
		}

		if (!placemark) return;

		const placemarkCoords = getPlacemarkCoords(placemark.item);

		if (!placemarkCoords) return;

		// добавляем плейсмарк
		mapPlacemark.current = new google.maps.Marker({
			position: placemarkCoords,
			map: map.current,
			...(placemark.options || {}),
		});

		if (mapPlacemark.current) {
			map.current.setCenter(mapPlacemark.current.getPosition()!);
		}
	}, [placemark]);

	// инициализируем динамические полигоны карты
	const initPolygons = useCallback(() => {
		if (!map.current) return;

		// удаляем существующие полигоны
		if (mapPolygons.current.length) {
			mapPolygons.current.forEach((polygon) => {
				polygon.setMap(null);
			});
			mapPolygons.current = [];
		}
		// добавление полигонов
		polygons.forEach((polygon) => {
			if (polygon) {
				const latLanPolygons = polygonsToGooglePolygons(
					polygon.items.geometry.type === 'Polygon'
						? [polygon.items.geometry.coordinates]
						: polygon.items.geometry.coordinates
				);
				latLanPolygons.forEach((coords) => {
					createPolygon(coords, polygon.options);
				});
			}
		});

		// центрирование карты, если есть полигоны
		if (mapPolygons.current.length) {
			map.current.fitBounds(getPolygonsBounds(mapPolygons.current));
		}
	}, [polygons]);

	// эффект для создания новой карты
	useEffect(() => {
		if (mapContainer && isReady) {
			try {
				let placemarkCoords;

				if (placemark) {
					placemarkCoords = getPlacemarkCoords(placemark.item);
				}

				const initialMapState = {
					center: placemarkCoords || { lng: -0.11, lat: 51.5 },
					zoom: placemark?.options?.zoom ?? 14,
				};

				map.current = new google.maps.Map(mapContainer, initialMapState);
				setTheme(map.current, isLight);
				if (!map.current) {
					return;
				}
				initPlacemark();

				initPolygons();

				let prevBounds: string | undefined = undefined;

				// обработка событий
				map.current.addListener('bounds_changed', () => {
					fullScreenEventListener(map.current!, toggleIsFullScreen);

					if (allStoresControls) {
						const bounds = map.current!.getBounds()?.toJSON();

						if (!bounds) {
							return;
						}

						const boundsString = JSON.stringify(bounds);

						if (prevBounds !== boundsString) {
							// проверяем что изменилось именно значение координат, т. к. ивент срабатывает и с одинаковыми значениями
							allStoresControls.setBounds([
								[bounds.east, bounds.north],
								[bounds.west, bounds.south],
							]);
							prevBounds = boundsString;
						}
					}
				});

				if (setMapRef) {
					setMapRef(map.current);
				}
			} catch {
				notification.error({
					message: t('Не удалось инициализировать карту'),
				});
			}
		}

		// Уничтожаем карту при отмонтировании компонента
		return () => {
			if (setMapRef) {
				setMapRef(undefined);
			}
			map.current = undefined;
		};
	}, [mapContainer, isReady]);

	useEffect(() => {
		setTheme(map.current, isLight);
	}, [isLight]);

	return (
		<div
			data-test="map"
			ref={(element) => setMapContainer(element)}
			style={{ inlineSize: '100%', blockSize: '500px' }}
		/>
	);
};

export default MapInput;
