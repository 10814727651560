import { useContext } from 'react';
import { createMakeAndWithStyles } from 'tss-react';

import { ThemeContext } from '../components/Theme';

function useTheme() {
	return useContext(ThemeContext);
}

export const { makeStyles, withStyles, useStyles } = createMakeAndWithStyles({ useTheme });
