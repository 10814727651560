import type { DependencyList } from 'react';
import { useEffect } from 'react';

import type { EventCallback, Key } from '../utils/ev';
import { subscribe } from '../utils/ev';

interface UseSubscribe {
	key: Key;
	cb: EventCallback;
	unSub?: boolean;
	single?: boolean;
	name?: string;
	needInit?: boolean;
}

export default function (
	{ key, cb, unSub = true, single, name, needInit = false }: UseSubscribe,
	deps: DependencyList = [],
	skip = false
) {
	useEffect(() => {
		if (skip) return;
		if (!window.subscribes) window.subscribes = {};
		if (key.some((item) => !item)) {
			return;
		}
		if (single) {
			if (!window.subscribes[name!]) {
				window.subscribes[name!] = true;
				const unSubscribe = subscribe(key, cb, needInit);
				return unSub ? unSubscribe : undefined;
			}
		} else {
			const unSubscribe = subscribe(key, cb, needInit);
			return unSub ? unSubscribe : undefined;
		}
	}, deps);
}
