/* eslint-disable max-lines */

import { tabletMaxWidth } from '~constants/breakPoints';
import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<void, 'rowIconHideOnHover' | 'debugBlock'>()((theme, _params, classes) => ({
	orderModal: {
		insetBlockStart: '5%',
		inlineSize: '90% !important',
		'& .ant-modal-body': {
			blockSize: '80vh',
		},
	},
	linkButton: {
		paddingBlock: 0,
		paddingInline: 0,
		fontWeight: 500,
	},
	highlightRow: {
		backgroundColor: '#e6f7ff',
	},
	rightColumn: {
		'& .ant-table-container': {
			'&::before': {
				display: 'none',
			},
			'&::after': {
				display: 'none',
			},
		},
		'& .ant-card-body': {
			borderInlineStart: `2px solid ${theme.border.primary}`,
			borderInlineEnd: `2px solid ${theme.border.primary}`,
		},
	},
	leftColumn: {
		'& .ant-table-container': {
			'&::before': {
				display: 'none',
			},
			'&::after': {
				display: 'none',
			},
		},
		'& .ant-card-body': {
			borderInlineStart: `2px solid ${theme.border.primary}`,
		},
	},
	title: {
		marginBlockStart: 16,
		color: `${theme.text.primary} !important`,
	},
	tabs: {
		marginBlockStart: 20,
		'&>.ant-tabs-content-holder': {
			'&>.ant-tabs-content': {
				blockSize: '100%',
			},
		},
	},
	tabPane: {
		display: 'flex',
		flexDirection: 'column',
	},
	modalControls: {
		marginBlockStart: 12,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	numberContainer: {
		display: 'inline-block',
		position: 'relative',
		'&:hover': {
			[`& .${classes.rowIconHideOnHover}`]: {
				display: 'none',
			},
		},
	},
	rowIconHideOnHover: {
		display: 'inline-block',
	},
	orderNumber: {
		display: 'inline-block',
		inlineSize: 'auto',
		whiteSpace: 'nowrap',
		minInlineSize: 114,
	},
	rowIcon: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		insetInlineEnd: 0,
		marginInlineStart: 8,
		inlineSize: 32,
		blockSize: 32,
		'& svg': {
			inlineSize: 16,
			blockSize: 16,
		},
	},
	parentOrderIcon: {
		display: 'inline-block',
		color: theme.text.secondary,
		marginInlineStart: 8,
	},
	inventoryTableToolbar: {
		inlineSize: '100%',
		display: 'flex',
		paddingBlockEnd: 16,
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	toPrint: {
		marginBlock: '5mm',
		marginInline: '5mm',
		'& .update-cover': {
			display: 'none',
		},
	},
	printButton: {
		marginInlineStart: 8,
	},
	rightToolbarBtn: {
		marginInlineStart: 8,
	},
	controlButtons: {
		'&>*': {
			marginBlockEnd: 8,
		},
		'&>*:last-child': {
			marginBlockEnd: 0,
		},
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		columnGap: 8,
		alignItems: 'baseline',
	},
	fullWidthButton: {
		inlineSize: '100%',
		backgroundColor: 'red',
	},
	radioGroup: {
		marginBlockEnd: 16,
		inlineSize: '100%',
		alignItems: 'flex-end',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	attributesButtons: {
		'&>*': {
			marginInlineEnd: 16,
		},
		'&>*:last-child': {
			marginInlineEnd: 0,
		},
	},
	printDiv: {
		overflow: 'hidden',
		blockSize: 0,
		inlineSize: 0,
	},
	printHeader: {
		marginBlockEnd: '5mm',
		fontSize: 10,
	},
	printReport: {
		marginBlockStart: 50,
	},
	printFooter: {
		marginBlockStart: '5mm',
		fontSize: 10,
		pageBreakAfter: 'auto',
	},
	printTitle: {
		textAlign: 'center',
		color: '#595959',
		fontSize: 14,
	},
	printLine: {
		display: 'flex',
		inlineSize: '50%',
		justifyContent: 'space-between',
	},
	tableTitleSeparator: {
		marginInlineEnd: 'auto',
		color: '#595959',
		fontSize: 12,
		marginBlock: '5px',
		marginInline: 0,
	},
	userInformation: {
		paddingInlineStart: 0,
		marginBlockStart: 16,
		'& a': {
			fontWeight: 500,
		},
	},
	innerBlocks: {
		marginBlockEnd: 8,
	},
	checkbox: {
		marginInlineStart: 'auto',
	},
	modalFooter: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	modalFooterControls: {
		display: 'flex',
		flexWrap: 'wrap',
		'&>*': {
			marginInlineEnd: 8,
			marginBlockEnd: 8,
		},
	},
	suggestTypeBlock: {
		display: 'flex',
		alignItems: 'center',
	},
	suggestActionsBlock: {
		display: 'flex',
		alignItems: 'center',
	},
	debugBlock: {
		border: `1px solid ${theme.border.secondary}`,
		borderRadius: 8,
		padding: 16,
		marginBlockStart: 16,
		position: 'relative',
		inlineSize: 'fit-content',
		'&>button': {
			marginInlineEnd: 8,
		},
		'&>button:last-of-type': {
			marginInlineEnd: 0,
		},
	},
	debugBlockTitle: {
		position: 'absolute',
		color: theme.text.primary,
		backgroundColor: theme.bgColor.secondary,
		insetBlockStart: -10,
		fontSize: 12,
		paddingBlock: 0,
		paddingInline: '8px',
		whiteSpace: 'nowrap',
		marginBlock: 'auto',
		marginInline: 'auto',
	},
	modalButtons: {
		blockSize: 32,
		borderRadius: 6,
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '12px',
		lineHeight: '20px',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: 'none',
		boxSizing: 'border-box',
		border: '2px solid transparent!important',
		flex: '1 0',
	},
	modalButtonsTaxi: {
		'&:not([disabled])': {
			color: theme.themeMode === 'light' ? '#AD6800!important' : '#D8BD14!important',
			background: theme.themeMode === 'light' ? 'rgba(255, 241, 184, 0.5)!important' : '#423A11!important',
		},
	},
	modalButtonsCourier: {
		'&:not([disabled])': {
			color: theme.themeMode === 'light' ? '#1890FF!important' : '#3C9AE8!important',
			background: theme.themeMode === 'light' ? '#E6F7FF!important' : '#15395B!important',
		},
	},
	modalWarning: {
		marginBlockEnd: '24px',
		inlineSize: '466px',
		color: gray[6],
	},
	unassignModal: {
		'& .ant-modal-body': {
			padding: 0,
		},
	},
	container: {
		display: 'flex',
		blockSize: '100%',
		[`@media (max-width:${tabletMaxWidth}px)`]: {
			flexDirection: 'column',
			flexBasis: '100%',
		},
	},
	reassignInfoContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	productViewBtn: {
		minInlineSize: '300px',
		textAlign: 'start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		' span': {
			minInlineSize: '300px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	discrepancyReportProductView: {
		maxInlineSize: 198,
		textAlign: 'start',
		'& span': {
			maxInlineSize: 198,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	blocksTitle: {
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '24px',
		marginBlockEnd: 8,
		color: theme.text.primary,
	},
	detailsBlock: {
		display: 'flex',
		flexDirection: 'column',
		borderInlineEnd: `1px solid ${theme.border.primary}`,
		paddingBlock: '16px 20px',
		paddingInline: '24px 16px',
		inlineSize: 300,
		[`@media (max-width:${tabletMaxWidth}px)`]: {
			inlineSize: '100%',
		},
	},
	detailItem: {
		marginBlockEnd: 8,
	},
	buttonsBlock: {
		gridArea: 'buttons',
		padding: '16px',
		paddingBlockEnd: '0',
		paddingInlineStart: '24px',
		boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.06)',
		blockSize: 132,
	},
	allButtonsBlock: {
		blockSize: 180,
	},
	buttonsBlockCheckbox: {
		fontWeight: 500,
		display: 'flex',
		alignItems: 'center',
		'&.ant-checkbox-wrapper': {
			marginBlockEnd: 12,
		},
		'& .ant-checkbox': {
			insetBlockStart: 0,
		},
	},
	buttonsBlockControls: {
		'&>*': {
			marginInlineEnd: 8,
			'&:last-child': {
				marginInlineEnd: 0,
			},
		},
	},
	buttonsBlockButtons: {
		display: 'flex',
		'&>*': {
			marginInlineEnd: 8,
			'&:last-child': {
				marginInlineEnd: 0,
			},
		},
	},
	allButtonsBlockButtons: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: 10,
	},
	couriersBlock: {
		gridArea: 'couriers',

		padding: '16px',
		paddingBlockEnd: '20px',
		paddingInlineStart: '24px',
	},
	detail: {
		fontWeight: 500,
	},
	parentIcon: {
		position: 'relative',
		insetInlineStart: -8,
	},
	childItem: {
		display: 'block',
		paddingInlineStart: '3pt',
	},
	warningIcon: {
		marginInlineStart: 8,
	},
	couriersList: {
		padding: 0,
		listStyle: 'none',
	},
	courierItemContainer: {
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		paddingBlock: '5px',
		paddingInline: '8px',
		background: theme.bgColor.secondary,
		borderRadius: 6,
		border: '2px solid transparent',
		marginBlockEnd: 8,
		blockSize: '100%',
		'&:last-of-type': {
			marginBlockEnd: 0,
		},
		'& svg': {
			marginInlineEnd: 8,
		},
	},
	courierItem: {
		display: 'flex',
	},
	courierItemLink: {
		marginInlineStart: 8,
	},
	courierItemSimple: {
		alignItems: 'center',
	},
	courierName: {
		fontSize: '12px',
		lineHeight: '20px',
		fontWeight: 500,
		margin: 0,
	},
	itemSelected: {
		transitionDuration: '.5s',
		border: `2px solid ${theme.text.selected}!important`,
	},
	couriersGroup: {
		padding: 0,
		listStyle: 'none',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridGap: 8,
		'& .ant-spin': {
			textAlign: 'start',
		},
	},
	couriersGroupTitle: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '20px',
		color: theme.text.icon,
	},
	courierDataItem: {
		margin: 0,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '18px',
		color: theme.text.secondary,
		'&>span': {
			color: '#9254DE',
		},
	},
	shelveNameWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	copyLinkButton: {
		marginInlineStart: '10px',
	},
	clearFiltersWrapper: {
		display: 'flex',
		inlineSize: 'inherit',
	},
	clearFilters: {
		marginInlineStart: 'auto',
	},
	blockEditBtn: {
		cursor: 'pointer',
		'&:hover': {
			color: '#1890ff',
		},
	},
	inventoryTableContainer: {
		'&>div': {
			marginBlockStart: 16,
			'&:first-child': {
				marginBlockStart: 0,
			},
		},
	},
	warehouseGroup: {
		display: 'inline-block',
		maxInlineSize: 130,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',

		'&.fullLength': {
			whiteSpace: 'normal',
			overflow: 'inherit',
		},
	},
	productPrint: {
		display: 'inline-block',
		maxInlineSize: 150,
		whiteSpace: 'normal',
	},
	totalRow: {
		fontWeight: 500,
	},
	invoicePopover: {
		inlineSize: 300,
		maxBlockSize: 500,
		overflow: 'scroll',
	},
	invoiceHeadersRow: {
		display: 'flex',
		gap: 10,
		marginBlockEnd: 15,
	},
	invoiceSuccess: {
		color: theme.text.statusGreen,
	},
	invoiceRefund: {
		color: theme.text.statusRed,
	},
	invoiceNotPaid: {
		color: theme.text.statusVolcano,
	},
	invoiceHeader: {
		fontStyle: 'italic',
		color: theme.text.secondary,
	},
	mainText: {
		color: theme.text.primary,
	},
	approvalMessage: {
		maxInlineSize: '350px',
		fontSize: '16px',
		lineHeight: '22px',
		marginInlineStart: '2px',

		'&.acceptanceMarket': {
			marginInlineStart: 0,
			maxInlineSize: '100%',
		},
	},
	approvalBlock: {
		display: 'flex',
		flexDirection: 'row',
		fontSize: '14px',
		lineHeight: '16px',
		marginBlockStart: '16px',
		marginBlockEnd: '8px',
		backgroundColor: '#FFF7E6',
		borderRadius: '12px',
		inlineSize: '398px',
		blockSize: '48px',
		paddingBlock: '8px',
		paddingInline: '8px 16px',
	},
	approvalIconModal: {
		display: 'block',
		color: '#FA8C16',
		inlineSize: '24px',
		marginInlineStart: '10px',
		marginInlineEnd: '4px',
		marginBlockStart: 'auto',
		marginBlockEnd: 'auto',
		'& svg': {
			blockSize: '20px',
			inlineSize: '20px',
		},
	},
	approvalWrap: {
		marginBlockStart: 'min(200px, 20vh)',
	},
	icon: {
		display: 'flex',
		inlineSize: 32,
		blockSize: 32,
		marginBlockStart: 2,
		borderRadius: 5,
		'&:hover': {
			backgroundColor: theme.bgColor.disabled,
		},
		'& svg': {
			margin: 'auto',
		},
	},
	orderTypeWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	orderType: {
		display: 'block',
		marginInlineEnd: 8,
	},
	scanDocksTab: {
		display: 'flex',
		alignItems: 'center',
		'& .anticon': {
			marginInline: '4px 0',
		},
	},
	orderTypeSubtitle: {
		display: 'inline-block',
		textTransform: 'uppercase',
		paddingBlock: '1px',
		paddingInline: '3px',
		fontSize: 12,
		lineHeight: '16px',
		color: gray[6],
		backgroundColor: gray[3],
		borderRadius: 3,
	},
	done: {
		color: theme.text.statusGreen,
		backgroundColor: theme.bgColor.statusGreen,
	},
	begin: {
		color: theme.text.statusBlue,
		backgroundColor: theme.bgColor.statusBlue,
	},
	export: {
		color: theme.text.statusGold,
		backgroundColor: theme.bgColor.statusGold,
	},
	import: {
		color: theme.text.statusPurple,
		backgroundColor: theme.bgColor.statusPurple,
	},
	orderIndicatorSteps: {
		marginBlockEnd: 24,
	},
	orderCheckNumber: {
		paddingBlock: '1px',
		paddingInline: '3px',
		fontSize: 12,
		background: theme.bgColor.disabled,
		borderRadius: 3,
		marginInlineStart: 5,
	},
	truemarkBlock: {
		marginInlineStart: 10,
	},
	deviationValue: {
		'&.negative': {
			color: theme.text.deviation.negative,
		},
		'&.positive': {
			color: theme.text.deviation.positive,
		},
	},
	shelfValue: {
		display: 'inline-block',
		whiteSpace: 'normal',
	},
	shelfId: {
		marginBlockEnd: 10,
	},
	inventoryDropdownContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		paddingInline: 15,
		paddingBlock: 8,

		'& .ant-btn-link': {
			color: theme.text.primary,
			paddingInline: 4,
			paddingBlock: 8,
		},
	},
	acceptanceMarketContainer: {
		display: 'block',
		blockSize: 392,

		'& .ant-upload.ant-upload-drag': {
			backgroundColor: '#fafafa!important',
			border: '1px solid #d9d9d9',

			'&:hover': {
				backgroundColor: '#e6f7ff!important',
			},
		},
	},
	printActContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	ticketLink: {
		display: 'block',
		marginBlockEnd: 10,
	},
	countWeightCell: {
		display: 'flex',
		flexDirection: 'column',
	},
	weightText: {
		fontSize: '0.9em',
		color: theme.text.light,
		lineHeight: 0.9,
	},
	orderMode: {
		display: 'flex',
		alignItems: 'center',
		columnGap: 8,
	},
	dispatchDeliveryType: {
		display: 'flex',
		columnGap: 8,
	},
	dispatchDeliveryTypeTaxiIcon: {
		marginBlockStart: 2,
	},
}));
