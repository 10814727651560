import dayjs from 'dayjs';
import type { DeepPartial } from 'react-hook-form/dist/types/utils';

import { transformRawDaysToTypes } from '~shared/components/Fields/RepeatScheduleField/utils';
import type { CheckProjects } from '~types/checkProjects';
import type { Timetable } from '~types/common';

import type { CheckProjectFormValues } from '../CheckProjectsDataPage/types';

export const isFormComplete = (formValues: DeepPartial<CheckProjectFormValues>) => {
	const nonScheduledType =
		(formValues.check_project_type === 'threshold' && formValues.threshold) ||
		formValues.check_project_type === 'regrading';

	const scheduleCheck = Boolean(
		formValues.check_project_type === 'schedule'
			? formValues.repeat_type === 'no'
				? formValues.once_date_time
				: formValues.begin_date
			: nonScheduledType
	);
	const endCheck = formValues.has_finish === 'yes' ? formValues.end_date : true;
	const productsCheck =
		formValues.products?.product_id?.length ||
		formValues.products?.product_group_id?.length ||
		formValues.products?.regrading_map?.length;
	const { store_id, cluster_id, company_id } = formValues.stores ?? {};
	const storesCheck = store_id?.length || cluster_id?.length || company_id?.length;

	return Boolean(formValues.title && scheduleCheck && endCheck && storesCheck && productsCheck);
};

export const isProjectComplete = (project: CheckProjects.CheckProject) =>
	Boolean(
		project.title &&
			project.schedule?.begin &&
			(project.check_project_type === 'threshold' ? project.threshold : project.schedule?.end) &&
			(project.stores.cluster_id?.length || project.stores.store_id?.length || project.stores.company_id?.length) &&
			(project.products.product_id?.length ||
				project.products.product_group_id?.length ||
				project.products.regrading_map?.length)
	);

export const isApprovable = (userId: string | undefined, projectVars: CheckProjectFormValues['vars']) =>
	userId !== projectVars.last_edited_by &&
	(projectVars.declined_by === undefined || userId === projectVars.declined_by);

const getTimetable = (values: CheckProjectFormValues): Timetable[] => {
	if (values.check_project_type === 'threshold') {
		return [];
	}

	switch (values.repeat_type) {
		case 'day_interval':
			return [
				{
					type: 'day_interval',
					repeat: values.repeat_interval,
					begin: values.timetable_time ?? '',
					end: values.timetable_time,
					start: dayjs(values.begin_date).format('YYYY-MM-DD'),
				},
			];

		case 'weekday':
			const types = transformRawDaysToTypes(values.repeat_weekdays ?? []);

			return types.map((repeatCase) => ({
				type: repeatCase,
				begin: values.timetable_time ?? '',
				end: values.timetable_time,
			}));
	}

	return [];
};

export const getSchedule = (values: CheckProjectFormValues) => {
	if (values.check_project_type === 'threshold') {
		return {
			timetable: [],
			begin: values.begin_date ? dayjs(values.begin_date).format() : '',
			end: values.end_date ? dayjs(values.end_date).format() : undefined,
		};
	}

	const isOnce = !values.repeat_type || values.repeat_type === 'no';
	const beginDate = isOnce && values.once_date_time ? values.once_date_time : values.begin_date;

	let endDate;

	if (isOnce && values.once_date_time) {
		endDate = dayjs(values.once_date_time).minute(0).format();
	} else if (values.has_finish === 'yes' && values.end_date) {
		endDate = dayjs(values.end_date).endOf('day').format();
	}

	return {
		timetable: isOnce ? [] : getTimetable(values),
		begin: beginDate ? dayjs(beginDate).minute(0).format() : '',
		end: endDate,
	};
};
