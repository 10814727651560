import { memo, useCallback } from 'react';

import api from '~/api';
import { PersonalDataButton } from '~shared/components/PersonalDataButton';

interface Props {
	orderId: string;
	showButton?: boolean;
}

export const CourierPhoneButton = memo(({ orderId, showButton }: Props) => {
	const loadPhone = useCallback((): Promise<string> => {
		return api.orders.loadCourierPhone({ order_id: orderId }).then((response) => response.data.phones.join(', '));
	}, [orderId]);

	return showButton ? (
		<PersonalDataButton disabled={!showButton} loadData={loadPhone} className="data-test-pd-courier-phone-button" />
	) : (
		<>{'—'}</>
	);
});
