// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type CheckProjectStatuses = 'active' | 'waiting_approve' | 'draft' | 'disabled' | 'removed';
export const checkProjectStatusesValues = [
	'active',
	'waiting_approve',
	'draft',
	'disabled',
	'removed',
] as CheckProjectStatuses[];
export const checkProjectStatusesNames = makeServerTranslations(
	checkProjectStatusesValues,
	'enums.CHECK_PROJECT_STATUSES'
);

export type CheckProjectTypes = 'schedule' | 'threshold' | 'regrading';
export const checkProjectTypesValues = ['schedule', 'threshold', 'regrading'] as CheckProjectTypes[];
export const checkProjectTypesNames = makeServerTranslations(checkProjectTypesValues, 'enums.CHECK_PROJECT_TYPES');
