import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Operations } from '~images/icons/operations.svg';
import type { WarehouseOperationsMetrics } from '~shared/utils/prepareWarehouseOperationsMetrics';
import { prepareWarehouseOperationsMetrics } from '~shared/utils/prepareWarehouseOperationsMetrics';
import type { Orders } from '~types/orders';

import ColumnContent from '../../ColumnContent/ColumnContent';
import { useStyles as healthTableauStyles } from '../../styles';
import { useStyles } from './styles';
import { createWarehouseInfoBlockItems } from './utils';

interface Props {
	storeId?: string | null;
	orders: Orders.Order[];
}

interface WarehouseInfoBlockItemProps {
	name: string;
	dataTest: string;
	value: number;
}

const WarehouseInfoBlockItem = ({ name, dataTest, value }: WarehouseInfoBlockItemProps) => {
	const { classes } = useStyles();

	return (
		<div className={classes.warehouseInfoBlockItem}>
			{name}
			<span className={classes.warehouseInfoBlockCount} data-test={dataTest}>
				{value}
			</span>
		</div>
	);
};

const WarehouseOperations = ({ storeId, orders }: Props) => {
	const { classes } = useStyles();
	const [t] = useTranslation();
	const { classes: healthTableauClasses } = healthTableauStyles();

	const calculatedData: WarehouseOperationsMetrics = useMemo(() => prepareWarehouseOperationsMetrics(orders), [orders]);

	const notProcessingItems = createWarehouseInfoBlockItems(calculatedData.notProcessing);
	const processingItems = createWarehouseInfoBlockItems(calculatedData.processing);

	return (
		<div className={healthTableauClasses.column} data-test="warehouse operations">
			<ColumnContent
				icon={Operations}
				iconColor="orange"
				text={t('Складские операции')}
				link={`/stores/${storeId}/orders`}
			>
				<div className={classes.warehouseOperationsSum}>
					{t('Операций всего')} <span data-test="all">{calculatedData.total}</span>
				</div>
			</ColumnContent>
			<ColumnContent
				dataTest="notProcessing"
				isSubTitle
				text={t('Не в работе')}
				amount={calculatedData.notProcessing.total}
			>
				<div className={classes.warehouseInfoBlockWrapper}>
					{notProcessingItems.map((item) => (
						<WarehouseInfoBlockItem key={item.name} {...item} />
					))}
				</div>
			</ColumnContent>
			<ColumnContent dataTest="processing" isSubTitle text={t('В работе')} amount={calculatedData.processing.total}>
				<div className={classes.warehouseInfoBlockWrapper}>
					{processingItems.map((item) => {
						return <WarehouseInfoBlockItem key={item.name} {...item} />;
					})}
				</div>
			</ColumnContent>
		</div>
	);
};

export default WarehouseOperations;
