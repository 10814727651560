import { notification } from '@lavka/ui-kit';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import type { ClusterMapLayer } from '~server-types/doc/api/models/cluster';
import OrderModal from '~shared/components/OrdersHistory/OrderModal';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import useMultiMemo from '~shared/hooks/useMultiMemo';
import isKitchenOrderType from '~shared/utils/isKitchenOrderType';
import type { Orders } from '~types/orders';
import type { Stores } from '~types/stores';
import type { Zones } from '~types/zones';

import { isOrderDelivered } from '../../MonitorTableau/utils/processCourierData';
import { googleMapsGeocoding } from '../../StoresPage/StoresDataPage/GeoData/utils';
import UnassignCourierModal from '../UnassignCourier';
import GoogleOrdersMap from './Google/GoogleOrdersMap';
import { useStyles } from './styles';
import { yandexMapsGeocoding } from './Yandex/yandexMapsGeocoding';
import type { OrderWithExtra } from './Yandex/YandexOrdersMap';
import YandexOrdersMap from './Yandex/YandexOrdersMap';

const MAPS_GEOCODING = {
	yandex: yandexMapsGeocoding,
	google: googleMapsGeocoding,
};

interface Props {
	orders: Orders.Order[];
	store: Stores.Store;
}

export interface OrderWithCoords extends Orders.Order {
	coordinates?: number[];
}

const OrdersMap = ({ orders, store }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [zone, setZone] = useState<Zones.Zone | null>(null);
	const [mapType, setMapType] = useState<ClusterMapLayer | null>(null);

	const clusterId = store.cluster_id;
	const [unassignCourierModalOrderId, setUnassignCourierModalOrderId] = useState<
		Orders.Order['order_id'] | undefined
	>();
	const [orderModalOrderId, setOrderModalOrderId] = useState<Orders.Order['order_id'] | undefined>();
	setOrderModalOrderId;
	const cache = useCache({
		clusters: clusterId,
	});

	useEffect(() => {
		const { map_layer } = cache.clusters[clusterId] ?? {};

		if (map_layer) {
			setMapType(map_layer);
		}
	}, [cache.clusters[clusterId]?.map_layer]);

	const ordersMap = useMemo(() => {
		return Object.fromEntries(
			orders
				.filter((order) => isKitchenOrderType(order.type) && !isOrderDelivered(order))
				.map((order) => [order.order_id, order])
		);
	}, [orders]);

	const getCoords = async ({ client_address }: Orders.Order): Promise<[number, number] | undefined> => {
		if (typeof client_address?.lon === 'number' && typeof client_address?.lat === 'number') {
			return [client_address.lon, client_address.lat];
		}

		if (client_address?.fullname && mapType) {
			return await MAPS_GEOCODING[mapType](client_address.fullname);
		}
	};

	const orderCoords = useMultiMemo(ordersMap, mapType, getCoords);

	const addBatchInfo = (ordersMap: Record<string, OrderWithExtra>) => {
		const batchedOrdersMap = { ...ordersMap };
		const batchMap: Record<string, string[]> = Object.values(batchedOrdersMap).reduce((map, order) => {
			if (order.courier_id) {
				map[order.courier_id] = [...(map[order.courier_id] ?? []), order.doc_number];
			}
			return map;
		}, {});

		for (const id in batchedOrdersMap) {
			const order = batchedOrdersMap[id];
			if (!order.courier_id || batchMap[order.courier_id].length <= 1) {
				order.isBatched = false;
				order.batchedWith = undefined;
			} else {
				order.isBatched = true;
				order.batchedWith = batchMap[order.courier_id].filter((docNum) => docNum !== order.doc_number);
			}
		}

		return batchedOrdersMap;
	};

	useAsyncEffect(async () => {
		try {
			const { data } = await api.zones.list({
				store_id: store.store_id,
				delivery_type: ['foot'] as Zones.Zone['delivery_type'][],
			});

			if (data.results?.length) {
				const now = dayjs();

				const currentZone = data.results.find(
					(zone) =>
						dayjs(zone.effective_from).isBefore(now) &&
						(!zone.effective_till || dayjs(zone.effective_till).isAfter(now))
				);

				setZone(currentZone ?? data.results.at(-1) ?? null);
			}
		} catch {
			notification.error({
				message: t('Не удалось загрузить зону'),
			});
		}
	}, [store.store_id]);

	const ordersWithCoords = useMemo(() => {
		return Object.fromEntries(
			Object.entries(ordersMap).map(([order_id, order]) => [order_id, { ...order, coordinates: orderCoords[order_id] }])
		);
	}, [orders, orderCoords]);

	if (!zone) {
		return null;
	}

	if (mapType === 'yandex') {
		return (
			<div className={classes.ordersMapContainer}>
				{
					<UnassignCourierModal
						orderId={unassignCourierModalOrderId as Orders.Order['order_id']}
						closeModal={() => setUnassignCourierModalOrderId(undefined)}
					/>
				}
				{<OrderModal setOrderIdToShow={setOrderModalOrderId} orderIdToShow={orderModalOrderId} />}
				<YandexOrdersMap
					mapState={{
						center: store.location.geometry.coordinates,
						zoom: 14,
						controls: ['zoomControl', 'fullscreenControl'],
					}}
					orders={addBatchInfo(ordersWithCoords)}
					zone={zone}
					setUnassignCourierModalOrderId={setUnassignCourierModalOrderId}
					setOrderModalOrderId={setOrderModalOrderId}
				/>
			</div>
		);
	}

	if (mapType === 'google') {
		return (
			<div className={classes.ordersMapContainer}>
				{
					<UnassignCourierModal
						orderId={unassignCourierModalOrderId as Orders.Order['order_id']}
						closeModal={() => setUnassignCourierModalOrderId(undefined)}
					/>
				}
				{<OrderModal setOrderIdToShow={setOrderModalOrderId} orderIdToShow={orderModalOrderId} />}
				<GoogleOrdersMap
					mapState={{
						center: store.location.geometry.coordinates,
					}}
					fetchZones={{
						req: () => {},
						data: null,
						loading: cache.loading,
						loaded: true,
					}}
					zone={zone}
					orders={addBatchInfo(ordersWithCoords)}
					setUnassignCourierModalOrderId={setUnassignCourierModalOrderId}
					setOrderModalOrderId={setOrderModalOrderId}
				/>
			</div>
		);
	}

	return null;
};

export default OrdersMap;
