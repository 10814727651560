import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	title: {
		marginBlockEnd: '0 !important',
		color: theme.text.primary + '!important',
		fontSize: '16px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		lineHeight: 'normal',
	},
	titleSmall: {
		lineHeight: 'normal',
		marginBlockEnd: '0 !important',
		color: theme.text.primary + '!important',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	bottom: {
		paddingBlockStart: 20,
	},
	leftCol: {
		display: 'grid',
		gridColumnGap: 8,
		justifyContent: 'start',
		gridAutoFlow: 'column',
		gridAutoColumns: 'min-content',
	},
	rightCol: {
		display: 'grid',
		gridColumnGap: 8,
		justifyContent: 'end',
		gridAutoFlow: 'column',
		gridAutoColumns: 'min-content',
	},
	buttonsBlock: {
		display: 'flex',
		alignItems: 'center',
		marginInlineStart: 'auto',
		'&>*': {
			marginInlineEnd: 8,
		},
		'&>*:last-child': {
			marginInlineEnd: 0,
		},
	},
	dotsOptions: {
		marginInlineStart: '8px !important',
	},
	link: {
		fontSize: 12,
		fontWeight: 400,
	},
}));
