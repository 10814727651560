import { Button, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EyeRegular } from '~images/icons/eye-regular.svg';
import { ReactComponent as EyeSlashRegular } from '~images/icons/eye-slash-regular.svg';
import Icon from '~shared/utils/icons';
import { useStyles as useCommonStyles } from '~styles/common';

import { useStyles } from '../styles';
import { EmptyValue } from '../utils';

interface Props {
	value: string;
	label: string;
}

const PasswordView = ({ value, label }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [visible, toggleVisible] = useState<boolean>(false);
	const { classes: commonClasses } = useCommonStyles();

	const fieldWidth = (value?.length ?? 0) * 9;

	return (
		<p className={classes.valueOnViewMode} data-test={`data form ${label}`}>
			{value ? (
				<>
					{visible ? (
						<>
							<span
								className={classes.passwordBlock}
								style={{
									inlineSize: fieldWidth,
								}}
							>
								{value}
							</span>
							<Tooltip title={t('Скрыть')}>
								<Button
									data-test={`data form ${label} input value hide btn`}
									className={commonClasses.btnIconBordered}
									onClick={() => toggleVisible(false)}
								>
									<Icon component={EyeRegular} />
								</Button>
							</Tooltip>
						</>
					) : (
						<>
							<span
								className={classes.passwordBlock}
								style={{
									inlineSize: fieldWidth,
								}}
							>
								*****
							</span>
							<Tooltip title={t('Показать')}>
								<Button
									data-test={`data form ${label} input value show btn`}
									className={commonClasses.btnIconBordered}
									onClick={() => toggleVisible(true)}
								>
									<Icon component={EyeSlashRegular} />
								</Button>
							</Tooltip>
						</>
					)}
				</>
			) : (
				<EmptyValue />
			)}
		</p>
	);
};

export default PasswordView;
