import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const MobileTableau = lazy(() => import('../components/MonitorTableau/MobileTableau'));

const tableau = makeRoutes([
	{
		path: '/mtableau',
		permit: 'monitor_orders',
		element: <MobileTableau />,
	},
	{
		path: '/mtableau/operations',
		permit: 'monitor_orders',
		element: <MobileTableau name="operations" />,
	},
	{
		path: '/mtableau/orders',
		permit: 'monitor_orders',
		element: <MobileTableau name="orders" />,
	},
	{
		path: '/mtableau/couriers',
		permit: 'monitor_orders',
		element: <MobileTableau name="couriers" />,
	},
	{
		path: '/mtableau/storekeepers',
		permit: 'monitor_orders',
		element: <MobileTableau name="storekeepers" />,
	},
]);

export default tableau;
