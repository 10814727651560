import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	partner: {
		display: 'flex',
		justifyContent: 'center',
		' svg': {
			marginBlockStart: '3px',
			marginInlineEnd: '3px',
		},
	},
}));
