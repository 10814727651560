import type { DependencyList } from 'react';
import { useEffect, useState } from 'react';

import { useUserTheme } from '~zustand/userSettings';

const mapTypesColourInversion = {
	'yandex#satellite': {
		ground: 0,
		controls: 0,
		copyrights: 0,
		balloon: 0,
		places: 0,
	},
	'yandex#hybrid': {
		ground: 0,
		controls: 0,
		copyrights: 0,
		balloon: 0,
		places: 0,
	},
	'yandex#map': {
		ground: 0.9,
		controls: 0.8,
		copyrights: 0.8,
		balloon: 0.8,
		places: 0.8,
	},
} as const;

export const useYandexMapDarkTheme = (map: any, deps?: DependencyList) => {
	const [currentMapType, setCurrentMapType] = useState<keyof typeof mapTypesColourInversion>('yandex#map');
	const theme = useUserTheme();
	const isLight = theme === 'light';

	// подписываемся на событие "изменение типа карты"
	useEffect(
		() => {
			const onMapTypeChangeModifyColorInversion = () => {
				setCurrentMapType(map.getType());
			};
			if (map) {
				map.events.add('typechange', onMapTypeChangeModifyColorInversion);
			}
			return () => {
				if (map) {
					map.events.remove('typechange', onMapTypeChangeModifyColorInversion);
				}
			};
		},
		deps ? [...deps] : undefined
	);

	// в зависимости от типа карты устанавливаем инверсию цветов карты в темном режиме: классическая карта инвертируется,
	// гибрид и спутник не инвертируются

	useEffect(() => {
		if (map) {
			const setMapColorInversion = (
				mapElement: keyof (typeof mapTypesColourInversion)['yandex#map'],
				effect: string
			) => {
				map.panes.get(mapElement).getElement().style.filter = isLight
					? ''
					: `${effect}(${mapTypesColourInversion[currentMapType][mapElement]})`;
			};

			setMapColorInversion('ground', 'invert');
			setMapColorInversion('controls', 'invert');
			setMapColorInversion('copyrights', 'invert');
			setMapColorInversion('balloon', 'invert');
			setMapColorInversion('places', 'invert');
		}
	}, [map, isLight, currentMapType]);
};
