import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { TariffPlans } from '~types/tariffPlans';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	save: (data: TariffPlans.TariffPlanCreate | TariffPlans.TariffPlanUpdate, config?: AxiosAdditionalConfig) =>
		axios.post<{ result: TariffPlans.TariffPlan }>('/api/supply/admin/salaries/tariff_plans/save', data, config),

	load: makeLoadFn<TariffPlans.TariffPlan, 'tariff_plan_id'>(
		'/api/supply/admin/salaries/tariff_plans/load',
		'tariff_plan_id'
	),

	list: makeListFn<TariffPlans.ListRequest, TariffPlans.TariffPlan>('/api/supply/admin/salaries/tariff_plans/list'),

	setStatus: (data: { tariff_plan_id: string; status: 'approved' | 'cancelled'; revision: number }) =>
		axios.post<{ result: TariffPlans.TariffPlan }>('/api/supply/admin/salaries/tariff_plans/set_status', data),
};
