import { useEffect, useState } from 'react';

import { appVersion } from '~/appConfig';
import { logError } from '~shared/utils/logs';
import { useUserConstants } from '~zustand/userData';

export default function useClearCache(checkUpdateTrigger = '', autoRefresh = false) {
	const [isLatestBuildDate, setIsLatestBuildDate] = useState(true);
	const fixedFrontendVersion = useUserConstants().frontend_version;
	const refreshApp = () => {
		if (caches) {
			void caches.keys().then((names) => {
				for (const name of names) {
					void caches.delete(name);
				}
			});
		}
		window.location.reload();
	};

	useEffect(() => {
		if (process.env.REACT_APP_MODE === 'ci') {
			return;
		}
		fetch('/version.json', { cache: 'no-store' })
			.then((response) => response.json())
			.then(({ version }) => {
				let hasNewVersion;

				// Если есть зафиксированная версия, то обновляемся в любом случае, иначе только на новую версию
				if (fixedFrontendVersion) {
					hasNewVersion = version !== appVersion;
				} else {
					const versionDate = version.replace('testing', '.');
					const appVersionDate = appVersion.replace('testing', '.');

					hasNewVersion =
						versionDate.localeCompare(appVersionDate, undefined, {
							numeric: true,
							sensitivity: 'base',
						}) === 1;
				}

				if (hasNewVersion) {
					setIsLatestBuildDate(false);
					if (autoRefresh) {
						refreshApp();
					}
				} else {
					setIsLatestBuildDate(true);
				}
			})
			.catch((error) => {
				// Просто логируем ошибку
				logError({}, error);
			});
	}, [checkUpdateTrigger]);

	return {
		isLatest: isLatestBuildDate,
		refreshApp,
	};
}
