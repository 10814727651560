// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type PathsChildrenPostResponsesCode = 'OK';
export const pathsChildrenPostResponsesCodeValues = ['OK'] as PathsChildrenPostResponsesCode[];
export const pathsChildrenPostResponsesCodeNames = makeServerTranslations(pathsChildrenPostResponsesCodeValues);

export type PathsStocksLogPostResponsesCode = 'OK';
export const pathsStocksLogPostResponsesCodeValues = ['OK'] as PathsStocksLogPostResponsesCode[];
export const pathsStocksLogPostResponsesCodeNames = makeServerTranslations(pathsStocksLogPostResponsesCodeValues);

export type PathsOrderSignalPostSignal =
	| 'test'
	| 'inventory_done'
	| 'inventory_snapshot'
	| 'inventory_recount'
	| 'inventory_approve'
	| 'inventory_report_export'
	| 'wakeup_order'
	| 'acceptance_agree'
	| 'update_reserve'
	| 'shortfall'
	| 'sale_stowage'
	| 'collected'
	| 'next_stage'
	| 'order_defibrillation'
	| 'courier_unassign'
	| 'more_product'
	| 'add_cargo'
	| 'close_cargo'
	| 'print'
	| 'show_again'
	| 'hide_again';
export const pathsOrderSignalPostSignalValues = [
	'test',
	'inventory_done',
	'inventory_snapshot',
	'inventory_recount',
	'inventory_approve',
	'inventory_report_export',
	'wakeup_order',
	'acceptance_agree',
	'update_reserve',
	'shortfall',
	'sale_stowage',
	'collected',
	'next_stage',
	'order_defibrillation',
	'courier_unassign',
	'more_product',
	'add_cargo',
	'close_cargo',
	'print',
	'show_again',
	'hide_again',
] as PathsOrderSignalPostSignal[];
export const pathsOrderSignalPostSignalNames = makeServerTranslations(pathsOrderSignalPostSignalValues);

export type PathsOrderSignalPostResponsesCode = 'OK';
export const pathsOrderSignalPostResponsesCodeValues = ['OK'] as PathsOrderSignalPostResponsesCode[];
export const pathsOrderSignalPostResponsesCodeNames = makeServerTranslations(pathsOrderSignalPostResponsesCodeValues);

export type PathsSuggestsDonePostStatus = 'done' | 'cancel' | 'error';
export const pathsSuggestsDonePostStatusValues = ['done', 'cancel', 'error'] as PathsSuggestsDonePostStatus[];
export const pathsSuggestsDonePostStatusNames = makeServerTranslations(pathsSuggestsDonePostStatusValues);

export type PathsSuggestsDonePostReasonCode =
	| 'CHANGE_COUNT'
	| 'CHANGE_VALID'
	| 'CHANGE_COUNT_VALID'
	| 'CHANGE_WEIGHT'
	| 'GOOD_EXTRAS'
	| 'TRASH_DAMAGE'
	| 'TRASH_TTL'
	| 'TRASH_DECAYED'
	| 'TRASH_ORDINANCE'
	| 'TRASH_MOL'
	| 'TRASH_ACCIDENT'
	| 'TRASH_STORE_NEEDS'
	| 'TRASH_TRUE_MARK_REFUND'
	| 'TRASH_TRUE_MARK_DAMAGE'
	| 'SHELF_IS_FULL'
	| 'LIKE_SHELF';
export const pathsSuggestsDonePostReasonCodeValues = [
	'CHANGE_COUNT',
	'CHANGE_VALID',
	'CHANGE_COUNT_VALID',
	'CHANGE_WEIGHT',
	'GOOD_EXTRAS',
	'TRASH_DAMAGE',
	'TRASH_TTL',
	'TRASH_DECAYED',
	'TRASH_ORDINANCE',
	'TRASH_MOL',
	'TRASH_ACCIDENT',
	'TRASH_STORE_NEEDS',
	'TRASH_TRUE_MARK_REFUND',
	'TRASH_TRUE_MARK_DAMAGE',
	'SHELF_IS_FULL',
	'LIKE_SHELF',
] as PathsSuggestsDonePostReasonCode[];
export const pathsSuggestsDonePostReasonCodeNames = makeServerTranslations(pathsSuggestsDonePostReasonCodeValues);

export type PathsExportReportPostReportType = 'inv3' | 'inv19';
export const pathsExportReportPostReportTypeValues = ['inv3', 'inv19'] as PathsExportReportPostReportType[];
export const pathsExportReportPostReportTypeNames = makeServerTranslations(pathsExportReportPostReportTypeValues);

export type PathsChangeStatusPostStatus = 'complete' | 'failed';
export const pathsChangeStatusPostStatusValues = ['complete', 'failed'] as PathsChangeStatusPostStatus[];
export const pathsChangeStatusPostStatusNames = makeServerTranslations(pathsChangeStatusPostStatusValues);

export type PathsChangeStatusPostReason = 'incomplete' | 'comment';
export const pathsChangeStatusPostReasonValues = ['incomplete', 'comment'] as PathsChangeStatusPostReason[];
export const pathsChangeStatusPostReasonNames = makeServerTranslations(pathsChangeStatusPostReasonValues);

export type PathsChangeStatusPostResponsesCode = 'OK';
export const pathsChangeStatusPostResponsesCodeValues = ['OK'] as PathsChangeStatusPostResponsesCode[];
export const pathsChangeStatusPostResponsesCodeNames = makeServerTranslations(pathsChangeStatusPostResponsesCodeValues);

export type PathsSetCourierPostMode = 'assign' | 'append' | 'remove';
export const pathsSetCourierPostModeValues = ['assign', 'append', 'remove'] as PathsSetCourierPostMode[];
export const pathsSetCourierPostModeNames = makeServerTranslations(pathsSetCourierPostModeValues);

export type PathsImportReportPostResponsesCode = 'OK';
export const pathsImportReportPostResponsesCodeValues = ['OK'] as PathsImportReportPostResponsesCode[];
export const pathsImportReportPostResponsesCodeNames = makeServerTranslations(pathsImportReportPostResponsesCodeValues);

export type CourierListItemStatuses = 'free' | 'assigned' | 'busy';
export const courierListItemStatusesValues = ['free', 'assigned', 'busy'] as CourierListItemStatuses[];
export const courierListItemStatusesNames = makeServerTranslations(
	courierListItemStatusesValues,
	'enums.COURIER_LIST_ITEM_STATUSES'
);

export type OrderReassignCourierTypes = 'taxi' | 'bike' | 'any';
export const orderReassignCourierTypesValues = ['taxi', 'bike', 'any'] as OrderReassignCourierTypes[];
export const orderReassignCourierTypesNames = makeServerTranslations(
	orderReassignCourierTypesValues,
	'enums.ORDER_REASSIGN_COURIER_TYPES'
);

export type PathsUpdateAssortmentPostResponsesCode = 'OK';
export const pathsUpdateAssortmentPostResponsesCodeValues = ['OK'] as PathsUpdateAssortmentPostResponsesCode[];
export const pathsUpdateAssortmentPostResponsesCodeNames = makeServerTranslations(
	pathsUpdateAssortmentPostResponsesCodeValues
);

export type PathsKitchenSuggestPostAction = 'start' | 'finish' | 'complete';
export const pathsKitchenSuggestPostActionValues = ['start', 'finish', 'complete'] as PathsKitchenSuggestPostAction[];
export const pathsKitchenSuggestPostActionNames = makeServerTranslations(pathsKitchenSuggestPostActionValues);

export type PathsKitchenSuggestPostResponsesCode = 'OK';
export const pathsKitchenSuggestPostResponsesCodeValues = ['OK'] as PathsKitchenSuggestPostResponsesCode[];
export const pathsKitchenSuggestPostResponsesCodeNames = makeServerTranslations(
	pathsKitchenSuggestPostResponsesCodeValues
);

export type PathsFinishCollectPostResponsesCode = 'OK';
export const pathsFinishCollectPostResponsesCodeValues = ['OK'] as PathsFinishCollectPostResponsesCode[];
export const pathsFinishCollectPostResponsesCodeNames = makeServerTranslations(
	pathsFinishCollectPostResponsesCodeValues
);

export type PathsReturnSupplierPostResponsesCode = 'OK';
export const pathsReturnSupplierPostResponsesCodeValues = ['OK'] as PathsReturnSupplierPostResponsesCode[];
export const pathsReturnSupplierPostResponsesCodeNames = makeServerTranslations(
	pathsReturnSupplierPostResponsesCodeValues
);

export type PathsAckPostResponsesCode = 'OK';
export const pathsAckPostResponsesCodeValues = ['OK'] as PathsAckPostResponsesCode[];
export const pathsAckPostResponsesCodeNames = makeServerTranslations(pathsAckPostResponsesCodeValues);

export type PathsQueuePostResponsesCode = 'OK';
export const pathsQueuePostResponsesCodeValues = ['OK'] as PathsQueuePostResponsesCode[];
export const pathsQueuePostResponsesCodeNames = makeServerTranslations(pathsQueuePostResponsesCodeValues);

export type UpdateAttrType = 'acceptance';
export const updateAttrTypeValues = ['acceptance'] as UpdateAttrType[];
export const updateAttrTypeNames = makeServerTranslations(updateAttrTypeValues);

export type CreateOrderType =
	| 'collect'
	| 'inventory'
	| 'order'
	| 'hand_move'
	| 'kitchen_provision'
	| 'shipment'
	| 'acceptance'
	| 'cargo_collect'
	| 'check'
	| 'check_final'
	| 'check_product_on_shelf'
	| 'check_more'
	| 'check_valid_short'
	| 'check_valid_regular'
	| 'visual_control'
	| 'writeoff_prepare_day'
	| 'writeoff'
	| 'stop_list'
	| 'shipment_rollback'
	| 'control_check'
	| 'repacking'
	| 'kitchen_prod'
	| 'regrading';
export const createOrderTypeValues = [
	'collect',
	'inventory',
	'order',
	'hand_move',
	'kitchen_provision',
	'shipment',
	'acceptance',
	'cargo_collect',
	'check',
	'check_final',
	'check_product_on_shelf',
	'check_more',
	'check_valid_short',
	'check_valid_regular',
	'visual_control',
	'writeoff_prepare_day',
	'writeoff',
	'stop_list',
	'shipment_rollback',
	'control_check',
	'repacking',
	'kitchen_prod',
	'regrading',
] as CreateOrderType[];
export const createOrderTypeNames = makeServerTranslations(createOrderTypeValues);

export type CreateOrderInventoryRequestShelfTypes =
	| 'store'
	| 'markdown'
	| 'kitchen_components'
	| 'office'
	| 'repacking'
	| 'review'
	| 'trash'
	| 'kitchen_trash'
	| 'cargo';
export const createOrderInventoryRequestShelfTypesValues = [
	'store',
	'markdown',
	'kitchen_components',
	'office',
	'repacking',
	'review',
	'trash',
	'kitchen_trash',
	'cargo',
] as CreateOrderInventoryRequestShelfTypes[];
export const createOrderInventoryRequestShelfTypesNames = makeServerTranslations(
	createOrderInventoryRequestShelfTypesValues
);

export type OrderInventoryTypes = 'planed' | 'extraordinary' | 'final';
export const orderInventoryTypesValues = ['planed', 'extraordinary', 'final'] as OrderInventoryTypes[];
export const orderInventoryTypesNames = makeServerTranslations(
	orderInventoryTypesValues,
	'enums.ORDER_INVENTORY_TYPES'
);

export type CreateOrderShipmentRequestRequiredPriceType = 'store' | 'markdown' | 'office' | 'review' | 'cargo';
export const createOrderShipmentRequestRequiredPriceTypeValues = [
	'store',
	'markdown',
	'office',
	'review',
	'cargo',
] as CreateOrderShipmentRequestRequiredPriceType[];
export const createOrderShipmentRequestRequiredPriceTypeNames = makeServerTranslations(
	createOrderShipmentRequestRequiredPriceTypeValues
);

export type OrderRequiredProductPriceType = 'store' | 'markdown' | 'office' | 'kitchen_components';
export const orderRequiredProductPriceTypeValues = [
	'store',
	'markdown',
	'office',
	'kitchen_components',
] as OrderRequiredProductPriceType[];
export const orderRequiredProductPriceTypeNames = makeServerTranslations(orderRequiredProductPriceTypeValues);

export type CreateType = 'part_refund';
export const createTypeValues = ['part_refund'] as CreateType[];
export const createTypeNames = makeServerTranslations(createTypeValues);

export type CreateOrderSaleStowageType = 'sale_stowage';
export const createOrderSaleStowageTypeValues = ['sale_stowage'] as CreateOrderSaleStowageType[];
export const createOrderSaleStowageTypeNames = makeServerTranslations(createOrderSaleStowageTypeValues);

export type CreateOrderWeightStowageType = 'weight_stowage';
export const createOrderWeightStowageTypeValues = ['weight_stowage'] as CreateOrderWeightStowageType[];
export const createOrderWeightStowageTypeNames = makeServerTranslations(createOrderWeightStowageTypeValues);
