import { red } from '@ant-design/colors';

import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	deleteEquipmentButton: {
		color: red[6],
		paddingBlockStart: 4,
		'& svg': {
			inlineSize: 13,
			blockSize: 15,
		},
		'&:disabled': {
			color: theme.text.disabled,
			borderColor: theme.text.disabled,
		},
	},
}));
