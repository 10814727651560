import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { appSessionId } from '~/appConfig';
import copyToClipboard from '~shared/utils/copyToClipboard';

export const SessionId = memo(() => {
	const [t] = useTranslation();

	const onClick = () => {
		copyToClipboard(appSessionId, t('Идентификатор сессии скопирован в буфер обмена'));
	};

	return <span onClick={onClick}>{appSessionId}</span>;
});
