import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	block: {
		display: 'flex',
		alignItems: 'center',
		margin: '0',
		marginInlineEnd: '8px',
	},
	spin: {
		marginInlineStart: 12,
	},
	buttonContainer: {
		display: 'grid',
		gridColumnGap: 0,
		gridAutoFlow: 'column',
	},
	button: {
		padding: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		inlineSize: 32,
		blockSize: 32,
		color: theme.text.primary,
		backgroundColor: `${theme.bgColor.main} !important`,
		borderColor: `${theme.border.secondary} !important`,
		'&:hover': {
			backgroundColor: theme.bgColor.main,
		},
		'&[disabled]': {
			backgroundColor: theme.bgColor.disabled + '!important',
			borderColor: theme.border.disabled + '!important',
			color: theme.text.disabled,
			'&:hover': {
				color: theme.text.disabled,
			},
			'& i': {
				color: theme.text.secondary,
			},
		},
	},
	buttonPrev: {
		borderStartStartRadius: 4,
		borderStartEndRadius: 0,
		borderEndEndRadius: 0,
		borderEndStartRadius: 4,
	},
	buttonNext: {
		borderStartStartRadius: 0,
		borderStartEndRadius: 4,
		borderEndEndRadius: 4,
		borderEndStartRadius: 0,
	},
	paginationInfo: {
		lineHeight: '30px',
		paddingBlock: '0',
		paddingInline: '16px',
		border: `1px solid ${theme.border.secondary}`,
		borderInlineEnd: 'none',
		borderInlineStart: 'none',
		color: theme.text.secondary,
		whiteSpace: 'nowrap',
	},
}));
