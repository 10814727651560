import { lazy } from 'react';

import { PrependPath } from '~shared/components/PrependPath';
import { makeRoutes } from '~shared/utils/makeRoutes';

const UsersTable = lazy(() => import('../components/UsersPage'));
const UsersDataPage = lazy(() => import('../components/UsersPage/UsersDataPage'));

const usersRoutes = makeRoutes([
	{
		path: '/users',
		element: <UsersTable />,
		accessDeniedElement: <PrependPath />,
	},
	{
		path: '/users/add',
		permit: 'users_create',
		element: <UsersDataPage editMode addMode />,
		accessDeniedElement: <PrependPath />,
	},
	{
		path: '/users/edit/:user_id',
		permit: 'users_save',
		element: <UsersDataPage editMode />,
		accessDeniedElement: <PrependPath />,
	},
	{
		path: '/users/:user_id',
		permit: 'users_load',
		element: <UsersDataPage />,
		accessDeniedElement: <PrependPath />,
	},
	{
		path: '/stores/:store_id/users',
		element: <UsersTable />,
	},
	{
		path: '/stores/:store_id/users/add',
		permit: 'users_create',
		element: <UsersDataPage editMode addMode />,
	},
	{
		path: '/stores/:store_id/users/edit/:user_id',
		permit: 'users_save',
		element: <UsersDataPage editMode />,
	},
	{
		path: '/stores/:store_id/users/:user_id',
		permit: 'users_load',
		element: <UsersDataPage />,
	},
]);

export default usersRoutes;
