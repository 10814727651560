import { useState } from 'react';
import { useParams } from 'react-router-dom';

import api from '~/api';
import useLoadData from '~shared/hooks/useLoadData';
import { useCheckPermit } from '~zustand/userData';

import { SchetsList } from './index';

export const CompanySchets = () => {
	const [cursor, setCursor] = useState<string>('');

	const params = useParams<{ company_id: string }>();
	const companyId = params.company_id!;
	const isPermitSchetsSeek = useCheckPermit('schets_seek');

	const schetsData = useLoadData(
		() =>
			api.schets.list({
				company_id: companyId,
				scope: 'company' as const,
				cursor,
			}),
		[cursor, companyId],
		!isPermitSchetsSeek
	);

	return (
		<SchetsList
			setCursor={setCursor}
			cursor={schetsData.data?.cursor ?? ''}
			schetsData={schetsData.data?.results ?? []}
			loading={schetsData.loading}
			baseUrl={`/companies/${companyId}/schets`}
		/>
	);
};
