import type { AxiosInstance } from 'axios';

import { logTvDeviceError, logTvDeviceResponse } from '~shared/utils/logs';

export default function (axios: AxiosInstance) {
	axios.interceptors.response.use(
		(response) => {
			logTvDeviceResponse(response);

			return response;
		},
		(error) => {
			logTvDeviceError(error);

			return Promise.reject(error);
		}
	);
}
