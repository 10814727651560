import { PageHeaderServiceMenu } from '@lavka/ui-kit/lib/esm/business-components/PageHeader/components/PageHeaderServiceMenu';
import { useGetHeaderData } from '@lavka/ui-kit/lib/esm/business-components/PageHeader/hooks/use-get-header-data';

import { useMenuStore } from '~shared/components/Layout/useMenuStore';
import { useUser, useUserDomainConfigs } from '~zustand/userData';

import { useStyles } from './styles';

const activeService = {
	id: 'wms',
	title: 'WMS',
	icon: 'wms',
	link: '/',
};

export function HeaderLeft() {
	const collapsed = useMenuStore((state) => state.collapsed);
	const { classes } = useStyles({ collapsed });
	const user = useUser();
	const domainConfigs = useUserDomainConfigs();
	const headerDataState = useGetHeaderData({
		enabled: !domainConfigs.header_services && process.env.REACT_APP_MODE !== 'ci',
		baseHost: window.location.hostname.replace('wms.', ''),
		companyId: user.company_id,
	});

	const b2bServices = headerDataState.data?.services
		.filter((e) => e.id !== activeService.id)
		.map((e) => ({
			id: e.id,
			icon: e.icon,
			title: e.name,
			link: e.url,
		}));

	const services = domainConfigs.header_services ?? b2bServices ?? [];

	return (
		<div className={classes.container}>
			<PageHeaderServiceMenu
				activeService={activeService}
				colorScheme={process.env.REACT_APP_MODE === 'production' ? 'colored' : 'grayscale'}
				serviceLinks={services}
				onServiceLinkClick={undefined}
			/>
		</div>
	);
}
