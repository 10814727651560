import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import { AngleDownAlternative } from '~shared/components/Icons/constants';
import type { UserExecutorLastSeenType } from '~shared/hooks/useStorekeepersData';
import Icon from '~shared/utils/icons';
import type { Orders } from '~types/orders';
import type { User } from '~types/user';

import StorekeeperOrders from '../StorekeeperOrders';
import StorekeeperUserName from './StorekeeperUserName';
import { useStyles } from './styles';

export type StorekeeperRowType = {
	user: User.Executor | UserExecutorLastSeenType;
	orders?: Orders.Order[];
	isToggled: boolean;
	storeId?: string | null;
	isHealthPage: boolean;
};

const { Panel } = Collapse;

const StorekeeperRow = ({ user, orders, isToggled = false, storeId, isHealthPage }: StorekeeperRowType) => {
	const { classes } = useStyles({ mode: isHealthPage });
	const { t } = useTranslation();

	return user ? (
		<>
			<div className={classes.storekeeperWrapper}>
				{isToggled ? (
					<Collapse
						bordered={false}
						defaultActiveKey={[]}
						className={classes.storekeeperAccordion}
						expandIcon={({ isActive }) => <Icon component={AngleDownAlternative} rotate={isActive ? 180 : 0} />}
						expandIconPosition="end"
						accordion={true}
					>
						<Panel
							header={
								<div className={classes.storekeeperAccordionHeader}>
									<StorekeeperUserName user={user} isHealthPage={isHealthPage} />
									<span className={classes.storekeeperTaskAmount}>
										{t('Заданий: {{amount}}', { amount: orders?.length })}
									</span>
								</div>
							}
							key="storekeeper_orders"
						>
							<StorekeeperOrders orders={orders} storeId={storeId} isHealthPage={isHealthPage} />
						</Panel>
					</Collapse>
				) : (
					<StorekeeperUserName user={user} isHealthPage={isHealthPage} />
				)}
			</div>
		</>
	) : null;
};

export default StorekeeperRow;
