import { useTranslation } from 'react-i18next';

import type { CheckProjects } from '~types/checkProjects';

type Props = {
	products: CheckProjects.CheckProject['products'];
};

const ProductsBlock = ({ products }: Props) => {
	const [t] = useTranslation();
	const resultProducts = [];

	if (products.product_id?.length) {
		resultProducts.push(t('{{count}} товар', { count: products.product_id.length }));
	}

	if (products.product_group_id?.length) {
		resultProducts.push(t('{{count}} категория', { count: products.product_group_id.length }));
	}

	return <div>{resultProducts.length ? resultProducts.join(', ') : '—'}</div>;
};

export default ProductsBlock;
