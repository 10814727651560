import { Checkbox } from 'antd';
import type { JSX } from 'react';

import type { FieldProps } from '~shared/components/Fields/types';

interface CheckboxFieldProps extends FieldProps {
	className?: string;
	defaultChecked?: boolean;
	layout?: (component: JSX.Element) => JSX.Element;
}

export const CheckboxField = ({
	className,
	label,
	input,
	disabled,
	editMode,
	defaultChecked = false,
	layout,
}: CheckboxFieldProps) => {
	const component = (
		<Checkbox
			className={className}
			data-test={`data form ${label}${disabled ? ' disabled' : ''}`}
			disabled={disabled || !editMode}
			defaultChecked={defaultChecked}
			onChange={input.onChange}
			checked={input.value}
		>
			{label}
		</Checkbox>
	);

	if (layout) {
		return layout(component);
	}

	return component;
};
