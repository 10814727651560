import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const timetableTypeDictionary = makeDynamicTranslations({
	once: tDynamic('Нет'),
	everyday: tDynamic('Ежедневно'),
	weekend: tDynamic('По выходным'),
	workday: tDynamic('По будням'),
	monday: tDynamic('По понедельникам'),
	tuesday: tDynamic('По вторникам'),
	wednesday: tDynamic('По средам'),
	thursday: tDynamic('По четвергам'),
	friday: tDynamic('По пятницам'),
	saturday: tDynamic('По субботам'),
	sunday: tDynamic('По воскресеньям'),
	holiday: tDynamic('Праздники'),
	day_interval: tDynamic('enums.TIMETABLE_ITEM_TYPES.day_interval'),
});

export const timetableTypesDays: Record<string, number[]> = {
	everyday: [0, 1, 2, 3, 4, 5, 6],
	weekend: [0, 6],
	workday: [1, 2, 3, 4, 5],
	monday: [1],
	tuesday: [2],
	wednesday: [3],
	thursday: [4],
	friday: [5],
	saturday: [6],
	sunday: [0],
};

export const scheduleTypeCases: Record<string, string[]> = {
	everyday: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
	weekend: ['saturday', 'sunday'],
	workday: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
};

export const WEEKDAYS_SHORT = makeDynamicTranslations({
	mon: tDynamic('Пн'),
	tue: tDynamic('Вт'),
	wed: tDynamic('Ср'),
	thu: tDynamic('Чт'),
	fri: tDynamic('Пт'),
	sat: tDynamic('Сб'),
	sun: tDynamic('Вс'),
});

export const WEEKDAYS_NUM = makeDynamicTranslations({
	0: tDynamic('Понедельник'),
	1: tDynamic('Вторник'),
	2: tDynamic('Среда'),
	3: tDynamic('Четверг'),
	4: tDynamic('Пятница'),
	5: tDynamic('Суббота'),
	6: tDynamic('Воскресенье'),
});
