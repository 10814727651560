import { notification } from '@lavka/ui-kit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import api from '~/api';
import { login } from '~shared/utils/loginFunctions';

const AuthDone = () => {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const state = searchParams.get('state');
		const code = searchParams.get('code');

		if (!state && !code) {
			return;
		}

		void (async function () {
			try {
				const response = await api.profile.authDone({
					state: state ?? '',
					code: code ?? '',
				});
				const path = await login(response.data);
				navigate(path);
			} catch (error) {
				console.log(error.status);

				if (error.data?.code === 'ER_2FA_REQUIRED') {
					notification.error({
						message: t('Необходимо включить двухфакторную авторизацию'),
					});
				} else if (error.status === 410) {
					notification.error({
						message: t('Срок действия приглашения истек'),
					});
				} else if (error.status === 403) {
					notification.error({
						message: t('Приглашение было выслано на другой емейл'),
					});
				} else {
					notification.error({
						message: `${t('Произошла ошибка')} ${error}`,
					});
				}
				navigate('/login');
			}
		})();
	}, []);

	return null;
};

export default AuthDone;
