import { Button } from 'antd';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { useCache } from '~cache/useCache';
import { arrayUniq } from '~shared/utils/arrayUniq';
import type { Orders } from '~types/orders';

import { CollectDocument } from './CollectDocument';
import { useStyles } from './styles';

const CodeCard = () => import('~shared/components/CodeCard');

interface Props {
	orders: Orders.Order[];
	buttonText: string;
}

export function PrintCollectButton({ orders, buttonText }: Props) {
	const { classes } = useStyles();
	const printComponent = useRef<HTMLDivElement>(null);

	const productIds = arrayUniq(orders.flatMap((order) => order.required.map((e) => e.product_id)));
	const shelfIds = arrayUniq(orders.flatMap((order) => order.vars?.shelf));

	const cache = useCache({
		products: productIds,
		shelves: shelfIds,
	});

	const print = useReactToPrint({
		content: () => printComponent.current,
		// Дожидаемся загрузки библиотеки генерации QR кодов
		onBeforeGetContent: () => CodeCard(),
	});

	return (
		<>
			<Button onClick={print}>{buttonText}</Button>
			<div className={classes.printDiv}>
				<div ref={printComponent}>
					{cache.loaded && (
						<div className={classes.printContainer}>
							{orders.map((order) => (
								<CollectDocument key={order.order_id} order={order} />
							))}
						</div>
					)}
				</div>
			</div>
		</>
	);
}
