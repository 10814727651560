import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextField from '~shared/components/Fields/TextField';
import { Field, Form } from '~shared/components/Forms';

import type { WaybillFormValues } from '.';

type Props = {
	onSubmit?: (value: WaybillFormValues) => void;
	hookForm: UseFormReturn<WaybillFormValues>;
};

const WaybillForm = ({ onSubmit, hookForm }: Props) => {
	const [t] = useTranslation();

	return (
		<Form onSubmit={onSubmit} data-test="change picking list" hookForm={hookForm}>
			<Field
				label={t('Указать номер товарно транспортной накладной')}
				key="waybill"
				name="waybill"
				editMode={true}
				placeholder={t('Укажите номер ТТН')}
				allowClear
				colspan={{
					lg: { span: 12 },
					sm: { span: 12 },
					xs: { span: 24 },
				}}
				component={TextField}
			/>
		</Form>
	);
};

export default WaybillForm;
