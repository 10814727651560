import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const CompaniesPage = lazy(() => import('../components/CompaniesPage'));
const CompaniesDataPage = lazy(() => import('../components/CompaniesPage/CompaniesDataPage'));

const companiesRoutes = makeRoutes([
	{
		path: '/companies',
		element: <CompaniesPage />,
	},
	{
		path: '/companies/add',
		permit: 'companies_create',
		element: <CompaniesDataPage editMode addMode />,
	},
	{
		path: '/companies/edit/:company_id',
		permit: 'companies_save',
		element: <CompaniesDataPage editMode />,
		disableRouteChecker: true,
	},
	{
		path: '/companies/:company_id',
		permit: 'companies_load',
		element: <CompaniesDataPage />,
		disableRouteChecker: true,
	},
]);

export default companiesRoutes;
