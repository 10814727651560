import type { AxiosResponse } from 'axios';
import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { requestSeparator } from '~shared/utils/requestSeparator';

import type { Keys, PartialEntity } from '../types';
import type { LoadApi } from './backendRequestsCombiner';
import { BackendRequestsCombiner } from './backendRequestsCombiner';

export function makeLoadFn<TEntity extends object, IdField extends string & keyof TEntity>(
	url: string,
	idField: IdField
) {
	const api: LoadApi<TEntity> = (data, config) =>
		axios.post(url, { [idField]: data.ids, _fields: data._fields }, config);
	const storage = new BackendRequestsCombiner<TEntity>(api, idField);

	return <Id extends string | string[], TFields extends Keys<TEntity>>(
		data: Record<IdField, Id> & { _fields?: TFields },
		config?: AxiosAdditionalConfig
	): Promise<
		AxiosResponse<{ result: CopyValueOrArray<Id, PartialEntity<TEntity, ReadonlyArray<TFields[number] | IdField>>> }>
	> => {
		const ids = data[idField];
		if (!Array.isArray(ids)) {
			return requestSeparator((ids) => {
				return api<Id, TFields>({ ids: ids as Id, _fields: data._fields }, config);
			}, ids);
		}
		return storage.get(ids, data._fields, { forceNewRequest: config?.force }).then(
			(result) =>
				({ data: { result } }) as unknown as AxiosResponse<{
					result: CopyValueOrArray<Id, PartialEntity<TEntity, ReadonlyArray<TFields[number] | IdField>>>;
				}>
		);
	};
}
