import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { Couriers } from '~types/couriers';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<Couriers.ListCouriersType, Couriers.Courier>('/api/supply/admin/couriers/list'),
	loadBrief: makeLoadFn<Couriers.CourierBrief, 'courier_id'>('/api/supply/admin/couriers/load_brief', 'courier_id'),
	load: makeLoadFn<Couriers.Courier, 'courier_id'>('/api/supply/admin/couriers/load', 'courier_id'),
	load_phone: (data: { courier_id: string | string[] }, config?: AxiosAdditionalConfig) =>
		axios.post('/api/supply/admin/couriers/load_phone', data, config),
	load_identifications: (data: { courier_id: string | string[] }, config?: AxiosAdditionalConfig) =>
		axios.post<{ identifications: string[] }>('/api/supply/admin/couriers/load_identifications', data, config),
	save: (
		data:
			| {
					external_id?: string;
					tags: string[];
			  }
			| {
					courier_id?: string;
					tags: string[];
			  }
			| {
					courier_id: string;
					shift_preferences: Couriers.ShiftPreference[];
			  },
		config?: AxiosAdditionalConfig
	) => axios.post<{ result: Couriers.Courier }>('/api/supply/admin/couriers/save', data, config),
	import_data: (data: { csv: string }) => axios.post('/api/supply/admin/couriers/import_data', data),
	export_data: (data: Couriers.ExportDataCouriersType) => axios.post('/api/supply/admin/couriers/export_data', data),
	csv_upload: (data: { csv: string }) =>
		axios.post<{ code: string; start_user_log_id: string }>('/api/supply/admin/couriers/csv_upload', data),
	checkins: (data: { store_id: string }) =>
		axios.post<{ couriers: Couriers.CheckinInfo[] }>('/api/supply/admin/couriers/checkins', data),
	activate: (data: { courier_id: string }) =>
		axios.post<{ code: string; courier?: Couriers.Courier }>('/api/supply/admin/couriers/activate', data),
	deactivate: (data: Couriers.DeactivateBlockRequest) =>
		axios.post<{ code: string; courier?: Couriers.Courier }>('/api/supply/admin/couriers/deactivate', data),
	set_status: (data: Couriers.SetStatusRequest | (Couriers.SetStatusRequest & Couriers.DeactivateBlockRequest)) =>
		axios.post<{ courier: Couriers.Courier }>('/api/supply/admin/couriers/set_status', data),
	ids_by_store: (data: { store_id: string; filter_deactivated?: boolean }) =>
		axios.post<{ results: Couriers.Courier['courier_id'][] }>('/api/admin/couriers/ids_by_store', data),
	set_notes: (data: { courier_id: string; notes: string }, config?: AxiosAdditionalConfig) =>
		axios.post('/api/admin/couriers/set_notes', data, config),
};
