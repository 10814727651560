import axios from 'axios';

import type { ProductStatuses } from '~server-types/doc/api/models/product';
import type { ShelfTypes } from '~server-types/doc/api/models/shelf';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { FileInfo } from '~types/common';
import type { Stocks } from '~types/stocks';

export default {
	product: (data: { store_id: string; product_id: string | string[]; shelf_type?: ShelfTypes }) =>
		axios.post<{ result: Stocks.Stock[] }>('/api/admin/stocks/product', data),

	shelf: (data: { shelf_id: string | string[] }) => {
		return axios.post<{ result: Stocks.StockOnShelf[] }>('/api/admin/stocks/shelf', data);
	},

	list: makeListFn<Stocks.StocksListRequest, Stocks.Stock>('/api/admin/stocks/list'),

	menu: (data?: { product_status?: ProductStatuses }) =>
		axios.post<{ result: Stocks.Stock[] }>('/api/admin/stocks/menu', data ?? {}),

	log: (data: { stock_id: string; cursor: string | null; direction?: 'ASC' | 'DESC' }) =>
		axios.post<{
			result: Stocks.StockLog[];
			cursor?: string | null;
		}>('/api/admin/stocks/log', data),

	import_data: (data: { csv: string }) => axios.post('/api/admin/stocks/import_data', data),
	empty_shelves: (data: { csv: string; delete_shelves?: boolean }) =>
		axios.post<EmptyObject>('/api/admin/stocks/empty_shelves', data),
	export_csv: (data: { store_id: string; shelf_id?: string; product_id?: string }) =>
		axios.post<{ code: string; message?: string; file_info?: FileInfo }>('/api/admin/stocks/export_csv', data),
};
