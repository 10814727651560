import { red } from '@ant-design/colors';

import { gray } from '~styles/colors';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles<{ error: string }>()((_, { error }) => ({
	tag: {
		borderColor: error ? red[7] : gray[1],
		inlineSize: '100%',
	},
}));
