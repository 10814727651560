import { checkProjectsFields } from '~constants/dataPageFields/checkProjectsFields';
import DataSection from '~shared/components/DataForm/DataSection';

interface Props {
	addMode?: boolean;
	editMode?: boolean;
}

const fields = ['stores'];
const section = {
	label: '',
	fields: checkProjectsFields[0].fields.filter((field) => fields.includes(field.key)),
};

export const LocationSection = ({ addMode, editMode = false }: Props) => {
	return (
		<DataSection
			section={section}
			colspan={{
				xl: { span: 6 },
				lg: { span: 8 },
				sm: { span: 12 },
				xs: { span: 12 },
			}}
			editMode={editMode}
			addMode={addMode}
		/>
	);
};
