import { Col, Row } from 'antd';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import analyticStatsInfo from '~constants/analyticStatsInfo';
import { arrayUniq } from '~shared/utils/arrayUniq';
import capitalize from '~shared/utils/capitalize';
import type { RechartsProps } from '~types/common';

import { HealthContext } from '../../context';
import type { HealthMetric, TimeIntervalDataObject } from '../../healthTypes';
import { useMetricsStore } from '../../hooks/useMetricsStore';
import type { TickValue } from '../ChartsComparisonModal';
import { useStyles } from './styles';
import { getTooltipAverageData } from './utils';

interface Props extends RechartsProps<{ payload: TimeIntervalDataObject }> {
	data: Partial<Record<HealthMetric, TimeIntervalDataObject[]>>;
	isDataByHour?: boolean;
	tickValue?: TickValue;
}

const ExtendedTooltip = ({ payload, data, isDataByHour, tickValue }: Props) => {
	const [t] = useTranslation();
	const { periodState } = useContext(HealthContext);
	const { classes } = useStyles();

	const metrics = useMetricsStore((state) => state.metrics);

	const dataPoint = payload?.[0]?.payload;

	const currentMetrics = arrayUniq(metrics.map((item) => item.metric));

	const averagedData = useMemo(
		() => getTooltipAverageData(currentMetrics, data, dataPoint, tickValue, isDataByHour),
		[currentMetrics, data, tickValue, dataPoint]
	);

	const retroLabels = {
		day: t('День назад'),
		week: t('Неделю назад'),
	};

	return (
		<div className={classes.extendedTooltipContainer}>
			<div className={classes.tooltipHeader}>{dataPoint?.fullDate.format('HH:mm, DD MMMM YYYY')}</div>
			<Row justify="space-between" gutter={5}>
				<Col span={10}>
					<span className={classes.tooltipLabel}>{t('Метрика')}</span>
				</Col>
				<Col span={7}>
					<span className={classes.tooltipLabel}>{t('Сейчас')}</span>
				</Col>
				<Col span={7} flex="end">
					<span className={classes.tooltipLabel}>
						{retroLabels[dataPoint?.comparisonType ?? periodState.comparisonType]}
					</span>
				</Col>
			</Row>
			{Object.entries(averagedData).map(([metric, data]) => {
				const { value, retroValue } = data as TimeIntervalDataObject;
				return (
					<Row key={metric} justify="space-between" gutter={5}>
						<Col span={10}>
							<span className={classes.tooltipLabel}>{capitalize(analyticStatsInfo[metric].label)}</span>
						</Col>
						<Col span={7}>
							<span className={classes.tooltipText}>{value ?? '—'}</span>
						</Col>
						<Col span={7} flex="end">
							<span className={classes.tooltipText}>{retroValue ?? '—'}</span>
						</Col>
					</Row>
				);
			})}
		</div>
	);
};

export default ExtendedTooltip;
