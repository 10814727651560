import { memo, useCallback } from 'react';

import api from '~/api';
import { PersonalDataButton } from '~shared/components/PersonalDataButton';

interface Props {
	courierId: string;
	disabled?: boolean;
}

export const CourierIdentification = memo(({ courierId, disabled }: Props) => {
	const load_identifications = useCallback((): Promise<string> => {
		return api.couriers.load_identifications({ courier_id: courierId }).then((e) => e.data.identifications.join(', '));
	}, [courierId]);

	return (
		<PersonalDataButton
			disabled={disabled}
			loadData={load_identifications}
			className="data-test-pd-courier-identification-button"
		/>
	);
});
