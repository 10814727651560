import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	healthContainer: {
		inlineSize: '100%',
	},
	cuteLittleText: {
		display: 'inline-flex',
		flexWrap: 'nowrap',
		justifyContent: 'flex-end',
		color: theme.text.light,
		fontSize: '0.9em',
	},
	sectorHeader: {
		fontSize: '1.2em',
		fontWeight: 'bold',
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	specialInfoRow: {
		display: 'grid',
		gridTemplateColumns: '7fr 5fr',
		marginBlock: '20px',
		marginInline: 0,
		'@media (max-width: 900px)': {
			gridTemplateColumns: 'auto',
		},
	},
	specialInfoCompany: {
		gridTemplateColumns: '1fr',
	},
	healthHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}));
