import { orderTypeNames } from '~server-types/doc/api/models/order';

export const createWarehouseInfoBlockItems = (data: Record<string, number>) => {
	return Object.keys(data)
		.filter((orderType) => Boolean(orderTypeNames[orderType]) && data[orderType])
		.map((orderType) => {
			return {
				name: orderTypeNames[orderType],
				dataTest: `${orderType}`,
				value: data[orderType],
			};
		});
};
