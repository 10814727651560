import { blue, green, red } from '@ant-design/colors';

import { mobileMaxWidth } from '~constants/breakPoints';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	label: {
		color: theme.text.primary,
	},
	labelRequired: {
		'&:after': {
			content: "'*'",
			color: red[5],
			display: 'inline-block',
			position: 'relative',
			insetInlineEnd: -2,
			insetBlockStart: -2,
		},
	},
	select: {
		inlineSize: '100%',
		minInlineSize: 0,
	},
	valueOnViewMode: {
		display: 'inline-flex',
		flexWrap: 'wrap',
		gap: 4,
		color: theme.text.primary,
		margin: 0,
		fontWeight: 500,
		fontSize: 14,
		marginBlockEnd: 10,
		wordBreak: 'break-word',
		'&:last-child': {
			marginBlockEnd: 0,
			display: 'flex',
		},
		'&.withCopyButton': {
			wordBreak: 'break-word',
		},
		'&>span:last-of-type>.status-tag': {
			marginInlineEnd: 0,
		},
	},
	emptyValue: {
		color: theme.text.secondary,
	},
	unactive: {
		color: theme.text.secondary,
	},
	dotSeparator: {
		display: 'none',
		[`@media (min-width:${mobileMaxWidth}px)`]: {
			display: 'block',
			borderBlockEnd: `2px dotted ${theme.border.primary}`,
			flexGrow: 1,
			marginBlock: '0',
			marginInline: '4px',
		},
	},
	inputLeft: {
		position: 'relative',
	},
	inputLeftEdit: {
		position: 'relative',
		insetBlockStart: -5,
	},
	labelContainer: {
		display: 'flex',
		alignItems: 'baseline',
		minBlockSize: '42px',
		'&+div': {
			'& .wms-input-container': {
				position: 'relative',
			},
		},
		/* stylelint-disable-next-line */
		'.two-col-full-width &': {
			flexGrow: 1,
			maxInlineSize: '100%',
		},
	},
	readOnlyFieldContainer: {
		flexBasis: '100%',
	},
	boldReadOnlyFieldContainer: {
		fontWeight: 500,
	},
	tooltipIconWarning: {
		fill: '#faad14 !important',
		blockSize: '11px',
	},
	tooltipIconInfo: {
		fill: theme.text.icon,
		blockSize: 14,
		marginBlockEnd: -2,
		marginInlineStart: 3,
	},
	warningContainer: {
		paddingBlockStart: '6px',
		marginInlineEnd: '10px',
		marginInlineStart: '10px',
		blockSize: 'inherit',
	},
	encodeLabelContainer: {
		display: 'flex',
		alignItems: 'baseline',
	},
	leftLabelContainer: {
		display: 'flex',
		alignItems: 'center',
		minBlockSize: '42px',
		'&+div': {
			'& .wms-input-container': {
				position: 'relative',
				insetBlockStart: -5,
			},
		},
	},
	inputContainer: {
		display: 'flex',
		inlineSize: '100%',
		position: 'relative',
	},
	inputContainerSchedule: {
		display: 'flex',
		gap: 8,
		marginBlockEnd: 8,
	},
	inputContainerScheduleInterval: {
		'&>*': {
			display: 'inline-block',
			minInlineSize: 58,
			'& .ant-input-number': {
				inlineSize: '100%',
			},
		},
	},
	lock: {
		position: 'relative',
		zIndex: 1,
		marginInlineStart: 16,
		padding: 0,
		inlineSize: 32,
		blockSize: 32,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexShrink: 0,
		'&:active > span, &:focus > span': {
			position: 'absolute',
		},
	},
	unlockIcon: {
		color: blue[4],
	},
	checkIcon: {
		position: 'absolute',
		insetInlineEnd: -24,
		color: green[4],
	},
	priceUnit: {
		display: 'flex',
		alignItems: 'center',
	},
	priceUnitInput: {
		marginBlock: '0',
		marginInline: '12px',
	},
	radioGroup: {
		display: 'flex',
		marginBlockEnd: 16,
		whiteSpace: 'nowrap',
		'&>label': {
			flex: 1,
			textAlign: 'center',
		},
	},
	productField: {
		marginBlockEnd: 16,
	},
	breakLine: {
		flexBasis: '100%',
		inlineSize: 0,
	},
	storesList: {
		padding: 0,
		listStyle: 'none',
		margin: '0',
		marginBlockEnd: '20px',
	},
	storesListItem: {
		display: 'flex',
		alignItems: 'center',
		'& a': {
			display: 'inline-block',
			marginInlineStart: 10,
		},
		marginBlockEnd: 4,
	},
	listItemView: {
		marginBlockEnd: 10,
	},
	toggleText: {
		fontSize: '12px',
		lineHeight: '20px',
		fontWeight: 400,
		fontStyle: 'normal',
		cursor: 'pointer',
		color: theme.text.secondary,
	},
	slideDownText: {
		marginBlockEnd: 2,
	},
	passwordBlock: {
		display: 'inline-block',
		marginInlineEnd: 8,
		minInlineSize: 32,
	},
	buttonRight: {
		marginInlineStart: '16px',
	},
	inputSelect: {
		minInlineSize: 0,
	},
	setActiveButton: {
		inlineSize: '100%',
		marginBlockEnd: '0.7em',
		border: `1px solid ${green[5]} !important`,
		color: 'white  !important',
		backgroundColor: `${green[5]} !important`,
		'&:not([disabled]):active, &:not([disabled]):focus': {
			border: `1px solid ${green[5]}`,
			color: 'white',
			backgroundColor: green[5],
		},
		'&:not([disabled]):hover': {
			border: `1px solid ${green[4]} !important`,
			color: 'white',
			backgroundColor: `${green[4]} !important`,
		},
	},
	switchLine: {
		display: 'flex',
		alignItems: 'center',
		'& button': {
			marginInlineEnd: 8,
		},
	},
	switchTooltip: {
		marginInlineStart: '10px',
		color: theme.text.primary,
		blockSize: 14,
		'& svg': {
			fill: theme.text.disabled,
		},
	},
	labelTooltip: {
		marginInlineStart: '10px',
		color: theme.text.primary,
	},
	tooltipWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	dateRangeView: {
		display: 'inline-flex',
	},
	tooltipIcon: {
		position: 'absolute',
		insetBlockStart: 0,
		insetBlockEnd: 0,
		margin: 'auto',
		blockSize: 14,
		insetInlineEnd: -22,
		'& svg': {
			fill: theme.text.disabled,
		},
	},
	tooltipIconSwitch: {
		position: 'relative',
		insetInlineEnd: -8,
	},
	tooltipIconText: {
		position: 'relative',
		insetInlineEnd: -8,
		marginBlockStart: 9,
	},
	fieldAlert: {
		borderRadius: 12,
		paddingBlock: '8px',
		paddingInline: '12px',
		inlineSize: '100%',
		'& .ant-alert-message': {
			fontWeight: 'normal',
			fontSize: '14px',
			lineHeight: '22px',
			color: '#FA8C16',
		},
		'& .ant-alert-description': {
			fontSize: '12px',
			lineHeight: '14px',
		},
		'& .ant-alert-icon': {
			marginInlineEnd: 8,
			'& svg': {
				inlineSize: 20,
			},
		},
	},
	usersListItem: {
		'& a': {
			display: 'inline-block',
			fontWeight: 500,
		},
	},
	warning: {
		color: 'red',
		'&>div': {
			color: 'red',
		},
	},
	inputError: {
		borderColor: theme.text.textField.errorMessage,
	},
	inputTextError: {
		position: 'relative',
		display: 'flex',
		color: theme.text.textField.errorMessage,
		fontSize: 12,
		insetInlineStart: 2,
		whiteSpace: 'pre',
		inlineSize: '100%',
		textWrap: 'wrap',
	},
	inputTextErrorInlineEnd: {
		insetInlineStart: 'unset',
		insetInlineEnd: 0,
	},
	inputErrorContainer: {
		insetBlockEnd: 'unset',
	},
	storeListLink: {
		inlineSize: '100%',
	},
	multipleInputTextErrors: {
		'&>p': {
			marginBlockEnd: 0,
		},
	},
}));
