import type { ClusterMapLayer } from '~server-types/doc/api/models/cluster';
import { checkExp } from '~zustand/userData';

const re = /\d+-\d+$/;
export const getShortNumber = (docNumber: string) => docNumber.match(re);

const NO_REASSIGNMENT_STATUSES = [
	'DELIVERED',
	'CANCELLED',
	'ARRIVED_TO_CUSTOMER',
	'CUSTOMER_NO_SHOW',
	'ORDER_TAKEN',
	'PICKUP',
	'UNKNOWN',
];

export const getPreset = (mapType: ClusterMapLayer, status?: string | null) => {
	const canReassignAfterTaken = checkExp('exp_order_reassign_after_taken');

	const noReassignmentStatuses = canReassignAfterTaken
		? NO_REASSIGNMENT_STATUSES.filter((e) => e !== 'ORDER_TAKEN')
		: NO_REASSIGNMENT_STATUSES;

	const color = !status || noReassignmentStatuses.includes(status) ? 'gray' : 'blue';

	if (mapType === 'yandex') {
		return `islands#${color}StretchyIcon`;
	}

	if (mapType === 'google') {
		return color;
	}
};

export const createDiv = ({ classNames = [], children = [] }: { classNames?: string[]; children?: HTMLElement[] }) => {
	const div = document.createElement('div');
	div.classList.add(...classNames);
	div.append(...children);

	return div;
};
