import type { JSX } from 'react';

import { useStyles } from '../styles';

interface Props {
	children: string | JSX.Element;
	dataTest?: string;
}

const LittleText = ({ children, dataTest }: Props) => {
	const { classes } = useStyles();

	return (
		<span className={classes.cuteLittleText} data-test={dataTest}>
			{children}
		</span>
	);
};

export default LittleText;
