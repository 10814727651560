import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { Companies } from '~types/companies';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<Companies.SeekRequest, Companies.Company>('/api/admin/companies/list'),
	load: makeLoadFn<Companies.Company, 'company_id'>('/api/admin/companies/load', 'company_id'),
	save: (data: Record<string, any>) => axios.post<{ result: Companies.Company }>('/api/admin/companies/save', data),
	tokenLoad: (data: { company_id: string }) => axios.post<{ token: string }>('/api/admin/companies/token/load', data),
	tokenChange: (data: { company_id: string }) => axios.post('/api/admin/companies/token/change', data),
	joinCompany: (data: { company_id: string }) => axios.post('/api/admin/companies/join-company', data),
};
