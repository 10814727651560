import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NdaIcon } from '~images/nda.svg';

import { useStyles } from './styles';

export interface INdaButtonProps {
	collapsed: boolean;
}

export function NdaButton({ collapsed }: INdaButtonProps) {
	const [t, i18n] = useTranslation();
	const { classes } = useStyles({ collapsed });
	const direction = i18n.dir();

	return (
		<Tooltip
			title={
				<div data-test="nda popover">
					<div className={classes.popoverHeader}>{t('Конфиденциально')}</div>
					<div className={classes.popoverBody}>{t('Коммерческая тайна ООО «Яндекс»')}</div>
					<a
						className={classes.popoverLink}
						href="https://wiki.yandex-team.ru/security/nda/"
						target="_blank"
						rel="noopener noreferrer"
					>
						{t('Узнать подробнее')}
					</a>
				</div>
			}
			trigger="hover"
			placement={direction === 'ltr' ? 'right' : 'left'}
			zIndex={10_000}
		>
			<div className={classes.container} data-test="nda">
				<NdaIcon />
				{!collapsed && <div className={classes.text}>{t('Конфиденциально')}</div>}
			</div>
		</Tooltip>
	);
}
