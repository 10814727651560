import { Col, Row } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import getProperTime from '~shared/utils/transformSecondsToTimeString';
import type { RechartsProps } from '~types/common';

import { HealthContext } from '../../context';
import type { TimeIntervalDataObject } from '../../healthTypes';
import { useStyles } from './styles';

const BasicTooltip = ({ payload }: RechartsProps<{ payload: TimeIntervalDataObject }>) => {
	const [t] = useTranslation();
	const { periodState } = useContext(HealthContext);
	const { classes } = useStyles();

	const dataPoint = payload?.[0]?.payload;

	if (!dataPoint) return null;

	const retroLabels = {
		day: t('День назад'),
		week: t('Неделю назад'),
	};

	const isTwoRows = !!dataPoint.retroFullDate && dataPoint.retroValue !== undefined;

	const value =
		!!dataPoint.value && dataPoint.key !== 'orders_count'
			? getProperTime(dataPoint.value, true, true)
			: dataPoint.value;
	const retroValue =
		!!dataPoint.retroValue && dataPoint.key !== 'orders_count'
			? getProperTime(dataPoint.retroValue, true, true)
			: dataPoint.retroValue;

	return (
		<div className={classes.tooltipContainer}>
			<div className={classes.tooltipHeader}>{dataPoint.fullDate.format('HH:mm, DD MMMM YYYY')}</div>
			<Row justify="space-between" gutter={5}>
				<Col span={12}>
					<span className={classes.tooltipLabel}>{t('Сейчас')}</span>
				</Col>
				<Col span={12} flex="end">
					<span className={classes.tooltipText}>{value ?? '—'}</span>
				</Col>
			</Row>
			{isTwoRows && (
				<Row justify="space-between" gutter={5}>
					<Col span={12}>
						<span className={classes.tooltipLabel}>
							{retroLabels[dataPoint.comparisonType ?? periodState.comparisonType]}
						</span>
					</Col>
					<Col span={12}>
						<span className={classes.tooltipText}>{retroValue}</span>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default BasicTooltip;
