import Icon from '@ant-design/icons/es/components/Icon';

export { default as ArrowDownOutlined } from '@ant-design/icons/ArrowDownOutlined';
export { default as CaretRightOutlined } from '@ant-design/icons/CaretRightOutlined';
export { default as CheckCircleFilled } from '@ant-design/icons/CheckCircleFilled';
export { default as ClockCircleFilled } from '@ant-design/icons/ClockCircleFilled';
export { default as CloseOutlined } from '@ant-design/icons/CloseOutlined';
export { default as DeleteOutlined } from '@ant-design/icons/DeleteOutlined';
export { default as DownloadOutlined } from '@ant-design/icons/DownloadOutlined';
export { default as DownOutlined } from '@ant-design/icons/DownOutlined';
export { default as EditOutlined } from '@ant-design/icons/EditOutlined';
export { default as ExclamationCircleOutlined } from '@ant-design/icons/ExclamationCircleOutlined';
export { default as ExportOutlined } from '@ant-design/icons/ExportOutlined';
export { default as EyeOutlined } from '@ant-design/icons/EyeOutlined';
export { default as FlagOutlined } from '@ant-design/icons/FlagOutlined';
export { default as FolderFilled } from '@ant-design/icons/FolderFilled';
export { default as LeftOutlined } from '@ant-design/icons/LeftOutlined';
export { default as PaperClipOutlined } from '@ant-design/icons/PaperClipOutlined';
export { default as PlusOutlined } from '@ant-design/icons/PlusOutlined';
export { default as PrinterOutlined } from '@ant-design/icons/PrinterOutlined';
export { default as ReloadOutlined } from '@ant-design/icons/ReloadOutlined';
export { default as RetweetOutlined } from '@ant-design/icons/RetweetOutlined';
export { default as RightOutlined } from '@ant-design/icons/RightOutlined';
export { default as SaveFilled } from '@ant-design/icons/SaveFilled';
export { default as SaveOutlined } from '@ant-design/icons/SaveOutlined';
export { default as SearchOutlined } from '@ant-design/icons/SearchOutlined';
export { default as SettingOutlined } from '@ant-design/icons/SettingOutlined';
export { default as StopOutlined } from '@ant-design/icons/StopOutlined';
export { default as UnorderedListOutlined } from '@ant-design/icons/UnorderedListOutlined';
export { default as UploadOutlined } from '@ant-design/icons/UploadOutlined';
export { default as UpOutlined } from '@ant-design/icons/UpOutlined';

export default Icon;
