import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useStyles } from '../styles';

type DataType = 'url' | 'text' | 'bool' | 'image' | 'dt';

interface Props {
	keyString: string;
	value: string | boolean | number;
}

const typeRE = /^(img|url|dt|bool|text)_(.*)$/;

const KeydataRow = ({ keyString, value }: Props) => {
	const { classes } = useStyles();

	const dataType: DataType = (keyString.match(typeRE)?.[1] as DataType) ?? 'text';
	const dataKey: string = keyString.match(typeRE)?.[2] ?? '';
	const valueRendered = useMemo(() => {
		switch (dataType) {
			case 'text':
				return <>{value}</>;
			case 'url':
				value = value as string;
				return <Link to={value}>{value.slice(0, 60) + '...'}</Link>;
			case 'dt':
				return <>{dayjs(value as string).format('DD.MM.YYYY HH:mm:ss')}</>;
			case 'bool':
				return <>{value.toString()}</>;
		}
	}, [keyString, value]);

	return (
		<Row>
			<Col span={6}>{dataKey}</Col>
			<Col span={18}>
				<span className={classes.keydataValue}>{valueRendered}</span>
			</Col>
		</Row>
	);
};

export default KeydataRow;
