import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

import type { HealthLevelMetrics, PeriodState } from './healthTypes';

export const levelDictionary = makeDynamicTranslations({
	company: tDynamic('Компания'),
	cluster: tDynamic('Кластер'),
	head_supervisor: tDynamic('Старший супервайзер'),
	supervisor: tDynamic('Супервайзер'),
	store: tDynamic('Склад'),
});

export const dotDivider = ' · ';

export const defaultPeriodState: PeriodState = {
	sliceType: 'day',
	comparisonType: 'week',
};

export const sourceByType = {
	company: { id_slice: 'companies', titleProp: 'title' },
	cluster: { id_slice: 'clusters', titleProp: 'title' },
	head_supervisor: { id_slice: 'userExecutors', titleProp: 'fullname' },
	supervisor: { id_slice: 'userExecutors', titleProp: 'fullname' },
	store: { id_slice: 'stores', titleProp: 'title' },
} as const;

const commonColumnKeys = [
	'full_cte',
	'assemble_time',
	'assemble_wait_time',
	'waiting_for_pickup',
	'to_client_time',
	'orders_count',
	'store_surge_level',
];

export const tableColumnKeys = ['title', ...commonColumnKeys];
export const clusterTableColumnKeys = ['cluster_title', ...commonColumnKeys];

export const defaultGraphsState: { now: HealthLevelMetrics[]; past: HealthLevelMetrics[] } = { now: [], past: [] };

export const criticalEvents = makeDynamicTranslations({
	stop_list: tDynamic('Стоп-лист'),
	hide_and_show: tDynamic('Отключение оборудования склада'),
	turnoff_starbucks: tDynamic('Отключение оборудования кухни'),
	store_disabled: tDynamic('Склад не работает'),
});
