import { Col, Row, Select, Spin } from 'antd';
import type { OptionProps } from 'antd/lib/select';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import type React from 'react';
import { type FunctionComponent, type JSX, type PropsWithChildren, type ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { SelectFieldParams } from '~constants/dataPageFields/types';

import { useStyles } from '../styles';
import type { FieldProps } from '../types';
import { getColSpan, getViewText, useFieldChangeProps } from '../utils';
import { useStyles as useSelectStyles } from './styles';

const { Option } = Select;

export interface SelectFieldProps extends FieldProps {
	options: string[];
	entity?: 'cluster' | 'store' | 'company' | 'courier_service' | 'product';
	optionsGroup?: { label: string; options: OptionProps[] }[] | OptionProps[];
	dictionary?: Record<string, string>;
	showSearch?: boolean;
	onSearch?: (value: string) => void;
	onClear?: () => void;
	addMode?: boolean;
	loading?: boolean;
	path?: string;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	allowClear?: boolean;
	linkTo?: ((value: string | undefined) => string) | string;
	hasLock?: boolean;
	simpleSearch?: boolean;
	paramsComponent?: JSX.Element;
	inputClassName?: string;
	onFocus?: () => void;
	onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	onMouseEnter?: () => void;
	customLayout?: (label: string, input: JSX.Element, params?: JSX.Element, className?: string) => JSX.Element;
	onSelect?: (value: string | null, option: any) => void;
	hideLabel?: boolean;
	afterLabel?: any;
	fieldRequired?: boolean;
	dataTest?: string;
	params?: SelectFieldParams;
	disabledOptions?: string[];
	mode?: 'multiple' | 'tags' | undefined;
	onlyView?: boolean;
	tagView?: boolean;
	resetValue?: boolean;
	viewValue?: string;
	additionalContent?: JSX.Element;
	additionalProps?: Record<string, any>;
	viewIconRight?: JSX.Element;
	resetInputValue?: boolean;
	placeholder?: string;
	emptyPlaceholder?: string;

	prepareOptionProps?: (
		option: string,
		initialProps: Omit<OptionProps, 'children' | 'value'>,
		dictionary: Record<string, string> | []
	) => Omit<OptionProps, 'children'>;
	rowClassName?: string;
	autoFocus?: boolean;
	width?: string;
	widthEditMode?: string;
	invalidMessage?: string;
	containerClassName?: string;
	labelContainerClassName?: string;
	hasViewMode?: boolean;
	notFoundContent?: ReactNode;

	renderSelectedItemExtraInfo?: (id: string) => JSX.Element;
	emptyTagText?: string;
	onBlur?: () => void;
	onDropdownVisibleChange?: (open: boolean) => void;
	open?: boolean;
	filterOption?: ((input: string, options: OptionProps) => boolean) | boolean;
	searchValue?: string;
	customLayoutClassName?: string;
	popupClassName?: string;
	tagRender?: (props: CustomTagProps) => React.ReactElement;
	isParamsComponentAlwaysVisible?: boolean;
}

const SelectField: FunctionComponent<PropsWithChildren<SelectFieldProps>> = ({
	tagRender,
	id,
	label = '',
	input,
	meta: { error },
	afterLabel,
	disabled,
	hasLock,
	options,
	optionsGroup,
	loading,
	dictionary,
	showSearch = true,
	onSearch,
	editMode,
	path,
	colspan,
	allowClear,
	linkTo,
	onFocus,
	onInputKeyDown,
	onMouseEnter,
	addMode,
	paramsComponent,
	inputClassName,
	customLayout,
	onClear,
	onSelect,
	hideLabel,
	fieldRequired,
	dataTest,
	params,
	disabledOptions,
	mode,
	onlyView,
	tagView,
	resetValue,
	viewValue,
	additionalContent,
	viewIconRight,
	resetInputValue,
	placeholder,
	prepareOptionProps,
	rowClassName,
	autoFocus = false,
	width,
	widthEditMode,
	invalidMessage,
	containerClassName,
	labelContainerClassName,
	hasViewMode = true,
	notFoundContent = null,
	emptyTagText,
	onBlur,
	onDropdownVisibleChange,
	open,
	filterOption,
	searchValue,
	customLayoutClassName,
	popupClassName,
	isParamsComponentAlwaysVisible,
}: SelectFieldProps) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { classes: selectClasses } = useSelectStyles();
	const [currentOption, setCurrentOption] = useState<string | null>();
	if (resetInputValue && !input.value) {
		input.value = undefined;
	}

	const fieldChangeProps = useFieldChangeProps(
		input,
		disabled,
		hasLock && !addMode,
		editMode,
		`input lock button ${label}`,
		undefined,
		resetValue
	);

	useEffect(() => {
		setCurrentOption(input.value);
	}, []);

	const onSelectWrapper = (value: string | null, option: any) => {
		setCurrentOption(value);
		if (onSelect) {
			onSelect(value, option);
		}
	};

	const isEmptyTag = tagView && !input.value?.length;

	const getInputValue = () => (Array.isArray(input.value) && !input.value.length ? undefined : input.value);

	const viewText = viewValue || (isEmptyTag ? emptyTagText ?? t('Нет тэга') : getInputValue());

	const renderSelect = loading ? (
		<Spin size="small" />
	) : (
		<>
			<div className={cx(classes.inputContainer, 'wms-input-container', containerClassName, { error: error })}>
				<Select
					className={cx(selectClasses.select, inputClassName, classes.inputSelect, {
						[selectClasses.selectError]: error || invalidMessage,
					})}
					showSearch={showSearch}
					onSearch={onSearch}
					searchValue={searchValue}
					onInputKeyDown={onInputKeyDown}
					onFocus={(onFocus || input.onFocus) as () => void}
					onMouseEnter={onMouseEnter}
					allowClear={allowClear}
					data-test={dataTest ?? `data form ${label}${fieldRequired ? ' required' : ''}${disabled ? ' disabled' : ''}`}
					notFoundContent={notFoundContent}
					mode={mode}
					filterOption={
						!onSearch && !optionsGroup
							? (input, option) => {
									return option?.props.title && option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
								}
							: filterOption ?? false
					}
					popupClassName={cx(
						`data-test-select-dropdown-${(dataTest ? dataTest : label).replace(/\s/g, '-')}`,
						popupClassName
					)}
					loading={loading}
					onSelect={onSelectWrapper}
					onClear={onClear}
					{...fieldChangeProps.changeProps}
					placeholder={placeholder}
					autoFocus={autoFocus}
					open={open}
					onDropdownVisibleChange={onDropdownVisibleChange}
					onBlur={onBlur}
					options={optionsGroup}
					tagRender={tagRender}
					aria-required={fieldRequired}
				>
					{options?.map((option, index) => {
						const initialProps = {
							'data-test': `data form option select ${dictionary?.[option] || option}${
								disabledOptions?.includes(option) ? ' disabled' : ''
							}`,
							disabled: disabledOptions?.includes(option),
						};

						const props = prepareOptionProps
							? prepareOptionProps(option, initialProps, dictionary ?? [])
							: initialProps;
						return (
							<Option key={option || index} value={option} {...props} title={dictionary?.[option] ?? option}>
								<div
									className={cx({
										[selectClasses.option]: !mode && option !== currentOption,
										[selectClasses.selectedOption]: !mode && option === currentOption,
									})}
								>
									{dictionary?.[option] ?? option}
								</div>
							</Option>
						);
					})}
				</Select>
				{viewIconRight && <div className={classes.warningContainer}>{viewIconRight}</div>}
				{!addMode && hasLock && fieldChangeProps.lockButton}
			</div>
			{error && (
				<span className={cx({ [classes.inputTextError]: error })} data-test={`data form ${label} error`}>
					{error}
				</span>
			)}
		</>
	);

	if (customLayout) {
		return customLayout(label, renderSelect, paramsComponent, customLayoutClassName);
	}

	const getParamsComponent = () => {
		if (isParamsComponentAlwaysVisible || (editMode && !onlyView) || !hasViewMode) {
			return paramsComponent;
		}
	};

	return (
		<Row
			align="top"
			gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]}
			className={rowClassName}
			style={editMode && widthEditMode ? { width: widthEditMode } : { width }}
		>
			{!hideLabel && (
				<Col {...getColSpan(colspan)} className={cx(classes.labelContainer, labelContainerClassName)}>
					<label
						className={cx(classes.label, {
							[classes.labelRequired]: editMode && fieldRequired,
						})}
						htmlFor={id}
					>
						{label}
					</label>
					{afterLabel || null}
					<div className={classes.dotSeparator} />
				</Col>
			)}

			<Col {...getColSpan(colspan)} className={classes.readOnlyFieldContainer}>
				{getParamsComponent()}
				{(editMode && !onlyView) || !hasViewMode ? (
					<>
						{renderSelect}
						{!!invalidMessage && <span className={classes.inputTextError}>{invalidMessage}</span>}
					</>
				) : (
					<div
						className={cx(classes.valueOnViewMode, params?.valueOnViewModeClassName)}
						data-test={dataTest ?? `data form ${label}`}
					>
						{linkTo && viewText ? (
							<>
								<Link to={typeof linkTo === 'string' ? linkTo : linkTo(viewText)}>
									{getViewText({
										value: viewText,
										dictionary,
										path,
										loading,
										params: {
											tagView,
										},
										viewIconRight,
									})}
								</Link>
								{params?.afterViewText}
							</>
						) : (
							<>
								{getViewText({
									value: viewText,
									dictionary,
									path,
									loading,
									params: {
										className: !viewText && viewText !== 0 ? classes.emptyValue : undefined,
										tagView,
										blank: params?.blank,
									},
									viewIconRight,
								})}
								{params?.afterViewText}
							</>
						)}
					</div>
				)}
			</Col>
			{additionalContent && <Col span={24}>{additionalContent}</Col>}
		</Row>
	);
};

export default SelectField;
