import axios from 'axios';

import { makeListFn } from '~shared/utils/makeApiFns';
import type { LegalEntities } from '~types/legalEntities';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	list: makeListFn<LegalEntities.SeekRequest, LegalEntities.LegalEntity>('/api/admin/legal_entities/list'),
	load: makeLoadFn<LegalEntities.LegalEntity, 'legal_entity_id'>('/api/admin/legal_entities/load', 'legal_entity_id'),
	save: (data: Record<string, any>) =>
		axios.post<{ result: LegalEntities.LegalEntity }>('/api/admin/legal_entities/save', data),
};
