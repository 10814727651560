import { useTranslation } from 'react-i18next';

import { ReactComponent as Courier } from '~images/icons/courier.svg';
import DeliveryCard from '~shared/components/DeliveryCard/DeliveryCard';
import type { CouriersByStatusData } from '~shared/constants/tableauCouriers';
import { deliveryStatusesArray } from '~shared/constants/tableauCouriers';

import ColumnContent from '../../ColumnContent/ColumnContent';
import { useStyles } from '../../styles';

interface Props {
	couriers: CouriersByStatusData;
}

const Couriers = ({ couriers }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	return (
		<div data-test="couriers" className={classes.column}>
			<ColumnContent
				icon={Courier}
				iconColor="limeGreen"
				text={t('Курьеры')}
				ratio={{ count: couriers.activeCouriers, totalCount: couriers.allCouriers }}
			/>
			{deliveryStatusesArray.map((status) => (
				<DeliveryCard theme="health" key={status} status={status} couriersStats={couriers.columns[status]} />
			))}
		</div>
	);
};

export default Couriers;
