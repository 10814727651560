import { Card } from '@lavka/ui-kit';

import type { Field, SectionConfig } from '~constants/dataPageFields/types';

import { useStyles as useFieldStyles } from '../Fields/styles';
import DataField from './DataField';

interface Props {
	section: SectionConfig;
	pageName?: string;
	disabled?: boolean;
	editMode?: boolean;
	addMode?: boolean;
	classes?: Record<string, string>;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	parentName?: string;
	parentIndex?: number;
	dataTest?: string;
	sectionView?: 'transparent' | 'default';
}
const DataSection = ({
	section,
	disabled,
	pageName,
	colspan,
	addMode,
	editMode,
	parentName,
	parentIndex,
	classes,
	dataTest,
	sectionView = 'transparent',
}: Props) => {
	classes = classes ?? {};
	const { cx, classes: fieldClasses } = useFieldStyles();

	const addParentIndex = (field: Field) => {
		return {
			...field,
			label: field.withoutParentIndex ? field.label : `${field.label} ${parentIndex}`,
			dataTest: `${field.key} ${parentIndex}`,
			...('disabled' in field && typeof field.disabled === 'function' && typeof parentIndex === 'number'
				? { disabled: field.disabled(parentIndex - 1) }
				: {}),
		};
	};

	const fields = section.fields
		.map((field) => {
			if (
				field.hidden &&
				!((editMode || addMode) && typeof field.hidden !== 'boolean' && !field.hidden.edit) &&
				!(!editMode && !addMode && typeof field.hidden !== 'boolean' && !field.hidden.view)
			) {
				return null;
			}

			return (
				<DataField
					key={[parentName, field.key].join('')}
					field={typeof parentIndex === 'number' ? addParentIndex(field) : field}
					parentName={parentName}
					disabled={disabled}
					pageName={pageName}
					colspan={colspan}
					addMode={addMode}
					editMode={editMode}
					classes={classes}
				/>
			);
		})
		.filter(Boolean);

	if (fields.length === 0) {
		return null;
	}

	const title = section.label && (
		<span
			className={cx(classes.formTitle, {
				[fieldClasses.labelRequired]: section.required,
			})}
		>
			{section.label}
			{section.labelElement}
		</span>
	);

	const label = section.buttonElement && {
		color: 'white',
		text: section.buttonElement,
	};

	return (
		<Card
			className={cx(classes.section, { [classes.defaultSection]: sectionView === 'default' })}
			view={sectionView}
			title={title}
			data-testid={dataTest ?? (section.label ? `section ${section.label}` : undefined)}
			// @ts-expect-error
			description={section.description}
			// @ts-expect-error
			label={label}
		>
			<div className={classes.fields}>{fields}</div>
		</Card>
	);
};

export default DataSection;
