interface Props {
	url: string;
	pathnamePostfix?: string;
	isSendImmediately?: boolean;
	messageText?: string;
	orderId?: string;
	externalId?: string;
	productId?: string;
}

export const getSupportChatIframeUrl = ({
	url,
	pathnamePostfix = '',
	isSendImmediately = false,
	messageText,
	orderId,
	externalId,
	productId,
}: Props): string => {
	if (!url) {
		return '';
	}

	const resultUrl = new URL(url);
	resultUrl.pathname += pathnamePostfix;
	resultUrl.searchParams.append('wms_source', 'wms');

	if (isSendImmediately) {
		resultUrl.searchParams.append('initial_message_autosend', 'true');
	}

	if (messageText) {
		resultUrl.searchParams.append('initial_message_text', messageText);
	}

	if (orderId) {
		resultUrl.searchParams.append('order_id', orderId);
	}

	if (externalId) {
		resultUrl.searchParams.append('doc_number', externalId);
	}

	if (productId) {
		resultUrl.searchParams.append('product_id', productId);
	}

	return resultUrl.toString();
};
