import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	filterHeader: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
	},
	tabBlocksArea: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start',
		marginInlineStart: 20,
		marginBlockEnd: 5,
	},
	controlBlock: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	firstInput: {
		'.ant-picker-input:first-child input': {
			marginInlineEnd: -40,
		},
	},
	controlLabel: {
		marginInlineEnd: 20,
		color: theme.text.primary,
	},
	graphsModal: {
		insetBlockStart: '5%',
		inlineSize: '90% !important',
		'& .ant-modal-body': {
			blockSize: '80vh',
		},
	},
	graphsModalContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
	},
	tickSelectContainer: {
		marginInlineStart: 'auto',
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	},
}));
