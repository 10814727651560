import { mobileMaxWidth } from '~constants/breakPoints';
import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	statsBlock: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gap: 10,

		[`@media (min-width:${mobileMaxWidth}px)`]: {
			gridTemplateColumns: 'repeat(4, minmax(100px, 1fr))',
		},
		['@media (min-width:1280px)']: {
			gridTemplateColumns: 'repeat(8, minmax(100px, 1fr))',
		},
	},
}));
