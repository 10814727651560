import { useEffect, useState } from 'react';

const useTimer = (secs: number) => {
	const [seconds, setSeconds] = useState(secs);
	const intervalTime = 1000;

	useEffect(() => {
		const timerInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds((s) => s - 1);
			} else if (seconds <= 0) {
				clearInterval(timerInterval);
			}
		}, intervalTime);
		return () => clearInterval(timerInterval);
	}, [seconds]);

	return [seconds, setSeconds] as const;
};

export default useTimer;
