import { makeStyles } from '~styles/theme';

import type { Theme } from '../../../../components/Theme';

export const useStyles = makeStyles()((theme: Theme) => ({
	pauseLabel: {
		display: 'inline',
		background: theme.bgColor.label,
		color: theme.text.label,
		paddingBlock: '3px',
		paddingInline: '10px',
		borderRadius: 12,
		marginInlineStart: 15,
	},
	pickedLocation: {
		display: 'flex',
		alignItems: 'end',
	},
	locationRow: {
		display: 'flex',
		alignItems: 'center',
		marginBlockStart: 5,
	},
	locationTitle: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
	},
	pauseFilter: {
		marginBlockEnd: 20,
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
	locationSpinner: {
		marginBlockStart: 20,
		display: 'flex',
		justifyContent: 'center',
	},
	inputTextError: {
		color: theme.text.textField.errorMessage,
		fontSize: 12,
		marginInlineStart: 2,
	},
}));
