import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';

export const FRONT_SUGGEST_STATUSES = makeDynamicTranslations({
	requested: tDynamic('В очереди'),
	cooking: tDynamic('Готовится'),
	completed: tDynamic('Готово'),
	packing: tDynamic('Упаковка'),
	done: tDynamic('Готово'),
	canceled: tDynamic('Отменено'),
});

export const FRONT_ORDER_STATUSES = makeDynamicTranslations({
	request: tDynamic('Ожидает'),
	requested: tDynamic('В очереди'),
	processing: tDynamic('Готовится'),
	completed: tDynamic('Готово'),
	canceled: tDynamic('Отменен'),
	pause: tDynamic('На паузе'),
});

export const CHANGE_SUGGEST_STATUS_CONFIG = {
	disableDefaultNotification: [400],
	disableExternalLog: [400],
};

export const CANCELED_ORDER_TIMEOUT = process.env.REACT_APP_MODE === 'ci' ? 5_000 : 15_000;

export const MIN_COOKING_TIME = 5;

export const MIN_COOKING_TIME_MILLISECONDS = MIN_COOKING_TIME * 1_000;
