import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	exportSchetTasksButton: {
		color: theme.text.disabled,
	},
	approveAlertSchedule: {
		listStyle: 'none',
		padding: 0,
		margin: 0,
		'& li': {
			marginBlockEnd: 8,
			'&:last-of-type': {
				marginBlockEnd: 0,
			},
		},
	},
	schetTaskBlock: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingInlineStart: 10,
	},
	approveMark: {
		insetBlockStart: -3,
		insetInlineStart: 0,
		insetBlockEnd: 0,
		margin: 'auto',
		position: 'absolute',
		blockSize: 14,
		'& svg': {
			fill: '#FAAD14',
		},
	},
	approveMarkApproved: {
		'& svg': {
			fill: 'rgb(26, 172, 2)',
		},
	},
	schetTaskDesc: {
		listStyle: 'none',
		padding: 0,
		display: 'flex',
		margin: 0,
		color: theme.text.disabled,
		position: 'relative',
		lineHeight: '14px',
		'&>li': {
			'&>b': {
				fontWeight: 500,
			},
			marginInlineEnd: 8,
			'&:last-of-type': {
				marginInlineEnd: 0,
			},
		},
	},
}));
