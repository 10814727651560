import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { firstBy } from 'thenby';

import api from '~/api';
import useLoadData from '~shared/hooks/useLoadData';
import useSubscribe from '~shared/hooks/useSubscribe';
import type { Orders } from '~types/orders';
import { useCheckExp, useUser } from '~zustand/userData';

import { getDataByColumn } from '../../components/MonitorTableau/utils/processCourierData';

const useOrdersQueque = (orders: Orders.Order[]) => {
	const [orderQueue, setOrderQueue] = useState<string[]>([]);
	const user = useUser();
	const isExpOrderQueue = useCheckExp('exp_order_queue');

	useLoadData(
		() => api.orders.queue(),
		[],
		!isExpOrderQueue,
		(data) => {
			setOrderQueue(data.queue ?? []);
		}
	);

	const orderColumnData = useMemo(() => {
		const data = getDataByColumn(orders);

		if (isExpOrderQueue) {
			const orderQueueMap = Object.fromEntries(orderQueue.map((externalId, index) => [externalId, index]));
			const lastItemIndex = data.waiting_collecting.length;

			data.waiting_collecting.sort(
				firstBy<Orders.Order>(
					(a, b) => (orderQueueMap[a.external_id] ?? lastItemIndex) - (orderQueueMap[b.external_id] ?? lastItemIndex)
				).thenBy((a: Orders.Order, b: Orders.Order) => dayjs(a.created).diff(dayjs(b.created)))
			);
		}

		return data;
	}, [orders, orderQueue]);

	useSubscribe(
		{
			key: ['order_queue', 'store', user.store_id],
			cb: (eventData) => {
				eventData.data.forEach((data) => {
					if (data.type === 'order_queue_updated') {
						setOrderQueue(data.queue);
					}
				});
			},
			unSub: false,
			single: true,
			name: 'order_queue',
		},
		[user.store_id],
		!isExpOrderQueue
	);

	return orderColumnData;
};

export default useOrdersQueque;
