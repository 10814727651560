import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	button: {
		padding: 0,
		inlineSize: 32,
		blockSize: 32,
		color: theme.text.primary,
		backgroundColor: theme.bgColor.main,
		fontSize: '20px',
	},
	divider: {
		backgroundColor: `${theme.border.primary}!important`,
		inlineSize: 2,
		borderRadius: 4,
		marginInlineEnd: 12,
		marginInlineStart: 4,
		blockSize: 28,
		display: 'block !important',
		insetBlockStart: '0 !important',
	},
}));
