import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()({
	header: {
		display: 'flex',
		marginBlockEnd: 16,
	},
	filters: {
		marginInlineEnd: 'auto',
	},
	paymentIconBlock: {
		marginInlineStart: 10,
	},
	paymentIcon: {
		paddingBlockStart: 2,
	},
});
