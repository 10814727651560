import { notification } from '@lavka/ui-kit';
import type { DependencyList } from 'react';
import { useState } from 'react';

import api from '~/api';
import i18n from '~/i18n';
import { useCache } from '~cache/useCache';
import arrayToChunks from '~shared/utils/arrayToChunks';
import type { Shelves } from '~types/shelves';
import { checkPermit } from '~zustand/userData';

import { useAsyncEffect } from './useAsyncEffect';

export async function getShelvesWithStocks(shelves: Shelves.Shelf[]) {
	try {
		const { data } = await api.stocks.shelf({
			shelf_id: shelves.map((shelf) => shelf.shelf_id),
		});

		shelves.forEach((shelf) => {
			shelf.has_stocks = data.result.some((stock: any) => {
				return stock.shelf_id === shelf.shelf_id && stock.count > 0;
			});
		});
		return shelves;
	} catch (e) {
		console.error(e);
	}
}

export default function useGetShelves(
	_: EmptyObject,
	deps: DependencyList = [],
	skip = false,
	onSuccess?: (data?: Shelves.Shelf[]) => void
): { shelves: Shelves.Shelf[]; loading: boolean } {
	const [shelves, setShelves] = useState<Shelves.Shelf[]>([]);
	const [loading, setLoading] = useState(false);
	const { addData } = useCache({});

	useAsyncEffect(async () => {
		if (skip) {
			return;
		}

		setLoading(true);

		try {
			if (!checkPermit('shelves_seek')) return;

			const { data } = await api.shelves.list();
			addData({
				entityName: 'shelves',
				data: data.results,
			});
			const shelves = data.results;
			if (shelves.length && checkPermit('stocks_seek')) {
				const chunks = arrayToChunks(shelves, 500);
				let shelvesWithStocks: Shelves.Shelf[] = [];
				for (const chunk of chunks) {
					const result = await getShelvesWithStocks(chunk);
					shelvesWithStocks = [...shelvesWithStocks, ...result!];
				}
				if (shelvesWithStocks.length) {
					setShelves(shelvesWithStocks);
				}
			}
			setShelves(data.results);
		} catch {
			notification.error({
				message: i18n.t('Не удалось получить области хранения'),
			});
		} finally {
			setLoading(false);
			onSuccess?.(shelves);
		}
	}, deps);

	return { shelves, loading };
}
