export function readAsText(file: File): Promise<string | undefined> {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = function (e) {
			// @ts-expect-error тут не может быть ArrayBuffer, так как используем readAsText
			resolve(e.target?.result ?? undefined);
		};
		fileReader.onerror = reject;

		fileReader.readAsText(file);
	});
}
