import { useMemo } from 'react';

import i18n from '~/i18n';
import analyticStatsInfo from '~constants/analyticStatsInfo';
import capitalize from '~shared/utils/capitalize';
import getIsMetricInRed from '~shared/utils/getIsMetricInRed';
import getProperTime from '~shared/utils/transformSecondsToTimeString';

import type { HealthLevelMetrics, StatDetails } from '../../healthTypes';
import type { ThresholdDataEntry } from '../../hooks/useGetThresholds';
import StatBlock from '../StatBlock';
import { useStyles } from './styles';

type Props = {
	statsData: {
		now: Partial<HealthLevelMetrics>;
		past: Partial<HealthLevelMetrics>;
	};
	thresholds: ThresholdDataEntry;
};

const getStatsFromData = (
	statKeys: string[],
	statsData: Props['statsData'],
	thresholds?: ThresholdDataEntry
): StatDetails[] => {
	return statKeys.map((key) => {
		let val = statsData.now[key];
		const rawDiff = statsData.now[key] - statsData.past[key];
		let diff: string | number = rawDiff;
		let threshold = thresholds?.[key];
		if (analyticStatsInfo[key].type === 'time') {
			val = getProperTime(Math.floor(val));
			diff = getProperTime(Math.floor(diff), true);
			threshold = threshold && getProperTime(Math.floor(threshold), true);
		}

		if (val && key === 'orders_count') {
			val = i18n.t('{{value}} шт.', { value: val });
			diff = i18n.t('{{value}} шт.', { value: diff }) ?? '—';
		}

		if (val && key === 'store_surge_level') {
			val = i18n.t('{{value}} складов', { value: val });
			diff = i18n.t('{{value}} складов', { value: diff }) ?? '—';
		}

		return {
			key,
			title: capitalize(analyticStatsInfo[key].label),
			value: val ? String(val) : '—',
			diff: val && diff ? String(diff) : '—',
			sign: rawDiff ? rawDiff > 0 : null,
			threshold: val && threshold ? threshold : undefined,
			isRed: thresholds?.[key] ? getIsMetricInRed(key, statsData.now[key], thresholds?.[key]) : undefined,
		};
	});
};

const statKeys = [
	'full_cte',
	'assemble_wait_time',
	'assemble_time',
	'waiting_for_pickup',
	'to_client_time',
	'orders_count',
	'store_surge_level',
	'pickup_time',
];

const Stats = ({ statsData, thresholds }: Props) => {
	const { classes } = useStyles();
	const stats = useMemo(() => getStatsFromData(statKeys, statsData, thresholds), [statKeys, statsData, thresholds]);

	return (
		<div className={classes.statsBlock}>
			{stats.map((stat) => (
				<StatBlock key={stat.key} stat={stat} threshold={thresholds?.[stat.key]} />
			))}
		</div>
	);
};

export default Stats;
