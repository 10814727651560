import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()({
	table: {
		overflow: 'auto',
	},
	toolbar: {
		display: 'flex',
		gap: 8,
		marginBlockEnd: 16,
		'> div:first-child': {
			marginInlineEnd: 'auto',
		},
	},
});
