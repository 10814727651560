import { notification } from '@lavka/ui-kit';
import type { OptionProps } from 'antd/lib/select';
import dayjs from 'dayjs';

import api from '~/api';
import i18n from '~/i18n';
import { arrayUniq } from '~shared/utils/arrayUniq';
import courierName from '~shared/utils/courierName';
import { checkIsCourierTaxiUuid, checkIsEdaId, checkIsId, checkIsWmsId } from '~shared/utils/ids';
import userName from '~shared/utils/userName';
import type { Couriers } from '~types/couriers';
import type { User } from '~types/user';

export type SelectValue = {
	label: string;
	value: string;
};

export type OptionTypes = 'surname' | 'name' | 'fullname';
export type SelectOption = {
	value: string;
	label: string;
	type: OptionTypes;
	surname?: string;
};

export type SelectOptionGroup<T> = { label: string; options: T[] };

export const formatSurname = <T extends SelectOption | OptionProps>(searchValue: string, options?: T[]) => {
	if (!options?.length) return searchValue;

	const isDeletedChars = searchValue.length < options?.[0]?.label.length;
	return options.length === 1 || !isDeletedChars ? options[0].label : searchValue;
};

export const filterName = (text: string, option: string | undefined): boolean => {
	if (!text) return true;
	return option ? option.toLowerCase().indexOf(text.toLowerCase()) >= 0 : false;
};

export const filterGroups = <T extends SelectOption | OptionProps>(text: string, option: T | undefined) => {
	if (!option) {
		return false;
	}

	if (option.type === 'name' && !checkIsId(text)) {
		// Делим строку вроде Арина Д. (123454) на 'Арина' и ' (123454)' и берем имя
		const name = option.label.split(/ .\./)?.[0];
		const optionText = `${option.surname} ${name}`;

		if (optionText.length < text.length) {
			return filterName(optionText, text);
		}
		return filterName(text, optionText);
	}
	return option.type === 'surname' || checkIsId(text);
};

export const transformOptions = <T extends SelectOption | OptionProps>(
	names: Record<string, T[]>,
	surnames: T[]
): SelectOptionGroup<T>[] | T[] => {
	const options = [] as SelectOptionGroup<T>[];

	Object.keys(names).map((surname) => {
		if (names[surname].length) {
			options.push({
				label: checkIsId(surname) ? i18n.t('Имена') : i18n.t('Имена c фамилией {{ surname }}', { surname }),
				options: names[surname],
			});
		}
	});

	surnames = arrayUniq(surnames?.filter((surname) => !names[surname.label]));
	if (surnames.length > 0 && surnames[0]?.type === 'surname') {
		options.push({
			label: i18n.t('Фамилии'),
			options: surnames,
		});
	}

	return surnames[0]?.type === 'fullname' ? surnames : options;
};

export const getNames = async <T extends SelectOption | OptionProps>({
	searchText,
	entityName,
	companyId,
	storeId,
	status,
	filter,
}: {
	searchText: string;
	entityName: 'couriersBrief' | 'userExecutors';
	companyId: string;
	storeId?: string;
	status?: 'active';
	filter?: (item: Couriers.Courier | User.User) => boolean;
}) => {
	try {
		switch (entityName) {
			case 'couriersBrief':
				return (await entityMap.couriersBrief(searchText, companyId, filter)) as T[];
			case 'userExecutors':
				return (await entityMap.userExecutors(searchText, companyId, storeId, status)) as T[];
		}
	} catch {
		notification.error({
			message: i18n.t('Не удалось выполнить поиск'),
		});
	}
};

const getSurnameOption = (surname: string) => ({
	label: surname,
	value: surname,
	type: 'surname' as const,
});

export const getSurnames = async <T extends SelectOption | OptionProps>({
	searchText,
}: {
	searchText: string;
}): Promise<T[] | undefined> => {
	if (!searchText) return;
	try {
		const { data } = await api.userSearch.find({ partial_value: searchText });
		return data.result.map(getSurnameOption) as T[];
	} catch (error) {
		if (error.status !== 'CANCELED') {
			notification.error({
				message: i18n.t('Не удалось получить список курьеров'),
			});
		}
	}
};

const getCourierOption = (courier: Couriers.Courier, surname: string) => ({
	label: courierName(courier, { showDisplayName: true, showEatsId: true }),
	value: courier.courier_id,
	type: 'name' as const,
	surname,
});

const getCouriers = async (searchText: string, companyId: string, filter?: (courier: Couriers.Courier) => boolean) => {
	if (!searchText) return;
	try {
		const searchData = {
			cursor: '',
			company_id: companyId,
		};

		if (checkIsEdaId(searchText)) {
			const { data } = await api.couriers.list({ ...searchData, courier_eda_id: searchText });
			if (data.results.length) {
				return data.results.map((courier) => getCourierOption(courier, searchText)) ?? [];
			}
		}

		let field: string | undefined = undefined;

		if (checkIsCourierTaxiUuid(searchText)) {
			field = 'courier_taxi_uuid';
		} else if (checkIsWmsId(searchText)) {
			field = 'courier_id';
		} else {
			field = 'last_name';
		}

		const { data } = await api.couriers.list({ ...searchData, [field]: searchText });
		const results = filter ? data.results.filter(filter) : data.results;
		return results.map((courier) => getCourierOption(courier, searchText)) ?? [];
	} catch (error) {
		if (error.status !== 'CANCELED') {
			notification.error({
				message: i18n.t('Не удалось получить список курьеров'),
			});
		}
	}
};

const getUserOption = (user: Pick<User.User, 'user_id' | 'last_name' | 'display_name'>, surname: string) => ({
	label: userName(user, { showDisplayName: true }),
	value: user.user_id,
	type: 'name' as const,
	surname,
});

const getUsers = async (searchText: string, companyId: string, storeId?: string, status?: 'active') => {
	if (!searchText) return;
	try {
		const searchData = {
			cursor: '',
			store_id: storeId,
			company_id: companyId,
			status,
		};

		let field = 'last_name';
		if (checkIsWmsId(searchText)) {
			field = 'user_id';
		}

		const { data } = await api.users.list({
			...searchData,
			[field]: searchText,
		});
		if (data.results.length) {
			return data.results.map((user) => getUserOption(user, searchText)) ?? [];
		}
	} catch {
		notification.error({
			message: i18n.t('Не удалось получить список пользователей'),
		});
	}
};

const getActiveUsersOption = (user: Pick<User.User, 'user_id' | 'last_name' | 'first_name'>) => ({
	label: userName(user),
	value: user.user_id,
	type: 'fullname' as const,
});

export const getActiveUsers = async (storeId?: string, tz?: string) => {
	try {
		const now = dayjs().tz(tz);
		const { data: userIds } = await api.reportData.health_metrics.users_last_seen({
			store_id: storeId ?? '',
			start_timestamp: now.startOf('day').format(),
			end_timestamp: now.format(),
		});

		if (Object.keys(userIds?.active).length === 0) return;
		const { data: users } = await api.users.load({
			user_id: Object.keys(userIds?.active) ?? [],
			_fields: ['user_id', 'last_name', 'first_name'],
		});

		return users.result.map(getActiveUsersOption);
	} catch {
		notification.error({
			message: i18n.t('Не удалось получить список активных пользователей'),
		});
	}
};

export const entityMap = {
	couriersBrief: getCouriers,
	userExecutors: getUsers,
};
