import { lazy } from 'react';

import { makeRoutes } from '~shared/utils/makeRoutes';

const UsersLogPage = lazy(() => import('../components/UsersLogPage'));

const usersLogRoutes = makeRoutes([
	{
		path: '/users_log/',
		permit: 'cur_user',
		element: <UsersLogPage />,
	},
]);

export default usersLogRoutes;
