import type { AxiosError, AxiosResponse } from 'axios';

import { isTvClientMode } from '~shared/utils/isTvClientMode';
import { checkExp } from '~zustand/userData';

export function logError(additional: Ya.Rum.LogErrorOptions['additional'], error?: Ya.Rum.LoggableError) {
	window.Ya?.Rum?.logError(
		{
			page: window.location.href,
			additional,
		},
		error
	);
}

export function logTvDeviceResponse(response: AxiosResponse) {
	if (isTvClientMode() && checkExp('exp_log_tv')) {
		logError({
			type: 'TV_DEVICE_LOG',
			requestUrl: response?.config?.url,
			requestData: response?.config?.data,
			responseStatus: response?.status,
			responseData: response?.data,
		});
	}
}

function addPageName(error: Error) {
	// eslint-disable-next-line no-restricted-globals
	const pageName = location.pathname
		.split('/')
		.map((e) => (e.length === 44 ? ':id' : e))
		.join('/');

	error.message = `${error.message} at ${pageName}`;
}

export function logTvDeviceError(error: AxiosError) {
	if (isTvClientMode() && checkExp('exp_log_tv')) {
		logAxiosError(error, 'TV_DEVICE_ERROR');
	}
}

export function logAxiosError(error: AxiosError, type = 'API_ERROR') {
	addPageName(error);

	if (error?.config?.url) {
		error.message = `${error.message} (${error?.config?.url})`;
	}

	logError(
		{
			type,
			requestUrl: error?.config?.url,
			requestData: error?.config?.data,
			responseStatus: error?.response?.status,
			responseData: error?.response?.data,
		},
		error
	);
}
