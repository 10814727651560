import type { AxiosInstance } from 'axios';

function printLog(name: string, object: unknown) {
	console.log(`---${name}: ${JSON.stringify(object, null, 2)}---`);
}

export default function (axios: AxiosInstance) {
	if (process.env.REACT_APP_MODE !== 'ci' || process.env.NODE_ENV === 'development') {
		return;
	}

	axios.interceptors.request.use((config) => {
		const log = {
			requestUrl: config?.url,
			requestData: config?.data,
		};
		printLog('NETWORK REQUEST', log);

		return config;
	});
	axios.interceptors.response.use(
		(response) => {
			const log = {
				requestUrl: response?.config?.url,
				requestData: response?.config?.data,
				responseStatus: response?.status,
				responseData: response?.data,
			};
			printLog('NETWORK RESPONSE', log);

			return response;
		},
		(error) => {
			const log = {
				requestUrl: error?.response?.config?.url,
				requestData: error?.response?.config?.data,
				responseStatus: error?.response?.status,
				responseData: error?.response?.data,
			};
			printLog('NETWORK ERROR RESPONSE', log);

			return Promise.reject(error);
		}
	);
}
