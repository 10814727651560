import axios from 'axios';

import type { AxiosAdditionalConfig } from '~/api';
import { makeListFn } from '~shared/utils/makeApiFns';
import type { CheckProjects } from '~types/checkProjects';
import type { Orders } from '~types/orders';

import { makeLoadFn } from './storage/makeLoadFn';
import type { Keys, PartialEntity } from './types';

export default {
	load: makeLoadFn<CheckProjects.CheckProject, 'check_project_id'>(
		'/api/admin/check_projects/load',
		'check_project_id'
	),

	save: (
		data:
			| CheckProjects.CheckProject
			| (Omit<CheckProjects.CheckProject, 'check_project_id'> & { check_project_id?: string }),
		config?: AxiosAdditionalConfig
	) => axios.post<{ result: CheckProjects.CheckProject }>('/api/admin/check_projects/save', data, config),
	list: makeListFn<{ title?: string; status?: string | string[]; limit?: number }, CheckProjects.CheckProject>(
		'/api/admin/check_projects/list'
	),

	find_orders: (data: { check_project_id: string; cursor?: string }) =>
		axios.post<{ result: Orders.Order[]; cursor: string | null }>('/api/admin/check_projects/find_orders', data),

	active_stores_projects: <TFields extends Keys<CheckProjects.CheckProject>>(data: { _fields?: TFields }) =>
		axios.post<{
			result: PartialEntity<CheckProjects.CheckProject, TFields>[];
		}>('/api/admin/check_projects/active_stores_projects', data),

	defibrillation: (data: { check_project_id: string }) =>
		axios.post<{ code: string; job_id: string }>('/api/admin/check_projects/defibrillation', data),
};
