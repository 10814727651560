import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EventsSlider } from '../EventsSlider';
import { useStyles } from './styles';

interface Props {
	storeId?: string;
	toggleOpen: (value: boolean) => void;
}

export const ModalHeader = ({ storeId, toggleOpen }: Props) => {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const navigate = useNavigate();

	return (
		<header className={classes.calendarHeader}>
			<div className={classes.calendarTopPanel}>
				<h6 className={classes.calendarTitle}>{t('Календарь событий')}</h6>
				<Button
					className={classes.calendarByWeekBtn}
					data-test="open-by-week-button"
					target="_self"
					size="small"
					onClick={() => {
						toggleOpen(false);
						navigate(`/stores/${storeId}?tab=storeEvents`);
					}}
				>
					{t('Открыть за неделю')}
				</Button>
			</div>
			<EventsSlider />
		</header>
	);
};
