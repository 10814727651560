import { useLayoutEffect, useState } from 'react';

import { zoneDeliveryTypesValues } from '~server-types/doc/api/models/zone';
import type { Stores } from '~types/stores';

import type { ZonePoligon } from '../../../../../components/StoresPage/StoresDataPage/Zones/types';

export const eZoneDeliveryTypesValues = zoneDeliveryTypesValues.filter((el) => el !== 'car_night');

const geometryCollectionToMultipolygon = (geometryCollection: any) => {
	return {
		type: 'MultiPolygon',
		coordinates: geometryCollection.geometries.map((geometry: any) => geometry.coordinates),
	};
};

export const normalizeGeoJson = (geoJson: any) => {
	return {
		...geoJson,
		features: geoJson.features.map((feature: any) => {
			if (feature?.geometry?.type === 'GeometryCollection') {
				return {
					...feature,
					geometry: geometryCollectionToMultipolygon(feature.geometry),
				};
			}
			return feature;
		}),
	};
};

// удаляем лишнюю третью координату высоты
export function normalizeZone(zone: any) {
	const sliceCoord = (coord: any[]) => {
		if (coord.length > 2) {
			return coord.slice(0, 2);
		}
		return coord;
	};
	return {
		...zone,
		geometry: {
			...zone.geometry,
			coordinates: zone.geometry.coordinates.map((coords: any[]) =>
				coords.map((coord) => {
					if (zone.geometry.type === 'MultiPolygon') {
						return coord.map((coord: any) => {
							return sliceCoord(coord);
						});
					} else {
						return sliceCoord(coord);
					}
				})
			),
		},
	};
}

export function useUploadGeoObjects(editMode: boolean) {
	const [fileList, setFileList] = useState<any[]>([]);
	const [textGeoObjectsImported, toggleTextGeoObjectsImported] = useState<boolean>(false);
	const [mapFieldsBeforeUploadGeoObjects, saveMapFieldsBeforeUploadGeoObjects] = useState<
		Stores.Zone[] | ZonePoligon | undefined
	>([]);

	useLayoutEffect(() => {
		setFileList([]);
		toggleTextGeoObjectsImported(false);
	}, [editMode]);

	return {
		fileList,
		setFileList,
		mapFieldsBeforeUploadGeoObjects,
		saveMapFieldsBeforeUploadGeoObjects,
		textGeoObjectsImported,
		toggleTextGeoObjectsImported,
	};
}
