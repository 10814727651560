import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function (
	paramName: string,
	options?: {
		default?: string;
	},
	skip?: boolean
): {
	value: Record<string, string>;
	active: string;
	setActive: (value: string) => void;
} {
	const [searchParams, setSearchParams] = useSearchParams();
	const paramValue = searchParams.get(paramName);

	// @ts-expect-error тут тип не сходится из-за undefined, пока не знаю как просто починить, раньше было any
	const [active, setActive] = useState<string>(paramValue ?? options?.default);

	useEffect(() => {
		if (skip) {
			return;
		}

		if (active && active !== paramValue) {
			setSearchParams((sp) => {
				sp.set(paramName, active);
				return sp;
			});
		}
	}, [active]);

	return {
		value: Object.fromEntries(searchParams),
		active,
		setActive,
	};
}
