import axios from 'axios';

import type { Products } from '~types/products';

import { makeLoadFn } from './storage/makeLoadFn';

export default {
	load: makeLoadFn<Products.Item, 'item_id'>('/api/admin/items/load', 'item_id'),
	expire: (data: { item_id: string }) => axios.post('/api/admin/items/expire', data),
	activate: (data: { item_id: string }) => axios.post('/api/admin/items/activate', data),
};
