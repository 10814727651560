export const checkProjectsOrderStatusColor = {
	request: 'blue',
	processing: 'purple',
	complete: 'green',
	canceled: 'red',
	failed: 'red',
};

export const statusOrder = {
	request: 2,
	processing: 4,
	complete: 5,
	canceled: 3,
	failed: 1,
	reserving: 6,
	approving: 7,
} as const;
